<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-grid-column-small uk-grid-row-large" uk-grid v-if="$can('READ', 'worker-salary-report')">
            <div class="uk-width-expand">
                <div v-if="isShow">
                    <div uk-grid>
                        <div class="uk-width-auto uk-margin-auto uk-margin-auto-vertical">
                            <img :src="worker ? worker.user_id.photo_url ? worker.user_id.photo_url.file_url : `${images}/avatar.svg` : `${images}/avatar.svg`" class="photo">
                        </div>
                        <div class="uk-width-expand uk-margin-auto uk-margin-auto-vertical">
                            <div class="applicant_name"><h4><span class="uk-text-bold">{{ worker ? worker.user_id.fullname : 'Nihil' }}</span></h4></div>
                            <div class="uk-flex uk-flex-row uk-flex-left">
                                <div>
                                    <span>Job Role: {{ worker ? worker.role_id.name : 'Nihil' }}</span><br>
                                    <span>Company: {{ worker ? worker.company_id.name : 'Nihil' }}</span><br>
                                    <span>Office: {{ worker ? worker.company_office_id.name : 'Nihil' }}</span><br>
                                </div>
                                <div class="uk-margin-xlarge-left">
                                    <span>Account Holder: {{ bank_info ? bank_info.bank_account_name : 'Nihil' }}</span><br>
                                    <span>Bank: {{ bank_info ? bank_info.bank_name : 'Nihil' }}</span><br>
                                    <span>Account Number: {{ bank_info ? bank_info.bank_account_number : 'Nihil' }}</span><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 v-if="isShow" class="title">Payment history of <span class="uk-text-bold">{{ worker ? worker.user_id.fullname : 'Nihil' }}</span></h4>
            </div>
            <div>
                <div class="uk-align-right" style="overflow:auto">
                    <div
                        class="report-download"
                        style="cursor:pointer;float:right"
                        @click="$router.back()"
                        >
                        <img :src="`${images}/cross.svg`" alt="" width="25">
                    </div>
                </div>
            </div>
        </div>
        <div v-if="data && $can('READ', 'worker-salary-report')" class="uk-flex uk-flex-between uk-margin-remove-top" uk-grid>
            <div uk-grid>
                <div class="uk-inline" v-show="isShow">
                    <div class="location-header">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20">
                        {{ `${formatDate(dateSalary.start_date)} - ${formatDate(dateSalary.end_date)}`}},
                        {{ data.recaps[0].job_partner_id.company_office_id.city || '-' }},
                        {{ data.recaps[0].job_partner_id.company_office_id.name || '-' }}
                    </div>
                </div>
            </div>
        </div>

        <div>
            <ul uk-tab>
                <li class="uk-active"><a href="#" class="uk-button">PAYMENT</a></li>
                <li><a href="#" class="uk-button">ADJUSTMENTS</a></li>
            </ul>

            <div class="uk-switcher">
                <!-- Tab 1 -->
                <div>
                    <div class="uk-grid uk-margin">
                        <div class="uk-width-2-3" v-show="isShow"
                        v-if="!consist(user_cred.roles, ['user-marketing'])">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-medium uk-table-striped">
                                    <caption class="table-caption">Income</caption>
                                    <thead>
                                        <tr>
                                            <th class="uk-text-left uk-text-middle">Type</th>
                                            <th class="uk-text-center uk-text-middle">Date</th>
                                            <th class="uk-text-center uk-text-middle">Total Working<br>Hours</th>
                                            <th class="uk-text-center uk-text-middle">Total Overtime<br>Hours</th>
                                            <th class="uk-text-center uk-text-middle">Fee</th>
                                            <th class="uk-text-center uk-text-middle">Overtime</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="data && data.recaps.length>0">
                                        <tr v-for="(recap, i) in data.recaps" :key="i+'x'">
                                            <td class="uk-text-left uk-text-middle">Attendance</td>
                                            <td class="uk-text-center uk-text-middle">{{ formatDate(recap.date) }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ recap.normal_work_hour }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ recap.overtime_work_hour_spv }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ formatCurrency( recap.fee_normal_total? recap.fee_normal_total : recap.fee_total - recap.fee_overtime) }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ formatCurrency( recap.fee_overtime_total ? recap.fee_overtime_total : recap.fee_overtime) }}</td>
                                        </tr>
                                        <tr v-if="data && data.recaps.length>0">
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-right td-bold border-top">Total</td>
                                            <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_normal_fee) }}</td>
                                            <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_overtime_fee) }}</td>
                                            <td class="uk-text-center border-top"></td>
                                        </tr>
                                        <template v-if="data && data.additions && data.additions.length>0" >
                                            <tr v-for="(addition, y) in data.additions" :key="y+'y'">
                                                <td class="uk-text-left uk-text-middle">{{ addition.name }}</td>
                                                <td class="uk-text-center uk-text-middle">{{ formatDate(addition.decision_date) }}</td>
                                                <td class="uk-text-center"></td>
                                                <td class="uk-text-center"></td>
                                                <td class="uk-text-center uk-text-middle">{{ formatCurrency( addition.decision_amount) }}</td>
                                                <td class="uk-text-center"></td>
                                            </tr>
                                            <tr>
                                                <td class="uk-text-center border-top"></td>
                                                <td class="uk-text-center border-top"></td>
                                                <td class="uk-text-center border-top"></td>
                                                <td class="uk-text-right td-bold border-top">Total</td>
                                                <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_income) }}</td>
                                                <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_overtime_fee) }}</td>
                                                <td class="uk-text-center border-top"></td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <empty-table v-else :colspan="7"/>
                                </table>
                            </div>
                        </div>
                        <div class="uk-width-1-3" v-show="isShow"
                        v-if="!consist(user_cred.roles, ['user-marketing'])">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-medium uk-table-striped">
                                    <caption class="table-caption">Expense</caption>
                                    <thead>
                                        <tr>
                                            <th class="uk-text-center">Name</th>
                                            <th class="uk-text-center">Approval Date</th>
                                            <th class="uk-text-center">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="data && data.expenses && data.expenses.length > 0">
                                        <tr v-for="(expense, q) in data.expenses" :key="q">
                                            <td class="uk-text-center uk-text-truncate uk-width-1-2">{{ expense.name == 'Cash Advance' ? `Cash Advance - id ${expense._id}` : expense.name }}</td>
                                            <td class="uk-text-center uk-width-auto">{{ formatDate(expense.decision_date) }}</td>
                                            <td class="uk-text-center">{{ formatCurrency(expense.decision_amount) }}</td>
                                        </tr>
                                        <tr v-if="data && data.expenses && data.expenses.length > 0">
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-right td-bold border-top">Total</td>
                                            <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_expense) }}</td>
                                        </tr>
                                    </tbody>
                                    <empty-table v-else :colspan="3"/>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="uk-margin payment uk-padding-small uk-flex uk-flex-between">
                        <div class="uk-width-auto uk-margin-auto-vertical">Total Payment</div>
                        <div class="uk-width-auto uk-margin-auto-vertical uk-text-bold">Rp {{formatCurrency(data ? data.total_payment : 0)}}</div>
                    </div>
                </div>
                <!-- Tab 1 -->
                <!-- Tab 2 -->
                <div class="uk-margin">
                    <div v-show="isShow"
                    v-if="!consist(user_cred.roles, ['user-marketing'])">
                        <div class="uk-overflow-auto">
                            <table class="uk-table uk-table-medium uk-table-striped">
                                <thead>
                                    <tr>
                                        <th class="uk-text-left uk-text-middle">Type</th>
                                        <th class="uk-text-center uk-text-middle">Name</th>
                                        <th class="uk-text-center uk-text-middle">Date</th>
                                        <th class="uk-text-center uk-text-middle">Amount</th>
                                        <th class="uk-text-center uk-text-middle">Updated By</th>
                                    </tr>
                                </thead>
                                <tbody v-if="dataAdjustment && dataAdjustment.length>0">
                                    <tr v-for="(item, x) in dataAdjustment" :key="x+'a'">
                                        <td class="uk-text-left uk-text-middle">{{ item.type == 1 ? 'Expenses' : 'Income' }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ item.name }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ formatDate(item.decision_date) }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ formatCurrency(item.decision_amount) }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ item.decision_by }}</td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="7"/>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Tab 2 -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import moment from 'moment';
moment.locale('id');

export default {
    data({ $route }) {
        return {
            markers: [],
            showInfo: false,
            moment: moment,
            city: $route.query.city,
            office: $route.query.office,
            worker: null,
            isShow: true,
            meta: $route.query,
            images: PREFIX_IMAGE,
            company_office: { name: $route.query.property_name, city: $route.query.property_city},
            showResult: false,
            data: null,
            bank_info: null,
            selected_data: null,
            dataAdjustment: null,
            createAdjustment: true,
            selectedAdjustment: {
                type: null,
                name: null,
                decision_date: null,
                decision_amount: null
            },
        };
    },
    components: {
        EmptyTable,
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
        dateSalary() {
            let start_date, end_date = null;
            if (this.data.recaps) {
                start_date = this.data.recaps[this.data.recaps.length - 1].date;
                end_date = this.data.recaps[0].date;
            }
            if (this.data.additions && this.data.additions.length > 0) {
                if (end_date < this.data.additions[0].decision_date) {
                    end_date = this.data.additions[0].decision_date;
                }
                if (start_date > this.data.additions[this.data.additions.length - 1].decision_date) {
                    start_date = this.data.additions[this.data.additions.length - 1].decision_date;
                }
            }
            if (this.data.expenses && this.data.expenses.length > 0) {
                if (end_date < this.data.expenses[0].decision_date) {
                    end_date = this.data.expenses[0].decision_date;
                }
                if (start_date > this.data.expenses[this.data.expenses.length - 1].decision_date) {
                    start_date = this.data.expenses[this.data.expenses.length - 1].decision_date;
                }
            }
            return {start_date: start_date, end_date: end_date};
        }
    },
    beforeMount() {
        if(window.UIkit.modal('.edit-fee')) window.UIkit.modal('.edit-fee').$destroy(true);
        if(window.UIkit.modal('.adjustment')) window.UIkit.modal('.adjustment').$destroy(true);
        if(window.UIkit.modal('.confirmation')) window.UIkit.modal('.confirmation').$destroy(true);
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        ...mapActions({
            getSalaryReportDetail: 'salary/getSalaryReportDetail',
            getSalaryAdjustments: 'salary_adjustment/getSalaryAdjustments'
        }),
        async getData() {
            this.data = await this.getSalaryReportDetail(this.meta);
            if (this.data && this.data.recaps.length > 0) {
                this.worker = this.data.recaps.at(-1).job_partner_id;
                this.bank_info = this.data.user_bank;
            }
            this.dataAdjustment = await this.getSalaryAdjustments(this.data.recaps.at(-1).job_partner_id._id);
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatHourMinute(data) {
            if (data == null) {
                return '-';
            }
            return formatter.hourMinuteOnly(data);
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'edit-fee':
                this.selected_data = null;
                await window.UIkit.modal('#edit-fee').hide();
                break;
            case 'adjustment':
                this.selectedAdjustment = {
                    type: null,
                    name: null,
                    decision_date: null,
                    decision_amount: null
                };
                await window.UIkit.modal('#adjustment').hide();
                break;
            case 'confirmation':
                this.selectedAdjustment = {
                    type: null,
                    name: null,
                    decision_date: null,
                    decision_amount: null
                };
                await window.UIkit.modal('#confirmation').hide();
                break;
            }
        },
        async showModal(modal_name, data=null) {
            switch(modal_name) {
            case 'edit-fee':
                this.selected_data = data;
                await window.UIkit.modal('#edit-fee').show();
                break;
            case 'adjustment':
                if (data) {
                    this.selectedAdjustment = data;
                    this.createAdjustment = false;
                }
                await window.UIkit.modal('#adjustment').show();
                break;
            case 'confirmation':
                this.selectedAdjustment = data;
                await window.UIkit.modal('#confirmation').show();
                break;
            }
        },
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.td-bold{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.report-download{
    font-family: 'Roboto';
    color: #5FD855;
    font-size: 20px;
}
.payment {
    background-color: #0ABAB5;
    color: #ffffff;
}
.photo {
    width: 106px;
    height: 106px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}
.border-top {
    border-top: 1px solid black;
}
.table-caption {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #000000;
}
</style>
