<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <section class="uk-flex uk-flex-between uk-margin-small">
            <div class="uk-flex myrobin-background-white uk-border-rounded padding-8px">
                <button
                    class="uk-button uk-margin-small-right uk-border-rounded"
                    :class="[
                        $route.query.tab === 'applicant'
                            ? 'myrobin-color-white uk-text-bold myrobin-background-primary'
                            : 'myrobin-color-grey myrobin-background-white'
                    ]"
                    :disabled="$route.query.tab === 'applicant'"
                    @click="setQueryURL({ tab: 'applicant'})"
                >
                    View All Applicant
                </button>
                <button
                    class="uk-button uk-border-rounded"
                    :class="[
                        $route.query.tab === 'vacancy'
                            ? 'myrobin-color-white uk-text-bold myrobin-background-primary'
                            : 'myrobin-color-grey myrobin-background-white'
                    ]"
                    :disabled="$route.query.tab === 'vacancy'"
                    @click="setQueryURL({ tab: 'vacancy'})"
                >
                    View per Vacancy
                </button>
            </div>
            <div v-if="$route.query.tab == 'applicant'">
                <button
                    class="uk-button uk-height-1-1 uk-text-500"
                    :class="[applicant_list_v4.docs.length > 0
                        ? 'uk-button-primary myrobin-color-white'
                        : 'myrobin-background-F4F4F4 text-grey'
                    ]"
                    :disabled="applicant_list_v4.docs.length == 0"
                    @click="isShowDownloadReportModal = true"
                >
                    Download Report
                </button>
            </div>
        </section>
        <ListVacancy v-if="$route.query.tab === 'vacancy'" />
        <ListApplicant v-if="$route.query.tab === 'applicant'" @filterApplied="(value) => allApplicantFilters = value" />

        <DownloadReportAllModal
            v-if="isShowDownloadReportModal"
            :filters="allApplicantFilters"
            @hideModal="isShowDownloadReportModal = false"
        />
    </div>
</template>

<script>
import ListApplicant from './List_Applicant';
import ListVacancy from './List_Vacancy';
import DownloadReportAllModal from '@/components/pages/admin/job_applicant/modals/DownloadReportAllModal';
import { notificationDanger } from '@/utils/notification';
import { mapGetters } from 'vuex';

export default {
    components: {
        ListVacancy,
        ListApplicant,
        DownloadReportAllModal
    },
    data() {
        return {
            isShowDownloadReportModal: false,
            allApplicantFilters: {}
        };
    },
    computed: {
        ...mapGetters({
            applicant_list_v4: 'job_applicant/job_applicant_list_v4'
        }),
    },
    watch: {
        async '$route.query.tab'() {
            if (!this.$route.query?.tab)
                await this.setActiveTabMenu({ tab: 'applicant' });
        }
    },
    async mounted() {
        try {
            await this.setFirstActiveTab();
            window.scrollTo({ top: 0 });
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        async setFirstActiveTab() {
            try {
                if (!this.$route.query.tab) {
                    await this.setActiveTabMenu({ tab: 'applicant' });
                } else {
                    await this.setActiveTabMenu({ tab: this.$route.query.tab });
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setActiveTabMenu({ tab }) {
            try {
                if (this.$route.query.tab !== tab) {
                    await this.setQueryURL({ tab });
                }
                window.scrollTo({ top: 0 });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setQueryURL({ tab }) {
            try {
                await this.$router.replace({
                    path: this.$route.fullPath,
                    query: { tab }
                });
            } catch (error) {
                notificationDanger(error);
            }
        }
    }
};
</script>

<style scoped>
.padding-8px {
    padding: 8px;
}

.myrobin-background-F4F4F4 {
    background: #F4F4F4;
}
</style>
