<template>
    <div class="uk-container uk-container-expand card-scrollable uk-padding-small">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <!-- Header -->
            <div v-if="$can('READ', 'rostering-shift')" class="uk-flex uk-flex-middle uk-flex-between">
                <p class="uk-margin-remove uk-text-lead">Shift Details</p>
                <button class="uk-button uk-button-link" @click="goBack">
                    <img :src="`${images}/cross.svg`" alt="Cross icon" width="15">
                </button>
            </div>
            <!-- End header -->

            <!-- Shift detail -->
            <div v-if="$can('READ', 'rostering-shift')" class="uk-grid-small uk-margin-top" uk-grid>
                <div class="uk-width-1-1">
                    <p class="uk-margin-remove">Shift Name: <span class="uk-text-bold">{{ shift_detail[0].shift_name || '-' }}</span></p>
                </div>
                <div class="uk-width-1-1">
                    <p class="uk-margin-remove">Job Title: {{ shift_detail[0].job_title || 'All Job Title' }}</p>
                </div>
                <div class="uk-width-1-1">
                    <p class="uk-margin-remove">
                        <span>Work Day(s): </span>
                        <template v-if="formatWorkDays({ data: shift_detail[0] }).length > 0">
                            <span
                                v-for="(day, index) in formatWorkDays({ data: shift_detail[0] })"
                                :key="index"
                            >
                                <span>{{ day }}</span>
                                <span v-if="index !== formatWorkDays({ data: shift_detail[0] }).length -1">, </span>
                            </span>
                        </template>
                        <template v-else>
                            <span>Setaip hari</span>
                        </template>
                    </p>
                </div>
                <div class="uk-width-1-4">
                    <p class="uk-margin-remove">
                        <span>Time: </span> 
                        <span>{{ formatHourMinute({ data: shift_detail[0].start_shift }) }}</span>
                        <span> - </span> 
                        <span>{{ formatHourMinute({ data: shift_detail[0].end_shift }) }}</span>   
                    </p>
                </div>
                <div class="uk-width-1-4">
                    <p class="uk-margin-remove">
                        <span>Duration: </span>
                        <span>{{ formatDuration({ start_shift: shift_detail[0].start_shift, end_shift: shift_detail[0].end_shift }) }}</span>
                        <span> Hours</span>
                    </p>
                </div>
            </div>
            <!-- End shift detail -->

            <!-- Worker list -->
            <div v-if="$can('READ', 'rostering-shift')" class="uk-margin-top">
                <div class="uk-flex uk-flex-middle uk-flex-between">
                    <p class="uk-margin-remove uk-text-lead">List of Workers in this shift</p>
                    <button
                        type="button"
                        class="uk-button uk-button-primary uk-border-rounded"
                        @click="showAddWorkerModal"
                    >Add Workers</button>
                </div>
                <div class="uk-card uk-card-default uk-margin-small-top">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-small uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-nowrap">Name</th>
                                    <th class="uk-text-nowrap">Location</th>
                                    <th class="uk-text-nowrap">Existing Shift</th>
                                    <th class="uk-text-nowrap">Job Role</th>
                                    <th class="uk-text-nowrap">Phone Number</th>
                                    <th class="uk-text-nowrap">Email</th>
                                    <th
                                        v-if="$can('EDIT', 'rostering-shift') || $can('DELETE', 'rostering-shift')"
                                        class="uk-text-nowrap uk-text-center"
                                    >Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="workerListLoading">
                                <tr>
                                    <td class="uk-text-center uk-text-nowrap" :colspan="7">
                                        <div class="uk-padding-large" uk-spinner="ratio: 2"></div>
                                    </td>
                                </tr>
                            </tbody>
                            <template v-else>
                                <tbody v-if="worker_list && worker_list.docs.length > 0">
                                    <tr v-for="(worker, index) in worker_list.docs" :key="index">
                                        <td class="uk-text-nowrap">
                                            <span>
                                                <img
                                                    v-if="!worker.photo_url"
                                                    :data-src="`${images}/avatar.svg`"
                                                    alt="Avatar Icon"
                                                    class="uk-preserve-width uk-border-circle"
                                                    style="object-fit: contain; object-position: center; width: 40px; height: 40px;"
                                                    uk-img
                                                >
                                                <img
                                                    v-else
                                                    :data-src="worker.photo_url.file_url"
                                                    @error="(e) => imgError(e)"
                                                    alt="Avatar Icon"
                                                    class="uk-preserve-width uk-border-circle"
                                                    style="object-fit: contain; object-position: center; width: 40px; height: 40px;"
                                                    uk-img
                                                >
                                            </span>
                                            <span class="uk-margin-small-left">{{ worker.fullname || '-' }}</span>
                                        </td>
                                        <td class="uk-text-nowrap">{{ worker.location || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ worker.existing_shift || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ worker.job_role || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ worker.phone_number || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ worker.email || '-' }}</td>
                                        <td
                                            v-if="$can('EDIT', 'rostering-shift') || $can('DELETE', 'rostering-shift')"
                                            class="uk-text-nowrap uk-text-center"
                                        >
                                            <button
                                                v-if="$can('EDIT', 'rostering-shift')"
                                                class="uk-button uk-button-default uk-border-rounded myrobin-button-primary"
                                                @click="showMoveWorkerModal({ mitraId: worker._id })"
                                            >Move</button>
                                            <button
                                                v-if="$can('DELETE', 'rostering-shift')"
                                                class="uk-button uk-button-danger uk-border-rounded uk-margin-small-left"
                                                @click="showDeleteWorkerModal({ mitraId: worker._id })"
                                            >Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="7"/>
                            </template>
                        </table>
                    </div>
                    <pagination
                        v-if="!workerListLoading && worker_list && worker_list.totalDocs"
                        :total-data="worker_list.totalDocs"
                        :change-limit="changeLimitWorkerList"
                        :change-page="changePageWorkerList"
                    />
                </div>
            </div>
            <!-- End worker list -->

            <!-- Add worker modal -->
            <div v-if="$can('WRITE', 'rostering-shift')" id="add-worker-modal" class="uk-flex-top uk-modal-container add-worker-modal" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" @click="cancelSelectWorker" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Tambah Mitra</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-flex uk-flex-center uk-flex-middle uk-padding-large">
                        <div uk-spinner="ratio: 2"></div>
                    </div>
                    <template v-else>
                        <div class="uk-padding-small uk-padding-remove-vertical" style="padding: 0px 27px;">
                            <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
                                <div class="uk-flex uk-flex-middle">
                                    <input
                                        v-if="mitra_list"
                                        class="uk-checkbox"
                                        type="checkbox"
                                        v-model="select_all_worker"
                                        :disabled="mitra_list.docs.filter((data) => data.shift_id !== this.$route.params.shift_id).length < 1"
                                        @change="selectAllWorker"
                                    >
                                    <input
                                        v-else
                                        class="uk-checkbox"
                                        type="checkbox"
                                        disabled
                                    >
                                    <form class="uk-margin-small-left" @submit.prevent="searchMitraList">
                                        <div class="uk-inline">
                                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                                            <input
                                                type="search"
                                                class="uk-input select-input"
                                                placeholder="Search worker by name"
                                                v-model="metaMitraList.fullname"
                                            >
                                        </div>
                                    </form>
                                </div>
                                <button
                                    type="button"
                                    class="uk-button"
                                    :class="[mitra_list && workers.length < 1 ? 'uk-button-default' : ' uk-button-primary']"
                                    :disabled="mitra_list && workers.length < 1"
                                    @click="deselectAllWorker"
                                >Deselect All</button>
                            </div>
                        </div>
                        <div class="uk-margin-top uk-padding-small uk-padding-remove-vertical" uk-overflow-auto>
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-small uk-table-striped uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th class="uk-text-nowrap"></th>
                                            <th class="uk-text-nowrap">Name</th>
                                            <th class="uk-text-nowrap">Location</th>
                                            <th class="uk-text-nowrap">Existing Shift</th>
                                            <th class="uk-text-nowrap">Job Role</th>
                                            <th class="uk-text-nowrap">Phone Number</th>
                                            <th class="uk-text-nowrap">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="mitra_list && mitra_list.docs && mitra_list.docs.length > 0">
                                        <tr v-for="(data, index) in mitra_list.docs" :key="index">
                                            <td>
                                                <input
                                                    class="uk-checkbox"
                                                    type="checkbox"
                                                    :checked="
                                                        findWorkerShiftData({ mitraId: data._id }) ||
                                                        findSelectedWorker({ mitraId: data._id })
                                                    "
                                                    :disabled="disabledUncheckMitra({ shiftId: data.shift_id })"
                                                    @change="selectWorker({ mitraId: data._id, userId: data.user_id, shiftId: data.shift_id })"
                                                >
                                            </td>
                                            <td class="uk-flex uk-flex-middle uk-text-nowrap">
                                                <span>
                                                    <img
                                                        v-if="!data.photo_url"
                                                        :data-src="`${images}/avatar.svg`"
                                                        alt="Avatar Icon"
                                                        class="uk-preserve-width uk-border-circle"
                                                        style="object-fit: contain; object-position: center; width: 40px; height: 40px;"
                                                        uk-img
                                                    >
                                                    <img
                                                        v-else
                                                        :data-src="data.photo_url.file_url"
                                                        @error="(e) => imgError(e)"
                                                        alt="Avatar Icon"
                                                        class="uk-preserve-width uk-border-circle"
                                                        style="object-fit: contain; object-position: center; width: 40px; height: 40px;"
                                                        uk-img
                                                    >
                                                </span>
                                                <span class="uk-margin-small-left">{{ data.fullname || '-' }}</span>
                                            </td>
                                            <td class="uk-text-nowrap">{{ data.location || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.existing_shift || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.job_role || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.phone_number || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.email || '-' }}</td>
                                        </tr>
                                    </tbody>
                                    <empty-table v-else :colspan="7"/>
                                </table>
                            </div>
                            <pagination
                                v-if="mitra_list && mitra_list.totalDocs"
                                :total-data="mitra_list.totalDocs"
                                :change-limit="changeLimitMitraList"
                                :change-page="changePageMitraList"
                            />
                        </div>
                        <div class="uk-margin-top uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="button_assign_mitra_to_shift_loading"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button"
                                :class="[
                                    workers.filter((worker) => worker.new === true).length < 1 ? 'uk-button-default' : 'uk-button-primary'
                                ]"
                                :disabled="workers.filter((worker) => worker.new === true).length < 1"
                                @click="assignWorkerToShift"
                            >Save</button>
                        </div>
                    </template>
                </div>
            </div>
            <!-- End add worker modal -->

            <!-- Handler if don't have access permission -->
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>Selamat Datang di Company Dashboard</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler if don't have access permission -->

            <!-- Move worker modal -->
            <div v-if="$can('EDIT', 'rostering-shift')" id="move-worker-modal" class="uk-flex-top move-worker-modal" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Move</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-flex uk-flex-center uk-flex-middle uk-padding-large">
                        <div uk-spinner="ratio: 2"></div>
                    </div>
                    <form v-else-if="!modalLoading && shift_options" @submit.prevent="movedWorker">
                        <div class="uk-padding uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <label for="moveToShift" class="uk-form-label">Move to Shift</label>
                                <select id="moveToShift" class="uk-select" v-model="form_move_worker_modal.new_company_shift_id">
                                    <option :value="null">Select Shift...</option>
                                    <option
                                        v-for="(shift, index) in shift_options"
                                        :key="index"
                                        :value="shift._id"
                                        :disabled="shift._id === $route.params.shift_id"
                                    >{{ shift.name || '-' }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="button_assign_mitra_to_shift_loading"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                class="uk-button"
                                :class="[!form_move_worker_modal.new_company_shift_id ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="!form_move_worker_modal.new_company_shift_id"
                            >Save</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End move worker modal -->

            <!-- Delete worker modal -->
            <div v-if="$can('DELETE', 'rostering-shift')" id="delete-worker-modal" class="uk-flex-top delete-worker-modal" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" @click="cancelDeleteWorker" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Delete Worker</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p class="uk-margin-remove">Are you sure want to proceed?</p>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="button_assign_mitra_to_shift_loading"
                            class="uk-button uk-button-default"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <template v-else>
                            <button
                                type="button"
                                class="uk-button uk-button-default"
                                style="border: 1px solid #0275D8; color: #0275D8; background-color: white;"
                                @click="cancelDeleteWorker"
                            >No</button>
                            <button
                                type="button"
                                class="uk-button uk-button-primary uk-margin-small-left"
                                @click="deletedWorker"
                            >Yes</button>
                        </template>
                    </div>
                </div>
            </div>
            <!-- End delete worker modal -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';
import formatter from "@/utils/formatter";
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import moment from 'moment';
moment.locale('id');

export default {
    name: 'ShiftDetail',
    components: {
        Pagination,
        EmptyTable
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            workerListLoading: false,
            worker_list: null,
            metaWorkerList: {
                limit: 10,
                page: 1,
                shift_id: this.$route.params.shift_id
            },
            modalLoading: false,
            mitra_list: null,
            metaMitraList: {
                limit: 10,
                page: 1,
                shift_id: null,
                fullname: null
            },
            worker_shift: [],
            select_all_worker_temp: false,
            workers_temp: [],
            select_all_worker: false,
            workers: [],
            button_assign_mitra_to_shift_loading: false,
            shift_options: null,
            form_move_worker_modal: {
                new_company_shift_id: null
            },
            selected_worker: null
        };
    },
    watch: {
        async metaWorkerList() {
            this.worker_list = await this.getMitraList(this.metaWorkerList);
        },
        async metaMitraList() {
            this.mitra_list = await this.getMitraList(this.metaMitraList);
            this.insertWorkerShiftData();
            this.findSelectedAllWorker();
        }
    },
    computed: {
        ...mapGetters({
            shift_detail: 'new_shift/shift_detail',
            shifts: 'new_shift/shifts'
        }),
    },
    beforeMount() {
        if (window.UIkit.modal('.add-worker-modal')) window.UIkit.modal('.add-worker-modal').$destroy(true);
        if (window.UIkit.modal('.move-worker-modal')) window.UIkit.modal('.move-worker-modal').$destroy(true);
        if (window.UIkit.modal('.delete-worker-modal')) window.UIkit.modal('.delete-worker-modal').$destroy(true);
    },
    async mounted() {
        this.isLoading = true;
        try {
            await this.getShiftDetail(this.$route.params.shift_id);
            this.worker_list = await this.getMitraList(this.metaWorkerList);
        } catch (error) {
            notificationDanger(error);
        }
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            getShiftDetail: 'new_shift/getShiftDetail',
            getMitraList: 'new_shift/getMitraList',
            getShiftListNoPaginate: 'new_shift/getShiftListNoPaginate',
            deleteMitraShift: 'new_shift/deleteMitraShift',
            moveMitraShift: 'new_shift/moveMitraShift',
            addMitraShift: 'new_shift/addMitraShift'
        }),
        goBack() {
            this.$router.push({ path: '/admin/shift', query: this.$route.query });
        },
        async showAddWorkerModal() {
            this.modalLoading = true;
            try {
                await window.UIkit.modal('#add-worker-modal').show();
                this.mitra_list = await this.getMitraList(this.metaMitraList);
                this.insertWorkerShiftData();
            } catch (error) {
                notificationDanger(error);
            }
            this.modalLoading = false;
        },
        async showMoveWorkerModal({ mitraId }) {
            this.modalLoading = true;
            try {
                await window.UIkit.modal('#move-worker-modal').show();
                this.shift_options = await this.getShiftListNoPaginate();
                this.selected_worker = mitraId;
            } catch (error) {
                notificationDanger(error);
            }
            this.modalLoading = false;
        },
        async showDeleteWorkerModal({ mitraId }) {
            try {
                await window.UIkit.modal('#delete-worker-modal').show();
                this.selected_worker = mitraId;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async cancelDeleteWorker() {
            try {
                await window.UIkit.modal('#delete-worker-modal').hide();
                this.selected_worker = null;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async searchMitraList() {
            this.mitra_list = await this.getMitraList(this.metaMitraList);
            this.findSelectedAllWorker();
        },
        insertWorkerShiftData() {
            return this.worker_shift = this.mitra_list.docs.filter((data) => data.shift_id === this.$route.params.shift_id);
        },
        findWorkerShiftData({ mitraId }) {
            return this.worker_shift.find((worker) => worker._id === mitraId);
        },
        disabledUncheckMitra({ shiftId }) {
            return shiftId === this.$route.params.shift_id;
        },
        findSelectedWorker({ mitraId }) {
            return this.workers.find((worker) => worker._id === mitraId);
        },
        selectAllWorker() {
            if (this.select_all_worker) {
                this.mitra_list.docs.forEach((data) => {
                    const findData = this.workers.find((worker) => worker._id === data._id && worker.shift_id !== this.$route.params.shift_id);
                    if (!findData) {
                        this.workers.push({
                            _id: data._id,
                            user_id: data.user_id,
                            shift_id: data.shift_id,
                            new: true
                        });
                    }
                });
            } else {
                this.mitra_list.docs.forEach((data) => {
                    this.workers = this.workers.filter((worker) => worker._id !== data._id);
                });
            }
        },
        selectWorker({ mitraId, userId, shiftId }) {
            this.select_all_worker = false;
            let selectedIds = [];
            const resultFindSameData = this.workers.find((item) => item._id === mitraId);
            selectedIds =
                this.workers.length > 0 ?
                    resultFindSameData ?
                        this.workers.filter((item) => item._id !== mitraId)
                        : [...this.workers, ...[{ _id: mitraId, user_id: userId, shift_id: shiftId, new: true }]]
                    : [{ _id: mitraId, user_id: userId, shift_id: shiftId, new: true }];
            this.workers = selectedIds;
        },
        findSelectedAllWorker() {
            const mitraIds = this.mitra_list.docs.map((data) => data._id);
            const workerIds = this.workers.map((worker) => worker._id);
            const result = mitraIds.filter((data) => workerIds.includes(data));
            if (result.length === this.mitra_list.docs.length) {
                this.select_all_worker = true;
            } else {
                this.select_all_worker = false;
            }
        },
        cancelSelectWorker() {
            this.select_all_worker = this.select_all_worker_temp;
            this.workers = this.workers_temp;
        },
        deselectAllWorker() {
            this.select_all_worker_temp = this.select_all_worker;
            this.workers_temp = this.workers;
            this.select_all_worker = false;
            this.workers = [];
        },
        async assignWorkerToShift() {
            this.button_assign_mitra_to_shift_loading = true;
            try {
                const users = this.workers.filter((worker) => worker.shift_id !== this.$route.params.shift_id);
                const payload = {
                    new_company_shift_id: this.$route.params.shift_id,
                    users
                };
                const response = await this.addMitraShift(payload);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await window.UIkit.modal('#add-worker-modal').hide();
                    notificationSuccess('Partner successfully added to shift!');
                    this.resetDataAddWorker();
                    this.workerListLoading = true;
                    this.resetMetaWorkerList();
                    this.worker_list = await this.getMitraList(this.metaWorkerList);
                    this.resetMetaMitraList();
                    this.mitra_list = await this.getMitraList(this.metaMitraList);
                    this.insertWorkerShiftData();
                } else {
                    notificationDangerCustom('Failed add partner to shift!');
                }
                this.workerListLoading = false;
            } catch (error) {
                notificationDanger(error);
            }
            this.button_assign_mitra_to_shift_loading = false;
        },
        async movedWorker() {
            this.button_assign_mitra_to_shift_loading = true;
            try {
                const payload = {
                    job_partner_id: this.selected_worker,
                    new_company_shift_id: this.form_move_worker_modal.new_company_shift_id,
                };
                const response = await this.moveMitraShift(payload);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await window.UIkit.modal('#move-worker-modal').hide();
                    this.selected_worker = null;
                    this.form_move_worker_modal.new_company_shift_id = null;
                    notificationSuccess('Workers are successfully moved');
                    this.workerListLoading = true;
                    this.resetMetaWorkerList();
                    this.worker_list = await this.getMitraList(this.metaWorkerList);
                    this.resetMetaMitraList();
                    this.mitra_list = await this.getMitraList(this.metaMitraList);
                    this.insertWorkerShiftData();
                } else {
                    notificationDangerCustom('Workers are failed moved');
                }
                this.workerListLoading = false;
            } catch (error) {
                notificationDanger(error);
            }
            this.button_assign_mitra_to_shift_loading = false;
        },
        async deletedWorker() {
            this.button_assign_mitra_to_shift_loading = true;
            try {
                const job_partner_id = this.selected_worker;
                const response = await this.deleteMitraShift(job_partner_id);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await window.UIkit.modal('#delete-worker-modal').hide();
                    this.selected_worker = null;
                    notificationSuccess('Workers are successfully deleted');
                    this.workerListLoading = true;
                    this.resetMetaWorkerList();
                    this.worker_list = await this.getMitraList(this.metaWorkerList);
                    this.resetMetaMitraList();
                    this.mitra_list = await this.getMitraList(this.metaMitraList);
                    this.insertWorkerShiftData();
                } else {
                    notificationDangerCustom('Workers are failed deleted');
                }
                this.workerListLoading = false;
            } catch (error) {
                notificationDanger(error);
            }
            this.button_assign_mitra_to_shift_loading = false;
        },
        resetDataAddWorker() {
            this.select_all_worker_temp = false;
            this.workers_temp = [];
            this.select_all_worker = false;
            this.workers = [];
        },
        resetMetaWorkerList() {
            this.worker_list = null;
            this.metaWorkerList = {
                limit: 10,
                page: 1,
                shift_id: this.$route.params.shift_id
            };
        },
        resetMetaMitraList() {
            this.mitra_list = null;
            this.metaMitraList = {
                limit: 10,
                page: 1,
                shift_id: null,
                fullname: null
            };
        },
        changeLimitWorkerList(e) {
            this.metaWorkerList = {
                ...this.metaWorkerList,
                limit: e.target.value
            };
        },
        changePageWorkerList(value) {
            this.metaWorkerList = {
                ...this.metaWorkerList,
                page: value
            };
        },
        changeLimitMitraList(e) {
            this.metaMitraList = {
                ...this.metaMitraList,
                limit: e.target.value
            };
        },
        changePageMitraList(value) {
            this.metaMitraList = {
                ...this.metaMitraList,
                page: value
            };
        },
        formatWorkDays({ data }) {
            const days = [];
            if (data.monday === true) {
                days.push('Senin');
            }
            if (data.tuesday === true) {
                days.push('Selasa');
            }
            if (data.wednesday === true) {
                days.push('Rabu');
            }
            if (data.thursday === true) {
                days.push('Kamis');
            }
            if (data.friday === true) {
                days.push('Jumat');
            }
            if (data.saturday === true) {
                days.push('Sabtu');
            }
            if (data.sunday === true) {
                days.push('Minggu');
            }
            return days.length > 0 ? days : '-';
        },
        formatHourMinute({ data }) {
            if (!data) {
                return '--:--';
            }
            return formatter.hourMinuteOnly(data);
        },
        formatDuration({ start_shift, end_shift }) {
            if (!start_shift) {
                return '-';
            } else {
                const start = moment.utc(start_shift);
                const end = (start_shift.hour > end_shift.hour) == true
                    ? moment.utc(end_shift).add(1, 'days')
                    : moment.utc(end_shift);
                const duration = moment.duration(end.diff(start));
                const hours = Math.round(duration.asHours(), 2);
                return hours;
            }
        },
        imgError(e) {
            e.target.src = `${this.images}/avatar.svg`;
        }
    }
};
</script>

<style scoped>
    .myrobin-button-primary {
        border: 1px solid #0275D8;
        color: #0275D8;
        background-color: white;
    }

    thead {
        background: #0ABAB5;
    }

    th {
        color: #FFFFFF;
    }
</style>
