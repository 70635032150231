<template>
    <section>
        <div
            id="create-user-modal"
            class="uk-flex-top create-user-modal"
            uk-modal
            esc-close="false"
            bg-close="false"
        >
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="close"
                />
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Create New User</h2>
                </div>
                <div class="uk-padding uk-grid-small" uk-grid>
                    <div class="uk-width-1-1">
                        <label class="uk-form-label">Office</label>
                        <multiselect
                            v-model="company_office"
                            placeholder="Select office"
                            name="company_office"
                            label="name"
                            track-by="name"
                            :searchable="false"
                            :options="officeOptions"
                            :loading='isOfficeFetching'
                            :internal-search="false"
                            :show-no-results="false"
                            @select="getRoleOptions"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <label class="uk-form-label">Job role</label>
                        <multiselect
                            v-model="role_name"
                            placeholder="Select job role"
                            name="role_name"
                            :searchable="false"
                            :options="roleOptions"
                            :loading='isRoleFetching'
                            :internal-search="false"
                            :show-no-results="false"
                            @select="getJobOptions"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <label class="uk-form-label">Vacancy</label>
                        <multiselect
                            v-model="job"
                            placeholder="Select job vacancy"
                            name="job"
                            label="title"
                            track-by="_id"
                            :searchable="false"
                            :options="jobOptions"
                            :loading='isJobFetching'
                            :internal-search="false"
                            :show-no-results="false"
                            @select="selectJob"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <label for="fullname" class="uk-form-label">Full Name<span class="uk-text-danger">*</span></label>
                        <input
                            id="fullname"
                            name="fullname"
                            type="text"
                            class="uk-input uk-border-rounded"
                            :class="{'uk-form-danger': errors.has('fullname')}"
                            v-model="formData.fullname"
                            v-validate="'required'"
                            required
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</span>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="email" class="uk-form-label">Email<span class="uk-text-danger">*</span></label>
                        <input
                            id="email"
                            name="email"
                            type="text"
                            class="uk-input uk-border-rounded"
                            :class="{'uk-form-danger': errors.has('email')}"
                            v-model="formData.email"
                            v-validate="'required|email'"
                            required
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('email')">{{ errors.first('email') }}</span>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="phone_number" class="uk-form-label">Phone Number</label>
                        <input
                            id="phone_number"
                            name="phone_number"
                            type="text"
                            class="uk-input uk-border-rounded"
                            :class="{'uk-form-danger': errors.has('phone_number')}"
                            v-model="formData.phone_number"
                            v-validate="'numeric'"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('phone_number')">{{ errors.first('phone_number') }}</span>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="password" class="uk-form-label">Password<span class="uk-text-danger">*</span></label>
                        <input
                            id="password"
                            name="password"
                            type="text"
                            class="uk-input uk-border-rounded"
                            :class="{'uk-form-danger': errors.has('password')}"
                            v-model="formData.password"
                            v-validate="'required'"
                            required
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('password')">{{ errors.first('password') }}</span>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="city" class="uk-form-label">City</label>
                        <multiselect
                            v-model="formData.domicile_city_id"
                            placeholder="Type to search city"
                            label="name"
                            name="city"
                            track-by="name"
                            :searchable="true"
                            :options="cityOptions"
                            :loading='isCityFetching'
                            :internal-search="false"
                            :clear-on-select="false"
                            :show-no-results="false"
                            @search-change="getCityOptions"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <label for="address" class="uk-form-label">Address</label>
                        <textarea
                            id="address"
                            name="address"
                            rows="5"
                            minlength="70"
                            maxlength="320"
                            class="uk-textarea uk-border-rounded"
                            v-model="formData.address"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <label for="identity_number" class="uk-form-label">KTP Number<span class="uk-text-danger">*</span></label>
                        <input
                            id="identity_number"
                            name="identity_number"
                            type="text"
                            class="uk-input uk-border-rounded"
                            :class="{'uk-form-danger': errors.has('identity_number')}"
                            v-model="formData.identity_number"
                            v-validate="'required|numeric|length:16'"
                            required
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('identity_number')">{{ errors.first('identity_number') }}</span>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="birthplace_city_id" class="uk-form-label">Place of Birth</label>
                        <multiselect
                            v-model="formData.birthplace_city_id"
                            placeholder="Type to search city"
                            label="name"
                            name="birthplace_city_id"
                            track-by="name"
                            :searchable="true"
                            :options="cityOptions"
                            :loading='isCityFetching'
                            :internal-search="false"
                            :clear-on-select="false"
                            :show-no-results="false"
                            @search-change="getCityOptions"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <div class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove" uk-grid>
                            <div class="uk-width-1-2 uk-margin-remove uk-padding-remove-left">
                                <div class="uk-inline uk-width-expand">
                                    <label for="birthdate" class="uk-form-label">Date of Birth</label>
                                    <datetime
                                        id="birthdate"
                                        name="birthdate"
                                        input-class="uk-input uk-border-rounded"
                                        type="date"
                                        v-model="formData.birthdate"
                                        :max-datetime="new Date().toISOString()"
                                        value-zone="local"
                                        placeholder="Birthdate"
                                    />
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar" style="margin-top: 22px;" />
                                </div>
                            </div>
                            <div class="uk-width-1-2 uk-margin-remove">
                                <div class="uk-inline uk-width-expand">
                                    <label for="gender" class="uk-form-label">Gender</label>
                                    <select
                                        id="gender"
                                        name="gender"
                                        class="uk-select uk-border-rounded"
                                        v-model="formData.gender"
                                    >
                                        <option v-for="(item, index) in genderOptions" :key="index" :value="item.value">{{ item.label || '-' }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1">
                        <div class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove" uk-grid>
                            <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                                <div class="uk-inline uk-width-expand">
                                    <label for="job_start" class="uk-form-label">Start Date<span class="uk-text-danger">*</span></label>
                                    <datetime
                                        id="job_start"
                                        name="job_start"
                                        :input-class="errors.has('job_start') ? 'uk-form-danger uk-input uk-border-rounded' : 'uk-input uk-border-rounded'"
                                        type="date"
                                        v-model="formData.job_start"
                                        placeholder="Start date"
                                        :min-datetime="new Date().toISOString()"
                                        value-zone="local"
                                        v-validate="'required'"
                                        required
                                    />
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar" style="margin-top: 22px;" />
                                </div>
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('job_start')">{{ errors.first('job_start') }}</span>
                            </div>
                            <div class="uk-width-1-3 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-center">
                                <span class="uk-text-bold"> - </span>
                            </div>
                            <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                                <div class="uk-inline uk-width-expand">
                                    <label for="job_end" class="uk-form-label">End Date<span class="uk-text-danger">*</span></label>
                                    <datetime
                                        id="job_end"
                                        name="job_end"
                                        :input-class="errors.has('job_end') ? 'uk-form-danger uk-input uk-border-rounded' : 'uk-input uk-border-rounded'"
                                        type="date"
                                        v-model="formData.job_end"
                                        placeholder="End date"
                                        :min-datetime="formData.job_start"
                                        value-zone="local"
                                        v-validate="'required'"
                                        required
                                    />
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar" style="margin-top: 22px;" />
                                </div>
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('job_end')">{{ errors.first('job_end') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer">
                    <button
                        class="uk-button uk-button-primary uk-text-bold uk-border-rounded uk-float-right"
                        :disabled="isLoading"
                        @click="save"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import { Datetime } from 'vue-datetime';
import {
    notificationSuccess,
    notificationDangerCustom,
} from "@/utils/notification";

export default {
    props: {
        company_offices: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Multiselect,
        Datetime
    },
    data() {
        return {
            company_office: null,
            role_name: null,
            job: null,
            formData: {
                job_id: null,
                fullname: null,
                email: null,
                phone_number: null,
                password: null,
                domicile_city_id: null,
                address: null,
                identity_number: null,
                birthplace_city_id: null,
                birthdate: null,
                gender: null,
                job_start: null,
                job_end: null,
            },
            officeOptions: this.company_offices,
            cityOptions: [],
            roleOptions: [],
            jobOptions: [],
            genderOptions: [
                { label: 'Pria', value: 'male' },
                { label: 'Wanita', value: 'female' },
                { label: 'Pria / Wanita', value: null },
            ],
            isLoading: false,
            isOfficeFetching: false,
            isRoleFetching: false,
            isJobFetching: false,
            isCityFetching: false,
        };
    },
    mounted() {
        window.UIkit.modal("#create-user-modal").show();
    },
    methods: {
        ...mapActions({
            postManualInputOnboarding: "mitra/postManualInputOnboarding",
            getCompanyInitiationList: "initiation/getCompanyInitiationList",
            getCities: 'option_data/getCities',
        }),
        async getRoleOptions(option){
            this.isRoleFetching = true;
            this.role_name = null;
            this.job = null;
            this.formData.job_id = null;
            const initiationList = await this.getCompanyInitiationList({ company_office_id: option._id });
            this.roleOptions = [...new Set(initiationList.map(data => data.role.name))];
            this.isRoleFetching = false;
        },
        async getJobOptions(option){
            this.isJobFetching = true;
            this.job = null;
            this.formData.job_id = null;
            const initiationList = await this.getCompanyInitiationList({ company_office_id: this.company_office._id, role_name: option });

            this.jobOptions = initiationList.reduce((jobList, initiation) => {
                if (initiation.role) {
                    return [...jobList, initiation.job_id];
                }
                return jobList;
            }, []);

            this.isJobFetching = false;
        },
        async getCityOptions(option){
            this.isCityFetching = true;
            this.cityOptions = await this.getCities({ name: option });
            this.isCityFetching = false;
        },
        selectJob(option) {
            this.formData.job_id = option._id;
        },
        close() {
            window.UIkit.modal("#create-user-modal").$destroy(true);
            this.$emit("close");
        },
        async save() {
            this.isLoading = true;

            const validate = await this.$validator.validateAll();
            if (!validate || this.$validator.errors.any()) {
                this.isLoading = false;
                return;
            }
            const birthplace_city_id = this.formData.birthplace_city_id ? this.formData.birthplace_city_id._id : null;
            const domicile_city_id = this.formData.domicile_city_id ? this.formData.domicile_city_id._id : null;
            const formData = { ...this.formData, birthplace_city_id, domicile_city_id };

            const response = await this.postManualInputOnboarding(formData);

            if (response && response.status === "OK") {
                notificationSuccess("Create new user success!");
                this.$emit("success");
                this.close();
            } else {
                notificationDangerCustom("Failed to create new user!");
            }

            this.isLoading = false;
        },
    },
};
</script>
