<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <button class="uk-button uk-button-default" style="border-radius: 5px;" @click="back">
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            <span>Back</span>
        </button>
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else-if="!isLoading && cvDetail !== null">
            <div class="uk-margin-top">
                <div class="uk-flex uk-flex-between uk-flex-middle">
                    <p class="uk-margin-remove uk-text-lead">{{ cvDetail.name }}</p>
                    <div class="uk-flex" style="gap: 10px;">
                        <button
                            class="uk-button uk-button-primary"
                            style="border-radius: 5px;"
                            @click="downloadCV"
                        >
                            Download CV
                        </button>
                    </div>
                </div>

                <div v-if="getLastOtherJobApplicant !== null" class="uk-margin-medium-top uk-flex uk-flex-between uk-flex-middle" style="gap: 10px;">
                    <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center" style="gap: 10px;">
                        <p class="uk-margin-remove">Position</p>
                        <p class="uk-margin-remove uk-text-bold">{{ getLastOtherJobApplicant.role_name || '-' }}</p>
                    </div>
                    <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center" style="gap: 10px;">
                        <p class="uk-margin-remove">Status</p>
                        <p class="uk-margin-remove uk-text-bold">{{ listStatus(getLastOtherJobApplicant.status) || '-' }}</p>
                    </div>
                    <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center" style="gap: 10px;">
                        <p class="uk-margin-remove">Interview Date</p>
                        <p class="uk-margin-remove uk-text-bold">{{ formaterDatetime(getLastOtherJobApplicant.interview_datetime) || '-' }}</p> 
                    </div>
                    <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center" style="gap: 10px;">
                        <p class="uk-margin-remove">Interview Score</p>
                        <p class="uk-margin-remove uk-text-bold">{{ getLastOtherJobApplicant.interview_score || '-' }}</p> 
                    </div>
                    <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center" style="gap: 10px;">
                        <p class="uk-margin-remove">Interview Notes</p>
                        <p class="uk-margin-remove uk-text-bold">{{ getLastOtherJobApplicant.interview_notes || '-' }}</p> 
                    </div>
                </div>
                <hr class="uk-margin-medium-top uk-margin-remove-bottom">
                <div>
                    <ul uk-tab>
                        <li class="uk-active"><a href="#" class="uk-button">CANDIDATE'S CV</a></li>
                        <li><a href="#" class="uk-button">OTHER JOB APPLICATION'S</a></li>
                    </ul>

                    <div class="uk-switcher uk-margin-medium-top">
                        <!-- Tab 1 -->
                        <div>
                            <!-- LINE 1 -->
                            <div class="uk-flex uk-flex-stretch" style="gap: 10px;">
                                <div class="uk-flex uk-width-1-3">
                                    <img :src="`${images}/call-black.svg`">
                                    <span class="uk-margin-small-left">{{ cvDetail.phone_number || '-' }}</span>
                                </div>
                                <div class="uk-flex uk-width-1-3">
                                    <img :src="`${images}/email.svg`">
                                    <span class="uk-margin-small-left">{{ cvDetail.email || '-' }}</span>
                                </div>
                            </div>

                            <div class="uk-margin-medium-top uk-flex uk-flex-stretch" style="gap: 10px;">
                                <div class="uk-flex uk-width-1-3">
                                    <img :src="`${images}/place.svg`">
                                    <span class="uk-margin-small-left" v-if="!cvDetail.domicile">-</span>
                                    <span class="uk-margin-small-left" v-else>{{ cvDetail.domicile.name }}</span>
                                </div>
                            </div>
                            <!-- LINE 1 -->

                            <hr class="uk-margin-medium-top">

                            <!-- LINE 2 -->
                            <div class="uk-flex uk-flex-stretch uk-margin-medium-top">
                                <div class="uk-flex uk-flex-middle uk-width-1-1">
                                    <img :src="`${images}/person.svg`">
                                    <span class="uk-margin-left uk-text-bold uk-text-muted">Candidate Summary</span>
                                </div>
                            </div>
                            <div class="uk-margin-top uk-flex list-candidate">
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-meta uk-text-bold">Latest Education</span>
                                    </div>
                                    <div class="uk-margin-small-top">
                                        <div v-if="getLastEducation">
                                            <p class="uk-text-small uk-text-bold uk-margin-remove">{{ getLastEducation.institution_name }}</p>
                                            <p class="uk-text-small uk-margin-remove">{{ getLastEducation.majors }}</p>
                                        </div>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-meta uk-text-bold">Last Job</span>
                                    </div>
                                    <div class="uk-margin-small-top">
                                        <div v-if="getLastWorkExperience">
                                            <p class="uk-text-small uk-text-bold uk-margin-remove">{{ getLastWorkExperience.company_name }}</p>
                                            <p class="uk-text-small uk-margin-remove">{{ getLastWorkExperience.title }}</p>
                                        </div>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-margin-top uk-flex list-candidate">
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-meta uk-text-bold">User Since</span>
                                    </div>
                                    <div>
                                        <span>{{ formatDate(cvDetail.created_at) }}</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-meta uk-text-bold">Years of Professional Experience</span>
                                    </div>
                                    <div>
                                        <span v-if="getLastWorkExperience">{{ formateDiffYear(getLastWorkExperience.start_date, getLastWorkExperience.end_date) }}</span>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                            </div>
                            <!-- LINE 2 -->

                            <hr class="uk-margin-medium-top">

                            <!-- LINE 3 -->
                            <div class="uk-flex uk-flex-stretch uk-margin-medium-top">
                                <div class="uk-flex uk-flex-middle uk-width-1-1">
                                    <img :src="`${images}/verified.svg`">
                                    <span class="uk-margin-left uk-text-bold uk-text-muted">Qualification</span>
                                </div>
                            </div>
                            <div class="uk-margin-top uk-flex list-candidate">
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div v-if="cvDetail.cv">
                                        <div>
                                            <span class="uk-text-muted uk-text-bold">Form Identification</span>
                                        </div>
                                        <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-small-top">
                                            <div class="uk-width-1-2 uk-text-nowrap">
                                                <span>SIM</span>
                                            </div>
                                            <div v-if="cvDetail.cv.sim" class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/check-circle.svg`" class="img-icon">
                                                <span class="uk-margin-small-left">Yes</span>
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-blue"
                                                    @click="openImage({file_url: cvDetail.cv.sim.document_url.file_url})">
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button> 
                                            </div>
                                            <div v-else class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-gray" disabled>
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button>
                                            </div>
                                        </div>
                                        <hr class="uk-margin-small-top uk-margin-remove-bottom">
                                        <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-small-top">
                                            <div class="uk-width-1-2 uk-text-nowrap">
                                                <span>KTP</span>
                                            </div>
                                            <div v-if="cvDetail.cv.ktp" class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/check-circle.svg`" class="img-icon">
                                                <span class="uk-margin-small-left">Yes</span>
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-blue"
                                                    @click="openImage({file_url: cvDetail.cv.ktp.document_url.file_url})">
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button> 
                                            </div>
                                            <div v-else class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-gray" disabled>
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button>
                                            </div>
                                        </div>
                                        <hr class="uk-margin-small-top uk-margin-remove-bottom">
                                        <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-small-top">
                                            <div class="uk-width-1-2 uk-text-nowrap">
                                                <span>Ijazah</span>
                                            </div>
                                            <div v-if="cvDetail.cv.ijazah" class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/check-circle.svg`" class="img-icon">
                                                <span class="uk-margin-small-left">Yes</span>
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-blue"
                                                    @click="openImage({file_url: cvDetail.cv.ijazah.document_url.file_url})">
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button> 
                                            </div>
                                            <div v-else class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-gray" disabled>
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button>
                                            </div>
                                        </div>
                                        <hr class="uk-margin-small-top uk-margin-remove-bottom">
                                        <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-small-top">
                                            <div class="uk-width-1-2 uk-text-nowrap">
                                                <span>SKCK</span>
                                            </div>
                                            <div v-if="cvDetail.cv.skck" class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/check-circle.svg`" class="img-icon">
                                                <span class="uk-margin-small-left">Yes</span>
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-blue"
                                                    @click="openImage({file_url: cvDetail.cv.skck.document_url.file_url})">
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button> 
                                            </div>
                                            <div v-else class="uk-flex uk-flex-middle">
                                                <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                                <button class="uk-margin-small-left uk-flex uk-flex-middle uk-flex-between filter-gray" disabled>
                                                    <img :src="`${images}/visibility.svg`" class="View icon">
                                                    <p class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-left">View</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span>-</span>
                                    </div>
                                </div>
                            </div>
                            <!-- LINE 3 -->

                            <hr class="uk-margin-medium-top">

                            <!-- LINE 4 -->
                            <div class="uk-flex uk-flex-stretch uk-margin-medium-top">
                                <div class="uk-flex uk-flex-middle uk-width-1-1">
                                    <img :src="`${images}/education.svg`">
                                    <span class="uk-margin-left uk-text-bold uk-text-muted">Education</span>
                                </div>
                            </div>
                            <div class="uk-margin-top list-candidate">
                                <div v-if="cvDetail.cv">
                                    <div v-if="cvDetail.cv.educations.length > 0" class="uk-flex uk-flex-column" style="gap: 10px;">
                                        <div  
                                            v-for="(education, index) in cvDetail.cv.educations"
                                            :key="index"
                                            class="uk-flex uk-flex-middle" 
                                        >
                                            <div 
                                                class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right"
                                            >
                                                <div>
                                                    <span class="uk-text-small uk-text-bold">{{ education.institution_name || '-' }}</span><br>
                                                    <span class="uk-text-small">{{ education.majors || '-' }}</span>
                                                </div>
                                            </div>
                                            <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                                <div>
                                                    <span class="uk-text-default">{{ formaterMonthYear(education.start_date) }} - {{ formaterMonthYear(education.end_date) }}</span><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span v-else>-</span>
                            </div>
                            <!-- LINE 4 -->

                            <hr class="uk-margin-medium-top">

                            <!-- LINE 5 -->
                            <div class="uk-flex uk-flex-stretch uk-margin-medium-top">
                                <div class="uk-flex uk-flex-middle uk-width-1-1">
                                    <img :src="`${images}/experience.svg`">
                                    <span class="uk-margin-left uk-text-bold uk-text-muted">Experience</span>
                                </div>
                            </div>
                            <div class="uk-margin-top list-candidate">
                                <div v-if="cvDetail.cv">
                                    <div v-if="cvDetail.cv.work_experiences.length > 0" class="uk-flex uk-flex-column" style="gap: 10px;">
                                        <div
                                            v-for="(experience, index) in cvDetail.cv.work_experiences"
                                            :key="index"
                                            class="uk-flex uk-flex-middle"
                                        >
                                            <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                                <div>
                                                    <span class="uk-text-small uk-text-bold">{{ experience.company_name || '-' }}</span><br>
                                                    <span class="uk-text-small">{{ experience.title || '-' }}</span>
                                                </div>
                                            </div>
                                            <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                                <div>
                                                    <span class="uk-text-default">{{ formaterMonthYear(experience.start_date) }} - {{ formaterMonthYear(experience.end_date) }}</span><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span v-else>-</span>
                                </div>
                                <span v-else>-</span>
                            </div>
                            <!-- LINE 5 -->
                        </div>
                        <!-- Tab 1 -->

                        <!-- Tab 2 -->
                        <div>
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-small uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Job Title</th>
                                            <th>Company</th>
                                            <th>Location</th>
                                            <th>Test Score</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(other_job, index) in other_job_aplication" :key="index">
                                            <!-- job title -->
                                            <td class="uk-text-nowrap">
                                                {{ other_job.job.title }}
                                            </td>
                                            <!-- company -->
                                            <td class="uk-text-nowrap">{{ other_job.company ? other_job.company.name : '-' }}</td>
                                            <!-- location -->
                                            <td class="uk-text-truncate">{{ other_job.company.address }}</td>
                                            <!-- test score -->
                                            <td v-if="other_job.all_user_scores === null">-</td>
                                            <td v-else class="uk-text-nowrap">
                                                <p v-for="(test, index) in other_job.all_user_scores" :key="index">{{ test.test_category_id.name }} : {{ test.score }}</p>
                                            </td>
                                            <!-- status -->
                                            <td class="uk-text-nowrap">
                                                {{ listStatus(other_job.status) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Tab 2 -->
                    </div>
                </div>
            </div>
        </div>
        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
        <PreviewPDFModal
            v-if="modal.isShowPreviewPDFModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewPDFModal = false"
        />
        <PreviewOfficeModal
            v-if="modal.isShowPreviewOfficeModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewOfficeModal = false"
        />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import formatter from "@/utils/formatter";
import api from '@/utils/api';
import { PREFIX_IMAGE } from '@/utils/constant';
import { JOB_APPLICANT } from '@/utils/api-url';
import { notificationDanger } from '@/utils/notification';
import PreviewImageModal from '@/components/globals/modals/ImageViewer.vue';
import PreviewPDFModal from '@/components/globals/modals/PdfViewer.vue';
import PreviewOfficeModal from '@/components/globals/modals/OfficeViewer.vue';
import { contentType } from '@/utils/helper';

export default {
    name: 'CvDetailPage',
    components: {
        PreviewPDFModal,
        PreviewImageModal,
        PreviewOfficeModal,
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            cvDetail: null,
            other_job_aplication: null,
            previewSrc: "",
            modal: {
                isShowPreviewImageModal: false,
                isShowPreviewPDFModal: false,
                isShowPreviewOfficeModal: false,
            },
        };
    },
    async mounted() {
        await this.getCandidateDetail();
    },
    computed: {
        getLastEducation() {
            return this.cvDetail.cv ? this.cvDetail.cv.educations.length > 0 ? this.cvDetail.cv.educations[this.cvDetail.cv.educations.length - 1] : null : null;
        },
        getLastWorkExperience() {
            return this.cvDetail.cv ? this.cvDetail.cv.work_experiences.length > 0 ? this.cvDetail.cv.work_experiences[this.cvDetail.cv.work_experiences.length - 1] : null : null;
        },
        getLastOtherJobApplicant() {
            return this.other_job_aplication ? this.other_job_aplication.length > 0 ? this.other_job_aplication[this.other_job_aplication.length - 1] : null : null; 
        }
    },
    methods: {
        ...mapActions({
            getExMitraCVDetail: "worker/getExMitraCVDetail",
        }),
        async getCandidateDetail() {
            this.isLoading = true;
            try {
                this.cvDetail = await this.getExMitraCVDetail(this.$route.params.id);
                const other_job_aplication = await api.apiGetAuth(JOB_APPLICANT.DETAIL_OTHER_JOB(this.cvDetail.user_id));
                this.other_job_aplication = other_job_aplication.data.result;
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
                notificationDanger(err);
            }
        },
        back() {
            this.$router.back();
        },
        formatDate(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateCompleteNew(data);
        },
        formateDiffYear(start, end) {
            const result = formatter.diffYears(start, end);
            if (result < 0) {
                return 'Less than one year';
            } else {
                return `${result} year`;
            }
        },
        formaterMonthYear(date) {
            if (date === null) {
                return "-";
            }
            return formatter.getMonthYear(date);
        },
        formaterDatetime(date) {
            if (date === null) {
                return "-";
            }
            return formatter.datetimeShortNew(date);
        },
        async downloadCV() {
            try {
                const result = await api.apiGetAuth(JOB_APPLICANT.DOWNLOAD_CV_APPLICANT(this.cvDetail.user_id));
                window.open(result.data.result.cv_path.file_url, '_blank');
            } catch (err) {
                notificationDanger(err);
            }
        },
        openImage(data) {
            this.previewSrc = data.file_url;
            this.modal[contentType(this.previewSrc)] = true;
        },
        listStatus(val) {
            switch (val) {
            case 0:
                return 'New Applicant';
            case 1:
                return 'Picked Up Applicant';
            case 3:
                return 'Reject Applicant';
            case 4:
                return 'Invite to Interview';
            case 5:
                return 'Interviewed Applicant';
            case 6:
                return 'Contract Sent';
            case 7:
                return 'Signed Applicant';
            }
        },
    }
};
</script>

<style scoped>
.list-candidate {
    margin-left: 45px;
}
.robin-btn {
    background-color: #0ABAB5;
    color: #fff !important;
}
.myrobin-background-primary {
    background-color: #0ABAB5;
}

.myrobin-background-secondary {
    background-color: #0275D8;
}

.myrobin-color-white {
    color: white;
}

.list-candidate {
    margin-left: 36px;
}

.filter-blue {
    color: #0275D8;
    filter: invert(33%) sepia(89%) saturate(3239%) hue-rotate(192deg) brightness(91%) contrast(98%)
}

.filter-gray {
    color: #979797;
    filter: invert(75%) sepia(0%) saturate(267%) hue-rotate(144deg) brightness(80%) contrast(92%);
}
</style>
