import Vue from 'vue';
import api from '@/utils/api';
import { PROFILE } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    bell_counter: 0,
    sidebar_counter: [
        {
            name: 'Reimbursement',
            counter: 0
        }
    ]
};

const mutations = {
    "SET_BELL_COUNTER": (state, payload) => {
        Vue.set(state, 'bell_counter', payload);
    },
    "SET_REIMBURSEMENT_COUNTER": (state, payload) => {
        const index = state.sidebar_counter.findIndex((item) => item.name === "Reimbursement");
        Vue.set(state.sidebar_counter, index, {name: 'Reimbursement', counter: payload});
    }
};

const actions = {
    async getNotifCounter(context, data) {
        try {
            const response = await api.apiGetAuth(PROFILE.COUNTER, data);
            context.commit('SET_BELL_COUNTER', response.data.result.unread_notif);
            context.commit('SET_REIMBURSEMENT_COUNTER', response.data.result.reimbursement_notif);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async subtractReimbursementCounter(context) {
        try {           
            context.commit('SET_REIMBURSEMENT_COUNTER', state.sidebar_counter[state.sidebar_counter.findIndex((item) => item.name === "Reimbursement")].counter - 1);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async clearNotifCounter(context) {
        try {
            context.commit('SET_BELL_COUNTER', 0);
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    bell_counter: state => {
        return state.bell_counter;
    },
    sidebar_counter: state => {
        return state.sidebar_counter;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
