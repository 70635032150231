<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div v-if="pageLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else-if="user">
            <div class="uk-flex uk-flex-between uk-flex-top">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-margin">
                    <div>
                        <img v-if="!user.photo_url" :data-src="`${images}/avatar.svg`" alt="Avatar Icon" uk-img class="photo">
                        <img v-else :data-src="user.photo_url.file_url" @error="(e) => imgError(e)" alt="Avatar Icon" uk-img class="photo">
                    </div>
                    <div class="uk-margin-medium-left" style="color: black">
                        <p class="uk-margin-remove uk-text-large uk-text-bold">{{ user.fullname }}</p>
                        <p class="uk-margin-remove">Job Role: {{ user.role }}</p>
                        <p class="uk-margin-remove">Company: {{ user.company_name }}</p>
                        <p class="uk-margin-remove">Office: {{ user.office_name }}</p>
                    </div>
                </div>
                <button @click="$router.push($store.state.routeBackFull)">
                    <img :src="`${images}/cross.svg`" alt="close icon" width="25" heigth="25" style="cursor: pointer;">
                </button>
            </div>
            <div class="uk-margin">
                <p class="uk-text-large">Leave history of <span class="uk-text-bold">{{ user.fullname }}</span></p>
            </div>
            <div class="uk-flex uk-child-width-expand@s uk-margin">
                <div class="location-header">
                    <img :src="`${images}/mi_filter.svg`" alt="" width="20"> {{ user.office_location }}, {{ user.office_name }}
                </div>
                <div class="uk-flex" style="gap: 15px">
                    <form @change="getMitraWithShiftList">
                        <select name="category" id="category" class="uk-select uk-text-small" v-model="meta.category">
                            <option value="">All Leave Type</option>
                            <option value=0>Unpaid</option>
                            <option value=1>Paid</option>
                        </select>
                    </form>
                    <form @change="getMitraWithShiftList">
                        <select name="sort" id="sort" class="uk-select uk-text-small" v-model="meta.sort_by">
                            <option value="" selected>Latest</option>
                            <option value="old" selected>Oldest</option>
                        </select>
                    </form>
                </div>
            </div>
        </div>

        <!-- start table body -->
        <div v-if="leave_list" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table v-if="$can('READ', 'worker-leave-report')" class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand uk-text-nowrap">Request Date</th>
                            <th class="uk-table-expand uk-text-nowrap">Leave Date</th>
                            <th class="uk-table-expand">Office</th>
                            <th class="uk-text-nowrap">Leave Type</th>
                            <th class="uk-text-nowrap">Leave Name</th>
                            <th class="uk-text-center">Duration (days)</th>
                            <th class="uk-text-center">Duration (hours)</th>
                            <th class="uk-table-expand">Notes</th>
                            <th class="uk-table-shrink">Document</th>
                            <th class="uk-text-center">Status</th>
                            <th class="uk-text-center">Approval/<br>Rejection Date</th>
                            <th class="uk-text-center uk-text-nowrap">Approved/<br>Rejected By</th>
                            <th class="uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="11"/>
                    <tbody v-if="leave_list.docs.length > 0">
                        <tr v-for="leave in leave_list.docs" :key="leave.id">
                            <td>{{ formatDate(leave.request_date) || '-' }}</td>
                            <td>{{ formatDate(leave.leave_date) || '-' }}</td>
                            <td>{{ leave.office_name }}</td>
                            <td>{{ leave.leave_category ? "Paid" : "Unpaid" }}</td>
                            <td>{{ leave.leave_name }}</td>
                            <td class="uk-text-center">{{ leave.duration_days ? leave.duration_days : "-" }}</td>
                            <td class="uk-text-center">{{ leave.duration_hours ? leave.duration_hours : "-" }}</td>
                            <td>{{ leave.reason }}</td>
                            <td class="uk-text-center">
                                <a
                                    v-if="leave.document_url"
                                    href="javascript:void(0)"
                                    @click="viewLeaveDoc(leave.document_url)"
                                    rel="noopener noreferrer"
                                >View</a>
                                <div v-else>No document</div>
                            </td>
                            <td class="uk-text-center">{{ statusConvert(leave.status) }}</td>
                            <td class="uk-table-expand uk-text-center">{{ leave.status ? formatDate(leave.decision_date) : '-' }}</td>
                            <td class="uk-table-expand uk-text-center">{{ leave.decision_by }}</td>
                            <td class="uk-flex" style="gap: 10px">
                                <button
                                    v-if="$can('EDIT', 'worker-leave-report')"
                                    class="uk-button"
                                    style="border-radius: 5px; width: 102px; color: white; cursor: pointer;"
                                    :style="[leave.status == 0 ? {'background-color': '#0275D8'} : {'background-color': '#BDBDBD'}]"
                                    :disabled="leave.status !== 0"
                                    @click="requestAction(leave, 'approve')"
                                >Accept</button>
                                <button
                                    v-if="$can('EDIT', 'worker-leave-report')"
                                    class="uk-button"
                                    style="border-radius: 5px; width: 102px; color: white; cursor: pointer;"
                                    :style="[leave.status == 0 ? {'background-color': '#D8020F'} : {'background-color': '#BDBDBD'}]"
                                    :disabled="leave.status !== 0"
                                    @click="requestAction(leave, 'reject')"
                                >Reject</button>
                                <button
                                    v-if="$can('DELETE', 'worker-leave-report')"
                                    class="uk-button"
                                    :style="{ backgroundColor: isHold ? '#DDD8D8' : '#ffffff', color: isHold ? '#888' : '#D8020F', border: isHold ? '' : '2px solid #D8020F' }"
                                    style="border-radius: 5px; width: 102px; backgroundColor: #ffffff; color: #D8020F; border: 2px solid #D8020F; cursor: pointer;"
                                    @click="requestAction(leave, 'delete')"
                                >Delete</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="11"/>
                </table>
            </div>
        </div>
        <pagination
            v-if="leave_list"
            :total-data="leave_list.totalDocs"
            :limit-page="meta.limit"
            :change-limit="changeLimit"
            :change-page="changePage"
        />
        <!-- end table body -->

        <!-- Approve Leave Req Modal -->
        <div id="approve-modal" class="uk-flex-top filter-data" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('approve-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center uk-text-medium">Approve Leave Request</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-block uk-child-width-1-2 uk-padding-remove-vertical" uk-grid>
                        <span>Leave Type</span>
                        <span>{{ selected_req.leave_category ? "Paid" : "Unpaid" }}</span>
                    </div>
                    <div class="uk-block uk-child-width-1-2 uk-padding-remove-vertical" uk-grid>
                        <span>Leave Name</span>
                        <span>{{ selected_req.leave_name }}</span>
                    </div>
                    <div class="uk-block uk-child-width-1-2 uk-padding-remove-vertical" uk-grid>
                        <span>Duration</span>
                        <span>{{ selected_req.duration_days ? selected_req.duration_days + " day" : selected_req.duration_hours + " hours" }}</span>
                    </div>
                </div>
                <form @submit.prevent="approveRejectLeaveReq(1)">
                    <div class="uk-padding-small uk-padding-remove-bottom">
                        <section class="uk-margin">
                            <label for="approveAmount" class="uk-form-label">Approved Amount</label>
                            <select
                                id="approveAmount"
                                name="approveAmount"
                                class="uk-select select-input"
                            >
                                <option selected value="">Full</option>
                            </select>
                        </section>
                        <section class="uk-margin">
                            <label for="approveNotes" class="uk-form-label">Notes <b class="uk-text-danger">*</b></label>
                            <textarea
                                id="approveNotes"
                                name="approveNotes"
                                rows="3"
                                class="uk-textarea"
                                :class="{'uk-form-danger': errors.has('desc')}"
                                v-model="notes"
                                v-validate="'required'"
                            ></textarea>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('desc')">{{ errors.first('desc') }}</span>
                        </section>
                    </div>
                    <hr>
                    <section class="uk-text-right uk-padding-small uk-padding-remove-top">
                        <button class="uk-button uk-button-primary button-action-table" type="submit">Save</button>
                    </section>
                </form>
            </div>
        </div>

        <!-- Reject Leave Req Modal -->
        <div id="reject-modal" class="uk-flex-top filter-data" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('reject-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center uk-text-medium">Reject Leave Request</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-block uk-child-width-1-2 uk-padding-remove-vertical" uk-grid>
                        <span>Leave Type</span>
                        <span>{{ selected_req.leave_category ? "Paid" : "Unpaid" }}</span>
                    </div>
                    <div class="uk-block uk-child-width-1-2 uk-padding-remove-vertical" uk-grid>
                        <span>Leave Name</span>
                        <span>{{ selected_req.leave_name }}</span>
                    </div>
                    <div class="uk-block uk-child-width-1-2 uk-padding-remove-vertical" uk-grid>
                        <span>Duration</span>
                        <span>{{ selected_req.duration_days ? selected_req.duration_days + " day" : selected_req.duration_hours + " hours" }}</span>
                    </div>
                </div>
                <form @submit.prevent="approveRejectLeaveReq(2)">
                    <div class="uk-padding-small uk-padding-remove-bottom">
                        <section class="uk-margin">
                            <label for="rejectNotes" class="uk-form-label">Rejection Notes <b class="uk-text-danger">*</b></label>
                            <textarea
                                id="rejectNotes"
                                name="rejectNotes"
                                rows="3"
                                class="uk-textarea"
                                :class="{'uk-form-danger': errors.has('desc')}"
                                v-model="notes"
                                v-validate="'required'"
                            ></textarea>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('desc')">{{ errors.first('desc') }}</span>
                        </section>
                    </div>
                    <hr>
                    <section class="uk-text-right uk-padding-small uk-padding-remove-top">
                        <button class="uk-button uk-button-primary button-action-table" type="submit">Save</button>
                    </section>
                </form>
            </div>
        </div>

        <!-- View leave document modal -->
        <div id="leave-document-modal" uk-modal esc-close="false" bg-close="true">
            <div v-if="selected_doc" class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('leave-document-modal')"></button>
                <div class="uk-modal-header uk-text-center">
                    <h2 class="uk-modal-title">Leave Request Document</h2>
                </div>
                <div class="uk-modal-body">
                    <div uk-grid>
                        <img :src="selected_doc.file_url" alt="leave-req-document">
                    </div>
                </div>
                <section class="uk-text-right uk-padding-small uk-padding-remove-top">
                    <button class="uk-button uk-button-primary button-action-table" type="button" >
                        <a :href="selected_doc.file_url" target="_blank" rel="noopener noreferrer" style="color: #fff">Download</a>
                    </button>
                </section>
            </div>
        </div>
        <!-- Delete  leave modal -->
        <div id="confirm-delete-modal" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirm-delete-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left">Are you sure?</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Selected leave request will be deleted.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="cancelDelete">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="acceptDelete">Yes</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import LoadingTable from '@/components/globals/tables/Loading';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import { notificationSuccess, notificationDangerCustom } from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                limit: 10,
                page: 1,
                category: '',
                sort_by: ''
            },
            images: PREFIX_IMAGE,
            user: null,
            leave_list: null,
            selected_req: {
                id: null,
                leave_category: 0,
                leave_name: '',
                duration_days: null,
                duration_hours: null
            },
            selected_doc: null,
            notes: null,
            pageLoading: false,
            isLoading: false
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
    },
    watch: {
        meta() {
            this.getMitraWithShiftList();
        },
    },
    computed: {
        user_cred() {
            return getUserLogin();
        },
    },
    async mounted() {
        try {
            this.pageLoading = true;

            await this.getMitraRequestData();
            await this.getMitraWithShiftList();

            this.pageLoading = false;
        } catch (error) {
            this.pageLoading = false;
        }
    },
    beforeMount() {
        if(window.UIkit.modal('.approve-modal')) window.UIkit.modal('.approve-modal').$destroy(true);
        if(window.UIkit.modal('.reject-modal')) window.UIkit.modal('.reject-modal').$destroy(true);
    },
    methods: {
        ...mapActions({
            getMitraData: 'leave/getMitraData',
            getLeaveRequestPerMitra: 'leave/getLeaveRequestPerMitra',
            approveRejectLeave: 'leave/approveRejectLeave',
            deleteRequestLeave: 'leave/deleteRequestLeave',
        }),
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async getMitraRequestData() {
            this.user = await this.getMitraData(this.$route.params.partner_id);
        },
        async getMitraWithShiftList() {
            const data = {
                ...this.meta,
                id: this.$route.params.partner_id
            };

            this.isLoading = true;
            this.leave_list = await this.getLeaveRequestPerMitra(data);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'approve-modal':
                await window.UIkit.modal('#approve-modal').show();
                break;
            case 'reject-modal':
                await window.UIkit.modal('#reject-modal').show();
                break;
            case 'leave-document-modal':
                await window.UIkit.modal('#leave-document-modal').show();
                break;
            case 'confirm-delete-modal':
                await window.UIkit.modal('#confirm-delete-modal').show();
                break;
            }
        },
        async hideModal(modal_name) {
            this.notes = null;
            this.selected_req = {
                id: '',
                leave_category: 0,
                leave_name: '',
                duration_days: null,
                duration_hours: null
            };
            switch(modal_name) {
            case 'approve-modal':
                await window.UIkit.modal('#approve-modal').hide();
                break;
            case 'reject-modal':
                await window.UIkit.modal('#reject-modal').hide();
                break;
            case 'leave-document-modal':
                await window.UIkit.modal('#leave-document-modal').hide();
                break;
            case 'confirm-delete-modal':
                await window.UIkit.modal('#confirm-delete-modal').hide();
                break;
            }
        },
        async viewLeaveDoc(item) {
            this.selected_doc = item;
            await window.UIkit.modal('#leave-document-modal').show();
        },
        async requestAction(data, type) {
            this.selected_req = {
                id: data._id,
                leave_category: data.leave_category,
                leave_name: data.leave_name,
                duration_days: data.duration_days,
                duration_hours: data.duration_hours
            };

            if (type === 'approve') {
                await this.showModal('approve-modal');
            } else if (type === 'delete') {
                await this.showModal('confirm-delete-modal');
            } else {
                await this.showModal('reject-modal');
            }
        },
        async approveRejectLeaveReq(status) {
            const validate = await this.$validator.validateAll();
            if (!validate || this.$validator.errors.any()) return;

            const data = {
                id: this.selected_req.id,
                status: status,
                notes: this.notes
            };
            const response = await this.approveRejectLeave(data);
            if (response.status == 'OK') {
                await this.getMitraWithShiftList();
                if (status == 1) {
                    await this.hideModal('approve-modal');
                    return notificationSuccess(`Leave request approved`);
                } else {
                    await this.hideModal('reject-modal');
                    return notificationSuccess(`Leave request rejected`);
                }
            } else {
                return notificationDangerCustom(response.status.message);
            }
        },
        async acceptDelete() {
            const response = await this.deleteRequestLeave(this.selected_req.id);
            if (response.status == 'OK') {
                await this.getMitraWithShiftList();
                await window.UIkit.modal('#confirm-delete-modal').hide();
                return notificationSuccess(`Leave request deleted`);
            } else {
                await window.UIkit.modal('#confirm-delete-modal').hide();
                return notificationDangerCustom(response.status.message);
            }
        },
        async cancelDelete() {
            await window.UIkit.modal('#confirm-delete-modal').hide();
        },
        formatDate(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateComplete(data);
        },
        statusConvert(status) {
            if (status == 0) {
                return '-';
            } else if (status == 1) {
                return 'Approved';
            } else {
                return 'Rejected';
            }
        }
    }
};
</script>

<style scoped>
.location-header {
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead {
    background-color: #0ABAB5;
}
th, .white-text {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter {
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.photo {
    width: 106px;
    height: 106px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}
</style>
