<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <div class="uk-flex uk-flex-middle uk-flex-between">
                <div class="uk-flex uk-flex-middle">
                    <div v-if="previousApplicantId" class="uk-margin-small-right">
                        <button
                            class="uk-button uk-button-default uk-border-pill border-black uk-flex-inline padding-18px uk-padding-remove-vertical"
                            @click="$router.push(`/admin/job_applicant/candidate/detail/${previousApplicantId}`);"
                        >
                            <span class="uk-margin-small-right">
                                <img :src="`${baseUrlImg}/arrow-back.svg`" alt="Previous Applicant" width="12px" height="12px"/>
                            </span>
                            Previous Applicant
                        </button>
                    </div>
                    <div v-if="nextApplicantId" class="uk-margin-small-right">
                        <button
                            class="uk-button uk-button-default uk-border-pill border-black uk-flex-inline padding-18px uk-padding-remove-vertical"
                            @click="$router.push(`/admin/job_applicant/candidate/detail/${nextApplicantId}`);"
                        >
                            Next Applicant
                            <span>
                                <img class="uk-margin-small-left icon-white" :src="`${baseUrlImg}/arrow-next.svg`" alt="Next Applicant" width="12px" height="12px"/>
                            </span>
                        </button>
                    </div>
                    <div class="uk-margin-small-right">
                        <button
                            v-if="button_download_cv_loading"
                            class="uk-button uk-button-default uk-border-pill uk-flex uk-flex-middle uk-flex-center"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </button>
                        <button 
                            v-else
                            class="uk-button uk-button-default uk-border-pill myrobin-background-primary myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="downloadCV({ userId: detail_candidate.user._id })"
                        >Download CV</button>
                    </div>
                    <div v-if="detail_candidate && [0,1,4,5,3].includes(detail_candidate.status)" class="uk-margin-small-right">
                        <button
                            v-if="detail_candidate && detail_candidate.status === 0"
                            class="uk-button uk-button-default uk-border-pill myrobin-background-secondary myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="moveUser(1)"
                        >Pickup Applicant
                            <span>
                                <img class="uk-margin-small-left icon-white" :src="`${baseUrlImg}/job_applicant/arrow-right-white.svg`" alt="Pickup Applicant" width="18px" height="18px"/>
                            </span>
                        </button>
                        <button
                            v-else-if="detail_candidate && detail_candidate.status === 1"
                            class="uk-button uk-button-default uk-border-pill myrobin-background-secondary myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="moveUser(4)"
                        >Invite to Interview
                            <span>
                                <img class="uk-margin-small-left icon-white" :src="`${baseUrlImg}/job_applicant/arrow-right-white.svg`" alt="Invite to Interview" width="18px" height="18px"/>
                            </span>
                        </button>
                        <button
                            v-else-if="detail_candidate && detail_candidate.status === 4"
                            class="uk-button uk-button-default uk-border-pill myrobin-background-secondary myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="moveUser(5)"
                        >Interviewed Applicant
                            <span>
                                <img class="uk-margin-small-left icon-white" :src="`${baseUrlImg}/job_applicant/arrow-right-white.svg`" alt="Interviewed Applicant" width="18px" height="18px"/>
                            </span>
                        </button>
                        <button
                            v-else-if="detail_candidate && detail_candidate.status === 3"
                            class="uk-button uk-button-default uk-border-pill myrobin-background-secondary myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="moveUser(1)"
                        >
                            <span>
                                <img class="icon-white uk-margin-small-right" :src="`${baseUrlImg}/job_applicant/arrow-left-white.svg`" alt="Pickup Applicant" width="18px" height="18px"/>
                            </span>
                            Pickup Applicant
                        </button>
                    </div>
                    <div v-if="detail_candidate && [0,1,4,5,3].includes(detail_candidate.status)" class="uk-margin-small-right">
                        <button
                            v-if="detail_candidate && [0,1,4,5].includes(detail_candidate.status)"
                            class="uk-button uk-button-default uk-border-pill myrobin-background-success myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="moveUser(6)"
                        >Send Contract
                            <span>
                                <img class="uk-margin-small-left icon-white" :src="`${baseUrlImg}/job_applicant/forward-white.svg`" alt="Send Contract" width="18px" height="18px"/>
                            </span>
                        </button>
                        <button
                            v-if="detail_candidate && [3].includes(detail_candidate.status)"
                            class="uk-button uk-button-default uk-border-pill myrobin-background-success myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="moveUser(4)"
                        >
                            <span>
                                <img class="uk-margin-small-right icon-white" :src="`${baseUrlImg}/job_applicant/backward-white.svg`" alt="Invite to Interview" width="18px" height="18px"/>
                            </span>
                            Invite to Interview
                        </button>

                    </div>
                    <div v-if="detail_candidate && [0,1,4,5].includes(detail_candidate.status)" class="uk-margin-small-right">
                        <button
                            v-if="detail_candidate && [0,1,4,5].includes(detail_candidate.status)"
                            class="uk-button uk-button-default uk-border-pill myrobin-background-danger myrobin-color-white padding-18px uk-padding-remove-vertical"
                            @click="moveUser(3)"
                        >Reject Applicant
                            <span>
                                <img class="uk-margin-small-left icon-white" :src="`${baseUrlImg}/job_applicant/reject-white.svg`" alt="Reject Applicant" width="18px" height="18px"/>
                            </span>
                        </button>
                    </div>
                </div>
                <button class="uk-button uk-margin-small-left uk-border-pill" @click="goToJobApplicantDetail">
                    <img :src="`${baseUrlImg}/cross.svg`" alt="Close icon">
                </button>
            </div>
            <div class="uk-flex uk-margin-medium-top">
                <div class="uk-flex uk-flex-center" style="align-items: center;">Vacancy Title</div>
                <div class="uk-margin-small-left uk-text-bold vacancy-title">
                    {{ detail_candidate && detail_candidate.job && detail_candidate.job.title ? detail_candidate.job.title : '-' }}
                </div>
            </div>
            <div class="uk-flex uk-flex-row uk-flex-left uk-margin-small-top">
                <div class="uk-width-auto uk-margin-medium-right">
                    <img 
                        v-if="detail_candidate.user.photo_url && detail_candidate.user.photo_url.file_url" 
                        :src="detail_candidate.user.photo_url.file_url"
                        class="photo"
                        @error="(e) => imgError(e)"
                    >
                    <img v-else :src="`${baseUrlImg}/avatar.svg`" class="photo" alt="">
                </div>
                <div class="uk-width-expand uk-flex uk-flex-column">
                    <div class="uk-flex uk-flex-row">
                        <div class="uk-text-bold uk-text-large">
                            {{ detail_candidate && detail_candidate.user && detail_candidate.user.fullname ? detail_candidate.user.fullname : '-' }}
                        </div>
                        <div class="uk-margin-small-left">
                            <div 
                                class="status-apply"
                                :class="[detail_candidate && detail_candidate.status === 0 ? 'status-apply-warning' : 
                                    detail_candidate && detail_candidate.status === 3 ? 'status-apply-danger' :
                                    detail_candidate && detail_candidate.status && [6,7].includes(detail_candidate.status) ?
                                    'status-apply-success' : 'status-apply-process']"
                            >
                                <div class="uk-text-bold uk-text-small">{{ 
                                    detail_candidate && detail_candidate.status === 0 ? 'New Applicant: Process me now!' :
                                    detail_candidate && detail_candidate.status === 3 ? 'Rejected' :
                                    detail_candidate && detail_candidate.status && [6,7].includes(detail_candidate.status) ?
                                    'Accepted' : 'Processed'
                                }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-grey text-f12-l24">
                        User Since <span class="uk-text-bold text-f12-l24">
                            {{
                                detail_candidate && detail_candidate.user && detail_candidate.user.created_at ? detail_candidate.user.created_at : null | moment('MMMM YYYY')
                            }}
                        </span>
                    </div>
                    <div class="text-grey uk-text-500 uk-margin-small-top text-f14-l21">
                        {{
                            detail_candidate && detail_candidate.user && detail_candidate.user.description ? detail_candidate.user.description : '-'
                        }}
                    </div>
                    <div class="uk-flex uk-flex-row uk-margin-small-top">
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${baseUrlImg}/job_applicant/detail/foundation_telephone.svg`" alt="">
                                </span>
                                {{ detail_candidate && detail_candidate.user && detail_candidate.user.phone_number ? detail_candidate.user.phone_number : '-' }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${baseUrlImg}/job_applicant/detail/ic_sharp-mail.svg`" alt="">
                                </span>
                                {{ detail_candidate && detail_candidate.user && detail_candidate.user.email ? detail_candidate.user.email : '-' }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${baseUrlImg}/job_applicant/detail/mdi_location.svg`" alt="">
                                </span>
                                {{ detail_candidate && detail_candidate.user && detail_candidate.user.domicile 
                                    && detail_candidate.user.domicile.name 
                                    && detail_candidate.user.domicile.province && detail_candidate.user.domicile.province.name
                                    && detail_candidate.user.address ? 
                                    detail_candidate.user.address+', '+ 
                                    toTitleCase(detail_candidate.user.domicile.name+', '+ detail_candidate.user.domicile.province.name)
                                    : '-' }}
                            </div>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${baseUrlImg}/job_applicant/detail/ph_gender-intersex-bold.svg`" alt="">
                                </span>
                                {{ detail_candidate && detail_candidate.user && detail_candidate.user.gender ? getGender(detail_candidate.user.gender) : '-' }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${baseUrlImg}/job_applicant/detail/lucide_calendar.svg`" alt="">
                                </span>
                                {{ detail_candidate && detail_candidate.user && detail_candidate.user.birthplace 
                                && detail_candidate.user.birthplace.name 
                                && detail_candidate.user.birthdate ? getBirthPlaceAndDate(detail_candidate.user.birthplace.name, detail_candidate.user.birthdate) : '-' }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${baseUrlImg}/job_applicant/detail/iconoir_birthday-cake.svg`" alt="">
                                </span>
                                {{ detail_candidate && detail_candidate.user 
                                && detail_candidate.user.birthdate ? getYears({
                                    date1: detail_candidate.user.birthdate
                                }) + ' Tahun': '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-medium-top applicant-progress uk-padding-small">
                <div class="uk-flex uk-flex-column uk-margin-large-right">
                    <div>
                        <p class="title-progress">Status Applicant</p>
                    </div>
                    <div class="">
                        <p class="uk-text-bold uk-margin-small-top">
                            {{ detail_candidate ? listStatus({ status: detail_candidate.status }) : '-' }}
                        </p>
                    </div>
                </div>
                <div class="uk-flex uk-flex-column uk-margin-large-right">
                    <div>
                        <p class="title-progress">Interview Date</p>
                    </div>
                    <div>
                        <p class="uk-text-bold uk-margin-small-top">
                            {{ detail_candidate && detail_candidate.interview_datetime ? candidateDatetime({ date: detail_candidate.interview_datetime }) : '-' }}
                        </p>
                    </div>
                </div>
                <div class="uk-flex uk-flex-column uk-margin-large-right">
                    <div>
                        <p class="title-progress">Interview Score</p>
                    </div>
                    <div>
                        <p class="uk-text-bold uk-margin-small-top">
                            {{ detail_candidate && detail_candidate.interview_score ? detail_candidate.interview_score : '-' }}
                        </p>
                    </div>
                </div>
                <div class="uk-flex uk-flex-column uk-margin-large-right">
                    <div>
                        <p class="title-progress">Interview Notes</p>
                    </div>
                    <div>
                        <p 
                            v-if="detail_candidate && detail_candidate.interview_notes "
                            class="uk-text-bold uk-margin-small-top uk-text-primary"
                            @click="modal.isShowNotesModal = true"
                        >
                            Result
                        </p>
                        <p 
                            v-else
                            class="uk-text-bold uk-margin-small-top"
                        >{{ '-' }}</p>
                    </div>
                </div>
                <div v-if="detail_candidate && detail_candidate.status > 3" class="uk-flex uk-flex-column uk-margin-large-right">
                    <div>
                        <p class="title-progress">Status Interview</p>
                    </div>
                    <div>
                        <p
                            class="uk-text-bold uk-margin-small-top"
                            :class="[detail_candidate && detail_candidate.status === 4 ? 'uk-text-danger' : 'uk-text-success']"
                        >
                            {{ detail_candidate && detail_candidate.status === 4 ? 'Not yet Interview' : 'Done Interview'}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="uk-flex uk-flex-column uk-margin-medium-top applicant-progress uk-padding-small">
                <div class="uk-flex uk-flex-middle">
                    <div class="uk-margin-small-right">
                        <button
                            class="uk-button uk-button-default uk-border-pill border-grey uk-flex-inline padding-18px uk-text-bold uk-padding-remove-vertical tab"
                            :class="[tab === 1 ? 'active-tab' : '']"
                            @click="gotoTab(1)"
                        >
                            CANDIDATE'S CV
                        </button>
                    </div>
                    <div class="uk-margin-small-right">
                        <button
                            class="uk-button uk-button-default uk-border-pill border-grey uk-flex-inline padding-18px uk-text-bold uk-padding-remove-vertical tab"
                            :class="[tab === 2 ? 'active-tab' : '']"
                            @click="gotoTab(2)"
                        >
                            OTHER JOB APPLICATIONS
                        </button>
                    </div>
                    <div class="uk-margin-small-right">
                        <button
                            class="uk-button uk-button-default uk-border-pill border-grey uk-flex-inline padding-18px uk-text-bold uk-padding-remove-vertical tab"
                            :class="[tab === 3 ? 'active-tab' : '']"
                            @click="gotoTab(3)"
                        >
                            ADDITIONAL QUESTIONS
                        </button>
                    </div>
                </div>
                <!-- Candidate CV -->
                <div v-if="tab === 1" class="uk-width-1-1 uk-flex uk-flex-column uk-flex-middle uk-margin-small-top">
                    <!-- Candidate Summary Accordion -->
                    <div class="accordion-drawer">
                        <input class="accordion-drawer__trigger" id="candidate-summary" type="checkbox" />
                        <label class="accordion-drawer__title remove-border-top candidate-icon" for="candidate-summary">Candidate Summary</label>
                        <div class="accordion-drawer__content-wrapper">
                            <div class="accordion-drawer__content">
                                <div class="uk-flex uk-flex-row">
                                    <div class="uk-width-small">
                                        <p class="text-f12-l24 myrobin-text-949494">
                                            Latest Education
                                        </p>
                                    </div>
                                    <div 
                                        v-if="
                                            detail_candidate && detail_candidate.cv 
                                            && detail_candidate.cv.educations 
                                            && detail_candidate.cv.educations.length > 0" 
                                        class="uk-flex uk-flex-column uk-width-auto">
                                        <p class="uk-margin-remove text-f12-l24 uk-text-bold">
                                            {{ detail_candidate.cv.educations[0].institution_name || '-' }}
                                        </p>
                                        <p class="uk-margin-remove text-f12-l24">
                                            {{ detail_candidate.cv.educations[0].majors || '-' }}
                                        </p>
                                        <p class="uk-margin-remove myrobin-text-949494 text-f12-l24">
                                            {{ getEducationalTime({
                                                startDate: detail_candidate.cv.educations[0].start_date,
                                                endDate: detail_candidate.cv.educations[0].end_date,
                                            }) || '-' }}
                                        </p>
                                        <p 
                                            v-if="detail_candidate.cv.educations[0].education_city && detail_candidate.cv.educations[0].education_city.name" 
                                            class="uk-margin-remove text-f12-l24">
                                            {{ toTitleCase(detail_candidate.cv.educations[0].education_city.name) || '-'}}
                                        </p>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-row">
                                    <div class="uk-width-small">
                                        <p class="text-f12-l24 myrobin-text-949494">
                                            Latest Job
                                        </p>
                                    </div>
                                    <div 
                                        v-if="
                                            detail_candidate && detail_candidate.cv 
                                            && detail_candidate.cv.work_experiences 
                                            && detail_candidate.cv.work_experiences.length > 0" 
                                        class="uk-flex uk-flex-column uk-width-auto">
                                        <p class="uk-margin-remove text-f12-l24 uk-text-bold">
                                            {{ detail_candidate.cv.work_experiences[0].title || '-' }}
                                        </p>
                                        <p class="uk-margin-remove text-f12-l24">
                                            {{ detail_candidate.cv.work_experiences[0].company_name || '-' }}
                                        </p>
                                        <p class="uk-margin-remove myrobin-text-949494 text-f12-l24">
                                            {{ getEducationalTime({
                                                startDate: detail_candidate.cv.work_experiences[0].start_date,
                                                endDate: detail_candidate.cv.work_experiences[0].end_date,
                                            }) || '-' }}
                                        </p>
                                        <p 
                                            v-if="detail_candidate.cv.work_experiences[0].company_city && detail_candidate.cv.work_experiences[0].company_city.name" 
                                            class="uk-margin-remove text-f12-l24">
                                            {{ toTitleCase(detail_candidate.cv.work_experiences[0].company_city.name) || '-'}}
                                        </p>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-row">
                                    <div class="uk-width-small">
                                        <p class="text-f12-l24 myrobin-text-949494">
                                            Years of Professional Experience
                                        </p>
                                    </div>
                                    <div 
                                        v-if="
                                            detail_candidate && detail_candidate.cv 
                                            && detail_candidate.cv.work_experiences 
                                            && detail_candidate.cv.work_experiences.length > 0" 
                                        class="uk-flex uk-flex-column uk-width-auto">
                                        <p class="uk-margin-remove text-f12-l24 uk-text-bold">
                                            {{ getYearsExperiences({
                                                data: detail_candidate.cv.work_experiences
                                            }) + ' Years' || '-' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Candidate Summary Accordion -->
                    <!-- Document Qualifications Accordion -->
                    <div class="accordion-drawer">
                        <input class="accordion-drawer__trigger" id="document-qualifications" type="checkbox" />
                        <label class="accordion-drawer__title document-qualify-icon" for="document-qualifications">Document Qualifications</label>
                        <div class="accordion-drawer__content-wrapper">
                            <div class="accordion-drawer__content uk-flex uk-flex-row uk-margin-small-top">
                                <div class="uk-width-1-3 uk-flex uk-flex-column">
                                    <p class="text-f12-l24 myrobin-text-949494 uk-margin-remove">
                                        Form of Identifications
                                    </p>
                                    <div class="uk-flex uk-flex-row uk-margin-small-top">
                                        <div class="uk-width-1-2 uk-flex">
                                            <img :src="[detail_candidate.cv.sim && detail_candidate.cv.sim.document_url && detail_candidate.cv.sim.document_url.file_url ? 
                                                        `${baseUrlImg}/check-circle.svg` : `${baseUrlImg}/cross-circle.svg`]" 
                                            class="uk-margin-small-right" width="30">
                                            <p class="text-f12-l24 uk-margin-remove uk-text-bold align-self-center">SIM</p>
                                        </div>
                                        <div class="uk-width-1-2 uk-flex">
                                            <button v-if="detail_candidate.cv.sim && detail_candidate.cv.sim.document_url 
                                                    && detail_candidate.cv.sim.document_url.file_url"   
                                                    @click="openImage({file_url: detail_candidate.cv.sim.document_url.file_url})">
                                                <img :src="detail_candidate.cv.sim.document_url.file_url" width="30">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="uk-flex uk-flex-row uk-margin-small-top">
                                        <div class="uk-width-1-2 uk-flex">
                                            <img :src="[detail_candidate.cv.kk && detail_candidate.cv.kk.document_url && detail_candidate.cv.kk.document_url.file_url ? 
                                                        `${baseUrlImg}/check-circle.svg` : `${baseUrlImg}/cross-circle.svg`]" 
                                            class="uk-margin-small-right" width="30">
                                            <p class="text-f12-l24 uk-margin-remove uk-text-bold align-self-center">KK</p>
                                        </div>
                                        <div class="uk-width-1-2 uk-flex">
                                            <button v-if="detail_candidate.cv.kk && detail_candidate.cv.kk.document_url 
                                                    && detail_candidate.cv.kk.document_url.file_url"   
                                                    @click="openImage({file_url: detail_candidate.cv.kk.document_url.file_url})">
                                                <img :src="detail_candidate.cv.kk.document_url.file_url" width="30">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="uk-flex uk-flex-row uk-margin-small-top">
                                        <div class="uk-width-1-2 uk-flex">
                                            <img :src="[detail_candidate.cv.ktp && detail_candidate.cv.ktp.document_url && detail_candidate.cv.ktp.document_url.file_url ? 
                                                        `${baseUrlImg}/check-circle.svg` : `${baseUrlImg}/cross-circle.svg`]" 
                                            class="uk-margin-small-right" width="30">
                                            <p class="text-f12-l24 uk-margin-remove uk-text-bold align-self-center">KTP</p>
                                        </div>
                                        <div class="uk-width-1-2 uk-flex">
                                            <button v-if="detail_candidate.cv.ktp && detail_candidate.cv.ktp.document_url 
                                                    && detail_candidate.cv.ktp.document_url.file_url"   
                                                    @click="openImage({file_url: detail_candidate.cv.ktp.document_url.file_url})">
                                                <img :src="detail_candidate.cv.ktp.document_url.file_url" width="30">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="uk-flex uk-flex-row uk-margin-small-top">
                                        <div class="uk-width-1-2 uk-flex">
                                            <img :src="[detail_candidate.cv.ijazah && detail_candidate.cv.ijazah.document_url && detail_candidate.cv.ijazah.document_url.file_url ? 
                                                        `${baseUrlImg}/check-circle.svg` : `${baseUrlImg}/cross-circle.svg`]" 
                                            class="uk-margin-small-right" width="30">
                                            <p class="text-f12-l24 uk-margin-remove uk-text-bold align-self-center">Ijazah</p>
                                        </div>
                                        <div class="uk-width-1-2 uk-flex">
                                            <button v-if="detail_candidate.cv.ijazah && detail_candidate.cv.ijazah.document_url 
                                                    && detail_candidate.cv.ijazah.document_url.file_url"   
                                                    @click="openImage({file_url: detail_candidate.cv.ijazah.document_url.file_url})">
                                                <img :src="detail_candidate.cv.ijazah.document_url.file_url" width="30">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="uk-flex uk-flex-row uk-margin-small-top">
                                        <div class="uk-width-1-2 uk-flex">
                                            <img :src="[detail_candidate.cv.skck && detail_candidate.cv.skck.document_url && detail_candidate.cv.skck.document_url.file_url ? 
                                                        `${baseUrlImg}/check-circle.svg` : `${baseUrlImg}/cross-circle.svg`]" 
                                            class="uk-margin-small-right" width="30">
                                            <p class="text-f12-l24 uk-margin-remove uk-text-bold align-self-center">SKCK</p>
                                        </div>
                                        <div class="uk-width-1-2 uk-flex">
                                            <button v-if="detail_candidate.cv.skck && detail_candidate.cv.skck.document_url 
                                                && detail_candidate.cv.skck.document_url.file_url" 
                                                @click="openImage({file_url: detail_candidate.cv.skck.document_url.file_url})">
                                                <img :src="detail_candidate.cv.skck.document_url.file_url" width="30">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="additionalDoc" class="uk-width-1-3 uk-flex uk-flex-column">
                                    <p class="text-f12-l24 myrobin-text-949494 uk-margin-remove">
                                        Additional Document(s)
                                    </p>
                                    <div v-for="(oDoc, oKey) in additionalDoc" :key="oKey" class="uk-flex uk-flex-row uk-margin-small-top">
                                        <div class="uk-width-2-3 uk-flex">
                                            <img :src="`${baseUrlImg}/check-circle.svg`" 
                                            class="uk-margin-small-right" width="30">
                                            <p class="text-f12-l24 uk-margin-remove uk-text-bold align-self-center">{{ oDoc.doc_name }}</p>
                                        </div>
                                        <div class="uk-width-1-3 uk-flex">
                                            <button
                                                @click="openImage({file_url: oDoc.document_url.file_url})">
                                                <img :src="getTypeOfFile(oDoc.document_url.file_url) === 'image' ? oDoc.document_url.file_url : `${baseUrlImg}/preview-docs.svg`" width="30">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Document Qualifications Accordion -->
                    <!-- Experiences Accordion -->
                    <div class="accordion-drawer">
                        <input class="accordion-drawer__trigger" id="experiences" type="checkbox" />
                        <label class="accordion-drawer__title experiences-icon" for="experiences">Experiences</label>
                        <div class="accordion-drawer__content-wrapper">
                            <div v-if="detail_candidate && detail_candidate.cv && detail_candidate.cv.work_experiences 
                                        && detail_candidate.cv.work_experiences.length > 0" 
                            class="accordion-drawer__content">
                                <div v-for="(experience, index) in candidate_experiences" :key="index" class="uk-flex uk-width-1-1">
                                    <div class="stepper-line"
                                        :class="[index === 0 ? 'margin-top-22' : '']"    
                                    ></div>
                                    <div class="uk-width-1-1 content-stepper">
                                        <div style="display: inline-flex" class="uk-padding-small uk-padding-remove-horizontal">
                                            <div class="stepper-circle"></div>
                                            <div class="uk-flex uk-flex-column uk-width-1-3 uk-padding-small uk-padding-remove-vertical">
                                                <p class="text-f12-l24 uk-text-bold uk-margin-remove">
                                                    {{ experience.title || '-' }}
                                                </p>
                                                <p class="text-f12-l24 uk-margin-remove">
                                                    {{ experience.company_name || '-'}}
                                                </p>
                                                <p class="text-f12-l24 myrobin-text-949494 uk-margin-remove">
                                                    {{ experience.start_date | moment('MMMM YYYY') }} - {{ experience.present ? 'Present' : experience.end_date | moment('MMMM YYYY') }}
                                                </p>
                                                <p v-if="experience.company_city && experience.company_city.name" class="text-f12-l24 uk-margin-remove">
                                                    {{ toTitleCase(experience.company_city.name) || '-' }}
                                                </p>
                                                <p class="text-f12-l24 uk-text-bold uk-margin-remove">
                                                    {{ experience.description || '-' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Experiences Accordion -->
                    <!-- Educations Accordion -->
                    <div class="accordion-drawer">
                        <input class="accordion-drawer__trigger" id="educations" type="checkbox" />
                        <label class="accordion-drawer__title educations-icon" for="educations">Educations</label>
                        <div class="accordion-drawer__content-wrapper">
                            <div class="accordion-drawer__content uk-margin-small-top">
                                <div class="uk-flex uk-flex-column">
                                    <div v-if="detail_candidate && detail_candidate.cv && detail_candidate.cv.educations && detail_candidate.cv.educations.length > 0">
                                        <div 
                                            v-for="(education, index) in candidate_educations" 
                                            :key="index" 
                                            class="uk-flex uk-flex-row uk-margin-small-bottom"
                                        >
                                            <div class="uk-width-auto uk-margin-small-right">
                                                <div 
                                                    class="uk-flex uk-border-rounded uk-padding-small uk-align-center uk-margin-remove-left uk-margin-remove-right college-yellow" 
                                                    :class="[
                                                        education.education_level.name.includes('SD') ? 'college-red' : '',
                                                        education.education_level.name.includes('SMP') ? 'college-blue' : '',
                                                        education.education_level.name.includes('SMA') ? 'college-grey' : '',
                                                    ]"
                                                    style="width: 51px; height: 51px;"
                                                >
                                                    <img :src="`${baseUrlImg}/job_applicant/detail/graduate.svg`" alt="Collage icon" width="24" height="24" />
                                                </div>
                                            </div>
                                            <div class="uk-flex uk-flex-column uk-width-auto">
                                                <p v-if="education.institution_name" class="text-f12-l18 uk-text-bold uk-margin-remove" style="margin-bottom: 4px !important;">
                                                    {{ education.institution_name }}
                                                </p>
                                                <p v-if="education.majors" class="text-f12-l18 uk-margin-remove">
                                                    {{ education.majors }}
                                                </p>
                                                <p v-if="education.start_date" class="text-f12-l18 myrobin-text-949494 uk-margin-remove">
                                                    {{ education.start_date | moment('MMMM YYYY') }} - {{ education.present ? 'Present' : education.end_date | moment('MMMM YYYY') }}
                                                </p>
                                                <p v-if="education.education_city && education.education_city.name" class="text-f12-l18 uk-margin-remove">
                                                    {{ toTitleCase(education.education_city.name) || '-'}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Educations Accordion -->
                    <!-- Skills Accordion -->
                    <div class="accordion-drawer">
                        <input class="accordion-drawer__trigger" id="skills" type="checkbox" />
                        <label class="accordion-drawer__title skills-icon" for="skills">Skills</label>
                        <div class="accordion-drawer__content-wrapper">
                            <div class="accordion-drawer__content uk-margin-small-top">
                                <div v-if="
                                        detail_candidate && detail_candidate.cv 
                                        && detail_candidate.cv.skills 
                                        && detail_candidate.cv.skills.length > 0"  
                                    class="uk-flex uk-flex-column">
                                    <div v-for="(skill, index) in detail_candidate.cv.skills" :key="index">
                                        <div class="uk-width-small">
                                            <p class="text-f12-l24 myrobin-text-949494 uk-margin-remove-bottom">
                                                {{ 'Skill ' + (index+1) }}
                                            </p>
                                            <p class="text-f12-l24 uk-text-bold uk-margin-remove-top">
                                                {{ skill.name }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Skills Accordion -->
                </div>
                <!-- End Candidate CV -->

                <!-- Other Job Applications -->
                <div v-if="tab === 2" class="uk-width-1-1 uk-flex uk-flex-column uk-margin-medium-top">
                    <div class="uk-width-1-5">
                        <select v-model="other_job.meta.sorting" class="uk-select uk-margin-small-bottom" @change="isFilterChanged">
                            <option value="" disabled>Sort By</option>
                            <option value="0">Latest apply date</option>
                            <option value="1">Oldest apply date</option>
                        </select>
                    </div>
                    <div class="uk-width-1-1">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th>Vacancy Title</th>
                                    <th>Apply Date</th>
                                    <th>Company</th>
                                    <th>Location</th>
                                    <th>Test Score</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <loading-table v-if="isLoadingOtherJob" :colspan="6" />
                            <template v-else>
                                <tbody v-if="detail_other_job && detail_other_job.length > 0">
                                    <tr
                                        v-for="(other_job, index) in detail_other_job"
                                        :key="index"
                                    >
                                        <td :uk-tooltip="other_job.job && other_job.job.title">{{ other_job.job && other_job.job.title ? trimWords(other_job.job.title,4) : '-' }}</td>
                                        <td class="uk-text-nowrap">{{ other_job && other_job.created_at ? formatMoment(other_job.created_at).format('DD MMM YYYY') : '-' }}</td>
                                        <td :uk-tooltip="other_job && other_job.company && other_job.company.name">
                                            {{ other_job && other_job.company && other_job.company.name ? trimWords(other_job.company.name, 4) : '-'  }}
                                        </td>
                                        <td :uk-tooltip="other_job && other_job.company && other_job.company.address">
                                            {{ other_job && other_job.company && other_job.company.address ? trimWords(other_job.company.address, 12) : '-'  }}
                                        </td>
                                        <td v-if="other_job.all_user_scores === null" class="uk-text-nowrap">-</td>
                                        <td v-else class="uk-text-nowrap">
                                            <p
                                                v-for="(test, index) in other_job.all_user_scores"
                                                :key="index"
                                            >
                                                {{ test.test_category_id && test.test_category_id.name ? test.test_category_id.name : '-' }} - {{ test.score ? test.score : '-' }}
                                            </p>
                                        </td>
                                        <td class="uk-text-nowrap">{{ listStatus({ status: other_job.status }) || '-' }}</td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="6" />
                            </template>
                        </table>
                    </div>
                </div>
                <!-- End Other Job Applications -->

                <div v-if="tab === 3" class="uk-width-1-1 uk-flex uk-flex-row uk-margin-small-top">
                    <hr/>
                    <div v-if="detail_candidate.question_answers.length > 0" class="uk-flex uk-flex-column uk-width-1-1 uk-margin-small-top">
                        <div class="uk-flex uk-flex-row uk-width-1-1" v-for="(additional_question, index) in detail_candidate.question_answers" :key="index">
                            <div class="uk-width-1-5 uk-flex">
                                <p class="text-f12-l18">{{ additional_question.question }}</p>
                            </div>
                            <div class="uk-width-4-5 uk-flex uk-flex-column">
                                <p v-if="additional_question.type !== 3" 
                                    class="text-f12-l18"
                                    :class="[additional_question.answer && additional_question.answer.length > 0 && additional_question.answer[0] ? 'uk-text-bold' : 'uk-text-italic']"
                                >
                                    {{ additional_question.answer[0] || "Candidate didn’t answer this question (the field wasn’t required)"}}
                                </p>
                                <div v-else>
                                    <div v-if="additional_question.answer.length > 0">
                                        <p class="text-f12-l18 uk-text-bold" v-for="(answer, answerIdx) in additional_question.answer" :key="answerIdx">
                                            {{ `${answerIdx+1}. ${answer}` }}
                                        </p>
                                    </div>
                                    <p v-else class="text-f12-l18 uk-text-italic">
                                        {{ "Candidate didn’t answer this question (the field wasn’t required)" }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-else class="uk-margin-remove uk-text-italic text-f12-l18">This vacancy has no additional question(s).</p>
                </div>
            </div>
        </div>
        <NotesModal
            v-if="modal.isShowNotesModal"
            title="Interview Notes"
            :body="detail_candidate && detail_candidate.interview_notes ? detail_candidate.interview_notes : '-'"
            @hideModal="modal.isShowNotesModal = false"
        />
        <ConfirmModal
            v-if="modal.isShowConfirmModal"
            title="Confirmation"
            body="Are you sure want to proceed?"
            @hideModal="modal.isShowConfirmModal = false"
            @confirmProcess="confirmProcess"
        />
        <InterviewModal
            v-if="modal.isSHowInterviewModal"
            :id="$route.params.job_apply_id"
            :isMulti="false"
            @hideModal="modal.isSHowInterviewModal = false"
            @onSave="confirmProcess"
        />
        <InterviewDoneModal
            v-if="modal.isSHowDoneInterviewModal"
            :id="$route.params.job_apply_id"
            :isMulti="false"
            @hideModal="modal.isSHowDoneInterviewModal = false"
            @onSave="confirmProcess"
        />
        <PassedModal
            v-if="modal.isShowPassedModal"
            :id="$route.params.job_apply_id"
            :isMulti="false"
            :office_id="
                (job_data.initiation && job_data.initiation.office && job_data.initiation.office._id)
                ? job_data.initiation.office._id
                : null"
            @hideModal="modal.isShowPassedModal = false"
            @onSave="confirmProcess"
        />
        <ResendContractModal
            v-if="modal.isShowResendContractModal"
            :id="$route.params.job_apply_id"
            :isMulti="false"
            @hideModal="modal.isShowResendContractModal = false"
            @onSave="confirmProcess"
        />
        <RejectedModal
            v-if="modal.isShowRejectedModal"
            :id="$route.params.job_apply_id"
            :isMulti="false"
            @hideModal="modal.isShowRejectedModal = false"
            @onSave="confirmProcess"
        />
        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
        <PreviewPDFModal
            v-if="modal.isShowPreviewPDFModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewPDFModal = false"
        />
        <PreviewOfficeModal
            v-if="modal.isShowPreviewOfficeModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewOfficeModal = false"
        />
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { DateTime } from 'luxon';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';
import { contentType } from '@/utils/helper';
import formatter from "@/utils/formatter";
import moment from 'moment';
// Modal
import ConfirmModal from '@/components/globals/modals/SimpleConfirm';
import InterviewModal from '@/components/pages/admin/job_applicant/modals/InterviewModal';
import InterviewDoneModal from '@/components/pages/admin/job_applicant/modals/InterviewDoneModal';
import PassedModal from '@/components/pages/admin/job_applicant/modals/PassedModal';
import RejectedModal from '@/components/pages/admin/job_applicant/modals/RejectedModal';
import ResendContractModal from '@/components/pages/admin/job_applicant/modals/ResendContractModal';
import NotesModal from '@/components/pages/admin/job_applicant/modals/InfoModal';
import PreviewImageModal from '@/components/globals/modals/ImageViewer.vue';
import PreviewPDFModal from '@/components/globals/modals/PdfViewer.vue';
import PreviewOfficeModal from '@/components/globals/modals/OfficeViewer.vue';
import LoadingTable from '@/components/globals/tables/Loading';
import EmptyTable from '@/components/globals/tables/EmptyTable';

export default {
    name: 'DetailCandidate',
    components: {
        ConfirmModal,
        InterviewDoneModal,
        InterviewModal,
        PassedModal,
        RejectedModal,
        ResendContractModal,
        NotesModal,
        PreviewPDFModal,
        PreviewImageModal,
        PreviewOfficeModal,
        LoadingTable,
        EmptyTable
    },
    data(){
        return {
            tab: 1,
            actionState: null,
            modal: {
                isShowConfirmModal: false,
                isSHowInterviewModal: false,
                isSHowDoneInterviewModal: false,
                isShowPassedModal: false,
                isShowRejectedModal: false,
                isShowResendContractModal: false,
                isShowPreviewImageModal: false,
                isShowPreviewPDFModal: false,
                isShowPreviewOfficeModal: false,
                isShowNotesModal: false
            },
            datetime: DateTime,
            isLoading: false,
            isLoadingOtherJob: false,
            baseUrlImg: PREFIX_IMAGE,
            button_download_cv_loading: false,
            detail_candidate: null,
            detail_other_job: null,
            candidate_educations: null,
            candidate_experiences: null,
            previousApplicantId: null,
            nextApplicantId: null,
            job_data: {
                docs: [],
                totalDocs: 0,
                initiation: null
            },
            meta: {
                limit: 1,
                page: 1,
                job_id: null,
            },
            other_job : {
                meta: {
                    limit: 1000,
                    page: 1,
                    sorting: ""
                }
            }
        };
    },
    watch: {
        async '$route'(){
            this.isLoading = true;
            try {
                await this.getCandidateDetail();
                await this.getDetailOtherJobApplicant();
                await this.getApplicantList();
                await this.setSiblingApplicant();
            } catch (error) {
                notificationDanger(error);
            }
            this.isLoading = false;
        }
    },
    computed: {
        ...mapGetters({
            job_applicant_list: 'job_applicant/job_applicant_list'
        }),
        additionalDoc() {
            return !this.detail_candidate.cv.other_document || !this.detail_candidate.cv.other_document.filter(d => d.doc_name).length > 0
                ? null
                : this.detail_candidate.cv.other_document.filter(d => d.doc_name);
        },
        buttonDisabledAfterInterview() {
            if (this.formDataAfterInterview.status == 5) {
                return true;
            } else if (this.formDataAfterInterview.status == 5 || this.formDataAfterInterview.status == 6) {
                if (this.formDataAfterInterview.start_date == null || this.formDataAfterInterview.end_date == null || this.formDataAfterInterview.shift_id == null) {
                    return true;
                } else {
                    return false;
                }
            } else if (this.formDataAfterInterview.status == 3) {
                return false;
            } else {
                return true;
            }
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.change-status-modal')) window.UIkit.modal('.change-status-modal').$destroy(true);
        if (window.UIkit.modal('.interview-modal')) window.UIkit.modal('.interview-modal').$destroy(true);
        if (window.UIkit.modal('.after-interview-modal')) window.UIkit.modal('.after-interview-modal').$destroy(true);
    },
    async mounted() {
        this.isLoading = true;
        try {
            await this.getCandidateDetail();
            await this.getDetailOtherJobApplicant();
            await this.getApplicantList();
            await this.setSiblingApplicant();
        } catch (error) {
            notificationDanger(error);
        }
        this.isLoading = false;
    },
    updated(){
        this.getQueryAccordion();
    },
    methods: {
        ...mapActions({
            getDetailCandidate: 'job_applicant/getDetailCandidate',
            getDetailOtherJob: 'job_applicant/getDetailOtherJob',
            downloadCVApplicant: 'job_applicant/downloadCVApplicant',
            getJobApplicantList: 'job_applicant/getJobApplicantList',
            getJobApplicants: 'applicant/getJobApplicants',
            pickupApplicant: 'applicant/pickupApplicant',
        }),
        moveUser(state) {
            const openModal = {
                "1": "isShowConfirmModal",
                "4": "isSHowInterviewModal",
                "5": "isSHowDoneInterviewModal",
                "6": "isShowPassedModal",
                "3": "isShowRejectedModal",
                "8": "isShowResendContractModal",
            };

            this.actionState = state;
            this.modal[openModal[state]] = true;
        },
        async confirmProcess() {
            const openModal = {
                "1": "isShowConfirmModal",
                "4": "isSHowInterviewModal",
                "5": "isSHowDoneInterviewModal",
                "6": "isShowPassedModal",
                "3": "isShowRejectedModal",
                "8": "isShowResendContractModal",
            };

            this.modal[openModal[this.actionState]] = false;

            if (this.actionState === 1) {
                this.pickedUpApplicant();
            } else {
                await this.getCandidateDetail();
            }
        },
        async pickedUpApplicant() {
            this.isLoading = true;
            try {
                const response = await this.pickupApplicant(this.$route.params.job_apply_id);
                if (response && response.status === 'OK') {
                    notificationSuccess('Picked up!');
                    await this.getCandidateDetail();
                } else {
                    notificationDangerCustom("Failed to picked up applicant!");
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.isLoading = false;
        },
        async downloadCV({ userId }) {
            this.button_download_cv_loading = true;
            try {
                const response = await this.downloadCVApplicant({ userId });
                if (response && response.status === 'OK') {
                    window.open(response.result.cv_path.file_url, '_blank');
                    notificationSuccess('CV downloaded!');
                } else {
                    notificationDangerCustom('Failed to download CV');
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.button_download_cv_loading = false;
        },
        async getCandidateDetail() {
            const response = await this.getDetailCandidate({ jobApplyId: this.$route.params.job_apply_id });
            if (response && response.status === 'OK') {
                this.detail_candidate = response.result;
                this.candidate_educations = response.result.cv.educations;
                this.candidate_experiences = response.result.cv.work_experiences;
            }
        },
        async getDetailOtherJobApplicant() {
            if (this.detail_candidate && this.detail_candidate.user && this.detail_candidate.user._id) {
                this.isLoadingOtherJob = true;
                const response = await this.getDetailOtherJob({ id: this.detail_candidate.user._id, ...this.other_job.meta});
                if (response && response.status === 'OK') {
                    this.detail_other_job = response.result.docs;
                    this.isLoadingOtherJob = false;
                }
            }
        },
        async getApplicantList() {
            if (this.detail_candidate && this.detail_candidate.job && this.detail_candidate.job._id) {
                this.meta.job_id = this.detail_candidate.job._id;
                const response = await this.getJobApplicants(this.meta);
                this.job_data.docs = response.docs;
                this.job_data.totalDocs = response.totalDocs;
                this.job_data.initiation = response.initiation;
            }
        },
        async setSiblingApplicant() {
            let jobApplicantIds = [];
            await this.getJobApplicantList({id: this.detail_candidate.job._id});
            jobApplicantIds = this.job_applicant_list.docs.map(jobApplicant => jobApplicant._id);
            const currentIndex = jobApplicantIds.indexOf(this.$route.params.job_apply_id);
            this.previousApplicantId = currentIndex - 1 >= 0 ? jobApplicantIds[currentIndex - 1] : null;
            this.nextApplicantId = currentIndex + 1 <= jobApplicantIds.length - 1 ? jobApplicantIds[currentIndex + 1] : null;
        },
        goToJobApplicantDetail() {
            let applicantFilter = {
                role: '',
                company_office: '',
                title: '',
                address: '',
                status: '',
                limit: 10,
                page: 1
            };
            if (localStorage.getItem(`$filter ${this.$route.name}`)) {
                applicantFilter = JSON.parse(localStorage.getItem(`$filter Job Applicant Detail`));
            }
            this.$router.push({ path: `/admin/job_applicant/detail/${this.detail_candidate.job._id}`, query: applicantFilter });
        },
        calcYearJobExp({ date }) {
            let now = this.$moment();
            let years = now.diff(date, 'year');
            let months = now.diff(date, 'months');
            return years+ ' Year, ' + months + ' Month';
        },
        listStatus({ status }) {
            switch (status) {
            case 0:
                return 'New Applicant';
            case 1:
                return 'Picked Up Applicant';
            case 3:
                return 'Reject Applicant';
            case 4:
                return 'Invite to Interview';
            case 5:
                return 'Interviewed Applicant';
            case 6:
                return 'Contract Sent';
            case 7:
                return 'Signed Applicant';
            }
        },
        candidateDatetime({ date }) {
            return this.$moment.utc(date).add(7, 'hours').format('DD MMM YYYY HH:mm:ss');
        },
        openImage(data) {
            this.previewSrc = data.file_url;
            this.modal[contentType(this.previewSrc)] = true;
        },
        getExpTime({workExperiences}) {
            let start = null;
            let end = null;
            for (const exp of workExperiences) {
                if (start && moment(start).isAfter(exp.start_date)) start = exp.start_date;
                else if (!start) start = exp.start_date;

                if (exp.present) end = moment();
                else if (end && moment(end).isBefore(exp.end_date)) end = exp.end_date;
                else if (!end) end = exp.end_date;
            }
            return this.calcYearJobExp({dateStart: start, dateEnd: end});
        },
        imgError(e){
            e.target.src = `${this.images}/avatar.svg`;
        },
        getGender(val){
            let data = '';
            if (val === 'female') {
                data = 'Perempuan';
            } else if(val === 'male') {
                data = 'Laki-laki';
            }
            return data;
        },
        getBirthPlaceAndDate(place, date){
            if (place === null || date === null) {
                return '-';
            }
            const birthdate = moment(date).format('DD MMMM YYYY');

            return formatter.toTitleCase(place) + ', ' + birthdate;
        },
        toTitleCase(string){
            return formatter.toTitleCase(string);
        },
        getYears({date1, date2 = null}){
            const lastDate = date2 ? date2 : new Date();
            return formatter.diffYears(date1, lastDate);
        },
        getYearsExperiences({data}){
            let year = 0;
            for (let i = 0; i < data.length; i++) {
                const lastDate = data[i].end_date ? data[i].end_date : new Date();
                year += formatter.diffYears(data[i].start_date, lastDate);
            }
            return year;
        },
        gotoTab(tab){
            this.tab = tab;
        },
        getEducationalTime({startDate, endDate = null}){
            const start = moment(startDate).format('MMMM YYYY');
            const end = endDate || endDate !== '' ? moment(endDate).format('MMMM YYYY') : 'Present';
            const concat = start + ' - ' + end;
            return concat;
        },
        getTypeOfFile(url){
            let type = 'image';
            const contentType = url.split(".").slice(-1)[0];
            if (['doc', 'docx', 'pdf','pptx'].includes(contentType)) {
                type = 'document';
            }
            return type;
        },
        formatMoment(date){
            return moment(date);
        },
        isFilterChanged(){
            this.getDetailOtherJobApplicant();
        },
        getQueryAccordion(){
            const accordion = this.$route.query.accordion ?? null;
            if (accordion) { 
                document.getElementById(accordion).checked = true;
            }
        },
        trimWords(text, limit){
            let words = text.split(' ');
            let trimmedText = '';
            for (let i = 0; i < limit; i++) {
                trimmedText = words.slice(0, limit).join(' ');
            }
            if (words.length > limit) {
                trimmedText += '...';
            }
            return trimmedText;
        }
    }
};
</script>

<style scoped>
    .myrobin-background-primary {
        background-color: #0ABAB5;
    }

    .myrobin-background-success {
        background-color: #22CC8E;
    }
    .myrobin-background-danger {
        background-color: #DF5C70;
    }
    .myrobin-background-secondary {
        background-color: #0275D8;
    }

    .myrobin-color-white {
        color: white;
    }

    .list-candidate {
        margin-left: 36px;
    }

    .filter-blue {
        color: #0275D8;
        filter: invert(33%) sepia(89%) saturate(3239%) hue-rotate(192deg) brightness(91%) contrast(98%)
    }

    .filter-gray {
        color: #979797;
        filter: invert(75%) sepia(0%) saturate(267%) hue-rotate(144deg) brightness(80%) contrast(92%);
    }

    .border-black {
        border: 1px solid black;
    }
    .icon-white {
        fill: #FFFFFF;
    }
    .padding-18px {
        padding: 18px;
    }
    .vacancy-title {
        border-radius: 10px;
        border: 1px solid #FEBE10;
        background: rgba(254, 190, 16, 0.25);
        padding: 10px;
    }
    .photo {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }
    .status-apply {
        display: inline-flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        color: white;
    }
    .status-apply-success {
        background: #00B500;
    }
    .status-apply-process {
        background: #3586FF;
    }
    .status-apply-warning {
        background: #FFC700;
    }
    .status-apply-danger {
        background: #DF5C70;
    }
    span.icon {
        align-items: center;
        margin-right: .7rem;
    }
    .applicant-progress {
        border-radius: 10px;
        border: 1px solid #969696;
    }
    .title-progress {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
    .border-grey {
        border: 1px solid #969696;;
    }
    .tab {
        background: #F2F2F2;;
    }
    .active-tab {
        border: 0px;
        background: #0078D1;
        font-weight: 600;
        color: #FFFFFF;
    }
    .remove-border-top {
        border-top: 0px;
    }
    .candidate-icon::before {
        content: url('~@/assets/job_applicant/detail/iconamoon_profile-fill.svg');
    }
    .document-qualify-icon::before {
        content: url('~@/assets/job_applicant/detail/ph_seal-check-fill.svg');
    }
    .experiences-icon::before {
        content: url('~@/assets/job_applicant/detail/maki_suitcase.svg');
    }
    .educations-icon::before {
        content: url('~@/assets/job_applicant/detail/graduate.svg');
    }
    .skills-icon::before {
        content: url('~@/assets/job_applicant/detail/skills.svg');
    }
    .accordion-drawer__content {
        padding-left: 48px;
    }
    .myrobin-text-949494 {
        color: #949494;
    }
    .text-f12-l24 {
        font-size: 12px;
        line-height: 24px;
    }
    .text-f12-l18 {
        font-size: 12px;
        line-height: 18px;
    }
    .college-red {
        background-color: #D8020F !important;
    }
    .college-blue {
        background-color: #175B9A !important;
    }
    .college-grey {
        background-color: #B7B7B7 !important;
    }
    .college-yellow {
        background-color: #FEBE10;
    }
    .stepper-line {
        --tw-bg-opacity: 1;
        background-color: rgba(218,218,218,var(--tw-bg-opacity));
        width: .125rem;
        height: auto;
    }
    .stepper-line.margin-top-22 {
        margin-top: 22px;
    }
    .content-stepper {
        row-gap: .625rem;
        display: grid;
    }
    .stepper-circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #878787; 
        border-radius: 100%;
        margin-top: 6px;
        margin-left: -6px;
    }
    .align-self-center {
        align-self: center;
    }
</style>
