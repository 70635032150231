<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable uk-heigth-1-1">
        <div v-if="$can('READ', 'worker-kyc') || $can('WRITE', 'worker-kyc')" class="uk-width-1-1 uk-height-1-1">
            <!-- Tab button -->
            <section class="uk-margin">
                <button
                    v-for="(bMenu, bKey) in buttonMenus"
                    :key="bKey"
                    class="uk-button uk-button-large uk-margin-right uk-margin-small-bottom uk-border-rounded"
                    :class="meta.status === bMenu.value ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default uk-text-muted'"
                    @click="changeActiveTab(bMenu.value)"
                >{{ bMenu.text }}</button>
            </section>

            <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
                <div uk-spinner="ratio: 2"></div>
            </div>
            <div v-else class="uk-height-1-1">
                <!-- Filter -->
                <!-- ROW 1 -->
                <div class="uk-flex uk-flex-left" style="gap: 10px">
                    <div class="uk-flex">
                        <!-- select domicile city -->
                        <Multiselect
                            v-model="filter.city"
                            id="city"
                            name="city"
                            :options="cities"
                            label="name"
                            track-by="_id"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            placeholder="Select City"
                            :select-label="null"
                            :deselect-label="null"
                        >
                            <span slot="noResult">Data not found</span>
                        </Multiselect>
                    </div>
                    <div class="uk-flex">
                        <!-- filter by warehouse -->
                        <Multiselect
                            v-model="filter.company_office"
                            id="company_office"
                            name="company_office"
                            :options="filtered_offices"
                            label="name"
                            track-by="_id"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :disabled="!meta.domicile_city_id"
                            placeholder="Select Office"
                            :select-label="null"
                            :deselect-label="null"
                        >
                            <span slot="noResult">Data not found</span>
                        </Multiselect>
                    </div>
                    <div class="uk-flex">
                        <Multiselect
                            v-model="filter.role"
                            id="role"
                            name="role"
                            :options="roleOptions"
                            :loading='isFetching'
                            label="name"
                            track-by="_id"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :disabled="!meta.domicile_city_id"
                            :select-label="null"
                            :deselect-label="null"
                            placeholder="Select Role"
                        >
                            <span slot="noResult">Data not found</span>
                        </Multiselect>
                    </div>
                </div>

                <div>
                    <!-- ROW 2 -->
                    <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
                        <div class="uk-flex" style="gap: 10px">
                            <!-- filter by identity -->
                            <form @submit.prevent="filterKYCMitraList">
                                <input
                                    v-if="filter.identity_filter == 'name'"
                                    type="text"
                                    class="uk-input"
                                    placeholder="Search Mitra Name"
                                    v-model="meta.fullname"
                                    style="width: 13rem;"
                                >
                                <input
                                    v-if="filter.identity_filter == 'identity_number'"
                                    type="text"
                                    class="uk-input"
                                    placeholder="Search Mitra NIK"
                                    v-model="meta.identity_number"
                                    style="width: 13rem;"
                                >
                                <input
                                    v-if="filter.identity_filter == 'email'"
                                    type="text"
                                    class="uk-input"
                                    placeholder="Search Mitra Email"
                                    v-model="meta.email"
                                    style="width: 13rem;"
                                >
                                <input
                                    v-if="filter.identity_filter == 'phone_number'"
                                    type="text"
                                    class="uk-input"
                                    placeholder="Search Mitra Phone Number"
                                    v-model="meta.phone_number"
                                    style="width: 13rem;"
                                >
                            </form>
                            <select name="identity_filter" id="identity_filter" class="uk-select" v-model="filter.identity_filter" style="width: 13rem;">
                                <option
                                    v-for="(filter, id) in idFilterOptions"
                                    :key="id"
                                    :value="filter.id"
                                >{{ filter.label }}</option>
                            </select>
                            <!-- filter by condition -->
                            <form @change="filterKYCMitraList()">
                                <select name="filter" id="filter" class="uk-select" v-model="meta.filter" style="width: 13rem;">
                                    <option value="" selected>Filter by ...</option>
                                    <option
                                        v-for="(filter, id) in filterOptions"
                                        :key="id"
                                        :value="filter.id"
                                    >{{ filter.label }}</option>
                                </select>
                            </form>
                            <!-- sort data -->
                            <form @change="filterKYCMitraList()">
                                <select name="office" id="office" class="uk-select" v-model="meta.sort_by" style="width: 13rem;">
                                    <option value="" selected>Sort by ...</option>
                                    <option
                                        v-for="(sort, id) in sortOptions"
                                        :key="id"
                                        :value="sort.id"
                                    >{{ sort.label }}</option>
                                </select>
                            </form>
                        </div>
                    </div>
                <!-- End filter -->
                </div>

                <div v-if="isMitraLoading || paginationLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
                    <div uk-spinner="ratio: 2"></div>
                </div>
                <template v-else>
                    <div v-if="mitraList.docs.length < 1">
                        <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                            <div
                                class="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-width-1-1 uk-text-italic"
                                style="font-size: 20px; color: #A7A7A7;"
                            >
                                <img :src="`${images}/briefcase.svg`" alt="No worker found" class="uk-margin-bottom">
                                <p class="uk-margin-remove">No result found!</p>
                                <p v-if="onFilter" class="uk-margin-remove">Sorry, there is no data to be shown with your filter settings.</p>
                                <p v-else class="uk-margin-remove">Sorry, there is no Mitras recorded for this company.</p>
                                <p v-if="onFilter">Please reorganize your filter settings now!</p>
                            </div>

                        </div>
                    </div>
                    <div v-else class="uk-margin-top uk-flex uk-flex-column" style="gap: 20px">
                        <div v-for="(mitra, index) in mitraList.docs" :key="index" class="uk-card uk-card-default uk-flex uk-flex-middle" style="border-radius: 10px;">
                            <MitraItem
                                :mitra-data="mitra"
                            />
                        </div>
                    </div>
                </template>
                <!-- Pagination -->
                <div v-if="!isMitraLoading" class="uk-margin-top">
                    <pagination
                        :total-data="mitraList.totalDocs"
                        :limit-page="meta.limit"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <!-- End pagination -->

            </div>
        </div>

        <!-- Handler when user can't access this page -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End handler when user can't access this page -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PREFIX_IMAGE } from "@/utils/constant";
import Multiselect from 'vue-multiselect';
import { notificationDanger } from "@/utils/notification";

export default {
    name: 'KYC',
    components: {
        Multiselect,
        MitraItem: () => import('@/components/globals/kyc/MitraItem'),
        Pagination: () => import('@/components/globals/Pagination'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            cities: [],
            filtered_offices: [],
            roleOptions: [],
            mitraList: {
                docs: [],
                totalDocs: 0,
            },
            isLoading: false,
            isFetching: false,
            isMitraLoading: false,
            paginationLoading: false,
            buttonMenus: [
                { text: 'Ready to Validate', value: 'unvalidated' },
                { text: 'Succesfully Validated', value: 'validated' },
                { text: 'All Mitra', value: 'all' },
            ],
            idFilterOptions: [
                {
                    id: 'name',
                    label: 'Search by Name'
                },
                {
                    id: 'identity_number',
                    label: 'Search by NIK'
                },
                {
                    id: 'email',
                    label: 'Search by Email'
                },
                {
                    id: 'phone_number',
                    label: 'Search by Phone Number'
                },
            ],
            filterOptions: [
                {
                    id: 'silver',
                    label: 'Silver'
                },
                {
                    id: 'gold',
                    label: 'Gold'
                },
                {
                    id: 'platinum',
                    label: 'Platinum'
                },
            ],
            sortOptions: [
            // this sort options is mismatched with the one on workers-mitra page
            // the cause: different BE, different interpretion on sort
                {
                    id: 'start-desc',
                    label: 'Latest Contract Start Date'
                },
                {
                    id: 'start-asc',
                    label: 'Oldest Contract Start Date'
                },
                {
                    id: 'end-desc',
                    label: 'Latest Contract End Date'
                },
                {
                    id: 'end-asc',
                    label: 'Oldest Contract End Date'
                },
                {
                    id: 'name-asc',
                    label: 'A-Z'
                },
                {
                    id: 'name-desc',
                    label: 'Z-A'
                },
            ],
            filter: {
                city: null,
                company_office: null,
                role: null,
                identity_filter: 'name',
            },
            meta: {
                domicile_city_id: null,
                company_office_id: null,
                role_id: null,
                status: '',
                fullname: null,
                identity_number: null,
                email: null,
                phone_number: null,
                filter: '',
                limit: 10,
                page: 1,
                sort_by: '',
            },
        };
    },
    computed: {
        ...mapGetters({
            company_offices: "company_office/company_offices",
            roles: "option_data/roles",
        }),
        onFilter() {
            let filterApplied = false;

            if (
                this.meta.domicile_city_id || this.meta.company_office_id ||
                this.meta.role_id || this.meta.fullname || this.meta.identity_number ||
                this.meta.email || this.meta.phone_number || this.meta.filter
            ) {
                filterApplied = true;
            }

            return filterApplied;
        }
    },
    watch: {
        meta() {
            this.$router.push({ name: this.$route.name, query: this.meta });
        },
        'filter.identity_filter'() {
            this.meta.fullname = '';
            this.meta.email = '';
            this.meta.identity_number = '';
        },
        async 'filter.city'(val) {
            if (val) {
                this.meta.domicile_city_id = val._id;
            } else {
                this.meta.domicile_city_id = '';
                this.filter.role = null;
            }
            await this.filterOffice();
        },
        async 'filter.company_office'(val) {
            if (val) {
                this.meta.company_office_id = val._id;
            } else {
                this.meta.company_office_id = '';
                this.filter.role = null;
            }
            await this.filterKYCMitraList();
        },
        async 'filter.role'(val) {
            if (val) {
                this.meta.role_id = val._id;
            } else {
                this.meta.role_id = null;
            }
            await this.filterKYCMitraList();
        },
    },
    async mounted() {
        try {
            this.isLoading = true;

            await this.getCompanyOffice();
            this.setCity();
            this.mapQuery();
            await this.filterKYCMitraList();

            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getKYCMitraList: "kyc/getKYCMitraList",
            getCompanyOffice: "company_office/getCompanyOffice",
            getRolesByOffice: "option_data/getRolesByOffice",
            getRoles: "option_data/getRoles",
        }),
        async filterKYCMitraList() {
            this.isMitraLoading = true;

            this.meta.page = 1;
            await this.fetchMitraKYC();

            this.isMitraLoading = false;
        },
        async fetchMitraKYC() {
            this.paginationLoading = true;

            let payload = {...this.meta};
            payload.validation_status = payload.status;
            delete payload.status;
            const result = await this.getKYCMitraList(payload);
            if (result) {
                this.mitraList.docs = result.docs;
                this.mitraList.totalDocs = result.totalDocs;
            } else {
                notificationDanger('Failed to get KYC mitra list.');
                this.mitraList.docs = [];
                this.mitraList.totalDocs = 0;
            }

            this.paginationLoading = false;
        },
        async changeActiveTab(status) {
            this.resetMeta();
            this.meta.status = status;
            await this.filterKYCMitraList();
        },
        setCity() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }

            this.filtered_offices = [];
        },
        async filterOffice() {
            try {
                this.meta.company_office_id = '';
                this.filter.company_office = null;

                if (this.company_offices && this.company_offices.length > 0) {
                    const offices = this.company_offices.filter((office) => {
                        if (
                            (office.domicile_city_id && office.domicile_city_id._id) &&
                            (this.meta.domicile_city_id) &&
                            (office.domicile_city_id._id === this.meta.domicile_city_id)
                        ) {
                            return office;
                        }
                    });
                    this.filtered_offices = offices;
                }

                if (this.filtered_offices.length > 0) {
                    this.filtered_offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

                    const officeIds = this.filtered_offices.map(office => office._id);
                    await this.setRoleOptions({ officeIds });
                }

                if (this.$route.query.company_office_id && this.filtered_offices.length > 0) {
                    this.meta.company_office_id = this.$route.query.company_office_id;
                    this.filter.company_office = this.filtered_offices.find(office => office._id == this.meta.company_office_id);
                }

                await this.filterKYCMitraList();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setRoleOptions({ officeIds }) {
            try {
                this.isFetching = true;
                if (officeIds && officeIds.length > 0) {
                    const roleOptions = [];

                    const [roleByOffice] = await Promise.all([
                        this.getRolesByOffice({ company_office_ids: officeIds }),
                        this.getRoles()
                    ]);

                    if ((roleByOffice && roleByOffice.length > 0) && (this.roles && this.roles.length > 0)) {
                        roleByOffice.forEach((roleId) => {
                            this.roles.forEach((role) => {
                                if ((roleId) && (role && role._id) && (roleId === role._id)) {
                                    roleOptions.push(role);
                                }
                            });
                        });
                    }

                    this.roleOptions = roleOptions;
                }

                if (this.roleOptions.length > 0) {
                    this.roleOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }

                if (this.$route.query.role_id && this.roleOptions.length > 0) {
                    this.meta.role_id = this.$route.query.role_id;
                    this.filter.role = this.roleOptions.find(role => role._id == this.meta.role_id);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetching = false;
            }
        },
        mapQuery() {
            if (this.$route.query.status) {
                this.meta.status = this.$route.query.status;
            } else {
                this.meta.status = 'unvalidated';
            }
            if (this.$route.query.domicile_city_id) {
                this.meta.domicile_city_id = this.$route.query.domicile_city_id;
                this.filterOffice();
            }
            if (this.$route.query.fullname) {
                this.meta.fullname = this.$route.query.fullname;
            }
            if (this.$route.query.identity_number) {
                this.meta.identity_number = this.$route.query.identity_number;
            }
            if (this.$route.query.email) {
                this.meta.email = this.$route.query.email;
            }
            if (this.$route.query.phone_number) {
                this.meta.phone_number = this.$route.query.phone_number;
            }
            if (this.$route.query.filter) {
                this.meta.filter = this.$route.query.filter;
            }
            if (this.$route.query.sort_by) {
                this.meta.sort_by = this.$route.query.sort_by;
            }
            if (this.$route.query.limit) {
                this.meta.limit = Number(this.$route.query.limit);
            }
            if (this.$route.query.page) {
                this.meta.page = Number(this.$route.query.page);
            }
            if (this.$route.query.identity_filter) {
                this.filter.identity_filter = this.$route.query.identity_filter;
            }
        },
        resetMeta() {
            this.meta = {
                domicile_city_id: null,
                company_office_id: null,
                role_id: null,
                status: '',
                fullname: null,
                identity_number: null,
                email: null,
                phone_number: null,
                filter: '',
                limit: 10,
                page: 1,
                sort_by: '',
            };
        },
        async changeLimit(e) {
            this.meta = {
                ...this.meta,
                page: 1,
                limit: e.target.value,
            };
            await this.filterKYCMitraList();
        },
        async changePage(value) {
            this.meta = {
                ...this.meta,
                page: value,
            };
            await this.fetchMitraKYC();
        },
    }
};
</script>

<style scoped>
.multiselect {
    width: 20rem;
}
</style>
