<template>
    <div class="uk-container uk-padding-small uk-container-expand card-scrollable">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-flex uk-flex-middle">
                <button 
                    class="uk-button uk-border-rounded"
                    :class="[activeTab == 'mitra_attendance' ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default myrobin-color-grey']"
                    @click="setActiveTabMenu('mitra_attendance')"
                >Mitra Attendance</button>
                <button 
                    class="uk-button uk-button-default uk-border-rounded uk-margin-small-left"
                    :class="[activeTab == 'summary_attendance' ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default myrobin-color-grey']"
                    @click="setActiveTabMenu('summary_attendance')"
                >Summary Attendance</button>
            </div>
            <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-margin-top">
                <mitra-attendance-tab v-if="activeTab == 'mitra_attendance'" :shift-list-no-paginate="shiftListNoPaginate"></mitra-attendance-tab>
                <summary-attendance-tab v-if="activeTab == 'summary_attendance'" :shift-list-no-paginate="shiftListNoPaginate"></summary-attendance-tab>
            </div>
            <div v-else class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationDanger } from '@/utils/notification';

export default {
    name: 'MitraAttendance',
    components: {
        MitraAttendanceTab: () => import('@/components/globals/MitraAttendance/MitraAttendanceTab'),
        SummaryAttendanceTab: () => import('@/components/globals/MitraAttendance/SummaryAttendanceTab')
    },
    data() {
        return {
            activeTab: null,
            isLoading: false,
            shiftListNoPaginate: []
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await Promise.all([
                this.setFirstActiveTabMenu(),
                this.setShiftNoPaginate()
            ]);
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getShiftListNoPaginate: 'new_shift/getShiftListNoPaginate'
        }),
        async setFirstActiveTabMenu() {
            try {
                if (this.$route.query.tab === undefined) {
                    await this.setQueryURL('mitra_attendance');
                }
                this.activeTab = this.$route.query.tab;
            } catch (error) {
                notificationDanger(error);
            }
        
        },
        async setActiveTabMenu(tab) {
            try {
                if (this.$route.query.tab !== undefined) {
                    await this.setQueryURL(tab);
                }
                this.activeTab = tab;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setQueryURL(tab) {
            try {
                await this.$router.replace({
                    path: '/admin/report/mitra_attendance',
                    query: {
                        tab
                    }
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setShiftNoPaginate() {
            try {
                this.shiftListNoPaginate = await this.getShiftListNoPaginate();
            } catch (error) {
                notificationDanger(error);
            }
        }
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}

th {
    color: #ffffff;
}

.myrobin-background-primary {
    background: #0ABAB5;
}

.myrobin-color-white {
    color: #FFFFFF;
}

.myrobin-color-grey {
    color: #9A9A9A;
}
</style>
