<template>
    <section>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div
                class="uk-modal-dialog uk-margin-auto-vertical"
            >
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="hideModal(false)"
                ></button>
                <div class="uk-modal-header uk-text-center">
                    <h4>Additional Questions</h4>
                </div>
                <div class="uk-modal-body uk-padding-remove-vertical" style="padding-bottom: 16px !important;" uk-overflow-auto>
                    <!-- <div>{{ body }}</div> -->
                    <div class="uk-flex uk-flex-column uk-width-1-1">
                        <div class="uk-margin-small-top" v-for="(question, index) in questions" :key="index">
                            <div class="uk-flex">
                                <p class="text-f12-l18 uk-margin-small-bottom">{{ `${index+1}. ${question.question}` }}</p>
                            </div>
                            <div v-if="question.type !== 3" class="uk-flex uk-padding-small uk-border-rounded" style="background-color: #f8f7f7">
                                <p
                                    class="text-f12-l18 uk-text-bold uk-text-default uk-margin-remove"
                                >
                                    {{
                                        question.answer[0]
                                    }}
                                </p>
                            </div>
                            <div v-else class="uk-flex uk-padding-small uk-border-rounded" style="background-color: #f8f7f7">
                                <p
                                    class="text-f12-l18 uk-text-bold uk-text-default uk-margin-remove"
                                >
                                    <ol>
                                        <li v-for="(answer, answerIdx) in question.answer" :key="answerIdx">{{ answer }}</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        questions: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit('hideModal');
        }
    }
};
</script>

<style scoped></style>
