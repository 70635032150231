import api from '@/utils/api';
import { SALARY_ADJUSTMENT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getSalaryAdjustments(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY_ADJUSTMENT.GET(data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createSalaryAdjustment(context, data) {
        try {
            const {job_partner_id, ...formData} = data;
            const response = await api.apiPostAuth(SALARY_ADJUSTMENT.CREATE(job_partner_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editSalaryAdjustment(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.EDIT(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteSalaryAdjustment(context, data) {
        try {
            const response = await api.apiDeleteAuth(SALARY_ADJUSTMENT.DELETE(data));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCashAdvances(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY_ADJUSTMENT.GET_CASH_ADVANCE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCashAdvanceDetail(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY_ADJUSTMENT.DETAIL_CASH_ADVANCE(data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async transferCashAdvance(context, data) {
        try {
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.TRANSFER_CASH_ADVANCE(data));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async approveCashAdvance(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.APPROVE_CASH_ADVANCE(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async rejectCashAdvance(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.REJECT_CASH_ADVANCE(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getReimbursements(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY_ADJUSTMENT.GET_REIMBURSEMENT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getReimbursementDetail(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY_ADJUSTMENT.DETAIL_REIMBURSEMENT(data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async transferReimbursement(context, data) {
        try {
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.TRANSFER_REIMBURSEMENT(data));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async approveReimbursement(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.APPROVE_REIMBURSEMENT(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async approveReimbursementVoucher(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.APPROVE_REIMBURSEMENT_VOUCHER(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async rejectReimbursement(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(SALARY_ADJUSTMENT.REJECT_REIMBURSEMENT(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions
};
