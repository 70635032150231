<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable uk-heigth-1-1">
        <div v-if="pageLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else class="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s" uk-grid>
            <div v-if="$can('READ', 'worker-leave-report')">
                <div v-if="isShow">
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20"> {{ cityName }}, {{ officeName }}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20"> Select Filter...
                    </div>
                </div>
                <p v-if="isShow" class="reset-filter" @click="resetLocation">Reset Filter</p>
            </div>
            <div v-else>
                <div v-if="isShow">
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20"> {{ cityName }}, {{ officeName }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isShow" class="uk-flex uk-margin uk-child-width-1-4" uk-grid>
            <div>
                <div class="uk-form-controls uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input
                        class="uk-input"
                        type="text"
                        placeholder="Search worker by name"
                        v-model="meta.fullname"
                        @keyup.enter="getMitraLeaveQuota"
                    >
                </div>
            </div>
            <div>
                <select
                    name="type"
                    class="uk-select"
                    v-model="view_category"
                >
                    <option value="all">All Leave Type</option>
                    <option value="0">Unpaid</option>
                    <option value="1">Paid</option>
                </select>
            </div>
            <div>
                <select
                    name="type"
                    class="uk-select"
                    v-model="meta.sort_by"
                    @change="getMitraLeaveQuota"
                >
                    <option value="" selected>Latest</option>
                    <option value="old" selected>Oldest</option>
                </select>
            </div>
        </div>

        <!-- start table body -->
        <div v-if="users" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table v-if="$can('READ', 'worker-leave-report')" class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand">Full name</th>
                            <th v-if="view_category !== '0'" class="uk-text-center">Paid Leave Request</th>
                            <th v-if="view_category !== '0'" class="uk-text-center">Paid Leave Quota</th>
                            <th v-if="view_category !== '1'" class="uk-text-center">Unpaid Leave Request</th>
                            <th v-if="view_category !== '1'" class="uk-text-center">Unpaid Leave Quota</th>
                            <th class="uk-table-expand">Office</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="users.docs.length > 0">
                        <tr v-for="(user, id) in users.docs" :key="id">
                            <td class="uk-text-primary" style="cursor: pointer;" @click="viewMitraLeaveRequest(user._id)">{{ user.fullname }}</td>
                            <td v-if="view_category !== '0'" class="uk-text-center">{{ user.paid_leave_request }}</td>
                            <td v-if="view_category !== '0'" class="uk-text-center">{{ user.paid_leave_quota }}</td>
                            <td v-if="view_category !== '1'" class="uk-text-center">{{ user.unpaid_leave_request }}</td>
                            <td v-if="view_category !== '1'" class="uk-text-center">{{ user.unpaid_leave_quota }}</td>
                            <td>{{ user.office_name }}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
        </div>
        <pagination
            v-if="users"
            :total-data="users.totalDocs"
            :limit-page="meta.limit"
            :change-limit="changeLimit"
            :change-page="changePage"
            :start-page="meta.page"
        />
        <!-- end table body -->

        <!-- Filter Modal -->
        <div id="location-filter" class="uk-flex-top filter-data" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('location-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center uk-text-medium">Select filter for leave report</h2>
                </div>
                <form @submit.prevent="submitFilterLocation">
                    <div class="uk-padding-small uk-padding-remove-bottom">
                        <section class="uk-margin">
                            <label for="selectCity" class="uk-form-label">City</label>
                            <select
                                id="selectCity"
                                name="selectCity"
                                class="uk-select select-input"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="">All cities</option>
                                <option
                                    v-for="(city, index) in cities"
                                    :key="index"
                                    :value="city"
                                >{{ city.name || '-' }}</option>
                            </select>
                        </section>
                        <section v-if="filterLoading">
                            <div uk-spinner :ratio="1"></div>
                        </section>
                        <section v-else class="uk-margin">
                            <label for="selectOffice" class="uk-form-label">Property</label>
                            <select id="selectOffice" name="selectOffice" class="uk-select select-input" v-model="company_office">
                                <option value="">All offices</option>
                                <option
                                    v-for="(office, index) in filtered_offices"
                                    :key="index"
                                    :value="office"
                                >{{ office.name || '-' }}</option>
                            </select>
                        </section>
                    </div>
                    <hr>
                    <section class="uk-text-right uk-padding-small uk-padding-remove-top">
                        <button class="uk-button uk-button-primary button-action-table" type="submit">Apply</button>
                    </section>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import LoadingTable from '@/components/globals/tables/Loading';
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    data() {
        return {
            meta: {
                limit: 10,
                page: 1,
                fullname: '',
                sort_by: '',
                company_office_id: null,
                domicile_city_id: null,
            },
            view_category: 'all',
            images: PREFIX_IMAGE,
            company_office: '',
            selected_city: '',
            cities: [],
            filtered_offices: [],
            users: null,
            pageLoading: false,
            isLoading: false,
            isShow: false,
            filterLoading: false,
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
    },
    watch: {
        meta: {
            handler() {
                this.$router.push({ name: this.$route.name, query: { ...this.$route.query, ...this.meta } }).catch(()=>{});
                this.getMitraLeaveQuota();
            },
            deep: true,
        },
        view_category() {
            this.$router.push({ name: this.$route.name, query: { ...this.$route.query, view_category: this.view_category } });
        },
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
        }),
        cityName() {
            let name = 'All Cities';
            if ((this.meta.domicile_city_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((office) => {
                    if (
                        (office && office.domicile_city_id && office.domicile_city_id._id) &&
                        (office.domicile_city_id._id === this.meta.domicile_city_id)
                    ) {
                        return office;
                    }
                });

                if (findCity && findCity.domicile_city_id && findCity.domicile_city_id.name) {
                    name = findCity.domicile_city_id.name;
                } else {
                    name = 'All Cities';
                }
            } else {
                name = 'All Cities';
            }

            return name;
        },
        officeName() {
            let name = 'All Offices';
            if ((this.meta.company_office_id) && (this.filtered_offices && this.filtered_offices.length > 0)) {
                const findOffice = this.filtered_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.meta.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findOffice && findOffice.name) {
                    name = findOffice.name;
                } else {
                    name = 'All Offices';
                }
            } else {
                name = 'All Offices';
            }

            return name;
        }
    },
    async mounted() {
        try {
            this.pageLoading = true;
            await this.getCompanyOffice();
            this.setCity();

            this.defaultMeta();
            // await this.getMitraLeaveQuota(); duplicate, handled in watch->meta
            this.pageLoading = false;
        } catch (error) {
            this.pageLoading = false;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.location-filter')) window.UIkit.modal('.location-filter').$destroy(true);
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getLeaveMitraList: 'leave/getLeaveMitraList',
        }),
        defaultMeta() {
            this.view_category = this.$route.query.view_category ?? 'all';
            this.meta = {
                limit: this.$route.query.limit ? parseInt(this.$route.query.limit) : 10,
                page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
                fullname: this.$route.query.fullname ?? '',
                sort_by: this.$route.query.sort_by ?? '',
                company_office_id: this.$route.query.company_office_id ?? null,
                domicile_city_id: this.$route.query.domicile_city_id ?? null
            };

            if (this.meta.domicile_city_id) {
                this.selected_city = this.cities.find((c) => c._id == this.meta.domicile_city_id);
                this.filterOffice();
            }
            if (this.meta.company_office_id) {
                this.company_office = this.filtered_offices.find((o) => o._id == this.meta.company_office_id);
            }

            this.isShow = true;
        },
        async getMitraLeaveQuota() {
            this.isLoading = true;
            this.users = await this.getLeaveMitraList(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'location-filter':
                this.setCity();
                await window.UIkit.modal('#location-filter').show();
                break;
            }
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'location-filter':
                await window.UIkit.modal('#location-filter').hide();
                break;
            }
        },
        viewMitraLeaveRequest(partner_id) {
            this.$router.push(`/admin/workers/leave/${partner_id}`);
        },
        setCity() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
            
            this.filtered_offices = [];

            if (this.selected_city) {
                this.filterOffice();
            }
        },
        filterOffice() {
            this.filterLoading = true;
            this.company_office = '';

            if (this.company_offices && this.company_offices.length > 0) {
                const offices = this.company_offices.filter((office) => {
                    if (
                        (office.domicile_city_id && office.domicile_city_id._id) && 
                        (this.selected_city && this.selected_city._id) &&
                        (office.domicile_city_id._id === this.selected_city._id)
                    ) {
                        return office;
                    }
                });
                this.filtered_offices = offices;
            }

            if (this.filtered_offices.length > 0) {
                this.filtered_offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
            if (this.meta.company_office_id) {
                const oldOffice = this.filtered_offices.find((o) => o._id == this.meta.company_office_id);
                this.company_office = oldOffice || '';
            }

            this.filterLoading = false;
        },
        async submitFilterLocation() {
            this.isShow = true;
            this.meta.domicile_city_id = this.selected_city && this.selected_city._id ? this.selected_city._id : null;
            this.meta.company_office_id = this.company_office && this.company_office._id ? this.company_office._id : null;
            // await this.getMitraLeaveQuota(); // duplicate, data already fetched via watch->meta

            await window.UIkit.modal('#location-filter').hide();
            this.$store.state.selected_company = this.company_office;
        },
        resetLocation() {
            this.isShow = false;
            this.users = null;
            this.cities = [];
            this.filtered_offices = [];
            this.selected_city = '';
            this.company_office = '';
            this.view_category = 'all';
            this.meta.domicile_city_id = null;
            this.meta.company_office_id = null;
            this.meta.fullname = '';
            this.meta.sort_by = '';
            this.meta.limit = 10;
            this.meta.page = 1;

            this.$router.replace(this.$route.path + '?reset');
            this.defaultMeta();
        },
    }
};
</script>

<style scoped>
.location-header {
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead {
    background-color: #0ABAB5;
}
th, .white-text {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter {
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
</style>
