import api from '@/utils/api';
import { TAX } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getTaxBatchList(context, data) {
        try {
            const response = await api.apiGetAuth(TAX.TAX_DEDUCTION_BATCH_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createTaxBatch(context, data) {
        try {
            const response = await api.apiPostAuth(TAX.TAX_DEDUCTION_BATCH_LIST, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getTaxBatchDetail(context, data) {
        try {
            const response = await api.apiGetAuth(TAX.MANAGE_TAX_BATCH(data.id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    // API for monthly tax deduction upload
    async uploadMonthlyTaxDeductionExcel(context, data) {
        try {
            const response = await api.apiPostAuth(TAX.UPLOAD_EXCEL_MONTHLY_TAX_DEDUCTION(data.get('id')), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    // API for yearly tax deduction upload
    async uploadYearlyTaxDeductionExcel(context, data) {
        try {
            const response = await api.apiPostAuth(TAX.UPLOAD_EXCEL_YEARLY_TAX_DEDUCTION(data.get('id')), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadPDFTaxProof(context, data) {
        try {
            const response = await api.apiPostAuth(TAX.UPLOAD_PDF_TAX_DEDUCTION(data.get('id')), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getTaxBatchUploadErrorLogs(context, id) {
        try {
            const response = await api.apiGetAuth(TAX.TAX_BATCH_ERROR_LOGS(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addMitraTaxDeduction(context, data) {
        try {
            const batch_id = data.batch_id;
            const is_monthly = data.is_monthly || false;
            delete data.batch_id;
            delete data.is_monthly;
            const response = await api.apiPostAuth(TAX.ADD_MITRA_TAX_DEDUCTION(batch_id, is_monthly), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editMitraTaxDeduction(context, data) {
        try {
            const batch_id = data.batch_id;
            const tax_id = data.tax_id;
            const is_monthly = data.is_monthly || false;
            delete data.batch_id;
            delete data.tax_id;
            delete data.is_monthly;
            const response = await api.apiPatchAuth(TAX.MANAGE_MITRA_TAX(batch_id, tax_id, is_monthly), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async distributeTaxDeductionBatch(context, id) {
        try {
            const response = await api.apiPatchAuth(TAX.DISTRIBUTE_TAX_BATCH(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async distributeMitraTaxProof(context, data) {
        try {
            const response = await api.apiPatchAuth(TAX.DISTRIBUTE_TAX_PROOF(data.batch_id, data.tax_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadMitraTaxProof(context, data) {
        try {
            const response = await api.apiGetAuth(TAX.DOWNLOAD_TAX_PROOF(data.batch_id, data.tax_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteTaxBatch(context, id) {
        try {
            const response = await api.apiDeleteAuth(TAX.MANAGE_TAX_BATCH(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteMitraTax(context, data) {
        try {
            const response = await api.apiDeleteAuth(TAX.MANAGE_MITRA_TAX(data.batch_id, data.tax_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async taxDataReportExcel(context, data) {
        try {
            const response = await api.apiGetAuth(TAX.TAX_REPORT_EXCEL(data.id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
