<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div class="uk-card uk-card-default uk-width-1-1@m uk-border-rounded">
            <div class="uk-padding-small">
                <div uk-grid>
                    <div class="uk-width-1-2">
                        <div class="uk-flex uk-flex-middle">
                            <img id="arrow-back" @click="goBack" :src="`${images}/arrow-back.svg`" height="26px" width="30px">
                            <p class="uk-margin-left">
                                <span class="uk-text-bold">
                                    {{ job_list.docs.length>0 ? job_list.docs[0].role.name : '-' }} - {{ job_list.docs.length>0 ? job_list.docs[0].office.name : 'null' }}
                                </span><br/>
                                <span class="uk-text-meta">{{ job_list.docs.length>0 ? job_list.docs[0].office.address : '-' }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="uk-width-1-2 uk-margin-top">
                        <div class="uk-flex uk-flex-right">
                            <p>
                                <span class="uk-text-meta">{{ job_list.docs.length>0 ? job_list.docs[0].job.title : '-' }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="uk-flex uk-flex-row-reverse uk-margin-bottom">
                    <button @click="downloadAllReport"
                        class="uk-button uk-button-default uk-text-nowrap uk-border-pill"
                        style="border: 1px solid #0abab5; color: #0abab5;"
                    >
                        Download Report
                    </button>
                </div>
                <div><hr></div>
                <div class="uk-flex uk-flex-wrap-around uk-margin-medium-top c-cursor">
                    <div @click="selectStatus('')" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === '' }"
                        class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ countAllApplicant(job_applicant_list.count_applicant) || 0 }}</h1>
                        <span>All Applicants</span>
                    </div>
                    <div class="uk-margin-right uk-text-center">
                        <hr class="uk-divider-vertical">
                    </div>
                    <div @click="selectStatus(0)" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === 0}"
                         class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ job_applicant_list.count_applicant ? job_applicant_list.count_applicant.total_new_applicant : 0 || 0 }}</h1>
                        <span>New Applicants</span>
                    </div>
                    <div class="uk-margin-right uk-text-center">
                        <br>
                        <img :src="`${images}/arrow-forward.svg`">
                    </div>
                    <div @click="selectStatus(1)" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === 1}"
                         class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ job_applicant_list.count_applicant ? job_applicant_list.count_applicant.total_picked_up_applicant : 0 || 0 }}</h1>
                        <span>Picked up Applicant</span>
                    </div>
                    <div class="uk-margin-right uk-text-center">
                        <br>
                        <img :src="`${images}/arrow-forward.svg`">
                    </div>
                    <div @click="selectStatus(4)" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === 4}"
                        class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ job_applicant_list.count_applicant ? job_applicant_list.count_applicant.total_invited_interview_applicant : 0 || 0 }}</h1>
                        <span>Invited to Interview</span>
                    </div>
                    <div class="uk-margin-right uk-text-center">
                        <br>
                        <img :src="`${images}/arrow-forward.svg`">
                    </div>
                    <div @click="selectStatus(5)" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === 5}"
                         class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ job_applicant_list.count_applicant ? job_applicant_list.count_applicant.total_interviewed_applicant : 0 || 0 }}</h1>
                        <span>Interviewed Applicant</span>
                    </div>
                    <div class="uk-margin-right uk-text-center">
                        <br>
                        <img :src="`${images}/arrow-forward.svg`">
                    </div>
                    <div @click="selectStatus(6)" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === 6}"
                         class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ job_applicant_list.count_applicant ? job_applicant_list.count_applicant.total_contract_sent_applicant : 0 || 0 }}</h1>
                        <span>Contract Sent</span>
                    </div>
                    <div class="uk-margin-right uk-text-center">
                        <br>
                        <img :src="`${images}/arrow-forward.svg`">
                    </div>
                    <div @click="selectStatus(7)" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === 7}"
                         class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ job_applicant_list.count_applicant ? job_applicant_list.count_applicant.total_signed_applicant : 0 || 0 }}</h1>
                        <span>Signed Applicant</span>
                    </div>
                    <div class="uk-margin-right uk-text-center">
                        <br>
                        <img :src="`${images}/arrow-forward.svg`">
                    </div>
                    <div @click="selectStatus(3)" :class="{'uk-box-shadow-medium selected-bg-color': isSelectedStatus === 3}"
                         class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                        <h1>{{ job_applicant_list.count_applicant ? job_applicant_list.count_applicant.total_rejected_applicant : 0 || 0 }}</h1>
                        <span>Rejected Applicant</span>
                    </div>
                </div>
            </div>
            <div><hr></div>
            <div class="uk-padding-small">
                <div class="uk-flex uk-flex-top">
                    <div class="uk-inline uk-margin-right uk-width-1-2 ">
                        <span class="uk-form-icon" uk-icon="icon: search"></span>
                        <input @input="searchFilter('fullname', $event)" v-model="applicant_filter.fullname" class="uk-input uk-border-pill" placeholder="Search by name">
                    </div>
                    <div class="uk-flex uk-flex-middle uk-margin-small-top uk-margin-right" id="filter" @click="showModal">
                        <img :src="`${images}/${ isHighlight ? 'filter-blue.svg' : 'filter_list.svg'}`">
                        <span class="uk-text-small uk-text-bold uk-margin-small-left" :class="{'filter-highlight': isHighlight }">Filter</span>
                    </div>
                    <div v-if="activeFilter" class="uk-flex uk-width-expand uk-margin-small-top uk-text-small">
                        Active Filter(s): {{ activeFilter }}
                    </div>
                </div>
                <div class="uk-flex uk-margin-top uk-flex-between">
                    <div class="uk-flex uk-flex-middle uk-margin-small-left">
                        <input
                            v-if="[0, 1, 3, 4, 5].includes(isSelectedStatus)"
                            class="uk-checkbox" type="checkbox"
                            :checked="selectedUsers.length === job_applicant_list.totalDocs && job_applicant_list.totalDocs != 0"
                            @change="selectAll"
                        />
                        <img class="uk-margin-small-left" :src="`${images}/refresh.svg`">
                        <div v-if="[0,1,4,5].includes(isSelectedStatus) && selectedUsers.length > 0">
                            <img v-if="isSelectedStatus === 0" class="uk-margin-small-left" :src="`${images}/job_applicant/arrow-right.svg`" @click="moveUser(1, 'multi')">
                            <img v-else-if="isSelectedStatus === 1" class="uk-margin-small-left" :src="`${images}/job_applicant/arrow-right.svg`" @click="moveUser(4, 'multi')">
                            <img v-else-if="isSelectedStatus === 4" class="uk-margin-small-left" :src="`${images}/job_applicant/arrow-right.svg`" @click="moveUser(5, 'multi')">
                            <img v-else-if="isSelectedStatus === 5" class="uk-margin-small-left" :src="`${images}/job_applicant/arrow-right.svg`" @click="moveUser(6, 'multi')">
                            <img v-if="[0,1,4].includes(isSelectedStatus)" class="uk-margin-small-left" :src="`${images}/job_applicant/forward.svg`" @click="moveUser(6, 'multi')">
                            <img v-if="[0,1,4,5].includes(isSelectedStatus)" class="uk-margin-small-left" :src="`${images}/job_applicant/reject.svg`" @click="moveUser(3, 'multi')">
                        </div>
                        <div v-else-if="isSelectedStatus === 3 && selectedUsers.length > 0" >
                            <img class="uk-margin-small-left" :src="`${images}/job_applicant/arrow-left.svg`" @click="moveUser(4, 'multi')">
                            <img class="uk-margin-small-left" :src="`${images}/job_applicant/backward.svg`" @click="moveUser(1, 'multi')">
                        </div>
                    </div>
                    <div class="uk-flex uk-flex-middle uk-margin-small-right">
                        <span v-if="[0, 1, 3, 4, 5].includes(isSelectedStatus)" class="uk-margin-small-right">{{ this.selectedUsers.length }} Applicant(s) selected</span>
                        <button
                            v-if="this.selectedUsers.length > 0"
                            class="uk-margin-small-right uk-button uk-button-default uk-border-pill uk-text-primary"
                            type="button"
                            style="border-color: #1e87f0"
                            @click="selectedUsers = []"
                        >
                            Cancel
                        </button>
                        <div v-if="this.selectedUsers.length > 0">
                            <div class="uk-button-group">
                                <div class="uk-inline">
                                    <button class="uk-button uk-button-primary uk-border-pill" type="button" aria-label="Toggle Dropdown">
                                        Change Status
                                        <span uk-icon="icon:  triangle-down"></span>
                                    </button>
                                    <div
                                        class="uk-background-primary uk-light"
                                        uk-dropdown="mode: click; target: !.uk-button-group;"
                                        style="padding: 10px"
                                    >
                                        <ul class="uk-nav uk-dropdown-nav">
                                            <li
                                                v-if="[0].includes(isSelectedStatus)"
                                                class="uk-text-center uk-text-bolder"
                                                style="cursor: pointer; margin: 5px 0;"
                                                @click="moveUser(1)"
                                            >
                                                Pick Up Applicant
                                            </li>
                                            <li
                                                v-if="[0, 1, 3].includes(isSelectedStatus)"
                                                class="uk-text-center uk-text-bolder"
                                                style="cursor: pointer; margin: 5px 0;"
                                                @click="moveUser(4)"
                                            >
                                                Invite to Interview
                                            </li>
                                            <li
                                                v-if="[0, 1, 3, 4].includes(isSelectedStatus)"
                                                class="uk-text-center uk-text-bolder"
                                                style="cursor: pointer; margin: 5px 0;"
                                                @click="moveUser(5)"
                                            >
                                                Interviewed Applicant
                                            </li>
                                            <li
                                                v-if="[0, 1, 4, 5].includes(isSelectedStatus)"
                                                class="uk-text-center uk-text-bolder"
                                                style="cursor: pointer; margin: 5px 0;"
                                                @click="moveUser(6)"
                                            >
                                                Send Contract
                                            </li>
                                            <li
                                                v-if="[0, 1, 4, 5].includes(isSelectedStatus)"
                                                class="uk-text-center uk-text-bolder"
                                                style="cursor: pointer; margin: 5px 0;"
                                                @click="moveUser(3)"
                                            >
                                                Reject Applicant
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="uk-margin-remove">
            <div class="uk-text-center">
                <span v-if="isLoading" uk-spinner="ratio: 2" class="uk-margin-large-top uk-margin-large-bottom"></span>
            </div>
            <div v-show="!isLoading" class="uk-margin-left uk-margin-right">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-padding-left">
                        <thead>
                        <tr>
                            <th class="uk-table-shrink uk-text-nowrap"></th>
                            <th class="uk-table-expand">Name</th>
                            <th class="uk-table-expand">Source</th>
                            <th class="uk-table-expand">NIK</th>
                            <th class="uk-width-medium">Location</th>
                            <th class="uk-table-shrink uk-text-nowrap">Phone Number</th>
                            <th class="uk-table-shrink uk-text-nowrap">Arrange By</th>
                            <th class="uk-table-shrink uk-text-nowrap">Date</th>
                            <th class="uk-table-shrink uk-text-nowrap">Education</th>
                            <th class="uk-table-shrink uk-text-nowrap">Age</th>
                            <th class="uk-table-shrink uk-text-nowrap">Experience</th>
                            <th class="uk-table-shrink uk-text-nowrap">KTP</th>
                            <th class="uk-table-shrink uk-text-nowrap">SIM</th>
                            <th class="uk-table-shrink uk-text-nowrap">STNK</th>
                            <th class="uk-table-shrink uk-text-nowrap">SKCK</th>        
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(list, index) in job_applicant_list.docs" :key="index">
                            <!-- action -->
                            <td>
                                <div v-if="isChecked(list._id)" class="uk-flex uk-flex-middle">
                                    <input
                                        v-if="[0, 1, 3, 4, 5].includes(isSelectedStatus)"
                                        class="uk-checkbox uk-margin-small-right" type="checkbox"
                                        :value="list._id"
                                        @change="changeSelected"
                                        :checked="isChecked(list._id)"
                                    />
                                    <span>
                                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/visibility-disabled.svg`" width="20" alt="">
                                    </span>
                                    <span>
                                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/cloud-download-disabled.svg`" width="20" alt="">
                                    </span>
                                    <span v-if="[0,1,4,5].includes(isSelectedStatus)">
                                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right-disabled.svg`" width="20" alt="">
                                    </span>
                                    <span v-if="[0,1,4].includes(isSelectedStatus)">
                                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/forward-disabled.svg`" width="20" alt="">
                                    </span>
                                    <span v-if="[0,1,4,5].includes(isSelectedStatus)">
                                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/reject-disabled.svg`" width="20" alt="">
                                    </span>
                                    <div v-if="isSelectedStatus === 3" class="uk-flex uk-flex-middle">
                                        <span>
                                            <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-left-disabled.svg`" width="20" alt="">
                                        </span>
                                        <span>
                                            <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/backward-disabled.svg`" width="20" alt="">
                                        </span>
                                    </div>
                                </div>
                                <div v-else class="uk-flex uk-flex-middle">
                                    <input
                                        v-if="[0, 1, 3, 4, 5].includes(isSelectedStatus)"
                                        class="uk-checkbox uk-margin-small-right" type="checkbox"
                                        :value="list._id"
                                        @change="changeSelected"
                                        :checked="isChecked(list._id)"
                                    />
                                    <a href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="viewDetailApplicant(list)">
                                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/visibility.svg`" width="20" alt="">
                                    </a>
                                    <a href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="downloadCV(list.user._id)">
                                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/cloud-download.svg`" width="20" alt="">
                                    </a>
                                    <div v-if="[0,1,4,5,6,7].includes(isSelectedStatus)" class="uk-flex uk-flex-middle">
                                        <div v-if="[0,1,4,5].includes(isSelectedStatus)" class="uk-flex uk-flex-middle">
                                            <a v-if="isSelectedStatus === 0" href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(1, 'single', list._id)">
                                                <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right.svg`" width="20" alt="">
                                            </a>
                                            <a v-else-if="isSelectedStatus === 1" href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(4, 'single', list._id)">
                                                <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right.svg`" width="20" alt="">
                                            </a>
                                            <a v-else-if="isSelectedStatus === 4" href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(5, 'single', list._id)">
                                                <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right.svg`" width="20" alt="">
                                            </a>
                                            <a v-else-if="isSelectedStatus === 5" href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(6, 'single', list._id)">
                                                <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right.svg`" width="20" alt="">
                                            </a>
                                        </div>
                                        <a v-if="[0,1,4].includes(isSelectedStatus)" href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(6, 'single', list._id)">
                                            <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/forward.svg`" width="20" alt="">
                                        </a>
                                        <a v-if="[0,1,4,5].includes(isSelectedStatus)" href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(3, 'single', list._id)">
                                            <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/reject.svg`" width="20" alt="">
                                        </a>
                                    </div>
                                    <div v-if="isSelectedStatus === 3" class="uk-flex uk-flex-middle">
                                        <a  href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(4, 'single', list._id)">
                                            <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-left.svg`" width="20" alt="">
                                        </a>
                                        <a href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="moveUser(1, 'single', list._id)">
                                            <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/backward.svg`" width="20" alt="">
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="uk-flex uk-flex-middle">
                                    <img class="uk-preserve-width uk-border-circle uk-margin-small-right" :src="`${images}/avatar.svg`" width="35" alt="">
                                    <span class="uk-text-bold">{{ list.user ? list.user.name : '-' }}</span>
                                </div>
                            </td>
                            <!-- source -->
                            <td class="uk-text-nowrap">{{ list.apply_from || 'PWA' }}</td>
                            <!-- nik -->
                            <td class="uk-text-nowrap">{{ list.user.identity_number ? list.user.identity_number : '-' }}</td>
                            <!-- location -->
                            <td class="uk-text-nowrap">{{ list.user.domicile ? list.user.domicile.name : '-' }}</td>
                            <!-- phone number -->
                            <td class="uk-text-nowrap">{{ list.user.phone_number }}</td>
                            <!-- arrange by -->
                            <td class="uk-text-nowrap">
                                <a href="javascript:;" v-if="ArrangedBy(list)" @click="showDetailArrangedBy((list.user ? list.user.name : '-'), list)">
                                    {{ ArrangedBy(list) }}
                                </a>
                                <span v-else>-</span>
                            </td>
                            <!-- date -->
                            <td class="uk-text-nowrap">{{ formatter.dateComplete(list.updated_at) }}</td>
                            <!-- education -->
                            <td class="uk-text-nowrap">{{ list.user.education_level ? list.user.education_level.name : '-'}}</td>
                            <!-- age -->
                            <td class="uk-text-nowrap">{{ list.user.birthdate ? getAge(list.user.birthdate) : '-'}}</td>
                            <!-- experience -->
                            <td>
                                <div class="uk-flex uk-flex-column" v-if="list.user && list.user.cv && list.user.cv.work_experiences && list.user.cv.work_experiences.length > 0">
                                    <div class="uk-text-break uk-text-emphasis">{{ getExpName({workExperiences: list.user.cv.work_experiences}) }}</div>
                                    <div class="uk-text-nowrap">{{ getExpTime({workExperiences: list.user.cv.work_experiences}) }}</div>
                                </div>
                            </td>
                            <!-- ktp -->
                            <td class="uk-text-nowrap">
                                <button v-if="list.user.cv && list.user.cv.ktp" class="filter-highlight"
                                @click="openImage({file_url: list.user.cv.ktp.document_url.file_url})">View</button>
                                <button v-else disabled>-</button>
                            </td>
                            <!-- sim -->
                            <td class="uk-text-nowrap">
                                <button v-if="list.user.cv && list.user.cv.sim" class="filter-highlight"
                                @click="openImage({file_url: list.user.cv.sim.document_url.file_url})">View</button>
                                <button v-else disabled>-</button>
                            </td>
                            <!-- stnk -->
                            <td class="uk-text-nowrap">
                                <button v-if="list.user.cv && list.user.cv.stnk" class="filter-highlight"
                                @click="openImage({file_url: list.user.cv.stnk.document_url.file_url})">View</button>
                                <button v-else disabled>-</button>
                            </td>
                            <!-- skck -->
                            <td class="uk-text-nowrap">
                                <button v-if="list.user.cv && list.user.cv.skck" class="filter-highlight"
                                @click="openImage({file_url: list.user.cv.skck.document_url.file_url})">View</button>
                                <button v-else disabled>-</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <pagination
                    v-if="job_applicant_list.docs.length != 0"
                    :total-data="job_applicant_list.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                    :limit-list="limitList"
                    :limit-page="applicant_filter.limit"
                    :start-page="applicant_filter.page"
                />
            </div>
            <div><hr></div>
        </div>

        <!-- detail arranged by -->
        <div id="arrangedByModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideDetailArrangedBy"></button>
                <div class="uk-modal-header">
                    <h4>View Updates</h4>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin-bottom">View Job Applicants Update for <b>{{ dataArrangedBy.name }}</b></div>
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-padding-left">
                            <thead>
                                <tr>
                                    <th class="uk-table-expand">Arrange by</th>
                                    <th class="uk-table-expand">Status</th>
                                    <th class="uk-table-expand">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="dataArrangedBy.data.rejected_by">
                                    <td class="uk-text-bold">{{ dataArrangedBy.data.rejected_by.fullname }}</td>
                                    <td>Rejected Applicant</td>
                                    <td>{{ dataArrangedBy.data.rejected_date ? formatter.dateComplete(dataArrangedBy.data.rejected_date) : '-' }}</td>
                                </tr>
                                <tr v-if="dataArrangedBy.data.contract_sent_by">
                                    <td class="uk-text-bold">{{ dataArrangedBy.data.contract_sent_by.fullname }}</td>
                                    <td>Signed Applicant</td>
                                    <td>{{ dataArrangedBy.data.contract_sent_date ? formatter.dateComplete(dataArrangedBy.data.contract_sent_date) : '-' }}</td>
                                </tr>
                                <tr v-if="dataArrangedBy.data.interviewed_by">
                                    <td class="uk-text-bold">{{ dataArrangedBy.data.interviewed_by.fullname }}</td>
                                    <td>Interviewed Applicant</td>
                                    <td>{{ dataArrangedBy.data.interviewed_date ? formatter.dateComplete(dataArrangedBy.data.interviewed_date) : '-' }}</td>
                                </tr>
                                <tr v-if="dataArrangedBy.data.invited_by">
                                    <td class="uk-text-bold">{{ dataArrangedBy.data.invited_by.fullname }}</td>
                                    <td>Invited to Interview</td>
                                    <td>{{ dataArrangedBy.data.invited_date ? formatter.dateComplete(dataArrangedBy.data.invited_date) : '-' }}</td>
                                </tr>
                                <tr v-if="dataArrangedBy.data.picked_up_by">
                                    <td class="uk-text-bold">{{ dataArrangedBy.data.picked_up_by.fullname }}</td>
                                    <td>Picked up Applicant</td>
                                    <td>{{ dataArrangedBy.data.picked_up_date ? formatter.dateComplete(dataArrangedBy.data.picked_up_date) : '-' }}</td>
                                </tr>
                                <tr v-if="
                                    dataArrangedBy.data &&
                                    !dataArrangedBy.data.rejected_by &&
                                    !dataArrangedBy.data.contract_sent_by &&
                                    !dataArrangedBy.data.interviewed_by &&
                                    !dataArrangedBy.data.invited_by &&
                                    !dataArrangedBy.data.picked_up_by
                                ">
                                    <td colspan="3" class="uk-text-bold uk-text-center">No Data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <section>
                        <button
                            class="uk-button uk-button-primary"
                            type="button"
                            style="border-color: #0f7ae5;"
                            @click="hideDetailArrangedBy"
                        >
                            Close
                        </button>
                    </section>
                </div>
            </div>
        </div>

        <!-- Filter candidate modal box -->
        <div id="filter-candidate" class="filter-candidate" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Filter Applicant</h2>
                </div>
                <div class="uk-modal-body">
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label><input v-model="applicant_filter.is_job_preference" class="uk-checkbox" type="checkbox"> Job Preference</label>
                            <multiselect
                                v-model="applicant_filter.job_preferences"
                                :multiple="true"
                                placeholder="Select Preferensi"
                                label="name"
                                name="job_preference"
                                track-by="name"
                                :searchable="true"
                                :options="job_preference_options"
                                :loading='isFetching'
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="!applicant_filter.is_job_preference"
                            >
                            </multiselect>
                        </div>
                        <div class="uk-width-1-2">
                            <label><input v-model="applicant_filter.is_domicile" class="uk-checkbox" type="checkbox"> Domicile</label>
                            <multiselect
                                v-model="applicant_filter.domiciles"
                                :multiple="true"
                                placeholder="Type to search city"
                                label="name"
                                name="city"
                                track-by="name"
                                :searchable="true"
                                :options="city_options"
                                :loading='isFetching'
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :show-no-results="false"
                                @search-change="getCityOptions"
                                :disabled="!applicant_filter.is_domicile"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label><input v-model="applicant_filter.is_education" class="uk-checkbox" type="checkbox"> Education</label>
                            <multiselect
                                v-model="applicant_filter.education_levels"
                                :multiple="true"
                                placeholder="Select Pendidikan"
                                label="name"
                                name="education"
                                track-by="name"
                                :searchable="true"
                                :options="education_options"
                                :loading='isFetching'
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="!applicant_filter.is_education"
                            >
                            </multiselect>
                        </div>
                        <div class="uk-width-1-2">
                            <label><input v-model="applicant_filter.is_work_experience" class="uk-checkbox" type="checkbox"> Work experience</label>
                            <input v-model="applicant_filter.work_experiences" class="uk-input" placeholder="Type to filter work exprerience" :disabled="!applicant_filter.is_work_experience">
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label><input v-model="applicant_filter.is_age" class="uk-checkbox" type="checkbox">{{" "}}Age</label>
                            <multiselect
                                v-model="applicant_filter.age"
                                :multiple="false"
                                placeholder="Select Age"
                                :searchable="false"
                                :options="['<20','21-30','31-40','41-50','>50']"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="!applicant_filter.is_age"
                            />
                        </div>
                        <div class="uk-width-1-2">
                            <label><input v-model="applicant_filter.is_gender" class="uk-checkbox" type="checkbox">{{ " " }}Gender</label>
                            <multiselect
                                v-model="applicant_filter.gender"
                                :multiple="false"
                                placeholder="Select Gender"
                                track-by="value"
                                label="name"
                                :searchable="false"
                                :options="[{ name: 'Laki-laki', value: 'male' }, { name: 'Perempuan', value: 'female' }]"
                                :loading='isFetching'
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="!applicant_filter.is_gender"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label><input v-model="applicant_filter.has_doc_ktp" class="uk-checkbox" type="checkbox"> KTP</label>
                            <label class="uk-margin-left"><input v-model="applicant_filter.has_doc_sim" class="uk-checkbox" type="checkbox"> SIM</label>
                            <label class="uk-margin-left"><input v-model="applicant_filter.has_doc_skck" class="uk-checkbox" type="checkbox"> SKCK</label>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-between">
                    <button class="uk-button uk-button-default clear-button" type="button" @click="clearFilter">Clear Filter</button>
                    <button class="uk-button uk-button-primary" type="button" @click="applyFilter">Apply</button>
                </div>
            </div>
        </div>
        <!-- End filter candidate modal box -->
        <ConfirmModal
            v-if="modal.isShowConfirmModal"
            title="Confirmation"
            body="Are you sure want to proceed?"
            @hideModal="modal.isShowConfirmModal = false"
            @confirmProcess="confirmProcess"
        />
        <InterviewModal
            v-if="modal.isSHowInterviewModal"
            :id="selectedSingleUser"
            :ids="selectedUsers"
            :isMulti="selectedUsers.length > 0 ? true : false"
            @hideModal="modal.isSHowInterviewModal = false"
            @onSave="confirmProcess"
        />
        <InterviewDoneModal
            v-if="modal.isSHowDoneInterviewModal"
            :id="selectedSingleUser"
            :ids="selectedUsers"
            :isMulti="selectedUsers.length > 0 ? true : false"
            @hideModal="modal.isSHowDoneInterviewModal = false"
            @onSave="confirmProcess"
        />
        <PassedModal
            v-if="modal.isShowPassedModal"
            :id="selectedSingleUser"
            :ids="selectedUsers"
            :isMulti="selectedUsers.length > 0 ? true : false"
            :office_id="job_list.docs.length > 0 ? job_list.docs[0].office._id : null"
            @hideModal="modal.isShowPassedModal = false"
            @onSave="confirmProcess"
        />
        <RejectedModal
            v-if="modal.isShowRejectedModal"
            :id="selectedSingleUser"
            :ids="selectedUsers"
            :isMulti="selectedUsers.length > 0 ? true : false"
            @hideModal="modal.isShowRejectedModal = false"
            @onSave="confirmProcess"
        />
        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
        <PreviewPDFModal
            v-if="modal.isShowPreviewPDFModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewPDFModal = false"
        />
        <PreviewOfficeModal
            v-if="modal.isShowPreviewOfficeModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewOfficeModal = false"
        />
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { JOB_APPLICANT } from '@/utils/api-url';
import api from '@/utils/api';
import formatter from "@/utils/formatter";
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from "vuex";
import Pagination from '@/components/globals/Pagination';
import ConfirmModal from '@/components/globals/modals/SimpleConfirm';
import InterviewModal from '@/components/pages/admin/job_applicant/modals/InterviewModal';
import InterviewDoneModal from '@/components/pages/admin/job_applicant/modals/InterviewDoneModal';
import PassedModal from '@/components/pages/admin/job_applicant/modals/PassedModal';
import RejectedModal from '@/components/pages/admin/job_applicant/modals/RejectedModal';
import PreviewImageModal from '@/components//globals/modals/ImageViewer.vue';
import PreviewPDFModal from '@/components//globals/modals/PdfViewer.vue';
import PreviewOfficeModal from '@/components//globals/modals/OfficeViewer.vue';
import { contentType, excelDownloader } from '@/utils/helper';
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';

export default {
    data() {
        return {
            previewSrc: "",
            limitList: [10, 30 , 50, 100, 200, 500, 1000],
            modal: {
                isShowConfirmModal: false,
                isSHowInterviewModal: false,
                isSHowDoneInterviewModal: false,
                isShowPassedModal: false,
                isShowRejectedModal: false,
                isShowPreviewImageModal: false,
                isShowPreviewPDFModal: false,
                isShowPreviewOfficeModal: false,
            },
            actionState: null,
            isHighlight: false,
            isLoading: false,
            isFetching: false,
            city_options: [],
            education_options: [],
            job_preference_options: [],
            isSelectedStatus: '',
            selectedUsers: [],
            selectedSingleUser: null,
            selectedType: 'multi',
            applicant_filter: {
                is_job_preference: false,
                job_preferences: [],
                is_domicile: false,
                domiciles: [],
                is_education: false,
                education_levels: [],
                is_work_experience: false,
                work_experiences: '',
                is_gender: false,
                gender: null,
                is_age: false,
                age: '',
                from_kormo: false,
                has_doc_ktp: false,
                has_doc_sim: false,
                has_doc_skck: false,
                fullname: '',
                phone_number: '',
                status: '',
                limit: 10,
                page: 1
            },
            activeFilter: null,
            images: PREFIX_IMAGE,
            formatter,
            dataArrangedBy: {
                name: null,
                data: {},
            }
        };
    },
    components: {
        Multiselect,
        Pagination,
        ConfirmModal,
        InterviewModal,
        InterviewDoneModal,
        PassedModal,
        RejectedModal,
        PreviewImageModal,
        PreviewPDFModal,
        PreviewOfficeModal,
    },
    computed: {
        ...mapGetters({
            job_list: 'job_applicant/job_list',
            job_applicant_list: 'job_applicant/job_applicant_list',
            job_all_applicant_list: 'job_applicant/job_all_applicant_list'
        })
    },
    async created() {
        if (localStorage.getItem(`$filter ${this.$route.name}`)) {
            this.applicant_filter = JSON.parse(localStorage.getItem(`$filter ${this.$route.name}`));
            this.isSelectedStatus = this.applicant_filter.status;
        }
        await Promise.all([
            this.getJobList({job_id: this.$route.params.job_id}),
            this.viewApplicant()
        ]);
        [this.education_options, this.job_preference_options] = await Promise.all([
            this.getEducationLevels(),
            this.getJobPreferences()
        ]);
        
        const selectedTab = this.$route.query.selected ? Number(this.$route.query.selected) : '';
        this.selectStatus(selectedTab);
    },
    beforeMount() {
        if (window.UIkit.modal('.filter-candidate')) window.UIkit.modal('.filter-candidate').$destroy(true);
        if (window.UIkit.modal('.modal-center')) window.UIkit.modal('.modal-center').$destroy(true);
    },
    methods: {
        ...mapActions({
            getJobList: 'job_applicant/getJobList',
            getJobApplicantList: 'job_applicant/getJobApplicantList',
            getJobAllApplicantList: 'job_applicant/getJobAllApplicantList',
            getReportApplicantList: 'job_applicant/getReportApplicantList',
            getEducationLevels: 'option_data/getEducationLevels',
            getJobPreferences: 'option_data/getJobPreferences',
            getCities: 'option_data/getCities',
            pickupMultiApplicant: 'applicant/pickupMultiApplicant',
        }),
        selectAll(e) {
            const target = e.target;
            this.selectedType = 'multi';

            if (target.checked) {
                this.job_all_applicant_list.forEach(data => {
                    if (!this.selectedUsers.includes(data)) {
                        this.selectedUsers.push(data);
                    }
                });
            } else {
                this.job_all_applicant_list.forEach(data => {
                    const userIdx = this.selectedUsers.findIndex(exData => exData === data);
                    if (userIdx > -1) this.selectedUsers.splice(userIdx, 1);
                });
            }
        },
        changeSelected(e) {
            const target = e.target;
            this.selectedType = 'multi';

            if (target.checked) {
                this.selectedUsers.push(target.value);
            } else {
                const userIdx = this.selectedUsers.findIndex(data => data === target.value);
                if (userIdx > -1) this.selectedUsers.splice(userIdx, 1);
            }
        },
        isChecked(id) {
            return this.selectedUsers.includes(id);
        },
        moveUser(state, type, userId) {
            const openModal = {
                "1": "isShowConfirmModal",
                "4": "isSHowInterviewModal",
                "5": "isSHowDoneInterviewModal",
                "6": "isShowPassedModal",
                "3": "isShowRejectedModal",
            };

            if (type === 'single') {
                this.selectedSingleUser = userId;
                this.selectedType = type;
            }

            this.actionState = state;
            this.modal[openModal[state]] = true;
        },
        async confirmProcess() {
            const openModal = {
                "1": "isShowConfirmModal",
                "4": "isSHowInterviewModal",
                "5": "isSHowDoneInterviewModal",
                "6": "isShowPassedModal",
                "3": "isShowRejectedModal",
            };

            this.modal[openModal[this.actionState]] = false;

            if (this.actionState === 1) {
                const payload = {};
                if (this.selectedType === 'single') {
                    const userArr = [];
                    userArr.push(this.selectedSingleUser);
                    payload.job_applicant_ids = userArr;
                } else {
                    payload.job_applicant_ids = this.selectedUsers;
                }
                this.pickedUpApplicant(payload);
            } else {
                this.viewApplicant();
            }
        },
        async pickedUpApplicant(payload) {
            this.isLoading = true;
            try {
                const response = await this.pickupMultiApplicant(payload);
                if (response && response.status === 'OK') {
                    notificationSuccess('Picked up!');
                    this.viewApplicant();
                } else {
                    notificationDangerCustom("Failed to picked up applicant!");
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.isLoading = false;
        },
        changeLimit(e) {
            this.applicant_filter.limit = e.target.value;
            this.applicant_filter.page = 1;
            localStorage.setItem(`$filter ${this.$route.name}`, JSON.stringify(this.applicant_filter));
            this.viewApplicant();
        },
        changePage(value) {
            this.applicant_filter.page = value;
            localStorage.setItem(`$filter ${this.$route.name}`, JSON.stringify(this.applicant_filter));
            this.viewApplicant();
        },
        async getCityOptions(query){
            this.isFetching = true;
            this.city_options = await this.getCities({name: query});
            this.isFetching = false;
        },
        async applyFilter() {
            this.applicant_filter.limit = 10;
            this.applicant_filter.page = 1;
            localStorage.setItem(`$filter ${this.$route.name}`, JSON.stringify(this.applicant_filter));

            this.setActiveFilter();

            this.viewApplicant();
            await window.UIkit.modal('#filter-candidate').hide();
        },
        clearFilter() {
            this.applicant_filter = {
                is_job_preference: false,
                job_preferences: [],
                is_domicile: false,
                domiciles: [],
                is_education: false,
                education_levels: [],
                is_work_experience: false,
                work_experiences: '',
                is_gender: false,
                gender: null,
                is_age: false,
                age: '',
                from_kormo: false,
                has_doc_ktp: false,
                has_doc_sim: false,
                has_doc_skck: false,
                fullname: '',
                phone_number: '',
                status: '',
                limit: 10,
                page: 1
            };
            // this.activeFilter = null;
        },
        setActiveFilter() {
            const filterList = {
                'is_job_preference': 'Job Preference',
                'is_domicile': 'Domicile',
                'is_education': 'Education',
                'is_work_experience': 'Work Experience',
                'has_doc_ktp': 'Doc KTP',
                'has_doc_sim': 'Doc SIM',
                'has_doc_skck': 'Doc SKCK'
            };
            let activeFilter = [];
            const selectedJobPreference = this.applicant_filter.job_preferences.map(jobPreference => jobPreference.name).join(', ');
            const selectedDomicile = this.applicant_filter.domiciles.map(domicile => domicile.name).join(', ');
            const selectedEducation = this.applicant_filter.education_levels.map(education => education.name).join(', ');
            const selectedExperiences = this.applicant_filter.work_experiences;

            for (const [key, value] of Object.entries(filterList)) {
                switch (key) {
                case 'is_job_preference':
                    if (this.applicant_filter.is_job_preference && selectedJobPreference) {
                        activeFilter.push(`${value} (${selectedJobPreference})`);
                    } else if (this.applicant_filter.is_job_preference) {
                        activeFilter.push(value);
                    }
                    break;
                case 'is_domicile':
                    if (this.applicant_filter.is_domicile && selectedDomicile) {
                        activeFilter.push(`${value} (${selectedDomicile})`);
                    } else if (this.applicant_filter.is_domicile) {
                        activeFilter.push(value);
                    }
                    break;
                case 'is_education':
                    if (this.applicant_filter.is_education && selectedEducation) {
                        activeFilter.push(`${value} (${selectedEducation})`);
                    } else if (this.applicant_filter.is_education) {
                        activeFilter.push(value);
                    }
                    break;
                case 'is_work_experience':
                    if (this.applicant_filter.is_work_experience && selectedExperiences) {
                        activeFilter.push(`${value} (${selectedExperiences})`);
                    } else if (this.applicant_filter.is_work_experience) {
                        activeFilter.push(value);
                    }
                    break;
                default:
                    if (this.applicant_filter[key]) activeFilter.push(value);
                    break;
                }
            }

            this.activeFilter = activeFilter.join(', ');
        },
        async downloadAllReport() {
            this.isLoading = true;
            const applicantFilter = this.formatFilter();
            const roleName = this.job_list.docs[0].role.name.trim();
            const officeName = this.job_list.docs[0].office.name.trim();
            const companyName = this.job_list.docs[0].company.name.trim();
            const reportApplicantList = await this.getReportApplicantList({id: this.$route.params.job_id, ...applicantFilter});
            excelDownloader(reportApplicantList.result, `Job Applicant_${roleName}_${officeName}_${companyName}.xls`);
            this.isLoading = false;
        },
        async downloadCV(user_id) {
            try {
                const result = await api.apiGetAuth(JOB_APPLICANT.DOWNLOAD_CV_APPLICANT(user_id));
                window.open(result.data.result.cv_path.file_url, '_blank');
            } catch (err) {
                notificationDanger(err);
            }
        },
        ArrangedBy(data) {
            switch (this.isSelectedStatus) {
            case 1:
                return data.picked_up_by?.fullname ?? false;

            case 3:
                return data.rejected_by?.fullname ?? false;

            case 4:
                return data.invited_by?.fullname ?? false;

            case 5:
                return data.interviewed_by?.fullname ?? false;

            case 6:
                return data.contract_sent_by?.fullname ?? false;

            case 7:
                return data.contract_sent_by?.fullname ?? false;

            default:
                return data.pickup_by?.fullname ?? false;
            }
        },
        async showModal(){
            await window.UIkit.modal('#filter-candidate').show();
        },
        async showDetailArrangedBy(name, data){
            this.dataArrangedBy = { name, data };
            await window.UIkit.modal('#arrangedByModal').show();
        },
        async hideDetailArrangedBy(){
            await window.UIkit.modal('#arrangedByModal').hide();
        },
        searchFilter(field, e) {
            this.applicant_filter[field] = formatter.sanitizeString(e.target.value);
            this.viewApplicant();
        },
        selectStatus(status) {
            this.isSelectedStatus = status;
            this.applicant_filter.status = status;
            this.applicant_filter.page = 1;
            this.selectedUsers = [];
            localStorage.setItem(`$filter ${this.$route.name}`, JSON.stringify(this.applicant_filter));
            this.viewApplicant();
        },
        viewDetailApplicant(user) {
            const jobApplicantIds = this.job_applicant_list.docs.map(jobApplicant => jobApplicant._id);
            localStorage.setItem(`$job_applicant_ids`, JSON.stringify(jobApplicantIds));
            const route = this.$router.resolve({path: `/admin/job_applicant/candidate/detail/${user._id}`});
            window.open(route.href, '_blank');
        },
        goBack() {
            localStorage.removeItem(`$filter ${this.$route.name}`);
            window.history.go(-1);
        },
        async viewApplicant() {
            this.isLoading = true;
            this.filterHighlight();
            const applicantFilter = this.formatFilter();

            await this.getJobApplicantList({id: this.$route.params.job_id, ...applicantFilter});
            if ([0, 1, 3, 4, 5].includes(this.applicant_filter.status)) {
                await this.getJobAllApplicantList({id: this.$route.params.job_id, ...applicantFilter});
            }
            this.selectedUsers = [];
            this.selectedSingleUser = null;
            this.isLoading = false;
            this.selectedType = 'multi';
        },
        filterHighlight() {
            const avoid = ['page', 'limit', 'status', 'domiciles', 'education_levels',
                'work_experiences', 'job_preferences', 'fullname', 'phone_number', 'total'];

            const isFiltered = Object.entries(this.applicant_filter).some(([key, value]) => {
                if (!avoid.includes(key) && value) {
                    return true;
                }
                return false;
            });

            if (isFiltered) {
                this.isHighlight = true;
            } else {
                this.isHighlight = false;
            }
        },
        countAllApplicant(obj) {
            if (obj){
                const total = Object.values(obj).reduce((a, b) => a + b);
                this.applicant_filter.total = total;
                return total;
            }else{
                return 0;
            }
        },
        formatFilter() {
            const applicantFilter = { ...this.applicant_filter };
            if (applicantFilter.is_job_preference && applicantFilter.job_preferences.length > 0) {
                applicantFilter.job_preferences = applicantFilter.job_preferences.map(jobPreference => jobPreference._id);
            } else {
                applicantFilter.job_preferences = [];
            }
            if (applicantFilter.is_domicile && applicantFilter.domiciles.length > 0) {
                applicantFilter.domiciles = applicantFilter.domiciles.map(domicile => domicile._id);
            } else {
                applicantFilter.domiciles = [];
            }
            if (applicantFilter.is_education && applicantFilter.education_levels.length > 0) {
                applicantFilter.education_levels = applicantFilter.education_levels.map(educationLevel => educationLevel._id);
            } else {
                applicantFilter.education_levels = [];
            }
            if (!applicantFilter.is_work_experience && applicantFilter.work_experiences) {
                applicantFilter.work_experiences = '';
            }

            applicantFilter.gender = (applicantFilter.gender?.value || '').toLowerCase();
            applicantFilter.age = (applicantFilter?.age || '').replace('<', '0-').replace('>', '');
            applicantFilter.timezone = new Date().getTimezoneOffset();

            return applicantFilter;
        },
        calcYearJobExp({ dateStart, dateEnd }) {
            let years = moment.duration(moment(dateEnd).diff(dateStart)).years();
            let months = moment.duration(moment(dateEnd).diff(dateStart)).months();
            return years+ ' Year, ' + months + ' Month';
        },
        openImage(data) {
            this.previewSrc = data.file_url;
            this.modal[contentType(this.previewSrc)] = true;
        },
        getExpTime({workExperiences}) {
            let start = null;
            let end = null;
            for (const exp of workExperiences) {
                if (start && moment(start).isAfter(exp.start_date)) start = exp.start_date;
                else if (!start) start = exp.start_date;

                if (exp.present) end = moment();
                else if (end && moment(end).isBefore(exp.end_date)) end = exp.end_date;
                else if (!end) end = exp.end_date;
            }
            return this.calcYearJobExp({dateStart: start, dateEnd: end});
        },
        getExpName({workExperiences}) {
            let names = "";
            for (const exp of workExperiences) {
                names = names + exp.title + ', ';
            }
            if (names.slice(-2) == ", ") {
                names = names.slice(0, -2);
            }
            return names;
        },
        getAge(birthdate) {
            return moment().diff(birthdate, 'years');
        },
    }
};
</script>

<style scoped>
    .filter-highlight {
        color : #0275D8;
    }
    .mr-24{
        margin-left: 24px;
    }
    div.muted h1,
    div.muted span {
        color: #999 !important;
    }
    .c-cursor .uk-width-expand,
    #filter,
    #arrow-back {
        cursor: pointer;
    }
    .selected-bg-color {
        background-color: whitesmoke;
        border-bottom: solid 5px;
        /*opacity: 0.1;*/
    }
    .photo {
        width: 120px;
        height: 120px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
    }
    .applicant_name {
        font-weight: 700;
        margin-bottom: 10px;
    }

    .clear-button {
        border: 1px solid #EF5350;
        color: #EF5350;
    }
</style>
