import Vue from 'vue';
import Vuex from 'vuex';

import announcement from './modules/announcement';
import applicant from './modules/applicant';
import auth from './modules/auth';
import candidate from './modules/candidate';
import company_office from './modules/company_office';
import company from './modules/company';
import company_recap from './modules/company_recap';
import contract from './modules/contract';
import dashboard from './modules/dashboard';
import initiation from './modules/initiation';
import job_applicant from './modules/job_applicant';
import mitra from './modules/mitra';
import option_data from './modules/option_data';
import profile from './modules/profile';
import report from './modules/report';
import salary from './modules/salary';
import salary_adjustment from './modules/salary_adjustment';
import schedule from './modules/schedule';
import task from './modules/task';
import worker from './modules/worker';
import new_shift from './modules/new_shift';
import setting from './modules/setting';
import leave from './modules/leave';
import salary_settings from './modules/salary_settings';
import daily_recap_worker_attendance from './modules/daily_recap_worker_attendance';
import notification from './modules/notification';
import other_document from './modules/other_document';
import file from './modules/file';
import job_preference from './modules/job_preference';
import platform from './modules/platform';
import admin_job_vacancy from './modules/admin_job_vacancy';
import tax from './modules/tax';
import job from './modules/job';
import additional_question_template_category from './modules/additional_question_template_category';
import additional_question_template from './modules/additional_question_template';
import kyc from './modules/kyc';
import overtime_report from './modules/overtime_report';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        selected_company: '',
        filter_mitra: '',
        routeBackFull: {}
    },
    mutations: {
        mutateRouteBackFull: function (state, payload) {
            state.routeBackFull = payload;
        }
    },
    modules: {
        announcement,
        applicant,
        auth,
        candidate,
        company,
        company_office,
        company_recap,
        contract,
        dashboard,
        initiation,
        job_applicant,
        mitra,
        option_data,
        profile,
        report,
        salary,
        salary_adjustment,
        schedule,
        task,
        worker,
        new_shift,
        setting,
        leave,
        salary_settings,
        daily_recap_worker_attendance,
        notification,
        other_document,
        file,
        job_preference,
        platform,
        admin_job_vacancy,
        tax,
        job,
        additional_question_template_category,
        additional_question_template,
        kyc,
        overtime_report
    },
});
