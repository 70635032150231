import Vue from 'vue';
import api from '@/utils/api';
import { PLATFORM_VERSION } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const state = {
    latest_version_data: null
};

const mutations = {
    "SET_LATEST_VERSION_DATA": (state, payload) => {
        Vue.set(state, 'latest_version_data', payload);
    }
};

const actions = {
    async getLatestVersion(context, data) {
        try {
            const response = await api.apiGetAuth(PLATFORM_VERSION.LATEST_VERSION, data);
            context.commit('SET_LATEST_VERSION_DATA', response.data.result);
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    latest_version_data: state => {
        return state.latest_version_data;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
