import Vue from 'vue';
import api from '@/utils/api';
import { API_COMPANY_RECAP } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    company_recaps: []
};

const mutations = {
    "SET_COMPANY_RECAP": (state, payload) => {
        Vue.set(state, 'company_recaps', payload);
    }
};

const actions = {
    async getCompanyRecap(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMPANY_RECAP.LIST, data);
            context.commit('SET_COMPANY_RECAP', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGrabWheelsScheduleRecap(context, data) {
        try {
            let type = data.updated_filter;
            if (data.updated_filter === 'daily') {
                type = '';
            }
            const response = await api.apiGetAuth(`${API_COMPANY_RECAP.LIST_GRABWHEELS}/${type}`, data);
            context.commit('SET_COMPANY_RECAP', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {
    company_recaps: state => {
        return state.company_recaps;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
