import api from '@/utils/api';
import { API_COMPANY } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    company: []
};

const actions = {
    async getCorrectionLimit() {
        try {
            const response = await api.apiGetAuth(API_COMPANY.PATCH_CORRECTION_LIMIT);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchCorrectionLimit(context, data) {
        try {
            const response = await api.apiPatchAuth(API_COMPANY.PATCH_CORRECTION_LIMIT, { attendance_correction_limit: data });
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    company: state => {
        return state.company;
    }
};

export default {
    namespaced,
    state,
    actions,
    getters
};
