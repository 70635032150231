import Vue from 'vue';
import api from '@/utils/api';
import { API_COMPANY_OFFICE } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    company_offices: []
};

const mutations = {
    "SET_COMPANY_OFFICE": (state, payload) => {
        Vue.set(state, 'company_offices', payload);
    }
};

const actions = {
    async getCompanyOffice(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMPANY_OFFICE.LIST, data);
            context.commit('SET_COMPANY_OFFICE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyOfficeId(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMPANY_OFFICE.LIST_ITEM(data));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyOfficePaginate(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMPANY_OFFICE.LIST_PAGINATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchCompanyOfficeAttendanceMethod(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMPANY_OFFICE.PATCH_ATTENDANCE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    company_offices: state => {
        return state.company_offices;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
