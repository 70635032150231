<template>
    <div class="uk-container uk-container-expand card-scrollable">

        <div v-if="meta.schedulePick == 'monthly'" style="margin-top:20px" class="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s" uk-grid>
            <div v-if="getRole() !== 'supervisor'">
                <div v-if="company_office">
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                    </div>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer;margin-right:41px" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="31" heigth="31" style="margin-right:10px"> Select Location filter
                    </div>
                </div>
                <p v-if="company_office" class="reset-filter" @click="resetLocation">Reset Location Filter</p>
            </div>
            <div v-else>
              <div v-if="company_office">
                  <div class="location-header">
                      <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                  </div>
                  <div class="location-header">
                      <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                  </div>
              </div>
            </div>

            <div class="uk-width-expand@m">
                <div class="location-header" style="cursor:pointer" @click="showModal('month-filter')">
                    <img :src="`${images}/calendar.png`" alt="" width="20"> {{filterMonthText()}}
                </div>
                <p v-if="selectedMonth" class="reset-filter" @click="resetDate">Reset Month Filter</p>
            </div>
            <div class="uk-flex uk-flex-right">
                <div class="uk-inline uk-margin-right">
                  <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search" style="height:40px"></span>
                  <input class="uk-input" style="width:200px" @keyup.enter="(e) => changeMeta(e, 'worker_name')" type="text" placeholder="Filter Jadwal Khusus">
                </div>
                <div style="margin-top:10px">
                  <img class="uk-margin-small-right" :src="`${images}/add.png`" alt="" width="24" height="24">
                  <a @click="showModal('list-uniq-schedule')">
                      <u style="color:#5FD855" class="uk-text-normal">Lihat Jadwal Khusus</u>
                  </a>
              </div>
            </div>
        </div>
        <div v-if="meta.schedulePick == 'weekly'" style="margin-top:20px" class="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s" uk-grid>
            <div v-if="getRole() !== 'supervisor'">
                <div v-if="company_office">
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                    </div>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer;margin-right:41px" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="31" heigth="31" style="margin-right:10px"> Select Location filter
                    </div>
                </div>
                <p v-if="company_office" class="reset-filter" @click="resetLocation">Reset Location Filter</p>
            </div>
            <div v-else>
              <div v-if="company_office">
                  <div class="location-header">
                      <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                  </div>
                  <div class="location-header">
                      <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                  </div>
              </div>
            </div>
            <div class="uk-width-expand@m">
                <div class="location-header" style="cursor:pointer" @click="showModal('date-filter')">
                    <img :src="`${images}/calendar.png`" alt="" width="20"> {{flterDateText()}}
                </div>
                <p v-if="meta.start_date || meta.end_date" class="reset-filter" @click="resetDate">Reset Date Filter</p>
            </div>
            <div class="uk-flex uk-flex-right" style="width:40%">
                <!-- <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search" style="height:40px"></span> -->
                <select
                    name="Role Filter"
                    class="uk-select uk-margin-right"
                    style="width:200px"
                    id="form-stacked-select"
                    v-model="meta.filterRole"
                    @change="handleFilterRole"
                >
                    <option value="" disabled selected>Filter Role</option>
                    <option value="">--Show All--</option>
                    <option v-for="data in roleList" :value="data._id" :key="data._id">{{data.name}}</option>
                </select>
                <!-- <input class="uk-input" v-model="meta.filterRole" @keyup.enter="handleFilterRole" type="text" placeholder="Filter Role"> -->
                <div style="margin-top:10px" v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','asd123'])">
                  <img :src="`${images}/add.png`" alt="" width="24" height="24">
                  <a @click="addSchedule" class="schedule-add">
                      Tambah Jadwal
                  </a>
              </div>
            </div>
        </div>

        <!-- Temporary hide select mingguan/bulanan -->
        <div v-show="false" class="uk-grid-column-small uk-margin-remove-top uk-child-width-1-3@s" uk-grid>
            <select
                style="width:178px"
                name="Updated By Filter"
                class="uk-select uk-margin-left"
                id="form-stacked-select"
                v-model="meta.schedulePick"
                @change="changeScheduleTime"
            >
            <option value="weekly">Mingguan</option>
            <option value="monthly">Bulanan</option>
            </select>
        </div>

        <span v-if="loadingSchedule" uk-spinner="ratio: 1" style="margin:auto;display:block"></span>

        <div v-if="meta.schedulePick == 'monthly' && !loadingSchedule" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <v-row class="fill-height">
                    <v-col style="padding:0;margin-bottom:0">
                    <v-sheet height="600">
                        <v-calendar
                        locale="id"
                        ref="calendar"
                        v-model="focus"
                        :weekdays="weekday"
                        color="primary"
                        :events="events"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        >
                        </v-calendar>
                    </v-sheet>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div v-if="meta.schedulePick == 'weekly' && !loadingSchedule" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-divider table-schedule">
                    <thead>
                        <tr>
                            <th class="uk-text-center"><span>Jam</span><br/></th>
                            <th v-for="i in scheduleList" class="uk-text-center" :key="i.day">
                                <span>{{i.day}}</span><br/>
                                <span>{{moment(i.date).format('DD-MMM')}}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="dataSchedule.length > 0">
                        <tr v-for="(data, i) in dataSchedule" :key="i">
                            <td class="uk-text-center" style="font-weight:bold;color:#000;border-right:1px solid #CCC">{{data.hour}}</td>
                            <template v-for="(e, wday) in 7">
                              <td v-if="scheduleList.length > wday" class="uk-text-center" :key="'day-' + wday">
                                <div v-if="i == 0">
                                  <div :id="`shift-card-${data._id}-${wday}`" v-for="(data, idx) in scheduleList[wday].schedule_shifts" :key="data._id"
                                  @click="handleClickCard(data._id, wday)" class="shift-card"
                                  :style="generateCardStyle(data.company_shift, data.is_crossday, idx, `${data._id}-${wday}`, wday)">
                                    <span class="uk-margin-small-top" v-if="data.send_notif == 1" @click="fillPublishModal(data, scheduleList[wday].date)">
                                      <a class="uk-text-bold uk-text-small" style="color:#FEBE10">
                                        <span uk-icon="icon: warning" style="color:#FEBE10"></span>
                                        {{data.company_shift.name}}
                                      </a>
                                    </span>
                                    <span v-else-if="data.is_rejected_worker" style="font-weight: bold;font-size: 14px;color: #FF0312">{{data.company_shift.name}}</span>
                                    <span v-else style="font-weight: bold;font-size: 14px;color: black">{{data.company_shift.name}}</span>
                                    <span style="font-size: 11px;color:#000">{{data.total_worker}} Pekerja</span>
                                    <a @click="wday === 0 ? handleClickDetail(
                                        data,
                                        scheduleList[wday].date,
                                        scheduleList[wday].day,
                                    ) : handleClickDetail(
                                        data,
                                        data.is_crossday && topShift(data._id) ? scheduleList[wday - 1].date : scheduleList[wday].date,
                                        data.is_crossday && topShift(data._id) ? scheduleList[wday - 1].day : scheduleList[wday].day
                                    )" style="font-size: 12px;color:#0ABAB5">Lihat Detail</a>
                                    <span v-if="data.company_role && getDuration(data.company_shift) > 2" style="background: #E8FFE6;border-radius: 5px;color:#0ABAB5;font-size: 10px;margin:0 5px">
                                      {{data.company_role.name}}
                                    </span>
                                    </div>
                                </div>
                                <div class="td-line"></div>
                              </td>
                            </template>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
        </div>

        <div id="month-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog" style="width: 430px;">
                <div class="uk-modal-header uk-margin-remove-bottom">
                    <h2 class="uk-modal-title uk-text-center" style="font-size: 24px;">Pilih Bulan</h2>
                </div>
                <div class="uk-padding-small">
                  <month-picker @change="setMonth"></month-picker>
                </div>
            </div>
        </div>

        <div id="date-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('date-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title" style="font-size: 24px;">Masukkan tanggal</h2>
                </div>
                <div class="uk-modal-body">
                     <div class="uk-width-1-1 uk-column-1-2@m">
                        <label class="uk-form-label" for="form-stacked-text">Dari</label>
                        <div class="uk-form-controls">
                            <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="Start Date"
                                v-model="meta.start_date"
                                placeholder="dd/mm/yyyy"
                            ></datetime>
                    </div>
                        </div>
                        <label class="uk-form-label" for="form-stacked-text">Sampai</label>
                        <div class="uk-form-controls">
                            <datetime
                                disabled
                                input-class="uk-input uk-input-disabled-override"
                                type="date"
                                name="End Date"
                                v-model="meta.end_date"
                                placeholder="dd/mm/yyyy"
                            ></datetime>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setDate">Simpan</button>
                </div>
            </div>
        </div>

        <div id="select-workers" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog" >
                <button class="uk-modal-close-default" uk-close @click="showModal('add-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title" style="font-size: 24px;">Pilih Pekerja</h2>
                </div>
                <div class="uk-modal-body">

                    <div>
                        <multiselect
                            v-model="createSchedule.currentUser"
                            :options="userList"
                            :multiple="true"
                            :close-on-select="true"
                            placeholder="Pilih Pekerja"
                            label="fullname"
                            track-by="fullname"
                            @select="confirmWorkerOnCreate">
                            </multiselect>
                    <ul class="uk-list">
                        <li v-for="user in createSchedule.currentUser" :key="user._id" style="justify-content:space-between;flex-direction:row;display:flex">
                            <span>{{user.fullname}}</span>
                            <img @click="handleRemoveWorker(user._id)" :src="`${images}/trash.png`" alt="" width="24" height="24" style="width:24px;height:24px;margin-right:10px;cursor:pointer">
                        </li>
                    </ul>
                    </div>

                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="showModal('add-schedule')">Ok</button>

                </div>
            </div>
        </div>

        <div id="select-workers-edit" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog" >
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('select-workers-edit')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title" style="font-size: 24px;">Pilih Pekerja</h2>
                </div>
                <div class="uk-modal-body">

                    <div>
                        <multiselect
                            v-model="detailSchedule.currentUser"
                            :options="userList"
                            :multiple="true"
                            :close-on-select="true"
                            placeholder="Pilih Pekerja"
                            label="fullname"
                            track-by="fullname"
                            @select="checkAvailability">
                            </multiselect>
                    <ul class="uk-list">
                        <li v-for="user in detailSchedule.currentUser" :key="user._id" style="justify-content:space-between;flex-direction:row;display:flex">
                            <span :class="user.status_worker === 2 ? 'uk-text-danger': ''">{{user.fullname}}</span>
                            <img @click="handleRemoveWorker2(user._id)" :src="`${images}/trash.png`" alt="" width="24" height="24" style="margin-right:10px;cursor:pointer">
                        </li>
                    </ul>
                    </div>
                </div>
                 <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="showModal('edit-schedule')">Ok</button>

                </div>
            </div>
        </div>

        <div id="detail-workers" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog" >
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('select-workers')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title" style="font-size: 24px;">Detail Pekerja</h2>
                </div>
                <div class="uk-modal-body">

                    <div>
                    <ul class="uk-list">
                        <li v-for="user in detailSchedule.currentUser" :key="user._id" style="justify-content:space-between;flex-direction:row;display:flex">
                            <span>{{user.fullname}}</span>
                            <span class="uk-text-muted uk-text-small" v-if="user.status_worker === 1">Terkonfirmasi
                                <img :src="`${images}/checkmark.png`" alt="" width="24" height="24"></span>
                            <span v-else-if="user.status_worker === 0" class="uk-text-muted uk-text-small">Menunggu Konfirmasi</span>
                            <span class="uk-text-danger uk-text-small" v-if="user.status_worker === 2">Menolak
                            <img :src="`${images}/danger.svg`" alt="" width="24" height="24"></span>
                        </li>
                    </ul>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="showModal('shift-detail')">Ok</button>

                </div>
            </div>
        </div>

        <div id="notif-mitra" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('notif-mitra')"></button>

                <div class="uk-modal-body uk-flex uk-flex-center uk-flex-middle uk-margin-remove-bottom uk-padding">
                    <h2 class="uk-modal-title" style="font-size:18px">
                        <img :src="`${images}/notif.png`" alt="" width="37" height="37" style="margin-right:10px">
                        Beri notifikasi kepada mitra?</h2>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="hideModal('notif-mitra')">Tidak</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="publishSchedule">Ya</button>

                </div>
            </div>
        </div>

        <div id="delete-shift" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-shift')"></button>

                <div class="uk-modal-header" style="border-bottom:none">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px;font-weight:bold">
                        Delete?</h2>
                </div>
                <div class="uk-modal-footer uk-text-right" style="justify-content:space-between;display:flex">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleDeleteShift(true)" style="margin-left:0 !important;">
                        Delete for {{moment(detailSchedule.date).format('DD-MMM-YYYY')}}</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleDeleteShift(false)">Delete All</button>
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="hideModal('delete-shift')">Cancel</button>

                </div>
            </div>
        </div>

        <div id="delete-specific" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-specific')"></button>

                <div class="uk-modal-header" style="border-bottom:none">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px;font-weight:bold">
                        Are you sure?</h2>
                </div>
                <div class="uk-modal-body">
                    <p v-if="isDeleteSpecific">{{detailSchedule.name}} will be deleted for {{moment(detailSchedule.date).format('DD MMMM YYYY')}}</p>
                    <p v-else>All {{detailSchedule.name}} occurances will be deleted</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="hideModal('delete-specific')">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleDeleteConfirm">Yes</button>

                </div>
            </div>
        </div>

        <div id="delete-success" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-success')"></button>

                <div class="uk-modal-body">
                    <div style="margin:auto;width:64px">
                    <img :src="`${images}/tick_cut.gif`" alt="" width="68" height="64" style="margin-right:10px">
                    </div>
                    <p v-if="isDeleteSpecific">{{detailSchedule.name}} deleted for {{moment(detailSchedule.date).format('DD MMMM YYYY')}}!</p>
                    <p v-else>All {{detailSchedule.name}} occurances deleted!</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('delete-success')">Back</button>
                </div>
            </div>
        </div>

        <div id="availability-warning" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <div class="uk-modal-header">
                    <h3 class="uk-flex uk-flex-center uk-flex-middle">
                        {{this.availability.user.fullname}} sudah berada pada shift lain di waktu yang sama
                    </h3>
                </div>
                <div class="uk-modal-body uk-flex uk-flex-center uk-flex-middle uk-margin-remove-bottom uk-padding">
                    <p>
                        {{this.availability.user.fullname}} sudah berada pada {{this.availability.shift.name}} yang berjalan pada pukul
                        {{this.availability.shift.start_shift.hour}}.{{("0" + this.availability.shift.start_shift.minute).slice(-2)}} -
                        {{this.availability.shift.end_shift.hour}}.{{("0" + this.availability.shift.end_shift.minute).slice(-2)}}. Silahkan pilih mitra lain!
                    </p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                      class="uk-button uk-button-primary uk-margin-small-left" type="button"
                      @click="onMitraUnavailable(availability.isCreate)">OK</button>
                </div>
            </div>
        </div>

        <div id="confirmation-select-workers" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-select-workers')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px">
                        {{this.availability.user.fullname}} sudah berada di shift {{this.availability.shift.name}}!</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Apakah Anda ingin memasukkan {{this.availability.user.fullname}} pada shift ini?</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button"  @click="onMitraUnavailable2(availability.isCreate)">Batalkan</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button"
                      @click="availability.isCreate ? showModal('select-workers') : showModal('select-workers-edit')">Ya</button>
                </div>
            </div>
        </div>

        <div id="notif-success" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('notif-success')"></button>

                <div class="uk-modal-body">
                    <div style="margin:auto;width:64px">
                    <img :src="`${images}/tick_cut.gif`" alt="" width="68" height="64" style="margin-right:10px">
                    </div>
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px;text-align:center !important">
                        Notifikasi Terkirim!</h2>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('notif-success')">Kembali ke Jadwal</button>
                </div>
            </div>
        </div>



        <div id="confirmation-warehouse" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-warehouse')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px">
                        Anda belum memilih Warehouse</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Silakan pilih Warehouse terlebih dahulu pada filter warehouse!</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button @click="hideModal('confirmation-warehouse')" class="uk-button uk-button-primary uk-margin-small-left" type="button" >Kembali</button>
                </div>
            </div>
        </div>

        <div id="add-uniq-schedule" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('add-uniq-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Tambah Jadwal Khusus</h2>
                </div>
                <div class="uk-modal-body">
                     <div class="uk-margin">
                         <label class="uk-form-label" for="form-stacked-text">Nama Jadwal Khusus</label>
                        <input style="margin-top:10px;width:326px;display:block" class="uk-input" type="text" placeholder="Nama Jadwal Khusus">
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleCreate">Simpan</button>
                </div>
            </div>
        </div>

        <div id="list-uniq-schedule" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('list-uniq-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Daftar Jadwal Khusus</h2>
                </div>
                <div class="uk-modal-body">
                     <v-row v-for="item in scheduleName" style="justify-content:space-between" :key="item._id">
                         <span>{{item.name}}</span>
                         <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="openEditName(item)">Edit</button>
                     </v-row>
                </div>
            </div>
        </div>

        <div id="edit-name-schedule" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-name-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Edit Jadwal Khusus</h2>
                    <span>{{editName.name}}</span>
                </div>
                <div class="uk-modal-body">
                    <label class="uk-form-label" for="form-stacked-text">Nama Jadwal Khusus</label>
                    <input v-model="editName.nameUpdate" style="margin-top:10px;width:326px;display:block" class="uk-input" type="text" placeholder="Nama Jadwal Khusus">
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleEditName()">Simpan</button>
                </div>
            </div>
        </div>

        <div id="add-schedule" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('add-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Tambah Jadwal</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Shift</label>
                         <select
                            style="margin-top:10px;width:326px;display:block"
                            name="Updated By Filter"
                            class="uk-select"
                            id="form-stacked-select"
                            v-model="createSchedule.companyShift"
                        >
                            <option value="" disabled selected>Pilih Shift</option>
                            <option v-for="data in companyShift" :value="data._id" :key="data._id">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Hari</label>
                        <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDay('senin')"
                            :class="(createSchedule.btnSenin)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Senin</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('selasa')"
                            :class="(createSchedule.btnSelasa)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Selasa</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('rabu')"
                            :class="(createSchedule.btnRabu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Rabu</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('kamis')"
                            :class="(createSchedule.btnKamis)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Kamis</button>
                            </div>
                        </div>
                        <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDay('jumat')"
                            :class="(createSchedule.btnJumat)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Jumat</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('sabtu')"
                            :class="(createSchedule.btnSabtu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Sabtu</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('minggu')"
                            :class="(createSchedule.btnMinggu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Minggu</button>
                            </div>
                        </div>
                    </div>
                    <p>Masa Berlaku</p>
                    <div class="uk-width-1-1 uk-column-1-2@m">
                        <label class="uk-form-label" for="form-stacked-text">Dari</label>
                        <div class="uk-form-controls">
                            <div class="uk-form-controls">

                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="Start Date"
                                :min-datetime="moment().format()"
                                v-model="createSchedule.start_date"
                                placeholder="dd/mm/yyy"
                            ></datetime>
                    </div>
                        </div>
                        <label class="uk-form-label" for="form-stacked-text">Sampai (Optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="End Date"
                                :min-datetime="createSchedule.start_date"
                                v-model="createSchedule.end_date"
                                placeholder="dd/mm/yyy"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Role</label>
                         <select
                            style="margin-top:10px;width:326px;display:block"
                            name="Updated By Filter"
                            class="uk-select"
                            id="form-stacked-select"
                            v-model="createSchedule.role"
                        >
                            <option value="" disabled selected>Pilih Role</option>
                            <option v-for="data in roleList" :value="data._id" :key="data._id">{{data.name}}</option>
                        </select>
                    </div>

                    <!--<div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label><input class="uk-checkbox" v-model="createSchedule.isSpecialSchedule" type="checkbox"> Merupakan jadwal khusus</label>
                    </div>
                     <div class="uk-margin" v-if="createSchedule.isSpecialSchedule">
                         <label class="uk-form-label" for="form-stacked-text">Jadwal Khusus</label>
                         <autocomplete :suggestions="specialOptions" @update-value="updateSpecialValue"></autocomplete>
                    </div>-->
                    <div class="uk-margin">
                        <button
                          :disabled="!allowSelectWorker()"
                          class="uk-button uk-button-primary uk-button-small" @click="showModal('select-workers')"
                          :uk-tooltip="!allowSelectWorker() ? 'Pilih shift, hari kerja, dan tanggal mulai terlebih dulu!' : 'Pilih pekerja untuk jadwal ini'"
                          :class="{'btn-choose-disabled': !allowSelectWorker(), 'btn-choose': allowSelectWorker()}"
                        >
                          Pilih Pekerja
                        </button>
                        <p v-if="createSchedule.currentUser.length > 0">{{createSchedule.currentUser.length}} pekerja dipilih</p>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleCreate">Simpan</button>
                </div>
            </div>
        </div>

        <div id="edit-schedule" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Edit</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Shift</label>
                         <select
                            style="margin-top:10px;width:326px;display:block"
                            name="Updated By Filter"
                            class="uk-select"
                            id="form-stacked-select"
                            v-model="detailSchedule.companyShift"
                            :disabled="(!detailSchedule.is_valid_forever)?true:false"
                        >
                            <option value="" disabled selected>Pilih Shift</option>
                            <option v-for="data in companyShift" :value="data._id" :key="data._id">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label><input class="uk-checkbox" type="checkbox" v-model="detailSchedule.is_valid_forever" @change="handleChangeCheckbox"> Perubahan berlaku untuk seterusnya</label>
                        <p v-if="!detailSchedule.is_valid_forever" style="margin-top:10px">Perubahan berlaku untuk {{moment(detailSchedule.date).format('ddd, DD MMM YYYY')}}</p>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Hari</label>
                        <div v-if="detailSchedule.is_valid_forever" class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDayEdit('senin')"
                            :class="(detailSchedule.btnSenin)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Senin</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('selasa')"
                            :class="(detailSchedule.btnSelasa)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Selasa</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('rabu')"
                            :class="(detailSchedule.btnRabu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Rabu</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('kamis')"
                            :class="(detailSchedule.btnKamis)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Kamis</button>
                            </div>
                        </div>
                        <div v-if="detailSchedule.is_valid_forever" class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDayEdit('jumat')"
                            :class="(detailSchedule.btnJumat)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Jumat</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('sabtu')"
                            :class="(detailSchedule.btnSabtu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Sabtu</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('minggu')"
                            :class="(detailSchedule.btnMinggu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Minggu</button>
                            </div>
                        </div>

                        <div v-if="!detailSchedule.is_valid_forever" class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button
                            class="btn-sml-active uk-button uk-button-primary uk-button-small">{{detailSchedule.specific.day}}</button>
                            </div>
                        </div>
                    </div>
                    <p>Masa Berlaku</p>
                    <div class="uk-width-1-1 uk-column-1-2@m">
                        <label class="uk-form-label" for="form-stacked-text">Dari</label>
                        <div class="uk-form-controls">
                            <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                :min-datetime="moment().format()"
                                name="Start Date"
                                v-model="detailSchedule.start_date"
                                placeholder="dd/mm/yyy"
                                :disabled="(!detailSchedule.is_valid_forever)?true:false"
                            ></datetime>
                            </div>
                        </div>
                        <label class="uk-form-label" for="form-stacked-text">Sampai (Optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="End Date"
                                :min-datetime="detailSchedule.start_date"
                                v-model="detailSchedule.end_date"
                                placeholder="dd/mm/yyy"
                                :disabled="(!detailSchedule.is_valid_forever)?true:false"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Role</label>
                         <select
                            style="margin-top:10px;width:326px;display:block"
                            name="Updated By Filter"
                            class="uk-select"
                            id="form-stacked-select"
                            v-model="detailSchedule.role"
                            :disabled="(!detailSchedule.is_valid_forever)?true:false"
                        >
                            <option value="" disabled selected>Pilih Role</option>
                            <option v-for="data in roleList" :value="data._id" :key="`k-${data._id}`">{{data.name}}</option>
                        </select>
                    </div>


                    <!--<div class="uk-margin">
                         <label class="uk-form-label" for="form-stacked-text">Jadwal Khusus</label>
                         <autocomplete :suggestions="specialOptions" @update-value="updateSpecialValue"></autocomplete>
                    </div>-->
                    <div class="uk-margin">
                        <button class="btn-choose uk-button uk-button-primary uk-button-small"  @click="selectWorkersEdit()">Pilih Pekerja</button>
                        <p v-if="detailSchedule.currentUser.length > 0">{{detailSchedule.currentUser.length}} pekerja dipilih</p>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button"
                    @click="(!detailSchedule.is_valid_forever)?handleSaveEditSpecific():handleSaveEdit()">Simpan</button>
                </div>
            </div>
        </div>

        <div id="shift-detail" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('shift-detail')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title" style="margin-bottom:10px">{{detailSchedule.name}}
                        <span v-if="detailSchedule.end_date != null">{{moment(detailSchedule.start_date).format('Do MMM')}}</span></h2>
                    <p style="margin-top:10px">{{moment(detailSchedule.date).format('ddd, DD MMM YYYY')}}</p>
                </div>

                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Shift</label>
                         <select
                            style="margin-top:10px;width:326px;display:block"
                            name="Updated By Filter"
                            class="uk-select"
                            id="form-stacked-select"
                            disabled
                            v-model="detailSchedule.companyShift"
                        >
                            <option value="" disabled selected>Pilih Shift</option>
                            <option v-for="data in companyShift" :value="data._id" :key="data._id">{{data.name}}
                                <span v-if="detailSchedule.end_date != null">{{moment(detailSchedule.start_date).format('Do MMM')}}</span></option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Hari</label>
                        <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button
                            :class="(detailSchedule.btnSenin)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Senin</button>
                            </div>
                            <div>
                            <button
                            :class="(detailSchedule.btnSelasa)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Selasa</button>
                            </div>
                            <div>
                            <button
                            :class="(detailSchedule.btnRabu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Rabu</button>
                            </div>
                            <div>
                            <button
                            :class="(detailSchedule.btnKamis)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Kamis</button>
                            </div>
                        </div>
                        <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button
                            :class="(detailSchedule.btnJumat)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Jumat</button>
                            </div>
                            <div>
                            <button
                            :class="(detailSchedule.btnSabtu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Sabtu</button>
                            </div>
                            <div>
                            <button
                            :class="(detailSchedule.btnMinggu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Minggu</button>
                            </div>
                        </div>
                    </div>
                    <p>Masa Berlaku</p>
                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label><input class="uk-checkbox" type="checkbox" v-model="detailSchedule.is_valid_forever" disabled> Masa berlaku selamanya</label>
                    </div>
                    <div class="uk-width-1-1 uk-column-1-2@m">
                        <label class="uk-form-label" for="form-stacked-text">Dari</label>
                        <div class="uk-form-controls">
                            <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="Start Date"
                                v-model="detailSchedule.start_date"
                                disabled
                                placeholder="dd/mm/yyy"
                            ></datetime>
                            </div>
                        </div>
                        <label class="uk-form-label" for="form-stacked-text">Sampai (Optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="End Date"
                                v-model="detailSchedule.end_date"
                                disabled
                                placeholder="dd/mm/yyy"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Role</label>
                         <select
                            style="margin-top:10px;width:326px;display:block"
                            name="Updated By Filter"
                            class="uk-select"
                            id="form-stacked-select"
                            disabled
                            v-model="detailSchedule.role"
                        >
                            <option value="" disabled selected>Pilih Role</option>
                            <option v-for="data in roleList" :value="data._id" :key="data._id">{{data.name}}</option>
                        </select>
                    </div>

                    <div class="uk-margin">
                        <button class="btn-choose uk-button uk-button-primary uk-button-small" @click="showModal('detail-workers')">Lihat Detail Pekerja</button>
                    </div>
                </div>
                <div class="uk-modal-footer" style="justify-content:space-between;display:flex">
                    <button @click="showModal('delete-shift')" class="uk-button uk-button-danger uk-margin-small-left" type="button" style="margin-left:0 !important;"
                    v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation'])">Delete</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleEdit"
                    v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','admin-bd'])">Edit</button>
                </div>
            </div>
        </div>

        <div id="location-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('location-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input property for which you want to see details</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">City</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office City"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="" disabled selected>Choose City</option>
                                <option v-for="(city, index) in cities" :key="index" :value="city">{{city}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">Property</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office Property"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="company_office"
                            >
                                <option value="" disabled selected>Choose Property</option>
                                <option v-for="(office, index) in filtered_offices" :key="index" :value="office">{{office.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setLocation">Apply</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
// import LabelStatus from '@/components/globals/LabelStatus';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { Datetime } from 'vue-datetime';
import {
    notificationDangerCustom
} from '@/utils/notification';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
moment.locale('id');
//import Autocomplete from '../../autocomplete.vue';
import { getUserLogin } from '@/utils/auth';

export default {
    data() {
        return {
            loadingSchedule: true,
            isDeleteSpecific: '',
            scheduleName: [],
            moment: moment,
            focus: '',
            scheduleList: [],
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            specialScheduleValue:'',
            specialOptions: [],
            userOptions: [],
            selectedMonth: '',
            selectedMonthIndex: moment().endOf('month').format('YYYY-MM-DD'),
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            selectedSchedulePublish: '',
            events: [],
            colors: ['orange'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            type: 'month',
            modalMode: 'add',
            dataSchedule: [{
                hour: '01:00'
            },{
                hour: '02:00'
            },{
                hour: '03:00'
            },{
                hour: '04:00'
            },{
                hour: '05:00'
            },{
                hour: '06:00'
            },{
                hour: '07:00'
            },{
                hour: '08:00'
            },{
                hour: '09:00'
            },{
                hour: '10:00'
            },{
                hour: '11:00'
            },{
                hour: '12:00'
            },{
                hour: '13:00'
            },{
                hour: '14:00'
            },{
                hour: '15:00'
            },{
                hour: '16:00'
            },{
                hour: '17:00'
            },{
                hour: '18:00'
            },{
                hour: '19:00'
            },{
                hour: '20:00'
            },{
                hour: '21:00'
            },{
                hour: '22:00'
            },{
                hour: '23:00'
            },{
                hour: '24:00'
            }],
            weekday: [1, 2, 3, 4, 5, 6, 0],
            meta: {
                limit: 50,
                page: 1,
                schedulePick: 'weekly',
                filterRole: '',
                start_date: null,
                end_date: null,
                property_city: null,
                property_name: null,
                month: null,
                year: new Date().getFullYear(), // need to change
                worker_name: '',
                updated_filter: 'weekly'
            },
            json_data: null,
            images: PREFIX_IMAGE,
            plans: [],
            managers: [],
            company_office: '',
            disabled_start_date: {
                to: new Date('2020','07','01') // 1 August 2020
            },
            selected_city: '',
            cities: [],
            filtered_offices: [],
            selected_month: '',
            selected_data: {
                user: {},
                company: {},
                company_office: {},
                job_partner: {
                    role: {}
                }
            },
            timeIsInvalid: false,
            createSchedule: {
                btnSenin: false,
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                end_date: null,
                specialScheduleText: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                currentUser: []
            },
            detailSchedule: {
                btnSenin: false,
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                specific: {date:null,day:null},
                end_date: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                specialScheduleText: null,
                currentUser: []
            },
            companyOfficeId: '',
            companyShift: [],
            roleList: [],
            userList: [],
            editName: {},
            availability: {
                user: {},
                shift: {
                    start_shift: 0,
                    end_shift: 0,
                },
                isCreate: false,
            },
            styleData: {},
        };
    },
    components: {
        // LabelStatus,
        EmptyTable,
        Datetime,
        Multiselect,
        //Autocomplete
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            recaps: 'mitra/recaps',
            isLogin: 'auth/isLogin'
        }),
        startDateModifier() {
            const { start_date } = this.meta;
            return { start_date };
        },
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        meta() {
            this.getRecaps(this.meta);
        },
        startDateModifier: {
            handler:function() {
                this.meta.end_date = moment(this.meta.start_date).add(6, 'days').toISOString();
            }
        }
    },
    async mounted() {
        const roles = await this.getCompanyRole();
        this.roleList = roles;
        const profile = await this.getProfile();
        this.company_office = this.$store.state.selected_company == '' ? profile.company_office : this.$store.state.selected_company;
        this.companyOfficeId = await this.company_office._id;
        const cmp = await this.getAllCompanyShift({companyOfficeId: this.companyOfficeId});
        this.companyShift = cmp;
        this.fetchSchedules();
        this.loadingSchedule = false;
        const users = await this.getUserPartner({company_office_id: this.companyOfficeId});
        this.userList = users;
        this.setCity();
        await this.getCompanyOffice();
        const scheduleName = await this.getScheduleName();
        let specialOptions = [];
        scheduleName.map((item) => {
            specialOptions.push(item.name);
        });
        this.scheduleName = scheduleName;
        this.specialOptions = specialOptions;
        // this.selectCompanyOffice();
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getProfile: 'auth/getProfile',
            getAllCompanyShift: 'schedule/getAllCompanyShift',
            getScheduleShift: 'schedule/getScheduleShift',
            getShiftDetail: 'option_data/getShiftDetail',
            getCompanyRole: 'schedule/getCompanyRole',
            getUserPartner: 'schedule/getUserPartner',
            postCreateSchedule: 'schedule/postCreateSchedule',
            showScheduleShift: 'schedule/showScheduleShift',
            putEditSchedule: 'schedule/putEditSchedule',
            putEditSpesificSchedule: 'schedule/putEditSpesificSchedule',
            getScheduleName: 'schedule/getScheduleName',
            patchScheduleName: 'schedule/patchScheduleName',
            postNotifSchedule: 'schedule/postNotifSchedule',
            deleteScheduleShift: 'schedule/deleteScheduleShift',
            deleteSpecificScheduleShift: 'schedule/deleteSpecificScheduleShift',
            deleteUserSchedule: 'schedule/deleteUserSchedule',
            checkAvailable: 'schedule/checkAvailable',
        }),
        addSchedule(){
            if (this.company_office.city == null || this.company_office.name == null) {
                this.showModal('confirmation-warehouse');
            } else {
                this.showModal('add-schedule');
            }
        },
        async fetchSchedules() {
            this.styleData = {};
            const schedules = await this.getScheduleShift({
                company_office_id: this.companyOfficeId,
                company_role_id: this.meta.filterRole,
                from_date: this.meta.start_date || moment().utc().startOf('day').toISOString(),
                to_date: this.meta.end_date ||  moment().utc().startOf('day').add(6, 'days').toISOString(),
                view_in: 'calendar'
            });
            this.scheduleList = schedules;
            const users = await this.getUserPartner({company_office_id: this.companyOfficeId});
            this.userList = users;
        },
        async handleDeleteConfirm(){
            let res = '';
            if (this.isDeleteSpecific) {
                res = await this.deleteSpecificScheduleShift({id:this.detailSchedule._id, date:this.detailSchedule.date});
            } else {
                res = await this.deleteScheduleShift({id:this.detailSchedule._id});
            }
            if (res.toLowerCase() == 'success') {
                this.showModal('delete-success');
                this.fetchSchedules();
                this.loadScheduleName();

            }
        },
        handleChangeCheckbox(){
            if (process.env.NODE_ENV !== 'production') {
                console.log(this.detailSchedule.is_valid_forever);
            }
        },
        handleClickCard(id, idx){
            const ell = document.getElementsByClassName('shift-card');
            for (var i = 0; i < ell.length; i++) {
                ell[i].style.zIndex = '1000';
            }
            const el = document.getElementById(`shift-card-${id}-${idx}`);
            el.style.zIndex = '1001';
        },
        handleDeleteShift(n){
            this.isDeleteSpecific = n;
            this.showModal('delete-specific');
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        async changeScheduleTime(){
            let payload = {};
            this.loadingSchedule = true;
            if (this.meta.schedulePick == 'weekly') {
                this.fetchSchedules();
            } else {
                const date = new Date(), y = date.getFullYear(), m = date.getMonth();
                const firstDay = new Date(y, m, 1);
                const lastDay = new Date(y, m + 1, 0);
                payload = {
                    company_office_id: this.companyOfficeId,
                    company_role_id: '',
                    from_date: firstDay,
                    to_date: lastDay,
                    view_in: 'calendar'
                };
                const schedules = await this.getScheduleShift(payload);
                const events = [];
                schedules.map((data) => {
                    const schedule_shifts = data.schedule_shifts;
                    schedule_shifts.map((item) => {
                        if (item.type > 0) {
                            events.push({
                                color: 'orange',
                                start: moment(data.date).format('YYYY-MM-DD hh:mm'),
                                name: item.name,
                                timed: true
                            });
                        }
                    });
                });
                this.events = events;
                this.scheduleList = schedules;
            }

            this.loadingSchedule = false;
        },
        openEditName(item) {
            this.editName = {
                name: item.name,
                _id: item._id
            };
            this.showModal('edit-name-schedule');
        },
        selectWorkersEdit() {
            this.showModal('select-workers-edit');
        },
        async handleEditName(){
            await this.patchScheduleName({id:this.editName._id,param:{name:this.editName.nameUpdate}});
            await this.loadScheduleName();
            this.hideModal('edit-name-schedule');
        },
        handleRemoveWorker(id){
            const data = this.createSchedule.currentUser.filter((item) => {
                return item._id !== id;
            });
            this.createSchedule.currentUser = data;
        },
        handleRemoveWorker2(id){
            const data = this.detailSchedule.currentUser.filter((item) => {
                return item._id !== id;
            });
            this.detailSchedule.currentUser = data;
        },
        updateSpecialValue(v){
            this.specialScheduleValue = v;
        },
        async loadScheduleName(){
            const scheduleName = await this.getScheduleName();
            let specialOptions = [];
            scheduleName.map((item) => {
                specialOptions.push(item.name);
            });
            this.scheduleName = scheduleName;
            this.specialOptions = specialOptions;
        },
        async handleFilterRole(){
            // const ff = this.roleList.filter((item) => {
            //     const str = item.name.toLowerCase();
            //     return str.indexOf(this.meta.filterRole.toLowerCase()) != -1;
            // });
            // if (ff.length > 0) {
            //     const schedules = await this.getScheduleShift({
            //         company_office_id: this.companyOfficeId,
            //         company_role_id: ff[0]._id,
            //         from_date: this.meta.start_date,
            //         to_date: this.meta.end_date,
            //         view_in: 'calendar'
            //     });
            //     this.scheduleList = schedules;
            // }
            this.fetchSchedules();
        },
        viewDay ({ date }) {
            this.focus = date;
            this.type = 'day';
        },
        topShift(id) {
            if (document.getElementById('shift-card-'+id+'-1').style.getPropertyValue("top") == '20px') {
                return true;
            } else {
                return false;
            }
        },
        async handleClickDetail(data, date, day) {
            const res = await this.showScheduleShift({id:data._id,date:date});
            this.showModal('shift-detail');
            let users = [];
            res.users.map((item) => {
                users.push({
                    _id: item._id,
                    fullname: item.name,
                    status_worker: item.status_worker
                });
            });
            this.detailSchedule = {
                _id: data._id,
                date: date,
                specific: {date:date,day:day},
                btnSenin: res.monday,
                btnSelasa: res.tuesday,
                btnRabu: res.wednesday,
                btnKamis: res.thursday,
                btnJumat: res.friday,
                btnSabtu: res.saturday,
                btnMinggu: res.sunday,
                is_valid_forever: res.is_valid_forever,
                name: res.company_shift.name,
                createdAt: res.created_at,
                start_date: res.valid_from,
                end_date: res.valid_to,
                companyShift: res.company_shift._id,
                companyShiftDetail: res.company_shift,
                role: (res.company_role) ? res.company_role._id : null,
                isSpecialSchedule: false,
                currentUser: users
            };
        },
        getTime (n) {
            if (parseInt(n) < 10) {
                return '0'+n;
            }
            return n;
        },
        handleClickUser(user) {
            this.createSchedule.currentUser.push(user);
            this.hideModal('select-workers');
            this.showModal('add-schedule');
        },
        handleClickUserEdit(user) {
            const data = {
                name: user.fullname,
                _id: user._id
            };
            this.detailSchedule.currentUser.push(data);
            this.hideModal('select-workers-edit');
            this.showModal('edit-schedule');
        },
        generateCardStyle (data, isCrossDay, idx, id, wday) {
            let top = data.start_shift.hour;
            top = parseInt((isCrossDay)?1:data.start_shift.hour) * 40 - 20;
            if(!isCrossDay){
                top = top + (parseInt(data.start_shift.minute)/60 * 40);
            }

            let height = data.end_shift.hour;
            let minHeight = 0;
            if (!isCrossDay) {
                if (parseInt(data.start_shift.hour) > parseInt(data.end_shift.hour)) {
                    height = (24 - parseInt(data.start_shift.hour)) * 40;
                } else {
                    height = (parseInt(data.end_shift.hour) - parseInt(data.start_shift.hour)) * 40;
                }

                minHeight = parseInt(data.end_shift.minute)/60 * 40;
            } else {
                height = (parseInt(data.end_shift.hour) - 1) * 40;
                minHeight = parseInt(data.end_shift.minute)/60 * 40;
            }


            let w = 120;
            w = w - ((idx+1) * 3);

            if (document.getElementById('shift-card-'+id)) {
                // const node = document.getElementById('shift-card-'+id).childNodes;
                // for (let i = 0; i < node.length; i++) {
                // // Do stuff
                //     const size = 14 - (idx + 1);
                //     node[i].style.fontSize = `${size}px`;
                // }
                // node.map((item) => {
                //     const size = 10 / (idx + 1);
                //     item.style.fontSize = `${size}px`;
                // });
            }

            let right = 0;
            if (!this.styleData[wday]) {
                this.styleData[wday] = [];
            }
            const occurances = this.styleData[wday]
                .filter(e => (e.hour === (isCrossDay ? 1 : data.start_shift.hour)) && e.idx < idx)
                .filter((v,i,a) => a.findIndex(t => t.idx === v.idx) === i);

            right = 15 * occurances.length;
            if (isCrossDay) {
                this.styleData[wday].push({idx, hour: 1});
            } else {
                this.styleData[wday].push({idx, hour: data.start_shift.hour});
            }

            return `top: ${top}px;height: ${height+minHeight}px;width: ${w}px;right:${right}px`;
        },
        getDuration(data) {
            return parseInt(data.end_shift.hour) - parseInt(data.start_shift.hour);
        },
        allowSelectWorker() {
            const daySelected =
                this.createSchedule.btnSenin || this.createSchedule.btnSelasa ||
                this.createSchedule.btnRabu || this.createSchedule.btnKamis ||
                this.createSchedule.btnJumat || this.createSchedule.btnSabtu ||
                this.createSchedule.btnMinggu;

            return this.createSchedule.start_date && this.createSchedule.companyShift &&  daySelected;
        },
        async confirmWorkerOnCreate(user) {
            const {companyShift, start_date} = this.createSchedule;
            const shiftDetail = await this.getShiftDetail(companyShift);

            const selectedDays = [
                'btnSenin', 'btnSelasa', 'btnRabu', 'btnKamis',
                'btnJumat', 'btnSabtu', 'btnMinggu'
            ]
                .map(day => this.createSchedule[day])
                .reduce((next, bool, index) => bool ? next.concat(index + 1) : next, []);

            const startDay = moment(start_date).isoWeekday();
            const optSchedule = {shift: shiftDetail};

            // Immediately rejects all upon one rejection
            await Promise.all(selectedDays.map(day => startDay <= day
                ? this.checkAvailability(user, true, {...optSchedule, date: moment(start_date).isoWeekday(day).toISOString()})
                : this.checkAvailability(user, true, {...optSchedule, date: moment(start_date).add(1, 'weeks').isoWeekday(day).toISOString()})
            ));
        },
        async checkAvailability(user, isCreate = false, optSchedule = {}) {
            const response = await this.checkAvailable({
                date: isCreate ?  optSchedule.date : this.detailSchedule.date,
                user_id: user._id
            });
            if (!response) return Promise.resolve();

            let currentStart =  isCreate
                ? optSchedule.shift.start_shift.hour
                : this.detailSchedule.companyShiftDetail.start_shift.hour;
            let currentEnd = isCreate
                ? optSchedule.shift.end_shift.hour
                : this.detailSchedule.companyShiftDetail.end_shift.hour;

            let latterStart = response.start_shift.hour;
            let latterEnd = response.end_shift.hour;

            if (currentEnd < currentStart) currentEnd += 24;
            if (latterEnd < latterStart) latterEnd += 24;

            if ( // Overlap
                latterStart <= currentEnd
                &&
                latterEnd >= currentStart
            ) {
                this.availability.shift = response;
                this.availability.user = user;
                this.availability.isCreate = isCreate;
                this.showModal('availability-warning');
                return Promise.reject();
            } else if (response !== null) {
                this.availability.shift = response;
                this.availability.user = user;
                this.availability.isCreate = isCreate;
                this.showModal('confirmation-select-workers');
                return Promise.reject();
            }
            return Promise.resolve();
        },
        onMitraUnavailable(isCreate) {
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser =
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser.filter(
                user => user._id !== this.availability.user._id
            );

            this.availability = {
                user: {},
                shift: {
                    start_shift: 0,
                    end_shift: 0,
                },
                isCreate: false,
            };
            this.hideModal('availability-warning');
            isCreate ? this.showModal('select-workers') : this.showModal('select-workers-edit');
        },
        onMitraUnavailable2(isCreate) {
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser =
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser.filter(
                user => user._id !== this.availability.user._id
            );

            this.availability = {
                user: {},
                shift: {
                    start_shift: 0,
                    end_shift: 0,
                },
                isCreate: false,
            };
            this.hideModal('confirmation-select-workers');
            isCreate ? this.showModal('select-workers') : this.showModal('select-workers-edit');
        },
        resetForm(){
            this.createSchedule = {
                btnSenin: false,
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                end_date: null,
                specialScheduleText: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                currentUser: []
            };
            this.detailSchedule = {
                btnSenin: false,
                specific: {date:null,day:null},
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                end_date: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                specialScheduleText: null,
                currentUser: []
            };
        },
        async handleCreate() {
            const userArr = [];
            let valid = true;
            this.createSchedule.currentUser.map((it) => {
                userArr.push(it._id);
            });
            const payload = {
                "company_shift_id": this.createSchedule.companyShift,
                "company_role_id": this.createSchedule.role,
                "is_special_schedule": this.createSchedule.isSpecialSchedule,
                "name": (this.specialScheduleValue != '') ? this.specialScheduleValue : null,
                "is_valid_forever": false,
                "valid_from": this.createSchedule.start_date,
                "valid_to": (this.createSchedule.end_date != '')?this.createSchedule.end_date:null,
                "monday": this.createSchedule.btnSenin,
                "tuesday": this.createSchedule.btnSelasa,
                "wednesday": this.createSchedule.btnRabu,
                "thursday": this.createSchedule.btnKamis,
                "friday": this.createSchedule.btnJumat,
                "saturday": this.createSchedule.btnSabtu,
                "sunday": this.createSchedule.btnMinggu,
                "users": userArr
            };
            if (this.createSchedule.currentUser == 0) {
                valid = false;
                return notificationDangerCustom('Pilih pekerja terlebih dahulu');
            }
            if (this.createSchedule.start_date == '' || this.createSchedule.start_date == null) {
                valid = false;
                return notificationDangerCustom('Silakan isi tanggal mulai terlebih dahulu');
            }
            const res = await this.postCreateSchedule(payload);
            if (res.toLowerCase() == 'success' && valid) {
                this.fetchSchedules();
                this.loadScheduleName();
                this.hideModal('add-schedule');
                this.resetForm();
            }
        },
        async handleSaveEdit () {
            const userArr = [];
            this.detailSchedule.currentUser.map((it) => {
                userArr.push(it._id);
            });
            const payload = {
                "company_shift_id": this.detailSchedule.companyShift,
                "company_role_id": this.detailSchedule.role,
                "is_special_schedule": this.detailSchedule.isSpecialSchedule,
                "name": (this.specialScheduleValue != '') ? this.specialScheduleValue : null,
                "is_valid_forever": false,
                "valid_from": this.detailSchedule.start_date,
                "valid_to": (this.detailSchedule.end_date != '')?this.detailSchedule.end_date:null,
                "monday": this.detailSchedule.btnSenin,
                "tuesday": this.detailSchedule.btnSelasa,
                "wednesday": this.detailSchedule.btnRabu,
                "thursday": this.detailSchedule.btnKamis,
                "friday": this.detailSchedule.btnJumat,
                "saturday": this.detailSchedule.btnSabtu,
                "sunday": this.detailSchedule.btnMinggu,
                "users": userArr
            };
            if (this.detailSchedule.currentUser == 0) {
                return notificationDangerCustom('Pilih pekerja terlebih dahulu');
            }
            const res = await this.putEditSchedule({payload:payload,id:this.detailSchedule._id});
            if (res.toLowerCase() == 'success') {
                this.hideModal('edit-schedule');
                this.fetchSchedules();
                this.loadScheduleName();
                this.resetForm();
            }
        },
        async handleSaveEditSpecific(){
            const userArr = [];
            this.detailSchedule.currentUser.map((it) => {
                userArr.push(it._id);
            });
            const payload = {
                "company_shift_id": this.detailSchedule.companyShift,
                "company_role_id": this.detailSchedule.role,
                "is_special_schedule": false,
                "name": (this.specialScheduleValue != '') ? this.specialScheduleValue : null,
                "users": userArr
            };
            if (this.detailSchedule.currentUser == 0) {
                return notificationDangerCustom('Pilih pekerja terlebih dahulu');
            }
            const res = await this.putEditSpesificSchedule({
                payload: payload,
                id: this.detailSchedule._id,
                date: this.detailSchedule.date
            });
            if (res.toLowerCase() == 'success') {
                this.hideModal('edit-schedule');
                this.fetchSchedules();
                this.loadScheduleName();
                this.resetForm();
            }
        },
        handleClickDay (day) {
            if (day == 'senin') {
                this.createSchedule.btnSenin = !this.createSchedule.btnSenin;
            }
            if (day == 'selasa') {
                this.createSchedule.btnSelasa = !this.createSchedule.btnSelasa;
            }
            if (day == 'rabu') {
                this.createSchedule.btnRabu = !this.createSchedule.btnRabu;
            }
            if (day == 'kamis') {
                this.createSchedule.btnKamis = !this.createSchedule.btnKamis;
            }
            if (day == 'jumat') {
                this.createSchedule.btnJumat = !this.createSchedule.btnJumat;
            }
            if (day == 'sabtu') {
                this.createSchedule.btnSabtu = !this.createSchedule.btnSabtu;
            }
            if (day == 'minggu') {
                this.createSchedule.btnMinggu = !this.createSchedule.btnMinggu;
            }
        },
        handleClickDayEdit (day) {
            if (day == 'senin') {
                this.detailSchedule.btnSenin = !this.detailSchedule.btnSenin;
            }
            if (day == 'selasa') {
                this.detailSchedule.btnSelasa = !this.detailSchedule.btnSelasa;
            }
            if (day == 'rabu') {
                this.detailSchedule.btnRabu = !this.detailSchedule.btnRabu;
            }
            if (day == 'kamis') {
                this.detailSchedule.btnKamis = !this.detailSchedule.btnKamis;
            }
            if (day == 'jumat') {
                this.detailSchedule.btnJumat = !this.detailSchedule.btnJumat;
            }
            if (day == 'sabtu') {
                this.detailSchedule.btnSabtu = !this.detailSchedule.btnSabtu;
            }
            if (day == 'minggu') {
                this.detailSchedule.btnMinggu = !this.detailSchedule.btnMinggu;
            }
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                setTimeout(() => {
                    this.selectedOpen = true;
                }, 10);
            };
            if (this.selectedOpen) {
                this.selectedOpen = false;
                setTimeout(open, 10);
            } else {
                open();
            }
            nativeEvent.stopPropagation();
        },
        updateRange ({ start, end }) {
            const events = [];
            const min = new Date(`${start.date}T00:00:00`);
            const max = new Date(`${end.date}T23:59:59`);
            const days = (max.getTime() - min.getTime()) / 86400000;
            const eventCount = this.rnd(days, days + 20);
            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0;
                const firstTimestamp = this.rnd(min.getTime(), max.getTime());
                const first = new Date(firstTimestamp - (firstTimestamp % 900000));
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
                const second = new Date(first.getTime() + secondTimestamp);
                events.push({
                    name: this.names[this.rnd(0, this.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: !allDay,
                });
            }
            this.events = events;
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a;
        },
        selectCompanyOffice(){
            this.company_office = this.company_offices.length > 0 ? this.company_offices[0] : 'n/a';
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        formatAbsolute(data) {
            return formatter.absoluteNumber(data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        redirectPage(link) {
            window.open(link, '_blank');
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').hide();
                break;
            case 'location-filter':
                await window.UIkit.modal('#location-filter').hide();
                break;
            case 'confirmation-warehouse':
                await window.UIkit.modal('#confirmation-warehouse').hide();
                break;
            case 'detail-workers':
                await window.UIkit.modal('#detail-workers').hide();
                break;
            case 'select-workers':
                await window.UIkit.modal('#select-workers').hide();
                break;
            case 'select-workers-edit':
                await window.UIkit.modal('#select-workers-edit').hide();
                break;
            case 'add-schedule':
                this.modalMode = 'add';
                await window.UIkit.modal('#add-schedule').hide();
                break;
            case 'edit-schedule':
                await window.UIkit.modal('#edit-schedule').hide();
                break;
            case 'shift-detail':
                this.modalMode = 'edit';
                await window.UIkit.modal('#shift-detail').show();
                break;
            case 'add-uniq-schedule':
                await window.UIkit.modal('#add-uniq-schedule').hide();
                break;
            case 'month-filter':
                await window.UIkit.modal('#month-filter').hide();
                break;
            case 'show-detail':
                await window.UIkit.modal('#show-detail').hide();
                this.selected_data= {
                    user: {},
                    company: {},
                    company_office: {},
                    job_partner: {
                        role: {}
                    }};
                this.timeIsInvalid = false;
                break;
            case 'confirmation-edit-box':
                await window.UIkit.modal('#confirmation-edit-box').hide();
                break;
            case 'confirmation-edit-box-2':
                await window.UIkit.modal('#confirmation-edit-box-2').hide();
                break;
            case 'confirmation-heads-up':
                await window.UIkit.modal('#confirmation-heads-up').hide();
                break;
            case 'confirmation-select-workers':
                await window.UIkit.modal('#confirmation-select-workers').hide();
                break;
            case 'list-uniq-schedule':
                await window.UIkit.modal('#list-uniq-schedule').hide();
                break;
            case 'success-edit':
                await window.UIkit.modal('#success-edit').hide();
                break;
            case 'notif-mitra':
                this.selectedSchedulePublish = '';
                await window.UIkit.modal('#notif-mitra').hide();
                break;
            case 'notif-success':
                await window.UIkit.modal('#notif-success').hide();
                break;
            case 'edit-name-schedule':
                await window.UIkit.modal('#edit-name-schedule').hide();
                break;
            case 'delete-shift':
                await window.UIkit.modal('#delete-shift').hide();
                break;
            case 'delete-specific':
                await window.UIkit.modal('#delete-specific').hide();
                break;
            case 'delete-success':
                await window.UIkit.modal('#delete-success').hide();
                break;
            case 'availability-warning':
                await window.UIkit.modal('#availability-warning').hide();
                break;
            }

        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').show();
                break;
            case 'delete-shift':
                await window.UIkit.modal('#delete-shift').show();
                break;
            case 'confirmation-warehouse':
                await window.UIkit.modal('#confirmation-warehouse').show();
                break;
            case 'delete-specific':
                await window.UIkit.modal('#delete-specific').show();
                break;
            case 'delete-success':
                await window.UIkit.modal('#delete-success').show();
                break;
            case 'select-workers':
                await window.UIkit.modal('#select-workers').show();
                break;
            case 'select-workers-edit':
                await window.UIkit.modal('#select-workers-edit').show();
                break;
            case 'notif-mitra':
                await window.UIkit.modal('#notif-mitra').show();
                break;
            case 'notif-success':
                await window.UIkit.modal('#notif-success').show();
                break;
            case 'confirmation-select-workers':
                await window.UIkit.modal('#confirmation-select-workers').show();
                break;
            case 'detail-workers':
                await window.UIkit.modal('#detail-workers').show();
                break;
            case 'add-schedule':
                this.modalMode = 'add';
                await window.UIkit.modal('#add-schedule').show();
                break;
            case 'add-uniq-schedule':
                await window.UIkit.modal('#add-uniq-schedule').show();
                break;
            case 'list-uniq-schedule':
                await window.UIkit.modal('#list-uniq-schedule').show();
                break;
            case 'edit-schedule':
                await window.UIkit.modal('#edit-schedule').show();
                break;
            case 'shift-detail':
                this.modalMode = 'edit';
                await window.UIkit.modal('#shift-detail').show();
                break;
            case 'location-filter':
                this.setCity();
                await window.UIkit.modal('#location-filter').show();
                break;
            case 'month-filter':
                await window.UIkit.modal('#month-filter').show();
                break;
            case 'show-detail':
                await window.UIkit.modal('#show-detail').show();
                break;
            case 'confirmation-edit-box':
                await window.UIkit.modal('#confirmation-edit-box').show();
                break;
            case 'confirmation-edit-box-2':
                await window.UIkit.modal('#confirmation-edit-box-2').show();
                break;
            case 'confirmation-heads-up':
                await window.UIkit.modal('#confirmation-heads-up').show();
                break;
            case 'success-edit':
                await window.UIkit.modal('#success-edit').show();
                break;
            case 'edit-name-schedule':
                await window.UIkit.modal('#edit-name-schedule').show();
                break;
            case 'availability-warning':
                await window.UIkit.modal('#availability-warning').show();
                break;
            }
        },
        fillPublishModal(schedule, date) {
            this.selectedSchedulePublish = schedule;
            this.selectedSchedulePublish.date = date;
            this.showModal('notif-mitra');
        },
        async publishSchedule() {
            // TODO: Integrate to API publish schedule
            const res = await this.postNotifSchedule({id:this.selectedSchedulePublish._id, date:this.selectedSchedulePublish.date});
            if (res.toLowerCase() == 'success') {
                // Refetch
                this.fetchSchedules();

                this.showModal('notif-success');
            }

        },
        async setDate() {
            if (this.meta.start_date != null && this.meta.end_date != null) {
                const start_date = new Date(this.meta.start_date);
                const end_date = new Date(this.meta.end_date);
                if (start_date > end_date) {
                    return notificationDangerCustom('End date must be greater than start date');
                }
                this.loadingSchedule = true;
                this.fetchSchedules();
                this.loadingSchedule = false;
            } else if (this.meta.start_date == null) {
                return notificationDangerCustom('Start date must be filled');
            }
            //this.getRecaps(this.meta);
            this.hideModal('date-filter');

        },
        setMonth(value) {
            if (!this.$refs.calendar) return;

            this.selectedMonth = value.month;
            const newVal = `${value.year}-${("0" + value.monthIndex).slice(-2)}-${moment(value.from).endOf('month').format('DD')}`;
            this.selectedMonthIndex = newVal;

            this.$nextTick(() => {
                this.$refs.calendar.value = newVal;
            });

            this.hideModal('month-filter');
        },
        dateFilterAsText() {
            let date_format = '';
            if (this.meta.start_date) {
                date_format = date_format + this.formatDate(this.meta.start_date);
            }
            if (this.meta.end_date) {
                if (date_format !== '') {
                    date_format = date_format + '-';
                }
                date_format = date_format + this.formatDate(this.meta.end_date);
            }
            return date_format;
        },
        flterDateTextMonthly() {
            const date = new Date(), m = date.getMonth();
            const monthName = this.monthName();
            return monthName[parseInt(m)];
        },
        getEventColor (event) {
            return event.color;
        },
        async resetDate() {
            this.meta.start_date = '';
            this.meta.end_date = '';
            this.fetchSchedules();

            if (this.meta.schedulePick == 'monthly') {
                this.selectedMonth = '',
                this.selectedMonthIndex = moment().endOf('month').format('YYYY-MM-DD'),
                this.$nextTick(() => {
                    this.$refs.calendar.value = this.selectedMonthIndex;
                });
            }

            //this.getRecaps(this.meta);
        },
        flterDateText() {
            let date_filter_text = this.dateFilterAsText();
            if (date_filter_text != '') {
                return date_filter_text;
            }
            return 'Choose date range';
        },
        filterMonthText() {
            return this.selectedMonth || 'Choose month';
        },
        setCity() {
            this.cities = [...new Set(this.company_offices.map(data => data.city))];
            this.filtered_offices = [];
        },
        filterOffice() {
            this.filtered_offices = this.company_offices.filter(data => data.city === this.selected_city);
        },
        setLocation() {
            if (this.company_office == '') {
                return notificationDangerCustom('Please select one of property');
            }
            this.meta.property_city = this.company_office.city;
            this.meta.property_name = this.company_office.name;
            this.companyOfficeId = this.company_office._id;
            this.fetchSchedules();
            //this.getRecaps(this.meta);
            this.hideModal('location-filter');
            this.$store.state.selected_company = this.company_office;
        },
        resetLocation() {
            this.meta.property_city = null;
            this.meta.property_name = null;
            this.company_office= '';
            this.selected_city= '';
            this.cities= [];
            this.filtered_offices= [];
            this.companyOfficeId = this.company_office._id;
            this.fetchSchedules();
            //this.getRecaps(this.meta);
            this.$store.state.selected_company = '';
        },
        handleEdit() {
            this.modalMode = 'edit';
            this.hideModal('shift-detail');
            this.showModal('edit-schedule');
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        generateWeeklyFileName() {
            return `MyRobin-Shipper Weekly Report ${this.monthName(this.selected_month)}.xls`;
        },
        monthName() {
            return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Oktober', 'November', 'December'];
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
.table-schedule td{
    padding: 8px 0px;
    position: relative;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.td-line{
    height:1px;
    border-top: 1px solid #CCC;
    width:100%;
    margin-top: 11px;
}
.schedule-add{
  font-family: 'Roboto';
  color: #5FD855;
  font-size: 18px;
}
.shift-card{
    position:absolute;
    padding:16px;
    width: 120px;
    background: #FFFFFF;
    box-shadow: -6px 0px 16px rgba(255, 255, 255, 0.5), 0px 2px 8px 4px rgba(33, 33, 33, 0.15);
    border-radius: 12px;
    z-index: 999;
    margin-left: auto;
    left: 0;
    right: 0;
    text-align: center;
    border: 1px solid #f1f1f1;
}
.shift-card span{
    display: block;
}
.btn-sml-active{
    background: #0ABAB5;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 101px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    color: #ffffff !important;
}
.btn-sml{
    background: #F8F8F8;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    width: 101px;
    color: #000000 !important;
}
.btn-choose{
    background: #FFFFFF;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    width: 219px;
    color: #000000 !important;
}
.btn-choose-disabled {
    background: #e7e7e7;
    border: 1px solid #ababab;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    width: 219px;
    color: gray !important;
}

#date-filter .uk-modal-dialog{
    margin: 60px auto;
}
.v-application .orange {
    background-color: #ff9800!important;
    border-color: #ff9800!important;
}
</style>
