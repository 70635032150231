<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
      <div uk-spinner="ratio: 2"></div>
    </div>
    <div v-else>
      <section class="uk-margin">
        <button
          v-for="(bMenu, bKey) in buttonMenus"
          :key="bKey"
          class="uk-button uk-button-large uk-margin-right uk-margin-small-bottom uk-border-rounded"
          :class="meta.tabActive === bMenu.value ? 'uk-button-primary' : 'uk-button-default uk-text-muted'"
          @click="(meta.tabActive = bMenu.value)"
        >
          {{ bMenu.text }}
        </button>
      </section>

      <section class="uk-margin">
        <select class="uk-select uk-margin-right" v-model="meta.domicile_city_id" @change="filterOffice" style="width: 15rem;">
          <option :value="null" selected>All Cities</option>
          <option v-for="(city, index) in cities" :key="index" :value="city._id">{{ city.name || '-' }}</option>
        </select>
      </section>

      <section class="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
        <div class="uk-flex" style="gap: 10px">
          <!-- filter by warehouse -->
          <select class="uk-select" v-model="meta.company_office_id" :disabled="meta.domicile_city_id === ''" style="width: 15rem;" @change="filterWorkerList">
            <option :value="null" selected>All Office</option>
            <option v-for="(office, index) in filtered_offices" :key="index" :value="office._id">{{ office.name || '-' }}</option>
          </select>
          <!-- filter by name -->
          <input type="text" class="uk-input" placeholder="Candidate Name" v-model="meta.fullname" style="width: 15rem;" @keypress.enter="filterWorkerList" />
          <!-- sort data -->
          <select name="office" id="office" class="uk-select" v-model="meta.sort_by" style="width: 15rem;" @change="filterWorkerList">
            <option :value="null" selected>Sort by ...</option>
            <option v-for="(sort, id) in sortOptions" :key="id" :value="sort.id">{{ sort.label }}</option>
          </select>
        </div>
      </section>

      <section class="uk-flex uk-flex-between uk-margin-top">
          <!-- open excel form download modal -->
          <div class="uk-flex" style="gap: 10px">
              <button
                  class="uk-button uk-button-default uk-text-nowrap"
                  style="
                      border-radius: 5px;
                      border: 1px solid #0abab5;
                      color: white;
                      background-color: #0abab5;
                  "
                  @click="modal.isShowDownloadExcel = true"
              >
                  <img :src="`${images}/shift-download-white.svg`" alt="" width="25">
                  <span>Download Mitra template</span>
              </button>
              <!-- open excel form upload modal -->
              <button
                  class="uk-button uk-button-default uk-text-nowrap"
                  style="
                      border-radius: 5px;
                      border: 1px solid #0abab5;
                      color: #0abab5;
                  "
                  @click="modal.isShowUploadExcel = true"
              >
                  <img :src="`${images}/shift-upload-primary.svg`" alt="" width="25">
                  <span>Upload details Mitra</span>
              </button>
              <!-- create user manual input -->
              <button
                v-if="meta.tabActive === 'manual'"
                class="uk-button uk-button-primary uk-text-bold uk-border-rounded"
                style="background-color: #1e87f0;"
                @click="(modal.isShowCreateUser = true)"
              >
                <img :data-src="`${images}/add-user-outline.svg`" alt="icon add multi" uk-img class="photo">
                Create User
              </button>
          </div>
      </section>

      <section class="uk-border-rounded uk-card uk-card-default uk-margin">
        <div class="uk-overflow-auto">
          <table class="uk-table uk-table-medium uk-table-divider">
            <thead>
              <tr>
                <th class="uk-table-expand">Name</th>
                <th class="uk-table-expand">Employee ID</th>
                <th class="uk-table-expand">Office</th>
                <th class="uk-table-expand">Role</th>
                <th class="uk-table-expand">Start Date</th>
                <th class="uk-table-expand">End Date</th>
                <th class="uk-table-shrink">Status</th>
                <th class="uk-table-expand">Source</th>
                <th class="uk-table-expand">Created at</th>
                <th class="uk-text-nowrap uk-text-center">Action</th>
              </tr>
            </thead>
            <tbody v-if="mitras.length > 0">
              <tr v-for="(mitra, index) in mitras" :key="index">
                <td class="uk-flex uk-flex-middle">
                  <img
                    class="uk-margin-small-right uk-border-circle"
                    :src="mitra.photo_url && mitra.photo_url.file_url ? mitra.photo_url.file_url : '/images/myrobin-img.webp'"
                    alt="foto"
                    style="width: 35px; height: 35px"
                  />
                  <span class="one-line-cell">{{ mitra.fullname }}</span>
                </td>
                <td>{{ mitra.employee_id }}</td>
                <td>{{ mitra.office_name }}</td>
                <td>{{ mitra.role }}</td>
                <td>{{ formatDate({ date: mitra.start_date }) }}</td>
                <td>{{ formatDate({ date: mitra.end_date }) }}</td>
                <td>{{ mitra.status === 1 ? 'Active' : 'Hold' }}</td>
                <td>{{ mitra.source }}</td>
                <td>{{ formatDate({ date: mitra.created_at }) }}</td>
                <td class="uk-text-nowrap uk-text-center">
                  <button
                    class="uk-margin-small-right uk-button-small uk-button-primary uk-border-rounded"
                    @click="$router.push(`/admin/workers/mitra/${mitra.user_id}`)"
                  >
                    Details
                  </button>
                  <button
                    v-if="$can('READ', 'onboarding')"
                    class="uk-margin-small-right uk-button-small button-dark-outline uk-border-rounded"
                    @click="downloadContract(mitra._id)"
                  >
                    Download Contract
                  </button>
                  <button
                    class="uk-margin-small-right uk-button-small uk-border-rounded button-primary-outline"
                    @click="resendEmail(mitra._id)"
                  >
                    Resend Email
                  </button>
                  <button v-if="$can('DELETE', 'onboarding')" class="uk-margin-small-right uk-button-small uk-button-danger uk-border-rounded">Delete</button>
                  <div v-if="$can('DELETE', 'onboarding')" class="uk-padding-small uk-text-left" uk-dropdown>
                    <ul class="uk-nav uk-dropdown-nav">
                      <li>
                        <button
                          class="uk-button uk-button-small uk-padding-remove uk-width-1-1 uk-text-left"
                          type="button"
                          @click="(idToDelete = mitra.user_id, modal.isShowConfirm = true)"
                        >
                          <img :data-src="`${images}/delete-person.svg`" alt="icon delete person" uk-img class="uk-margin-small-right">
                          Delete User
                        </button>
                      </li>
                      <li class="uk-nav-divider" />
                      <li>
                        <button
                          class="uk-button uk-button-small uk-padding-remove uk-width-1-1 uk-text-left"
                          type="button"
                          @click="deleteWorker(mitra._id)"
                        >
                          <img :data-src="`${images}/delete-partner.svg`" alt="icon delete partner" uk-img class="uk-margin-small-right">
                          Delete Kemitraan
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
            <empty-table v-else :colspan="10" />
          </table>
        </div>
      </section>
      <pagination
        v-if="mitras.length != 0"
        :total-data="mitraDetails.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
        :limit-list="limitList"
        :limit-page="meta.limit"
      />
    </div>

    <DownloadContract
      v-if="modal.isShowDownloadContract"
      :selected_worker="selected_worker"
      @close="modal.isShowDownloadContract = false"
    />

    <CreateUserModal
      v-if="modal.isShowCreateUser"
      :company_offices="company_offices"
      @success="filterWorkerList"
      @close="modal.isShowCreateUser = false"
    />

    <DeleteMitra
      v-if="modal.isShowDeleteMitra"
      :selected_worker="selected_worker"
      :idToDelete="idToDelete"
      @close="modal.isShowDeleteMitra = false"
    />

    <ConfirmModal
      v-if="modal.isShowConfirm"
      title="Confirmation"
      body="Are you sure want to proceed?"
      @hideModal="(modal.isShowConfirm = false)"
      @confirmProcess="deletePartner"
    />

    <MitraDataDownload
      v-if="modal.isShowDownloadExcel"
      :selection="templateSelection"
      :meta="meta"
      @close="modal.isShowDownloadExcel = false"
    />
    <MitraDataUpload
      v-if="modal.isShowUploadExcel"
      :selection="templateSelection"
      :source="meta.tabActive"
      @close="modal.isShowUploadExcel = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import DownloadContract from '@/components/globals/Mitra/DownloadContract';
import CreateUserModal from '@/components/globals/Mitra/CreateUserModal';
import DeleteMitra from '@/components/globals/Mitra/DeleteMitra';
import MitraDataDownload from '@/components/globals/modals/MitraDataDownload';
import MitraDataUpload from '@/components/globals/modals/MitraDataUpload';
import { notificationDanger, notificationSuccess, notificationDangerCustom } from "@/utils/notification";
import ConfirmModal from '@/components/globals/modals/SimpleConfirm';

export default {
    data(){
        return {
            idToDelete: '',
            selected_worker: {
                fullname: null,
                photo_url: null,
                birthdate: null,
                domicile: null,
                cv: null,
                all_user_scores: null,
                company_office_id: null,
                role_id: null,
                user_id: null,
                job_partner_id: null,
                sign_contracts: []
            },
            limitList: [5, 10 ,15, 20],
            modal: {
                isShowDownloadContract: false,
                isShowCreateUser: false,
                isShowDeleteMitra: false,
                isShowConfirm: false,
                isShowDownloadExcel: false,
                isShowUploadExcel: false,
            },
            isLoading: false,
            images: PREFIX_IMAGE,
            buttonMenus: [
                { text: 'Normal Flow', value: 'normal' },
                { text: 'Auto Assign Register', value: 'auto-assign-register' },
                { text: 'Auto Assign Unregister', value: 'auto-assign-unregister' },
                { text: 'Migration', value: 'migration' },
                { text: 'Full Migration', value: 'full-migration' },
                { text: 'Manual Input Mitra', value: 'manual' },
            ],
            mitras: [],
            mitraDetails: {},
            meta: { domicile_city_id: null, company_office_id: null, fullname: null, sort_by: null, limit: 10, page: 1, tabActive: 'normal' },
            sortOptions: [
                { id: 'latest', label: 'Created at latest' },
                { id: 'oldest', label: 'Created at oldest' },
            ],
            filtered_offices: [],
            cities: [],
            templateSelection: [
                {
                    name: 'Profile',
                    type: 'profile',
                },
                {
                    name: 'Identity Document',
                    type: 'document'
                },
                {
                    name: 'Family Details',
                    type: 'family'
                },
                {
                    name: 'Equipment',
                    type: 'equipment'
                },
                {
                    name: 'Emergency Contact',
                    type: 'emergency'
                },
                {
                    name: 'Job Details',
                    type: 'job'
                },
            ],
        };
    },
    components: {
        Pagination,
        EmptyTable,
        DownloadContract,
        CreateUserModal,
        DeleteMitra,
        ConfirmModal,
        MitraDataDownload,
        MitraDataUpload
    },
    computed: {
        ...mapGetters({
            company_offices: "company_office/company_offices",
        }),
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.defaultMeta();
            await this.getCompanyOffice();
            await this.filterWorkerList();
            this.setCity();
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    },
    watch: {
        meta: {
            handler() {
                this.$router.push({ name: this.$route.name, query: this.meta });
            },
            deep: true,
        },
        "meta.tabActive"() {
            this.filterWorkerList();
        },
        "modal.isShowDeleteMitra"(val) {
            if (!val) {
                this.filterWorkerList();
            }
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: "company_office/getCompanyOffice",
            getMitraOnBoarding: "mitra/getMitraOnBoarding",
            getMitraDetail: "worker/getMitraDetail",
            postResendEmail: "worker/postResendEmail",
            postDeletePartner: "worker/postDeletePartner",
        }),

        defaultMeta() {
            this.meta = {
                domicile_city_id: this.$route.query.domicile_city_id ?? null ,
                company_office_id: this.$route.query.company_office_id ?? null,
                fullname: this.$route.query.fullname ?? null,
                sort_by: this.$route.query.sort_by ?? null,
                limit: this.$route.query.limit ? parseInt(this.$route.query.limit) : 10,
                page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
                tabActive: this.$route.query.tabActive ?? 'normal'
            };
        },

        formatDate({ date }) {
            if (date === null || date === undefined) return '-';
            else return formatter.dateCompleteNew(date);
        },

        changeLimit(e) {
            this.meta.limit = e.target.value;
            this.filterWorkerList();
        },

        changePage(value) {
            this.meta.page = value;
            this.filterWorkerList();
        },

        async filterOffice() {
            try {
                this.meta.company_office_id = null;

                if (this.company_offices && this.company_offices.length > 0) {
                    const offices = this.company_offices.filter((office) => {
                        if (
                            (office.domicile_city_id && office.domicile_city_id._id) &&
                            (this.meta.domicile_city_id) &&
                            (office.domicile_city_id._id === this.meta.domicile_city_id)
                        ) {
                            return office;
                        }
                    });
                    this.filtered_offices = offices;
                }

                if (this.filtered_offices.length > 0) {
                    this.filtered_offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }

                this.filterWorkerList();
            } catch (error) {
                notificationDanger(error);
            }
        },

        async setCity(){
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }

            this.filtered_offices = [];
        },

        async filterWorkerList() {
            const response = await this.getMitraOnBoarding({ source: this.meta.tabActive, payload: this.meta });
            if (response && response.status === 'OK') {
                this.mitraDetails = response.result;
                this.mitras = this.mitraDetails.docs;
            } else {
                notificationDanger('Failed get mitra onboarding.');
            }
        },

        async downloadContract(worker_id) {
            try {
                const response = await this.getMitraDetail(worker_id);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                    this.modal.isShowDownloadContract = true;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },

        async deleteWorker(worker_id) {
            try {
                const response = await this.getMitraDetail(worker_id);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                    this.idToDelete = worker_id;
                    this.modal.isShowDeleteMitra = true;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },

        async deletePartner() {
            try {
                const response = await this.postDeletePartner(this.idToDelete);

                if (response && response.status === "OK") {
                    notificationSuccess("Partner deleted!");
                    this.filterWorkerList();
                } else {
                    notificationDangerCustom("Failed to delete partner.");
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modal.isShowConfirm = false;
                this.filterWorkerList();
            }
        },

        async resendEmail(worker_id) {
            try {
                const response = await this.postResendEmail({ job_partner_ids: [worker_id] });
                if (response && response.status === 'OK') {
                    notificationSuccess("email success resend!");
                }
            } catch (error) {
                notificationDanger(error);
            }
        },

        fillSelectedWorker({ workerDetail }) {
            this.selected_worker.fullname = workerDetail.fullname;
            this.selected_worker.photo_url = workerDetail.photo_url;
            this.selected_worker.birthdate = workerDetail.birthdate;
            this.selected_worker.domicile = workerDetail.domicile;
            this.selected_worker.cv = workerDetail.cv;
            this.selected_worker.all_user_scores = workerDetail.all_user_scores;
            this.selected_worker.company_office_id = workerDetail.company_office_id;
            this.selected_worker.role_id = workerDetail.role_id;
            this.selected_worker.user_id = workerDetail.user_id;
            this.selected_worker.job_partner_id = workerDetail._id;
            this.selected_worker.sign_contracts = workerDetail.sign_contracts;
        },
    },
};
</script>

<style scoped>
.one-line-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
thead {
  background-color: #0ABAB5;
}

th {
  color: #ffffff;
}

td {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.button-primary-outline {
  border: 1px solid #0275D8;
  color: #0275D8;
  background: white;
}

.button-dark-outline {
  border: 1px solid #000000;
  color: #000000;
  background: white;
}

.uk-button-primary {
  background-color: #0ABAB5
}
</style>
