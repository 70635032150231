<template>
    <div class="main uk-position-relative uk-margin-top card-scrollable">
        <!-- Filter -->
        <div class="uk-container uk-container-expand">
            <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom" uk-grid>
                <!-- Select province -->
                <div class="uk-width-1-3">
                    <p class="uk-margin-small-bottom">Please Select a Province</p>
                    <Multiselect
                        v-model="filter.province"
                        placeholder="Choose a province..."
                        id="province"
                        name="province"
                        label="name"
                        :options="provinces"
                        :loading='isProvinceLoading'
                        track-by="_id"
                        :searchable="true"
                        :internal-search="true"
                        :allow-empty="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :select-label="null"
                        :deselect-label="null"
                        @select="onSelectProvince">
                        <span slot="noResult">Data not found</span>
                    </Multiselect>
                </div>
                <button
                    v-if="!filter.province || filter.province._id != 0"
                    class="uk-button uk-button-primary uk-text-nowrap uk-border-rounded text-semibold"
                    :class="[!filter.province || jobVacancyCount == 0 ? 'disabled-button' : ' myrobin-background-primary']"
                    :disabled="!filter.province || jobVacancyCount == 0"
                    @click="onClickContinue"
                >
                    <span>Continue view applicants detail</span>
                </button>
            </div>
        </div>
        <!-- Content -->
        <div>
            <div v-if="isLoading" class="uk-position-absolute uk-position-center uk-flex uk-flex-column uk-flex-center uk-text-center uk-text-italic text-A7A7A7">
                <div uk-spinner="ratio: 3" class="uk-margin-auto uk-margin-bottom"></div>
                <p>
                    Please be patient. <br />
                    We currently loading the data...
                </p>
            </div>
            <!-- No Data -->
            <div v-else-if="!isLoading && jobVacancyCount == 0" class="uk-flex uk-flex-column uk-flex-center uk-height-large uk-text-center uk-text-italic text-A7A7A7">
                <div>
                    <img :src="`${images}/job_applicant/suitcase.svg`">
                </div>
                <p class="uk-margin-remove-bottom">No results found!</p>
                <p class="uk-margin-remove-top">
                    Sorry, there is no vacancy nor applicant in <span class="uk-text-bold">{{ filter.province.name }}</span> recorded for this company.
                </p>
                <p>Please choose another province now based on this company’s worker request!</p>
            </div>
            <!-- Data Exist -->
            <div v-else-if="!isLoading">
                <!-- All Time Cards -->
                <div class="uk-container uk-container-expand all-time-container">
                    <hr>
                    <p v-if="!filter.province" class="uk-text-small">Please choose a province to show data below</p>
                    <p v-else class="uk-text-small">
                        All Time Job Applicant Data in <span class="text-semibold">{{ filter.province.name }}</span>
                        vacancies
                    </p>
                    <div class="all-time-card-container uk-margin-bottom">
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.allApplicants }}</p>
                                <span class="uk-text-small">All Applicants</span>
                            </div>
                        </div>
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.newApplicants }}</p>
                                <span class="uk-text-small">New Applicants</span>
                            </div>
                        </div>
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.pickedUpApplicants }}</p>
                                <span class="uk-text-small">Picked up Applicant</span>
                            </div>
                        </div>
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.invitedToInterview }}</p>
                                <span class="uk-text-small">Invited to Interview</span>
                            </div>
                        </div>
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.interviewedApplicants }}</p>
                                <span class="uk-text-small">Interviewed Applicant</span>
                            </div>
                        </div>
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.contractSent }}</p>
                                <span class="uk-text-small">Contract Sent</span>
                            </div>
                        </div>
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.signedApplicants }}</p>
                                <span class="uk-text-small">Signed Applicant</span>
                            </div>
                        </div>
                        <div>
                            <div class="all-time-card uk-text-center">
                                <p class="uk-text-bold">{{ allTimeData.rejectedApplicants }}</p>
                                <span class="uk-text-small">Rejected Applicant</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Total Cards -->
                <div class="uk-container uk-container-expand">
                    <hr>
                    <p v-if="!filter.province" class="uk-text-small">Please choose a province to show data below</p>
                    <p v-else class="uk-text-small">
                        All Time Job Applicant Data in <span class="text-semibold">{{ filter.province.name }}</span>
                        vacancies
                    </p>
                    <div class="total-card-container uk-margin-bottom">
                        <div>
                            <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                                uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                                <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                    <p class="uk-margin-remove uk-text-bold">Total Job Applications</p>
                                    <p class="uk-margin-remove">for the last 30 days</p>
                                </div>
                                <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                    style="background-color: #FFE500;">
                                    {{ last30DaysData.jobApplications }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                                uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                                <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                    <p class="uk-margin-remove uk-text-bold">Total Unique Applicants</p>
                                    <p class="uk-margin-remove">for the last 30 days</p>
                                </div>
                                <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                    style="background-color: #FFE500;">
                                    {{ last30DaysData.uniqueApplicants }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                                uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                                <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                    <p class="uk-margin-remove uk-text-bold">Total In-Progress Applicants</p>
                                    <p class="uk-margin-remove">for the last 30 days</p>
                                </div>
                                <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding myrobin-color-white"
                                    style="background-color: #0275D8;">
                                    {{ last30DaysData.inProgressApplicants }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                                uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                                <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                    <p class="uk-margin-remove uk-text-bold">Total Accepted Applicants</p>
                                    <p class="uk-margin-remove">for the last 30 days</p>
                                </div>
                                <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding myrobin-color-white"
                                    style="background-color: #22CC8E;">
                                    {{ last30DaysData.acceptedApplicants }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                                uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                                <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                    <p class="uk-margin-remove uk-text-bold">Total Rejected Applicants</p>
                                    <p class="uk-margin-remove">for the last 30 days</p>
                                </div>
                                <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding myrobin-color-white"
                                    style="background-color: #DF5C70;">
                                    {{ last30DaysData.rejectedApplicants }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            isProvinceLoading: false,
            provinces: [],
            filter: {
                province: null,
            },
            jobVacancyCount: null,
            allTimeData: {
                allApplicants: 0,
                newApplicants: 0,
                pickedUpApplicants: 0,
                invitedToInterview: 0,
                interviewedApplicants: 0,
                contractSent: 0,
                signedApplicants: 0,
                rejectedApplicants: 0
            },
            last30DaysData: {
                jobApplications: 0,
                uniqueApplicants: 0,
                inProgressApplicants: 0,
                acceptedApplicants: 0,
                rejectedApplicants: 0
            },
        };
    },
    components: {
        Multiselect
    },
    mounted() {
        this.getProvinceOptions();
        if (localStorage.getItem(`$job_applicant province`)) {
            this.filter.province = JSON.parse(localStorage.getItem(`$job_applicant province`));
            this.getData();
        }
    },
    methods: {
        ...mapActions({
            getDashboardData: 'job_applicant/getDashboardData',
            getProvinces: 'option_data/getProvinces'
        }),
        async getProvinceOptions() {
            this.isProvinceFetching = true;
            this.provinces = await this.getProvinces();
            this.provinces.unshift({ _id: 0, name: 'ALL IN INDONESIA' });
            this.isProvinceFetching = false;
        },
        onClickContinue() {
            // this.$router.push(`job_applicant/detail/${this.filter.province._id}`);
            window.open(`job_applicant/detail/${this.filter.province._id}`, '_blank');
        },
        onSelectProvince() {
            this.getData();
            localStorage.setItem(`$job_applicant province`, JSON.stringify(this.filter.province));
        },
        async getData() {
            this.isLoading = true;
            const response = await this.getDashboardData({ provinceId: this.filter.province._id });
            if (response && response.status === 'OK') {
                this.jobVacancyCount = response.result.jobVacancyCount;
                if (this.jobVacancyCount > 0) {
                    this.allTimeData = response.result.allTimeData;
                    this.last30DaysData = response.result.last30DaysData;
                }
            }
            this.isLoading = false;
        },
    }
};
</script>

<style scoped>
div .main {
    color: #000;
}

.text-semibold {
    font-weight: 600;
}

.text-A7A7A7 {
    color: #A7A7A7;
}

.disabled-button {
    background-color: #CDCDCD;
}

.all-time-container {
    background-color: #EEE;
}

.all-time-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    justify-content: space-between;
}

.all-time-card {
    padding: 12px;
    background-color: white;
    font-size: 26px;
    aspect-ratio: 1/1;
    display: grid;
    grid-template-rows: 1fr 1fr;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.all-time-card p {
    margin: 0;
    align-self: center;
}

.all-time-card div {
    align-self: self-start;
}

.total-card-container {
    --grid-layout-gap: 20px;
    --grid-column-count: 2;
    --grid-item-min-width: 415px;
    --grid-item-max-width: calc((100% - var(--grid-layout-gap)) / var(--grid-column-count));
    display: grid;
    gap: var(--grid-layout-gap);
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
}

.total-card {
    border-radius: 20px;
    color: #000;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.total-card .title {
    font-size: 18px;
}

.total-card .number {
    font-size: 50px;
    font-weight: 500;
}
</style>
