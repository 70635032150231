import api from '@/utils/api';
import { CANDIDATE } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getCandidateList(context, data) {
        try {
            const response = await api.apiGetAuth(CANDIDATE.LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCV(context, data) {
        try {
            const response = await api.apiGetAuth(CANDIDATE.GET_CV(data.filter, data.data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllCandidate(context, data) {
        try {
            const response = await api.apiGetAuth(CANDIDATE.ALL, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async inviteToJob(context, data) {
        try {
            const response = await api.apiPostAuth(CANDIDATE.INVITE_TO_JOB(data.user_id, data.type, data.job_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadCandidateCv(context, data) {
        try {
            const response = await api.apiGetAuth(CANDIDATE.DOWNLOAD_CV, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async inviteMutipleCandidate(context, data) {
        try {
            const response = await api.apiPostAuth(CANDIDATE.INVITE_MULTI_CANDIDATE(data.type, data.job_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
