import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VeeValidate from 'vee-validate';
import { store } from './store';
import Vuex from 'vuex';
import JsonExcel from 'vue-json-excel';
import Datetime from 'vue-datetime';
import VueClipboard from 'vue-clipboard2';
import Vuetify from 'vuetify';
import { MonthPicker, MonthPickerInput } from 'vue-month-picker';
import * as VueGoogleMaps from 'vue2-google-maps';

import 'vuetify/dist/vuetify.min.css';

import { abilitiesPlugin } from '@casl/vue';
import { Ability } from "@casl/ability";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
import VModal from 'vue-js-modal/dist/index.nocss.js';
import 'vue-js-modal/dist/styles.css';

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(Vuex);
Vue.use(VeeValidate);
Vue.use(Vuetify);
Vue.use(VueClipboard);
Vue.use(require('vue-moment'));
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBDzZW5nmmabcfg8VulJJq60BScRbQMaGI',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
});

//casl plugin https://casl.js.org/v4/en/package/casl-vue
Vue.use(abilitiesPlugin, new Ability([]));

Vue.component('downloadExcel', JsonExcel);
Vue.component('datetime', Datetime);
Vue.component('month-picker', MonthPicker);
Vue.component('month-picker-input', MonthPickerInput);
// sentry plugin https://sentry.io
// Sentry.init({
//     Vue,
//     dsn: process.env.VUE_APP_SENTRY_DSN,
//     environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
//     integrations: [
//         new BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             tracingOrigins: [process.env.VUE_APP_API_URL],
//         }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     enabled: process.env.VUE_APP_SENTRY_ENVIRONMENT !== 'development'
// });

new Vue({
    el: '#app',
    vuetify : new Vuetify(),
    router,
    store,
    components: { App },
    template: '<App/>',
});
