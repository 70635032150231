<template>
    <section>
        <div id="filter-vacancy" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="hideModal(false)"
                ></button>
                <div class="uk-modal-header">
                    <h4>Filter Vacancy</h4>
                </div>
                <div class="uk-modal-body">
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="
                                        checkFilterVacancy.is_domicile
                                    "
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                City/Domicile</label
                            >
                            <multiselect
                                v-model="vacancyFilter.vacancy_domicilies"
                                :multiple="true"
                                placeholder="Type to search city"
                                label="name"
                                name="city"
                                track-by="name"
                                :searchable="true"
                                :options="filterOptions.city_options"
                                :loading="isFetching"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :show-no-results="false"
                                @search-change="getCityOptions"
                                :disabled="!checkFilterVacancy.is_domicile"
                            >
                            </multiselect>
                        </div>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="
                                        checkFilterVacancy.is_company_office
                                    "
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Office</label
                            >
                            <multiselect
                                v-model="vacancyFilter.vacancy_offices"
                                :multiple="true"
                                placeholder="Type to search office"
                                label="name"
                                name="vacancy_offices"
                                track-by="name"
                                :searchable="true"
                                :options="
                                    filterOptions.company_office_options
                                "
                                :loading="isFetching"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                @search-change="getCompanyOfficeOptions"
                                :disabled="
                                    !checkFilterVacancy.is_company_office
                                "
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="
                                        checkFilterVacancy.is_role
                                    "
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Role</label
                            >
                            <multiselect
                                v-model="vacancyFilter.vacancy_roles"
                                :multiple="true"
                                placeholder="Type to search role"
                                label="name"
                                name="vacancy_roles"
                                track-by="name"
                                :searchable="true"
                                :options="
                                    filterOptions.roles_options
                                "
                                :loading="isFetching"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                @search-change="getRoleOptions"
                                :disabled="
                                    !checkFilterVacancy.is_role
                                "
                            >
                            </multiselect>
                        </div>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="
                                        checkFilterVacancy.is_status
                                    "
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Vacancy Status</label
                            >
                            <multiselect
                                v-model="vacancyFilter.vacancy_status"
                                :multiple="false"
                                placeholder="Select vacancy status"
                                label="name"
                                name="vacancy_status"
                                track-by="value"
                                :searchable="true"
                                :options="vacancyStatusOptions"
                                :loading="isFetching"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="
                                    !checkFilterVacancy.is_status
                                "
                            >
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div
                    class="uk-modal-footer uk-flex uk-flex-middle uk-flex-between"
                >
                    <button
                        class="uk-button uk-button-default clear-button"
                        type="button"
                        @click="clearFilter"
                    >
                        Clear Filter
                    </button>
                    <button
                        class="uk-button uk-button-primary apply-button"
                        type="button"
                        :disabled="!buttonApplyFilter"
                        @click="applyFilter"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'FilterVacancyModal',
    components: {
        Multiselect
    },
    props: {
        vacancyFilter: {
            type: Object,
            required: true
        },
        checkFilterVacancy: {
            type: Object,
            required: true
        },
        buttonApplyFilter: {
            type: Boolean,
            required: true,
        },
        isFetching: {
            type: Boolean,
            required: true
        },
        filterOptions: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            vacancyStatusOptions: [
                {
                    name: 'In Progress',
                    value: '0'
                },
                {
                    name: 'Submitted',
                    value: 1
                },
                {
                    name: 'Completed',
                    value: 2
                }
            ]
        };
    },
    mounted() {
        window.UIkit.modal('#filter-vacancy').show();
    },
    methods: {
        clearFilter() {
            this.$emit('clear-filter');
            this.hideModal();
        },
        applyFilter() {
            this.$emit('apply-filter');
            this.hideModal();
        },
        hideModal() {
            window.UIkit.modal('#filter-vacancy').$destroy(true);
            this.$emit('hideModal');
        },
        getCityOptions(query) {
            this.$emit('get-city-options', query);
        },
        getRoleOptions(query){
            this.$emit('get-role-options', query);
        },
        getCompanyOfficeOptions(query){
            this.$emit('get-company-office-options', query);
        }
    }
};
</script>

<style scoped>
.clear-button {
    border: 1px solid #ef5350;
    color: #ef5350;
}
.clear-button:disabled {
    color: #979797 !important;
    border: 1px solid #e5e5e5;
}
.apply-button:disabled {
    color: #979797 !important;
}
</style>
