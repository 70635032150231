<template>
    <div
        uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar"
    >
        <nav
            class="uk-navbar-container header-background"
            style="position: relative; z-index: 980"
            uk-navbar="mode: click"
        >
            <div class="uk-navbar-left">
                <ul class="uk-navbar-nav">
                    <li>
                        <a
                            class="uk-navbar-toggle"
                            href="#toggle-animation"
                            uk-toggle="target: #toggle-animation; animation: uk-animation-slide-left"
                        >
                            <span uk-navbar-toggle-icon></span>
                        </a>
                    </li>
                    <li v-if="profile != null">
                        <a disabled>
                        <img
                            v-if="profile.company.logo_url != null"
                            style="max-width: 100px; max-height: 59px"
                            :src="profile.company.logo_url.file_url"
                        />
                        <picture v-else class="def-img-wrapper">
                            <img class="def-company-img" :src="`${images}/building.png`"/>
                        </picture>
                        <span class="uk-margin-small-left">{{ profile.company.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="uk-navbar-center">
                <ul class="uk-navbar-nav">
                    <li><b>{{ headerTitle }}</b></li>
                </ul>
            </div>

            <div class="uk-navbar-right">
                <ul class="uk-navbar-nav">
                    <li>
                        <a href="#notif-modal" uk-toggle @click="getNotif">
                            <span class="uk-position-relative" uk-icon="icon: bell; ratio: 1.5" >
                                <span
                                    v-if="counter && counter > 0"
                                    class="uk-badge uk-position-absolute uk-position-top-right uk-position-z-index uk-label-default"
                                    style="min-width: 17px;height: 17px"
                                >{{counter}}</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a class="uk-navbar-toggle" href="#">
                            <span class="avatar uk-text-bold" style="width: 43px">{{ stringAvatar }}</span>
                            <span class="uk-margin-small-left">{{ fullName }}</span>
                        </a>
                        <div class="uk-navbar-dropdown" uk-toggle>
                            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav style="color: white">
                                <template v-if="profile != null && profile.company_options && profile.company_options.length > 0">
                                    <li>
                                        <router-link to="" @click.native="showCompanyModal">
                                            <img class="uk-margin-small-right" :src="`${images}/building.svg`" alt="" />
                                            Switch Company
                                        </router-link>
                                    </li>
                                    <hr />
                                </template>
                                <li>
                                    <router-link to="" @click.native="showModal">
                                        <img class="uk-margin-small-right" :src="`${images}/pencil.svg`" alt="" />
                                        Edit Profile
                                    </router-link>
                                </li>
                                <hr />
                                <li>
                                    <router-link to="" @click.native="logout">
                                        <img class="uk-margin-small-right" :src="`${images}/logout.svg`" alt="" />
                                        Log Out
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>

        <div id="profileModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Edit Profile</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-text-center uk-margin" v-if="profile != null">
                        <img v-if="profile.photo_url != null" :src="profile.photo_url.file_url" @error="(e) => imgError(e)" class="photo" />
                        <img v-else :src="`${images}/avatar.svg`" class="photo" />
                    </div>
                    <div class="uk-form-horizontal uk-margin">
                        <div class="uk-margin">
                            <label class="uk-form-label" for="name">Profile Image</label>
                            <div class="uk-form-controls">
                                <input name="profile_image" type="file" ref="profile_image" placeholder="Upload Image" />
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="name">Nama</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="name" type="text" v-model="form.fullname" />
                            </div>
                        </div>
                        <div class="uk-margin" v-if="profile != null">
                            <label class="uk-form-label" for="email">Email</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="email" type="text" disabled :placeholder="profile.email" />
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="phone">No. Telepon</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="phone" type="text" v-model="form.phone_number" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary" type="button" @click="saveProfile">Simpan</button>
                </div>
            </div>
        </div>

        <div
            v-if="profile != null && profile.company_options && profile.company_options.length > 0"
            id="companyModal"
            uk-modal
            esc-close="false"
            bg-close="false"
        >
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideCompanyModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Switch Company</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-company">Switch to</label>
                        <div class="uk-form-controls">
                            <multiselect
                                id="form-company"
                                v-model="form_change_company.company_id"
                                placeholder="Type to search company"
                                deselect-label="Can't remove this value"
                                label="name"
                                track-by="_id"
                                :searchable="true"
                                :allow-empty="false"
                                :multiple="false"
                                :options="profile.company_options"
                            ></multiselect>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-border-rounded uk-button uk-button-primary" type="button" @click="saveChangeCompany()">
                        Save
                    </button>
                </div>
            </div>
        </div>

        <div id="notif-modal" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Notifications</h2>
                </div>
                <div class="uk-modal-body" uk-overflow-auto>
                    <div v-if="isLoading" uk-spinner class="uk-position-center" :ratio="2"></div>
                    <div
                        v-else v-for="(notif, i) in notifications"
                        :key="i" 
                        class="uk-card uk-card-small uk-card-hover uk-card-body uk-margin-bottom uk-drag" 
                        @click="clickNotif(notif)"
                    >
                        <div v-if="!notif.is_read" class="uk-card-badge uk-label">New</div>
                        <h3 class="uk-card-title">{{ notif.title }}</h3>
                        <p>{{ notif.description }}</p>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button :disabled="notifications.length < 1 || isLoading" class="uk-button uk-button-primary" type="button" @click="readAllNotif">Mark all as read</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from "@/utils/constant";
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom,
} from "@/utils/notification";

export default {
    data() {
        return {
            images: PREFIX_IMAGE,
            form: {
                fullname: null,
                phone_number: null,
                photos: null,
            },
            form_change_company: {
                company_id: null,
            },
            profile: null,
            notifications: [],
            isLoading: null,
            notifQuery: {
                type: 'new_worker_request,new_sp_request'
            },
            routing: null,
        };
    },
    components: {
        Multiselect,
    },
    computed: {
        ...mapGetters({
            counter: 'notification/bell_counter',
        }),
        fullName() {
            return formatter.getFullname();
        },
        stringAvatar() {
            return formatter.textAvatar();
        },
        stringCompanyName() {
            return formatter.textAvatar(this.profile.company.name);
        },
        headerTitle() {
            return formatter.getHeaderTitle(this.$route.fullPath);
        },
    },
    async mounted() {
        this.profile = await this.getProfile();
    },
    methods: {
        async logout() {
            await this.$store.dispatch("auth/logout");
        },
        ...mapActions({
            getProfile: "profile/getProfile",
            updateProfile: "profile/updateProfile",
            updateUserCompany: "profile/updateUserCompany",
            uploadProfileImage: "profile/uploadProfileImage",
            readNotification: "profile/readNotification",
            clearNotifCounter: 'notification/clearNotifCounter'
        }),
        async showCompanyModal() {
            this.form_change_company.company_id = this.profile.company;
            await window.UIkit.modal("#companyModal").show();
        },
        async hideCompanyModal() {
            this.form_change_company.company_id = null;
            await window.UIkit.modal("#companyModal").hide();
        },
        async showModal() {
            this.form.fullname = this.profile.fullname;
            this.form.phone_number = this.profile.phone_number;
            await window.UIkit.modal("#profileModal").show();
        },
        async hideModal() {
            this.form.fullname = null;
            this.form.phone_number = null;
            this.form.photos = null;
            this.$refs.profile_image.value = "";
            await window.UIkit.modal("#profileModal").hide();
        },
        imgError(e) {
            e.target.src = `${this.images}/avatar.svg`;
        },
        async uploadImage() {
            var files = this.$refs.profile_image.files;
            let formData = new FormData();

            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set("file", file, file.name);
                const result = await this.uploadProfileImage(formData);
                this.form.photos = result.file_path;
            }
        },
        async saveProfile() {
            await this.uploadImage();
            let response = await this.updateProfile(this.form);
            this.$refs.profile_image.value = "";
            if (response && response.status === "OK") {
                notificationSuccess("Update profile success!");
                this.hideModal();
                this.$router.push('/admin');
            } else {
                notificationDanger("Failed to update profile.");
            }
        },
        async saveChangeCompany() {
            if (this.profile.company._id == this.form_change_company.company_id._id) {
                notificationDangerCustom("Selected company not changed!");
            } else {
                this.form_change_company.company_id = this.form_change_company.company_id._id;
                let response = await this.updateUserCompany(this.form_change_company);
                if (response && response.status === "OK") {
                    notificationSuccess("Switch Company success!");
                    this.profile = await this.getProfile();
                    this.hideCompanyModal();
                    if (!this.routing) {
                        this.$router.go();
                    }
                    this.routing = null;
                } else {
                    notificationDanger("Failed to change company.");
                }
            }
        },
        async getNotif() {
            this.isLoading = true;
            this.notifications = await this.$store.dispatch('profile/getNotification', this.notifQuery);
            this.isLoading = false;
        },
        async clickNotif(notif) {
            const resp = await this.readNotification({notification_ids:[`${notif._id}`]});
            if (resp === 'success') {
                notificationSuccess('Notification has been read!');
                for (const notification of this.notifications) {
                    if (notification._id.toString() == notif._id.toString()) {
                        notification.is_read = true;
                    }
                }

                let spType = '';
                if (notif.type === 'new_sp_request') {
                    if (notif.description.includes('SP1')) {
                        spType = 'sp1';
                    } else if (notif.description.includes('SP2')) {
                        spType = 'sp2';
                    } else if (notif.description.includes('Terminasi')) {
                        spType = 'termin';
                    }
                }

                switch (notif.type) {
                case 'new_worker_request':
                    this.routing = 'job_vacancy';
                    if (this.profile.company._id !== notif.model_id) {
                        this.form_change_company.company_id = Object.assign(
                            {},
                            this.form_change_company.company_id,
                            { _id: notif.model_id }
                        );
                        await this.saveChangeCompany();
                    }
                    this.$router.push('/admin/job_vacancy');
                    this.$router.go();
                    break;
                case 'new_sp_request':
                    this.routing = 'worker';
                    if (this.profile.company._id !== notif.model_id) {
                        this.form_change_company.company_id = Object.assign(
                            {},
                            this.form_change_company.company_id,
                            { _id: notif.model_id }
                        );
                        await this.saveChangeCompany();
                    }
                    this.$router.push({
                        path: '/admin/workers/mitra',
                        query: { filter: spType }
                    });
                    this.$router.go();
                    break;
                default:
                    this.$router.push('\\');
                    break;
                }
            }
            await window.UIkit.modal("#notif-modal").hide();
        },
        async readAllNotif() {
            this.isLoading = true;
            const resp = await this.$store.dispatch('profile/readAllNotification');
            if (resp === 'success') {
                notificationSuccess('All notification has been read!');
                for (const notif of this.notifications) {
                    notif.is_read = true;
                }
                await this.clearNotifCounter();
            }
            this.isLoading = false;
            await window.UIkit.modal("#notif-modal").hide();
        }
    },
};
</script>

<style scoped>
.header-background {
  padding: 0 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
.uk-navbar-nav > li > a {
  min-height: 60px;
}
.uk-navbar-center {
  color: #0abab5;
}
.photo {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.def-img-wrapper {
    display: flex;
    width: 59px;
    height: 59px;
    border: 2px solid #dbdbdb;
    border-radius: 100%;
    background-color: #ebebeb;
    padding: 8px;
}
.default-company-img {
    max-width: 100%;
    object-fit: contain;
}
</style>
