<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <!-- Filter -->
            <template v-if="$can('READ', 'rostering-shift')">
                <button
                    v-if="meta.company_office_id === null"
                    type="button"
                    class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default"
                    @click="showFilterShiftModalBox"
                >
                    <span><img :src="`${images}/shipper-location.png`" alt="Location icon" width="20"></span>
                    <span class="uk-margin-small-left uk-text-warning">Select Location Filter</span>
                </button>
                <template v-else>
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <button
                            type="button"
                            class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default"
                            @click="showFilterShiftModalBox"
                        >
                            <span><img :src="`${images}/shipper-location.png`" alt="Location icon" width="20"></span>
                            <span class="uk-margin-small-left uk-text-warning">{{ cityName || '-' }}</span>
                        </button>
                        <button
                            v-if="$can('WRITE', 'rostering-shift')"
                            type="button"
                            class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default"
                            @click="showAddOrEditShiftModalBox({ actionType: 'addShift', shift: null })"
                        >
                            <span><img :src="`${images}/shift-add.png`" alt="Add shift icon" width="25"></span>
                            <span class="uk-margin-small-left uk-text-success">Tambah Shift</span>
                        </button>
                    </div>
                    <div class="uk-margin-top">
                        <div class="uk-flex uk-flex-middle uk-flex-between">
                            <button
                                type="button"
                                class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default"
                                @click="showFilterShiftModalBox"
                            >
                                <span><img :src="`${images}/shipper-storage.png`" alt="Storage icon" width="20"></span>
                                <span class="uk-margin-small-left uk-text-warning">{{ officeName || '-' }}</span>
                            </button>
                            <button
                                type="button"
                                class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default"
                                @click="showDownloadReportModalBox"
                            >
                                <span><img :src="`${images}/download.png`" alt="Download icon" width="25"></span>
                                <span class="uk-margin-small-left uk-text-success">Download Report</span>
                            </button>
                        </div>
                        <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
                            <button
                                type="button"
                                class="uk-button uk-button-link uk-text-muted uk-text-default"
                                @click="resetLocationFilter"
                            >
                                <span style="text-decoration: underline;">Reset Location Filter</span>
                            </button>
                            <div class="uk-flex uk-flex-middle uk-flex-left" style="gap: 15px;">
                                <button
                                    type="button"
                                    class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                                    style="border: 1px solid #0abab5; color: #0abab5;"
                                    @click="showDownloadShiftTemplateModalBox"
                                >
                                    <span><img :src="`${images}/shift-download.svg`" alt="" width="25"></span>
                                    <span class="uk-margin-small-left">Download Shift Template</span>
                                </button>
                                <button
                                    v-if="$can('EDIT', 'rostering-shift')"
                                    type="button"
                                    class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                                    style="color: white;"
                                    :style="[!disableChangeMonthAndWeekButton ? {'background-color': '#0abab5'} : {'background-color': '#BDBDBD'}]"
                                    :disabled="disableChangeMonthAndWeekButton"
                                    @click="showImportShiftTemplateModalBox"
                                >
                                    <span><img :src="`${images}/shift-upload.svg`" alt="Upload icon" width="25"></span>
                                    <span class="uk-margin-small-left">Import Shift Template</span>
                                </button>
                                <button
                                    v-if="$can('EDIT', 'rostering-shift')"
                                    type="button"
                                    class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                                    style="color: white; background: #FFC737;"
                                    @click="showFailedImportShiftTemplateModalBox"
                                >
                                    <span><img :src="`${images}/timelapse-logs.svg`" alt="Logs icon" width="25"></span>
                                    <span class="uk-margin-small-left">Error Log</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <!-- End filter -->

            <!-- Shift list -->
            <div v-if="$can('READ', 'rostering-shift') && meta.company_office_id !== null" class="uk-grid-small uk-margin-top" uk-grid>
                <div class="uk-width-auto">
                    <p>Available Shift:</p>
                </div>
                <div class="uk-width-expand">
                    <template v-if="$can('EDIT', 'rostering-shift')">
                        <div
                            v-if="shifts.length > 0"
                            class="uk-flex uk-flex-middle uk-flex-left"
                            style="overflow-x: auto; padding-bottom: 15px; gap: 15px;"
                        >
                            <button
                                v-for="(shift, index) in shifts"
                                :key="index"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded uk-text-nowrap"
                                style="border: 2px solid #979797;"
                                @click="showAddOrEditShiftModalBox({ actionType: 'editShift', shift })"
                            >
                                <div class="uk-border-circle uk-inline color-circle-sm uk-margin-remove-left" :style="`background-color: #${shift.color};`"></div>
                                <span class="uk-margin-small-left uk-text-default">{{ shift.name || '-' }}</span>
                                <span class="uk-margin-small-left"><img :src="`${images}/pencil.png`" alt="Pencil icon" width="15"></span>
                            </button>
                        </div>
                        <div v-else>
                            <p>No shift</p>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            v-if="shifts.length > 0"
                            class="uk-flex uk-flex-middle uk-flex-left"
                            style="overflow-x: auto; padding-bottom: 15px; gap: 15px;"
                        >
                            <button
                                v-for="(shift, index) in shifts"
                                :key="index"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded uk-text-nowrap"
                                style="border: 2px solid #979797;"
                            >
                                <div class="uk-border-circle uk-inline color-circle-sm uk-margin-remove-left" :style="`background-color: #${shift.color};`"></div>
                                <span class="uk-margin-small-left uk-text-default">{{ shift.name || '-' }}</span>
                            </button>
                        </div>
                        <div v-else>
                            <p>No shift</p>
                        </div>
                    </template>
                </div>
                <div v-if="$can('EDIT', 'rostering-shift') && selectedWorker" class="uk-width-auto">
                    <button
                        v-if="buttonLoaderAssignShiftMitra"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        disabled
                    >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="uk-button uk-border-rounded"
                        style="color: white;"
                        :style="[shiftChange ? {'background-color': '#0275D8'} : {'background-color': '#BDBDBD'}]"
                        :disabled="!shiftChange"
                        @click="doAssignShiftMitra"
                    >Save</button>
                </div>
            </div>
            <!-- End shift list -->

            <!-- Daily shift list -->
            <div v-if="$can('READ', 'rostering-shift') && meta.company_office_id !== null" class="uk-card uk-card-default uk-margin-top">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-middle uk-table-medium uk-table-divider">
                        <thead>
                            <tr>
                                <th class="uk-table-expand uk-text-center" rowspan="2">
                                    <div class="uk-flex uk-flex-middle">
                                        <p>Full Name</p>
                                    </div>
                                </th>
                                <th class="uk-text-center" rowspan="2">
                                    <div class="uk-flex uk-flex-middle">
                                        <p class="uk-text-nowrap">Job Role</p>
                                    </div>
                                </th>
                                <th class="uk-width-small">
                                    <button
                                        type="button"
                                        class="uk-button uk-button-small"
                                        :disabled="disableChangeMonthAndWeekButton"
                                        @click="changeMonth(-1)"
                                    >&#8592;</button>
                                </th>
                                <th class="uk-table-expand uk-text-center" colspan="7">{{ monthName || '-' }}</th>
                                <th class="uk-width-small">
                                    <button
                                        type="button"
                                        class="uk-button uk-button-small"
                                        :disabled="disableChangeMonthAndWeekButton"
                                        @click="changeMonth(1)"
                                    >&#8594;</button>
                                </th>
                                <th v-if="monthDate.page === Math.ceil(days / 7)" class="uk-text-center" rowspan="2">
                                    <div class="uk-flex uk-flex-middle uk-flex-center">
                                        <p>Total</p>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th class="uk-width-small">
                                    <button
                                        type="button"
                                        class="uk-button uk-button-small"
                                        :disabled="disableChangeMonthAndWeekButton"
                                        @click="changeWeek(-1)"
                                    >&#8592;</button>
                                </th>
                                <th
                                    v-for="(day, index) in monthDate.days"
                                    :key="index"
                                    :value="day"
                                    class="uk-text-center uk-width-medium"
                                >{{ day || '-' }}</th>
                                <th
                                    class="uk-width-small"
                                    :colspan="8 - monthDate.days.length"
                                >
                                    <button
                                        type="button"
                                        class="uk-button uk-button-small"
                                        :disabled="disableChangeMonthAndWeekButton"
                                        @click="changeWeek(1)"
                                    >&#8594;</button>
                                </th>
                            </tr>
                        </thead>
                        <loading-table v-if="tableLoading" :colspan="11"></loading-table>
                        <template v-else>
                            <tbody v-if="workers.docs.length > 0">
                                <tr
                                    v-for="(worker, index) in workers.docs"
                                    :key="index"
                                    :class="{'selected-row': selected({ workerId: worker._id })}"
                                >
                                    <td class="uk-text-primary" style="cursor: pointer;" @click="selectWorker({ worker })">{{ worker.fullname || 'Mitra MyRobin' }}</td>
                                    <td style="cursor: pointer;" @click="selectWorker({ worker })">{{ worker.role || '-' }}</td>
                                    <td style="cursor: pointer;" @click="selectWorker({ worker })"></td>
                                    <td
                                        v-for="(day, index) in monthDate.days"
                                        :key="index"
                                        :value="day"
                                        class="uk-text-center uk-text-nowrap"
                                    >
                                        <select
                                            v-if="selected({ workerId: worker._id }) && checkAssigned({ workerId: worker._id, day })"
                                            style="background-color: white;"
                                            @change="onChangeOptions({ day, event: $event })"
                                        >
                                            <option
                                                v-for="(shift, index) in shiftOptions"
                                                :key="index"
                                                :value="shift._id"
                                                :selected="shift.name == checkAssigned({ workerId: worker._id, day }).name"
                                            >{{ shift.name || '-' }}</option>
                                        </select>
                                        <span v-else>
                                            <template v-if="checkAssigned({ workerId: worker._id, day }) && checkAssigned({ workerId: worker._id, day }) !== 'OFF'">
                                                <div
                                                    class="uk-border-circle uk-inline color-circle-sm"
                                                    :style="`background-color: #${checkAssigned({ workerId: worker._id, day }).color};`"
                                                ></div>
                                                <span>{{ checkAssigned({ workerId: worker._id, day }).name }}</span>
                                            </template>
                                            <span v-else>OFF</span>
                                        </span>
                                    </td>
                                    <td style="cursor: pointer;" :colspan="8 - monthDate.days.length" @click="selectWorker({ worker })"></td>
                                    <td v-if="monthDate.page === Math.ceil(days / 7)" class="uk-text-center uk-width-medium uk-text-nowrap">
                                        <div
                                            v-if="recapPerUser({ workerId: worker._id }) && recapPerUser({ workerId: worker._id }).data"
                                            @click="hideRecapPerUser({ workerId: worker._id })"
                                        >
                                            <span
                                                v-for="(data, index) in recapPerUser({ workerId: worker._id }).data"
                                                :key="index"
                                                class="uk-flex uk-flex-column"
                                            >
                                                <div>
                                                    <div
                                                        class="uk-border-circle uk-inline color-circle-sm"
                                                        :style="`background-color: #${data.color};`"
                                                    ></div>
                                                    <span>{{ data.name }}: {{ data.count }}</span>
                                                </div>
                                            </span>
                                        </div>
                                        <button
                                            v-else
                                            type="button"
                                            class="uk-button uk-button-primary"
                                            @click="viewShiftRecapByUser({ workerId: worker._id})"
                                        >Show</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot v-if="workers.docs.length > 0">
                                <tr>
                                    <td></td>
                                    <td>Total</td>
                                    <td></td>
                                    <td
                                        v-for="(day, index) in monthDate.days"
                                        :key="index"
                                        :value="day"
                                        class="uk-text-nowrap uk-text-center"
                                    >
                                        <div v-if="recapPerDay({ day })" @click="hideRecapPerDay({ day })">
                                            <span
                                                v-for="(data, index) in recapPerDay({ day }).data"
                                                :key="index"
                                                class="uk-flex uk-flex-column"
                                            >
                                                <div>
                                                    <div
                                                        class="uk-border-circle uk-inline color-circle-sm"
                                                        :style="`background-color: #${data.color};`"
                                                    ></div>
                                                    <span>{{ data.name }} : {{ data.count }}</span>
                                                </div>
                                            </span>
                                        </div>
                                        <button
                                            v-else
                                            type="button"
                                            class="uk-button uk-button-primary"
                                            @click="viewShiftRecapByDate({ day })"
                                        >Show</button>
                                    </td>
                                </tr>
                            </tfoot>
                            <empty-table v-if="workers.docs.length < 1" :colspan="11"/>
                        </template>
                    </table>
                </div>
                <pagination
                    :total-data="workers.totalDocs"
                    :limit-page="meta.limit"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End daily shift list -->

            <!-- Handler when user can't access -->
            <div v-if="!$can('READ', 'rostering-shift') && !$can('EDIT', 'rostering-shift') && !$can('DELETE', 'rostering-shift')">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>You are not authorized for read data this page</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler when user can't access -->

            <!-- Filter shift modal box -->
            <div id="filter-shift-modal-box" class="uk-flex-top filter-shift-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Filter Shift Data</h2>
                    </div>
                    <form @submit.prevent="doFilterShift">
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <label for="city" class="uk-form-label">City</label>
                                <select id="city" class="uk-select uk-border-rounded" v-model="formFilterShift.city" @change="filterOffices">
                                    <option :value="null" selected>Choose City</option>
                                    <option
                                        v-for="(city, index) in cities"
                                        :key="index"
                                        :value="city"
                                    >{{ city.name || '-' }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="office" class="uk-form-label">Office</label>
                                <select
                                    id="office"
                                    class="uk-select uk-border-rounded"
                                    v-model="formFilterShift.office"
                                    :disabled="formFilterShift.city === null"
                                >
                                    <option :value="null" selected>Choose Office</option>
                                    <option
                                        v-for="(office, index) in offices"
                                        :key="index"
                                        :value="office"
                                    >{{ office.name || '-' }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="buttonLoaderModalBox"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="submit"
                                class="uk-button"
                                :class="[disabledApplyFilterButton ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledApplyFilterButton"
                            >Apply</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End filter shift modal box -->

            <!-- Add or edit shift modal box -->
            <div id="add-or-edit-shift-modal-box" class="uk-flex-top add-or-edit-shift-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">{{ addOrEditActionType === 'addShift' ? 'Tambah Shift' : 'Edit Shift' }}</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <form v-else @submit.prevent="doAddOrEditShift">
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <label for="shiftName" class="uk-form-label">Nama Shift</label>
                                <input
                                    id="shiftName"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="selectedShift.name"
                                >
                            </div>
                            <div class="uk-width-1-2">
                                <label for="startShift" class="uk-form-label">Dari</label>
                                <datetime
                                    input-id="startShift"
                                    input-class="uk-input uk-border-rounded uk-text-center"
                                    type="time"
                                    placeholder="--:--"
                                    v-model="selectedShift.start_shift"
                                ></datetime>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="endShift" class="uk-form-label">Sampai</label>
                                <datetime
                                    input-id="endShift"
                                    input-class="uk-input uk-border-rounded uk-text-center"
                                    type="time"
                                    placeholder="--:--"
                                    v-model="selectedShift.end_shift"
                                ></datetime>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="color" class="uk-form-label">Color</label>
                                <div class="uk-flex uk-flex-middle" style="gap: 15px;">
                                    <label for="EF5350">
                                        <input
                                            id="EF5350"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #EF5350;"
                                            value="EF5350"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === 'EF5350' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #EF5350;"
                                        ></div>
                                    </label>
                                    <label for="FFA726">
                                        <input
                                            id="FFA726"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #FFA726;"
                                            value="FFA726"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === 'FFA726' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #FFA726;"
                                        ></div>
                                    </label>
                                    <label for="FFEE58">
                                        <input
                                            id="FFEE58"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #FFEE58;"
                                            value="FFEE58"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === 'FFEE58' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #FFEE58;"
                                        ></div>
                                    </label>
                                    <label for="66BB6A">
                                        <input
                                            id="66BB6A"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #66BB6A;"
                                            value="66BB6A"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === '66BB6A' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #66BB6A;"
                                        ></div>
                                    </label>
                                    <label for="42A5F5">
                                        <input
                                            id="42A5F5"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #42A5F5;"
                                            value="42A5F5"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === '42A5F5' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #42A5F5;"
                                        ></div>
                                    </label>
                                    <label for="5C6BC0">
                                        <input
                                            id="5C6BC0"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #5C6BC0;"
                                            value="5C6BC0"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === '5C6BC0' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #5C6BC0;"
                                        ></div>
                                    </label>
                                    <label for="AB47BC">
                                        <input
                                            id="AB47BC"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #AB47BC;"
                                            value="AB47BC"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === 'AB47BC' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #AB47BC;"
                                        ></div>
                                    </label>
                                    <label for="EC407A">
                                        <input
                                            id="EC407A"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #EC407A;"
                                            value="EC407A"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === 'EC407A' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #EC407A;"
                                        ></div>
                                    </label>
                                    <label for="8D6E63">
                                        <input
                                            id="8D6E63"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #8D6E63;"
                                            value="8D6E63"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === '8D6E63' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #8D6E63;"
                                        ></div>
                                    </label>
                                    <label for="78909C">
                                        <input
                                            id="78909C"
                                            class="uk-radio"
                                            type="radio"
                                            style="background-color: #78909C;"
                                            value="78909C"
                                            v-model="selectedShift.color"
                                            hidden
                                        >
                                        <div
                                            :class="[selectedShift.color === '78909C' ? 'selected-circle' : '', 'uk-border-circle color-circle']"
                                            class="uk-border-circle color-circle"
                                            style="background-color: #78909C;"
                                        ></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            class="uk-modal-footer uk-flex uk-flex-middle"
                            :class="[buttonLoaderModalBox || addOrEditActionType === 'addShift' ? 'uk-flex-right' : 'uk-flex-between']"
                        >
                            <button
                                v-if="buttonLoaderModalBox"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <template v-else>
                                <button
                                    v-if="$can('DELETE', 'rostering-shift') && addOrEditActionType === 'editShift'"
                                    type="button"
                                    class="uk-button uk-button-danger"
                                    @click="showConfirmationModalBox({ title: 'Are you sure ?', body: `${selectedShift.name} will be deleted.`, actionType: 'deleteShift' })"
                                >Delete</button>
                                <button
                                    type="submit"
                                    class="uk-button"
                                    :class="[
                                        selectedShift.name === '' ||
                                        selectedShift.start_shift === null ||
                                        selectedShift.end_shift === null ||
                                        selectedShift.color === null ? 'uk-button-default' : 'uk-button-primary'
                                    ]"
                                    :disabled="
                                        selectedShift.name === '' ||
                                        selectedShift.start_shift === null ||
                                        selectedShift.end_shift === null ||
                                        selectedShift.color === null
                                    "
                                >Save</button>
                            </template>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End add or edit shift modal box -->

            <!-- Download shift template modal box -->
            <div id="download-shift-template-modal-box" class="uk-flex-top download-shift-template-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Please select the type of template that you wish to download</h2>
                    </div>
                    <form @submit.prevent="doDownloadShiftTemplate">
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-2">
                                <label for="forMonth" class="uk-form-label">For month:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    @change="setMonth({ event: $event })"
                                >
                                    <option
                                        v-for="(month, index) in monthOptions"
                                        :key="index"
                                        :value="month.name"
                                        :selected="month.name == monthName"
                                    >{{ month.name }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="forMonth" class="uk-form-label">Year:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    v-model="reportYear"
                                >
                                    <option
                                        v-for="(year, index) in yearOptions"
                                        :key="index"
                                        :value="year"
                                    >{{ year }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="buttonLoaderModalBox"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="submit"
                                class="uk-button uk-button-primary"
                            >Download</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End download shift template modal box -->

            <!-- Import shift template modal box -->
            <div id="import-shift-template-modal-box" class="uk-flex-top import-shift-template-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Please select the type of template that you wish to upload</h2>
                    </div>
                    <form @submit.prevent="
                        showConfirmationModalBox({
                            title: 'Confirmation',
                            body: 'Are you sure want to proceed?',
                            actionType: 'importShiftTemplate'
                        })
                    ">
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-2">
                                <label for="forMonth">For month:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    @change="setMonth({ event: $event })"
                                >
                                    <option
                                        v-for="(month, index) in monthOptions"
                                        :key="index"
                                        :value="month.name"
                                        :selected="month.name == monthName"
                                    >{{ month.name || '-' }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="forMonth" class="uk-form-label">Year:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    v-model="reportYear"
                                >
                                    <option
                                        v-for="(year, index) in yearOptions"
                                        :key="index"
                                        :value="year"
                                    >{{ year }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="uploadFile">Upload File :</label>
                            </div>
                            <div class="uk-width-1-2">
                                <button
                                    v-if="!fileAttachment"
                                    type="button"
                                    class="uk-button uk-button-default uk-border-rounded"
                                    style="width: 165px; border: 1px solid #0abab5; color: #0abab5;"
                                    @click="triggerUploadFile()"
                                >
                                    <span><img :src="`${images}/shift-download.svg`" alt="Upload icon" width="25"></span>
                                    <input
                                        ref="upload"
                                        style="display: none"
                                        type="file"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        @change="fileUpload({ event: $event })"
                                    />
                                    <span>Upload File</span>
                                </button>
                                <div v-else class="uk-flex uk-flex-middle uk-flex-between" style="gap: 10px;">
                                    <div class="uk-border-rounded uk-overflow-auto uk-flex uk-flex-middle" style="gap: 10px; padding-bottom: 10px;">
                                        <span class="uk-text-nowrap">{{ fileAttachment ? fileAttachment.name : '-' }}</span>
                                    </div>
                                    <button type="button" class="uk-button uk-button-small" style="padding-bottom: 10px;" @click="fileAttachment = null">
                                        <img :src="`${images}/cross.svg`" alt="Cross red icon" width="25">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                type="submit"
                                class="uk-button"
                                :class="[fileAttachment === null ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="fileAttachment === null"
                            >Save</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End import shift template modal box -->

            <!-- Download report modal box -->
            <div id="download-report-modal-box" class="uk-flex-top download-report-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Please select the type of report that you wish to download</h2>
                    </div>
                    <div class="uk-modal-body">
                        <div v-if="cityReport == true || officeReport == true" class="uk-margin-bottom" uk-grid>
                            <div class="uk-width-1-2">
                                <label for="forMonth">For month:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    @change="setMonth({ event: $event })"
                                >
                                    <option
                                        v-for="(month, index) in monthOptions"
                                        :key="index"
                                        :value="month.name"
                                        :selected="month.name === monthName"
                                    >{{ month.name || '-' }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="forMonth">Year:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    v-model="reportYear"
                                >
                                    <option
                                        v-for="(year, index) in yearOptions"
                                        :key="index"
                                        :value="year"
                                    >{{ year }}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="cityReport == false && officeReport == false">
                            <p>Please select category</p>
                            <div
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="cityReport = true, officeReport = false"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>Report per City</span>
                                </div>
                                <div>
                                    <img :src="`${images}/arrow-right.png`" alt="arrow-right">
                                </div>
                            </div>
                            <div
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="officeReport = true, cityReport = false"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>Report per Office</span>
                                </div>
                                <div>
                                    <img :src="`${images}/arrow-right.png`" alt="arrow-right">
                                </div>
                            </div>
                        </div>
                        <div v-if="cityReport == true">
                            <p>City report</p>
                            <div 
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-middle uk-flex-between uk-width-1-1"
                                @click="doDownloadShiftReport({ type: 'city', data: null })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>All cities</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                            <div
                                v-for="(city, index) in cities"
                                :key="index"
                                :value="city"
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-middle uk-flex-between uk-width-1-1"
                                @click="doDownloadShiftReport({ type: 'city', data: city })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>{{ city.name || '-' }}</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                        </div>
                        <div v-if="officeReport == true">
                            <p>Office report</p>
                            <div 
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-middle uk-flex-between uk-width-1-1"
                                @click="doDownloadShiftReport({ type: 'office', data: null })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>All Offices</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                            <div
                                v-for="(office, index) in company_offices"
                                :key="index"
                                :value="office"
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-middle uk-flex-between uk-width-1-1"
                                @click="doDownloadShiftReport({ type: 'office', data: office })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>{{ office.name || '-' }}</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="cityReport == true || officeReport == true" class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            type="button"
                            class="uk-button uk-button-primary"
                            @click="cityReport = false, officeReport = false"
                        >Back</button>
                    </div>
                </div>
            </div>
            <!-- End download report modal box -->

            <!-- Failed import shift template modal box -->
            <div id="failed-import-shift-template-modal-box" class="uk-modal-container uk-flex-top failed-import-shift-template-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Upload Status</h2>
                    </div>
                    <div v-if="errorLogData">
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p>
                                    Upload Successful:
                                    <span class="uk-text-bold">{{ errorLogData.accepted_data.userCount }} Users, {{ errorLogData.accepted_data.shiftCount }} Shifts</span>
                                </p>
                            </div>
                            <div class="uk-width-1-2">
                                <p>
                                    Upload Failed: <span class="uk-text-bold">{{ errorLogData.rejected_data.length }} Users</span>
                                </p>
                            </div>
                            <div class="uk-width-1-2 uk-flex uk-flex-right">
                                <template>
                                    <button
                                        v-if="is_download_rejected_data_loading"
                                        type="button"
                                        class="uk-button uk-button-default uk-border-rounded uk-margin-small-left uk-width-1-5"
                                        disabled
                                    >
                                        <span><div uk-spinner="ratio: 0.5"></div></span>
                                        <span class="uk-margin-small-left">Loading</span>
                                    </button>
                                    <button
                                        v-else
                                        type="button"
                                        class="uk-button uk-border-rounded uk-button-primary uk-margin-small-left uk-width-1-5 uk-position-relative"
                                        @click="doDownloadRejectedData"
                                    >
                                        <img
                                            class="uk-position-center-left uk-margin-small-left"
                                            :src="`${images}/download-outline-white.svg`"
                                            alt="Download icon"
                                        >
                                        <span>Download</span>
                                    </button>
                                </template>
                            </div>
                        </div>
                        <div class="uk-padding-small">
                            <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                                <thead>
                                    <tr>
                                        <th class="uk-text-nowrap">Name</th>
                                        <th class="uk-text-nowrap">Job Role</th>
                                        <th class="uk-text-nowrap">Month</th>
                                        <th class="uk-text-nowrap">Date</th>
                                        <th class="uk-text-nowrap">Status</th>
                                    </tr>
                                </thead>
                                <tbody v-if="errorLogData.rejected_data.length > 0">
                                    <tr v-for="(data, index) in errorLogData.rejected_data" :key="index">
                                        <td class="uk-text-nowrap"><span class="uk-text-bold">{{ data.data["Name"] || '-' }}</span></td>
                                        <td class="uk-text-nowrap">{{ data.data["Job Role"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ data.data["Month"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ data.shiftDate.length > 0 ? data.shiftDate.join(', ') : '-' }}</td>
                                        <td class="uk-text-nowrap">{{ data.reason }}</td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="5" />
                            </table>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button type="button" class="uk-button uk-button-primary uk-modal-close">Close</button>
                    </div>
                </div>
            </div>
            <!-- End import shift template modal box -->

            <!-- Confirmation modal box -->
            <div id="confirmation-modal-box" class="uk-flex-top confirmation-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">{{ confirmationModalBoxData.title || '-' }}</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>{{ confirmationModalBoxData.body || '-' }}</p>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button
                            v-if="buttonLoaderModalBox"
                            type="button"
                            class="uk-button uk-button-default"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <template v-else>
                            <button
                                class="uk-button uk-button-danger uk-modal-close"
                                type="button"
                            >No</button>
                            <button
                                class="uk-button uk-margin-small-left uk-button-primary"
                                type="button"
                                @click="doHandleConfirmationModalBox"
                            >Yes</button>
                        </template>
                    </div>
                </div>
            </div>
            <!-- End confirmation modal box -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import { notificationDanger, notificationSuccess, notificationDangerCustom } from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';
import { Datetime } from 'vue-datetime';
import moment from 'moment';
moment.locale('id');

export default {
    name: 'Schedule',
    components: {
        Datetime,
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable')
    },
    data() {
        return {
            isLoading: false,
            modalLoading: false,
            tableLoading: false,
            images: PREFIX_IMAGE,
            buttonLoaderModalBox: false,
            buttonLoaderAssignShiftMitra: false,
            disableChangeMonthAndWeekButton: false,
            cities: [],
            offices: [],
            shifts: [],
            shiftOptions: [],
            recap: [],
            dailyShiftAssign: [],
            errorLogData: null,
            companyOffice: null,
            formFilterShift: {
                city: null,
                office: null
            },
            addOrEditActionType: null,
            selectedShift: {
                _id: null,
                company_office_id: '',
                name: '',
                start_shift: null,
                end_shift: null,
                color: null,
                status: 0
            },
            confirmationModalBoxData: {
                title: 'Are you sure ?',
                body: 'Are you sure want to proceed?',
                actionType: null
            },
            workers: {
                docs: [],
                totalDocs: 0
            },
            monthDate: {
                page: 1,
                days: [],
            },
            updatedMonth: null,
            monthName: '',
            monthNum: null,
            days: null,
            selectedWorker: null,
            shiftChange: false,
            date_shift: null,
            meta: {
                company_office_id: null,
                property_city: null,
                property_name: null,
                limit: 10,
                page: 1
            },
            monthOptions: [
                {
                    name: 'Januari',
                    value: '01'
                },
                {
                    name: 'Februari',
                    value: '02'
                },
                {
                    name: 'Maret',
                    value: '03'
                },
                {
                    name: 'April',
                    value: '04'
                },
                {
                    name: 'Mei',
                    value: '05'
                },
                {
                    name: 'Juni',
                    value: '06'
                },
                {
                    name: 'Juli',
                    value: '07'
                },
                {
                    name: 'Agustus',
                    value: '08'
                },
                {
                    name: 'September',
                    value: '09'
                },
                {
                    name: 'Oktober',
                    value: '10'
                },
                {
                    name: 'November',
                    value: '11'
                },
                {
                    name: 'Desember',
                    value: '12'
                },
            ],
            yearOptions: [2022, 2023, 2024],
            currentYear: null,
            reportYear: null,
            reportFilter: {
                monthName: null,
                monthValue: null,
            },
            fileAttachment: null,
            cityReport: false,
            officeReport: false,
            filename_excel: null,
            is_download_rejected_data_loading: false
        };
    },
    watch: {
        async meta() {
            try {
                this.tableLoading = true;
                await this.setWorkers();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        reportYear() {
            const updatedMonth = moment(this.reportFilter.monthValue).set('year', this.reportYear).format("YYYY-MM");
            this.reportFilter.monthValue = updatedMonth;
        }
    },
    computed: {
        ...mapGetters({
            company_offices: 'company_office/company_offices'
        }),
        disabledApplyFilterButton() {
            let disabled = true;

            if (
                this.formFilterShift.city === null ||
                this.formFilterShift.office === null
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        cityName() {
            let name = null;

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((data) => data._id === this.meta.company_office_id);

                if (findCity && findCity.domicile_city_id && findCity.domicile_city_id.name) {
                    name = findCity.domicile_city_id.name;
                }
            }

            return name;
        },
        officeName() {
            let name = null;

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findOffice = this.company_offices.find((data) => data._id === this.meta.company_office_id);

                if (findOffice && findOffice.name) {
                    name = findOffice.name;
                }
            }

            return name;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.filter-shift-modal-box')) window.UIkit.modal('.filter-shift-modal-box').$destroy(true);
        if (window.UIkit.modal('.add-or-edit-shift-modal-box')) window.UIkit.modal('.add-or-edit-shift-modal-box').$destroy(true);
        if (window.UIkit.modal('.download-shift-template-modal-box')) window.UIkit.modal('.download-shift-template-modal-box').$destroy(true);
        if (window.UIkit.modal('.import-shift-template-modal-box')) window.UIkit.modal('.import-shift-template-modal-box').$destroy(true);
        if (window.UIkit.modal('.download-report-modal-box')) window.UIkit.modal('.download-report-modal-box').$destroy(true);
        if (window.UIkit.modal('.failed-import-shift-template-modal-box')) window.UIkit.modal('.failed-import-shift-template-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-modal-box')) window.UIkit.modal('.confirmation-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.getCompanyOffice();
            this.setCities();
            this.updatedMonth = moment();
            // this.updatedMonth = this.updatedMonth.year(new Date().getFullYear().toFixed());
            this.reportYear = this.currentYear = new Date().getFullYear();
            this.getCurrentMonthDays();
            this.getWeeklyDay();
            this.getReportSelectedMonth();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getAstroShiftList: 'new_shift/getAstroShiftList',
            checkShift: 'option_data/checkShift',
            addAstroDailyShift: 'new_shift/addAstroDailyShift',
            editAstroDailyShift: 'new_shift/editAstroDailyShift',
            deleteAstroDailyShift: 'new_shift/deleteAstroDailyShift',
            getAstroMitraList: 'new_shift/getAstroMitraList',
            assignMitraShift: 'new_shift/assignMitraShift',
            getAstroShiftRecapDate: 'new_shift/getAstroShiftRecapDate',
            getAstroTemplateShift: 'mitra/getAstroTemplateShift',
            uploadShift: 'new_shift/uploadShiftTemplate',
            getShiftUploadErrorLogs: 'new_shift/getShiftUploadErrorLogs',
            getAstroMonthlyShiftReport: 'mitra/getAstroMonthlyShiftReport',
            getAstroShiftRecapUser: 'new_shift/getAstroShiftRecapUser'
        }),
        async showFilterShiftModalBox() {
            try {
                await window.UIkit.modal('#filter-shift-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showAddOrEditShiftModalBox({ actionType, shift }) {
            try {
                this.addOrEditActionType = actionType;
                this.resetSelectedShift();
                if (actionType === 'addShift' && !shift) {
                    const start = new Date().setHours(8, 30, 0, 0);
                    const end = new Date().setHours(17, 0, 0, 0);
                    this.selectedShift.start_shift = new Date(start).toISOString();
                    this.selectedShift.end_shift = new Date(end).toISOString();
                    await window.UIkit.modal('#add-or-edit-shift-modal-box').show();
                } else if (actionType === 'editShift' && shift) {
                    await this.checkShiftBeforeEdit({ shift });
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showDownloadShiftTemplateModalBox() {
            try {
                await window.UIkit.modal('#download-shift-template-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showImportShiftTemplateModalBox() {
            try {
                this.fileAttachment = null;
                await window.UIkit.modal('#import-shift-template-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showDownloadReportModalBox() {
            try {
                this.cityReport = this.officeReport = false;
                await window.UIkit.modal('#download-report-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showFailedImportShiftTemplateModalBox() {
            try {
                const payload = { company_office_id: this.companyOffice._id, month: this.monthNum };
                const response = await this.getShiftUploadErrorLogs(payload);
                if (response) {
                    this.errorLogData = response;
                    this.filename_excel = response.filename;
                    await window.UIkit.modal('#failed-import-shift-template-modal-box').show();
                } else {
                    notificationSuccess('Shift Template 100% Uploaded Successfully!');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationModalBox({ title, body, actionType }) {
            try {
                this.resetConfirmationModalBoxData();
                this.fillConfirmationModalBoxData({ title, body, actionType });
                await window.UIkit.modal('#confirmation-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        resetLocationFilter() {
            this.resetFormFilterShift();
            this.resetMeta();
            this.companyOffice = null;
            this.workers.docs = [];
            this.workers.totalDocs = 0;
            this.shifts = [];
        },
        async checkShiftBeforeEdit({ shift }) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal('#add-or-edit-shift-modal-box').show();
                const payload = { id: shift._id };
                const response = await this.checkShift(payload);
                if (response && response.status === 'OK') {
                    this.fillSelectedShift({
                        id: shift._id,
                        companyOfficeId: shift.company_office_id,
                        name: shift.name,
                        startShift: shift.start_shift,
                        endShift: shift.end_shift,
                        color: shift.color,
                        status: 0
                    });
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalLoading = false;
            }
        },
        async doFilterShift() {
            try {
                this.buttonLoaderModalBox = true;
                this.companyOffice = this.formFilterShift.office;
                this.fillMeta({ companyOfficeId: this.formFilterShift.office && this.formFilterShift.office._id ? this.formFilterShift.office._id : null });
                await this.setShifts();

                this.workers.docs = [];
                this.workers.totalDocs = 0;
                await this.setWorkers();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoaderModalBox = false;
                await window.UIkit.modal('#filter-shift-modal-box').hide();
            }
        },
        doAddOrEditShift() {
            if (this.addOrEditActionType === 'addShift') {
                this.doAddShift();
            } else if (this.addOrEditActionType === 'editShift') {
                this.doEditShift();
            }
        },
        async doAddShift() {
            try {
                this.buttonLoaderModalBox = true;
                const start_shift = this.formatTimeShift(this.selectedShift.start_shift);
                const end_shift = this.formatTimeShift(this.selectedShift.end_shift);

                const payloads = {
                    company_id: this.companyOffice.company_id,
                    company_office_id: this.companyOffice._id === undefined ? console.log('show heads up modal box') : this.companyOffice._id,
                    name: this.selectedShift.name,
                    start_shift,
                    end_shift,
                    color: this.selectedShift.color
                };

                const response = await this.addAstroDailyShift(payloads);
                if (response && response.status == '1') {
                    notificationSuccess(`${payloads.name} created successfully`);
                    await this.setShifts();
                    this.resetSelectedShift();
                    this.dailyShiftAssign = [];
                    this.shiftChange = false;
                    this.selectedWorker = null;
                    this.recap = [];
                    this.disableChangeMonthAndWeekButton = false;
                } else {
                    notificationDangerCustom(`failed to created ${payloads.name}`);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoaderModalBox = false;
                await window.UIkit.modal('#add-or-edit-shift-modal-box').hide();
            }
        },
        async doEditShift() {
            try {
                this.buttonLoaderModalBox = true;
                const start_shift = this.formatTimeShift(this.selectedShift.start_shift);
                const end_shift = this.formatTimeShift(this.selectedShift.end_shift);

                const payloads = {
                    shift_id: this.selectedShift._id,
                    company_id: this.companyOffice.company_id,
                    company_office_id: this.selectedShift.company_office_id,
                    name: this.selectedShift.name,
                    start_shift,
                    end_shift,
                    color: this.selectedShift.color
                };

                const response = await this.editAstroDailyShift(payloads);
                if (response && response.status === 'OK') {
                    notificationSuccess(`${payloads.name} updated successfully`);
                    await this.setShifts();
                    await this.setWorkers();
                    this.resetSelectedShift();
                    this.dailyShiftAssign = [];
                    this.shiftChange = false;
                    this.selectedWorker = null;
                    this.recap = [];
                    this.disableChangeMonthAndWeekButton = false;
                } else {
                    notificationDangerCustom(`Failed to update ${payloads.name}`);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoaderModalBox = false;
                await window.UIkit.modal('#add-or-edit-shift-modal-box').hide();
            }
        },
        async doDeleteShift() {
            try {
                this.buttonLoaderModalBox = true;
                const payloads = { shift_id: this.selectedShift._id };
                const response = await this.deleteAstroDailyShift(payloads);
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.resetSelectedShift();
                    notificationSuccess('Shift deleted successfully');
                    await window.UIkit.modal('#confirmation-modal-box').hide();
                    await this.setShifts();
                    await this.setWorkers();
                } else {
                    notificationDangerCustom('Failed to delete shift');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoaderModalBox = false;
                await window.UIkit.modal('#confirmation-modal-box').hide();
            }
        },
        async doHandleConfirmationModalBox() {
            try {
                if (this.confirmationModalBoxData.actionType === 'deleteShift') {
                    await this.doDeleteShift();
                } else if (this.confirmationModalBoxData.actionType === 'importShiftTemplate') {
                    await this.doImportShiftTemplate();
                } else {
                    await window.UIkit.modal('#confirmation-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doAssignShiftMitra() {
            try {
                this.buttonLoaderAssignShiftMitra = true;
                const payloads = {
                    company_id: this.companyOffice.company_id,
                    company_office_id: this.companyOffice._id,
                    job_partner_id: this.selectedWorker._id,
                    user_id: this.selectedWorker.user_id,
                    daily_shifts: this.dailyShiftAssign
                };
                const response = await this.assignMitraShift(payloads);
                if (response === 'success') {
                    await this.setWorkers();
                    this.dailyShiftAssign = [];
                    this.shiftChange = false;
                    this.selectedWorker = null;
                    this.recap = [];
                    this.disableChangeMonthAndWeekButton = false;
                    notificationSuccess('Mitra assigned successfully');
                } else {
                    await this.setWorkers();
                    this.dailyShiftAssign = [];
                    this.shiftChange = false;
                    this.selectedWorker = null;
                    this.recap = [];
                    this.disableChangeMonthAndWeekButton = false;
                    notificationDangerCustom(response);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoaderAssignShiftMitra = false;
            }
        },
        async doDownloadShiftTemplate() {
            try {
                this.buttonLoaderModalBox = true;
                this.meta.month = this.reportFilter.monthValue;
                const days = moment().month(this.reportFilter.monthName).daysInMonth();
                const payloads = {
                    company_office_id: this.meta.company_office_id,
                    month: this.meta.month
                };
                const astroTemplateShift = await this.getAstroTemplateShift(payloads);

                const dataExcel = [];
                let obj = {
                    "City": this.cityName,
                    "Office": this.officeName
                };

                let dataShift = [];
                if (astroTemplateShift && astroTemplateShift.list_shift.length > 0) {
                    dataShift = [...astroTemplateShift.list_shift];
                    dataShift.push({ _id: null, name: 'OFF' });
                }

                if (dataShift.length > astroTemplateShift.result.length) {
                    for (let i = 0; i < dataShift.length; i++) {
                        obj["Shift ID"] = dataShift[i] && dataShift[i]._id ? dataShift[i]._id : null;
                        obj["Available Shift"] = dataShift[i] && dataShift[i].name ? dataShift[i].name : null;

                        if (i < astroTemplateShift.result.length) {
                            obj["User ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].user_id ? astroTemplateShift.result[i].user_id : null;
                            obj["Job Partner ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i]._id ? astroTemplateShift.result[i]._id : null;
                            obj["Employee ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].employee_id ? astroTemplateShift.result[i].employee_id : null;
                            obj["No. KTP"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].identity_number ? astroTemplateShift.result[i].identity_number : null;
                            obj["Name"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].fullname ? astroTemplateShift.result[i].fullname : null;
                            obj["Job Role"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].role ? astroTemplateShift.result[i].role : null;
                            obj["Month"] = this.reportFilter.monthName;

                            // Loop date base on current month date
                            const mitra = astroTemplateShift.result[i];
                            for (let i = 1; i <= this.days; i++) {
                                obj[`${this.meta.month}-${('0' + i).slice(-2)}`] = mitra.company_office_id === mitra.shiftPerDay[i]?.company_office_id ? mitra.shiftPerDay[i]?.shift_id?.name : null;
                            }
                        } else {
                            obj["User ID"] = null;
                            obj["Job Partner ID"] = null;
                            obj["Employee ID"] = null;
                            obj["No. KTP"] = null;
                            obj["Name"] = null;
                            obj["Job Role"] = null;
                            obj["Month"] = null;

                            // Loop date base on current month date
                            for (let i = 1; i <= days; i++) {
                                obj[`${this.meta.month}-${('0' + i).slice(-2)}`] = null;
                            }
                        }

                        dataExcel.push(obj);

                        obj = {
                            "City": null,
                            "Office": null
                        };
                    }
                } else if (astroTemplateShift.result.length > dataShift.length) {
                    for (let i = 0; i < astroTemplateShift.result.length; i++) {
                        if (i < dataShift.length) {
                            obj["Shift ID"] = dataShift[i] && dataShift[i]._id ? dataShift[i]._id : null;
                            obj["Available Shift"] = dataShift[i] && dataShift[i].name ? dataShift[i].name : null;
                        } else {
                            obj["Shift ID"] = null;
                            obj["Available Shift"] = null;
                        }

                        obj["User ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].user_id ? astroTemplateShift.result[i].user_id : null;
                        obj["Job Partner ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i]._id ? astroTemplateShift.result[i]._id : null;
                        obj["Employee ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].employee_id ? astroTemplateShift.result[i].employee_id : null;
                        obj["No. KTP"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].identity_number ? astroTemplateShift.result[i].identity_number : null;
                        obj["Name"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].fullname ? astroTemplateShift.result[i].fullname : null;
                        obj["Job Role"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].role ? astroTemplateShift.result[i].role : null;
                        obj["Month"] = this.reportFilter.monthName;

                        // Loop date base on current month date
                        const mitra = astroTemplateShift.result[i];
                        for (let i = 1; i <= days; i++) {
                            obj[`${this.meta.month}-${('0' + i).slice(-2)}`] = mitra.company_office_id ===  mitra.shiftPerDay[i]?.company_office_id ? mitra.shiftPerDay[i]?.shift_id?.name : null;
                        }

                        dataExcel.push(obj);

                        obj = {
                            "City": null,
                            "Office": null
                        };
                    }
                } else {
                    for (let i = 0; i < astroTemplateShift.result.length; i++) {
                        obj["Shift ID"] = dataShift[i] && dataShift[i]._id ? dataShift[i]._id : null;
                        obj["Available Shift"] = dataShift[i] && dataShift[i].name ? dataShift[i].name : null;
                        obj["User ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].user_id ? astroTemplateShift.result[i].user_id : null;
                        obj["Job Partner ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i]._id ? astroTemplateShift.result[i]._id : null;
                        obj["Employee ID"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].employee_id ? astroTemplateShift.result[i].employee_id : null;
                        obj["No. KTP"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].identity_number ? astroTemplateShift.result[i].identity_number : null;
                        obj["Name"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].fullname ? astroTemplateShift.result[i].fullname : null;
                        obj["Job Role"] = astroTemplateShift.result[i] && astroTemplateShift.result[i].role ? astroTemplateShift.result[i].role : null;
                        obj["Month"] = this.reportFilter.monthName;

                        // Loop date base on current month date
                        const mitra = astroTemplateShift.result[i];
                        for (let i = 1; i <= days; i++) {
                            obj[`${this.meta.month}-${('0' + i).slice(-2)}`] = mitra.company_office_id === mitra.shiftPerDay[i]?.company_office_id ? mitra.shiftPerDay[i]?.shift_id?.name : null;
                        }

                        dataExcel.push(obj);

                        obj = {
                            "City": null,
                            "Office": null
                        };
                    }
                }

                excelDownloader(dataExcel, `Shift_${this.cityName}_${this.officeName}_${this.meta.month}.xls`);
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoaderModalBox = false;
            }
        },
        async doImportShiftTemplate() {
            try {
                this.buttonLoaderModalBox = true;
                let data = new FormData();
                data.append('company_office_id', this.meta.company_office_id);
                data.append('month', this.reportFilter.monthValue);
                data.append('excel', this.fileAttachment);
                const response = await this.uploadShift(data);
                if (response && response.status === 'OK') {
                    if (response.result.rejected_data.length > 0) {
                        this.errorLogData = response.result;
                        this.filename_excel = this.fileAttachment.name;
                        await window.UIkit.modal('#failed-import-shift-template-modal-box', { stack: true }).show();
                    } else {
                        notificationSuccess('Shift template uploaded successfully');
                    }
                    this.fileAttachment = null;
                    this.dailyShiftAssign = [];
                    this.shiftChange = false;
                    this.selectedWorker = null;
                    this.recap = [];
                    await this.setWorkers();
                } else {
                    notificationDangerCustom('Failed to upload shift template!');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoaderModalBox = false;
                this.disableChangeMonthAndWeekButton = false;
                await window.UIkit.modal('#confirmation-modal-box').hide();
            }
        },
        async doDownloadShiftReport({ type, data }) {
            try {
                let payload = { month: this.reportFilter.monthValue };
                const days = moment().month(this.reportFilter.monthName).daysInMonth();
                let placeType = '';
                let placeName = '';

                if (type === 'city') {
                    placeType = 'City';
                    if (data) {
                        payload = {
                            ...payload,
                            domicile_city_id: data._id || null
                        };
                        placeName = data.name || 'All cities';
                    } else {
                        placeName = 'All cities';
                    }
                } else {
                    placeType = 'Office';
                    if (data) {
                        payload = {
                            ...payload,
                            company_office_id: data._id || null
                        };
                        placeName = data.name || 'All offices';
                    } else {
                        placeName = 'All offices';
                    }
                }

                const monthlyUserReport = await this.getAstroMonthlyShiftReport(payload);
                const dataExcel = [];
                monthlyUserReport.forEach(mitra => {
                    let obj = {
                        "Fullname": mitra.fullname,
                        "Job Role": mitra.role,
                        "Mitra ID": mitra.employee_id,
                        "No KTP": mitra.identity_number,
                    };

                    for (let i = 1; i <= days; i++) {
                        const shift_name = mitra.shiftPerDay[i]?.shift_id?.name;
                        obj[i+" "] = shift_name;
                    }

                    dataExcel.push(obj);
                });
                excelDownloader(dataExcel, `Shift_${placeType}_${placeName}_${this.reportFilter.monthName}.xls`);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDownloadRejectedData() {
            try {
                this.is_download_rejected_data_loading = true;
                const fileName = `(Rejected data) ${this.filename_excel}`;
                const shiftInfoList = this.errorLogData.shift_info_list;
                const rejectedList = this.errorLogData.rejected_data;
                const excelList = [];

                const len = Math.max(shiftInfoList.length, rejectedList.length);
                for (let i = 0; i < len; i++) {
                    const rejectedData = rejectedList[i]?.data ?? null;
                    if (shiftInfoList[i] && rejectedData) {
                        excelList.push({ ...shiftInfoList[i], ...rejectedData });
                    } else if (shiftInfoList[i]) {
                        excelList.push({ ...shiftInfoList[i] });
                    } else {
                        excelList.push({ ...rejectedData });
                    }
                }

                excelDownloader(excelList, fileName);
                notificationSuccess('Report successfully downloaded!');
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.is_download_rejected_data_loading = false;
            }
        },
        filterOffices() {
            this.formFilterShift.office = null;

            if (this.company_offices && this.company_offices.length > 0) {
                const offices = this.company_offices.filter((office) => {
                    if (
                        (office.domicile_city_id && office.domicile_city_id._id) &&
                        (this.formFilterShift.city && this.formFilterShift.city._id) &&
                        (office.domicile_city_id._id === this.formFilterShift.city._id)
                    ) {
                        return office;
                    }
                });

                this.offices = offices;
            }

            if (this.offices.length > 0) {
                this.offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        setCities() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });

                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        async setShifts() {
            try {
                const payloads = { company_office_id: this.meta.company_office_id };
                const response = await this.getAstroShiftList(payloads);
                this.shifts = response;
                this.setShiftOptions({ shifts: response });
            } catch (error) {
                notificationDanger(error);
            }
        },
        setMonth({ event }) {
            const selectedMonth = this.monthOptions.find((month) => month.name == event.target.value);
            const month = moment().year(this.reportYear).month(selectedMonth.value-1).format("YYYY-MM");
            this.resetReportFilter();
            this.fillReportFilter({
                monthName: selectedMonth.name,
                monthValue: month
            });
        },
        setShiftOptions({ shifts }) {
            const def = { _id: ' ', name: 'OFF' };
            this.shiftOptions = [def, ...shifts];
        },
        async setWorkers() {
            try {
                const response = await this.getAstroMitraList(this.meta);
                if (response && response.docs && response.totalDocs) {
                    this.workers.docs = response.docs;
                    this.workers.totalDocs = response.totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        getCurrentMonthDays() {
            this.monthName = this.updatedMonth.format("MMMM");
            this.monthNum = this.updatedMonth.format("MM");
            this.days = this.updatedMonth.daysInMonth();
        },
        getWeeklyDay() {
            const limit = 7;
            const days = [];
            for (let i = (limit * this.monthDate.page - 6); i <= limit * this.monthDate.page; i++) {
                if (i <= this.days) {
                    days.push(i);
                }
            }
            this.monthDate.days = days;
        },
        getReportSelectedMonth() {
            const currentMonth = this.monthOptions.find((month) => month.name == this.monthName);
            const month = moment().year(this.currentYear).month(currentMonth.value-1).format("YYYY-MM");
            this.resetReportFilter();
            this.fillReportFilter({
                monthName: currentMonth.name,
                monthValue: month
            });
        },
        selectWorker({ worker }) {
            this.shiftChange = false;
            if (!this.selectedWorker) {
                this.selectedWorker = worker;
            } else if (this.selectedWorker._id == worker._id) {
                this.selectedWorker = null;
            } else {
                this.selectedWorker = worker;
            }

            if (this.selectedWorker == null) {
                this.disableChangeMonthAndWeekButton = false;
            } else {
                this.disableChangeMonthAndWeekButton = true;
            }
        },
        selected({ workerId }) {
            return this.selectedWorker?._id === workerId;
        },
        checkAssigned({ workerId, day }) {
            if (this.workers.docs.length > 0) {
                const ym = this.updatedMonth.format("YYYY-MM");
                const date = moment(`${ym}-${day}`).format("YYYY-MM-DD");
                const mitra = this.workers.docs.find((data) => data._id == workerId);

                if (mitra) {
                    const assigned = mitra.daily_shift.find((shift) => moment(shift.date_shift).format("YYYY-MM-DD") == date);
                    if (assigned && assigned.shift_id && assigned.shift_id.name) {
                        const validShift = this.shiftOptions.find((shift) => shift.name.toUpperCase() == assigned.shift_id.name.toUpperCase());
                        return validShift ? assigned.shift_id : "OFF";
                    } else {
                        return "OFF";
                    }
                }
            } else {
                return '-';
            }
        },
        async viewShiftRecapByDate({ day }) {
            try {
                const ym = this.updatedMonth.format("YYYY-MM");
                const date = moment(`${ym}-${day}`).format("YYYY-MM-DD");

                const payloads = {
                    company_office_id: this.companyOffice._id,
                    date
                };

                let response = await this.getAstroShiftRecapDate(payloads);

                const data = {
                    day: `${day}-${this.monthName}`,
                    data: response
                };

                this.recap.push(data);
            } catch (error) {
                notificationDanger(error);
            }
        },
        hideRecapPerDay({ day }) {
            this.recap = this.recap.filter((recap) => {
                return recap.day != `${day}-${this.monthName}`;
            });
        },
        recapPerDay({ day }) {
            const data = this.recap.find((data) => data.day == `${day}-${this.monthName}`);
            if (data) {
                return data;
            }
        },
        async viewShiftRecapByUser({ workerId }) {
            try {
                const ym = this.updatedMonth.format("YYYY-MM");
                const startDate = moment(ym+'-01').format("YYYY-MM-DD");
                const endDate = moment(ym+'-'+this.days).format("YYYY-MM-DD");

                const payloads = {
                    company_office_id: this.companyOffice._id,
                    job_partner_id: workerId,
                    from_date: startDate,
                    to_date: endDate
                };

                let result = null;
                result = await this.getAstroShiftRecapUser(payloads);

                let totalShift = 0;
                for (const res of result) {
                    totalShift += res.count;
                }

                const offCount = this.days - totalShift;
                if (offCount > 0) {
                    const off = {
                        name: "OFF",
                        count: offCount
                    };
                    result = [...result, off];
                }

                const data = {
                    worker: `${workerId}-${this.monthNum}`,
                    data: result
                };

                this.recap.push(data);
            } catch (error) {
                notificationDanger(error);
            }
        },
        recapPerUser({ workerId }) {
            const data = this.recap.find((data) => data.worker == `${workerId}-${this.monthNum}`);
            if (data) return data;
        },
        hideRecapPerUser({ workerId }) {
            this.recap = this.recap.filter((recap) => recap.worker != `${workerId}-${this.monthNum}`);
        },
        onChangeOptions({ day, event }) {
            this.shiftChange = true;

            const ym = this.updatedMonth.format("YYYY-MM");
            const date = moment(`${ym}-${day}`).format("YYYY-MM-DD");
            const index = this.dailyShiftAssign.findIndex(item => item.date_shift == date);

            if (index < 0) {
                this.date_shift = day;
                const item = {
                    date_shift: date,
                    shift_id: event.target.value
                };
                this.dailyShiftAssign.push(item);
            } else {
                this.dailyShiftAssign[index].shift_id = event.target.value;
            }
        },
        changeMonth(value) {
            // this.updatedMonth = this.updatedMonth.year(new Date().getFullYear().toFixed());
            this.updatedMonth = this.updatedMonth.add(value, 'month');
            this.reportYear = this.currentYear = new Date(this.updatedMonth).getFullYear();
            this.getCurrentMonthDays();
            this.monthDate.page = 1;
            this.getWeeklyDay();
            this.getReportSelectedMonth();
        },
        changeWeek(value) {
            this.monthDate.page += value;
            const maxPage = Math.ceil(this.days/7);
            if (this.monthDate.page < 1) this.monthDate.page = 1;
            if (this.monthDate.page > maxPage) this.monthDate.page = maxPage;
            this.getWeeklyDay();
        },
        triggerUploadFile() {
            this.$refs.upload.click();
        },
        fileUpload({ event }) {
            if (event.target.files && event.target.files.length > 0) {
                this.fileAttachment = event.target.files[0];
            }
        },
        fillSelectedShift({ id, companyOfficeId, name, startShift, endShift, color, status }) {
            const start = new Date().setHours(startShift.hour, startShift.minute, 0, 0);
            const end = new Date().setHours(endShift.hour, endShift.minute, 0, 0);

            this.selectedShift._id = id;
            this.selectedShift.company_office_id = companyOfficeId;
            this.selectedShift.name = name;
            this.selectedShift.start_shift = new Date(start).toISOString();
            this.selectedShift.end_shift = new Date(end).toISOString();
            this.selectedShift.color = color;
            this.selectedShift.status = status;
        },
        fillConfirmationModalBoxData({ title, body, actionType }) {
            this.confirmationModalBoxData.title = title;
            this.confirmationModalBoxData.body = body;
            this.confirmationModalBoxData.actionType = actionType;
        },
        fillMeta({ companyOfficeId }) {
            this.meta.company_office_id = companyOfficeId;
        },
        fillReportFilter({ monthName, monthValue }) {
            this.reportFilter.monthName = monthName;
            this.reportFilter.monthValue = monthValue;
        },
        resetFormFilterShift() {
            this.formFilterShift.city = null;
            this.formFilterShift.office = null;
        },
        resetSelectedShift() {
            this.selectedShift._id = null;
            this.selectedShift.company_office_id = '';
            this.selectedShift.name = '';
            this.selectedShift.start_shift = null;
            this.selectedShift.end_shift = null;
            this.selectedShift.color = null;
            this.selectedShift.status = 0;
        },
        resetConfirmationModalBoxData() {
            this.confirmationModalBoxData.title = 'Are you sure ?';
            this.confirmationModalBoxData.body = 'Are you sure want to proceed ?';
            this.confirmationModalBoxData.actionType = null;
        },
        resetMeta() {
            this.meta.company_office_id = null;
            this.meta.page = 1;
            this.meta.limit = 10;
        },
        resetReportFilter() {
            this.reportFilter.monthName = null;
            this.reportFilter.monthValue = null;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        formatTimeShift(value) {
            if (value == '') {
                return null;
            } else {
                return {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.color-circle-sm {
    width: 10px;
    height: 10px;
    margin: 0 5px 0 5px;
}
.color-circle {
    width: 30px;
    height: 30px;
}
.selected-circle {
    border: 2px solid black;
}
.selected-row {
    background-color: rgba(2, 117, 216, 0.1);
}
</style>
