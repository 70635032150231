<template>
    <tbody>
        <tr>
            <td :colspan="colspan" class="uk-text-center">{{ emptyText }}</td>
        </tr>
    </tbody>
</template>

<script>
export default {
    props: {
        colspan: {
            required: true,
            type: Number,
        },
        emptyText: {
            type: String,
            default: 'data not found'
        },
    },
};
</script>
