import api from '@/utils/api';
import { JOB_PREFERENCE } from '@/utils/api-url';
import { notificationDanger } from '@/utils/notification';
import Vue from "vue";

const namespaced = true;
const state = {
    job_preferences: [],
};

const mutations = {
    "SET_JOB_PREFERENCE": (state, payload) => {
        Vue.set(state, 'job_preferences', payload);
    }
};

const actions = {
    async getJobPreference(context, data) {
        try {
            const response = await api.apiGetAuth(JOB_PREFERENCE.LIST, data);
            context.commit('SET_JOB_PREFERENCE', response.data.result.docs);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    job_preferences: state => {
        return state.job_preferences;
    },
};

export default {
    state,
    mutations,
    namespaced,
    actions,
    getters
};
