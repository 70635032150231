<template>
    <div class="uk-container uk-container-expand card-scrollable uk-padding-small">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <!-- Filter -->
            <template v-if="$can('READ', 'worker-task-assignment') && meta.company_office_id === null">
                <button 
                    type="button" 
                    class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default" 
                    @click="showFilterModalBox"
                >
                    <span><img :src="`${images}/shipper-storage.png`" alt="Filter icon" width="20"></span>
                    <span class="uk-margin-small-left uk-margin-small-top uk-text-warning">Select Location Filter</span>
                </button>
            </template>
            <template v-if="$can('READ', 'worker-task-assignment') && meta.company_office_id !== null">
                <div class="uk-flex uk-flex-middle uk-flex-between">
                    <button 
                        type="button" 
                        class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default" 
                        @click="showFilterModalBox"
                    >
                        <span><img :src="`${images}/shipper-location.png`" alt="Location icon" width="20"></span>
                        <span class="uk-margin-small-left uk-text-warning">{{ cityName || '-' }}</span>
                    </button>
                    <button 
                        v-if="$can('WRITE', 'worker-task-assignment')"
                        class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default"
                        @click="showCreateOrEditTaskModalBox({ 
                            actionType: 'create-task', 
                            id: null, 
                            companyOfficeId: meta.company_office_id, 
                            roleId: null, 
                            name: null 
                        })" 
                    >
                        <span><img :src="`${images}/shift-add.png`" alt="Add task icon" width="25"></span>
                        <span class="uk-margin-small-left uk-text-success">Tambah Task</span>
                    </button>
                </div>
                <div class="uk-margin-small-top">
                    <button 
                        type="button" 
                        class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default" 
                        @click="showFilterModalBox"
                    >
                        <span><img :src="`${images}/shipper-storage.png`" alt="Storage icon" width="20"></span>
                        <span class="uk-margin-small-left uk-text-warning">{{ officeName || '-' }}</span>
                    </button>
                    <button 
                        type="button" 
                        class="uk-button uk-button-link uk-text-muted uk-text-default uk-margin-small-top" 
                        @click="doResetFilter"
                    >
                        <span style="text-decoration: underline;">Reset Location Filter</span>
                    </button>
                </div>
            </template>
            <template v-if="$can('READ', 'worker-task-assignment')">
                <div class="uk-margin-small-top">
                    <select id="roleFilter" class="uk-select uk-width-1-2" v-model="meta.role_id">
                        <option :value="null" selected>All Role</option>
                        <option 
                            v-for="(role, index) in roleOptions"
                            :key="index"
                            :value="role._id"
                        >{{ role.name }}</option>
                    </select>
                </div>
            </template>
            <!-- End filter -->

            <!-- Task list -->
            <div v-if="$can('READ', 'worker-task-assignment')" class="uk-card uk-card-default uk-margin-top">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-small uk-table-middle uk-table-striped">
                        <thead>
                            <tr>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle uk-width-small">No</th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Task Name</th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Role</th>
                                <th 
                                    v-if="$can('EDIT', 'worker-task-assignment')"
                                    class="uk-text-nowrap uk-text-center uk-text-middle uk-width-small"
                                >Action</th>
                            </tr>
                        </thead>
                        <loading-table v-if="loadingTable" :colspan="4"></loading-table>
                        <template v-else>
                            <tbody v-if="taskData.docs.length > 0">
                                <tr
                                    v-for="(task, index) in taskData.docs"
                                    :key="index"
                                >
                                    <td class="uk-text-nowrap uk-text-center uk-text-middle uk-width-small">
                                        {{ autoIncrementNumberPagination({ 
                                            pagingCounter: taskData.pagingCounter,
                                            index 
                                        }) }}
                                    </td>
                                    <td class="uk-text-nowrap uk-text-center uk-text-middle">{{ task.name || '-' }}</td>
                                    <td class="uk-text-nowrap uk-text-center uk-text-middle">{{ task.role && task.role.name ? task.role.name : '-' }}</td>
                                    <td v-if="$can('EDIT', 'worker-task-assignment')" class="uk-text-nowrap uk-text-center uk-text-middle uk-width-small">
                                        <button 
                                            type="button" 
                                            class="uk-button uk-button-primary uk-border-rounded" 
                                            @click="showCreateOrEditTaskModalBox({ 
                                                actionType: 'edit-task', 
                                                id: task._id || null, 
                                                companyOfficeId: task.company_office_id || null, 
                                                roleId: task.role && task.role._id ? task.role._id : null, 
                                                name: task.name || null 
                                            })" 
                                        >Edit</button>
                                    </td>
                                </tr>
                            </tbody>
                            <empty-table 
                                v-else 
                                :colspan="4"
                            ></empty-table>
                        </template>
                    </table>
                </div>
                <pagination
                    :total-data="taskData.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End task list -->

            <!-- Handler when user not allowed -->
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>You are not authorized for read data this page</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler when user not allowed -->

            <!-- Filter modal box -->
            <div id="filter-modal-box" class="filter-modal-box uk-flex-top" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">Input property for which you want to see details</h2>
                    </div>
                    <form @submit.prevent="doFilterTask">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="city" class="uk-form-label">City :</label>
                                    <select 
                                        id="city" 
                                        class="uk-select uk-border-rounded" 
                                        v-model="formFilter.domicile_city_id"
                                        data-vv-name="domicile_city_id"
                                        data-vv-scope="formFilter"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                        <option :value="null" selected>Choose City</option>
                                        <option 
                                            v-for="(city, index) in cityOptions"
                                            :key="index"
                                            :value="city._id"
                                        >{{ city.name || '-' }}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formFilter.domicile_city_id')">
                                        {{ errors.first('formFilter.domicile_city_id') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="office" class="uk-form-label">Property's Name :</label>
                                    <select 
                                        id="office" 
                                        class="uk-select uk-border-rounded"
                                        :disabled="!formFilter.domicile_city_id"
                                        v-model="formFilter.company_office_id"
                                        data-vv-name="company_office_id"
                                        data-vv-scope="formFilter"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                        <option :value="null" selected>Choose Property</option>
                                        <option 
                                            v-for="(office, index) in officeOptions"
                                            :key="index"
                                            :value="office._id"
                                        >{{ office.name }}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formFilter.company_office_id')">
                                        {{ errors.first('formFilter.company_office_id') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="loadingButtonModalBox"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else
                                type="submit" 
                                class="uk-button"
                                :class="[disabledApplyFilterButton ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledApplyFilterButton"
                            >Apply</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End filter modal box -->

            <!-- Create or edit task modal box -->
            <div id="create-or-edit-task-modal-box" class="create-or-edit-task-modal-box uk-flex-top" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">
                            {{ actionType === 'create-task' ? 'Create Task' : 'Edit Task' }}
                        </h2>
                    </div>
                    <form @submit.prevent="modalBoxAction">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="role" class="uk-form-label">Select Role</label>
                                    <select 
                                        id="role" 
                                        class="uk-select uk-border-rounded" 
                                        v-model="fromCreateOrEditTask.role_id" 
                                        data-vv-name="role_id"
                                        data-vv-scope="fromCreateOrEditTask"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                        <option :value="null" selected>Select Role</option>
                                        <option 
                                            v-for="(role, index) in roleOptions"
                                            :key="index"
                                            :value="role._id"
                                        >{{ role.name }}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('fromCreateOrEditTask.role_id')">
                                        {{ errors.first('fromCreateOrEditTask.role_id') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="taskName" class="uk-form-label">Task Name</label>
                                    <input 
                                        id="taskName" 
                                        type="text" 
                                        class="uk-input uk-border-rounded" 
                                        v-model="fromCreateOrEditTask.name" 
                                        data-vv-name="name"
                                        data-vv-scope="fromCreateOrEditTask"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('fromCreateOrEditTask.name')">
                                        {{ errors.first('fromCreateOrEditTask.name') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="loadingButtonModalBox"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else 
                                type="submit" 
                                class="uk-button"
                                :class="[disabledCreateOrEditTaskButton ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledCreateOrEditTaskButton"
                            >Apply</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End create or edit task modal box -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import { 
    notificationSuccess,
    notificationDanger, 
    notificationDangerCustom, 
} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: 'TaskAssignment',
    components: {
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading')
    },
    data() {
        return {
            isLoading: false,
            loadingTable: false,
            loadingButtonModalBox: false,
            images: PREFIX_IMAGE,
            cityOptions: [],
            officeOptions: [],
            roleOptions: [],
            formFilter: {
                domicile_city_id: null,
                company_office_id: null
            },
            actionType: 'create-task',
            fromCreateOrEditTask: {
                id: null,
                company_office_id: null,
                role_id: null,
                name: null
            },  
            meta: {
                company_office_id: null,
                role_id: null,
                limit: 10,
                page: 1
            },
            taskData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0
            }
        };
    },
    watch: {
        'formFilter.domicile_city_id'(val) {
            if (val) {
                this.setOfficeOptions({ domicileCityId: val });
            }
        },
        async meta() {
            try {
                this.loadingTable = true;
                await this.getTaskData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingTable = false;
            }
        },
        async 'meta.role_id'() {
            try {
                this.loadingTable = true;
                await this.getTaskData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingTable = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            roles: 'option_data/roles'
        }),
        disabledApplyFilterButton() {
            let disabled = true;

            if (
                this.formFilter.domicile_city_id === null ||
                this.formFilter.company_office_id === null ||
                this.$validator.errors.any('formFilter')
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        cityName() {
            let name = null;

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.meta.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findCity && findCity.domicile_city_id && findCity.domicile_city_id.name) {
                    name = findCity.domicile_city_id.name;
                }
            }

            return name;
        },
        officeName() {
            let name = null;

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findOffice = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.meta.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findOffice && findOffice.name) {
                    name = findOffice.name;
                }
            }

            return name;
        },
        disabledCreateOrEditTaskButton() {
            let disabled = true;

            if (this.actionType === 'create-task') {
                if (
                    this.fromCreateOrEditTask.company_office_id === null ||
                    this.fromCreateOrEditTask.role_id === null ||
                    this.fromCreateOrEditTask.name === null ||
                    this.$validator.errors.any('fromCreateOrEditTask')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.actionType === 'edit-task') {
                if (
                    this.fromCreateOrEditTask.id === null ||
                    this.fromCreateOrEditTask.company_office_id === null ||
                    this.fromCreateOrEditTask.role_id === null ||
                    this.fromCreateOrEditTask.name === null ||
                    this.$validator.errors.any('fromCreateOrEditTask')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else {
                disabled = true;
            }

            return disabled;
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
            await Promise.all([
                this.getCompanyOffice(),
                this.setRoleOptions(),
                this.getTaskData()
            ]);
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.filter-modal-box')) window.UIkit.modal('.filter-modal-box').$destroy(true);
        if (window.UIkit.modal('.create-or-edit-task-modal-box')) window.UIkit.modal('.create-or-edit-task-modal-box').$destroy(true);
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getTaskAssignment: 'task/getTaskAssignment',
            getRole: 'option_data/getRoles',
            addTask: 'task/addTaskAssignment',
            updateTask: 'task/updateTaskAssignment'
        }),
        async showFilterModalBox() {
            try {
                this.setCityOptions();
                await window.UIkit.modal('#filter-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCreateOrEditTaskModalBox({ actionType, id, companyOfficeId, roleId, name }) {
            try {
                this.actionType = actionType;
                this.resetFormCreateOrEditTask();
                this.fillFormCreateOrEditTask({ id, companyOfficeId, roleId, name });
                await window.UIkit.modal('#create-or-edit-task-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async modalBoxAction() {
            try {
                switch (this.actionType) {
                case 'create-task':
                    await this.doCreateTask();
                    break;
                case 'edit-task':
                    await this.doEditTask();
                    break;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doFilterTask() {
            try {
                const validate = await this.$validator.validateAll('formFilter');
                if (!validate || this.$validator.errors.any('formFilter')) return;

                this.loadingButtonModalBox = true;
                this.meta.company_office_id = this.formFilter.company_office_id;
                this.loadingTable = true;
                await this.getTaskData();
                await window.UIkit.modal('#filter-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
                this.loadingTable = false;
            }
        },
        async doResetFilter() {
            try {
                this.resetFormFilter();
                this.meta.company_office_id = null;
                this.loadingTable = true;
                await this.getTaskData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingTable = false;
            }
        },
        async doCreateTask() {
            try {
                const validate = await this.$validator.validateAll('fromCreateOrEditTask');
                if (!validate || this.$validator.errors.any('fromCreateOrEditTask')) return;

                this.loadingButtonModalBox = true;
                this.fromCreateOrEditTask.company_office_id = this.meta.company_office_id;
                delete this.fromCreateOrEditTask.id;
                const response = await this.addTask(this.fromCreateOrEditTask);
                if (response && response.status === 'OK' && response.result === 'success') {
                    notificationSuccess('Task created successfully');
                    this.resetFormCreateOrEditTask();
                    await window.UIkit.modal('#create-or-edit-task-modal-box').hide();
                    this.loadingTable = true;
                    await this.getTaskData();
                } else {
                    notificationDangerCustom('Task failed to create');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
                this.loadingTable = false;
            }
        },
        async doEditTask() {
            try {
                const validate = await this.$validator.validateAll('fromCreateOrEditTask');
                if (!validate || this.$validator.errors.any('fromCreateOrEditTask')) return;

                this.loadingButtonModalBox = true;
                const response = await this.updateTask(this.fromCreateOrEditTask);
                if (response && response.status === 'OK' && response.result === 'success') {
                    notificationSuccess('Task updated successfully');
                    this.resetFormCreateOrEditTask();
                    await window.UIkit.modal('#create-or-edit-task-modal-box').hide();
                    this.loadingTable = true;
                    await this.getTaskData();
                } else {
                    notificationDangerCustom('Task failed to update');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
                this.loadingTable = false;
            }
        },
        setCityOptions() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cityOptions.find((data) => {
                        if (
                            (office.domicile_city_id && office.domicile_city_id._id) && 
                            (data._id === office.domicile_city_id._id)
                        ) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cityOptions.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cityOptions.length > 0) {
                this.cityOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        setOfficeOptions({ domicileCityId }) {
            if ((domicileCityId) && (this.company_offices && this.company_offices.length > 0)) {
                const offices = this.company_offices.filter((office) => {
                    if (
                        (office && office.domicile_city_id && office.domicile_city_id._id) &&
                        (office.domicile_city_id._id === domicileCityId)
                    ) {
                        return office;
                    }
                });

                this.officeOptions = offices;
            }

            if (this.officeOptions.length > 0) {
                this.officeOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        async setRoleOptions() {
            try {
                await this.getRole();
                this.roleOptions = this.roles;

                if (this.roleOptions.length > 0) {
                    this.roleOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        fillFormCreateOrEditTask({ id, companyOfficeId, roleId, name }) {
            this.fromCreateOrEditTask.id = id;
            this.fromCreateOrEditTask.company_office_id = companyOfficeId;
            this.fromCreateOrEditTask.role_id = roleId;
            this.fromCreateOrEditTask.name = name;
        },
        resetFormFilter() {
            this.formFilter.domicile_city_id = null;
            this.formFilter.company_office_id = null;
            this.$validator.reset();
        },
        resetFormCreateOrEditTask() {
            this.fromCreateOrEditTask.id = null;
            this.fromCreateOrEditTask.company_office_id = null;
            this.fromCreateOrEditTask.role_id = null;
            this.fromCreateOrEditTask.name = null;
            this.$validator.reset();
        },
        async getTaskData() {
            try {
                const response = await this.getTaskAssignment(this.meta);
                if ((response) && (response.docs && response.totalDocs && response.pagingCounter)) {
                    this.taskData.docs = response.docs;
                    this.taskData.totalDocs = response.totalDocs;
                    this.taskData.pagingCounter = response.pagingCounter;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
    }
};
</script>

<style scoped>
    thead {
        background: #0ABAB5;
    }

    th {
        color: #FFFFFF;
    }
</style>
