<template>
    <section>
        <div id="select-wa-template-modal" class="uk-flex-top select-wa-template-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
                <div class="uk-modal-header">
                    <div v-if="isLoading" class="uk-padding-small"></div>
                    <h3 v-else-if="isTemplateSelected">Are you sure want to send this template?</h3>
                    <h3 v-else-if="templates.length > 0">Select WhatsApp Template</h3>
                    <div v-else class="uk-flex uk-flex-middle">
                        <img :src="`${images}/job_applicant/alert-red-outline.svg`" alt="Icon" class="uk-margin-small-right" />
                        <h3 class="uk-margin-remove">No WhatsApp Template Generated</h3>
                    </div>
                </div>
                <div class="uk-modal-body text-black">
                    <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle uk-height-small">
                        <div uk-spinner="ratio: 1"></div>
                    </div>
                    <div v-else-if="isTemplateSelected" class="uk-overflow-hidden">
                        <table class="uk-table uk-table-small">
                            <tbody>
                                <tr>
                                    <td class="uk-padding-remove uk-width-1-4">To </td>
                                    <td class="uk-padding-remove-vertical uk-text-right">:</td>
                                    <td class="uk-padding-remove uk-text-600 uk-table-expand">{{ applicant.user.fullname }}</td>
                                </tr>
                                <tr>
                                    <td class="uk-padding-remove uk-width-1-4">Phone Number </td>
                                    <td class="uk-padding-remove-vertical uk-text-right">:</td>
                                    <td class="uk-padding-remove uk-text-600 uk-table-expand uk-text-break-word">{{ applicant.user.phone_number }}</td>
                                </tr>
                                <tr>
                                    <td class="uk-padding-remove uk-width-1-4">Template Title </td>
                                    <td class="uk-padding-remove-vertical uk-text-right">:</td>
                                    <td class="uk-padding-remove uk-text-600 uk-table-expand">{{ selectedTemplate.title }}</td>
                                </tr>
                                <tr>
                                    <td class="uk-padding-remove uk-width-1-4">Template Message </td>
                                    <td class="uk-padding-remove-vertical uk-text-right">:</td>
                                    <td class="uk-padding-remove uk-text-600 uk-table-expand uk-text-break-word">
                                        <div class="pre-wrap">{{ previewMessage(selectedTemplate.message) }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else-if="templates.length > 0" class="uk-flex uk-flex-column">
                        <label v-for="template in templates" :key="template._id">
                            <input class="uk-radio" type="radio" :value="template" v-model="selectedTemplate">
                            <span class="uk-margin-small-left">{{ template.title }}</span>
                        </label>
                    </div>
                    <div v-else>
                        There is no WhatsApp templates have been generated yet. <br />
                        Please visit MABES to set them first by clicking the
                        <span class="uk-text-600">'Create Template'</span> button.
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right uk-text-500">
                    <button
                        v-if="isLoading"
                        class="uk-button uk-button-default uk-width-1-3"
                        disabled
                    >
                        <span><div uk-spinner="ratio: 0.5"></div></span>
                        <span class="uk-margin-small-left">Loading...</span>
                    </button>
                    <div v-else-if="templates.length > 0" class="uk-flex uk-flex-row-reverse uk-flex-between">
                        <button
                            class="uk-button uk-width-1-3"
                            :class="[!selectedTemplate ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="!selectedTemplate"
                            @click="handleConfirmSelection"
                        >
                            Send WhatsApp
                        </button>
                        <button
                            v-if="isTemplateSelected && !isLoading"
                            class="uk-button uk-modal-close uk-width-1-3 cancel-button"
                            @click="hideModal"
                        >
                            Cancel
                        </button>
                    </div>
                    <button v-else class="uk-button uk-button-primary" type="button" @click="handleCreate">
                        Create Template
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import { notificationDanger } from '@/utils/notification';
import phoneNumber from "@/utils/phone-number";

export default {
    props: {
        applicant: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: true,
            templates: [],
            selectedTemplate: null,
            isTemplateSelected: false
        };
    },
    async mounted() {
        await this.getTemplates();
        window.UIkit.modal('#select-wa-template-modal').show();
    },
    methods: {
        ...mapActions({
            getWhatsAppTemplates: 'applicant/getWhatsAppTemplates',
        }),
        hideModal() {
            window.UIkit.modal('#select-wa-template-modal').$destroy(true);
            this.$emit("hideModal");
        },
        async getTemplates() {
            try {
                const response = await this.getWhatsAppTemplates();
                if (response && response.status === 'OK') {
                    this.templates = response.result;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async handleConfirmSelection() {
            if (this.isTemplateSelected) {
                this.sendTemplate();
            } else {
                this.isLoading = true;
                setTimeout(() => {
                    this.isTemplateSelected = true;
                    this.isLoading = false;
                }, 500);
            }
        },
        sendTemplate() {
            window.open(`https://api.whatsapp.com/send/?phone=${phoneNumber(this.applicant.user.phone_number)}&text=${this.selectedTemplate.message}&type=phone_number&app_absent=0`);
        },
        handleCreate() {
            if (process.env.NODE_ENV == "production") {
                window.open("https://markasbesar.myrobin.id/admin/applicant/whatsapp-template");
            } else {
                window.open("https://pre-markasbesar.myrobin.tech/admin/applicant/whatsapp-template");
            }
        },
        previewMessage(message) {
            message = decodeURIComponent(message);
            if (message.length < 121) return message;
            return message.substring(0, 121) + '...';
        }
    }
};
</script>

<style scoped>
.uk-text-break-word {
    word-break: break-word;
}

.cancel-button {
    border: 1px solid #EF5350;
    color: #EF5350;
}

.text-black {
    color: #000;
}

.pre-wrap {
    white-space: pre-wrap;
}
</style>
