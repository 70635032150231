<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-flex-top">
            <p class="uk-text-large uk-text-bold">Attendance Method Settings</p>
            <button @click="closeCurrentSetting()">
                <img :src="`${images}/cross.svg`" alt="close icon" width="25" heigth="25" style="cursor: pointer;">
            </button>
        </div>
        <div class="uk-margin">
            <!-- <form @change="changeAttendanceSetting($event)"> -->
            <form>
                <p>Select Attendance Method to Configure</p>
                <select
                    name="attendance-method"
                    id="attendance-method"
                    class="uk-select uk-text-small uk-form-width-large"
                >
                    <option value="self-scan" selected>Self-scan</option>
                </select>
            </form>
        </div>

        <!-- start table body -->
        <div v-show="setting_list" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="">Attendance Method Type</th>
                            <th class="uk-table-expand">Available Settings</th>
                            <th v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-operation','admin-client'])" class="">Action</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Self-scan</td>
                        <td>Set the same QR Code for all offices and show QR code in Supervisor app, set QR refresh hour</td>
                        <td class="uk-flex" style="gap: 10px">
                            <button
                                v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-operation','admin-client'])"
                                class="uk-button uk-button-primary uk-border-rounded"
                                @click="editSetting('self-scan')"
                            >Edit</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <!-- settings section -->
        <div v-show="setting_edit == 'self-scan'" class="uk-margin">
            <div v-show="isLoading" uk-spinner class="uk-position-center" :ratio="2"></div>
            <div v-show=!isLoading>
                <p style="font-size: 16px">Settings for self-scan attendance method</p>
                <form class="uk-form-horizontal" @submit.prevent="saveSetting('self-scan')">
                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label>
                            <input
                                v-model="data.set_spv_qr"
                                type="checkbox"
                                class="uk-checkbox"
                            >
                            Set the same QR code for all offices and show QR code in Supervisor app
                        </label>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-horizontal-text">Refresh QR code at</label>
                        <div class="uk-inline uk-form-controls">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: clock"></span>
                            <datetime
                                :input-class="{'uk-input': data.set_spv_qr}"
                                id="form-horizontal-text"
                                type="time"
                                name="QR Refresh Hour"
                                v-model="data.qr_refresh_hour"
                                :disabled="!data.set_spv_qr"
                                :placeholder="formatHourMinute(data.qr_refresh_hour)"
                                @input="adjustQrRefreshHour"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-text-right">
                        <button
                            v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-operation','admin-client'])"
                            class="uk-button uk-margin-small-left"
                            :class="[submitLoad ? 'uk-button-default' : 'uk-button-primary']"
                            type="submit"
                            :disabled="submitLoad"
                        >
                            <span uk-spinner v-if="submitLoad"></span>
                            <span v-else>Save</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import { notificationSuccess, notificationDanger } from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                limit: 10,
                page: 1,
                category: '',
                sort_by: ''
            },
            images: PREFIX_IMAGE,
            setting_list: true,
            setting_edit: null,
            data: {
                set_spv_qr: false,
                qr_refresh_hour: null
            },
            pageLoading: false,
            isLoading: false,
            submitLoad: false
        };
    },
    components: {
        Datetime,
    },
    watch: {
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    methods: {
        ...mapActions({
            getAttendanceSelfScanSetting: 'setting/getAttendanceSelfScanSetting',
            setAttendanceSelfScanSetting: 'setting/setAttendanceSelfScanSetting',
        }),
        async editSetting(type) {
            this.isLoading = true;
            if (type == 'self-scan') {
                const result = await this.getAttendanceSelfScanSetting();
                this.data.set_spv_qr = result.is_qr_spv;
                this.data.qr_refresh_hour = result.qr_refresh_hour;
                this.setting_list = false;
                this.setting_edit = type;
            }
            this.isLoading = false;
        },
        changeAttendanceSetting(e) {
            this.setting_list = false;
            this.setting_edit = e.target.value;
        },
        async saveSetting(type) {
            this.submitLoad = true;
            if(this.setting_edit == type) {
                const payload = {
                    set_spv_qr: this.data.set_spv_qr,
                    qr_refresh_hour: this.data.qr_refresh_hour
                };
                const res = await this.setAttendanceSelfScanSetting(payload);
                if (res && res.status == 'OK') {
                    this.setting_list = true;
                    this.setting_edit = null;
                    this.submitLoad = false;
                    return notificationSuccess(`Setting ${type} berhasil disimpan.`);
                } else {
                    this.submitLoad = false;
                    return notificationDanger(`Ada masalah dengan sistem`);
                }
            } else {
                this.submitLoad = false;
                return notificationDanger(`Ada masalah dengan sistem`);
            }
        },
        adjustQrRefreshHour(value) {
            if (value == '') {
                return this.data.qr_refresh_hour = null;
            } else {
                return this.data.qr_refresh_hour = {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        formatHourMinute(data) {
            if (!data) {
                return '-';
            }
            return formatter.hourMinuteOnly(data);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        closeCurrentSetting() {
            if(this.setting_list) {
                this.$router.go(-1);
            } else {
                this.data.set_spv_qr = false;
                this.data.qr_refresh_hour = null;
                this.setting_list = true;
                this.setting_edit = null;
            }
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th, .white-text {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
</style>
