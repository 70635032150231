import api from '@/utils/api';
import { COMPANY_LEAVE } from '@/utils/api-url';
import { notificationDanger } from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getLeaveMitraList(context, data) {
        try {
            const response = await api.apiGetAuth(COMPANY_LEAVE.LIST_MITRA, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraData(context, id) {
        try {
            const response = await api.apiGetAuth(COMPANY_LEAVE.DETAIL_MITRA(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getLeaveRequestPerMitra(context, data) {
        try {
            const job_partner_id  = data.id;
            delete data.id;
            const response = await api.apiGetAuth(COMPANY_LEAVE.LIST_LEAVE_PER_MITRA(job_partner_id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async approveRejectLeave(context, data) {
        try {
            const request_id  = data.id;
            delete data.id;
            const response = await api.apiPatchAuth(COMPANY_LEAVE.APPROVE_REJECT(request_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteRequestLeave(context, id) {
        try {
            const response = await api.apiDeleteAuth(COMPANY_LEAVE.APPROVE_REJECT(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
