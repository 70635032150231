<template>
    <div>
        <div class="uk-flex uk-flex-between myrobin-background-white uk-padding-small">
            <div class="uk-flex uk-flex-middle">
                <p class="uk-text-bold uk-margin-remove">Mitra Data Validation</p>
            </div>
            <div class="uk-flex uk-flex-middle" style="gap: 10px;">
                <button
                    v-if="validated.is_valid"
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('READ', 'worker-kyc')
                        ? 'myrobin-background-white myrobin-border-green myrobin-color-green'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('READ', 'worker-kyc'))"
                    @click="$emit('read-validation-notes')"
                >View Silver Notes</button>
                <button
                    v-if="validated.is_valid"
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'myrobin-background-white myrobin-border-blue myrobin-color-blue'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                    @click="$emit('unlabel')"
                >Unlabel This Mitra as Silver</button>
                <button
                    v-else
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'uk-button-primary myrobin-color-white'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                    @click="$emit('validate')"
                >Label This Mitra as Silver</button>
                <button
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'myrobin-background-primary myrobin-color-white'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    @click="$emit('is-validating');"
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                >Validate</button>
            </div>
        </div>
        <div class="uk-width-1-1 uk-card uk-card-default uk-margin-top">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <template v-if="tableLoading">
                        <LoadingTable :colspan="2" />
                    </template>
                    <template v-else>
                        <tbody>
                            <tr :class="{ 'myrobin-background-pink' : !data.fullname || !data.fullname_ktp }">
                                <td>
                                    <p>Mitra Name (from PWA)</p>
                                    <p class="uk-text-italic text-color-blue">Mitra Full Name by MDM</p>
                                </td>
                                <td>
                                    <p>{{ data.fullname }}</p>
                                    <p class="uk-text-italic text-color-blue">{{ data.fullname_ktp || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.phone_number || !data.whatsapp_number }">
                                <td>
                                    <p>Mitra Phone Number (from PWA)</p>
                                    <p class="uk-text-italic text-color-blue">Mitra WA Number by MDM</p>
                                </td>
                                <td>
                                    <p>{{ data.phone_number || '-' }}</p>
                                    <p class="uk-text-italic text-color-blue">{{ data.whatsapp_number || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.ktp_mitra || !data.ktp_mdm }">
                                <td>
                                    <div>KTP Photo (from PWA)</div>
                                    <p class="uk-text-italic text-color-blue">KTP Photo by MDM</p>
                                </td>
                                <td>
                                    <div v-if="data.ktp_mitra && data.ktp_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.ktp_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.ktp_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.ktp_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                    <p class="text-color-blue">{{ data.ktp_mdm || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.identity_number }">
                                <td>Mitra NIK (from PWA)</td>
                                <td>{{ data.identity_number }}</td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.address || !data.domicile_address }">
                                <td>
                                    <p>Address KTP (from PWA)</p>
                                    <p class="uk-text-italic text-color-blue">Domicile Address by MDM</p>
                                </td>
                                <td class="uk-text-truncate">
                                    <p>{{ data.address || '-' }}</p>
                                    <p class="uk-text-italic text-color-blue">{{ data.domicile_address || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.birthplace }">
                                <td>Birthplace</td>
                                <td>{{ data.birthplace ? data.birthplace.name : '-' }}</td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.birthdate }">
                                <td>Birthdate</td>
                                <td>{{ data.birthdate ? formatLongDate(data.birthdate) : '-' }}</td>
                            </tr>
                            <tr v-for="(data, id) in contactForm" :key="id" :class="{ 'myrobin-background-pink' : !data.fill }">
                                <td class="uk-text-left uk-text-nowrap">{{ data.field }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data.fill || '-' }}</td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </div>
        </div>

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import moment from 'moment';
import PreviewImageModal from '@/components/globals/modals/ImageViewer.vue';

moment.locale('id');

export default {
    name: 'SilverValidationTableView',
    components: {
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        PreviewImageModal,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        validated: {
            type: Object,
            required: false,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            formatter: formatter,
            previewSrc: null,
            modal: {
                isShowPreviewImageModal: false,
            },
        };
    },
    computed: {
        contactForm() {
            const contacts = this.data.contacts && this.data.contacts.length > 0 ? this.data.contacts : [];
            return [
                {
                    field: 'Emergency Contact Name (1)',
                    fill: contacts[0]?.name
                },
                {
                    field: 'Emergency Contact Relation (1)',
                    fill: contacts[0]?.relationship
                },
                {
                    field: 'Emergency Contact Phone Number (1)',
                    fill: contacts[0]?.phone_number
                },
                {
                    field: 'Emergency Contact Name (2)',
                    fill: contacts[1]?.name
                },
                {
                    field: 'Emergency Contact Relation (2)',
                    fill: contacts[1]?.relationship
                },
                {
                    field: 'Emergency Contact Phone Number (2)',
                    fill: contacts[1]?.phone_number
                },
                {
                    field: 'Emergency Contact Name (3)',
                    fill: contacts[2]?.name
                },
                {
                    field: 'Emergency Contact Relation (3)',
                    fill: contacts[2]?.relationship
                },
                {
                    field: 'Emergency Contact Phone Number (3)',
                    fill: contacts[2]?.phone_number
                },
            ];
        },
    },
    methods: {
        formatLongDate(date) {
            return formatter.dateCompleteNew(moment(date).toISOString());
        },
        previewImage({ file_url }) {
            this.previewSrc = file_url;
            this.modal.isShowPreviewImageModal = true;
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
    color: white !important;
}
tbody, input {
    color: #000 !important;
}
.myrobin-color-white {
    color: #ffffff;
}
.myrobin-color-green {
    color: #0abab5;
}
.myrobin-color-blue {
    color: #0275d8;
}
</style>
