<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable uk-heigth-1-1">
        <div class="uk-flex uk-margin uk-child-width-expand@s" uk-grid>
            <div class="uk-width-1-1">
                <div class="uk-inline uk-width-1-3">
                    <span class="uk-form-icon" uk-icon="icon: search"></span>
                    <input @input="filterJobs('fullname', $event)" v-model="filter.fullname" class="uk-input uk-border-pill" placeholder="Search applicant name">
                </div>
            </div>
        </div>
        <div class="uk-flex uk-margin uk-child-width-expand@s" uk-grid>
            <div>
                <input @input="filterJobs('role', $event)" v-model="filter.role" class="uk-input uk-border-pill" placeholder="Filter by role">
            </div>
            <div>
                <input @input="filterJobs('title', $event)" v-model="filter.title" class="uk-input uk-border-pill" placeholder="Filter by vacancy's title">
            </div>
            <div>
                <input @input="filterJobs('company_office', $event)" v-model="filter.company_office" class="uk-input uk-border-pill" placeholder="Filter by office">
            </div>
            <div>
                <select v-model="filter.status" class="uk-select uk-border-pill" @change="isStatusChanged">
                    <option value="">Filter by status</option>
                    <option value="0">Active</option>
                    <option value="1">Non-Active</option>
                </select>
            </div>
        </div>
        <div>
        <span v-if="isLoading" uk-spinner="ratio: 2" class="uk-position-center uk-margin-auto"></span>
        <div v-show="!isLoading && job_list.docs.length == 0" class="uk-text-center">
            <span>Data Not Found</span>
        </div>
        <div v-show="!isLoading" v-for="list in job_list.docs" :key="list._id" class="uk-card uk-card-default uk-width-1-1@m uk-border-rounded uk-margin-small-bottom">
            <div class="uk-card-body uk-padding-remove">
                <div class="uk-padding-small">
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <div><span class="uk-text-bold uk-margin-right">{{ list.job.title }}</span><span class="uk-text-meta">{{ list.role.name }} - {{ list.office.name }}</span></div>
                            <div><span class="uk-text-meta">{{ list.office.address }}</span></div>
                        </div>
                        <div class="uk-width-1-2">
                            <div class="uk-flex uk-flex-right">
                                <div class="uk-margin-small-right">
                                    <img :src="`${images}/share.svg`">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="list.count_applicant === null" class="uk-flex uk-flex-wrap-around uk-margin-medium-top">
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>All Applicants</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <hr class="uk-divider-vertical">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>New Applicants</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>Picked up Applicant</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>Invited to Interview</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>Interviewed Applicant</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>Contract Sent</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>Signed Applicant</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small">
                            <h1>0</h1>
                            <span>Rejected Applicant</span>
                        </div>
                    </div>
                    <div v-else class="uk-flex uk-flex-wrap-around uk-margin-medium-top">
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, '')">
                            <h1>{{ countAllApplicant(list.count_applicant) || 0 }}</h1>
                            <span>All Applicants</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <hr class="uk-divider-vertical">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, 0)">
                            <h1>{{ list.count_applicant.total_new_applicant || 0 }}</h1>
                            <span>New Applicants</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, 1)">
                            <h1>{{ list.count_applicant.total_picked_up_applicant || 0 }}</h1>
                            <span>Picked up Applicant</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, 4)">
                            <h1>{{ list.count_applicant.total_invited_interview_applicant || 0 }}</h1>
                            <span>Invited to Interview</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, 5)">
                            <h1>{{ list.count_applicant.total_interviewed_applicant || 0 }}</h1>
                            <span>Interviewed Applicant</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, 6)">
                            <h1>{{ list.count_applicant.total_contract_sent_applicant || 0 }}</h1>
                            <span>Contract Sent</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, 7)">
                            <h1>{{ list.count_applicant.total_signed_applicant || 0 }}</h1>
                            <span>Signed Applicant</span>
                        </div>
                        <div class="uk-margin-right uk-text-center">
                            <br>
                            <img :src="`${images}/arrow-forward.svg`">
                        </div>
                        <div class="uk-margin-right uk-text-center uk-width-expand uk-text-small applicant-counter" @click="clickOnStatus(list.job._id, 3)">
                            <h1>{{ list.count_applicant.total_rejected_applicant || 0 }}</h1>
                            <span>Rejected Applicant</span>
                        </div>
                    </div>
                </div>
                <div v-if="isShowCandidate === list._id">
                    <div class="uk-padding-small">
                        <div class="uk-flex">
                            <div class="uk-inline uk-margin-right uk-width-1-2 ">
                                <span class="uk-form-icon" uk-icon="icon: search"></span>
                                <input class="uk-input uk-border-pill" placeholder="Search by phone number or name">
                            </div>
                            <div class="uk-flex uk-flex-middle">
                                <img :src="`${images}/filter_list.svg`">
                                <span class="uk-text-small uk-text-bold uk-margin-small-left uk-pointer" @click="showModal('filter-candidate')">Filter</span>
                            </div>
                        </div>
                        <div class="uk-flex uk-margin-top">
                            <div class="uk-inline uk-margin-right uk-margin-small-left">
                                <input class="uk-checkbox uk-border-rounded" type="checkbox">
                            </div>
                            <div class="uk-flex uk-flex-middle">
                                <img :src="`${images}/refresh.svg`">
                            </div>
                        </div>
                    </div>
                    <hr class="uk-margin-remove">
                    <div class="uk-margin-left uk-margin-right">
                        <div class="uk-overflow-auto">
                            <table class="uk-table uk-table-hover uk-table-middle uk-table-divider uk-padding-left">
                                <thead>
                                <tr>
                                    <th class="uk-table-shrink"></th>
                                    <th class="uk-table-expand">Name</th>
                                    <th class="uk-table-shrink">Fit Score</th>
                                    <th class="uk-width-small">Location</th>
                                    <th class="uk-table-shrink uk-text-nowrap">Phone Number</th>
                                    <th class="uk-table-shrink uk-text-nowrap">Education</th>
                                    <th class="uk-table-shrink uk-text-nowrap">Last Position</th>
                                    <th class="uk-table-shrink uk-text-nowrap">Experience</th>
                                    <th class="uk-table-shrink uk-text-nowrap"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(list, index) in list_applicant.docs" :key="index">
                                    <!-- checkbox -->
                                    <td><input class="uk-checkbox uk-border-rounded" type="checkbox"></td>
                                    <!-- name -->
                                    <td>
                                        <div class="uk-flex uk-flex-middle">
                                            <img class="uk-preserve-width uk-border-circle uk-margin-small-right" :src="`${images}/avatar.svg`" width="35" alt="">
                                            <span class="uk-text-bold">{{ list.user.name }}</span>
                                        </div>
                                    </td>
                                    <!-- fit score -->
                                    <td class="uk-table-link">
                                        <a class="uk-link-reset" href="#">785</a>
                                    </td>
                                    <!-- location -->
                                    <td class="uk-text-truncate">{{ list.user.domicile.name }}</td>
                                    <!-- phone number -->
                                    <td class="uk-text-nowrap">+62 812 3456 7890 null</td>
                                    <!-- education -->
                                    <td class="uk-text-nowrap">{{ list.user.education_level.name }}</td>
                                    <!-- last position -->
                                    <td class="uk-text-nowrap uk-text-center">-</td>
                                    <!-- experience -->
                                    <td class="uk-text-nowrap">8 Years, 4 Months</td>
                                    <!-- action -->
                                    <td>
                                        <div class="uk-flex uk-flex-middle">
                                            <a href="javascript:void(0)" @click="viewDetailApplicant(list)">
                                                <img class="uk-preserve-width uk-border-circle uk-margin-small-right" :src="`${images}/visibility.svg`" width="20" alt="">
                                            </a>
                                            <a href="#"><img class="uk-preserve-width uk-border-circle uk-margin-small-right" :src="`${images}/cloud_download.svg`" width="20" alt=""></a>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-card-footer uk-padding-remove-left">
                <a v-if="list.count_applicant"
                    href="javascript:void(0)"
                    @click="viewAllApplicant(list.job._id)"
                    class="uk-text-success"
                    style="margin-left: 15px;">
                    {{ isShowCandidate === -1 ? 'View All Candidates' : 'Hide All Candidates'}}
                </a>
            </div>
        </div>
        <pagination 
            v-if="job_list.docs.length > 0"
            :total-data="job_list.totalDocs"
            :change-limit="changeLimit"
            :change-page="changePage"
            :limit-list="limitList"
            :limit-page="filter.limit"
            :start-page="filter.page"
        />
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/globals/Pagination';

export default {
    data() {
        return {
            limitList: [5, 10 ,15, 20],
            isLoading: false,
            isShowCandidate: -1,
            list_applicant: {docs: []},
            filter_by: [],
            posted_by: [],
            list_active: [{name: 'Active'}, {name: 'Non-Active'}],
            is_active: '',
            filter: {
                fullname: '',
                role: '',
                company_office: '',
                title: '',
                address: '',
                status: '',
                limit: 10,
                page: 1,
                selected: '',
                province_id: ''
            },
            sort: {
                active: null
            },
            images: PREFIX_IMAGE,
        };
    },
    components: {
        Pagination
    },
    watch: {
        filter: {
            handler(){
                this.$router.push({name: this.$route.name, query: {...this.$route.query, ...this.filter}}).catch(()=>{});
                this.getList();
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            job_list: 'job_applicant/job_list'
        }),
        user_cred() {
            return getUserLogin();
        },
    },
    async created() {
        this.filter = {
            fullname: this.$route.query.fullname ?? this.filter.fullname,
            role: this.$route.query.role ?? this.filter.role,
            company_office: this.$route.query.company_office ?? this.filter.company_office,
            title: this.$route.query.title ?? this.filter.title,
            address: this.$route.query.address ?? this.filter.address,
            status: this.$route.query.status ?? this.filter.status,
            limit: this.$route.query.limit ? parseInt(this.$route.query.limit) : this.filter.limit,
            page: this.$route.query.page ? parseInt(this.$route.query.page) : this.filter.page,
            province_id: this.$route.params.province_id ? this.$route.params.province_id : this.filter.province_id
        };
    },
    methods: {
        ...mapActions({
            getJobList: 'job_applicant/getJobList',
        }),
        changeLimit(e) {
            this.filter.limit = e.target.value;
            this.getList();
        },
        changePage(value) {
            this.filter.page = value;
            this.getList();
        },
        viewDetailApplicant(user) {
            this.$router.push(`/admin/job_applicant/candidate/detail/${user._id}`);
        },
        async viewAllApplicant(job_id) {
            this.$router.push({ path: `/admin/job_applicant/detail/${this.$route.params.province_id}/${job_id}`, query: this.filter });
        },
        clickOnStatus(job_id, index){
            this.filter.selected = index;
            this.$router.push({ path: `/admin/job_applicant/detail/${this.$route.params.province_id}/${job_id}`, query: this.filter });
        },
        async pages(index){
            this.filter.page = index;
            this.getList();
        },
        async filterJobs(field, e) {
            if (this.filter[field].length === 0){
                this.filter.limit = 10;
                this.filter.page = 1;
                await this.getList();
            }
            if (field != 'title') {
                this.filter[field] = formatter.sanitizeString(e.target.value);
            }
            for (const value of Object.values(this.filter)) {
                const input = formatter.sanitizeString(value);
                if (input.length > 2) {
                    this.filter.limit = 10;
                    this.filter.page = 1;
                    await this.getList();
                }
            }
        },
        isStatusChanged() {
            this.getList();
        },
        countAllApplicant(obj) {
            return Object.values(obj).reduce((a, b) => a + b);
        },
        async getList() {
            this.isLoading = true;
            await this.getJobList(this.filter);
            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
    div.muted h1,
    div.muted span {
        color: #999 !important;
    }
.photo {
    width: 120px;
    height: 120px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}
.applicant_name {
    font-weight: 700;
    margin-bottom: 10px;
}

.applicant-counter:hover {
    background: rgba(0, 0, 0, 0.10);
}
</style>
