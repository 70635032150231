<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s" uk-grid>
            <div v-if="getRole() !== 'supervisor'">
                <div v-if="company_office">
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                    </div>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> Select Location filter
                    </div>
                </div>
                <p v-if="company_office" class="reset-filter" @click="resetLocation">Reset Location Filter</p>
            </div>
            <div v-else>
              <div v-if="company_office">
                  <div class="location-header">
                      <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                  </div>
                  <div class="location-header">
                      <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                  </div>
              </div>
            </div>
            <div>
                <div v-show="company_office" class="location-header" style="cursor:pointer" @click="showModal('date-filter')">
                    <img :src="`${images}/calendar.png`" alt="" width="20"> {{flterDateText()}}
                </div>
                <p v-if="meta.start_date != '' || meta.end_date != ''" class="reset-filter" @click="resetDate">Reset Date Filter</p>
            </div>
            <div v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','asd123'])">
                <div v-show="company_office" style="overflow:auto">
                    <div
                        class="schedule-add"
                        @click="showModal('create-schedule')"
                        style="cursor:pointer;float:right"
                        >
                        <img :src="`${images}/shift-add.png`" alt="" width="25">
                        Tambah Jadwal
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!company_office" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand uk-text-center">Name</th>
                            <th class="uk-table-expand uk-text-center">Type</th>
                            <th class="uk-text-center">Code</th>
                            <th class="uk-text-center">City</th>
                            <th class="uk-text-center">Address</th>
                            <th class="uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.$store.state.company_office.company_offices">
                        <tr v-for="(warehouse, i) in this.$store.state.company_office.company_offices" :key="i">
                            <td class="uk-text-left uk-text-justify">{{warehouse.name || '-'}}</td>
                            <td class="uk-text-center">{{warehouse.type.description}}</td>
                            <td class="uk-text-center">{{warehouse.code}}</td>
                            <td class="uk-text-center">{{warehouse.city}}</td>
                            <td class="uk-text-center">{{warehouse.address}}</td>
                            <td>
                                <button
                                  style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#0275D8"
                                  @click="selectWarehouse(warehouse)"
                                  class="uk-button-primary">
                                  Details
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
            <pagination
                :total-data="this.$store.state.company_office.company_offices.length"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div v-else class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand uk-text-center">Day</th>
                            <th class="uk-table-expand uk-text-center">Date</th>
                            <th class="uk-text-center">Workers Assigned</th>
                            <th class="uk-text-center">Actual Worker</th>
                            <th class="uk-text-center" v-if="!consist(user_cred.roles, ['asd123'])">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="daily_schedules.docs.length>0">
                        <tr v-for="(schedule, i) in daily_schedules.docs" :key="i">
                            <td class="uk-text-left uk-text-justify">{{formatDay(schedule.date)}}</td>
                            <td class="uk-text-center">{{formatDate(schedule.date)}}</td>
                            <td class="uk-text-center">{{schedule.total_worker}}</td>
                            <td class="uk-text-center">{{schedule.actual_worker}}</td>
                            <td class="uk-text-center" v-if="!consist(user_cred.roles, ['asd123'])">
                                <button v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','admin-bd'])"
                                  style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#0275D8"
                                  @click="showEditModal(schedule)"
                                  class="uk-button-primary uk-margin-right">
                                  Edit
                                </button>
                                <button v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation'])"
                                  style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#0275D8"
                                  @click="showDeleteModal(schedule)"
                                  class="uk-button-danger uk-margin-left">
                                  Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
            <pagination
                :total-data="daily_schedules.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="date-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('date-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input Date Range for Which you Want to See Details</h2>
                </div>
                <div class="uk-modal-body">
                    <div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">From</label>
                            <div class="uk-form-controls">
                                <datepicker
                                    name="Start Date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    v-model="meta.start_date"
                                    :disabled-dates="disabled_start_date"
                                ></datepicker>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">To (optional)</label>
                            <div class="uk-form-controls">
                                <datepicker
                                    name="End Date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    v-model="meta.end_date"
                                ></datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setDate">Apply</button>
                </div>
            </div>
        </div>

        <div id="location-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('location-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input property for which you want to see details</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">City</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office City"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="" disabled selected>Choose City</option>
                                <option v-for="(city, index) in cities" :key="index" :value="city">{{city}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">Property</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office Property"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="company_office"
                            >
                                <option value="" disabled selected>Choose Property</option>
                                <option v-for="(office, index) in filtered_offices" :key="index" :value="office">{{office.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setLocation">Apply</button>
                </div>
            </div>
        </div>

        <div id="formScheduleModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('create-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Tambah Jadwal</h2>
                </div>
                <div class="uk-modal-body">

                    <div class="uk-margin">
                        <label class="uk-form-label">Jumlah Pekerja</label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('total_worker')}"
                            name="total_worker"
                            type="text"
                            v-model="createSchedule.total_worker"
                            v-validate="'required'"
                            placeholder="Masukkan total pekerja">
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('total_worker')">{{ errors.first('total_worker') }}</span>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Hari</label>
                        <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                                <button @click="handleClickDay('senin')"
                                :class="(createSchedule.monday)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Senin</button>
                            </div>
                            <div>
                                <button @click="handleClickDay('selasa')"
                                :class="(createSchedule.tuesday)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Selasa</button>
                            </div>
                            <div>
                                <button @click="handleClickDay('rabu')"
                                :class="(createSchedule.wednesday)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Rabu</button>
                            </div>
                            <div>
                                <button @click="handleClickDay('kamis')"
                                :class="(createSchedule.thursday)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Kamis</button>
                            </div>
                        </div>
                        <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                                <button @click="handleClickDay('jumat')"
                                :class="(createSchedule.friday)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Jumat</button>
                            </div>
                            <div>
                                <button @click="handleClickDay('sabtu')"
                                :class="(createSchedule.saturday)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Sabtu</button>
                            </div>
                            <div>
                                <button @click="handleClickDay('minggu')"
                                :class="(createSchedule.sunday)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Minggu</button>
                            </div>
                        </div>
                    </div>

                    <p>Masa Berlaku</p>
                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label><input class="uk-checkbox" type="checkbox" v-model="createSchedule.is_valid_forever"> Masa berlaku selamanya</label>
                    </div>
                    <div class="uk-width-1-1 uk-column-1-2@m">
                        <label class="uk-form-label" for="form-stacked-text">Dari</label>
                        <div class="uk-form-controls">
                        <datetime
                            input-class="uk-input"
                            type="date"
                            name="Start Date"
                            v-model="createSchedule.start_date"
                            placeholder="dd/mm/yyy"
                        ></datetime>
                        <label class="uk-form-label" for="form-stacked-text">Sampai (Optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="End Date"
                                :min-datetime="createSchedule.start_date"
                                v-if="createSchedule.is_valid_forever == false"
                                v-model="createSchedule.end_date"
                                placeholder="dd/mm/yyy"
                            ></datetime>
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="End Date"
                                :min-datetime="createSchedule.start_date"
                                v-else
                                disabled
                                v-model="createSchedule.end_date"
                                placeholder="dd/mm/yyy"
                            ></datetime>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary" type="button" @click="createSchedules">Save</button>
                </div>
            </div>
        </div>

        <div id="editScheduleModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-schedule')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Edit Jadwal</h2>
                </div>
                <div class="uk-modal-body">

                    <div class="uk-margin">
                        <label class="uk-form-label">Tanggal</label>
                        <datetime
                          input-class="uk-input"
                          type="date"
                          :class="{'uk-form-danger': errors.has('date')}"
                          name="date"
                          v-model="detailSchedule.date"
                          placeholder="Pilih tanggal"
                          v-validate="'required'"
                        ></datetime>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label">Jumlah Pekerja</label>
                        <input
                          class="uk-input"
                          :class="{'uk-form-danger': errors.has('total_worker')}"
                          name="total_worker"
                          type="text"
                          v-model="detailSchedule.total_worker"
                          v-validate="'required'"
                          placeholder="Masukkan total pekerja">
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('total_worker')">{{ errors.first('total_worker') }}</span>
                    </div>

                </div>
                <div class="uk-modal-footer uk-text-right">
                  <button class="uk-button uk-button-primary" type="button" @click="updateSchedule">Save</button>
                </div>
            </div>
        </div>

        <div id="deleteScheduleModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-schedule')"></button>
                <div class="uk-modal-header">
                  <h2 class="uk-modal-title">Delete Jadwal</h2>
                </div>
                <div class="uk-modal-body">
                  <div class="uk-margin">
                    <div>
                      <h4>Tanggal</h4>
                      <p>{{formatDate(detailSchedule.date)}}</p>
                    </div>
                    <div>
                      <h4>Jumlah Pekerja</h4>
                      <p>{{detailSchedule.total_worker}}</p>
                    </div>
                  </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger" type="button" @click="deleteSchedule(scheduleId)">Delete</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
// import LabelStatus from '@/components/globals/LabelStatus';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import Datepicker from 'vuejs-datepicker';
import {Datetime} from 'vue-datetime';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import moment from 'moment';
moment.locale('id');

export default {
    data() {
        return {
            meta: {
                limit: 50,
                page: 1,
                start_date: '',
                end_date: '',
                company_office_id: '',
                is_deleted: false,
            },
            scheduleId: '',
            createSchedule: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                company_office_id: '',
                start_date: moment().format(),
                end_date: '',
                total_worker: '',
                is_valid_forever: false
            },
            detailSchedule: {
                company_office_id: '',
                date: '',
                total_worker: '',
                is_valid_forever: false
            },
            images: PREFIX_IMAGE,
            user_profile: '',
            company_office: '',
            disabled_start_date: {
                to: new Date('2020','07','01') // 1 August 2020
            },
            isLoading: false,
            selected_city: '',
            cities: [],
            filtered_offices: [],
            years: [2019, 2020, 2021, 2022],
            months:[{key: "Januari", value: 1}, {key: "Februari", value: 2}, {key: "Maret", value: 3}, {key: "April", value: 4}, {key: "Mei", value: 5},
                {key: "Juni", value: 6}, {key: "Juli", value: 7}, {key: "Agustus", value: 8}, {key: "September", value: 9},
                {key: "Oktober", value: 10}, {key: "November", value: 11}, {key: "Desember", value: 12}]
        };
    },
    components: {
        // LabelStatus,
        EmptyTable,
        Pagination,
        Datetime,
        Datepicker
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            daily_schedules: 'schedule/daily_schedules',
        }),
        user_cred() {
            return getUserLogin();
        }
    },
    watch: {
        meta() {},
    },
    async mounted() {
        await this.getCompanyOffice();
        this.user_profile = await this.getProfile();
        this.company_office = this.$store.state.selected_company == '' ? this.user_profile.company_office : this.$store.state.selected_company;
        // this.selectCompanyOffice();
        this.meta.company_office_id = this.company_office == null ? null : this.company_office._id;
        this.setCity();
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getProfile: 'auth/getProfile',
            getDailySchedules: 'schedule/getDailySchedules',
            createDailySchedule: 'schedule/createDailySchedule',
            updateDailySchedule: 'schedule/updateDailySchedule',
            deleteDailySchedule: 'schedule/deleteDailySchedule',
        }),
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        selectCompanyOffice(){
            this.company_office = this.company_offices.length > 0 ? this.company_offices[0] : 'n/a';
        },
        async selectWarehouse(data) {
            this.meta.company_office_id = data._id;
            this.createSchedule.company_office_id = data._id;
            this.company_office = data;
            await this.getDailySchedules(this.meta);
        },
        formatDay(date) {
            if (!date) return null;
            return moment(date).format('dddd');
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        detailForm(schedule) {
            try {
                this.scheduleId = schedule._id;
                this.detailSchedule.company_office_id = schedule.company_office._id;
                this.detailSchedule.date = schedule.date;
                this.detailSchedule.total_worker = schedule.total_worker;
                this.detailSchedule.is_valid_forever = schedule.is_valid_forever == undefined ? false : schedule.is_valid_forever;
            } catch (err) {
                notificationDangerCustom(err);
            }
        },
        handleClickDay(day) {
            if (day == 'senin') {
                this.createSchedule.monday = !this.createSchedule.monday;
            }
            if (day == 'selasa') {
                this.createSchedule.tuesday = !this.createSchedule.tuesday;
            }
            if (day == 'rabu') {
                this.createSchedule.wednesday = !this.createSchedule.wednesday;
            }
            if (day == 'kamis') {
                this.createSchedule.thursday = !this.createSchedule.thursday;
            }
            if (day == 'jumat') {
                this.createSchedule.friday = !this.createSchedule.friday;
            }
            if (day == 'sabtu') {
                this.createSchedule.saturday = !this.createSchedule.saturday;
            }
            if (day == 'minggu') {
                this.createSchedule.sunday = !this.createSchedule.sunday;
            }
        },
        async createSchedules() {
            const res = await this.createDailySchedule(this.createSchedule);
            if (res.status == "OK") {
                await this.getDailySchedules(this.meta);
                await this.hideModal('create-schedule');
                this.resetSchedule();
                notificationSuccess('Jadwal berhasil di tambah');
            }
        },
        async updateSchedule() {
            const res = await this.updateDailySchedule({scheduleId: this.scheduleId, data: this.detailSchedule});
            if (res.status == "OK") {
                await this.getDailySchedules(this.meta);
                await this.hideModal('edit-schedule');
                this.resetForm();
                notificationSuccess('Jadwal berhasil di update');
            }
        },
        async deleteSchedule(id) {
            const res = await this.deleteDailySchedule(id);
            if (res.status == "OK") {
                await this.getDailySchedules(this.meta);
                await this.hideModal('delete-schedule');
                this.resetForm();
                notificationSuccess('Jadwal berhasil di hapus');
            }
        },
        resetSchedule() {
            this.createSchedule = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                start_date: moment().format(),
                end_date: '',
                total_worker: '',
                is_valid_forever: false
            };
        },
        resetForm() {
            this.detailSchedule = {
                date: '',
                total_worker: '',
                is_valid_forever: false
            };
            this.$validator.reset();
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').hide();
                break;
            case 'location-filter':
                await window.UIkit.modal('#location-filter').hide();
                break;
            case 'create-schedule':
                await window.UIkit.modal('#formScheduleModal').hide();
                break;
            case 'edit-schedule':
                await window.UIkit.modal('#editScheduleModal').hide();
                this.resetForm();
                break;
            case 'delete-schedule':
                await window.UIkit.modal('#deleteScheduleModal').hide();
                this.resetForm();
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').show();
                break;
            case 'location-filter':
                this.setCity();
                await window.UIkit.modal('#location-filter').show();
                break;
            case 'create-schedule':
                await window.UIkit.modal('#formScheduleModal').show();
                break;
            }
        },
        async showEditModal(schedule) {
            this.detailForm(schedule);
            await window.UIkit.modal('#editScheduleModal').show();
        },
        async showDeleteModal(schedule) {
            this.detailForm(schedule);
            await window.UIkit.modal('#deleteScheduleModal').show();
        },
        async setDate() {
            if (this.meta.start_date != '' && this.meta.end_date != '') {
                const start_date = new Date(this.meta.start_date);
                const end_date = new Date(this.meta.end_date);
                if (start_date > end_date) {
                    return notificationDangerCustom('End date must be greater than start date');
                }
            } else if (this.meta.start_date == '') {
                return notificationDangerCustom('Start date must be filled');
            }
            this.hideModal('date-filter');
            await this.getDailySchedules(this.meta);
        },
        dateFilterAsText() {
            let date_format = '';
            if (this.meta.start_date) {
                date_format = date_format + this.formatDate(this.meta.start_date);
            }
            if (this.meta.end_date) {
                if (date_format !== '') {
                    date_format = date_format + '-';
                }
                date_format = date_format + this.formatDate(this.meta.end_date);
            }
            return date_format;
        },
        resetDate() {
            this.meta.start_date = '';
            this.meta.end_date = '';
        },
        flterDateText() {
            let date_filter_text = this.dateFilterAsText();
            if (date_filter_text != '') {
                return date_filter_text;
            }
            return 'Choose date range';
        },
        setCity() {
            this.cities = [...new Set(this.company_offices.map(data => data.city))];
            this.filtered_offices = [];
        },
        filterOffice() {
            this.filtered_offices = this.company_offices.filter(data => data.city === this.selected_city);
        },
        async setLocation() {
            if (this.company_office == '') {
                return notificationDangerCustom('Please select one of property');
            }
            this.meta.company_office_id = this.company_office._id;
            this.hideModal('location-filter');
            this.$store.state.selected_company = this.company_office;
            await this.getDailySchedules(this.meta);
        },
        resetLocation() {
            this.meta.company_office_id = null;
            this.company_office= '';
            this.selected_city= '';
            this.cities= [];
            this.filtered_offices= [];
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.schedule-add{
  font-family: 'Roboto';
  color: #5FD855;
  font-size: 18px;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.btn-sml-active{
    background: #0ABAB5;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 101px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    color: #ffffff !important;
}
.btn-sml{
    background: #F8F8F8;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    width: 101px;
    color: #000000 !important;
}
</style>
