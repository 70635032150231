<template>
  <section>
    <div id="mainModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
        <div class="uk-modal-header">
          <h4>{{ title }}</h4>
        </div>
        <div class="uk-modal-body">
          <div>{{ body }}</div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <section v-if="isLoading">
            <button class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          </section>
          <section v-else>
            <button
              class="uk-button uk-button-default uk-border-rounded uk-margin-right uk-text-primary"
              type="button"
              style="border-color: #0f7ae5;"
              @click="hideModal(false)"
            >
              No
            </button>
            <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="confirmProcess">Yes</button>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            isLoading: false,
        };
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal");
        },
        confirmProcess() {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("confirmProcess");
        }
    }
};
</script>
