<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);
window.UIkit = UIkit;

export default {
    name: 'app'
};
</script>

<style lang="less">
@import "../node_modules/uikit/src/less/uikit.theme.less";
@import "./style.less";
@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,900&display=swap');
@import "../node_modules/vue-datetime/dist/vue-datetime.css";
</style>
