import api from '@/utils/api';
import { API_AUTH } from '@/utils/api-url';
import { setAuthCookie, setUserRole, getUserLogin, logoutUser } from '@/utils/auth';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import router from '@/router';

const namespaced = true;
const state = {
    isLogin: getUserLogin() !== null,
    token: null, // for temp token
    client_role: null // for temp client role
};

const actions = {
    async login(context, data) {
        try {
            // set simple basic auth for injection handling || can be improved...
            data.basicAuthHeader = 'Basic ' + btoa(data.username + ':' + data.password);
            // set variable return response from api
            const response = await api.apiPostBasicAuth(API_AUTH.LOGIN, data);
            // set up state for token before logged in
            context.state.token = response.data.token;
            // set up state for client role before logged in
            context.state.client_role = response.data.result.client_role;
            // return payload response
            return response.data.status;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async validateKeyAuth(context, data) {
        try {
            const response = await api.apiPostAuth(API_AUTH.VALIDATE_KEY_AUTH, data);
            if (response.data && response.data.status === 'OK') {
                // set auth token to client cookies
                setAuthCookie(context.state.token);
                // set client role to client cookies
                setUserRole(context.state.client_role);
                // show notification
                notificationSuccess('Login Success');
                // reset state token to null/empty
                context.state.token = null;
                // reset state client_role to null/empty
                context.state.client_role = null;
                // reload existing page
                router.go(0);
            }
        } catch (err) {
            // show failed notification handler
            notificationDanger(err);
        }
    },
    async sendOTPCode(context, data) {
        try {
            await api.apiPostAuth(API_AUTH.SEND_OTP_CODE, data);
        } catch (err) {
            // show failed notification handler
            notificationDanger(err);
        }
    },
    async validateOTP(context, data) {
        try {
            await api.apiPostAuth(API_AUTH.VALIDATE_OTP_AUTH, data);
            // set auth token to client cookies
            setAuthCookie(context.state.token);
            // set client role to client cookies
            setUserRole(context.state.client_role);
            // show notification
            notificationSuccess('Login Success');
            // reset state token to null/empty
            context.state.token = null;
            // reset state client_role to null/empty
            context.state.client_role = null;
            // reload existing page
            router.go(0);
        } catch (err) {
            // show failed notification handler
            notificationDanger(err);
        }
    },
    async logout() {
        try {
            await api.apiGetAuth(API_AUTH.LOGOUT);
            logoutUser();
            notificationSuccess('Logout Success');
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getProfile() {
        try {
            const response = await api.apiGetAuth(API_AUTH.GET_PROFILE);
            return response.data.result;
            //logoutUser();
            //notificationSuccess('Logout Success');
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMyRole() {
        try {
            const response = await api.apiGetAuth(API_AUTH.GET_ROLES);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};


export default {
    namespaced,
    state,
    actions,
};
