import Vue from "vue";
import Router from "vue-router";
import { store } from '@/store/index';
import { getUserLogin } from "@/utils/auth";

import EmptyPageLayout from "@/components/layouts/EmptyPage";
import AdminLayout from "@/components/layouts/Admin";

import Dashboard from "@/components/pages/admin/Dashboard";
import Mitra from "@/components/pages/admin/workers/Mitra";
import MitraDetail from "@/components/pages/admin/workers/MitraDetail";
import TaskAssignment from "@/components/pages/admin/workers/Task";
import WorkerDetail from "@/components/pages/admin/workers/Candidate";
import Attendance from "@/components/pages/admin/workers/Attendance";
import Attendance2 from "@/components/pages/admin/workers/Attendance-2";
import Salary from "@/components/pages/admin/workers/Salary";
import SalaryDetail from "@/components/pages/admin/workers/SalaryDetail";
import SalaryReport from "@/components/pages/admin/workers/SalaryReport";
import SalaryReportList from "@/components/pages/admin/workers/SalaryReportList";
import SalaryReportDetail from "@/components/pages/admin/workers/SalaryReportDetail";
import Leave from "@/components/pages/admin/workers/Leave";
import LeavePerMitra from "@/components/pages/admin/workers/LeavePerMitra";
import CashAdvance from "@/components/pages/admin/workers/CashAdvance";
import CashAdvanceDetail from "@/components/pages/admin/workers/CashAdvanceDetail";
import Reimbursement from "@/components/pages/admin/workers/Reimbursement";
import ReimbursementDetail from "@/components/pages/admin/workers/ReimbursementDetail";
import TaxBatchList from '@/components/pages/admin/workers/tax/index';
import TaxBatchDetail from '@/components/pages/admin/workers/tax/TaxBatchDetail';
import KYCValidation from '@/components/pages/admin/workers/kyc/index';
import KYCMitraDetail from '@/components/pages/admin/workers/kyc/MitraDetail';
import OvertimeReport from '@/components/pages/admin/workers/overtime-report/index';
import OvertimeReportDetail from '@/components/pages/admin/workers/overtime-report/Detail';
import OvertimeDetailMitra from '@/components/pages/admin/workers/overtime-report/Mitra';
// import Schedule from '@/components/pages/admin/Schedule';
// import Shift from "@/components/pages/admin/Shift";

// New shift
import Shift from "@/components/pages/admin/new_shift";
import AddShift from "@/components/pages/admin/new_shift/AddShift";
import EditShift from "@/components/pages/admin/new_shift/EditShift";
import DetailShift from "@/components/pages/admin/new_shift/DetailShift";

// import Recap from '@/components/pages/admin/Recap';
// import InitiationList from '@/components/pages/admin/initiation/List';
// import InitiationDetail from '@/components/pages/admin/initiation/Detail';
import Announcement from "@/components/pages/admin/Announcement";
import AttendanceMethod from "@/components/pages/admin/AttendanceMethod";
import AttendanceSetting from "@/components/pages/admin/attendance-setting/AttendanceSetting";
import Contract from "@/components/pages/admin/Contract";
import Candidate from "@/components/pages/admin/Candidate";
import Onboarding from "@/components/pages/admin/Onboarding";
import ExMitra from "@/components/pages/admin/ExMitra";
import CvDetail from "@/components/pages/admin/ExMitra/CvDetail";
import JobVacancyList from "@/components/pages/admin/job_vacancy/List";
import JobVacancyListV2 from "@/components/pages/admin/job_vacancy/ListV2";
import JobVacancyDetail from "@/components/pages/admin/job_vacancy/Detail";
import JobApplicantDashboard from "@/components/pages/admin/job_applicant/Dashboard";
import JobApplicantDetail from "@/components/pages/admin/job_applicant/Detail/index";
import JobApplicantDetailVacancy from "@/components/pages/admin/job_applicant/Detail";
import JobApplicantCandidateDetail from "@/components/pages/admin/job_applicant/Detail_Candidate";
import NewApplicant from "@/components/pages/admin/applicant/New";
import AllApplicant from "@/components/pages/admin/applicant/All";
import PassedApplicant from "@/components/pages/admin/applicant/Passed";
import ScreeningApplicant from "@/components/pages/admin/applicant/Screening";
import RejectedApplicant from "@/components/pages/admin/applicant/Rejected";
import InterviewApplicant from "@/components/pages/admin/applicant/Interview";
// Payslip v1
// import PayslipList from '@/components/pages/admin/workers/payslip-v1/PayslipList';
// import PayslipDetail from '@/components/pages/admin/workers/payslip-v1/PayslipDetail';
// Payslip v2
// import PayslipList from '@/components/pages/admin/workers/payslip-v2/PayslipList';
// import PayslipDetail from '@/components/pages/admin/workers/payslip-v2/PayslipDetail';
// Payslip v3
import PayslipList from '@/components/pages/admin/workers/payslip-v3/PayslipList';
import PayslipDetail from '@/components/pages/admin/workers/payslip-v3/PayslipDetail';

import MitraReport from "@/components/pages/report/Mitra";
import OfficeReport from "@/components/pages/report/Office";
import InitiationReport from "@/components/pages/report/Initiation";
import InitiationFulfillmentReport from "@/components/pages/report/Fulfillment";
import MitraAttendance from "@/components/pages/report/mitra-attendance";
import SummaryAttendanceDetail from "@/components/pages/report/mitra-attendance/SummaryAttendanceDetail";
import UserAttendance from "@/components/pages/report/UserAttendance";

import ShipperWorkers from "@/components/pages/shipper/Workers";
import ShipperSchedule from "@/components/pages/shipper/Schedule";
import ShipperShift from "@/components/pages/shipper/Shift";
// import ShipperRecap from '@/components/pages/shipper/Recap';
// import ShipperCreateInitiation from '@/components/pages/shipper/initiation/Create';
// import ShipperListInitiation from '@/components/pages/shipper/initiation/List';

import GrabwheelsWorkers from "@/components/pages/grabwheels/Workers";
import GrabwheelsRecap from "@/components/pages/grabwheels/Recap";
import GrabwheelsSchedule from "@/components/pages/grabwheels/Schedule";

import AstroShift from "@/components/pages/astro/Schedule";

// import AstroShift from "@/components/pages/astro/Daily";
// import NoShift from "@/components/pages/admin/new_shift/NoShift";

import Login from "@/components/pages/Login";
import NotFound from "@/components/pages/NotFound";
import RolePageList from "@/components/pages/setting/access";
import RoleDetailPage from "@/components/pages/setting/access/Detail";
import SalarySettings from '@/components/pages/setting/salary-settings';
import JobApplicantInterviewSchedule from '@/components/pages/admin/job_applicant/InterviewSchedule';
import FilePreview from '@/components/pages/file/Preview';

Vue.use(Router);

const router = new Router({
    mode: "history", // remove # in url
    routes: [
        {
            name: 'File',
            path: "/file",
            component: FilePreview
        },
        {
            path: "/",
            component: EmptyPageLayout,
            redirect: "/login",
            children: [
                {
                    path: "/login",
                    name: "Login",
                    component: Login,
                    meta: {
                        public: true,
                        onlyWhenLoggedOut: true,
                    },
                },
            ],
        },
        {
            path: "/admin",
            component: AdminLayout,
            redirect: "/admin/dashboard",
            children: [
                {
                    path: "dashboard",
                    name: "Dashboard",
                    component: Dashboard,
                },
                {
                    path: "workers/mitra",
                    name: "Mitra",
                    component: Mitra,
                    beforeEnter: (to, from, next) => {
                        if (!from.fullPath.includes("workers/mitra")) {
                            store.state.filter_mitra = null;
                        }
                        next();
                    }
                },
                {
                    path: "workers/mitra/:mitra_id",
                    name: "MitraDetail",
                    component: MitraDetail,
                },
                {
                    path: "workers/task",
                    name: "Task",
                    component: TaskAssignment,
                },
                {
                    path: "workers/attendance",
                    name: "Attendance",
                    component: Attendance,
                },
                {
                    path: "workers/attendance-2",
                    name: "Attendance-2",
                    component: Attendance2,
                },
                {
                    path: "workers/candidate/:user_name",
                    name: "WorkerDetail",
                    component: WorkerDetail,
                },
                {
                    path: "workers/salary",
                    name: "Salary",
                    component: Salary,
                },
                {
                    path: "workers/salary/detail",
                    name: "SalaryDetail",
                    component: SalaryDetail,
                },
                {
                    path: "workers/salary/report",
                    name: "SalaryReport",
                    component: SalaryReport,
                },
                {
                    path: "workers/salary/report/list",
                    name: "SalaryReportList",
                    component: SalaryReportList,
                },
                {
                    path: "workers/salary/report/detail",
                    name: "SalaryReportDetail",
                    component: SalaryReportDetail,
                },
                {
                    path: "workers/cash-advance",
                    name: "CashAdvance",
                    component: CashAdvance,
                },
                {
                    path: "workers/cash-advance/detail",
                    name: "CashAdvanceDetail",
                    component: CashAdvanceDetail,
                },
                {
                    path: "workers/leave",
                    name: "Leave",
                    component: Leave,
                },
                {
                    path: "workers/leave/:partner_id",
                    name: "Leave Per Mitra",
                    component: LeavePerMitra,
                },
                {
                    path: "workers/reimbursement",
                    name: "Reimbursement",
                    component: Reimbursement,
                },
                {
                    path: "workers/reimbursement/detail",
                    name: "ReimbursementDetail",
                    component: ReimbursementDetail,
                },
                {
                    path: "workers/payslip",
                    name: "PayslipList",
                    component: PayslipList,
                },
                {
                    path: "workers/payslip/detail/:id",
                    name: "PayslipDetail",
                    component: PayslipDetail,
                },
                {
                    path: "workers/tax",
                    name: "TaxBatchList",
                    component: TaxBatchList,
                },
                {
                    path: "workers/tax/detail/:id",
                    name: "TaxBatchDetail",
                    component: TaxBatchDetail,
                },
                {
                    path: "workers/kyc",
                    name: "KYCValidation",
                    component: KYCValidation,
                },
                {
                    path: "workers/kyc/:id",
                    name: "KYCMitraDetail",
                    component: KYCMitraDetail,
                },
                {
                    path: "workers/overtime-report",
                    name: "OvertimeReport",
                    component: OvertimeReport,
                },
                {
                    path: "workers/overtime-report/detail",
                    name: "OvertimeReportDetail",
                    component: OvertimeReportDetail,
                },
                {
                    path: "workers/overtime-report/:id",
                    name: "OvertimeDetailMitra",
                    component: OvertimeDetailMitra,
                },
                // {
                //     path: 'schedule',
                //     name: 'schedule',
                //     component: Schedule,
                // },
                // {
                //     path: "shift",
                //     name: "Shift",
                //     component: Shift,
                // },
                // {
                //     path: "shift/weekly",
                //     name: "Shift",
                //     component: Shift,
                // },
                // {
                //     path: "no-shift",
                //     name: "NoShift",
                //     component: NoShift,
                // },
                {
                    path: "shift",
                    name: "Shift",
                    component: Shift,
                },
                {
                    path: "shift/daily",
                    name: "Shift",
                    component: AstroShift,
                },
                {
                    path: "shift/add-shift/:company_office_id",
                    name: "AddShift",
                    component: AddShift
                },
                {
                    path: "shift/edit-shift/:shift_id",
                    name: "EditShift",
                    component: EditShift
                },
                {
                    path: "shift/detail/:shift_id",
                    name: "DetailShift",
                    component: DetailShift
                },
                // {
                //     path: 'recap',
                //     name: 'Recap',
                //     component: Recap
                // },
                {
                    path: "report/mitra",
                    name: "Mitra Report",
                    component: MitraReport,
                },
                {
                    path: "report/office",
                    name: "Office Report",
                    component: OfficeReport,
                },
                {
                    path: "report/initiation",
                    name: "Initiation Report",
                    component: InitiationReport,
                },
                {
                    path: "report/initiation_fulfillment",
                    name: "Initiation Fulfillment Report",
                    component: InitiationFulfillmentReport,
                },
                {
                    path: "report/mitra_attendance",
                    name: "Mitra Attendance",
                    component: MitraAttendance,
                },
                {
                    path: "report/mitra_attendance/:daily_recap_worker_attendance_id",
                    name: "Summary Attendance Detail",
                    component: SummaryAttendanceDetail,
                },
                {
                    path: "report/user_attendance",
                    name: "User Attendance Report",
                    component: UserAttendance,
                },
                {
                    path: "applicant/new",
                    name: "New Applicant",
                    component: NewApplicant,
                },
                {
                    path: "applicant/all",
                    name: "All Applicant",
                    component: AllApplicant,
                },
                {
                    path: "applicant/passed",
                    name: "Passed Applicant",
                    component: PassedApplicant,
                },
                {
                    path: "applicant/screening",
                    name: "Screening Applicant",
                    component: ScreeningApplicant,
                },
                {
                    path: "applicant/rejected",
                    name: "Rejected Applicant",
                    component: RejectedApplicant,
                },
                {
                    path: "applicant/interview",
                    name: "Interview Applicant",
                    component: InterviewApplicant,
                },
                {
                    path: "candidate",
                    name: "Candidate",
                    component: Candidate,
                },
                {
                    path: "onboarding",
                    name: "Onboarding",
                    component: Onboarding,
                },
                {
                    path: "ex-mitra",
                    name: "Ex Mitra",
                    component: ExMitra,
                },
                {
                    path: "ex-mitra/:id",
                    name: "CV Detail",
                    component: CvDetail,
                },
                {
                    path: "job_vacancy",
                    name: "Job Vacancy List",
                    component: JobVacancyListV2,
                },
                {
                    path: "job_vacancy/v1",
                    name: "Job Vacancy List V1",
                    component: JobVacancyList,
                },
                {
                    path: "contract",
                    name: "Contract",
                    component: Contract,
                },
                {
                    path: "job_vacancy/detail/:job_id",
                    name: "Job Vacancy Detail",
                    component: JobVacancyDetail,
                },
                {
                    path: "announcement",
                    name: "Announcement",
                    component: Announcement,
                },
                {
                    path: "attendance_method",
                    name: "Attendance Method",
                    component: AttendanceMethod,
                },
                {
                    path: "attendance_setting",
                    name: "Attendance Setting",
                    component: AttendanceSetting,
                },
                {
                    path: "job_applicant",
                    name: "Job Applicant Dashboard",
                    component: JobApplicantDashboard,
                },
                {
                    path: "job_applicant/detail/:province_id",
                    name: "Job Applicant Detail",
                    component: JobApplicantDetail,
                },
                {
                    path: "job_applicant/detail/:province_id/:job_id",
                    name: "Job Applicant Detail Vacancy",
                    component: JobApplicantDetailVacancy,
                },
                {
                    path: "job_applicant/candidate/detail/:job_apply_id",
                    name: "Job Applicant Detail Candidate",
                    component: JobApplicantCandidateDetail,
                },
                {
                    path: "job_applicant/schedule/interview",
                    name: "Job Applicant Interview Schedule",
                    component: JobApplicantInterviewSchedule,
                },
                {
                    path: '/whats-new',
                    name: 'WhatsNew',
                    component: () => import('@/components/pages/WhatsNew')
                },
            ],
        },
        {
            path: "/shipper",
            component: AdminLayout,
            redirect: "/admin/dashboard",
            children: [
                {
                    path: "attendance",
                    name: "ShipperWorkers",
                    component: ShipperWorkers,
                },
                {
                    path: "schedule",
                    name: "Shipperschedule",
                    component: ShipperSchedule,
                },
                {
                    path: "shift",
                    name: "ShipperShift",
                    component: ShipperShift,
                },
                // {
                //     path: 'recap',
                //     name: 'ShipperRecap',
                //     component: ShipperRecap
                // },
                // {
                //     path: 'initiation/create',
                //     name: 'ShipperCreateInitiation',
                //     component: ShipperCreateInitiation,
                // },
                // {
                //     path: 'initiation/list',
                //     name: 'ShipperListInitiation',
                //     component: ShipperListInitiation,
                // },
            ],
        },
        {
            path: "/grabwheels",
            component: AdminLayout,
            redirect: "/admin/dashboard",
            children: [
                {
                    path: "attendance",
                    name: "GrabWorkers",
                    component: GrabwheelsWorkers,
                },
                {
                    path: "schedule",
                    name: "Grabschedule",
                    component: GrabwheelsSchedule,
                },
                // {
                //     path: 'shift',
                //     name: 'Shift',
                //     component: GrabwheelsShift
                // },
                {
                    path: "recap",
                    name: "GrabRecap",
                    component: GrabwheelsRecap,
                },
                // {
                //     path: 'initiation/create',
                //     name: 'CreateInitiation',
                //     component: GrabwheelsCreateInitiation,
                // },
                // {
                //     path: 'initiation/list',
                //     name: 'ListInitiation',
                //     component: GrabwheelsListInitiation,
                // },
            ],
        },
        {
            path: "/settings",
            component: AdminLayout,
            redirect: "/settings/roles",
            children: [
                {
                    path: 'roles/:id/edit',
                    name: 'DetailRole',
                    component: RoleDetailPage,
                },
                {
                    path: 'roles',
                    name: 'ListRoles',
                    component: RolePageList,
                },
                {
                    path: 'salary-settings',
                    name: 'SalarySettings',
                    component: SalarySettings
                }
            ]
        },
        {
            path: "*",
            component: NotFound,
            meta: {
                public: true,
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.name !== from.name) {
        store.commit('mutateRouteBackFull', from);
    }

    const isPublic = to.matched.some((record) => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some(
        (record) => record.meta.onlyWhenLoggedOut
    );
    const loggedIn = getUserLogin();

    if (!isPublic && !loggedIn) {
        if (to.name == "File") {
            document.title = "MyRobin File Previewer";
            return next();
        } else {
            return next({
                path: "/login",
                query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
            });
        }
    }

    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
        if (loggedIn.email.search("shipper") !== -1) {
            return next("/shipper");
        } else if (loggedIn.email.search("grabwheels") != -1) {
            return next("/grabwheels");
        } else {
            if (to.name == "File") {
                document.title = "MyRobin File Previewer";
                return next();
            } else {
                return next("/admin");
            }
        }
    }

    next();
});

export default router;
