<template>
  <div id="toggle-animation" class="width-large, card-sidebar">
    <div class="sidebar uk-card uk-card-default uk-card-body uk-height-1-1">
      <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
        <template>
          <li
            v-for="(item, idx) in menus"
            :key="item.permission"
          >
            <router-link v-if="item.path" :to="item.path">
              <img
                :src="item.icon"
                alt=""
                style="padding-right: 10px; width: 25px"
              />{{item.name}}
            </router-link>
            <div v-else @click="open(idx)" style="padding-left: 40px; padding-right: 30px; color: #ffffff;">
              <div class="uk-flex uk-flex-between">
                <div>
                  <img
                    :src="item.icon"
                    alt=""
                    style="padding-right: 10px; width: 25px"
                  />{{item.name}}
                </div>
                <div>
                  <img
                    v-if="!item.open"
                    :src="`${images}/down.svg`"
                    alt=""
                    style=""
                    class="uk-margin-left"
                  />
                  <img
                    v-else
                    :src="`${images}/up.svg`"
                    alt=""
                    style=""
                    class="uk-margin-left"
                  />
                </div>
              </div>
            </div>
            <template v-if="item.open">
              <li
                v-for="(sub) in item.child"
                :key="sub.permission"
                style="padding-left: 30px; padding-right: 30px; padding-bottom: 0; color: #ffffff; margin-top: 8px; margin-bottom: 0; border-bottom: none;"
              >
                <router-link :to="sub.path">
                  <img
                    :src="sub.icon"
                    alt=""
                    style="padding-right: 10px; width: 25px"
                    class="uk-margin-left"
                  />{{sub.name}} <span v-if="sidebar_counter.find(item=> item.name == sub.name)
                    && sidebar_counter[sidebar_counter.findIndex(item=> item.name == sub.name)].counter > 0" class="uk-badge">
                    {{sidebar_counter[sidebar_counter.findIndex(item=> item.name == sub.name)].counter}}
                    </span>
                </router-link>
              </li>
            </template>
          </li>
        </template>
      </ul>
      <router-link to="/whats-new" class="version-link">
        <template v-if="latest_version_data && latest_version_data.version">
            <span>v{{ latest_version_data.version }}</span>
            <img 
                :src="`${images}/information-filled-white.svg`" 
                alt="Information icon"
            >
        </template>
        <span v-else>-</span>
      </router-link>
    </div>
  </div>
</template>
<script>
import { version } from "@/../package.json";
import { mapActions, mapGetters } from "vuex";
import { PREFIX_IMAGE } from "@/utils/constant";
import formatter from "@/utils/formatter";
import { getUserLogin } from "@/utils/auth";
import { notificationDanger } from '@/utils/notification';

export default {
    data() {
        return {
            profile: null,
            version,
            images: PREFIX_IMAGE,
            initiation_dropdown: false,
            shipper: false,
            grabwheels: false,
            astro: false,
            report: false,
            applicant: false,
            workers: false,
            rostering: false,
            setting: false,
            menus: [
                {
                    name: "Dashboard",
                    icon: `${PREFIX_IMAGE}/dashboard.png`,
                    path: "/admin/dashboard",
                    permission: "dashboard",
                    ability: "READ"
                },
                {
                    name: "Rostering",
                    icon: `${PREFIX_IMAGE}/rostering.svg`,
                    path: null,
                    permission: "rostering",
                    ability: "READ",
                    open: false,
                    child: [
                        {
                            name: "Shift",
                            icon: `${PREFIX_IMAGE}/shift.png`,
                            path: "/admin/shift",
                            permission: "rostering-shift",
                            ability: "READ"
                        },
                    ]
                },
                {
                    name: "Job Applicant",
                    icon: `${PREFIX_IMAGE}/candidate.svg`,
                    path: null,
                    permission: "job-applicant",
                    ability: "READ",
                    open: false,
                    child: [
                        {
                            name: "Processing",
                            icon: `${PREFIX_IMAGE}/job_applicant/processing.svg`,
                            path: "/admin/job_applicant",
                            permission: 'job-applicant-processing',
                            ability: "READ",
                        },
                        {
                            name: "Interview Schedule",
                            icon: `${PREFIX_IMAGE}/job_applicant/schedule.svg`,
                            path: "/admin/job_applicant/schedule/interview",
                            permission: 'job-applicant-interview-schedule',
                            ability: "READ",
                        },
                    ]
                },
                {
                    name: "Candidate",
                    icon: `${PREFIX_IMAGE}/candidate.svg`,
                    path: "/admin/candidate",
                    permission: "candidate",
                    ability: "READ"
                },
                {
                    name: "Onboarding",
                    icon: `${PREFIX_IMAGE}/onboarding.svg`,
                    path: "/admin/onboarding",
                    permission: "onboarding",
                    ability: "READ"
                },
                {
                    name: "Ex-Mitra",
                    icon: `${PREFIX_IMAGE}/ex-mitra.svg`,
                    path: "/admin/ex-mitra",
                    permission: "ex-mitra",
                    ability: "READ"
                },
                {
                    name: "Job Vacancy",
                    icon: `${PREFIX_IMAGE}/job_vacancy.svg`,
                    path: "/admin/job_vacancy",
                    permission: "job-vacancy",
                    ability: "READ"
                },
                {
                    name: "Attendance Method",
                    icon: `${PREFIX_IMAGE}/attendance_method.svg`,
                    path: "/admin/attendance_method",
                    permission: "attendance-method",
                    ability: "READ"
                },
                {
                    name: "Contract",
                    icon: `${PREFIX_IMAGE}/reporting.svg`,
                    path: "/admin/contract",
                    permission: "contract",
                    ability: "READ"
                },
                {
                    name: "Worker",
                    icon: `${PREFIX_IMAGE}/worker.png`,
                    path: null,
                    permission: "worker",
                    ability: "READ",
                    open: false,
                    child: [
                        {
                            name: "Mitra",
                            icon: `${PREFIX_IMAGE}/worker.png`,
                            path: "/admin/workers/mitra",
                            permission: "worker-mitra",
                            ability: "READ"
                        },
                        {
                            name: "KYC Validation",
                            icon: `${PREFIX_IMAGE}/contact-book.svg`,
                            path: "/admin/workers/kyc",
                            permission: "worker-kyc",
                            ability: "READ"
                        },
                        {
                            name: "Task Assignment",
                            icon: `${PREFIX_IMAGE}/assignment.svg`,
                            path: "/admin/workers/task",
                            permission: "worker-task-assignment",
                            ability: "READ"
                        },
                        {
                            name: "Attendance",
                            icon: `${PREFIX_IMAGE}/attendance.svg`,
                            path: "/admin/workers/attendance",
                            permission: "worker-attendance",
                            ability: "READ"
                        },
                        {
                            name: "Salary",
                            icon: `${PREFIX_IMAGE}/salary.svg`,
                            path: "/admin/workers/salary",
                            forMitra: ["common"],
                            permission: "worker-salary",
                            ability: "READ"
                        },
                        {
                            name: "Salary Report",
                            icon: `${PREFIX_IMAGE}/salary.svg`,
                            path: "/admin/workers/salary/report",
                            forMitra: ["common"],
                            permission: "worker-salary-report",
                            ability: "READ"
                        },
                        {
                            name: "Cash Advance",
                            icon: `${PREFIX_IMAGE}/cash-advance.svg`,
                            path: "/admin/workers/cash-advance",
                            forMitra: ["common"],
                            permission: "worker-cash-advance",
                            ability: "READ"
                        },
                        {
                            name: "Leave Report",
                            icon: `${PREFIX_IMAGE}/schedule.svg`,
                            path: "/admin/workers/leave",
                            forMitra: ["common"],
                            permission: "worker-leave-report",
                            ability: "READ"
                        },
                        {
                            name: "Reimbursement",
                            icon: `${PREFIX_IMAGE}/reimbursement.svg`,
                            path: "/admin/workers/reimbursement",
                            forMitra: ["common"],
                            permission: "worker-reimbursement",
                            ability: "READ"
                        },
                        {
                            name: "Payslip",
                            icon: `${PREFIX_IMAGE}/payslip.svg`,
                            path: "/admin/workers/payslip",
                            forMitra: ["common"],
                            permission: "worker-payslip",
                            ability: "READ"
                        },
                        {
                            name: "Tax Deduction",
                            icon: `${PREFIX_IMAGE}/tax.svg`,
                            path: "/admin/workers/tax",
                            forMitra: ["common"],
                            permission: "worker-tax-deduction",
                            ability: "READ"
                        },
                        {
                            name: "Overtime Report",
                            icon: `${PREFIX_IMAGE}/overtime-report.svg`,
                            path: "/admin/workers/overtime-report",
                            forMitra: ["common"],
                            permission: "worker-overtime-report",
                            ability: "READ"
                        }
                    ]
                },
                {
                    name: "Announcement",
                    icon: `${PREFIX_IMAGE}/announcement.svg`,
                    path: "/admin/announcement",
                    permission: "announcement",
                    ability: "READ"
                },
                {
                    name: "Report",
                    icon: `${PREFIX_IMAGE}/dashboard.png`,
                    path: null,
                    permission: "report",
                    ability: "READ",
                    open: false,
                    child: [
                        {
                            name: "Mitra",
                            icon: `${PREFIX_IMAGE}/mitra_report.svg`,
                            path: "/admin/report/mitra",
                            permission: "report-mitra",
                            ability: "READ"
                        },
                        {
                            name: "Office List",
                            icon: `${PREFIX_IMAGE}/office-list.svg`,
                            path: "/admin/report/office",
                            permission: "report-office-list",
                            ability: "READ"
                        },
                        {
                            name: "Initiation",
                            icon: `${PREFIX_IMAGE}/init.svg`,
                            path: "/admin/report/initiation",
                            permission: "report-initiation",
                            ability: "READ"
                        },
                        {
                            name: "Initiation vs fulfillment",
                            icon: `${PREFIX_IMAGE}/init-fulfill.svg`,
                            path: "/admin/report/initiation_fulfillment",
                            permission: "report-initiation-vs-fulfillment",
                            ability: "READ"
                        },
                        {
                            name: "Mitra Attendance",
                            icon: `${PREFIX_IMAGE}/init-fulfill.svg`,
                            path: "/admin/report/mitra_attendance?tab=mitra_attendance",
                            permission: "report-mitra-attendance",
                            ability: "READ"
                        },
                        {
                            name: "User Attendance",
                            icon: `${PREFIX_IMAGE}/user-badge.svg`,
                            path: "/admin/report/user_attendance",
                            permission: "report-user-attendance",
                            ability: "READ"
                        }
                    ]
                },
                {
                    name: "Settings",
                    icon: `${PREFIX_IMAGE}/office-list.svg`,
                    path: null,
                    permission: "settings",
                    ability: "READ",
                    open: false,
                    child: [
                        {
                            name: "Setting Permission",
                            icon: `${PREFIX_IMAGE}/office-list.svg`,
                            path: "/settings/roles",
                            permission: "setting-role",
                            ability: "READ"
                        },
                        {
                            name: "Salary Settings",
                            icon: `${PREFIX_IMAGE}/money-setting.svg`,
                            path: "/settings/salary-settings",
                            permission: "salary-settings",
                            ability: "READ"
                        }
                    ],
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            sidebar_counter: 'notification/sidebar_counter',
            latest_version_data: 'platform/latest_version_data'
        }),
        user_cred() {
            return getUserLogin();
        },
    },
    async created() {
        await Promise.all([
            this.getUserLogin(),
            this.setLatestPlatformVersionData()
        ]).then(() =>
            this.mapMenu(),
        );
        // const path = this.$route.fullPath;
        // if (path.indexOf("/admin/report") > -1) {
        //     this.report = true;
        // } else if (path.indexOf("/admin/workers") > -1) {
        //     this.workers = true;
        // } else if (path.indexOf("/admin/applicant") > -1) {
        //     this.applicant = true;
        // } else if (path.indexOf("schedule") > -1 || path.indexOf("shift") > -1) {
        //     this.rostering = true;
        // }
    },
    methods: {
        ...mapActions({
            getProfile: "auth/getProfile",
            getLatestVersion: 'platform/getLatestVersion'
        }),
        async logout() {
            await this.$store.dispatch("auth/logout");
        },
        showInitiation() {
            this.initiation_dropdown = !this.initiation_dropdown;
        },
        async getUserLogin() {
            this.profile = await this.getProfile();
            const companyBasf = "BASF_Indonesia"?.toLowerCase();

            if (
                this.profile.company_name.search("shipper") !== -1 ||
                this.profile.company_name.search("Shipper") !== -1
            ) {
                this.shipper = true;
            } else if (this.profile.company_name.search("wheels") !== -1) {
                this.grabwheels = true;
            } else if (
                (this.profile.company_name.search("Astro") !== -1) ||
                (this.profile.company_name.search("Lion Parcel") !== -1) ||
                (this.profile.company_name.toLowerCase().search("kgx") !== -1) || 
                (this.profile.company_name?.toLowerCase()?.split(" ")?.join("_")?.includes(companyBasf)) === true
            ) {
                this.astro = true;
            }
        },
        getUserRole() {
            const profile = getUserLogin();
            if (profile.email.search("grab") != -1) {
                this.grab_user = true;
            } else {
                this.grab_user = false;
            }
            return formatter.getUserRole();
        },
        async mapMenu() {
            const newMenu = [];
            this.menus.forEach(item => {
                if (this.$can(item.ability, item.permission)) {
                    let newItem = {};
                    if (item.child && item.child.length > 0) {
                        const child = [];
                        item.child.forEach(childItem => {
                            if (this.$can(childItem.ability, childItem.permission)) {
                                if (this.astro && childItem.permission === 'rostering-shift') {
                                    childItem.path = '/admin/shift/daily';
                                }
                                child.push(childItem);
                            }
                        });
                        newItem = {
                            ...item,
                            child: child
                        };
                    } else {
                        newItem = {
                            ...item
                        };
                    }
                    newMenu.push(newItem);
                }
            });
            this.menus = newMenu;
        },
        consist(user_roles, value) {
            return value.some((i) => user_roles.includes(i));
        },
        open(idx){
            this.menus[idx].open = !this.menus[idx].open;
        },
        async setLatestPlatformVersionData() {
            try {
                if (navigator.onLine) { // Check internet connection 
                    const meta = { platform_category_name: 'compdash' };
                    await this.getLatestVersion(meta);
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
    },
};
</script>

<style scoped>
.title a {
  text-transform: none;
  color: #ffffff;
  cursor: auto;
  padding-left: 20px !important;
}
.width-large {
  width: 20.5%;
}
#toggle-animation {
  transition: width 500ms;
}
.card-sidebar {
  height: calc(100vh - 60px);
}
.sidebar {
  overflow: auto;
  background-color: #0abab5;
  width: 255px;
}
li {
  padding: 8px 0;
  border-top: 1px solid #f1f2f3;
}
li:last-child {
  border-bottom: 1px solid #f1f2f3;
}
.uk-active {
  background-color: rgba(45, 216, 211, 0.74);
  border-left: 5px solid #f5b338;
  font-weight: 600;
}
.uk-active .uk-icon {
  color: #f5b338;
}
li.uk-active a {
  color: #ffffff;
}
.uk-nav-default li a.just-icon {
  padding: 0;
  text-align: center;
  color: #ffffff;
}
.uk-nav-default li a {
  color: #ffffff;
}
.uk-nav-default li a:hover {
  color: #ffffff;
  font-weight: 600;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.uk-badge {
  background: red;
}
.version-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    color: white;
    text-decoration: none;
}
</style>
