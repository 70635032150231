import Vue from 'vue';
import api from '@/utils/api';
import { JOB_APPLICANT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    job_list: {docs:[], totalDocs: 0},
    job_applicant_list: {docs:[], totalDocs: 0},
    job_applicant_list_v4: {docs: [], totalDocs: 0},
    job_all_applicant_list: [],
    job_all_applicant_list_v4: []
};

const mutations = {
    "SET_JOB_LIST": (state, payload) => {
        Vue.set(state, 'job_list', payload);
    },
    "SET_JOB_APPLICANT_LIST": (state, payload) => {
        Vue.set(state, 'job_applicant_list', payload);
    },
    "SET_JOB_ALL_APPLICANT_LIST": (state, payload) => {
        Vue.set(state, 'job_all_applicant_list', payload);
    },
    "SET_APPLICANT_LIST_V4": (status, payload) => {
        Vue.set(status, 'job_applicant_list_v4', payload);
    },
    "SET_ALL_APPLICANT_LIST_V4": (status, payload) => {
        Vue.set(status, 'job_all_applicant_list_v4', payload);
    },
};

const actions = {
    async getJobList(context, data) {
        try {
            const response = await api.apiGetAuth(JOB_APPLICANT.LIST_JOB, data);
            context.commit('SET_JOB_LIST', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDashboardData(context, { provinceId }) {
        try {
            const response = await api.apiGetAuth(JOB_APPLICANT.DASHBOARD(provinceId));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobApplicantList(context, data) {
        try {
            const {id, ...filter} = data;
            const response = await api.apiGetAuth(JOB_APPLICANT.LIST_APPLICANT(id), filter);
            context.commit('SET_JOB_APPLICANT_LIST', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobApplicantListV4(context, data) {
        try {
            const {...filter} = data;
            const response = await api.apiGetAuth(JOB_APPLICANT.LIST_APPLICANT_V4, filter);
            context.commit('SET_APPLICANT_LIST_V4', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobAllApplicantList(context, data) {
        try {
            const {id, ...filter} = data;
            const response = await api.apiGetAuth(JOB_APPLICANT.ALL_LIST_APPLICANT(id), filter);
            context.commit('SET_JOB_ALL_APPLICANT_LIST', response.data.result.docs ?? []);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getReportAllApplicant(context, data) {
        try {
            const response = await api.apiGetAuth(JOB_APPLICANT.REPORT_ALL_APPLICANT, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getReportApplicantList(context, data) {
        try {
            const {id, ...filter} = data;
            const response = await api.apiGetAuth(JOB_APPLICANT.REPORT_LIST_APPLICANT(id), filter);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDetailCandidate(context, { jobApplyId }) {
        try {
            const response = await api.apiGetAuth(JOB_APPLICANT.DETAIL_CANDIDATE(jobApplyId));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadCVApplicant(context, { userId }) {
        try {
            const response = await api.apiGetAuth(JOB_APPLICANT.DOWNLOAD_CV_APPLICANT(userId));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDetailOtherJob(context, data) {
        try {
            const {id, ...filter} = data;
            const response = await api.apiGetAuth(JOB_APPLICANT.DETAIL_OTHER_JOB(id), filter);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getInterviewSchedule(context, data) {
        const { companyId, meta } = data;
        const response = await api.apiGetAuth(JOB_APPLICANT.INTERVIEW_SCHEDULE_LIST(companyId), meta);
        return response.data;
    },
    async getAllJobApplicantListV4(context, data) {
        try {
            const { ...filter } = data;
            const response = await api.apiGetAuth(JOB_APPLICANT.ALL_LIST_APPLICANT_V4, filter);
            context.commit('SET_ALL_APPLICANT_LIST_V4', response.data.result);
        } catch (error) {
            notificationDanger(error);
        }

    }
};

const getters = {
    job_list: state => {
        return state.job_list;
    },
    job_applicant_list: state => {
        return state.job_applicant_list;
    },
    job_all_applicant_list: state => {
        return state.job_all_applicant_list;
    },
    job_applicant_list_v4: state => {
        return state.job_applicant_list_v4;
    },
    job_all_applicant_list_v4: state => {
        return state.job_all_applicant_list_v4;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
