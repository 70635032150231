<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin-small-bottom">
            <div class="uk-margin-auto-vertical uk-text-bold">Reimbursement Request</div>
            <button
                @click="$router.back()"
                >
                <img :src="`${images}/cross.svg`" alt="" width="25">
            </button>
        </div>
        <div v-if="data && $can('READ', 'worker-reimbursement')" class="uk-flex uk-flex-left">
            <div class="uk-width-auto">
                <table id="table-data">
                    <tbody>
                        <tr>
                            <td>Reimbursement ID</td>
                            <td>: {{ data._id }}</td>
                        </tr>
                        <tr>
                            <td>Employee Name</td>
                            <td>: {{ data.user.fullname }}</td>
                        </tr>
                        <tr>
                            <td>Request Date</td>
                            <td>: {{ moment(data.request_date).format('DD MMM YYYY') }}</td>
                        </tr>
                        <tr>
                            <td>Notes</td>
                            <td>: {{ data.request_note }}</td>
                        </tr>
                        <tr>
                            <td>Amount (Rp)</td>
                            <td>: {{ formatCurrency(parseInt(data.request_amount)) }}</td>
                        </tr>
                        <tr>
                            <td>Aprroved By</td>
                            <td>: {{ data.decision_by || '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="uk-margin-xlarge-left uk-width-auto uk-flex uk-flex-column">
                <div><span class="uk-text-bold">Payment Information</span></div>
                <div>
                    <table id="table-data">
                        <tbody>
                            <tr>
                                <td>Bank Name</td>
                                <td>: {{ data.bank_info && data.bank_info.bank_name ? data.bank_info.bank_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Account Holder</td>
                                <td>: {{ data.bank_info && data.bank_info.bank_account_name ? data.bank_info.bank_account_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Account Number</td>
                                <td>: {{ data.bank_info && data.bank_info.bank_account_number ? data.bank_info.bank_account_number : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- Card -->
        <div v-if="data && $can('READ', 'worker-reimbursement')" id="card-data" class="uk-margin uk-card uk-card-small uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
            <div class="uk-flex uk-flex-row uk-flex-between">
                <div class="uk-flex uk-flex-column">
                    <div class="uk-text-bold uk-margin-small-bottom">Request Notes</div>
                    <div class="uk-text-small uk-margin-small-bottom">{{ data.request_note }}</div>
                    <template v-if="data.max_ticket">
                        <div class="uk-text-bold uk-margin-small-bottom">Available Ticket Amount</div>
                        <div class="uk-text-small">{{ formatCurrency(data.max_ticket) }}</div>
                    </template>
                    <div class="uk-text-bold uk-margin-small-bottom">Attachment</div>
                    <div class="uk-text-small">
                        <a v-if="data.document_attachment" href="javascript:;" @click="openImage({file_url: data.document_attachment.file_url})">View</a>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="uk-flex uk-flex-column">
                    <template v-if="data.request_voucher_ids && data.request_voucher_ids.length > 0" >
                        <div class="uk-text-bold uk-margin-small-bottom">Requested Ticket Amount</div>
                        <div class="uk-text-small uk-margin-small-bottom">{{ data.request_voucher_ids.length }}</div>
                        <div class="uk-text-bold uk-margin-small-bottom">Approved Ticket Amount</div>
                        <div class="uk-text-small">{{ data.decision_voucher_ids != [] ? data.decision_voucher_ids.length : '-' }}</div>
                    </template>
                    <template v-else>
                        <div class="uk-text-bold uk-margin-small-bottom">Requested Amount</div>
                        <div class="uk-text-small uk-margin-small-bottom">{{ formatCurrency(data.request_amount) }}</div>
                        <div class="uk-text-bold uk-margin-small-bottom">Approved Amount</div>
                        <div class="uk-text-small">{{ data.decision_amount ? formatCurrency(data.decision_amount) : '-' }}</div>
                    </template>
                </div>
                <div class="uk-flex uk-flex-column">
                    <div class="uk-text-bold uk-margin-small-bottom">Status</div>
                    <div class="uk-text-small uk-margin-small-bottom" :style="{ color: statusColor(data.status)}">{{ formatStatus(data.status) }}</div>
                    <div class="uk-text-bold uk-margin-small-bottom">Approval/Rejection Notes</div>
                    <div class="uk-text-small">{{ data.decision_note ? data.decision_note : '-' }}</div>
                </div>
                <div v-if="$can('EDIT', 'worker-reimbursement')" class="uk-flex uk-flex-row">
                    <template v-if="data.status == 1">
                        <div class="uk-margin-auto-vertical">
                            <button class="uk-button uk-button-primary uk-border-rounded" @click="showModal('approve')">Approve</button>
                        </div>
                        <div class="uk-margin-auto-vertical uk-margin-left">
                            <button class="uk-button uk-button-danger uk-border-rounded" @click="showModal('reject')">Reject</button>
                        </div>
                    </template>
                    <template v-if="data.status == 2">
                        <div class="uk-margin-auto-vertical">
                            <button class="uk-button uk-button-primary uk-border-rounded" @click="showModal('change-status')">Change Status</button>
                        </div>
                    </template>
                    <template v-if="data.status == 4 || data.status == 3">
                        <div class="uk-margin-auto-vertical">
                            <button id="disabled" class="uk-button uk-border-rounded">Change Status</button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- Card -->

        <!-- Modal box Change Status -->
        <div id="change-status" class="change-status" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('change-status')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Change Status</h2>
                </div>
                <div class="uk-modal-body">
                    Change Status to Transferred?
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="transfer">Save</button>
                </div>
            </div>
        </div>
        <!-- End modal box Change Status -->

        <!-- Modal box Approve ticket -->
        <div id="approve" class="approve" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('approve')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Approve Reimbursement Request</h2>
                </div>
                <div v-if="data" class="uk-modal-body">
                    <div class="uk-width-auto uk-margin-bottom">
                        <table id="table-data">
                            <tbody>
                                <tr>
                                    <td>Reimbursement ID</td>
                                    <td>{{ data._id }}</td>
                                </tr>
                                <tr v-if="data.max_ticket">
                                    <td>Available Ticket Amount</td>
                                    <td>{{ formatCurrency(data.max_ticket) }}</td>
                                </tr>
                                <tr v-if="data.max_ticket">
                                    <td>Requested Ticket Amount</td>
                                    <td>{{ formatCurrency(data.request_voucher_ids.length) }}</td>
                                </tr>
                                <tr v-else>
                                    <td>Requested Amount</td>
                                    <td>{{ formatCurrency(data.request_amount) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <form @submit.prevent>
                        <div class="uk-margin">
                            <label for="decision-amount">Please enter the approved ticket amount</label><br>
                            <input v-if="data.max_ticket" id="decision-amount" type="number" class="uk-input" :max="data.max_ticket" min="0" v-model="form.decision_ticket">
                            <input v-else id="decision-amount" type="number" class="uk-input" min="0" v-model="form.decision_amount">
                        </div>
                        <div class="uk-margin">
                            <label for="decision-note">Notes (optional)</label><br>
                            <input id="decision-note" type="text" class="uk-input" v-model="form.decision_note">
                        </div>
                    </form>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="approve">Save</button>
                </div>
            </div>
        </div>
        <!-- End modal box Approve -->

        <!-- Modal box Reject -->
        <div id="reject" class="reject" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('reject')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Reject Reimbursement Request</h2>
                </div>
                <div v-if="data" class="uk-modal-body">
                    <div class="uk-width-auto uk-margin-bottom">
                        <table id="table-data">
                            <tbody>
                                <tr>
                                    <td>Reimbursement ID</td>
                                    <td>{{ data._id }}</td>
                                </tr>
                                <tr v-if="data.max_ticket">
                                    <td>Available Ticket Amount</td>
                                    <td>{{ formatCurrency(data.max_ticket) }}</td>
                                </tr>
                                <tr v-if="data.max_ticket">
                                    <td>Requested Ticket Amount</td>
                                    <td>{{ formatCurrency(data.request_voucher_ids.length) }}</td>
                                </tr>
                                <tr v-else>
                                    <td>Requested Amount</td>
                                    <td>{{ formatCurrency(data.request_amount) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <form @submit.prevent>
                        <div class="uk-margin">
                            <label for="decision-note">Rejection Notes</label><br>
                            <input id="decision-note" type="text" class="uk-input" v-model="form.decision_note">
                        </div>
                    </form>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="reject">Save</button>
                </div>
            </div>
        </div>
        <!-- End modal box Reject -->
        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
        <PreviewPDFModal
            v-if="modal.isShowPreviewPDFModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewPDFModal = false"
        />
        <PreviewOfficeModal
            v-if="modal.isShowPreviewOfficeModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewOfficeModal = false"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import moment from 'moment';
moment.locale('id');
import { PREFIX_IMAGE } from '@/utils/constant';
import { contentType } from '@/utils/helper';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import PreviewImageModal from '@/components//globals/modals/ImageViewer.vue';
import PreviewPDFModal from '@/components//globals/modals/PdfViewer.vue';
import PreviewOfficeModal from '@/components//globals/modals/OfficeViewer.vue';

export default {
    components: {
        PreviewPDFModal,
        PreviewImageModal,
        PreviewOfficeModal,
    },
    data({ $route }) {
        return {
            moment: moment,
            meta: $route.query,
            data: null,
            images: PREFIX_IMAGE,
            previewSrc: "",
            modal: {
                isShowPreviewImageModal: false,
                isShowPreviewPDFModal: false,
                isShowPreviewOfficeModal: false,
            },
            form: {
                decision_ticket: null,
                decision_amount: null,
                decision_note: null
            }
        };
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        async meta() {
            await this.getData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.change-status')) window.UIkit.modal('.change-status').$destroy(true);
        if(window.UIkit.modal('.approve')) window.UIkit.modal('.approve').$destroy(true);
        if(window.UIkit.modal('.reject')) window.UIkit.modal('.reject').$destroy(true);
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        ...mapActions({
            getReimbursementDetail: 'salary_adjustment/getReimbursementDetail',
            transferReimbursement: 'salary_adjustment/transferReimbursement',
            approveReimbursement: 'salary_adjustment/approveReimbursement',
            approveReimbursementVoucher: 'salary_adjustment/approveReimbursementVoucher',
            rejectReimbursement: 'salary_adjustment/rejectReimbursement',
            subtractReimbursementCounter: 'notification/subtractReimbursementCounter'
        }),
        async getData() {
            this.data = await this.getReimbursementDetail(this.meta.salary_adjustment_id);
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        formatStatus(data) {
            switch (data) {
            case 1:
                return 'Requested';
            case 2:
                return 'Accepted';
            case 3:
                return 'Rejected';
            case 4:
                return 'Transferred';
            }
        },
        statusColor(data) {
            switch (data) {
            case 1:
                return '#000000';
            case 2:
                return '#009688';
            case 3:
                return '#D8020F';
            case 4:
                return '#0275D8';
            }
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'change-status':
                await window.UIkit.modal('#change-status').hide();
                break;
            case 'approve':
                await window.UIkit.modal('#approve').hide();
                this.resetForm();
                break;
            case 'reject':
                await window.UIkit.modal('#reject').hide();
                this.resetForm();
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'change-status':
                await window.UIkit.modal('#change-status').show();
                break;
            case 'approve':
                await window.UIkit.modal('#approve').show();
                break;
            case 'reject':
                await window.UIkit.modal('#reject').show();
                break;
            }
        },
        async transfer() {
            const response = await this.transferReimbursement(this.meta.salary_adjustment_id);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('change-status');
                await this.getData();
                this.resetForm();
            } else {
                notificationDangerCustom('Failed to transfer!');
            }
        },
        async approve() {
            if (this.data.max_ticket) {
                if (this.data.max_ticket < this.form.decision_ticket) {
                    notificationDangerCustom('Approved ticket amount must be less than available ticket amount!');
                    return null;
                }
            }
            let response = null;
            if (this.data.max_ticket) {
                delete this.form.decision_amount;
                response = await this.approveReimbursementVoucher({...this.form, _id: this.meta.salary_adjustment_id});
            } else {
                delete this.form.decision_ticket;
                response = await this.approveReimbursement({...this.form, _id: this.meta.salary_adjustment_id});
            }
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('approve');
                await this.subtractReimbursementCounter();
                await this.getData();
                this.resetForm();
            } else {
                notificationDangerCustom('Failed to approve!');
            }
        },
        async reject() {
            const response = await this.rejectReimbursement({_id: this.meta.salary_adjustment_id, decision_note: this.form.decision_note});
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('reject');
                await this.subtractReimbursementCounter();
                await this.getData();
                this.resetForm();
            } else {
                notificationDangerCustom('Failed to transfer!');
            }
        },
        resetForm() {
            this.form.decision_ticket = null;
            this.form.decision_amount = null;
            this.form.decision_note = null;
        },
        openImage(data) {
            this.previewSrc = data.file_url;
            this.modal[contentType(this.previewSrc)] = true;
        },
    }
};
</script>

<style scoped>
#table-data td {
    padding: 8px;
    padding-left: 0px;
    font-size: 14px;
    font-weight: 400;
    border-style: hidden;
    border-collapse: collapse;
}
#table-data tr:nth-child(even){background-color: white;}
#card-data {
    color: black;
}
#disabled {
    background: #9E9E9E;
    color: white;
    cursor: not-allowed;
}
</style>
