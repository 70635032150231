import api from '@/utils/api';
import { KYC } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getKYCMitraList(context, data) {
        try {
            const response = await api.apiGetAuth(KYC.GET_KYC_MITRA, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getKYCMitraDetail(context, id) {
        try {
            const response = await api.apiGetAuth(KYC.GET_DETAIL_KYC_MITRA(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async validateKYCDataSilver(context, data) {
        try {
            const id = data.id;
            delete data.id;
            const response = await api.apiPostAuth(KYC.VALIDATE_KYC_DATA_SILVER(id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async validateKYCDataGold(context, data) {
        try {
            const id = data.id;
            delete data.id;
            const response = await api.apiPostAuth(KYC.VALIDATE_KYC_DATA_GOLD(id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async validateKYCDataPlatinum(context, data) {
        try {
            const id = data.id;
            delete data.id;
            const response = await api.apiPostAuth(KYC.VALIDATE_KYC_DATA_PLATINUM(id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async markMitraKYCLabel(context, data) {
        try {
            const response = await api.apiPostAuth(KYC.MARK_MITRA_LABEL, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
