<template>
    <div>
        <SilverValidationEdit
            v-if="isValidating"
            :data="validationData"
            @cancel-validation="cancelValidation"
            @save-validation="saveValidation"
        />
        <SilverValidationView
            v-else
            :data="data"
            :table-loading="tableLoading"
            :validated="validated"
            @validate="$emit('validate', { type: 'silver' })"
            @unlabel="$emit('unlabel', { type: 'silver' })"
            @read-validation-notes="$emit('read-validation-notes', { type: 'silver' })"
            @is-validating="enterValidationMode"
        />
    </div>
</template>

<script>
import SilverValidationView from "@/components/globals/kyc/view/SilverValidation";
import SilverValidationEdit from "@/components/globals/kyc/edit/SilverValidation";

export default {
    name: 'SilverValidationTable',
    components: {
        SilverValidationView,
        SilverValidationEdit,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        validated: {
            type: Object,
            required: false,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            isValidating: false,
            validationData: {},
        };
    },
    methods: {
        enterValidationMode() {
            this.validationData = {...this.data};
            this.isValidating = true;
        },
        cancelValidation() {
            this.isValidating = false;
        },
        saveValidation() {
            this.isValidating = false;
            this.$emit('re-fetch');
        },
    }
};
</script>

<style scoped></style>
