<template>
  <section>
    <div id="interview-modal" class="uk-flex-top interview-modal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
          <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
          <div class="uk-modal-header">
              <h2 class="uk-modal-title">Resend Contract</h2>
          </div>
          <form @submit.prevent="handleInterviewApplicant">
            <div class="uk-padding uk-grid-small" uk-grid>
              <div class="uk-width-1-1">
                <label for="startDate" class="uk-form-label">Start Date <span class="uk-text-danger">*</span></label>
                <div class="uk-inline uk-width-expand">
                  <datetime
                    id="startDate"
                    type="date"
                    name="start_date"
                    format="dd MMM yyyy"
                    input-class="uk-input"
                    :max-datetime="formData.end_date"
                    :class="{'uk-form-danger': errors.has('formData.start_date')}"
                    v-model="formData.start_date"
                    v-validate="'required'"
                    required
                  ></datetime>
                  <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                </div>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('formData.start_date')">{{ errors.first('formData.start_date') }}</span>
              </div>
              <div class="uk-width-1-1">
                <label for="endDate" class="uk-form-label">End Date</label>
                <div class="uk-inline uk-width-expand">
                  <datetime
                    id="endDate"
                    type="date"
                    name="end_date"
                    format="dd MMM yyyy"
                    input-class="uk-input"
                    :min-datetime="formData.start_date"
                    :class="{'uk-form-danger': errors.has('formData.end_date')}"
                    :disabled="!formData.start_date"
                    v-model="formData.end_date"
                    v-validate="'required'"
                    required
                  ></datetime>
                  <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                </div>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('formData.end_date')">{{ errors.first('formData.end_date') }}</span>
              </div>
              <div class="uk-width-1-1">
                <label for="employment_type" class="uk-form-label">Employment Type <span class="uk-text-danger">*</span></label>
                <multiselect
                  id="employment_type"
                  v-model="formData.employment_type"
                  placeholder="Select employment type"
                  name="employment_type"
                  :class="{'uk-form-danger': errors.has('employment_type')}"
                  :options="options"
                  v-validate="'required'"
                  required
                ></multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('employment_type')">{{ errors.first('employment_type') }}</span>
              </div>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button 
                v-if="isLoading"
                class="uk-button uk-button-default uk-flex uk-flex-middle uk-flex-center"
                disabled
              >
                <span><div uk-spinner="ratio: 0.5"></div></span>
                <span class="uk-margin-small-left">Loading...</span>
              </button>
              <button 
                v-else
                type="submit"
                class="uk-button" 
                :class="[buttonDisabled ? 'uk-button-default' : 'uk-button-primary']"
                :disabled="buttonDisabled"
              >
                Save
              </button>
            </div>
          </form>
      </div>
  </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Datetime,
        Multiselect,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        isMulti: {
            type: Boolean,
            required: true,
        }
    },
    data(){
        return {
            datetime: DateTime,
            isLoading: false,
            formData: {
                id: this.$props.id,
                start_date: null,
                end_date: null,
                employment_type: null
            },
            options: ['PKWT', 'KEMITRAAN', 'DAILY WORKER']
        };
    },
    watch: {
        "this.formData": {
            deep: true,
            async handler() {
                this.validate();
            },
        },
    },
    mounted() {
        window.UIkit.modal('#interview-modal').show();
    },
    computed: {
        buttonDisabled() {
            if (
                this.formData.start_date == null
            ) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapActions({
            resentContractApplicant: 'applicant/resentContractApplicant'
        }),
        hideModal() {
            window.UIkit.modal('#interview-modal').$destroy(true);
            this.$emit("hideModal");
        },
        async handleInterviewApplicant() {
            const validate = await this.$validator.validateAll(this.formData);
            console.log('v == ', validate);
            console.log('val == ', this.$validator.errors);
            if (!validate || this.$validator.errors.any()) return;

            this.isLoading = true;
            const response = await this.resentContractApplicant(this.formData);

            if (response && response.status === 'OK') {
                notificationSuccess('Change status success!');
                window.UIkit.modal('#interview-modal').$destroy(true);
                this.$emit("onSave");
            } else {
                notificationDangerCustom('Failed to change status applicant!');
            }

            this.isLoading = false;
        },
    }
};
</script>
