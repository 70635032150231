import api from '@/utils/api';
import { SETTING_ACCESS, ATTENDANCE_SETTING } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getRoleList(context, data) {
        try {
            const response = await api.apiGetAuth(SETTING_ACCESS.GET_ROLE_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRoleDetail(context, data) {
        try {
            const response = await api.apiGetAuth(SETTING_ACCESS.GET_ROLE_DETAIL(data.role_id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateOrCreateRole(context, data) {
        try {
            const response = await api.apiPatchAuth(SETTING_ACCESS.UPDATE_OR_CREATE_ROLE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAttendanceSelfScanSetting(context, data) {
        try {
            const response = await api.apiGetAuth(ATTENDANCE_SETTING.SELF_SCAN_SETTING, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async setAttendanceSelfScanSetting(context, data) {
        try {
            const response = await api.apiPatchAuth(ATTENDANCE_SETTING.SELF_SCAN_SETTING, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
