<template>
  <section>
    <div id="interview-modal" class="uk-flex-top interview-modal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
          <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
          <div class="uk-modal-header">
              <h2 class="uk-modal-title">Reject Applicant</h2>
          </div>
          <form @submit.prevent="handleInterviewApplicant">
            <div class="uk-padding uk-grid-small" uk-grid>
              <div class="uk-width-1-1">
                <label for="rejectNotes" class="uk-form-label">Reject Notes</label>
                <textarea 
                  id="rejectNotes"
                  name="reject_reason"
                  rows="5"
                  class="uk-textarea"
                  placeholder="Reason"
                  v-model="formData.reject_reason"
                ></textarea>
              </div>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button 
                v-if="isLoading"
                class="uk-button uk-button-default uk-flex uk-flex-middle uk-flex-center"
                disabled
              >
                <span><div uk-spinner="ratio: 0.5"></div></span>
                <span class="uk-margin-small-left">Loading...</span>
              </button>
              <button v-else type="submit" class="uk-button uk-button-primary">
                Save
              </button>
            </div>
          </form>
      </div>
  </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';

export default {
    props: {
        id: {
            type: String,
            default: '',
        },
        ids: {
            type: Array,
            default: () => [],
        },
        isMulti: {
            type: Boolean,
            required: true,
        }
    },
    data(){
        return {
            isLoading: false,
            formData: {
                job_applicant_ids: this.$props.ids,
                id: this.$props.id,
                reject_reason: null,
            }
        };
    },
    mounted() {
        window.UIkit.modal('#interview-modal').show();
    },
    methods: {
        ...mapActions({
            rejectMultiApplicant: 'applicant/rejectMultiApplicant',
            rejectApplicant: 'applicant/rejectApplicant',
        }),
        hideModal() {
            window.UIkit.modal('#interview-modal').$destroy(true);
            this.$emit("hideModal");
        },
        async handleInterviewApplicant() {
            let pathApi = 'rejectApplicant';

            if (this.$props.isMulti) {
                pathApi = 'rejectMultiApplicant';
                delete this.formData.id;
            } else {
                delete this.formData.job_applicant_ids;
            }

            this.isLoading = true;
            const response = await this[pathApi](this.formData);

            if (response && response.status === 'OK') {
                notificationSuccess('Change status success!');
                window.UIkit.modal('#interview-modal').$destroy(true);
                this.$emit("onSave");
            } else {
                notificationDangerCustom('Failed to change status applicant!');
            }

            this.isLoading = false;
        },
    }
};
</script>
