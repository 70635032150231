<template>
    <div>
        <div class="uk-flex uk-flex-between myrobin-background-white uk-padding-small">
            <div class="uk-flex uk-flex-middle">
                <p class="uk-text-bold uk-margin-remove">Mitra Data Validation</p>
            </div>
            <div class="uk-flex uk-flex-middle" style="gap: 10px;">
                <button
                    type="button"
                    class="uk-button uk-border-rounded myrobin-background-white myrobin-color-green myrobin-border-green uk-position-relative uk-width-auto"
                    @click="$emit('cancel-validation')"
                >Cancel</button>
                <button
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="disableSaveValidationButon
                        ? 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                        : 'myrobin-background-primary myrobin-color-white'
                    "
                    :disabled="disableSaveValidationButon"
                    @click="saveValidation()"
                >Save</button>
            </div>
        </div>
        <div class="uk-width-1-1 uk-card uk-card-default uk-margin-top">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p>Mitra Name (from PWA)</p>
                                <p class="uk-text-italic text-color-blue">Mitra Full Name by MDM</p>
                            </td>
                            <td>
                                <p>
                                    <input v-model="data.fullname" class="uk-input" type="text" disabled placeholder="-">
                                </p>
                                <p>
                                    <input v-model="form.fullname_ktp" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Mitra Phone Number (from PWA)</p>
                                <p class="uk-text-italic text-color-blue">Mitra WA Number by MDM</p>
                            </td>
                            <td>
                                <p>
                                    <input v-model="data.phone_number" class="uk-input" type="text" disabled placeholder="-">
                                </p>
                                <p>
                                    <input v-model="form.whatsapp_number" class="uk-input" type="text">
                                    <span
                                        v-show="hasError.whatsapp_number"
                                        class="uk-text-small uk-text-danger"
                                    >Please input the correct phone number</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>KTP Photo (from PWA)</div>
                                <p class="uk-text-italic text-color-blue">KTP Photo by MDM</p>
                            </td>
                            <td>
                                <div v-if="data.ktp_mitra && data.ktp_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.ktp_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.ktp_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate myrobin-color-gray">{{ data.ktp_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                                <p>
                                    <input v-model="form.ktp_mdm" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>Mitra NIK (from PWA)</td>
                            <td>
                                <input v-model="form.identity_number" class="uk-input" type="text">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Address KTP (from PWA)</p>
                                <p class="uk-text-italic text-color-blue">Domicile Address by MDM</p>
                            </td>
                            <td class="uk-text-truncate">
                                <p>
                                    <input v-model="data.address" class="uk-input" type="text" disabled placeholder="-">
                                </p>
                                <p>
                                    <input v-model="form.domicile_address" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>Birthplace</td>
                            <td>
                                <Multiselect
                                    v-model="form.birthplace"
                                    placeholder="Type to search city"
                                    label="name"
                                    track-by="name"
                                    :options="cities"
                                    :loading='isLoading'
                                    :searchable="true"
                                    @search-change="getCityOptions"
                                ></Multiselect>
                            </td>
                        </tr>
                        <tr>
                            <td>Birthdate</td>
                            <td>
                                <datetime
                                    v-model="form.birthdate"
                                    name="date"
                                    id="date"
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    format="dd LLL yyyy"
                                    :max-datetime="datetime.now().toISODate()"
                                ></datetime>
                            </td>
                        </tr>
                        <tr v-for="(contact, id) in contactForm" :key="id">
                            <td class="uk-text-left uk-text-nowrap">{{ contact.label }}</td>
                            <td class="uk-text-left uk-text-nowrap uk-flex uk-flex-column">
                                <input v-model="form.contacts[contact.id-1][contact.value]" class="uk-input" type="text">
                                <span
                                    v-show="formError.id == contact.id && !form.contacts[contact.id-1][contact.value]"
                                    class="uk-text-small uk-text-danger"
                                >{{ contact.label }} is required</span>
                                <span
                                    v-show="contact.value == 'phone_number' && hasError.phone_number == contact.id"
                                    class="uk-text-small uk-text-danger"
                                >Please input the correct phone number</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import Multiselect from 'vue-multiselect';
import { notificationSuccess, notificationDangerCustom } from "@/utils/notification";
import PreviewImageModal from '@/components/globals/modals/ImageViewer.vue';

export default {
    name: 'SilverValidationTableEdit',
    components: {
        Datetime,
        Multiselect,
        PreviewImageModal,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
    },
    data() {
        return {
            datetime: DateTime,
            isLoading: false,
            form: { ...this.$props.data },
            cities: [
                { _id: this.$props.data.birthplace?._id, name: this.$props.data.birthplace?.name }
            ],
            contactForm: [
                {
                    id: 1,
                    label: 'Emergency Contact Name (1)',
                    value: 'name'
                },
                {
                    id: 1,
                    label: 'Emergency Contact Relation (1)',
                    value: 'relationship'
                },
                {
                    id: 1,
                    label: 'Emergency Contact Phone Number (1)',
                    value: 'phone_number'
                },
                {
                    id: 2,
                    label: 'Emergency Contact Name (2)',
                    value: 'name'
                },
                {
                    id: 2,
                    label: 'Emergency Contact Relation (2)',
                    value: 'relationship'
                },
                {
                    id: 2,
                    label: 'Emergency Contact Phone Number (2)',
                    value: 'phone_number'
                },
                {
                    id: 3,
                    label: 'Emergency Contact Name (3)',
                    value: 'name'
                },
                {
                    id: 3,
                    label: 'Emergency Contact Relation (3)',
                    value: 'relationship'
                },
                {
                    id: 3,
                    label: 'Emergency Contact Phone Number (3)',
                    value: 'phone_number'
                },
            ],
            formError: {
                id: null,
            },
            hasError: {
                phone_number: false,
                whatsapp_number: false,
            },
            phoneRgxp: /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{4}[-\s.]?[0-9]{3,6}$/im,
            previewSrc: null,
            modal: {
                isShowPreviewImageModal: false,
            }
        };
    },
    computed: {
        disableSaveValidationButon() {
            let disabled = false;
            if (this.formError.id || this.hasError.phone_number || this.hasError.whatsapp_number) {
                disabled = true;
            }

            return disabled;
        }
    },
    mounted() {
        this.createContactForm();
    },
    watch: {
        'form.contacts': {
            deep: true,
            async handler() {
                this.checkContactForm();
            },
        },
        'form.whatsapp_number'(value) {
            this.hasError.whatsapp_number = !this.phoneRgxp.test(value);
        },
    },
    methods: {
        ...mapActions({
            getCities: 'option_data/getCities',
            validateKYCDataSilver: 'kyc/validateKYCDataSilver',
        }),
        async getCityOptions(query){
            this.isLoading = true;
            this.cities = await this.getCities({ name: query });
            this.isLoading = false;
        },
        createContactForm() { // create default emergency contact if contact data less than 3
            const totalContact = this.form.contacts.length;
            const maxContact = 3 - totalContact;

            if (maxContact > 0) {
                let contact = [];
                for (let i = 0; i < maxContact; i++) {
                    contact[i] = {
                        name: '',
                        relationship: '',
                        phone_number: '',
                    };
                }

                this.form.contacts = [ ...this.form.contacts, ...contact ];
            }
        },
        checkContactForm() {
            for(let i=0; i < this.form.contacts.length; i++) {
                let countField = 0;
                const contact = this.form.contacts[i];
                for(var key in contact) {
                    if (contact[key]) {
                        countField++;
                    }
                }
                // check if all field in contact form is filled
                if (countField !=3 && countField != 0) {
                    this.formError.id = i=1;
                    break;
                } else {
                    this.formError.id = null;
                }

                if (contact.phone_number) { // check if phone number format is correct
                    if (!this.phoneRgxp.test(contact.phone_number)) {
                        this.hasError.phone_number = i+1;
                    } else {
                        this.hasError.phone_number = null;
                    }
                }
            }
        },
        async saveValidation() {
            // remove incomplete contact form
            const contactForm = this.form.contacts.filter((c) => {
                if (c.name && c.relationship && c.phone_number) {
                    return c;
                }
            });
            const payload = {
                id: this.$route.params.id,
                contacts: contactForm,
            };
            if (this.form.fullname_ktp) {
                payload.fullname_ktp = this.form.fullname_ktp;
            }
            if (this.form.whatsapp_number) {
                payload.whatsapp_number = this.form.whatsapp_number;
            }
            if (this.form.ktp_mdm) {
                payload.ktp_mdm = this.form.ktp_mdm;
            }
            if (this.form.identity_number) {
                payload.identity_number = this.form.identity_number;
            }
            if (this.form.domicile_address) {
                payload.domicile_address = this.form.domicile_address;
            }
            if (this.form.birthplace) {
                payload.birthplace_city_id = this.form.birthplace._id;
            }
            if (this.form.birthdate) {
                payload.birthdate = this.form.birthdate;
            }

            const response = await this.validateKYCDataSilver(payload);
            if (response && response.status == 'OK') {
                notificationSuccess('Mitra silver data validated!');
            } else {
                notificationDangerCustom('Failed to validate mitra silver data.');
            }

            this.$emit('save-validation');
        },
        previewImage({ file_url }) {
            this.previewSrc = file_url;
            this.modal.isShowPreviewImageModal = true;
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
    color: white !important;
}
tbody, input {
    color: #000 !important;
}
input:disabled {
    background-color: #e5e5e5 !important;
}
.myrobin-color-white {
    color: #ffffff;
}
</style>
