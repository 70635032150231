import api from '@/utils/api';
import { PROFILE } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getProfile(context, data) {
        try {
            const response = await api.apiGetAuth(PROFILE.GET_UPDATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateProfile(context, data) {
        try {
            const response = await api.apiPutAuth(PROFILE.GET_UPDATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateUserCompany(context, data) {
        try {
            const response = await api.apiPatchAuth(PROFILE.SWITCH_COMPANY, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadProfileImage(context, data) {
        try {
            const response = await api.apiPostAuth(PROFILE.UPLOAD_IMAGE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getNotification(context, data) {
        try {
            const response = await api.apiGetAuth(PROFILE.NOTIFICATION, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getNotifCounter(context, data) {
        try {
            const response = await api.apiGetAuth(PROFILE.COUNTER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async readNotification(context, data) {
        try {
            const response = await api.apiPatchAuth(PROFILE.NOTIFICATION, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async readAllNotification(context, data) {
        try {
            const response = await api.apiPatchAuth(PROFILE.READ_ALL_NOTIFICATION, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
