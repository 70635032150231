const READ = 1, WRITE = 2, EDIT = 4, DELETE = 8;
const propAbility = (ability) => {
    return {
        READ: !!(ability & READ),
        WRITE: !!(ability & WRITE),
        EDIT: !!(ability & EDIT),
        DELETE: !!(ability & DELETE),
    };
};
const sumAbility = (ability) => {
    let sumAbility = 0;
    if (ability.READ) {
        sumAbility += READ;
    }
    if (ability.WRITE) {
        sumAbility += WRITE;
    }
    if (ability.EDIT) {
        sumAbility += EDIT;
    }
    if (ability.DELETE) {
        sumAbility += DELETE;
    }
    return sumAbility;
};
export {
    propAbility,
    sumAbility,
};

