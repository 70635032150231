import api from '@/utils/api';
import { APPLICANT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getApplicantList(context, data) {
        try {
            const response = await api.apiGetAuth(APPLICANT.LIST_DETAIL_JOB, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobApplicantDetail(context, id) {
        try {
            const response = await api.apiGetAuth(APPLICANT.DETAIL(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobApplicants(context, data) {
        try {
            const response = await api.apiGetAuth(APPLICANT.LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async pickupApplicant(context, id) {
        try {
            const response = await api.apiPatchAuth(APPLICANT.PICKUP(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async pickupMultiApplicant(context, ids) {
        try {
            const response = await api.apiPostAuth(APPLICANT.MULTI_PICKUP, ids);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async rejectApplicant(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(APPLICANT.REJECT(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async rejectMultiApplicant(context, payload) {
        try {
            const response = await api.apiPostAuth(APPLICANT.MULTI_REJECT, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async inviteInterviewApplicant(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(APPLICANT.INVITE_INTERVIEW(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async inviteMultiInterviewApplicant(context, payload) {
        try {
            const response = await api.apiPostAuth(APPLICANT.MULTI_INVITE_INTERVIEW, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async interviewApplicant(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(APPLICANT.INTERVIEWED(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async interviewMultiApplicant(context, payload) {
        try {
            const response = await api.apiPostAuth(APPLICANT.MULTI_INTERVIEWED, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async sentContractApplicant(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(APPLICANT.SENT_CONTRACT(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async sentMultiContractApplicant(context, payload) {
        try {
            const response = await api.apiPostAuth(APPLICANT.MULTI_SENT_CONTRACT, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async resentContractApplicant(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(APPLICANT.RESENT_CONTRACT(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWhatsAppTemplates(context, data) {
        try {
            const response = await api.apiGetAuth(APPLICANT.LIST_WHATSAPP_TEMPLATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

export default {
    namespaced,
    actions,
};
