<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div v-if="$can('READ', 'candidate')" class="uk-margin-bottom">
            <!-- ROW 1, filter -->
            <div class="uk-flex uk-margin uk-child-width-expand@s" uk-grid>
                <div>
                    <input
                        class="uk-input"
                        name="title"
                        type="text"
                        v-model="filter.fullname"
                        placeholder="Candidate Name"
                        @keypress.enter="getCandidates"
                    >
                </div>
                <div>
                    <multiselect
                        v-model="filter.domicile_city_id"
                        placeholder="Type to search city"
                        label="name"
                        name="city"
                        track-by="name"
                        :searchable="true"
                        :options="city_options"
                        :loading='isFetching'
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :show-no-results="false"
                        @search-change="getCityOptions"
                        @input="getCandidates"
                    ></multiselect>
                </div>
                <div>
                    <multiselect
                        v-model="filter.education_level_id"
                        placeholder="Select Pendidikan"
                        label="name"
                        name="education"
                        track-by="name"
                        :searchable="true"
                        :options="education_options"
                        :loading='isFetching'
                        :internal-search="true"
                        :clear-on-select="false"
                        @input="getCandidates"
                    ></multiselect>
                </div>
                <div>
                    <multiselect
                        v-model="filter.job_preference_id"
                        placeholder="Select Preferensi"
                        label="name"
                        name="job_preference"
                        track-by="name"
                        :searchable="true"
                        :options="job_preference_options"
                        :loading='isFetching'
                        :internal-search="true"
                        :clear-on-select="false"
                        @input="getCandidates"
                    ></multiselect>
                </div>
                <div>
                    <multiselect
                        v-model="filter.company_id"
                        placeholder="Select Company"
                        label="name"
                        name="company"
                        track-by="_id"
                        :searchable="true"
                        :options="company_options"
                        :loading='isFetching'
                        :internal-search="true"
                        :clear-on-select="false"
                        @input="getCandidates"
                    ></multiselect>
                </div>
                <div>
                    <multiselect
                        v-model="filter.role_id"
                        placeholder="Select Job Title"
                        label="name"
                        name="role"
                        track-by="_id"
                        :searchable="true"
                        :options="role_options"
                        :loading='isFetching'
                        :internal-search="true"
                        :clear-on-select="false"
                        :disabled="!meta.company_id"
                        @input="getCandidates"
                    ></multiselect>
                </div>
            </div>

            <!-- ROW 2, multi-select -->
            <!-- default, show if not in multi select mode-->
            <template v-if="$can('EDIT', 'candidate')">
                <div v-if="!multi_select" class="uk-flex uk-flex-right uk-margin-top">
                    <button
                        type="button"
                        class="uk-button uk-button-default uk-text-nowrap uk-flex uk-flex-center uk-flex-middle uk-border-rounded button-filter"
                        @click="enableMultiselect()"
                    >
                        <img src="/images/multi-select-filter.svg" alt="Multi select filter icon">
                        <span>Multi-select Mitra</span>
                    </button>
                </div>
                <!-- multi select mode -->
                <div v-else class="uk-flex uk-flex-between uk-flex-middle uk-margin-top" style="gap: 10px">
                    <div class="uk-flex uk-flex-left uk-flex-middle" style="gap: 10px">
                        <button
                            v-if="totalCandidates.length < 1"
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                            style="gap: 5px;"
                            :style="[
                                (allCandidateOptions && allCandidateOptions.length < 1) || filterLoading ?
                                {'background-color': '#DDD8D8', 'color': '#888'} :
                                {'background-color': 'white', 'border': '1px solid #0275D8', 'color': '#0275D8'}
                            ]"
                            :disabled="(allCandidateOptions && allCandidateOptions.length < 1) || filterLoading"
                            @click="doSelectAllWorker"
                        >
                            <img
                                v-if="allCandidateOptions && allCandidateOptions.length > 0"
                                :src="`${images}/select-all-blue.svg`"
                                alt="Select all icon blue"
                                width="25"
                            >
                            <span>Select All</span>
                        </button>
                        <button
                            v-else
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                            style="gap: 5px;"
                            :style="[
                                filterLoading ?
                                {'background-color': '#DDD8D8', 'color': '#888'} :
                                {'background-color': 'white', 'border': '1px solid #0275D8', 'color': '#0275D8'}
                            ]"
                            :disabled="filterLoading"
                            @click="doUnselectAllWorker"
                        >
                            <img
                                :src="`${images}/select-all-blue.svg`"
                                alt="Select all icon blue"
                                width="25"
                            >
                            <span>Unselect All</span>
                        </button>
                        <span>{{ totalCandidates.length }} Candidate(s) selected</span>
                    </div>
                    <div class="uk-flex uk-flex-right uk-flex-middle" style="gap: 10px">
                        <button
                            class="uk-button"
                            style="border-radius: 5px; color: white;"
                            :style="{
                                backgroundColor: filterLoading || disableInvite ? '#DDD8D8' : '#0275D8',
                                color: filterLoading || disableInvite ? '#888' : 'white'
                            }"
                            :disabled="filterLoading || disableInvite"
                            @click="openInviteAutoAssignModal()"
                        >Auto Assign</button>
                        <button
                            class="uk-button"
                            style="border-radius: 5px; color: white;"
                            :style="{
                                backgroundColor: filterLoading || disableInvite ? '#DDD8D8' : '#0275D8',
                                color: filterLoading || disableInvite ? '#888' : 'white'
                            }"
                            :disabled="filterLoading || disableInvite"
                            @click="openInviteJobModal()"
                        >Invite to Job</button>
                        <button
                            class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                            style="gap: 5px;"
                            :style="[
                                filterLoading ? {'background-color': '#BDBDBD', 'color': 'white'} : {'border': '1px solid #0275D8', 'color': '#0275D8'}
                            ]"
                            :disabled="filterLoading"
                            @click="disableMultiselect()"
                        >Cancel</button>
                    </div>
                </div>
            </template>
        </div>

        <!-- candidate list -->
        <div v-if="$can('READ', 'candidate')">
            <span v-if="isLoading" uk-spinner="ratio: 2" class="uk-position-center uk-margin-auto"></span>
            <div v-else-if="candidate_data.docs.length != 0" class="uk-margin">
                <div
                    v-for="(candidate, a) in candidate_data.docs"
                    :key="a"
                    class="uk-card uk-card-default uk-margin uk-flex uk-flex-middle"
                    style="padding: 10px 46px 10px 30px"
                >
                    <div v-if="multi_select" class="uk-margin-right">
                        <input
                            type="checkbox"
                            class="uk-checkbox uk-margin-remove"
                            style="border-radius: 5px; width: 24px; height: 24px; border: 2px solid #979797; margin: 0 0 0 30px"
                            :checked="findCheckedUser({ candidate })"
                            @change="addSelection({ candidate })"
                        />
                    </div>
                    <div class="uk-width-auto uk-margin-auto">
                        <img
                            v-if="candidate.photo_url && candidate.photo_url.file_url"
                            :src="candidate.photo_url.file_url"
                            @error="(e) => imgError(e)" class="photo"
                        >
                        <img v-else :src="`${images}/avatar.svg`" class="photo">
                    </div>
                    <div class="uk-width-expand uk-margin uk-margin-left">
                        <div class="applicant_name"><span>{{ candidate.fullname }}</span></div>
                        <span>Tanggal Lahir: {{ formatDate(candidate.birthdate) }}</span><br>
                        <span>Preferensi Pekerjaan: {{ jobPreferences(candidate.job_preferences) }}</span><br>
                        <span>Pendidikan: {{ education(candidate.cv) }}</span><br>
                        <span>No. Handphone: {{ candidate.phone_number || '-' }}</span><br>
                        <span>Pengalaman Kerja: </span>
                        <span v-if="candidate.cv == null || candidate.cv.work_experiences.length == 0">-<br></span>
                        <div v-else>
                            <span v-for="(exp, x) in candidate.cv.work_experiences" :key="x" class="uk-margin-small-left" >
                                <span v-if="exp.title">{{x+1}}. {{exp.title}} - {{exp.company_name}} ({{formatYear(exp.start_date)}} - {{formatYear(exp.end_date)}})<br></span>
                            </span>
                        </div>
                        <span>Assessment Online yang diambil: </span>
                        <span v-if="candidate.all_user_scores == null || candidate.all_user_scores.length == 0">-</span>
                        <div v-else>
                            <span v-for="(test, x) in candidate.all_user_scores" :key="x" class="uk-margin-small-left">
                            {{x+1}}. {{test.test_category_id.name}} : {{test.score}}<br>
                            </span>
                        </div>
                    </div>
                    <div v-if="!multi_select" class="uk-width-1-6 uk-child-width-expand">
                        <button
                            v-if="$can('EDIT', 'candidate')"
                            class="uk-button uk-button-primary uk-align-right uk-button-small uk-border-rounded uk-margin-small"
                            @click="openInviteJobModal(candidate._id)"
                        >Invite To Job</button>
                        <button
                            v-if="$can('EDIT', 'candidate')"
                            class="uk-button uk-button-primary uk-align-right uk-button-small uk-border-rounded uk-margin-small"
                            @click="openInviteAutoAssignModal(candidate._id)"
                        >Auto Assign</button>
                        <button
                            class="uk-button uk-button-primary uk-align-right uk-button-small uk-border-rounded uk-margin-small"
                            @click="showModal('detailModal', candidate)"
                        >Details</button>
                        <button
                            class="uk-button uk-button-default  uk-align-right uk-button-small uk-border-rounded uk-margin-small"
                            :disabled="notAllowToDownload(candidate)"
                            @click="download(candidate._id)"
                        >
                            <span uk-spinner v-if="isDownloading"></span>
                            <span v-else>Unduh CV</span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else-if="candidate_data.docs.length == 0" class="uk-position-center uk-margin-auto"> No Candidate Found!</div>

            <pagination
                :total-data="candidate_data.totalDocs"
                :limit-page="meta.limit"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <!-- Notice for unauthorized user -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized read data from this page</p>
                    </span>
                </div>
            </div>
        </div>

        <div id="inviteModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Invite Candidate to Job</h2>
                </div>
                <div v-if="modalLoading" class="uk-padding">
                    <div class="uk-flex uk-flex-middle uk-flex-center">
                        <div uk-spinner="ratio: 2"></div>
                    </div>
                </div>
                <div v-else class="uk-modal-body">
                    <label v-if="multi_select" class="uk-form-label">
                        Invite <span class="uk-text-bold">{{ totalCandidates.length }} Candidates</span> to a job
                    </label>
                    <Multiselect
                        v-model="selected_job"
                        placeholder="Select Job Vacancy Title"
                        label="title"
                        name="vacancy_title"
                        track-by="_id"
                        :searchable="true"
                        :options="job_list"
                        :loading='isFetching'
                        :internal-search="true"
                        :clear-on-select="false"
                    ></Multiselect>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        type="button"
                        class="uk-button uk-button-default"
                        :disabled="!selected_job"
                        style="color: white;"
                        :style="{
                            backgroundColor: selected_job ? '#0275D8' : '#DDD8D8',
                        }"
                        @click="multi_select ? inviteMultipleCandidate(1) : inviteCandidate(1)"
                    >Invite To Job</button>
                </div>
            </div>
        </div>

        <div id="inviteAutoAssignModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Send Auto Assign Link</h2>
                </div>
                <div v-if="modalLoading" class="uk-padding">
                    <div class="uk-flex uk-flex-middle uk-flex-center">
                        <div uk-spinner="ratio: 2"></div>
                    </div>
                </div>
                <div v-else class="uk-modal-body">
                    <label v-if="multi_select" class="uk-form-label">
                        Assign <span class="uk-text-bold">{{ totalCandidates.length }} Candidates</span> to
                    </label>
                    <label v-else class="uk-form-label">Assign to</label>
                    <Multiselect
                        v-model="selected_job"
                        placeholder="Select Job Vacancy Title"
                        label="title"
                        name="vacancy_title"
                        track-by="_id"
                        :searchable="true"
                        :options="job_list"
                        :loading='isFetching'
                        :internal-search="true"
                        :clear-on-select="false"
                    ></Multiselect>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        type="button"
                        class="uk-button uk-button-default"
                        :disabled="!selected_job"
                        style="color: white;"
                        :style="{
                            backgroundColor: selected_job ? '#0275D8' : '#DDD8D8',
                        }"
                        @click="multi_select ? inviteMultipleCandidate(2) : inviteCandidate(2)"
                    >Send</button>
                </div>
            </div>
        </div>

        <div id="detailModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('detailModal')"></button>
                <div class="uk-modal-header">
                    <h3 class="uk-modal-title uk-text-center">Detail Applicant</h3>
                </div>
                <div class="uk-modal-body">
                    <span v-if="isFetching" uk-spinner="ratio: 2" class="uk-position-center uk-margin"></span>
                    <div v-else-if="selected_candidate != null" class="uk-margin" uk-grid>
                        <div class="uk-width-1-1">
                            <h2 class="uk-text-center">{{selected_candidate.user.fullname}}</h2>
                        </div>
                        <div class="uk-width-auto uk-margin-auto uk-margin-auto-vertical">
                            <img
                                v-if="selected_candidate.user.photo_url != null" :src="selected_candidate.user.photo_url.file_url"
                                @error="(e) => imgError(e)" class="photo"
                            >
                            <img v-else :src="`${images}/avatar.svg`" class="photo">
                        </div>
                        <div class="uk-width-expand uk-margin-medium-top uk-margin-auto">
                            <span>Tanggal Lahir: {{formatDate(selected_candidate.user.birthdate)}}</span><br>
                            <span>Domisili: {{selected_candidate.user.domicile ? selected_candidate.user.domicile.name : '-'}}</span><br>
                            <span>Pendidikan: {{education(selected_candidate.cv)}}</span><br>
                            <span>Pengalaman Kerja: </span>
                            <span v-if="selected_candidate.cv == null || selected_candidate.cv.work_experiences.length == 0">-<br></span>
                            <div v-else>
                                <span v-for="(exp, x) in selected_candidate.cv.work_experiences" :key="x" class="uk-margin-small-left" >
                                    <span v-if="exp.title">{{x+1}}. {{exp.title}} - {{exp.company_name}} ({{formatYear(exp.start_date)}} - {{formatYear(exp.end_date)}})<br></span>
                                </span>
                            </div>
                            <span>Assessment Online yang diambil: </span>
                            <span v-if="selected_candidate.user.all_user_scores == null || selected_candidate.user.all_user_scores.length == 0">-</span>
                            <div v-else>
                                <span v-for="(test, x) in selected_candidate.user.all_user_scores" :key="x" class="uk-margin-small-left">
                                {{x+1}}. {{test.test_category_id.name}} : {{test.score}}<br></span>
                            </div>
                            <br>
                            <span>Dokumen Pendukung:
                                <a v-if="consistDoc(selected_candidate.cv, 'ktp')" style="font-weight: 700" :href="selected_candidate.cv.ktp.document_url.file_url">KTP</a>
                                <span v-else style="color:red">KTP</span>
                                &nbsp;
                                <a v-if="consistDoc(selected_candidate.cv, 'skck')" style="font-weight: 700" :href="selected_candidate.cv.skck.document_url.file_url">SKCK</a>
                                <span v-else style="color:red">SKCK</span>
                                &nbsp;
                                <a v-if="consistDoc(selected_candidate.cv, 'ijazah')" style="font-weight: 700" :href="selected_candidate.cv.ijazah.document_url.file_url">Ijazah</a>
                                <span v-else style="color:red">Ijazah</span>
                                &nbsp;
                                <a v-if="consistDoc(selected_candidate.cv, 'sim')" style="font-weight: 700" :href="selected_candidate.cv.sim.document_url.file_url">SIM</a>
                                <span v-else style="color:red">SIM</span>
                                &nbsp;
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import { mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import { PREFIX_IMAGE } from '@/utils/constant';
import Pagination from '@/components/globals/Pagination';
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';

export default {
    data() {
        return {
            images: PREFIX_IMAGE,
            meta: {
                limit: 10,
                page: 1,
                fullname: '',
                domicile_city_id: null,
                job_preference_id: null,
                education_level_id: null,
                company_id: null,
                role_id: null
            },
            filter: {
                fullname: '',
                domicile_city_id: null,
                job_preference_id: null,
                education_level_id: null,
                company_id: null,
                role_id: null
            },
            selected_candidate: null,
            selected_candidate_id: null,
            selected_job: '',
            job_list: [],
            candidate_data: {docs:[]},
            education_options: [],
            city_options: [],
            job_preference_options: [],
            company_options: [],
            role_options: [],
            isLoading: false,
            isFetching: false,
            isDownloading: false,
            filterLoading: false,
            modalLoading: false,
            multi_select: false,
            totalCandidates: [],
            allCandidateOptions: [],
            metaCandidate: {
                fullname: '',
                domicile_city_id: null,
                job_preference_id: null,
                education_level_id: null,
                company_id: null,
                role_id: null
            },
        };
    },
    components: {
        Pagination,
        Multiselect,
    },
    computed: {
        disableInvite() {
            if (this.totalCandidates.length < 1) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        meta() {
            this.getCandidates();
        },
    },
    beforeMount() {
        if (window.UIkit.modal('.detailModal')) window.UIkit.modal('.detailModal').$destroy(true);
        if (window.UIkit.modal('.inviteModal')) window.UIkit.modal('.inviteModal').$destroy(true);
        if (window.UIkit.modal('.inviteAutoAssignModal')) window.UIkit.modal('.inviteAutoAssignModal').$destroy(true);
    },
    async mounted() {
        this.education_options = await this.getEducationLevels();
        this.job_preference_options = await this.getJobPreferences();
        this.company_options = await this.getCompanies();
        this.getCandidates();
    },
    methods: {
        ...mapActions({
            getEducationLevels: 'option_data/getEducationLevels',
            getJobPreferences: 'option_data/getJobPreferences',
            getCities: 'option_data/getCities',
            getCompanies: 'option_data/getCompanies',
            getRolesOnCompany: 'option_data/getRolesOnCompany',
            getCandidateList: 'candidate/getCandidateList',
            getAllCandidate: 'candidate/getAllCandidate',
            getJobList: 'initiation/getJobList',
            inviteToJob: 'candidate/inviteToJob',
            inviteMutipleCandidate: 'candidate/inviteMutipleCandidate',
            getCV: 'candidate/getCV',
            downloadCandidateCv: 'candidate/downloadCandidateCv',
        }),
        async getCityOptions(query){
            this.isFetching = true;
            this.city_options = await this.getCities({name: query});
            this.isFetching = false;
        },
        async getCandidates(){
            this.isLoading = this.filterLoading = true;

            this.meta.fullname = this.filter.fullname = this.filter.fullname ? formatter.sanitizeString(this.filter.fullname) : '';
            this.meta.domicile_city_id = this.filter.domicile_city_id ? this.filter.domicile_city_id._id : '';
            this.meta.job_preference_id = this.filter.job_preference_id ? this.filter.job_preference_id._id : '';
            this.meta.education_level_id = this.filter.education_level_id ? this.filter.education_level_id._id : '';

            if (this.filter.role_id) {
                if (this.filter.company_id._id != this.meta.company_id) {
                    this.filter.role_id = null;
                    this.meta.role_id = null;
                } else {
                    this.meta.role_id = this.filter.role_id._id;
                }
            } else {
                this.meta.role_id = null;
            }
            if (this.filter.company_id) {
                if (this.filter.company_id._id != this.meta.company_id) {
                    await this.getRoleOptions();
                }
                this.meta.company_id = this.filter.company_id._id;
            } else {
                this.meta.company_id = null;
                this.filter.role_id = null;
                this.meta.role_id = null;
            }

            this.candidate_data = await this.getCandidateList(this.meta);
            await this.getFilteredAllCandidates();

            this.isLoading = this.filterLoading = false;
        },
        async getFilteredAllCandidates() {
            this.metaCandidate.fullname = this.meta.fullname;
            this.metaCandidate.domicile_city_id = this.meta.domicile_city_id;
            this.metaCandidate.job_preference_id = this.meta.job_preference_id;
            this.metaCandidate.education_level_id = this.meta.education_level_id;
            this.metaCandidate.company_id = this.meta.company_id;
            this.metaCandidate.role_id = this.meta.role_id;

            if (Object.values(this.metaCandidate).some(value => !!value)) {
                this.allCandidateOptions = await this.getAllCandidate(this.meta);
            } else {
                this.allCandidateOptions = [];
            }
        },
        async getRoleOptions(){
            this.isFetching = true;
            this.role_options = await this.getRolesOnCompany({company_id: this.filter.company_id._id});
            this.isFetching = false;
        },
        imgError(e){
            e.target.src = `${this.images}/avatar.svg`;
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatYear(data) {
            if (!data || data == '') {
                return '-';
            }
            return formatter.yearOnly(data);
        },
        jobPreferences(data) {
            let x = '';
            if (data == null) {
                x = '-';
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (!data[i+1]) {
                        x += data[i].name;
                    } else {
                        x += data[i].name+ `, `;
                    }
                }
            }
            return x;
        },
        education(data) {
            let x = '';
            if (data == null || data.educations.length == 0) {
                x = '-';
            } else {
                for (let i = 0; i < data.educations.length; i++) {
                    if (!data.educations[i+1]) {
                        x += data.educations[i].institution_name;
                    } else {
                        x += data.educations[i].institution_name+ `, `;
                    }
                }
            }
            return x;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showModal(modal, data=null){
            switch (modal) {
            case 'detailModal':
                if (data.email) {
                    this.selected_candidate = await this.getCV({ filter:'email', data:data.email });
                } else {
                    this.selected_candidate = await this.getCV({ filter:'phone', data:data.phone_number });
                }
                await window.UIkit.modal('#detailModal').show();
                break;
            case 'inviteModal':
                this.selected_candidate_id = data;
                this.isFetching = true;
                this.job_list = await this.getJobList({ auto_assign: 0 });
                this.isFetching = false;
                await window.UIkit.modal('#inviteModal').show();
                break;
            case 'inviteAutoAssignModal':
                this.selected_candidate_id = data;
                this.isFetching = true;
                this.job_list = await this.getJobList({ auto_assign: 1 });
                this.isFetching = false;
                await window.UIkit.modal('#inviteAutoAssignModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'detailModal':
                await window.UIkit.modal('#detailModal').hide();
                this.selected_candidate = null;
                break;
            case 'inviteModal':
                this.selected_candidate_id = null;
                this.selected_job = '';
                await window.UIkit.modal('#inviteModal').hide();
                await window.UIkit.modal('#inviteAutoAssignModal').hide();
                break;
            }
        },
        async openInviteJobModal(candidate_id) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#inviteModal").show();

                this.selected_job = '';
                this.selected_candidate_id = candidate_id;
                this.job_list = await this.getJobList({ auto_assign: 0 });

                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#inviteModal").hide();
                this.selected_candidate_id = null;
                this.selected_job = '';
                this.modalLoading = false;
            }
        },
        async openInviteAutoAssignModal(candidate_id) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#inviteAutoAssignModal").show();

                this.selected_job = '';
                this.selected_candidate_id = candidate_id;
                this.job_list = await this.getJobList({ auto_assign: 1 });

                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#inviteAutoAssignModal").hide();
                this.selected_candidate_id = null;
                this.selected_job = '';
                this.modalLoading = false;
            }
        },
        async inviteCandidate(value){
            try {
                if (!this.selected_candidate_id) {
                    notificationDangerCustom('No candidate selected!');
                    await this.closeInvitationModal();
                }

                const data = {
                    user_id: this.selected_candidate_id,
                    job_id: this.selected_job._id
                };
                if (value == 1) {
                    data.type = 'job';
                } else {
                    data.type = 'auto-assign';
                }

                const response = await this.inviteToJob(data);
                await this.closeInvitationModal();
                this.resetInviteData();

                if (response && response.status === 'OK') {
                    notificationSuccess(response.message.toString());
                } else {
                    notificationDangerCustom('Failed to invite candidate.');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await this.closeInvitationModal();
                this.resetInviteData();
            }
        },
        async inviteMultipleCandidate(value) {
            try {
                if (this.totalCandidates.length < 1) {
                    notificationDangerCustom('No candidates selected!');
                    await this.closeInvitationModal();
                }

                const distinctCandidateId = [...new Set(this.totalCandidates.map(item => String(item._id)))];
                const data = {
                    job_id: this.selected_job._id,
                    payload: { user_ids: distinctCandidateId }
                };
                if (value == 1) {
                    data.type = 'job';
                } else {
                    data.type = 'auto-assign';
                }

                const response = await this.inviteMutipleCandidate(data);
                await this.closeInvitationModal();
                this.resetTotalWorkers();

                if (response && response.status === 'OK') {
                    notificationSuccess(response.message.toString());
                } else {
                    notificationDangerCustom('Failed to invite candidates.');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await this.closeInvitationModal();
                this.selected_job = '';
                this.job_list = [];
                this.disableMultiselect();
            }
        },
        async closeInvitationModal() {
            await window.UIkit.modal("#inviteModal").hide();
            await window.UIkit.modal("#inviteAutoAssignModal").hide();
        },
        resetInviteData() {
            this.selected_job = '';
            this.selected_candidate_id = null;
            this.job_list = [];
        },
        async download(_id){
            this.isDownloading = true;
            const response = await this.downloadCandidateCv({user_id: _id});
            if (response && response.status === 'OK') {
                await window.open(response.result.cv_path.file_url);
                notificationSuccess('Downloaded!');
                this.isDownloading = false;
            } else {
                this.isDownloading = false;
                notificationDanger('Failed to download.');
            }
        },
        consistDoc(data, filter){
            if (data) {
                switch (filter) {
                case 'ktp':
                    if (data.ktp) {
                        return data.ktp;
                    } else {
                        return false;
                    }
                case 'skck':
                    if (data.skck) {
                        return data.skck;
                    } else {
                        return false;
                    }
                case 'ijazah':
                    if (data.ijazah) {
                        return data.ijazah;
                    } else {
                        return false;
                    }
                case 'sim':
                    if (data.sim) {
                        return data.sim;
                    } else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        },
        notAllowToDownload(candidate){
            if (candidate.description == undefined || candidate.description == null) {
                return true;
            } else if (candidate.cv == null) {
                return true;
            } else if (candidate.cv.educations.length == 0) {
                return true;
            } else if (candidate.cv.skills.length == 0) {
                return true;
            } else if (candidate.cv.work_experiences.length == 0) {
                return true;
            } else if (candidate.fullname == null) {
                return true;
            } else if (candidate.email == null && candidate.phone_number == null) {
                return true;
            } else if (candidate.birthdate == null) {
                return true;
            } else if (candidate.domicile == null) {
                return true;
            } else if (candidate.fullname == null) {
                return true;
            } else if (candidate.job_preferences == null || candidate.job_preferences.length == 0) {
                return true;
            } else if (this.isDownloading) {
                return true;
            } else {
                return false;
            }
        },
        enableMultiselect() {
            this.resetTotalWorkers();
            this.multi_select = true;
        },
        disableMultiselect() {
            this.resetTotalWorkers();
            this.multi_select = false;
        },
        findCheckedUser({ candidate }) {
            return !!this.totalCandidates.find((item) => item._id == candidate._id);
        },
        addSelection({ candidate }) {
            let selectedIds = [];
            const resultFindSameData = this.totalCandidates.find((item) => item._id == candidate._id);
            selectedIds = this.totalCandidates.length > 0 ? 
                resultFindSameData ?
                    this.totalCandidates.filter((item) => item._id != candidate._id)
                    : [...this.totalCandidates, ...[candidate]] : [candidate];
            this.totalCandidates = selectedIds;
        },
        doSelectAllWorker() {
            if (this.allCandidateOptions && this.allCandidateOptions.length > 0) {
                for (const candidate of this.allCandidateOptions) {
                    const findSameData = this.totalCandidates.find((data) => data._id == candidate._id);
                    if (!findSameData) {
                        this.totalCandidates.push(candidate);
                    }
                }
            }
        },
        doUnselectAllWorker() {
            this.resetTotalWorkers();
        },
        resetTotalWorkers() {
            this.totalCandidates = [];
        },
    }
};
</script>

<style scoped>
.photo {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.applicant_name {
    font-weight: 700;
    margin-bottom: 10px;
}
.button-filter {
    border: 1px solid #0275D8;
    background-color: white;
    color: #0275D8;
}
</style>
