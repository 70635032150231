<template>
    <section>
        <div id="interview-modal" class="uk-flex-top interview-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Send Contract</h2>
                </div>
                <form @submit.prevent="handleInterviewApplicant">
                    <div class="uk-padding uk-grid-small" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="startDate" class="uk-form-label">Start Date <span class="uk-text-danger">*</span></label>
                            <div class="uk-inline uk-width-expand">
                            <datetime
                                id="startDate"
                                type="date"
                                name="start_date"
                                format="dd MMM yyyy"
                                input-class="uk-input"
                                :max-datetime="formData.end_date"
                                :class="{'uk-form-danger': errors.has('formData.start_date')}"
                                v-model="formData.start_date"
                                v-validate="'required'"
                                required
                            ></datetime>
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('formData.start_date')">{{ errors.first('formData.start_date') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="endDate" class="uk-form-label">End Date <span class="uk-text-danger">*</span></label>
                            <div class="uk-inline uk-width-expand">
                            <datetime
                                id="endDate"
                                type="date"
                                name="end_date"
                                format="dd MMM yyyy"
                                input-class="uk-input"
                                :min-datetime="formData.start_date"
                                :class="{'uk-form-danger': errors.has('formData.end_date')}"
                                :disabled="!formData.start_date"
                                v-model="formData.end_date"
                                v-validate="'required'"
                                required
                            ></datetime>
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('formData.end_date')">{{ errors.first('formData.end_date') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="shift_id" class="uk-form-label">Shift <span class="uk-text-danger">*</span></label>
                            <select 
                            id="shift_id"
                            name="shift_id"
                            class="uk-select"
                            :class="{'uk-form-danger': errors.has('formData.shift_id')}"
                            v-model="formData.shift_id"
                            v-validate="'required'"
                            required
                            >
                            <option :value="null" selected disabled>Select shift</option>
                            <option v-for="(item, index) in shifts" :key="index" :value="item._id">{{item.name}}</option>
                            </select>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('formData.shift_id')">{{ errors.first('formData.shift_id') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="documentLink" class="uk-form-label">Document Link</label>
                            <input
                            id="documentLink"
                            name="document"
                            class="uk-input"
                            type="text"
                            placeholder="Put document's link here"
                            v-model="formData.document"
                            />
                        </div>
                        <div class="uk-width-1-1">
                            <label for="employment_type" class="uk-form-label">Employment Type <span class="uk-text-danger">*</span></label>
                            <multiselect
                            id="employment_type"
                            v-model="formData.employment_type"
                            placeholder="Select employment type"
                            name="employment_type"
                            v-validate="'required'"
                            :class="{'uk-form-danger': errors.has('employment_type')}"
                            :options="options">
                            </multiselect>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('employment_type')">{{ errors.first('employment_type') }}</span>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button 
                            v-if="isLoading"
                            class="uk-button uk-button-default uk-flex uk-flex-middle uk-flex-center"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </button>
                        <button 
                            v-else
                            type="submit"
                            class="uk-button" 
                            :class="[buttonDisabled ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="buttonDisabled"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Datetime,
        Multiselect,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: '',
        },
        ids: {
            type: Array,
            required: false,
            default: () => [],
        },
        office_id: {
            type: String,
            required: false,
            default: ''
        },
        isMulti: {
            type: Boolean,
            required: false,
            default: false
        },
        companyId: {
            type: String,
            required: false,
            default: ''
        }
    },
    data(){
        return {
            datetime: DateTime,
            isLoading: false,
            shifts: [],
            formData: {
                job_applicant_ids: this.$props.ids,
                id: this.$props.id,
                start_date: null,
                end_date: null,
                shift_id: null,
                document: null,
                employment_type: null
            },
            options: ['PKWT', 'KEMITRAAN', 'DAILY WORKER']
        };
    },
    async mounted() {
        window.UIkit.modal('#interview-modal').show();
        await Promise.all([
            this.getShifts(),
            this.getShiftByCompany()
        ]);
    },
    computed: {
        buttonDisabled() {
            if (
                this.formData.start_date == null ||
                this.formData.end_date == null ||
                this.formData.shift_id == null
            ) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapActions({
            sentMultiContractApplicant: 'applicant/sentMultiContractApplicant',
            sentContractApplicant: 'applicant/sentContractApplicant',
            getAllShiftByOffice: 'option_data/getAllShiftByOffice',
            getAllShiftByCompany: 'option_data/getAllShiftByCompany'
        }),
        hideModal() {
            window.UIkit.modal('#interview-modal').$destroy(true);
            this.$emit("hideModal");
        },
        async getShifts() {
            if (this.$props.office_id) {
                const response = await this.getAllShiftByOffice({ _id: this.$props.office_id });
                this.shifts = response;
                if (this.shifts) {
                    this.shifts.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }
            }
        },
        async handleInterviewApplicant() {
            const validate = await this.$validator.validateAll('formData');
            if (!validate || this.$validator.errors.any()) return;
            let pathApi = 'sentContractApplicant';

            if (this.$props.isMulti) {
                pathApi = 'sentMultiContractApplicant';
                delete this.formData.id;
            } else {
                delete this.formData.job_applicant_ids;
            }

            this.isLoading = true;
            const response = await this[pathApi](this.formData);

            if (response && response.status === 'OK') {
                notificationSuccess('Change status success!');
                window.UIkit.modal('#interview-modal').$destroy(true);
                this.$emit("onSave");
            } else {
                notificationDangerCustom('Failed to change status applicant!');
            }

            this.isLoading = false;
        },
        async getShiftByCompany() {
            if (this.$props.companyId) {
                const response = await this.getAllShiftByCompany({ company_id: this.$props.companyId });
                this.shifts = response;
                if (this.shifts) {
                    this.shifts.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }
            }
        }
    }
};
</script>
