<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small uk-flex uk-flex-middle">
                <div class="uk-width-1-3">
                    <multiselect
                        v-model="meta.model_ids"
                        placeholder="Type to search office"
                        label="name"
                        name="office"
                        track-by="name"
                        :searchable="true"
                        :multiple="true"
                        :options="company_offices"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :limit="1"
                        :limit-text="limitText"
                        :max-height="600"
                        @input="getAllAnnouncementList"
                        :disabled="meta.all_offices"
                        >
                        </multiselect>
                </div>
                <div>
                    <div class="uk-inline" @click="showModal('date-filter')">
                        <a class="uk-form-icon" href="#" uk-icon="icon: calendar"></a>
                        <input class="uk-input" type="text" :value="filterDateText()" disabled>
                    </div>
                </div>
                <div class="">
                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.all_offices" @change="getAllAnnouncementList"> Semua Mitra</label>
                </div>
                <div>
                    <button class="uk-button uk-button-primary" @click="showModal('create-modal')">Create</button>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-divider">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-table-shrink">No</th>
                            <th class="uk-text-center uk-width-auto">Start date</th>
                            <th class="uk-text-center uk-width-auto">End date</th>
                            <th class="uk-text-center uk-width-auto">Announcement</th>
                            <th class="uk-text-center uk-width-auto">For</th>
                            <th class="uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="announcements.docs.length>0">
                        <tr v-for="(announcement, i) in announcements.docs" :key="i">
                            <td class="uk-text-center">{{i+1}}</td>
                            <td class="uk-text-center uk-width-auto">{{formatDateTime(announcement.start_datetime)}}</td>
                            <td class="uk-text-center uk-width-auto">{{formatDateTime(announcement.end_datetime)}}</td>
                            <td class="uk-text-center">{{formatAnnouncement(announcement.announcement)}}</td>
                            <td class="uk-text-center">{{announcement.recipient_count}} Mitra</td>
                            <td class="uk-text-center">
                                <div class="uk-flex uk-flex-center">
                                    <button class="uk-margin-small-right uk-button-small uk-button-default" @click="showModal('detail-modal', announcement)">Detail</button>
                                    <button class="uk-margin-small-right uk-button-small uk-button-primary" @click="showModal('edit-modal', announcement)"
                                    v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-operation','admin-bd'])">Edit</button>
                                    <button class="uk-button-small uk-button-danger" @click="markDeleteAnnouncement(announcement._id)"
                                    v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-operation','admin-bd'])">Delete</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="announcements.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="date-filter" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('date-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input Date Range for Announcement List</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">From</label>
                        <div class="uk-form-controls">
                            <datetime
                                name="Start Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                type='datetime'
                                v-model="meta.start_datetime"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">To (optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                name="End Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                type='datetime'
                                v-model="meta.end_datetime"
                            ></datetime>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setDate">Apply</button>
                </div>
            </div>
        </div>

        <div id="create-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-width-1-2">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('create-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Announcement</h2>
                </div>
                <div class="uk-modal-body">
                    <div uk-grid>
                        <div class="uk-width-1-2 uk-margin-small">
                            <label class="uk-form-label" for="form-start">Start Date</label>
                            <div class="uk-form-controls">
                                <datetime
                                    id="form-start"
                                    name="start-date"
                                    :input-class="{'uk-form-danger': errors.has('start-date'), 'uk-input': true}"
                                    :min-datetime="new Date().toISOString()"
                                    placeholder="dd/mm/yyy"
                                    type='datetime'
                                    v-validate="'required'"
                                    v-model="form.start_datetime"
                                ></datetime><span class="uk-text-small uk-text-danger" v-show="errors.has('start-date')">{{ errors.first('start-date') }}</span>
                            </div>
                        </div>
                        <div class="uk-width-1-2 uk-margin-small uk-margin-remove-top">
                            <label class="uk-form-label" for="form-end">End Date</label>
                            <div class="uk-form-controls">
                                <datetime
                                    id="form-end"
                                    name="end-date"
                                    :input-class="{'uk-form-danger': errors.has('end-date'), 'uk-input': true}"
                                    :min-datetime="form.start_datetime"
                                    placeholder="dd/mm/yyy"
                                    type='datetime'
                                    v-validate="'required'"
                                    v-model="form.end_datetime"
                                ></datetime><span class="uk-text-small uk-text-danger" v-show="errors.has('end-date')">{{ errors.first('end-date') }}</span>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <label for="office" class="uk-form-label">For (Office)</label>
                            <div class="uk-width-1-1 uk-position-relative">
                                <button
                                    type="button"
                                    ref="showCreateDropdownOfficeOptions"
                                    class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between uk-border-rounded color-35495e border-1-e8e8e8 padding-10"
                                    @click="openCreateDropdownOfficeOptions"
                                >
                                    <span>{{ form.model_ids.length > 0 ? `${form.model_ids.length} office(s) selected` : 'Select office' }}</span>
                                    <span>
                                        <img :src="`${images}/chevron-up-down.svg`" alt="Chevron up down icon">
                                    </span>
                                </button>
                                <div
                                    v-if="showCreateDropdownOfficeOptions"
                                    v-closable="{
                                        exclude: ['showCreateDropdownOfficeOptions'],
                                        handler: 'closeCreateDropdownOfficeOptions'
                                    }"
                                    class="uk-width-1-1 uk-position-absolute border-1-cccccc overflow-y-auto background-white z-1"
                                >
                                    <div class="height-100 max-height-191">
                                        <div class="uk-width-1-1 padding-5-10">
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="selectAllOffice"
                                                    @change="selectAllData({ event: $event, data: 'model_ids' })"
                                                > All offices
                                            </label>
                                        </div>
                                        <div
                                            v-for="(office, index) in company_offices"
                                            :key="index"
                                            class="uk-width-1-1 padding-5-10"
                                        >
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="form.model_ids.includes(office._id)"
                                                    @change="selectData({ id: office._id, data: 'model_ids' })"
                                                > {{ office.name || '-' }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <label for="user" class="uk-form-label">For (Mitra)</label>
                            <div class="uk-width-1-1 uk-position-relative">
                                <button
                                    type="button"
                                    ref="showCreateDropdownUserOptions"
                                    class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between uk-border-rounded color-35495e border-1-e8e8e8 padding-10"
                                    @click="openCreateDropdownUserOptions"
                                >
                                    <span>{{ form.user_ids.length > 0 ? `${form.user_ids.length} user(s) selected` : 'Select user' }}</span>
                                    <span>
                                        <img :src="`${images}/chevron-up-down.svg`" alt="Chevron up down icon">
                                    </span>
                                </button>
                                <div
                                    v-if="showCreateDropdownUserOptions"
                                    v-closable="{
                                        exclude: ['showCreateDropdownUserOptions'],
                                        handler: 'closeCreateDropdownUserOptions'
                                    }"
                                    class="uk-width-1-1 uk-position-absolute border-1-cccccc overflow-y-auto background-white z-1"
                                >
                                    <div class="height-100 max-height-191">
                                        <div class="uk-width-1-1 padding-5-10">
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="form.user_ids.length === workerList.length"
                                                    @change="selectAllData({ event: $event, data: 'user_ids' })"
                                                    > All users
                                                </label>
                                        </div>
                                        <div
                                            v-for="(user, index) in workerList"
                                            :key="index"
                                            class="uk-width-1-1 padding-5-10"
                                        >
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="form.user_ids.includes(user._id)"
                                                    @change="selectData({ id: user._id, data: 'user_ids' })"
                                                > {{ user.fullname }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <label class="uk-form-label" for="form-pengumuman">Announcement Content</label>
                            <div class="uk-form-controls">
                                <vue-editor
                                    v-model="form.announcement"
                                    name="announcement"
                                    :editor-toolbar="customToolbar"
                                    v-validate="'required'"
                                />
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('announcement')">{{ errors.first('announcement') }}</span>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <div>
                                <label class="uk-form-label uk-margin-small-right">Send to:</label>
                                <label class="uk-form-label uk-margin-small-right">
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        name="notification-type"
                                        value="1"
                                        v-model="form.notification_type"
                                        v-validate="'required'"> In-App Notification
                                </label>
                                <label class="uk-form-label uk-margin-small-right">
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        name="notification-type"
                                        value="2"
                                        v-model="form.notification_type"> Email
                                </label>
                                <label class="uk-form-label uk-margin-small-right">
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        name="notification-type"
                                        value="3"
                                        v-model="form.notification_type"> Push Notification
                                </label>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('notification-type')">At least one type is selected.</span>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" type="button" class="uk-button uk-button-default" disabled>
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button v-else class="uk-button uk-button-primary" type="button" @click="saveAnnouncement">Send</button>
                </div>
            </div>
        </div>

        <div id="edit-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-width-1-2">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Announcement</h2>
                </div>
                <div class="uk-modal-body">
                    <div uk-grid>
                        <div class="uk-width-1-2 uk-margin-small">
                            <label class="uk-form-label" for="form-start">Start Date</label>
                            <div class="uk-form-controls">
                                <datetime
                                    id="form-start"
                                    name="Start Date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    :min-datetime="new Date().toISOString()"
                                    type='datetime'
                                    v-model="form.start_datetime"
                                    :disabled="!isEdit"
                                ></datetime>
                            </div>
                        </div>
                        <div class="uk-width-1-2 uk-margin-small uk-margin-remove-top">
                            <label class="uk-form-label" for="form-end">End Date</label>
                            <div class="uk-form-controls">
                                <datetime
                                    id="form-end"
                                    name="End Date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    :min-datetime="form.start_datetime"
                                    type='datetime'
                                    v-model="form.end_datetime"
                                    :disabled="!isEdit"
                                ></datetime>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <label for="office" class="uk-form-label">For (Office)</label>
                            <div class="uk-width-1-1 uk-position-relative">
                                <button
                                    type="button"
                                    ref="showEditDropdownOfficeOptions"
                                    class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between uk-border-rounded border-1-e8e8e8 padding-10"
                                    :class="[!isEdit ? 'color-e8e8e8' : 'color-35495e']"
                                    :disabled="!isEdit"
                                    @click="openEditDropdownOfficeOptions"
                                >
                                    <span>{{ form.model_ids.length > 0 || !isEdit ? `${form.model_ids.length} office(s) selected` : 'Select office' }}</span>
                                    <span>
                                        <img :src="`${images}/chevron-up-down.svg`" alt="Chevron up down icon">
                                    </span>
                                </button>
                                <div
                                    v-if="showEditDropdownOfficeOptions"
                                    v-closable="{
                                        exclude: ['showEditDropdownOfficeOptions'],
                                        handler: 'closeEditDropdownOfficeOptions'
                                    }"
                                    class="uk-width-1-1 uk-position-absolute border-1-cccccc overflow-y-auto background-white z-1"
                                >
                                    <div class="height-100 max-height-191">
                                        <div class="uk-width-1-1 padding-5-10">
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="selectAllOffice"
                                                    @change="selectAllData({ event: $event, data: 'model_ids' })"
                                                > All offices
                                            </label>
                                        </div>
                                        <div
                                            v-for="(office, index) in company_offices"
                                            :key="index"
                                            class="uk-width-1-1 padding-5-10"
                                        >
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="form.model_ids.includes(office._id)"
                                                    @change="selectData({ id: office._id, data: 'model_ids' })"
                                                > {{ office.name || '-' }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <label for="user" class="uk-form-label">For (Mitra)</label>
                            <div class="uk-width-1-1 uk-position-relative">
                                <button
                                    type="button"
                                    ref="showEditDropdownUserOptions"
                                    class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between uk-border-rounded border-1-e8e8e8 padding-10"
                                    :class="[!isEdit ? 'color-e8e8e8' : 'color-35495e']"
                                    :disabled="!isEdit"
                                    @click="openEditDropdownUserOptions"
                                >
                                    <span>{{ form.user_ids.length > 0 || !isEdit ? `${form.user_ids.length} user(s) selected` : 'Select user' }}</span>
                                    <span>
                                        <img :src="`${images}/chevron-up-down.svg`" alt="Chevron up down icon">
                                    </span>
                                </button>
                                <div
                                    v-if="showEditDropdownUserOptions"
                                    v-closable="{
                                        exclude: ['showEditDropdownUserOptions'],
                                        handler: 'closeEditDropdownUserOptions'
                                    }"
                                    class="uk-width-1-1 uk-position-absolute border-1-cccccc overflow-y-auto background-white z-1"
                                >
                                    <div class="height-100 max-height-191">
                                        <div class="uk-width-1-1 padding-5-10">
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="form.user_ids.length === workerList.length"
                                                    @change="selectAllData({ event: $event, data: 'user_ids' })"
                                                    > All users
                                                </label>
                                        </div>
                                        <div
                                            v-for="(user, index) in workerList"
                                            :key="index"
                                            class="uk-width-1-1 padding-5-10"
                                        >
                                            <label>
                                                <input
                                                    class="uk-checkbox uk-border-rounded"
                                                    type="checkbox"
                                                    :checked="form.user_ids.includes(user._id)"
                                                    @change="selectData({ id: user._id, data: 'user_ids' })"
                                                > {{ user.fullname }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <label class="uk-form-label" for="form-pengumuman">Announcement Content</label>
                            <div class="uk-form-controls">
                                <vue-editor
                                    v-model="form.announcement"
                                    name="announcement"
                                    :editor-toolbar="customToolbar"
                                    :disabled="!isEdit"
                                    v-validate="'required'"
                                />
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('announcement')">{{ errors.first('announcement') }}</span>
                        </div>
                        <div class="uk-width-1-1 uk-margin-small">
                            <div>
                                <label class="uk-form-label uk-margin-small-right">Send to:</label>
                                <label class="uk-form-label uk-margin-small-right">
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        name="notification-type"
                                        value="1"
                                        v-model="form.notification_type"
                                        :disabled="!isEdit"> In-App Notification
                                </label>
                                <label class="uk-form-label uk-margin-small-right">
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        name="notification-type"
                                        value="2"
                                        v-model="form.notification_type"
                                        :disabled="!isEdit"> Email
                                </label>
                                <label class="uk-form-label uk-margin-small-right">
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        name="notification-type"
                                        value="3"
                                        v-model="form.notification_type"
                                        :disabled="!isEdit"> Push Notification
                                </label>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('notification-type')">At least one type is selected.</span>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right" v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-operation','admin-bd'])">
                    <button v-if="isLoading" type="button" class="uk-button uk-button-default" disabled>
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button v-else-if="isEdit" class="uk-button uk-button-primary" type="button" @click="saveAnnouncement">Send</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters  } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import Multiselect from 'vue-multiselect';
import { Datetime } from 'vue-datetime';
import { VueEditor } from 'vue2-editor';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from "@/utils/constant";

let handleOutsideClick;
Vue.directive('closable', {
    bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
        handleOutsideClick = (e) => {
            e.stopPropagation();
            // Get the handler method name and the exclude array
            // from the object used in v-closable
            const { handler, exclude } = binding.value;
            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                // We only run this code if we haven't detected
                // any excluded element yet
                if (!clickedOnExcludedEl) {
                    // Get the element using the reference name
                    const excludedEl = vnode.context.$refs[refName];
                    // See if this excluded element
                    // is the same element the user just clicked on
                    if (excludedEl) {
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                }
            });
            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the dialog
                // and not the button, then call the outside-click handler
                // from the same component this directive is used in
                vnode.context[handler]();
            }
        };
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },
    unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    },
});

export default {
    data(){
        return {
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1,
                start_datetime: null,
                end_datetime: null,
                model_ids: [],
                all_offices: false
            },
            form: {
                source: 2,
                type: 3,
                model_ids: [],
                user_ids: [],
                announcement: '',
                start_datetime: null,
                end_datetime: null,
                notification_type: []
            },
            customToolbar: [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'align': '' }, { 'align': 'center' },{ 'align': 'right' }, { 'align': 'justify' }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link'],
                ['clean']
            ],
            workerList: [],
            isLoading: false,
            isEdit: false,
            announcements: {docs: []},
            selected_announcement: null,
            showCreateDropdownOfficeOptions: false,
            showCreateDropdownUserOptions: false,
            showEditDropdownOfficeOptions: false,
            showEditDropdownUserOptions: false,
        };
    },
    components: {
        VueEditor,
        Multiselect,
        Datetime,
        EmptyTable,
        Pagination,
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
        }),
        user_cred() {
            return getUserLogin();
        },
        selectAllOffice() {
            return this.company_offices.every((element) => {
                if (element && element._id) {
                    return this.form.model_ids.includes(element._id);
                }
            });
        },
    },
    async mounted() {
        await this.getCompanyOffice();
        await this.getWorkerList();
        await this.getAllAnnouncementList();
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getWorker: 'worker/getWorkerListAll',
            getAnnouncementList: 'announcement/getAnnouncementList',
            postAnnouncement: 'announcement/postAnnouncement',
            updateAnnouncement: 'announcement/updateAnnouncement',
            deleteAnnouncement: 'announcement/deleteAnnouncement',
        }),
        async getAllAnnouncementList() {
            this.isLoading = true;
            let sentMeta = {...this.meta};

            if (this.meta.model_ids != []) {
                let newArr = [];
                for (const office of this.meta.model_ids) {
                    newArr.push(office._id);
                }
                sentMeta.model_ids = newArr;
            }
            this.announcements = await this.getAnnouncementList(sentMeta);
            this.isLoading = false;
        },
        async getWorkerList() {
            let query = {};

            if (this.form.model_ids.length > 0)  {
                const companyOfficeIds = this.form.model_ids.map(modelId => modelId);
                query = { ...query, company_office_id: companyOfficeIds };
            }

            this.workerList = await this.getWorker(query);
        },
        limitText (count) {
            return `and ${count} other offices`;
        },
        async showModal(modal_name, data=null) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').show();
                break;
            case 'create-modal':
                await window.UIkit.modal('#create-modal').show();
                break;
            case 'detail-modal':
                this.fillForm(data);
                this.isEdit = false;
                await window.UIkit.modal('#edit-modal').show();
                break;
            case 'edit-modal':
                this.fillForm(data);
                this.isEdit = true;
                await window.UIkit.modal('#edit-modal').show();
                break;
            }
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').hide();
                break;
            case 'create-modal':
                window.UIkit.modal('#create-modal').hide();
                this.resetForm();
                break;
            case 'detail-modal':
                window.UIkit.modal('#edit-modal').hide();
                this.resetForm();
                this.isEdit = false;
                break;
            case 'edit-modal':
                window.UIkit.modal('#edit-modal').hide();
                this.resetForm();
                this.isEdit = false;
                break;
            }
        },
        async setDate() {
            if (this.meta.start_datetime != null && this.meta.end_datetime != null) {
                const start_date = new Date(this.meta.start_datetime);
                const end_date = new Date(this.meta.end_datetime);
                if (start_date > end_date) {
                    return notificationDangerCustom('End date must be greater than start date');
                }
            } else if (this.meta.start_date == null) {
                return notificationDangerCustom('Start date must be filled');
            }
            await this.getAllAnnouncementList();
            this.hideModal('date-filter');
        },
        filterDateText() {
            let date_filter_text = this.dateFilterAsText();
            if (date_filter_text != '') {
                return date_filter_text;
            }
            return 'Choose date range';
        },
        dateFilterAsText() {
            let date_format = '';
            if (this.meta.start_datetime) {
                date_format = date_format + this.formatDate(this.meta.start_datetime);
            }
            if (this.meta.end_datetime) {
                if (date_format !== '') {
                    date_format = date_format + '-';
                }
                date_format = date_format + this.formatDate(this.meta.end_datetime);
            }
            return date_format;
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatDateTime(data) {
            if (data == null) {
                return '-';
            }
            return formatter.datetimeShort(data);
        },
        formatRecipients(data) {
            if (data == null) {
                return '-';
            }
            if (data.length == 0) {
                return 'Semua Mitra';
            }
            if (data.length > 0) {
                let list = '';
                for (let i = 0; i < data.length; i++) {
                    if (!data[i+1]) {
                        list += data[i].name;
                    } else {
                        list += data[i].name+ `, `;
                    }
                }
                return list;
            }
        },
        formatAnnouncement(data) {
            if (data == null) {
                return '-';
            }
            data = data.replace(/<[^>]*>/g, ' ');
            return data.substring(0,26)+'...';
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        openCreateDropdownOfficeOptions() {
            this.showCreateDropdownOfficeOptions = !this.showCreateDropdownOfficeOptions;
        },
        openEditDropdownOfficeOptions() {
            this.showEditDropdownOfficeOptions = !this.showEditDropdownOfficeOptions;
        },
        closeCreateDropdownOfficeOptions() {
            this.showCreateDropdownOfficeOptions = false;
        },
        closeEditDropdownOfficeOptions() {
            this.showEditDropdownOfficeOptions = false;
        },
        openCreateDropdownUserOptions() {
            this.showCreateDropdownUserOptions = !this.showCreateDropdownUserOptions;
        },
        openEditDropdownUserOptions() {
            this.showEditDropdownUserOptions = !this.showEditDropdownUserOptions;
        },
        closeCreateDropdownUserOptions() {
            this.showCreateDropdownUserOptions = false;
        },
        closeEditDropdownUserOptions() {
            this.showEditDropdownUserOptions = false;
        },
        resetForm() {
            this.form = {
                source: 2,
                type: 3,
                model_ids: [],
                user_ids: [],
                announcement: '',
                start_datetime: null,
                end_datetime: null,
                notification_type: []
            };
        },
        async fillForm(data) {
            this.form.id = data._id;
            this.form.start_datetime = data.start_datetime;
            this.form.end_datetime = data.end_datetime;
            this.form.announcement = data.announcement;
            if (data.recipients.length > 0) {
                this.form.model_ids = data.recipients.map(element => element._id);
            }
            this.form.user_ids = data.user_ids.map(element => element._id);
            this.form.notification_type = data.notification_type;

            await this.getWorkerList();
        },
        async selectAllData({ event, data }) {
            const target = event.target;
            if (target.checked) {
                let options = [];

                switch (data) {
                case 'model_ids':
                    options = this.company_offices.map((data) => data._id);
                    break;
                case 'user_ids':
                    options = this.workerList.map((data) => data._id);
                    break;
                }

                this.form[data] = JSON.parse(JSON.stringify(options));
            } else {
                this.form[data] = [];
            }

            if (data === 'model_ids') {
                await this.getWorkerList();
            }
        },
        async selectData({ id, data }) {
            this.form[data] = this.form[data].includes(id) ? this.form[data].filter((dataId) => dataId !== id) : [...this.form[data], id];

            if (data === 'model_ids') {
                await this.getWorkerList();

                // Reselect users
                if (this.form.user_ids.length > 0) {
                    this.form.user_ids = this.form.user_ids.filter(userId => {
                        return this.workerList.some(worker => worker._id === userId);
                    });
                }
            }
        },
        async saveAnnouncement() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                // Parse notification type value
                this.form.notification_type = this.form.notification_type.map(
                    notificationType => parseInt(notificationType)
                );

                let response = null;
                if (this.isEdit) {
                    response = await this.updateAnnouncement(this.form);
                } else {
                    response = await this.postAnnouncement(this.form);
                }

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Announcement saved!');
                    await this.getAllAnnouncementList();
                    if (this.isEdit) {
                        this.hideModal('edit-modal');
                    } else {
                        this.hideModal('create-modal');
                    }
                } else {
                    notificationDanger('Failed to save announcement.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async markDeleteAnnouncement(id) {
            try {
                this.isLoading = true;

                const response = await this.deleteAnnouncement(id);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Announcement deleted!');
                    await this.getAllAnnouncementList();
                } else {
                    notificationDanger('Failed to delete announcement.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>

<style scoped>
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.height-100 {
    height: 100%;
}

.max-height-191 {
    max-height: 191px;
}

.padding-10 {
    padding: 10px;
}

.padding-5-10 {
    padding: 5px 10px;
}

.border-1-e8e8e8 {
    border: 1px solid #e8e8e8
}

.border-1-cccccc {
    border: 1px solid #cccccc;
}
.background-white {
    background: white;
}

.overflow-y-auto {
    overflow-y: auto;
}

.z-1 {
    z-index: 1;
}
</style>
