import api from '@/utils/api';
import { OTHER_DOCUMENT } from '@/utils/api-url';
import { notificationDanger } from '@/utils/notification';

const namespaced = true;

const actions = {
    async uploadDocument(context, data) {
        try {
            const response = await api.apiPostAuth(OTHER_DOCUMENT.UPLOAD_DOCUMENT(data.api, data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async validateDocument(context, data) {
        try {
            const response = await api.apiPatchAuth(OTHER_DOCUMENT.VALIDATE(data.user_id, data.document), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async validateMultipleDocument(context, data) {
        try {
            const response = await api.apiPatchAuth(OTHER_DOCUMENT.VALIDATE_MULTIPLE(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions
};
