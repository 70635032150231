<template>
  <div class="uk-width-1-4@s uk-margin-large-top">
    <div v-if="!authMode">
      <img :src="`${images}/login-logo.png`" alt="" class="uk-margin-bottom" width="340">
      <form @submit.prevent="onSubmitLogin">
        <div class="uk-margin uk-text-left">
          <div class="uk-inline uk-width-1-1">
            <h3 style="color:white">Email {{errors.first('data.email')}}</h3>
            <!-- <span class="uk-form-icon" uk-icon="icon: user"></span> -->
            <input
                name="email"
                class="uk-input"
                type="text"
                placeholder="Enter your email address"
                v-model="formLogin.email"
                v-validate="'required|email'"
                data-vv-name="email"
                data-vv-scope="formLogin"
                :class="{'uk-form-danger': errors.has('formLogin.email')}"
            >
          </div>
          <span class="uk-text-small uk-text-danger" v-show="errors.has('formLogin.email')">{{ errors.first('formLogin.email') }}</span>
        </div>
        <div class="uk-margin uk-text-left">
          <div class="uk-inline uk-width-1-1">
            <h3 style="color:white">Password</h3>
            <!-- <span class="uk-form-icon" uk-icon="icon: lock"></span> -->
            <input
                name="password"
                class="uk-input"
                type="password"
                placeholder="Enter your password"
                autocomplete="new-password"
                v-model="formLogin.password"
                v-validate="'required|min:8'"
                data-vv-name="password"
                data-vv-scope="formLogin"
                :class="{'uk-form-danger': errors.has('formLogin.password')}"
            >
          </div>
          <span class="uk-text-small uk-text-danger" v-show="errors.has('formLogin.password')">{{ errors.first('formLogin.password') }}</span>
        </div>
        <button v-if="!is_loading" class="uk-button uk-button-primary uk-width-1-1" type="submit" :disabled="is_loading">Login</button>
        <button v-else class="uk-button uk-button-primary uk-width-1-1" type="button" disabled><div uk-spinner></div></button>
      </form>
    </div>
    <div v-else-if="authMode === 'keyauth'">
      <form @submit.prevent="onSubmitKeyAuth" class="center-form">
        <div class="uk-margin uk-text-left">
          <div class="uk-inline uk-width-1-1">
            <h3 style="color:white" class="uk-margin-remove-bottom">Key Auth</h3>
            <h5 style="color:white" class="uk-margin-remove-top">Please enter the correct Key Auth</h5>
            <!-- <span class="uk-form-icon" uk-icon="icon: user"></span> -->
            <input
                name="key"
                class="uk-input"
                type="text"
                placeholder="Enter Key Auth"
                v-model="keyAuth.key"
                v-validate="'required'"
                data-vv-name="key"
                data-vv-scope="keyAuth"
                :class="{'uk-form-danger': errors.has('keyAuth.key')}"
            >
          </div>
          <span class="uk-text-small uk-text-danger" v-show="errors.has('keyAuth.key')">{{ errors.first('keyAuth.key') }}</span>
        </div>
        <button v-if="!is_loading" class="uk-button uk-button-primary uk-width-1-1" type="submit" :disabled="is_loading">Submit</button>
        <button v-else class="uk-button uk-button-primary uk-width-1-1" type="button" disabled><div uk-spinner></div></button>
      </form>
      <hr>
      <button class="uk-button uk-button-primary uk-width-1-1" type="button" @click="otpMode('otp')">Use OTP Instead</button>
    </div>
    <div v-else>
      <form @submit.prevent="onSubmitOTP" class="center-form">
        <div class="uk-margin uk-text-left">
          <div class="uk-inline uk-width-1-1">
            <h3 style="color:white" class="uk-margin-remove-bottom">OTP</h3>
            <h5 style="color:white" class="uk-margin-remove-top">Please enter the OTP Code that we have sent to your email. The code will expires in 5 minutes.</h5>
            <!-- <span class="uk-form-icon" uk-icon="icon: user"></span> -->
            <input
                name="key"
                class="uk-input"
                type="text"
                placeholder="Enter OTP Code"
                v-model="OTP.verify_code"
                v-validate="'required'"
                data-vv-name="otp"
                data-vv-scope="formOTP"
                :class="{'uk-form-danger': errors.has('OTP.verify_code')}"
            >
          </div>
          <span class="uk-text-small uk-text-danger" v-show="errors.has('OTP.verify_code')">{{ errors.first('OTP.verify_code') }}</span>
        </div>
        <button v-if="!is_loading" class="uk-button uk-button-primary uk-width-1-1" type="submit" :disabled="OTP.verify_code.length < 6">Submit</button>
        <button v-else class="uk-button uk-button-primary uk-width-1-1" type="button" disabled><div uk-spinner></div></button>
      </form>
      <h5 style="color:white" class="uk-margin-remove-bottom">Resend code in {{ formattedTimer }}</h5>
      <button
          @click="RequestNewCode"
          :class="{'uk-background-muted': resendBtnDisabled }"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
          type="button"
          :disabled="resendBtnDisabled">
        Request New Code
      </button>
      <hr>
      <button class="uk-button uk-button-primary uk-width-1-1" type="button" @click="otpMode('keyauth')">Use Key Auth Instead</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    data() {
        return {
            timer: 60,
            resendBtnDisabled: true,
            intervalId: null,
            authMode: null, // otp, keyauth
            images: PREFIX_IMAGE,
            is_loading: false,
            btnOTP: true,
            formLogin: {
                email: '',
                password: ''
            },
            keyAuth: {
                key: ''
            },
            OTP: {
                verify_code: '',
                email: ''
            },
            send_OTP: {
                email: ''
            }
        };
    },
    computed: {
        formattedTimer() {
            const minutes = Math.floor(this.timer / 60).toString().padStart(2, '0');
            const seconds = (this.timer % 60).toString().padStart(2, '0');
            return `${minutes}:${seconds}`;
        }
    },
    methods: {
        ...mapActions({
            loginUser: 'auth/login',
            validateKeyAuthUser: 'auth/validateKeyAuth',
            validateOTPUser: 'auth/validateOTP',
            sendOTPCodeUser: 'auth/sendOTPCode'
        }),
        updateTimer() {
            if (this.timer > 0) {
                this.timer--;
            } else {
                this.resendBtnDisabled = false;
                clearInterval(this.intervalId);
            }
        },
        async onSubmitLogin() {
            const validate = await this.$validator.validateAll('formLogin');
            if (!validate || this.$validator.errors.any('formLogin')) return;
            await this.login();
        },
        async onSubmitKeyAuth() {
            // validate spesific form "keyAuth"
            const validate = await this.$validator.validateAll('keyAuth');
            // Check returned validate
            if (!validate || this.$validator.errors.any('keyAuth')) return;
            await this.validateKeyAuth();
        },
        async onSubmitOTP() {
            const validate = await this.$validator.validateAll('formOTP');
            if (!validate || this.$validator.errors.any('formOTP')) return;
            await this.validateOTP();
        },
        async login() {
            this.is_loading = true;
            const result = await this.loginUser(this.formLogin);
            this.is_loading = false;
            if (result) {
                this.OTP.email = this.formLogin.email;
                this.send_OTP.email = this.formLogin.email;
                this.authMode = 'keyauth';
            }
        },
        async validateKeyAuth() {
            this.is_loading = true;
            await this.validateKeyAuthUser(this.keyAuth);
            this.is_loading = false;
        },
        async validateOTP() {
            this.is_loading = true;
            await this.validateOTPUser(this.OTP);
            this.is_loading = false;
        },
        async otpMode(mode) {
            this.authMode = mode;
            await this.sendOTPCodeUser(this.send_OTP);
            this.intervalId = setInterval(this.updateTimer, 1000);
        },
        async RequestNewCode() {
            this.timer = 60;
            this.resendBtnDisabled = true;
            await this.sendOTPCodeUser(this.send_OTP);
            this.intervalId = setInterval(this.updateTimer, 1000);
        }
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
};
</script>

<style scoped>
.center-form {
  margin-top: 50%
}

</style>
