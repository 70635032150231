import api from '@/utils/api';
import { WORKER, EXMITRA, SP_REQUEST } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getWorkerListAll(context, data) {
        try {
            const response = await api.apiGetAuth(WORKER.LIST_ALL, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllWorkerList(context, data) {
        try {
            const response = await api.apiGetAuth(WORKER.ALL_WORKER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWorkerList(context, data) {
        try {
            const response = await api.apiGetAuth(WORKER.LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getExWorkerList(context, data) {
        try {
            const response = await api.apiGetAuth(EXMITRA.LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraDetail(context, data) {
        try {
            const response = await api.apiGetAuth(WORKER.MITRA_DETAIL(data));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraListContract(context, data) {
        try {
            const response = await api.apiGetAuth(WORKER.LIST_CONTRACT_BY_USER(data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadContract(context, data) {
        try {
            const {sign_contract_id, user_id, job_partner_id} = data;
            const response = await api.apiGetAuth(WORKER.DOWNLOAD_CONTRACT(sign_contract_id, user_id, job_partner_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadSignature(context, data) {
        try {
            const {sign_contract_id, user_id, job_partner_id} = data;
            const response = await api.apiGetAuth(WORKER.DOWNLOAD_SIGNATURE(sign_contract_id, user_id, job_partner_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadUnsignedContract(context, data) {
        try {
            const {sign_contract_id, user_id, job_partner_id} = data;
            const response = await api.apiGetAuth(WORKER.DOWNLOAD_UNSIGNED_CONTRACT(sign_contract_id, user_id, job_partner_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getExMitraCVDetail(context, data) {
        try {
            const response = await api.apiGetAuth(EXMITRA.EX_MITRA_CV_DETAIL(data), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWorkerSalary(context, data) {
        try {
            const response = await api.apiGetAuth(WORKER.SALARY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteMitra(context, data) {
        try {
            const response = await api.apiPostAuth(WORKER.DELETE_MITRA, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async moveOrChangeRoleMitra(context, data) {
        try {
            const {id, ...payload} = data;
            const response = await api.apiPostAuth(WORKER.MOVE_CHANGE_MITRA(id), payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async moveMultipleMitra(context, data) {
        try {
            const response = await api.apiPostAuth(WORKER.MOVE_MULTIPLE_MITRA, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async holdUnholdMitra(context, data) {
        try {
            const {id} = data;
            const response = await api.apiPatchAuth(WORKER.HOLDUNHOLD(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async holdUnholdMultipleMitra(context, data) {
        try {
            const response = await api.apiPatchAuth(WORKER.HOLD_UNHOLD_MULTIPLE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async reactiveExMitra(context, data) {
        try {
            const { id } = data;
            const response = await api.apiPatchAuth(EXMITRA.REACTIVE(id));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async renewContractMitra(context, data) {
        try {
            const { id, payload } = data;
            const response = await api.apiPatchAuth(WORKER.RENEW_CONTRACT_MITRA(id), payload);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async renewMultiContractMitra(context, payload) {
        try {
            const response = await api.apiPatchAuth(WORKER.RENEW_MULTI_CONTRACT_MITRA, payload);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async downloadMitraDataExcel(context, data) {
        try {
            const response =  await api.apiGetAuth(WORKER.DOWNLOAD_MITRA_DATA, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadMitraData(context, data) {
        try {
            const response = await api.apiPostAuth(WORKER.UPLOAD_MITRA_DATA(data.get('type'), data.get('source')), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraSpData(context, id) {
        try {
            const response = await api.apiGetAuth(SP_REQUEST.SP_DETAIL(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async sendSpToMitra(context, data) {
        try {
            const { id, payload } = data;
            const response = await api.apiPostAuth(SP_REQUEST.SP_SEND(id), payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async cancelSpMitra(context, id) {
        try {
            const response = await api.apiPatchAuth(SP_REQUEST.SP_CANCEL(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postResendEmail(context, data) {
        try {
            const response = await api.apiPostAuth(WORKER.RESEND_EMAIL, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postDeletePartner(context, user_id) {
        try {
            const response = await api.apiDeleteAuth(WORKER.DELETE_PARTNER(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadOnboardingMitradataExcel(context, data) {
        try {
            const response =  await api.apiGetAuth(WORKER.DOWNLOAD_ONBOARDING_MITRA_DATA(data.source), data.payload);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async reblastNotification(context, data) {
        try {
            const response =  await api.apiPostAuth(WORKER.REBLAST_NOTIFICATION(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
