<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between">
            <div class="uk-flex uk-child-width-expand@s" style="gap: 20px">
                <form @change="getData">
                    <select name="office" id="office" class="uk-select" v-model="meta.status">
                        <option :value="null" selected>All Status</option>
                        <option :value="1">Requested</option>
                        <option :value="2">Approved</option>
                        <option :value="3">Rejected</option>
                        <option :value="4">Transferred</option>
                    </select>
                </form>
                <form @change="getData">
                    <select name="sort" id="sort" class="uk-select" v-model="meta.sort">
                        <option value="-1" selected>Latest</option>
                        <option value="1">Earliest</option>
                    </select>
                </form>
            </div>
        </div>
        <!-- Main Table -->
        <div class="uk-card uk-card-default uk-margin" v-if="!consist(user_cred.roles, ['user-marketing'])">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-center">Request ID</th>
                            <th class="uk-text-center">Request Date</th>
                            <th class="uk-text-center">Employee Name</th>
                            <th class="uk-text-center">Notes</th>
                            <th class="uk-text-center">Amount</th>
                            <th class="uk-text-center">Approved Amount</th>
                            <th class="uk-text-center">Status</th>
                            <th class="uk-text-center">Approval Date</th>
                            <th class="uk-text-center">Approved By</th>
                        </tr>
                    </thead>
                    <tbody v-if="data.docs.length>0">
                        <tr v-for="(item, i) in data.docs" :key="i">
                            <td class="uk-text-center uk-text-middle">
                                <a style="text-decoration: none;" @click="detail({salary_adjustment_id: item._id})" href="javascript:void(0)">{{item._id || '-'}}</a>
                            </td>
                            <td class="uk-text-center uk-text-middle">{{ moment(item.request_date).format('DD MMM YYYY') }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.user.fullname }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.request_note }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(item.request_amount)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.decision_amount ? formatCurrency(parseInt(item.decision_amount)) : '-' }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatStatus(item.status) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.decision_date ? moment(item.decision_date).format('DD MMM YYYY') : '-' }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.decision_by ? item.decision_by : '-' }}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="9"/>
                </table>
            </div>
            <pagination
                :total-data="data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End of Main Table -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import moment from 'moment';
moment.locale('id');

export default {
    data() {
        return {
            moment: moment,
            meta: {
                limit: 10,
                page: 1,
                status: null,
                sort: '-1'
            },
            data: {
                docs: [],
                totalDocs: 0
            },
            selected_data: null
        };
    },
    components: {
        EmptyTable,
        Pagination
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        async meta() {
            await this.getData();
        },
    },
    beforeMount() {
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        ...mapActions({
            getCashAdvances: 'salary_adjustment/getCashAdvances',
        }),
        async getData() {
            this.data = await this.getCashAdvances(this.meta);
        },
        detail(data) {
            this.$router.push({ path: `cash-advance/detail`, query: data });
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        formatStatus(data) {
            switch (data) {
            case 1:
                return 'Requested';
            case 2:
                return 'Approved';
            case 3:
                return 'Rejected';
            case 4:
                return 'Transferred';
            }
        }
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.report-download{
    font-family: 'Roboto';
    color: #5FD855;
    font-size: 20px;
}
</style>
