<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s" uk-grid>
            <div v-if="getRole() !== 'supervisor'">
                <div v-if="company_office">
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                    </div>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="20"> Select Location filter
                    </div>
                </div>
                <p v-if="company_office" class="reset-filter" @click="resetLocation">Reset Location Filter</p>
            </div>
            <div v-else>
              <div v-if="company_office">
                  <div class="location-header">
                      <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                  </div>
                  <div class="location-header">
                      <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                  </div>
              </div>
            </div>
            <div>
            </div>
            <div>
                <div style="overflow:auto">
                    <div
                        v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','asd123'])"
                        class="shift-add"
                        style="cursor:pointer;float:right"
                        @click="checkAdd"
                        >
                        <img :src="`${images}/shift-add.png`" alt="" width="25">
                        Tambah shift
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand uk-text-center">Shift name</th>
                            <th v-if="company_office" class="uk-text-center">Warehouse</th>
                            <th class="uk-text-center">Start<br>Time</th>
                            <th class="uk-text-center">End<br>Time</th>
                            <!-- <th class="uk-text-center">Pembagian Shift</th> -->
                            <th class="uk-text-center">Duration<br>(hrs)</th>
                            <!-- <th class="uk-text-center">Type</th> -->
                            <th class="uk-text-center" v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','admin-bd'])">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="shifts.docs.length>0">
                        <tr v-for="(shift, i) in shifts.docs" :key="i">
                            <td>{{shift.name || '-'}}</td>
                            <td v-if="company_office" class="uk-text-center">{{formatWarehouseName(shift.company_office_id)}}</td>
                            <td class="uk-text-center">{{formatHourMinute(shift.start_shift)}}</td>
                            <td class="uk-text-center">{{formatHourMinute(shift.end_shift)}}</td>
                            <!-- <td class="uk-text-center">{{shift.is_not_split_shift == true ? 'Tidak' : 'Ya'}}</td> -->
                            <td class="uk-text-center">{{formatDuration(shift)}}</td>
                            <!-- <td class="uk-text-center">{{formatType(shift)}}</td> -->
                            <td class="uk-text-center" v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','admin-bd'])">
                                <button
                                  style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#0275D8"
                                  class="uk-button-primary"
                                  @click="selectData(shift)">
                                  Edit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
            <pagination
                :total-data="shifts.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="location-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('location-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input property for which you want to see details</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">City</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office City"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="" disabled selected>Choose City</option>
                                <option v-for="(city, index) in cities" :key="index" :value="city">{{city}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">Property's Name</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office Property"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="company_office"
                            >
                                <option value="" disabled selected>Choose Property</option>
                                <option v-for="(office, index) in filtered_offices" :key="index" :value="office">{{office.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setLocation">Apply</button>
                </div>
            </div>
        </div>

        <div id="edit-shift" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-shift')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Edit Shift</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Nama Shift</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Company Office"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    v-model="selected_data.name"
                                >
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Dari</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start Shift"
                                    v-model="selected_data.start_shift"
                                    :placeholder="formatHourMinute(selected_data.start_shift)"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">Sampai</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End Shift"
                                    v-model="selected_data.end_shift"
                                    :placeholder="formatHourMinute(selected_data.end_shift)"
                                ></datetime>
                            </div>
                        </div>
                        <!-- <div class="uk-width-1-1 uk-column-1-2@m">
                            <div class="uk-form-controls">
                                <input
                                    name="Split Shift"
                                    class="uk-checkbox"
                                    id="form-stacked-text"
                                    type="checkbox"
                                    v-model="selected_data.is_not_split_shift"
                                > Tidak ada pembagian shift
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m" v-if="selected_data.is_not_split_shift == false">
                            <label class="uk-form-label" for="form-stacked-text">Paruh Awal</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start First Half Shift"
                                    v-model="selected_data.start_first_half_shift"
                                    :placeholder="formatHourMinute(selected_data.start_first_half_shift)"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">-</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End First Half Shift"
                                    v-model="selected_data.end_first_half_shift"
                                    :placeholder="formatHourMinute(selected_data.end_first_half_shift)"
                                ></datetime>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m" v-if="selected_data.is_not_split_shift == false">
                            <label class="uk-form-label" for="form-stacked-text">Istirahat</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start Break Shift"
                                    v-model="selected_data.start_break_shift"
                                    :placeholder="formatHourMinute(selected_data.start_break_shift)"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">-</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End Break Shift"
                                    v-model="selected_data.end_break_shift"
                                    :placeholder="formatHourMinute(selected_data.end_break_shift)"
                                ></datetime>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m" v-if="selected_data.is_not_split_shift == false">
                            <label class="uk-form-label" for="form-stacked-text">Paruh Akhir</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start Last Half Shift"
                                    v-model="selected_data.start_last_half_shift"
                                    :placeholder="formatHourMinute(selected_data.start_last_half_shift)"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">-</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End Last Half Shift"
                                    v-model="selected_data.end_last_half_shift"
                                    :placeholder="formatHourMinute(selected_data.end_last_half_shift)"
                                ></datetime>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="uk-modal-footer" style="display:flex;justify-content:space-between">
                    <button class="uk-button uk-button-danger" type="button" @click="showModal('confirmation-delete-box')"
                    v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation'])">Delete</button>
                    <button :disabled="timeIsInvalid" class="uk-button uk-button-primary" type="button" @click="updateData"
                    v-if="consist(user_cred.roles, ['superadmin-product','superadmin-tech','admin-operation','admin-bd'])">Save</button>
                </div>
            </div>
        </div>

        <div id="add-shift" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('add-shift')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Tambah Shift</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Nama Shift</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Shift Name"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    v-model="selected_data.name"
                                >
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Dari</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start Shift"
                                    v-model="selected_data.start_shift"
                                    :placeholder="selected_data.start_shift == null ? '-' : formatHourMinute(selected_data.start_shift)"
                                    @input="adjustStartShift"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">Sampai</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End Shift"
                                    v-model="selected_data.end_shift"
                                    :placeholder="selected_data.end_shift == null ? '-' : formatHourMinute(selected_data.end_shift)"
                                    @input="adjustEndShift"
                                ></datetime>
                            </div>
                        </div>
                        <!-- <div class="uk-width-1-1 uk-column-1-2@m">
                            <div class="uk-form-controls">
                                <input
                                    name="Split Shift"
                                    class="uk-checkbox"
                                    id="form-stacked-text"
                                    type="checkbox"
                                    v-model="selected_data.is_not_split_shift"
                                > Tidak ada pembagian shift
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m" v-if="selected_data.is_not_split_shift == false">
                            <label class="uk-form-label" for="form-stacked-text">Paruh Awal</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start First Half Shift"
                                    v-model="selected_data.start_first_half_shift"
                                    :placeholder="selected_data.start_first_half_shift == null ? '-' : formatHourMinute(selected_data.start_first_half_shift)"
                                    @input="adjustStartFirstHalfShift"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">-</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End First Half Shift"
                                    v-model="selected_data.end_first_half_shift"
                                    :placeholder="selected_data.end_first_half_shift == null ? '-' : formatHourMinute(selected_data.end_first_half_shift)"
                                    @input="adjustEndFirstHalfShift"
                                ></datetime>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m" v-if="selected_data.is_not_split_shift == false">
                            <label class="uk-form-label" for="form-stacked-text">Istirahat</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start Break Shift"
                                    v-model="selected_data.start_break_shift"
                                    :placeholder="selected_data.start_break_shift == null ? '-' : formatHourMinute(selected_data.start_break_shift)"
                                    @input="adjustStartBreakShift"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">-</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End Break Shift"
                                    v-model="selected_data.end_break_shift"
                                    :placeholder="selected_data.end_break_shift == null ? '-' : formatHourMinute(selected_data.end_break_shift)"
                                    @input="adjustEndBreakShift"
                                ></datetime>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m" v-if="selected_data.is_not_split_shift == false">
                            <label class="uk-form-label" for="form-stacked-text">Paruh Akhir</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="Start Last Half Shift"
                                    v-model="selected_data.start_last_half_shift"
                                    :placeholder="selected_data.start_last_half_shift == null ? '-' : formatHourMinute(selected_data.start_last_half_shift)"
                                    @input="adjustStartLastHalfShift"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">-</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="time"
                                    name="End Last Half Shift"
                                    v-model="selected_data.end_last_half_shift"
                                    :placeholder="selected_data.end_last_half_shift == null ? '-' : formatHourMinute(selected_data.end_last_half_shift)"
                                    @input="adjustEndLastHalfShift"
                                ></datetime>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button :disabled="timeIsInvalid" class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="addData">Save</button>
                </div>
            </div>
        </div>

        <div id="confirmation-heads-up" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-heads-up')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center"><img :src="`${images}/warning.png`" alt="" width="50"> Anda belum memilih warehouse!</h2>
                </div>
                <div class="uk-modal-body uk-text-center">
                    <p>Silahkan pilih Warehouse terlebih dahulu pada filter warehouse.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('confirmation-heads-up')">Kembali</button>
                </div>
            </div>
        </div>

        <div id="confirmation-edit-box" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-edit-box')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Shift ini dipakai pada jadwal</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Silahkan hapus dahulu semua implementasi shift ini pada jadwal untuk mengedit dan menghapus.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('confirmation-edit-box')">Kembali</button>
                </div>
            </div>
        </div>

        <div id="confirmation-delete-box" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-delete-box')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left">Are you sure ?</h2>
                </div>
                <div class="uk-modal-body">
                    <p>{{selected_data.name}} will be deleted.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="declineConfirmationEdit">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="acceptConfirmationDelete">Yes</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import { Datetime } from 'vue-datetime';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import moment from 'moment';
moment.locale('id');

export default {
    data() {
        return {
            meta: {
                limit: 50,
                page: 1,
                status: '',
                company_office_id: ''
            },
            images: PREFIX_IMAGE,
            company_office: '',
            selected_city: '',
            cities: [],
            filtered_offices: [],
            selected_data: {
                company_office_id: '',
                name: '',
                status: 0,
                is_not_split_shift: true,
                start_shift: null,
                startShift: null,
                end_shift: null,
                endShift: null,
                start_first_half_shift: null,
                end_first_half_shift: null,
                start_break_shift: null,
                end_break_shift: null,
                start_last_half_shift: null,
                end_last_half_shift: null
            },
            timeIsInvalid: false
        };
    },
    components: {
        // LabelStatus,
        EmptyTable,
        Pagination,
        Datetime
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            shifts: 'option_data/shifts',
        }),
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        meta() {
            this.getShifts(this.meta);
        },
    },
    async mounted() {
        await this.getCompanyOffice();
        // const profile = await this.getProfile();
        // this.company_office = this.$store.state.selected_company == '' ? profile.company_office : this.$store.state.selected_company;
        // this.meta.company_office_id = await this.company_office._id;
        // this.selectCompanyOffice();
        await this.getShifts(this.meta);
        this.setCity();
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getCompanyOfficeId: 'company_office/getCompanyOfficeId',
            getProfile: 'auth/getProfile',
            getShifts: 'option_data/getShifts',
            addShift: 'option_data/postShift',
            checkShift: 'option_data/checkShift',
            updateShift: 'option_data/updateShift',
            deleteShift: 'option_data/deleteShift',
        }),
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        selectCompanyOffice(){
            this.company_office = this.company_offices.length > 0 ? this.company_offices[0] : 'n/a';
        },
        formatWarehouseName(data) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('data', data);
            }
            if (this.company_office == '') {
                return '-';
            } else {
                return this.company_office.name;
            }
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatHourMinute(data) {
            if (data == null) {
                return '-';
            }
            return formatter.hourMinuteOnly(data);
        },
        formatDuration(data) {
            if (data.start_shift == null) {
                return '-';
            } else {
                const start = moment.utc(data.start_shift);
                const end = (data.start_shift.hour > data.end_shift.hour) == true
                    ? moment.utc(data.end_shift).add(1, 'days')
                    : moment.utc(data.end_shift);
                const duration = moment.duration(end.diff(start));
                const hours = Math.round(duration.asHours(), 2);
                return hours;
            }
        },
        formatType(data) {
            if (data.is_not_split_shift == false) {
                return 'Full Shift';
            } else {
                return 'Paruh Awal, Paruh Akhir';
            }
        },
        formatAbsolute(data) {
            return formatter.absoluteNumber(data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        redirectPage(link) {
            window.open(link, '_blank');
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'location-filter':
                await window.UIkit.modal('#location-filter').hide();
                break;
            case 'add-shift':
                await window.UIkit.modal('#add-shift').hide();
                break;
            case 'edit-shift':
                await window.UIkit.modal('#edit-shift').hide();
                this.selected_data= {
                    company_office_id: '',
                    name: '',
                    is_not_split_shift: true,
                    start_shift: null,
                    end_shift: null,
                    start_first_half_shift: null,
                    end_first_half_shift: null,
                    start_break_shift: null,
                    end_break_shift: null,
                    start_last_half_shift: null,
                    end_last_half_shift: null
                };
                this.timeIsInvalid = false;
                break;
            case 'confirmation-delete-box':
                await window.UIkit.modal('#confirmation-delete-box').hide();
                break;
            case 'confirmation-edit-box':
                await window.UIkit.modal('#confirmation-edit-box').hide();
                break;
            case 'confirmation-heads-up':
                await window.UIkit.modal('#confirmation-heads-up').hide();
                break;
            case 'success-edit':
                await window.UIkit.modal('#success-edit').hide();
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'location-filter':
                this.setCity();
                await window.UIkit.modal('#location-filter').show();
                break;
            case 'add-shift':
                await window.UIkit.modal('#add-shift').show();
                break;
            case 'edit-shift':
                await window.UIkit.modal('#edit-shift').show();
                break;
            case 'confirmation-delete-box':
                await window.UIkit.modal('#confirmation-delete-box').show();
                break;
            case 'confirmation-edit-box':
                await window.UIkit.modal('#confirmation-edit-box').show();
                break;
            case 'confirmation-heads-up':
                await window.UIkit.modal('#confirmation-heads-up').show();
                break;
            case 'success-edit':
                await window.UIkit.modal('#success-edit').show();
                break;
            }
        },
        setCity() {
            this.cities = [...new Set(this.company_offices.map(data => data.city))];
            this.filtered_offices = [];
        },
        filterOffice() {
            this.filtered_offices = this.company_offices.filter(data => data.city === this.selected_city);
        },
        setLocation() {
            if (this.company_office == '') {
                return notificationDangerCustom('Please select one of property');
            }
            this.meta.property_city = this.company_office.city;
            this.meta.property_name = this.company_office.name;
            this.meta.company_office_id = this.company_office._id;
            this.getShifts(this.meta);
            this.hideModal('location-filter');
            this.$store.state.selected_company = this.company_office;
        },
        resetLocation() {
            this.meta.property_city = null;
            this.meta.property_name = null;
            this.company_office= '';
            this.meta.company_office_id= '';
            this.selected_city= '';
            this.cities= [];
            this.filtered_offices= [];
            this.getShifts(this.meta);
            this.$store.state.selected_company = '';
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        checkAdd() {
            if (this.company_office == '') {
                this.showModal('confirmation-heads-up');
            } else {
                this.showModal('add-shift');
            }
        },
        async addData() {
            const data = {
                company_office_id: this.company_office._id == undefined ? this.showModal('confirmation-heads-up') : this.company_office._id,
                name: this.selected_data.name,
                is_not_split_shift: this.selected_data.is_not_split_shift,
                start_shift: this.selected_data.start_shift,
                end_shift: this.selected_data.end_shift,
                start_first_half_shift: this.selected_data.start_first_half_shift,
                end_first_half_shift: this.selected_data.end_first_half_shift,
                start_break_shift: this.selected_data.start_break_shift,
                end_break_shift: this.selected_data.end_break_shift,
                start_last_half_shift: this.selected_data.start_last_half_shift,
                end_last_half_shift: this.selected_data.end_last_half_shift
            };
            const response = await this.addShift(data);
            if (response.status == '1') {
                await this.getShifts(this.meta);
                this.hideModal('add-shift');
                return notificationSuccess(`Shift ${data.name} berhasil ditambahkan`);
            }
        },
        async selectData(data) {
            const payload = {
                id: data._id
            };
            const response = await this.checkShift(payload);
            if (response == undefined) {
                return this.showModal('confirmation-edit-box');
            } else if (response.status == 'OK') {
                this.selected_data = {
                    _id: data._id,
                    company_office_id: data.company_office_id,
                    name: data.name,
                    is_not_split_shift: data.is_not_split_shift,
                    start_shift: {
                        hour: data.start_shift.hour,
                        minute: data.start_shift.minute
                    },
                    end_shift: {
                        hour: data.end_shift.hour,
                        minute: data.end_shift.minute
                    },
                    start_first_half_shift: data.is_not_split_shift == true ? null : {
                        hour: data.start_first_half_shift.hour,
                        minute: data.start_first_half_shift.minute
                    },
                    end_first_half_shift: data.is_not_split_shift == true ? null : {
                        hour: data.end_first_half_shift.hour,
                        minute: data.end_first_half_shift.minute
                    },
                    start_break_shift: data.is_not_split_shift == true ? null : {
                        hour: data.start_break_shift.hour,
                        minute: data.start_break_shift.minute
                    },
                    end_break_shift: data.is_not_split_shift == true ? null : {
                        hour: data.end_break_shift.hour,
                        minute: data.end_break_shift.minute
                    },
                    start_last_half_shift: data.is_not_split_shift == true ? null : {
                        hour: data.start_last_half_shift.hour,
                        minute: data.start_last_half_shift.minute
                    },
                    end_last_half_shift: data.is_not_split_shift == true ? null : {
                        hour: data.end_last_half_shift.hour,
                        minute: data.end_last_half_shift.minute
                    }
                };
                this.showModal('edit-shift');
            }
        },
        async updateData() {
            const data = {
                id: this.selected_data._id,
                company_office_id: this.selected_data.company_office_id,
                name: this.selected_data.name,
                is_not_split_shift: this.selected_data.is_not_split_shift,
                start_shift: this.selected_data.start_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.start_shift).getHours(),
                        minute: new Date(this.selected_data.start_shift).getMinutes()
                    }
                    : this.selected_data.start_shift,
                end_shift: this.selected_data.end_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.end_shift).getHours(),
                        minute: new Date(this.selected_data.end_shift).getMinutes()
                    }
                    : this.selected_data.end_shift,
                start_first_half_shift: this.selected_data.start_first_half_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.start_first_half_shift).getHours(),
                        minute: new Date(this.selected_data.start_first_half_shift).getMinutes()
                    }
                    : this.selected_data.start_first_half_shift,
                end_first_half_shift: this.selected_data.end_first_half_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.end_first_half_shift).getHours(),
                        minute: new Date(this.selected_data.end_first_half_shift).getMinutes()
                    }
                    : this.selected_data.end_first_half_shift,
                start_break_shift: this.selected_data.start_break_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.start_break_shift).getHours(),
                        minute: new Date(this.selected_data.start_break_shift).getMinutes()
                    }
                    : this.selected_data.start_break_shift,
                end_break_shift: this.selected_data.end_break_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.end_break_shift).getHours(),
                        minute: new Date(this.selected_data.end_break_shift).getMinutes()
                    }
                    : this.selected_data.end_break_shift,
                start_last_half_shift: this.selected_data.start_last_half_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.start_last_half_shift).getHours(),
                        minute: new Date(this.selected_data.start_last_half_shift).getMinutes()
                    }
                    : this.selected_data.start_last_half_shift,
                end_last_half_shift: this.selected_data.end_last_half_shift.hour == undefined
                    ? {
                        hour: new Date(this.selected_data.end_last_half_shift).getHours(),
                        minute: new Date(this.selected_data.end_last_half_shift).getMinutes()
                    }
                    : this.selected_data.end_last_half_shift
            };
            const response = await this.updateShift(data);
            if (response.status == 'OK') {
                await this.getShifts(this.meta);
                this.hideModal('edit-shift');
                return notificationSuccess(`Shift ${data.name} berhasil diubah`);
            }
        },
        async acceptConfirmationDelete() {
            const data = {
                id: this.selected_data._id,
            };
            const response = await this.deleteShift(data);
            if (response.status == 'OK') {
                await this.getShifts(this.meta);
                await this.hideModal('confirmation-delete-box');
                return notificationSuccess('Shift deleted');
            }
        },
        declineConfirmationEdit() {
            this.hideModal('confirmation-delete-box');
            this.showModal('edit-shift');
        },
        async updateDataConfirm() {
            await window.UIkit.modal('#edit-shift').hide();
            this.showModal('confirmation-edit-box-2');
        },
        adjustStartShift(value) {
            if (value == '') {
                return this.selected_data.start_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.start_shift = {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        adjustStartFirstHalfShift(value) {
            if (value == '') {
                return this.selected_data.start_first_half_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.start_first_half_shift = this.selected_data.start_shift;
            }
        },
        adjustStartBreakShift(value) {
            if (value == '') {
                return this.selected_data.start_break_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.start_break_shift = {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        adjustStartLastHalfShift(value) {
            if (value == '') {
                return this.selected_data.start_last_half_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.start_last_half_shift = {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        adjustEndShift(value) {
            if (value == '') {
                return this.selected_data.end_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.end_shift = {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        adjustEndFirstHalfShift(value) {
            if (value == '') {
                return this.selected_data.end_first_half_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.end_first_half_shift = {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        adjustEndBreakShift(value) {
            if (value == '') {
                return this.selected_data.end_break_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.end_break_shift = {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        adjustEndLastHalfShift(value) {
            if (value == '') {
                return this.selected_data.end_last_half_shift = null;
            } else {
                this.timeIsInvalid = false;
                this.selected_data.end_last_half_shift = this.selected_data.end_shift;
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
.shift-add{
  font-family: 'Roboto';
  color: #5FD855;
  font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
</style>
