<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <section class="uk-flex uk-flex-between uk-flex-middle">
                <!-- Filter job initiation list -->
                <div v-if="$can('READ', 'job-vacancy')" class="uk-grid-small" uk-grid>
                    <div class="uk-width-auto">
                        <select
                            class="uk-select"
                            v-model="meta.company_office_id"
                            @change="filterJobInitiation"
                        >
                            <option value="" selected>All Office</option>
                            <option
                                v-for="(office, index) in officeOptions"
                                :key="index"
                                :value="office._id"
                            >{{ office.name || '-' }}</option>
                        </select>
                    </div>
                    <div class="uk-width-auto">
                        <select
                            class="uk-select"
                            v-model="meta.role_id"
                            @change="filterJobInitiation"
                        >
                            <option value="" selected>All Job Title</option>
                            <option
                                v-for="(role, index) in roleOptions"
                                :key="index"
                                :value="role._id"
                            >{{ role.name || '-' }}</option>
                        </select>
                    </div>
                    <div class="uk-width-auto">
                        <select
                            class="uk-select"
                            v-model="meta.status"
                            @change="filterJobInitiation"
                        >
                            <option value="">All Status</option>
                            <option value="0" selected>In Progress</option>
                            <option value="2">Completed</option>
                        </select>
                    </div>
                </div>
                <!-- End filter job initiation list -->
                <div v-if="$can('WRITE', 'job-vacancy') && !meta.company_office_id">
                    <button
                        class="uk-button uk-button-primary uk-text-bold uk-border-rounded uk-text-nowrap"
                        @click="openMultiJobPost"
                    >
                        <img :data-src="`${images}/plus_multi.svg`" alt="icon add multi" uk-img class="photo">
                        Create Multiple Job Posts
                    </button>
                </div>
            </section>
            <section v-if="meta.company_office_id" class="uk-flex uk-flex-between uk-margin-top">
                <!-- open excel form download modal -->
                <div>
                    <button
                        class="uk-button uk-button-default uk-text-nowrap uk-margin-right"
                        style="
                            border-radius: 5px;
                            border: 1px solid #0abab5;
                            color: #0abab5;
                        "
                        @click="downloadTemplate()"
                    >
                        <img :src="`${images}/shift-download.svg`" alt="" width="25" uk-img class="photo">
                        <span>Download Template</span>
                    </button>
                    <!-- open excel form upload modal -->
                    <button
                        class="uk-button uk-button-default uk-text-nowrap"
                        style="
                            border-radius: 5px;
                            border: 1px solid #0abab5;
                            color: white;
                            background-color: #0abab5;
                        "
                        @click="$refs.uploadTemplate.click()"
                    >
                        <img :src="`${images}/shift-upload.svg`" alt="" width="25" uk-img class="photo">
                        <span>Import Template</span>
                    </button>
                    <input
                        ref="uploadTemplate"
                        type="file"
                        accept=".xls,.xlsx"
                        style="display: none"
                        @change="uploadTemplate"
                    />
                </div>
                <div v-if="$can('WRITE', 'job-vacancy')">
                    <button
                        class="uk-button uk-button-primary uk-text-bold uk-border-rounded uk-text-nowrap"
                        @click="openMultiJobPost"
                    >
                        <img :data-src="`${images}/plus_multi.svg`" alt="icon add multi" uk-img class="photo">
                        Create Multiple Job Posts
                    </button>
                </div>
            </section>

            <!-- Job initiation list -->
            <div v-if="$can('READ', 'job-vacancy')" class="uk-card uk-card-default uk-margin-top">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-medium uk-table-striped">
                        <thead>
                            <tr>
                                <th v-if="$can('READ', 'job-vacancy')" class="uk-text-nowrap uk-text-center">Action</th>
                                <th class="uk-table-expand">Office</th>
                                <th class="uk-table-expand">Job Title</th>
                                <th class="uk-table-shrink">Manpower</th>
                                <th class="uk-table-shrink">Fulfilled</th>
                                <th class="uk-table-expand">Start Date</th>
                                <th class="uk-table-expand">Due Date</th>
                                <th class="uk-table-expand">Start Work</th>
                                <th class="uk-table-expand">Posted on PWA</th>
                                <th class="uk-table-expand">Vacancy's Title</th>
                                <th class="uk-table-expand">PIC Workers Request</th>
                                <th class="uk-table-expand">PIC WhatsApp</th>
                                <th class="uk-table-expand">Status Request</th>
                                <th class="uk-table-expand">Status Job Post</th>
                            </tr>
                        </thead>
                        <tbody v-if="initiations.docs.length > 0">
                            <tr v-for="(initiation, index) in initiations.docs" :key="index">
                                <td v-if="$can('READ', 'job-vacancy')" class="uk-text-nowrap uk-text-center">
                                    <button
                                        v-if="initiation.job_id !== null && $can('READ', 'job-vacancy')"
                                        class="uk-button uk-text-bold uk-border-rounded uk-width-1-1"
                                        style="border: 1px solid #0275D8; color: #0275D8; background: white;"
                                        @click="detailJobPost({ jobId: initiation.job_id._id })"
                                    >Details</button>
                                    <button
                                        v-else-if="initiation.job_id === null && $can('WRITE', 'job-vacancy')"
                                        class="uk-button uk-button-primary uk-text-bold uk-border-rounded uk-width-1-1"
                                        @click="createJobPost({ jobDetail: initiation })"
                                    >Create Job Post</button>
                                </td>
                                <td>{{ initiation.office ? initiation.office.name : '-' }}</td>
                                <td>{{ initiation.role ? initiation.role.name : '-' }}</td>
                                <td>{{ initiation.manpower || 0 }}</td>
                                <td>{{ initiation.fulfilled_manpower || 0 }}</td>
                                <td>{{ formatDate({ date: initiation.start_date }) }}</td>
                                <td>{{ formatDate({ date: initiation.due_date }) }}</td>
                                <td>{{ formatDate({ date: initiation.start_work }) }}</td>
                                <td>{{ initiation && initiation.job_id && initiation.job_id.created_at ? formatDate({ date: initiation.job_id.created_at }) : '-' }}</td>
                                <td :uk-tooltip="initiation.job_id !== null ? initiation.job_id.title : 'Vacancy hasn\'t created yet!'">
                                    {{ preview({ data: initiation.job_id !== null ? initiation.job_id.title : null }) }}
                                </td>
                                <td>{{ picList({ picList: initiation.pic }) }}</td>
                                <td>{{ initiation.admin_job_vacancy ? initiation.admin_job_vacancy : '-' }}</td>
                                <td>{{ initStatus({ status: initiation.status }) }}</td>
                                <td>{{ postStatus({ initiation }) }}</td>
                            </tr>
                        </tbody>
                        <empty-table v-else :colspan="12" />
                    </table>
                </div>
                <pagination
                    :total-data="initiations.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End job initiation list -->
        </div>

        <!-- Create job post modal box -->
        <div v-if="multiJobPost.isOpen">
            <div class="mainModal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close @click="hideMulltiJobPost" />
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">{{ multiJobPost.postType === 'multi' ? 'Create Multiple Job Posts' : 'Job Post Form' }}</h2>
                    </div>
                    <ul class="uk-child-width-expand" uk-tab>
                        <li :class="[multiJobPost.activeTab === 0 && 'active']">
                            <a
                                class="uk-text-bold"
                                :class="[multiJobPost.activeTab === 0 && 'uk-text-primary']"
                                href="#"
                                @click="multiJobPost.activeTab = 0"
                            >
                                Create Job Post
                            </a>
                        </li>
                        <li :class="[multiJobPost.activeTab === 1 && 'active']">
                            <a
                                class="uk-text-bold"
                                :class="[multiJobPost.activeTab === 1 && 'uk-text-primary']"
                                href="#"
                                @click="multiJobPost.activeTab = 1"
                            >
                                Additional Question(s)
                            </a>
                        </li>
                    </ul>
                    <div class="uk-modal-body uk-padding-remove-top">
                        <AddSingleJobPost
                            v-if="multiJobPost.activeTab === 0 && multiJobPost.postType === 'single'"
                            :jobTypeOptions="jobTypeOptions"
                            :contractOptions="contractOptions"
                            :ageFilter="ageFilter"
                            :genderOptions="genderOptions"
                            :educationLevelOptions="educationLevelOptions"
                            :docOptions="docOptions"
                            :additionalDocOptions="additionalDocOptions"
                            :jobPreferenceOptions="jobPreferenceOptions"
                            :testCategoryOptions="testCategoryOptions"
                            :adminJobVacancyOptions="adminJobVacancyOptions"
                            :formData="multiJobPost.formData"
                            :selectedData="selectedData"
                            :employer-company-id="employerCompanyId || ''"
                            @inValid="(multiJobPost.postIsValid = false)"
                            @valid="(multiJobPost.postIsValid = true)"
                        />
                        <AddMultiJobPost
                            v-else-if="multiJobPost.activeTab === 0 && multiJobPost.postType === 'multi'"
                            :jobTypeOptions="jobTypeOptions"
                            :contractOptions="contractOptions"
                            :ageFilter="ageFilter"
                            :genderOptions="genderOptions"
                            :educationLevelOptions="educationLevelOptions"
                            :docOptions="docOptions"
                            :additionalDocOptions="additionalDocOptions"
                            :jobPreferenceOptions="jobPreferenceOptions"
                            :testCategoryOptions="testCategoryOptions"
                            :adminJobVacancyOptions="adminJobVacancyOptions"
                            :formData="multiJobPost.formData"
                            :roleOptions="open_roles"
                            :company="initiations.docs.length > 0 ? initiations.docs[0].company : {}"
                            @inValid="(multiJobPost.postIsValid = false)"
                            @valid="(multiJobPost.postIsValid = true)"
                        />
                        <AddQuestion
                            v-else
                            :formData="multiJobPost.formData"
                            @inValid="(multiJobPost.questionisValid = false)"
                            @valid="(multiJobPost.questionisValid = true)"
                        />
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="multiJobPost.isLoading"
                            class="uk-button uk-button-default uk-flex uk-flex-middle"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button
                            v-else
                            class="uk-button"
                            :class="(multiJobPost.postIsValid && multiJobPost.questionisValid) ? 'uk-button-primary' : 'uk-button-default uk-text-lighter'"
                            :style="{ cursor: (multiJobPost.postIsValid && multiJobPost.questionisValid) ? 'pointer' : 'default' }"
                            :disabled="!multiJobPost.postIsValid || !multiJobPost.questionisValid"
                            @click="saveJobPost"
                        >Save</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End create job post modal box -->

        <Loader v-model="isUploadTemplate" />
        <UploadStatus v-if="summary.isShowStatus" :data="summary.data" :officeName="officeName" @hideModal="(summary = { isShowStatus: false, data: {} })" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
moment.locale('id');
import formatter from "@/utils/formatter";
import { 
    notificationSuccess, 
    notificationDanger, 
    notificationDangerCustom 
} from '@/utils/notification';
import { PREFIX_IMAGE } from "@/utils/constant";
import { excelDownloader } from '@/utils/helper';

export default {
    name: 'JobVacancyList',
    components: {
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Pagination: () => import('@/components/globals/Pagination'),
        AddMultiJobPost: () => import('./modals/AddMultiJobPost'),
        AddSingleJobPost: () => import('./modals/AddSingleJobPost'),
        AddQuestion: () => import('./modals/AddQuestion'),
        Loader: () => import('@/components/globals/Loader'),
        UploadStatus: () => import('./modals/UploadStatus'),
    },
    watch: {
        async meta() {
            await this.getInitiationList(this.meta);
        }
    },
    data() {
        return {
            isDownloadTemplate: false,
            isUploadTemplate: false,
            officeName: null,
            summary: {
                isShowStatus: false,
                data: {},
            },
            images: PREFIX_IMAGE,
            isLoading: false,
            multiJobPost: {
                isOpen: false,
                postType: 'single',
                activeTab: 0,
                postIsValid: false,
                questionisValid: true,
                isLoading: false,
                formData: {
                    id: null,
                    initiation_ids: [],
                    admin_job_vacancy_id: null,
                    role_id: '',
                    title: '',
                    job_description: '',
                    job_type_id: '',
                    benefits: null,
                    job_start: null,
                    working_hour_start: '',
                    working_hour_end: '',
                    gender: null,
                    age_group: null,
                    education_level_id: '',
                    skill_needed: '',
                    is_experience_needed: false,
                    required_tests: [],
                    required_docs: [{ label: 'KTP', value: 'ktp', $isDisabled: true }],
                    additional_docs: [],
                    job_contracts: [],
                    additional_questions: [],
                    question_usage: {
                        default: true,
                        auto_assign: false,
                        auto_assign_unreg: false,
                        migration: false,
                    },
                },
            },
            meta: {
                limit: Object.keys(this.$route.query).length === 0 ? 10 : this.$route.query.limit,
                page: Object.keys(this.$route.query).length === 0 ? 1 : this.$route.query.page,
                status: Object.keys(this.$route.query).length === 0 ? 0 : this.$route.query.status,
                company_office_id: Object.keys(this.$route.query).length === 0 ? '' : this.$route.query.company_office_id,
                role_id: Object.keys(this.$route.query).length === 0 ? '' : this.$route.query.role_id,
            },
            jobTypeOptions: [],
            educationLevelOptions: [],
            testCategoryOptions: [],
            jobPreference: [],
            contractOptions: [],
            jobPreferenceOptions: [],
            adminJobVacancyOptions: [],
            employerCompanyId: '',
            genderOptions: [
                { label: 'Pria', value: 'male' },
                { label: 'Wanita', value: 'female' },
                { label: 'Pria / Wanita', value: null },
            ],
            selectedData: {
                role: '',
                company: '',
                office: '',
            },
            ageFilter: {
                needed: false,
                min: 16,
                max: 61
            },
            docOptions: [
                { label: 'KTP', value: 'ktp', $isDisabled: true },
                { label: 'Ijazah', value: 'ijazah' },
                { label: 'SIM', value: 'sim' },
                { label: 'SKCK', value: 'skck' },
                { label: "KK", value: "kk" },
            ],
            additionalDocOptions: [],
            officeOptions: [],
            roleOptions: [],
            openRoles: [],
            metaJobPreferenceList: {
                limit: 10000,
                page: 1,
            },
            metaAdminJobVacancyList: {
                limit: 10000,
                page: 1
            }
        };
    },
    computed: {
        ...mapGetters({
            initiations: 'initiation/initiations',
            roles: 'option_data/roles',
            open_roles: 'option_data/open_roles',
            company_offices: 'company_office/company_offices',
            job_preferences: 'job_preference/job_preferences',
            admin_job_vacancies: 'admin_job_vacancy/admin_job_vacancies'
        })
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.getCompanyOffice();
            await this.getRoles();
            await this.filterJobInitiation();
            await this.getOpenRoles(),
            await this.getJobPreference(this.metaJobPreferenceList);
            await this.getAdminJobVacancy(this.metaAdminJobVacancyList);
            await Promise.all([
                this.setJobTypeData(),
                this.setEducationLevelData(),
                this.setTestCategoryData(),
                this.setContractOptionData(),
                this.getAdditionalDocOptionData(),
            ]);
            this.sortOfficeOptions();
            this.sortRoleOptions();
            this.isLoading = false;
            this.jobPreferenceOptions = this.job_preferences;
            this.adminJobVacancyOptions = this.admin_job_vacancies;
        } catch (error) {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getRoles: 'option_data/getRoles',
            getJobPreference: 'job_preference/getJobPreference',
            getOpenRoles: 'option_data/getOpenRoles',
            getInitiationList: 'initiation/getInitiationList',
            getSalarySetup: 'initiation/getSalarySetup',
            getJobTypes: 'option_data/getJobTypes',
            getEducationLevels: 'option_data/getEducationLevels',
            getTestCategories: 'option_data/getTestCategories',
            getSelectableContract: 'contract/getSelectableContract',
            postJob: 'initiation/postJob',
            postMultiJob: 'initiation/postMultiJob',
            GetTemplateFullMigration: 'initiation/GetTemplateFullMigration',
            PostFullMigration: 'initiation/PostFullMigration',
            getAdditionalDocument: 'initiation/getAdditionalDocument',
            getAdminJobVacancy: 'admin_job_vacancy/getAdminJobVacancy'
        }),
        hideMulltiJobPost(){
            window.UIkit.modal(".mainModal").$destroy(true);
            this.selectedData = {
                role: '',
                company: '',
                office: '',
            },
            this.multiJobPost = {
                isOpen: false,
                postType: 'single',
                activeTab: 0,
                postIsValid: false,
                questionisValid: true,
                isLoading: false,
                formData: {
                    id: null,
                    initiation_ids: [],
                    admin_job_vacancy_id: null,
                    role_id: '',
                    title: '',
                    job_description: '',
                    job_type_id: '',
                    benefits: null,
                    job_start: null,
                    working_hour_start: '',
                    working_hour_end: '',
                    gender: null,
                    age_group: null,
                    education_level_id: '',
                    skill_needed: '',
                    is_experience_needed: false,
                    required_tests: [],
                    required_docs: [{ label: 'KTP', value: 'ktp', $isDisabled: true }],
                    additional_docs: [],
                    job_contracts: [],
                    additional_questions: [],
                    question_usage: {
                        default: true,
                        auto_assign: false,
                        auto_assign_unreg: false,
                        migration: false,
                    },
                }
            };
        },
        async downloadTemplate() {
            if (this.isDownloadTemplate)
                return notificationDangerCustom('Another download is in progress, please wait.');

            this.isDownloadTemplate = true;

            try {
                const result = await this.GetTemplateFullMigration(this.meta.company_office_id);
                const office = this.officeOptions.find(d => d._id === this.meta.company_office_id);
                const reConstruct = result.map(d => ({
                    "Office": d.office_name, "Role": d.role_name,
                    "Role ID": d.role_id, "Job ID": d.job_id,
                    "Name*": "", "Email*": "",
                    "No Telp": "", "Password*": "",
                    "Start Date*": "", "End Date*": "",
                    "No KTP*": "", "Client Mitra ID": "", "Jenis Kelamin": "",
                    "Jumlah Anak": "", "Golongan Darah": "",
                    "Nama Bank": "", "Nama Rekening di Bank": "",
                    "Nomor Rekening": "", "Alamat": "",
                    "Tanggal Lahir": "", "BPJS Kesehatan": "",
                    "BPJS Ketenagakerjaan": "", "BPJS BPU (Bukan Penerima Upah)": "",
                }));
                excelDownloader(reConstruct, `Full Migration_${office.name}.xlsx`);
            } catch (error) {
                notificationDanger(error);
            }

            this.isDownloadTemplate = false;
        },
        async uploadTemplate(e) {
            this.isUploadTemplate = true;

            try {
                const office = this.officeOptions.find(d => d._id === this.meta.company_office_id);
                this.officeName = office.name;
                const filepath = e.target.files[0];
                let formData = new FormData();
                formData.append('file', filepath);
                const response = await this.PostFullMigration({ formData, office_id: this.meta.company_office_id });
                this.$refs.uploadTemplate.value = null;

                if(response && response.status === 'OK') {
                    this.summary.isShowStatus = true;
                    this.summary.data = response.data;
                } else {
                    notificationDangerCustom('Upload failed!');
                }
            } catch (error) {
                notificationDanger(error);
            }

            this.isUploadTemplate = false;
        },
        sortOfficeOptions() {
            for (const office of this.company_offices) {
                const findOffice = this.officeOptions.find((data) => data.name.toUpperCase() === office.name.toUpperCase());
                if(!findOffice) {
                    this.officeOptions.push(office);
                }
            }
            this.officeOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
        },
        sortRoleOptions() {
            for (const role of this.roles) {
                const findRole = this.roleOptions.find((data) => data.name.toUpperCase() === role.name.toUpperCase());
                if (!findRole) {
                    this.roleOptions.push(role);
                }
            }
            this.roleOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
        },
        async filterJobInitiation() {
            this.setQueryURL();
            await this.getInitiationList(this.meta);
        },
        async setJobTypeData() {
            this.jobTypeOptions = await this.getJobTypes();
        },
        async getAdditionalDocOptionData() {
            this.additionalDocOptions = await this.getAdditionalDocument();
        },
        async setEducationLevelData() {
            this.educationLevelOptions = await this.getEducationLevels();
        },
        async setTestCategoryData() {
            this.testCategoryOptions = await this.getTestCategories();
        },
        async setContractOptionData() {
            this.contractOptions = await this.getSelectableContract();
        },
        async setSalarySetupData({ company_office_id, role_id }) {
            const response = await this.getSalarySetup({ company_office_id, role_id });
            return response.result;
        },
        openMultiJobPost() {
            this.multiJobPost.postType = 'multi';
            this.multiJobPost.isOpen = true;
            setTimeout(() => (window.UIkit.modal('.mainModal').show()), 300);
        },
        async createJobPost({ jobDetail }) {
            const salarySetupData = await this.setSalarySetupData({ company_office_id: jobDetail.office._id, role_id: jobDetail.role._id });
            this.fillSelectedData({ jobDetail });
            this.fillFormData({ jobDetail, salarySetup: salarySetupData });
            this.multiJobPost.postType = 'single';
            this.multiJobPost.isOpen = true;
            setTimeout(() => (window.UIkit.modal('.mainModal').show()), 300);
        },
        fillFormData({ jobDetail, salarySetup }) {
            this.multiJobPost.postType = 'single';
            this.selectedData.role = jobDetail.role ? jobDetail.role.name : '';
            this.multiJobPost.formData.id = jobDetail._id;
            this.multiJobPost.formData.job_start = jobDetail.start_work;
            this.multiJobPost.formData.job_start = jobDetail.start_work;
            this.multiJobPost.formData.salary = salarySetup.fee_normal;
            this.multiJobPost.formData.salary_overtime_hourly = salarySetup.fee_overtime_hourly;
            this.multiJobPost.formData.salary_unit = this.feeUnitFormater({ text: salarySetup.fee_normal_unit });
        },
        fillSelectedData({ jobDetail }){
            this.selectedData.role = jobDetail.role ? jobDetail.role.name : '';
            this.selectedData.company = jobDetail.company ? jobDetail.company.name : '';
            this.selectedData.office = jobDetail.office ? jobDetail.office.name : '';
            this.employerCompanyId = jobDetail.company ? jobDetail.company.id : '';
        },
        async saveJobPost() {
            try {
                this.multiJobPost.isLoading = true;
                let apiEndPoint = "postJob";

                if (this.ageFilter.needed) {
                    this.multiJobPost.formData.age_group = `${this.ageFilter.min} - ${this.ageFilter.max}`;
                } else {
                    this.multiJobPost.formData.age_group = null;
                }

                if (this.multiJobPost.formData.working_hour_start == 'Invalid date' || this.multiJobPost.formData.working_hour_start == '') {
                    this.multiJobPost.formData.working_hour_start = null;
                } else {
                    this.multiJobPost.formData.working_hour_start = moment(this.multiJobPost.formData.working_hour_start).format('HH:mm');
                }
                if (this.multiJobPost.formData.working_hour_end == 'Invalid date' || this.multiJobPost.formData.working_hour_end == '') {
                    this.multiJobPost.formData.working_hour_end = null;
                } else {
                    this.multiJobPost.formData.working_hour_end = moment(this.multiJobPost.formData.working_hour_end).format('HH:mm');
                }

                const required_docs = this.multiJobPost.formData.required_docs.map((d) => d.value);
                const additional_docs = this.multiJobPost.formData.additional_docs.map((d) => d._id);
                const job_contracts = this.multiJobPost.formData.job_contracts.map(d => d._id);
                const required_tests = this.multiJobPost.formData.required_tests.map(d => ({
                    test_category_id: d._id,
                    minimum_score: d.minimum_score
                }));

                if (this.multiJobPost.formData.benefits === null || this.multiJobPost.formData.benefits === '') {
                    this.multiJobPost.formData.benefits = null;
                }

                this.multiJobPost.formData.job_preference_id = this.multiJobPost.formData.job_preference_id._id;
                
                if (this.multiJobPost.formData.admin_job_vacancy_id !== null) {
                    this.multiJobPost.formData.admin_job_vacancy_id = this.multiJobPost.formData.admin_job_vacancy_id._id;
                }

                // delete unused data
                if (this.multiJobPost.postType === 'single') {
                    delete this.multiJobPost.formData.initiation_ids;
                    delete this.multiJobPost.formData.role_id;
                } else {
                    apiEndPoint = "postMultiJob";
                    delete this.multiJobPost.formData.id;
                }

                const response = await this[apiEndPoint]({
                    ...this.multiJobPost.formData, required_docs, job_contracts, required_tests, additional_docs
                });

                this.multiJobPost.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Job saved!');
                    await this.getInitiationList(this.meta);
                    await this.getOpenRoles();
                    this.hideMulltiJobPost();
                } else {
                    notificationDanger('Failed to save job!');
                }
            } catch (error) {
                notificationDanger(error);
                this.multiJobPost.isLoading = false;
            }
        },
        setQueryURL() {
            this.$router.push('?' + Object.keys(this.meta).map(key => key + '=' + this.meta[key]).join('&'));
        },
        detailJobPost({ jobId }) {
            this.$router.push(`/admin/job_vacancy/detail/${jobId}`);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        feeUnitFormater({ text }) {
            switch (text) {
            case 'Month':
                return 'Bulan';
            case 'Day':
                return 'Hari';
            case 'Hour':
                return 'Jam';
            default:
                return '-';
            }
        },
        formatDate({ date }) {
            if (date === null || date === undefined) return '-';
            else return formatter.dateComplete(date);
        },
        preview({ data }) {
            if (data === null || data === undefined) return '-';
            else return data.substring(0,26)+'...';
        },
        picList({ picList }){
            if (picList === null || picList === undefined || picList.length < 1) return '-';
            else {
                let data = '';
                if (picList.length > 0) {
                    for (let i = 0; i < picList.length; i++) {
                        if (!picList[i+1]) {
                            data += picList[i].fullname;
                        } else {
                            data += picList[i].fullname + ', ';
                        }
                    }
                } else {
                    data = '-';
                }
                return data;
            }
        },
        initStatus({ status }){
            switch (status) {
            case 0:
                return 'In Progress';
            case 1:
                return 'Submitted';
            case 2:
                return 'Completed';
            default:
                return '-';
            }
        },
        postStatus({ initiation }){
            if (initiation === null || initiation === undefined) {
                return '-';
            } else {
                if (initiation.job_id === null) {
                    return 'Not Created';
                } else {
                    if (initiation.job_id.status !== 1) {
                        return 'Inactive';
                    } else {
                        return 'Live';
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
thead {
    background: #0ABAB5;
}

th {
    color: #FFFFFF;
}

td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
</style>
