<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <loader-page v-if="isLoading" />
        <div v-else>
            <div class="uk-flex uk-flex-middle uk-flex-center">
                <button
                    v-if="downloadButtonLoading"
                    type="button"
                    class="uk-button uk-button-default"
                    disabled
                >
                    <span uk-spinner="ratio: 0.5"></span>
                    <span class="uk-margin-small-left">Loading</span>
                </button>
                <button 
                    v-else
                    type="button" 
                    class="uk-button uk-button-primary" 
                    @click="downloadReport"
                >Download</button>
            </div>
            <div class="uk-card uk-card-default uk-margin" v-if="all_recaps != null">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-medium uk-table-divider">
                        <thead>
                            <tr>
                                <th class="uk-table-expand">Company</th>
                                <th class="uk-table-expand">Office Type</th>
                                <th class="uk-table-expand">Office Code</th>
                                <th class="uk-table-expand">Office Name</th>
                                <th class="uk-table-expand">City</th>
                                <th class="uk-table-expand">Address</th>
                                <th class="uk-table-expand">Geoloc</th>
                                <th class="uk-table-expand">Notes</th>
                                <th class="uk-table-auto uk-text-nowrap"># Workers</th>
                                <th class="uk-table-expand">Status</th>
                                <th class="uk-table-expand">Created At</th>
                            </tr>
                        </thead>
                        <tbody v-if="all_recaps.length>0">
                            <tr v-for="(recap, i) in all_recaps" :key="i">
                                <td>{{recap["Company"]}}</td>
                                <td>{{recap["Office Type"]}}</td>
                                <td>{{recap["Office Code"]}}</td>
                                <td>{{recap["Office Name"]}}</td>
                                <td>{{recap["City"]}}</td>
                                <td>{{recap["Address"]}}</td>
                                <td>{{recap["Geoloc"]}}</td>
                                <td>{{recap["Notes"]}}</td>
                                <td>{{recap["# Workers"]}}</td>
                                <td>{{recap["Status"]}}</td>
                                <td>{{recap["Created At"]}}</td>
                            </tr>
                        </tbody>
                        <empty-table v-else :colspan="11"/>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { excelDownloader } from '@/utils/helper';
import { notificationDanger } from '@/utils/notification';

export default {
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage')
    },
    data() {
        return {
            isLoading: false,
            downloadButtonLoading: false,
            all_recaps: null,
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.all_recaps = await this.getOfficeReport();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getOfficeReport: 'report/getOfficeReport',
        }),
        async downloadReport() {
            try {
                this.downloadButtonLoading = true;
                await excelDownloader(this.all_recaps, this.generateFileName());
            } catch (error) {   
                notificationDanger(error);
            } finally {
                this.downloadButtonLoading = false;
            }
        },
        generateFileName() {
            let filename = `Office Report.xls`;
            return filename;
        },
    },
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
</style>
