<template>
    <div class="uk-container uk-container-expand card-scrollable" style="padding: 20px;">
        <button @click="$router.push($store.state.routeBackFull);">
            <img class="uk-margin-small-right" :src="`${images}/arrow-back.svg`" alt="" />
        </button>
        <div class="uk-card uk-card-default uk-margin-top" style="border-radius: 10px; padding: 10px 0">
            <div class="uk-grid-collapse" uk-grid>
                <div class="uk-width-auto" style="border-right: 1px solid #E8E8E8;">
                    <div
                        class="sidemenu standard-padding"
                        :class="[menu.link === activeMenu && 'active-menu']"
                        v-for="menu in menus"
                        :key="menu.link"
                        @click="activeMenu = menu.link"
                    >
                        {{ menu.title }}
                    </div>
                </div>
                <div class="uk-width-expand">
                    <div class="standard-padding">
                        <Profile v-if="activeMenu === 'pro'" />
                        <IdentityDoc v-else-if="activeMenu === 'i_doc'" />
                        <FamilyDetail v-else-if="activeMenu === 'f_det'" />
                        <Equipment v-else-if="activeMenu === 'equ'" />
                        <Document v-else-if="activeMenu === 'doc'" />
                        <EmergencyContact v-else-if="activeMenu === 'e_con'" />
                        <JobDetail v-else-if="activeMenu === 'j_det'" />
                        <Education v-else-if="activeMenu === 'edu'" />
                        <Experience v-else-if="activeMenu === 'exp'" />
                        <TestResult v-else-if="activeMenu === 't_res'" />
                        <SalarySetiing v-else />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    components: { 
        Profile: () => import('@/components/pages/admin/workers/detail-worker/profile.vue'), 
        FamilyDetail: () => import('@/components/pages/admin/workers/detail-worker/family-detail.vue'), 
        IdentityDoc: () => import('@/components/pages/admin/workers/detail-worker/IdentityDoc.vue'), 
        Equipment: () => import('@/components/pages/admin/workers/detail-worker/equipment.vue'), 
        Document: () => import('@/components/pages/admin/workers/detail-worker/document.vue'), 
        EmergencyContact: () => import('@/components/pages/admin/workers/detail-worker/emergency-contact.vue'), 
        JobDetail: () => import('@/components/pages/admin/workers/detail-worker/job-detail.vue'), 
        Education: () => import('@/components/pages/admin/workers/detail-worker/education.vue'), 
        Experience: () => import('@/components/pages/admin/workers/detail-worker/experience.vue'), 
        TestResult: () => import('@/components/pages/admin/workers/detail-worker/test-result.vue'),
        SalarySetiing: () => import('@/components/pages/admin/workers/detail-worker/salary-setting.vue'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            activeMenu: 'pro',
            menus: [
                { link: 'pro', title: 'Profile' },
                { link: 'i_doc', title: 'Identity Document' },
                { link: 'f_det', title: 'Family Details' },
                { link: 'equ', title: 'Equipment' },
                { link: 'doc', title: 'Documents' },
                { link: 'e_con', title: 'Emergency Contact' },
                { link: 'j_det', title: 'Job Details' },
                { link: 'edu', title: 'Education' },
                { link: 'exp', title: 'Experience' },
                { link: 't_res', title: 'Test Results' },
                { link: 'sal_set', title: 'Salary Setting' },
            ]
        };
    },
};
</script>

<style scoped>
    .sidemenu {
        margin: .2rem 0;
        cursor: pointer;
    }
    .active-menu {
        background: #0ABAB5;
        color: #ffffff;
    }
    .standard-padding {
        padding: .5rem 1.5rem;
    }
</style>
