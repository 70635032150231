<template>
    <div class="uk-container uk-container-expand card-scrollable" style="padding: 20px;">
        <span class="uk-text-lead uk-text-bold uk-text-large">Company Dashboard Settings</span><br>
        <span class="uk-text-bold uk-text-default">Permissions</span>
        <div v-if="pageLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>

            <!-- Table -->
            <div class="uk-margin-medium-top">
                <div class="uk-card uk-card-default">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-middle uk-table-medium uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-text-nowrap uk-text-center">Role</th>
                                    <th class="uk-width-large">View</th>
                                    <th class="uk-width-large">Create</th>
                                    <th class="uk-width-large">Edit</th>
                                    <th class="uk-width-large">Delete</th>
                                    <th class="uk-width-large">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="roles && roles.length > 0">
                                <tr v-for="(rs, index) in roles" :key="index">
                                    <td class="uk-text-nowrap">{{ rs.name }}</td>
                                    <td>{{ trimer(rs.view.length > 0 ? rs.view.join(", "): null) }}</td>
                                    <td>{{ trimer(rs.write.length > 0 ? rs.write.join(", "): null) }}</td>
                                    <td>{{ trimer(rs.edit.length > 0 ? rs.edit.join(", "): null) }}</td>
                                    <td>{{ trimer(rs.deletes.length > 0 ? rs.deletes.join(", "): null) }}</td>
                                    <td class="uk-text-center">
                                        <button
                                            style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#0275D8"
                                            class="uk-button-primary"
                                            @click="goToDetail(rs.id)"
                                        >Edit</button>
                                    </td>
                                </tr>
                            </tbody>
                            <empty-table v-else :colspan="6"></empty-table>
                        </table>
                        <pagination
                            :total-data="pagination.totalDocs"
                            :change-limit="changeLimit"
                            :change-page="changePage"
                            :page-limit ="meta.limit"
                        />
                    </div>
                </div>
            </div>
            <!-- End table -->
        </div>
    </div>
</template>

<style scoped>
thead {
    background: #0ABAB5;
}
th {
    color: #FFFFFF;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
</style>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { propAbility } from '@/utils/acl';
import Pagination from '@/components/globals/Pagination';

export default {
    name: 'PageSettingAccess',
    data() {
        return {
            pageLoading: false,
            meta: {
                limit: 10,
                page: 1,
            },
            pagination:{
                totalDocs: 0,
            },
            roles:[],
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    watch: {
        async meta() {
            await this.getRoles(this.meta);
        },
    },
    async mounted() {
        try {
            this.pageLoading = true;
            await this.getRoles(this.meta);
            this.pageLoading = false;
        } catch (error) {
            this.pageLoading = false;
        }
    },
    methods:{
        ...mapActions({
            getRoleList: 'setting/getRoleList',
        }),
        async getRoles() {
            this.isLoading = true;
            await this.getRoleList(this.meta).then((result) => {
                this.roles = this.mapAbility(result.docs);
                this.pagination.totalDocs = result.totalDocs;
            });
            this.isLoading = false;
        },
        trimer(text){
            const length = 120;
            const trimmedString = text ? text.length > 120 ? text.substring(0, length)+"..." : text : "-";
            return trimmedString;
        },
        mapAbility(datas){
            const res = datas.map(item => {
                const view = [], write = [],  edit = [], deletes = [];
                item.permissions.forEach(pm => {
                    const abl = propAbility(pm.ability);
                    if(abl.READ){
                        view.push(pm.name);
                    }if(abl.WRITE){
                        write.push(pm.name);
                    }if(abl.EDIT){
                        edit.push(pm.name);
                    }if(abl.DELETE){
                        deletes.push(pm.name);
                    }
                });
                return {
                    id: item._id,
                    name: item.name,
                    view,
                    write,
                    edit,
                    deletes
                };
            });
            return res;
        },
        goToDetail(id){
            this.$router.push(`/settings/roles/${id}/edit`);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
    }
};
</script>
