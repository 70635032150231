import api from '@/utils/api';
import { SALARY_SETTINGS } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async createReimbursementSetup(context, data) {
        try {
            const response = await api.apiPostAuth(SALARY_SETTINGS.REIMBURSEMENT_LIST, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getReimbursementSetupList(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY_SETTINGS.REIMBURSEMENT_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDetailReimbursementSetup(context, data) {
        try {
            const { _id } = data;
            const response = await api.apiGetAuth(SALARY_SETTINGS.REIMBURSEMENT_DETAIL(_id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editReimbursementSetup(context, data) {
        try {
            const { _id, ...formData } = data;
            const response = await api.apiPatchAuth(SALARY_SETTINGS.REIMBURSEMENT_DETAIL(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteReimbursementSetup(context, data) {
        try {
            const { _id } = data;
            const response = await api.apiDeleteAuth(SALARY_SETTINGS.REIMBURSEMENT_DETAIL(_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

export default {
    namespaced,
    actions
};
