<template>
  <section>
    <div id="modal-center" class="uk-modal-container" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog" style="max-height: 800px; overflow-y: scroll;">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
        <div class="uk-modal-header uk-text-center">
          <h2 class="uk-modal-title">Lampiran</h2>
        </div>
        <div class="uk-modal-body">
          <pdf :src="src" :page="page" />
        </div>
        <div class="uk-modal-footer uk-text-right">
            <span style="float: left">Page {{ page }} of {{ numPages }}</span>
            <button
              class="uk-button uk-button-default uk-border-rounded uk-margin-right uk-text-primary"
              type="button"
              style="border-color: #0f7ae5;"
              :disabled="page <= 1"
              @click="page = page - 1"
            >
              Prev
            </button>
            <button
              class="uk-button uk-button-default uk-border-rounded uk-text-primary"
              type="button"
              style="border-color: #0f7ae5;"
              :disabled="page >= numPages"
              @click="page = page + 1"
            >
              Next
            </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import pdf from 'vue-pdf';

export default {
    components: { pdf },
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            src: pdf.createLoadingTask(this.$props.url),
            numPages: undefined,
            page: 1,
        };
    },
    mounted() {
        window.UIkit.modal('#modal-center').show();
        this.src.promise.then(pdf => {
            this.numPages = pdf.numPages;
        });
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#modal-center').$destroy(true);
            this.$emit("hideModal");
        },
        download(img) {
            window.location = img;
        },
    }
};
</script>
