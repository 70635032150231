<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s" uk-grid>
            <div v-if="getRole() !== 'supervisor'">
                <div v-if="company_office">
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                    </div>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer" @click="showModal('location-filter')">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> Select Location filter
                    </div>
                </div>
                <p v-if="company_office" class="reset-filter" @click="resetLocation">Reset Location Filter</p>
            </div>
            <div v-else>
              <div v-if="company_office">
                  <div class="location-header">
                      <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{company_office.city || 'n/a'}}
                  </div>
                  <div class="location-header">
                      <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{company_office.name || 'n/a'}}
                  </div>
              </div>
            </div>
            <div>
                <div class="location-header" style="cursor:pointer" @click="showModal('date-filter')">
                    <img :src="`${images}/calendar.png`" alt="" width="20"> {{flterDateText()}}
                </div>
                <p v-if="meta.from_date != null || meta.end_date != null || meta.monthly_filter != null || meta.year_filter != null" class="reset-filter" @click="resetDate">Reset Date Filter</p>
            </div>
            <div>
                <div style="overflow:auto">
                    <div
                        style="cursor:pointer;float:right"
                        @click="downloadRecap"
                        >
                        <img :src="`${images}/excel.png`" alt="" width="20">
                        Download Recap
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-inline">
            <select
                name="Updated By Filter"
                class="uk-select uk-margin-left"
                id="form-stacked-select"
                v-model="meta.updated_filter"
                @change="(e) => changeMeta(e, 'updated_filter')"
            >
            <option value="" disabled selected>Select Recap</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            </select>
        </div>
        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand uk-text-center">Hub</th>
                            <th class="uk-table-expand uk-text-center">Date</th>
                            <th class="uk-text-center">Assigned<br>Workers</th>
                            <th class="uk-text-center">Actual<br>Workers</th>
                            <th class="uk-text-center">Attendance<br>Rate (%)</th>
                        </tr>
                    </thead>
                    <tbody v-if="company_recaps.docs">
                        <tr v-for="(worker, i) in company_recaps.docs" :key="i">
                            <td class="uk-text-left uk-text-justify">{{worker.company_office.name || '-'}}</td>
                            <td class="uk-text-center">{{meta.updated_filter === 'daily' ? formatDate(worker.date) : worker.date}}</td>
                            <td class="uk-text-center">{{worker.total_worker}}</td>
                            <td class="uk-text-center">{{worker.actual_worker}}</td>
                            <td class="uk-text-center">{{formatNumber(worker.attendance_rate)}}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
            <pagination
                :total-data="company_recaps.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="date-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('date-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input Date Range for Which you Want to See Details</h2>
                </div>
                <div class="uk-modal-body">
                    <div v-if="meta.updated_filter === 'daily'">
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">From</label>
                            <div class="uk-form-controls">
                                <datepicker
                                    name="Start Date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    v-model="meta.from_date"
                                    :disabled-dates="disabled_start_date"
                                ></datepicker>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">To (optional)</label>
                            <div class="uk-form-controls">
                                <datepicker
                                    name="End Date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    v-model="meta.to_date"
                                ></datepicker>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Year</label>
                            <div class="uk-form-controls">
                               <select
                                name="Year Property"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="meta.year_filter"
                                >
                                    <option value="" disabled selected>Pick Year</option>
                                    <option v-for="(year, index) in years" :key="index" :value="year">{{year}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Month</label>
                            <div class="uk-form-controls">
                               <select
                                name="Month Property"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="meta.monthly_filter"
                                >
                                    <option value="" disabled selected>Pick Month</option>
                                    <option v-for="(month, index) in months" :key="index" :value="month.value">{{month.key}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setDate">Apply</button>
                </div>
            </div>
        </div>

        <div id="location-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('location-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input property for which you want to see details</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">City</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office City"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="" disabled selected>Choose City</option>
                                <option v-for="(city, index) in cities" :key="index" :value="city">{{city}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">Property</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office Property"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="company_office"
                            >
                                <option value="" disabled selected>Choose Property</option>
                                <option v-for="(office, index) in filtered_offices" :key="index" :value="office">{{office.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setLocation">Apply</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
// import LabelStatus from '@/components/globals/LabelStatus';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import Datepicker from 'vuejs-datepicker';
import {
    // notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';
import moment from 'moment';
moment.locale('id');

export default {
    data() {
        return {
            meta: {
                limit: 50,
                page: 1,
                from_date: null,
                to_date: null,
                company_office_id: null,
                worker_name: '',
                updated_filter: 'daily',
                year_filter: null,
                monthly_filter: null,
            },
            images: PREFIX_IMAGE,
            user_profile: '',
            company_office: '',
            disabled_start_date: {
                to: new Date('2020','07','01') // 1 August 2020
            },
            selected_city: '',
            cities: [],
            filtered_offices: [],
            years: [2019, 2020, 2021, 2022],
            months:[{key: "Januari", value: 1}, {key: "Februari", value: 2}, {key: "Maret", value: 3}, {key: "April", value: 4}, {key: "Mei", value: 5},
                {key: "Juni", value: 6}, {key: "Juli", value: 7}, {key: "Agustus", value: 8}, {key: "September", value: 9},
                {key: "Oktober", value: 10}, {key: "November", value: 11}, {key: "Desember", value: 12}]
        };
    },
    components: {
        // LabelStatus,
        EmptyTable,
        Pagination,
        Datepicker
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            company_recaps: 'company_recap/company_recaps',
        }),
    },
    watch: {
        meta() {
            this.getGrabWheelsScheduleRecap(this.meta);
        },
    },
    async mounted() {
        await this.getCompanyOffice();
        this.user_profile = await this.getProfile();
        this.company_office = this.$store.state.selected_company == '' ? this.user_profile.company_office : this.$store.state.selected_company;
        // this.selectCompanyOffice();
        this.meta.company_office_id = this.company_office == null ? null : this.company_office._id;
        this.getGrabWheelsScheduleRecap(this.meta);
        this.setCity();
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getProfile: 'auth/getProfile',
            getCompanyRecap: 'company_recap/getCompanyRecap',
            getGrabWheelsScheduleRecap: 'company_recap/getGrabWheelsScheduleRecap',
            getAllRecaps: 'mitra/getAllRecaps',
            getWeeklyReportByMonth: 'mitra/getWeeklyReportByMonth',
            getAllNonGroupedRecaps: 'mitra/getAllNonGroupedRecaps'
        }),
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        selectCompanyOffice(){
            this.company_office = this.company_offices.length > 0 ? this.company_offices[0] : 'n/a';
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatNumber(data) {
            if (data == null) {
                return '0%';
            }
            return formatter.absoluteNumber(data) + '%';
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').hide();
                break;
            case 'location-filter':
                await window.UIkit.modal('#location-filter').hide();
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'date-filter':
                await window.UIkit.modal('#date-filter').show();
                break;
            case 'location-filter':
                this.setCity();
                await window.UIkit.modal('#location-filter').show();
                break;
            }
        },
        async setDate() {
            if (this.meta.updated_filter == 'daily') {
                if (this.meta.from_date != null && this.meta.to_date != null) {
                    const from_date = new Date(this.meta.from_date);
                    const to_date = new Date(this.meta.to_date);
                    if (from_date > to_date) {
                        return notificationDangerCustom('End date must be greater than start date');
                    }
                } else if (this.meta.from_date == null) {
                    return notificationDangerCustom('Start date must be filled');
                }
            }
            await this.getGrabWheelsScheduleRecap(this.meta);
            this.hideModal('date-filter');

        },
        dateFilterAsText() {
            let date_format = '';
            if (this.meta.from_date) {
                date_format = date_format + this.formatDate(this.meta.from_date);
            }
            if (this.meta.to_date) {
                if (date_format !== '') {
                    date_format = date_format + '-';
                }
                date_format = date_format + this.formatDate(this.meta.to_date);
            }
            return date_format;
        },
        generateFileName() {
            let suffixName = "";
            const officeCity = this.company_office ? this.company_office.city : '';
            const officeCode = this.company_office ? this.company_office.code : '';
            if (this.dateFilterAsText() != '' && this.company_office == '') {
                suffixName = ` (${this.dateFilterAsText()})`;
            } else if (this.dateFilterAsText() == '' && this.company_office != '') {
                suffixName = ` ${officeCity} ${officeCode}`;
            } else if (this.dateFilterAsText() != '' && this.company_office != '') {
                suffixName = ` ${officeCity} ${officeCode} (${this.dateFilterAsText()})`;
            }

            const filename = `MyRobin-Shipper Mitra Shift Report${suffixName}.xls`;
            return filename;
        },
        generateFileNameRecap() {
            const filename = `MyRobin Recap Report ${this.meta.updated_filter}.xls`;
            return filename;
        },
        resetDate() {
            this.meta.from_date = null;
            this.meta.to_date = null;
            this.meta.monthly_filter = null;
            this.meta.year_filter = null;
            this.getGrabWheelsScheduleRecap(this.meta);
        },
        monthName(data) {
            if (data == 1) {
                return 'January';
            }
            if (data == 2) {
                return 'February';
            }
            if (data == 3) {
                return 'March';
            }
            if (data == 4) {
                return 'April';
            }
            if (data == 5) {
                return 'May';
            }
            if (data == 6) {
                return 'June';
            }
            if (data == 7) {
                return 'July';
            }
            if (data == 8) {
                return 'August';
            }
            if (data == 9) {
                return 'September';
            }
            if (data == 10) {
                return 'October';
            }
            if (data == 11) {
                return 'November';
            }
            if (data == 12) {
                return 'December';
            }
        },
        flterDateText() {
            let date_filter_text = '';
            if (this.meta.updated_filter == 'daily') {
                date_filter_text = this.dateFilterAsText();
                if (date_filter_text != '') {
                    return date_filter_text;
                } else {
                    return date_filter_text = 'Choose date range';
                }
            } else {
                if (!this.meta.monthly_filter && !this.meta.year_filter) {
                    return date_filter_text = 'Choose date range';
                } else {
                    return date_filter_text = `${this.monthName(this.meta.monthly_filter)} - ${this.meta.year_filter}`;
                }
            }
        },
        setCity() {
            this.cities = [...new Set(this.company_offices.map(data => data.city))];
            this.filtered_offices = [];
        },
        filterOffice() {
            this.filtered_offices = this.company_offices.filter(data => data.city === this.selected_city);
        },
        setLocation() {
            if (this.company_office == '') {
                return notificationDangerCustom('Please select one of property');
            }
            this.meta.company_office_id = this.company_office._id;
            this.getGrabWheelsScheduleRecap(this.meta);
            this.hideModal('location-filter');
            this.$store.state.selected_company = this.company_office;
        },
        resetLocation() {
            this.meta.company_office_id = null;
            this.company_office= '';
            this.selected_city= '';
            this.cities= [];
            this.filtered_offices= [];
            this.getGrabWheelsScheduleRecap(this.meta);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        async downloadRecap() {
            await this.getGrabWheelsScheduleRecap(this.meta);
            const all_recaps = await this.mapReport(this.company_recaps.docs);
            excelDownloader(all_recaps, this.generateFileNameRecap());
        },
        async mapReport(recaps) {

            const reports = recaps.map(elem => ({
                "Company Name": elem.company_office.name,
                "Date" : elem.date,
                "Total Worker" : elem.total_worker,
                "Actual_Worker" : elem.actual_worker,
                "Attendance_Aate" : elem.attendance_rate + '%',
            }
            ));
            return reports;
        },
        async downloadNewExcelDaily() {
            await this.getGrabWheelsScheduleRecap(this.meta);
            const all_recaps = await this.company_recaps;
            for (let i in all_recaps) {
                all_recaps[i].date = all_recaps[i].date !== '-' ? formatter.dateComplete(all_recaps[i].date) : '-';
                delete all_recaps[i].user_id;
            }

            excelDownloader(all_recaps, this.generateFileName());
        },
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
</style>
