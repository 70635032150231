<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template>
            <section class="uk-flex uk-flex-between uk-flex-middle">
                <!-- Filter job initiation list -->
                <div v-if="$can('READ', 'job-vacancy')" class="uk-grid-small uk-display-block" uk-grid>
                    <div
                        class="uk-flex uk-width-max-content myrobin-background-white"
                        style="padding: 8px; margin-left: 20px !important;"
                    >
                        <div>
                            <button
                                class="uk-button text-btn"
                                :class="isActiveStatus('') && 'btn-choose' || 'uk-button-default'"
                                style="padding: 8px 16px; color: #979797;"
                                @click="() => changeQuery({ key: 'status', value: '' })"
                            >
                                All Jobs
                            </button>
                        </div>
                        <div class="uk-width-auto" style="margin-left: 10px;">
                            <button
                                class="uk-button text-btn"
                                :class="isActiveStatus(STATUS.PROGRESS) && 'btn-choose' || 'uk-button-default'"
                                style="padding: 8px 16px; color: #979797;"
                                @click="() => changeQuery({ key: 'status', value: STATUS.PROGRESS })"
                            >
                                In Progress Jobs
                            </button>
                        </div>
                        <div class="uk-width-auto" style="margin-left: 10px">
                            <button
                                class="uk-button text-btn"
                                :class="isActiveStatus(STATUS.CLOSED) && 'btn-choose' || 'uk-button-default'"
                                style="padding: 8px 16px; color: #979797;"
                                @click="() => changeQuery({ key: 'status', value: STATUS.CLOSED })"
                            >
                                Closed Jobs
                            </button>
                        </div>
                        <div class="uk-width-auto" style="margin-left: 10px">
                            <button
                                class="uk-button text-btn"
                                :class="isActiveStatus(STATUS.NOT_CREATED) && 'btn-choose' || 'uk-button-default'"
                                style="padding: 8px 16px; color: #979797;"
                                @click="() => changeQuery({ key: 'status', value: STATUS.NOT_CREATED })"
                            >
                                Not Created Jobs
                            </button>
                        </div>
                    </div>

                    <div class="grid-cols-3 uk-width-1-1">
                        <multiselect
                            v-model="meta.city_id"
                            id="city"
                            name="city"
                            :options="this.cityOptions"
                            label="name"
                            track-by="_id"
                            placeholder="Select City"
                            style="width: 250px !important; font-size: 14px !important;"
                            class="uk-width-1-1"
                        >
                            <span slot="onResult">Data not found</span>
                        </multiselect>
                        <multiselect
                            v-model="meta.office_id"
                            id="office"
                            name="office"
                            :options="this.officeOptions"
                            label="name"
                            track-by="_id"
                            placeholder="Select Office"
                            style="width: 250px !important; font-size: 14px !important;"
                            class="uk-width-1-1"
                        >
                            <span slot="onResult">Data not found</span>
                        </multiselect>
                        <multiselect
                            v-model="meta.role_id"
                            id="role"
                            name="role"
                            :options="this.roleOptions"
                            label="name"
                            track-by="_id"
                            placeholder="Select Role"
                            style="width: 250px !important; font-size: 14px !important;"
                            class="uk-width-1-1"
                        >
                            <span slot="onResult">Data not found</span>
                        </multiselect>
                    </div>

                    <div class="uk-width-1-1 uk-flex">
                        <div class="uk-inline" style="width: 417px; margin-right: 16px;">
                            <span class="uk-form-icon" style="color: #000 !important;" uk-icon="icon: search"></span>
                            <input
                                v-model="meta.title"
                                class="uk-input uk-border-pill"
                                placeholder="Search vacancy name"
                            >
                        </div>
                        <div class="uk-inline">
                            <button
                                class="uk-button uk-border-pill"
                                :class="[isFiltered && 'bg-warning' || 'uk-button-default']"
                                style="padding: 0 15px; font-size: 16px;"
                                @click="showFilter"
                            >
                                <img
                                    alt="filter icon"
                                    src="/images/job_applicant/filter-black.svg"
                                    style="aspect-ratio: 1/1; max-width: 24px; height: 24px; margin-bottom: 4px;"
                                />
                                Filter Vacancies
                            </button>
                        </div>
                    </div>

                    <div
                        v-if="showMigrationAction"
                        class="uk-width-1-1 uk-flex"
                    >
                        <input ref="uploadTemplate" type="file" hidden @change="uploadFileMigration" />

                        <button
                            class="uk-button text-btn btn-outline-green uk-flex"
                            style="padding: 8px; margin-right: 16px; font-weight: 400; align-items: center;"
                            @click="downloadMigrationTemplate"
                        >
                            <img v-if="!isDownloadTemplate" :src="`${images}/shift-download.svg`" alt="shift download" width="25" uk-img class="photo">
                            <span v-else uk-spinner="ratio: 1"/>
                            <span style="margin-left: 4px;">Download Template</span>
                        </button>

                        <button
                            class="uk-button text-btn btn-choose uk-flex"
                            style="padding: 8px; margin-right: 16px; font-weight: 400; align-items: center;"
                            @click="() => this.$refs.uploadTemplate.click()"
                        >
                            <img :src="`${images}/shift-upload.svg`" alt="upload icon" width="25" uk-img class="photo">
                            <span style="margin-left: 4px;">Import Template</span>
                        </button>
                    </div>
                </div>
                <!-- End filter job initiation list -->

                <div
                    v-if="showCreateJobAction"
                    class="uk-display-block"
                >
                    <div class="uk-flex" style="margin-bottom: 15px; justify-content: end;">
                        <button
                            class="uk-button uk-button-primary uk-text-bold uk-border-rounded uk-text-nowrap"
                            @click="() => showJobForm({ form_type: 'multi', timeout: 300 })"
                        >
                            <img :data-src="`${images}/plus_multi.svg`" alt="icon add multi" uk-img class="photo">
                            Create Multiple Job Posts
                        </button>
                    </div>

                    <div>
                        <button
                            class="uk-button uk-text-bold uk-border-rounded uk-text-nowrap"
                            style="color: #FFF; background: #979797; pointer-events: none;"
                        >
                            <img :data-src="`${images}/plus_multi.svg`" alt="icon add multi" uk-img class="photo">
                            Blast Hot Jobs via WhatsApp
                        </button>
                    </div>
                </div>
            </section>

            <section class="uk-width-1-1 uk-flex" style="margin-top: 16px;">
                <hr class="uk-width-1-1" style="margin: auto 10px;" />
                <select
                    class="uk-select uk-border-pill text-f14-l21"
                    placeholder="Sort By ..."
                    style="width: 100%; max-width: 272px !important; padding: 0 15px;"
                    @change="(e) => changeQuery({ key: 'sort_by', value: e.target.value })"
                >
                    <option value="updated_desc" :selected="meta.sort_by === 'updated_desc'">Latest Updated on App</option>
                    <option value="updated_asc" :selected="meta.sort_by === 'updated_asc'">Oldest Updated on App</option>
                    <option value="posted_desc" :selected="meta.sort_by === 'posted_desc'">Latest Posted on App</option>
                    <option value="posted_asc" :selected="meta.sort_by === 'posted_asc'">Oldest Posted on App</option>
                    <option value="start_desc" :selected="meta.sort_by === 'start_desc'">Latest Start Date</option>
                    <option value="start_asc" :selected="meta.sort_by === 'start_asc'">Oldest Start Date</option>
                    <option value="title_asc" :selected="meta.sort_by === 'title_asc'">A-Z</option>
                    <option value="title_desc" :selected="meta.sort_by === 'title_desc'">Z-A</option>
                </select>
            </section>

            <section v-if="isActiveStatus(STATUS.PROGRESS)" class="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
                <div class="uk-flex uk-flex-left uk-flex-middle">
                    <p v-if="selectedInitiationIds.length > 0" class="uk-margin-remove">{{ selectedInitiationIds.length }} Job(s) selected</p>
                    <p v-else class="uk-margin-remove">No Job(s) selected</p>
                    <button 
                        type="button" 
                        class="uk-button uk-button-default uk-border-rounded uk-margin-left"
                        :class="[disabledSelectAllButton ? 'btn-disabled' : 'btn-select-all']"
                        :disabled="disabledSelectAllButton"
                        @click="selectAllInitiation"
                    >
                        <span class="uk-margin-small-right">Select All</span>
                        <img
                            v-if="disabledSelectAllButton"
                            :src="`${images}/select-all-white.svg`"
                            alt="Select all icon blue"
                            width="25"
                        >
                        <img
                            v-else
                            :src="`${images}/select-all-blue.svg`"
                            alt="Select all icon blue"
                            width="25"
                        >
                    </button>
                    <button 
                        type="button" 
                        class="uk-button uk-border-rounded uk-margin-left"
                        :class="[disabledCloseMultipleButton ? 'btn-disabled' : 'uk-button-danger']"
                        :disabled="disabledCloseMultipleButton"
                        @click="showCloseMultipleInitiation"
                    >
                        <img
                            alt="Close icon"
                            :src="`${images}/job_applicant/close-circle-white.svg`"
                            style="aspect-ratio: 1/1; max-width: 18px; height: 18px; margin-right: 8px; margin-top: -1px;"
                        >
                        <span>Close this Vacancy</span>
                    </button>
                </div>
                <div v-if="selectedInitiationIds.length > 0">
                    <button
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        :class="[disabledCloseMultipleButton ? 'btn-disabled' : 'btn-cancel-select-all']"
                        :disabled="disabledCloseMultipleButton"
                        @click="unselectAllInitiation"
                    >Cancel</button>
                </div>
            </section>

            <div
                v-if="$can('READ', 'job-vacancy') && !this.searchLoading && !this.isLoading"
                class="uk-card uk-card-default uk-margin-top space-y-4 bg-transparent uk-width-1-1 card-scrollable"
            >
                <job-vacancy-item
                    v-for="initiation in initiations.docs"
                    :key="initiation._id"
                    :job-id="initiation.job_id && initiation.job_id._id"
                    :job-initiation-id="initiation._id"
                    :job-title="(initiation.job_id && initiation.job_id.title) || (initiation.role && initiation.role.name) || '-'"
                    :job-description="initiation.job_id && initiation.job_id.job_description || initiation.description || '-'"
                    :job-role="initiation.role"
                    :job-office="initiation.office"
                    :job-posted-at="initiation.job_id && initiation.job_id.created_at"
                    :job-updated-at="initiation.job_id && initiation.job_id.updated_at"
                    :job-request-start="initiation.start_date"
                    :job-due-date="initiation.due_date"
                    :job-start-work="initiation.start_work"
                    :job-boosted-at="initiation.job_id && initiation.job_id.boosted_at"
                    :job-boosted-cooldown="initiation.job_id && initiation.job_id.boosted_cooldown"
                    :job-status="initiation.status"
                    :job-is-closed="initiation.status === 2"
                    :total-manpower="initiation.manpower"
                    :fulfilled-manpower="initiation.fulfilled_manpower"
                    :pic="initiation.pic"
                    :pic-whatsapp="initiation.admin_job_vacancy"
                    :show-checkbox="isActiveStatus(STATUS.PROGRESS)"
                    :selected-initiation="findSelectedInitiation({ _id: initiation._id })"
                    :disabled-boost-vacancy-button="disabledActionPerItemButton"
                    :disabled-close-vacancy-button="disabledActionPerItemButton"
                    :disabled-reopen-vacancy-button="disabledActionPerItemButton"
                    @boost="showBoost"
                    @close="showClose"
                    @reopen="showReopen"
                    @not-created="showNotCreated"
                    @select-initiation="selectInitiation"
                />
            </div>
            <template v-else-if="searchLoading || isLoading">
                <div class="uk-flex uk-width-1-1 uk-align-center uk-flex-center">
                    <span uk-spinner="ratio: 2.5"></span>
                </div>
            </template>

            <pagination
                :total-data="initiations.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
                :start-page="meta.page"
            />
        </template>

        <!--  modal components  -->
        <modal-filter-job-vacancy-v2
            v-if="modal.filter.mounted"
            :show="modal.filter.show"
            :pic-options="picOptions"
            @close="(timeout) => showFilter({ timeout })"
            @apply="submitFilter"
        />

        <modal-boost-job-vacancy-v2
            :initiation-id="modal.boost.show && modal.boost._id || null"
            :boosted-at="formatDate(todayISO)"
            @cancel="(timeout) => showBoost({ _id: null, timeout })"
            @success="reloadInitiationData"
        />

        <modal-close-job-vacancy-v2
            :initiation-id="modal.close.show && modal.close._id || null"
            :closed-at="formatDate(todayISO)"
            :pic-name="authName"
            @cancel="(timeout) => showClose({ _id: null, timeout })"
            @success="reloadInitiationData"
        />

        <modal-reopen-job-vacancy-v2
            :initiation-id="modal.reopen.show && modal.reopen._id || null"
            :closed-at="formatDate(todayISO)"
            @cancel="(timeout) => showReopen({ _id: null, timeout })"
            @success="showReopen"
        />

        <modal-reopen-confirm-job-vacancy-v2
            :initiation-id="modal.reopen_confirm.show && modal.reopen_confirm._id || null"
            :due-date="modal.reopen_confirm._id && modal.reopen_confirm.props.due_date || null"
            :start-work="modal.reopen_confirm._id && modal.reopen_confirm.props.start_work || null"
            :form-data="modal.reopen_confirm._id && modal.reopen_confirm.props.form_data || {}"
            :update-fn="modal.reopen_confirm.props.update_fn"
            @cancel="(timeout) => showReopenConfirm({ _id: null, timeout })"
            @success="reloadInitiationData"
        />

        <modal-not-created-warning-job-vacancy
            :initiation-id="modal.not_created.show && modal.not_created._id || null"
            @cancel="() => toggleModal('not_created')"
        />

        <modal-job-form-vacancy-v2
            :show="!!modal.form_job._id || modal.form_job.show"
            :form-type="modal.form_job.props.form_type"
            :due-date="modal.form_job.props.due_date"
            :start-date="modal.form_job.props.start_date"
            :start-work="modal.form_job.props.start_work"
            :selected-data="modal.form_job.props.selected_data"
            :selected-company="modal.form_job.props.selected_company"
            :job-id="modal.form_job.props.job_id"
            :is-edit="modal.form_job.props.is_edit"
            :initiation-detail="modal.form_job.props.initiation_detail"
            @cancel="(timeout) => showJobForm({ _id: null, timeout })"
            @confirm="showJobForm"
            @success="reloadInitiationData"
        />

        <Loader v-model="isUploadTemplate" />
        <UploadStatus
            v-if="migrationSummary.isShowStatus"
            :data="migrationSummary.data"
            :office-name="meta.office_id.name"
            @hideModal="(migrationSummary = { isShowStatus: false, data: {} })"
        />

        <ModalCloseMultiJobVacancyV2 
            :show="modal.close_multi.show"
            :closed-at="formatDate(todayISO)"
            :pic-name="authName"
            :submit-loading="closeMultipleInitiationJobLoading"
            @cancel="(timeout) => showCloseMultipleInitiation({ timeout })"
            @proceed-close-multiple-job="doCloseMultipleInitiationJob"
        />
        <!--  end modal components  -->
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import {mapActions, mapGetters} from 'vuex';
import {PREFIX_IMAGE} from '@/utils/constant';
import formatter from '@/utils/formatter';
import {notificationDanger} from '@/utils/notification';
import {excelDownloader} from '@/utils/helper';

const filterKey = [
    'is_start_date',
    'start_date',
    'is_due_date',
    'due_date',
    'is_updated_date',
    'updated_date',
    'is_posted_date',
    'posted_date',
    'is_pic_wa',
    'pic_wa',
    'is_pic',
    'pic'
];

export default {
    name: 'JobVacancyListV2',
    components: {
        ModalBoostJobVacancyV2: () => import('./modals/Boost'),
        ModalCloseJobVacancyV2: () => import('./modals/Close'),
        ModalFilterJobVacancyV2: () => import('./modals/Filter'),
        ModalJobFormVacancyV2: () => import('./modals/AddJob'),
        ModalNotCreatedWarningJobVacancy: () => import('./modals/NotCreatedWarning'),
        ModalReopenConfirmJobVacancyV2: () => import('./modals/ReopenConfirm'),
        ModalReopenJobVacancyV2: () => import('./modals/Reopen'),
        UploadStatus: () => import('./modals/UploadStatus'),
        JobVacancyItem: () => import('./Item'),
        Pagination: () => import('@/components/globals/Pagination'),
        Loader: () => import('@/components/globals/Loader'),
        Multiselect,
        ModalCloseMultiJobVacancyV2: () => import('./modals/CloseMultiJob')
    },
    data() {
        return {
            isLoading: false,
            images: PREFIX_IMAGE,
            meta: {
                status: null,
                page: 1,
                limit: 10,
                sort_by: null,
                title: null,
                city_id: null,
                office_id: null,
                role_id: null,
            },
            cityOptions: [],
            officeOptions: [],
            roleOptions: [],
            authName: '',
            filter: {},
            searchTimer: null,
            searchLoading: false,
            modal: {
                boost: { show: false, _id: null, name: '#boost-job-vacancy-v2' },
                close: { show: false, _id: null, name: '#close-job-vacancy-v2' },
                filter: { show: false, name: '#filter-job-vacancy-v2', mounted: false },
                reopen: { show: false, _id: null, name: '#reopen-job-vacancy-v2' },
                form_job: {
                    show: false,
                    _id: null,
                    name: '#form-job-vacancy-v2',
                    props: {
                        selected_data: {
                            role: '',
                            company: '',
                            office: '',
                        },
                        selected_company: null,
                        job_id: null,
                        initiation_detail: {},
                        form_type: 'single',
                        is_edit: false,
                        start_work: null,
                        due_date: null,
                    },
                },
                reopen_confirm: {
                    show: false,
                    _id: null,
                    name: '#reopen-confirm-job-vacancy-v2',
                    props: {
                        due_date: null,
                        start_work: null,
                        form_data: {},
                        update_fn: undefined,
                    },
                },
                not_created: { show: false, _id: null, name: '#not-created-warning-job-vacancy-v2' },
                close_multi: { show: false, name: '#close-multi-job-vacancy-v2' }
            },
            picOptions: [],
            mappedInitiations: null,
            isDownloadTemplate: false,
            isUploadTemplate: false,
            migrationSummary: {
                isShowStatus: false,
                data: [],
            },
            closeMultipleInitiationJobLoading: false,
            selectedInitiationIds: []
        };
    },
    computed: {
        ...mapGetters({
            initiations: 'initiation/initiations',
            adminJobVacancies: 'admin_job_vacancy/admin_job_vacancies',
            allInitiations: 'initiation/allInitiations'
        }),
        formatter() {
            return formatter;
        },
        STATUS() {
            return {
                PROGRESS: 'progress',
                CLOSED: 'closed',
                NOT_CREATED: 'not_created',
            };
        },
        isFiltered() {
            let exists = false;
            let i = 0;

            while (!exists && i < filterKey.length) {
                const exitsKey = filterKey[i];
                exists = exitsKey && (this.filter[exitsKey] || this.$route.query[exitsKey]) || false;
                i++;
            }

            return exists;
        },
        objectFilter() {
            const {city_id, office_id, role_id} = this.$route.query;
            return {city_id, office_id, role_id};
        },
        todayISO() {
            return new Date().toISOString();
        },
        showCreateJobAction() {
            return this.$can('WRITE', 'job-vacancy') && (
                this.isActiveStatus('') || this.isActiveStatus(this.STATUS.NOT_CREATED)
            );
        },
        showMigrationAction() {
            return this.$can('WRITE', 'job-vacancy') && this.meta.office_id &&
                this.meta.office_id._id && this.isActiveStatus(this.STATUS.PROGRESS);
        },
        disabledSelectAllButton() {
            return Boolean(this.initiations?.length < 1 || this.allInitiations?.length < 1);
        },
        disabledCloseMultipleButton() {
            return Boolean(this.selectedInitiationIds?.length < 1);
        },
        disabledActionPerItemButton() {
            return Boolean(this.selectedInitiationIds?.length > 0);
        }
    },
    watch: {
        async '$route.query'(value, oldValue) {
            try {
                const isTitleChange = value.title !== oldValue.title;

                if (value !== oldValue && !isTitleChange) {
                    this.isLoading = true;

                    this.resetInitiationData();
                    this.resetAllListInitiationDataV4();
                    this.resetMeta();
                    this.mapQuery();
                    this.mapFilter();

                    const {city_id, office_id, role_id} = value;
                    const payload = {
                        ...this.meta,
                        ...this.filter,
                        ...this.objectFilter,
                        city_id,
                        office_id,
                        role_id,
                    };

                    if (payload?.status === 'progress') {
                        await Promise.all([
                            this.getInitiations(payload),
                            this.getAllInitiations(payload)
                        ]);
                    } else {
                        this.selectedInitiationIds = [];
                        await this.getInitiations(payload);
                    }
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async 'meta.title'(value, oldValue) {
            try {
                const {title} = this.$route.query;

                if (value !== oldValue && title !== value) {
                    if (this.searchTimer) clearTimeout(this.searchTimer);

                    const page = 1;
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            page,
                            title: formatter.sanitizeString(value)
                        }
                    });

                    this.searchLoading = true;
                    this.searchTimer = setTimeout(async () => {
                        await Promise.all([
                            this.getInitiations({ page }),
                            this.getAllInitiations()
                        ]);
                        // await this.getInitiations({page})
                        //     .catch(notificationDanger)
                        //     .finally(() => this.searchLoading = false);
                    }, 1500);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.searchLoading = false;
            }
        },
        async 'meta.city_id'(newValue) {
            const value = newValue?._id || null;

            await this.changeQuery({ key: 'city_id', value });
        },
        async 'meta.office_id'(newValue) {
            const value = newValue?._id || null;

            await this.changeQuery({ key: 'office_id', value });
        },
        async 'meta.role_id'(newValue) {
            const value = newValue?._id || null;

            await this.changeQuery({ key: 'role_id', value });
        }
    },
    async mounted() {
        this.isLoading = true;
        try {
            this.resetInitiationData();
            this.resetAllListInitiationDataV4();
            this.resetMeta();
            this.mapQuery();
            this.mapFilter();

            const [profile, picData] = await Promise.all([
                this.getUserProfile(),
                this.getPIC({ limit: 1000 }),
                this.getFilterOptions(),
                this.getAdminPIC({ limit: 1000 })
            ]);

            if (this.$route.query?.status == 'progress') {
                await Promise.all([
                    this.getInitiations(),
                    this.getAllInitiations()
                ]);
            } else {
                await this.getInitiations();
            }

            this.mapObjectFilter();
            this.authName = profile.fullname;
            this.picOptions = picData.docs;
        } catch (e) {
            notificationDanger(e);
        } finally {
            this.modal.filter.mounted = true;
            this.isLoading = false;
        }
    },
    beforeDestroy() {
        this.resetMeta();
    },
    methods: {
        ...mapActions({
            getInitiationApi: 'initiation/getListInitiationJobsV4',
            getFilter: 'initiation/getFilterInitiationJobsV4',
            resetInitiationData: 'initiation/resetInitiationDataV4',
            getPIC: 'initiation/searchPICV4',
            getAdminPIC: 'admin_job_vacancy/searchAdminJobVacancy',
            getUserProfile: 'auth/getProfile',
            getTemplateFullMigration: 'initiation/GetTemplateFullMigration',
            postFullMigration: 'initiation/PostFullMigration',
            getAllListInitiationJobsV4: 'initiation/getAllListInitiationJobsV4',
            resetAllListInitiationDataV4: 'initiation/resetAllListInitiationDataV4',
            multipleUpdateStatusV4: 'initiation/multipleUpdateStatusV4'
        }),
        async getFilterOptions() {
            try {
                const result = await this.getFilter();
                this.cityOptions = result.cities;
                this.officeOptions = result.companyOffices;
                this.roleOptions = result.companyRoles;
            } catch (e) {
                notificationDanger(e);
            }
        },
        async getInitiations(newQuery = {}) {
            try {
                const payload = {
                    ...this.meta,
                    ...this.filter,
                    ...this.objectFilter,
                    ...newQuery,
                };

                await this.getInitiationApi(payload);
                this.mapInitiationsData();
            } catch (e) {
                notificationDanger(e);
            }
        },
        async downloadMigrationTemplate() {
            if (this.isDownloadTemplate) {
                throw new Error('Please wait, template is downloading');
            }

            try {
                this.isDownloadTemplate = true;
                const result = await this.getTemplateFullMigration(this.meta.office_id?._id);
                const {name} = this.meta.office_id || {};
                const reConstruct = result.map((item) => ({
                    "Office": item.office_name, "Role": item.role_name,
                    "Role ID": item.role_id, "Job ID": item.job_id,
                    "Name*": "", "Email*": "",
                    "No Telp": "", "Password*": "",
                    "Start Date*": "", "End Date*": "",
                    "No KTP*": "", "Client Mitra ID": "", "Jenis Kelamin": "",
                    "Jumlah Anak": "", "Golongan Darah": "",
                    "Nama Bank": "", "Nama Rekening di Bank": "",
                    "Nomor Rekening": "", "Alamat": "",
                    "Tanggal Lahir": "", "BPJS Kesehatan": "",
                    "BPJS Ketenagakerjaan": "", "BPJS BPU (Bukan Penerima Upah)": ""
                }));
                excelDownloader(reConstruct, `Full Migration_${name}.xlsx`);
            } catch (e) {
                notificationDanger(e);
            } finally {
                this.isDownloadTemplate = false;
            }
        },
        async uploadFileMigration(e) {
            try {
                this.isUploadTemplate = true;
                const filepath = e.target.files[0];
                let formData = new FormData();
                formData.append('file', filepath);
                const response = await this.postFullMigration({ formData, office_id: this.meta.office_id?._id });
                this.$refs.uploadTemplate.value = null;

                if (!response || (response && response.status !== 'OK')) {
                    return;
                }

                this.migrationSummary.isShowStatus = true;
                this.migrationSummary.data = response.data;
            } catch (e) {
                notificationDanger(e);
            } finally {
                this.isUploadTemplate = false;
            }
        },
        async reloadInitiationData() {
            try {
                this.isLoading = true;

                this.resetMeta();
                this.resetInitiationData();
                this.resetAllListInitiationDataV4();
                this.unselectAllInitiation();
                this.mapQuery();

                if (this.$route.query?.status == 'progress') {
                    await Promise.all([
                        this.getInitiations(),
                        this.getAllInitiations()
                    ]);
                } else {
                    await this.getInitiations();
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async changeQuery({ key, value }) {
            let query = { ...this.$route.query };
            query[key] = value;

            if (key !== 'page') {
                this.meta.page = 1;
                delete query.page;
            }

            if (key === 'status') {
                if (value === this.STATUS.NOT_CREATED) {
                    this.meta.sort_by = 'start_desc';
                    query.sort_by = 'start_desc';
                } else {
                    this.meta.sort_by = null;
                    delete query.sort_by;
                }
            }

            await this.$router.replace({ query });
        },
        async getAllInitiations(newQuery = {}) {
            const payload = {
                ...this.meta,
                ...this.filter,
                ...this.objectFilter,
                ...newQuery,
            };

            await this.getAllListInitiationJobsV4(payload);
        },
        async doCloseMultipleInitiationJob() {
            try {
                this.closeMultipleInitiationJobLoading = true;

                const payload = {
                    initiation_ids: this.selectedInitiationIds,
                    status: 2,
                    job_status: 4
                };

                const result = await this.multipleUpdateStatusV4(payload);
                if (result?.status == 'OK') {
                    this.modal.close_multi.show = false;
                    await this.reloadInitiationData();
                    window.UIkit.modal("#close-multi-job-vacancy-v2").$destroy(true);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.closeMultipleInitiationJobLoading = false;
            }
        },
        submitFilter(filter) {
            try {
                this.resetMeta();
                this.mapQuery();
                this.isLoading = true;

                this.filter = {...filter, change: true};
                this.mapFilter(this.$route.query);

                if (JSON.stringify(this.filter) !== JSON.stringify(this.$route.query)) {
                    this.$router.replace({query: this.filter});
                }
            } catch (e) {
                notificationDanger(e);
            } finally {
                this.isLoading = false;
                this.showFilter({ timeout: 0 });
            }
        },
        changeLimit(e) {
            this.changeQuery({ key: 'limit', value: e.target.value });
        },
        changePage(value) {
            this.changeQuery({ key: 'page', value });
        },
        isActiveStatus(status) {
            return (this.$route.query?.status || String(this.meta.status)) === status;
        },
        mapQuery() {
            this.meta.sort_by = this.$route.query?.sort_by || null;
            this.meta.page = parseInt(this.$route.query?.page || '1');
            this.meta.limit = parseInt(this.$route.query?.limit || '10');
            this.meta.title = this.$route.query?.title || null;

            this.meta.status = this.$route.query?.status ?? this.STATUS.PROGRESS;
        },
        mapObjectFilter() {
            const { city_id, office_id, role_id } = this.$route.query;
            const length = Math.max(this.cityOptions.length, this.officeOptions.length, this.roleOptions.length);

            for (let i = 0; i < length; i++) {
                if (this.cityOptions[i]?._id === city_id) {
                    this.meta.city_id = this.cityOptions[i];
                }
                if (this.officeOptions[i]?._id === office_id) {
                    this.meta.office_id = this.officeOptions[i];
                }
                if (this.roleOptions[i]?._id === role_id) {
                    this.meta.role_id = this.roleOptions[i];
                }
            }
        },
        mapFilter(queries = {}) {
            const isChange = this.filter.change;
            const newFilter = { ...queries };

            filterKey.forEach((key) => {
                if (!this.filter[key] && isChange) {
                    if (newFilter[key]) delete newFilter[key];

                    delete this.filter[key];
                    delete this.meta[key];
                } else {
                    const value = this.filter[key] || this.$route.query[key] || null;

                    if (value) {
                        this.filter[key] = String(value);
                        this.meta[key] = value;
                    }
                }
            });

            delete this.filter.change;

            this.filter = { ...newFilter, ...this.filter };
        },
        mapInitiationsData() {
            this.mappedInitiations = {};

            this.initiations.docs.forEach((initiation) => {
                this.mappedInitiations[initiation._id] = initiation;
            });
        },
        resetMeta() {
            this.filter = {};

            this.meta.status = this.STATUS.PROGRESS;
            this.meta.page = 1;
            this.meta.limit = 10;
            this.meta.sort_by = null;
            this.meta.title = null;
        },
        formatDate(date) {
            return date && formatter.dateCustomFormat({ date, format: 'MMMM DD, YYYY' });
        },
        toggleModal(modal_name) {
            const newValue = !this.modal[modal_name].show;
            this.modal[modal_name].show = newValue;

            if (newValue && this.modal[modal_name].name) {
                setTimeout(() => (window.UIkit.modal(this.modal[modal_name].name).show()), 300);
            }
        },
        showFilter({ timeout = 0 }) {
            setTimeout(() => this.toggleModal('filter'), timeout);
        },
        showBoost({ _id, timeout = 0 }) {
            this.modal.boost._id = _id;
            setTimeout(() => this.toggleModal('boost'), timeout);
        },
        showClose({ _id, timeout = 0 }) {
            this.modal.close._id = _id;
            setTimeout(() => this.toggleModal('close'), timeout);
        },
        showNotCreated({ _id = null, due_date, start_work, isClosed = true, timeout = 0 }) {
            if (isClosed) {
                this.modal.not_created._id = _id;
                setTimeout(() => this.toggleModal('not_created'), timeout);
            } else {
                this.showJobForm({ _id, due_date, start_work, timeout });
            }
        },
        showReopen({ _id = null, modal_name, next_id, start_work, due_date, is_edit, timeout = 0 }) {
            this.modal.reopen._id = _id;
            setTimeout(() => this.toggleModal('reopen'), timeout);

            const params = { _id: next_id, start_work, due_date, timeout: 100, is_edit };
            if (modal_name === 'reopen_confirm' && next_id) {
                this.showReopenConfirm(params);
            } else if (modal_name === 'form_job' && next_id) {
                this.showJobForm(params);
            }
        },
        showReopenConfirm({ _id = null, due_date = null, start_work = null, form_data = {}, update_fn, timeout = 0 }) {
            this.modal.reopen_confirm._id = _id;

            this.modal.reopen_confirm.props = {
                due_date,
                start_work,
                form_data,
                update_fn,
            };

            setTimeout(() => this.toggleModal('reopen_confirm'), timeout);
        },
        showJobForm({ _id = null, due_date = null, start_work = null, form_type = 'single', form_data, is_edit = false, next, timeout = 0 }) {
            const initiationId = `${_id || this.modal.form_job._id}`;
            this.modal.form_job._id = _id;

            const initiationDetail = _id && this.mappedInitiations[initiationId] || {};
            const nextId = form_data?.id ? (initiationId || initiationDetail?._id) : null;

            this.modal.form_job.props = {
                selected_data: {
                    role: initiationDetail.role?.name || '',
                    company: initiationDetail.company?.name || '',
                    office: initiationDetail.office?.name || '',
                },
                selected_company: initiationDetail.company || null,
                job_id: initiationDetail.job_id?._id,
                initiation_detail: initiationDetail,
                form_type,
                is_edit,
                start_work,
                due_date,
            };

            // if come from reopen
            setTimeout(() => {
                this.toggleModal('form_job');

                if (nextId) {
                    this.showReopenConfirm({
                        _id: nextId, due_date, start_work, form_data, update_fn: next
                    });
                }
            }, timeout);
        },
        selectInitiation({ _id = null }) {
            let selectedIds = [];
            const resultFindSameData = this.selectedInitiationIds.find((item) => item == _id);
            selectedIds = this.selectedInitiationIds.length > 0 ? resultFindSameData ? this.selectedInitiationIds.filter((item) => item != _id) : [...this.selectedInitiationIds, ...[_id]] : [_id];
            this.selectedInitiationIds = selectedIds;
        },
        findSelectedInitiation({ _id = null }) {
            return !!this.selectedInitiationIds.find((item) => item == _id);
        },
        unselectAllInitiation() {
            this.selectedInitiationIds = [];
        },
        selectAllInitiation() {
            if (this.allInitiations && this.allInitiations.length > 0) {
                for (const initiation of this.allInitiations) {
                    const findSameData = this.selectedInitiationIds.find((data) => data == initiation?._id);
                    if (!findSameData) {
                        this.selectedInitiationIds.push(initiation?._id);
                    }
                }
            }
        },
        showCloseMultipleInitiation({ timeout = 0 }) {
            setTimeout(() => this.toggleModal('close_multi'), timeout);
        }
    },
};
</script>

<style>
.btn-choose {
    background: #0abab5;
    border: 1px solid #0abab5;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    color: #FFFFFF !important;
    font-weight: 700;
}

.btn-outline-green {
    background: #FFFFFF;
    border: 1px solid #0abab5;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    color: #0abab5 !important;
    font-weight: 500;
}

.text-btn {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.2px;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    column-gap: 10px;
}

.bg-transparent {
    background: #F2F2F2 !important;
}

.space-y-4 > * + * {
    margin-top: 1rem;
}

.bg-warning {
    background: #FEBE10;
}

.btn-select-all {
    border: 1px solid #0275D8 !important;
    background-color: white !important;
    color: #0275D8 !important;
}

.btn-disabled {
    background: #C2C3C7 !important;
    color: white !important;
    cursor: not-allowed !important;
}

.btn-cancel-select-all {
    background: white !important;
    border: 1px solid #CC240F !important;
    color: #CC240F !important;
}
</style>
