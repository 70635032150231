<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin-small-bottom">
            <div class="uk-margin-auto-vertical uk-text-bold">Cash Advance Request</div>
            <button
                @click="$router.back()"
                >
                <img :src="`${images}/cross.svg`" alt="" width="25">
            </button>
        </div>
        <div v-if="data" class="uk-flex uk-flex-left">
            <div class="uk-width-auto">
                <table id="table-data">
                    <tbody>
                        <tr>
                            <td>Request ID</td>
                            <td>: {{ data._id }}</td>
                        </tr>
                        <tr>
                            <td>Employee Name</td>
                            <td>: {{ data.user.fullname }}</td>
                        </tr>
                        <tr>
                            <td>Request Date</td>
                            <td>: {{ moment(data.request_date).format('DD MMM YYYY') }}</td>
                        </tr>
                        <tr>
                            <td>Notes</td>
                            <td>: {{ data.request_note }}</td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td>: {{ formatCurrency(parseInt(data.request_amount)) }}</td>
                        </tr>
                        <tr>
                            <td>Aprroved By</td>
                            <td>: {{ data.decision_by || '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="uk-margin-xlarge-left uk-width-auto uk-flex uk-flex-column">
                <div><span class="uk-text-bold">Payment Information</span></div>
                <div>
                    <table id="table-data">
                        <tbody>
                            <tr>
                                <td>Bank Name</td>
                                <td>: {{ data.bank_info && data.bank_info.bank_name ? data.bank_info.bank_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Account Holder</td>
                                <td>: {{ data.bank_info && data.bank_info.bank_account_name ? data.bank_info.bank_account_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Account Number</td>
                                <td>: {{ data.bank_info && data.bank_info.bank_account_number ? data.bank_info.bank_account_number : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Card -->
        <div v-if="data" id="card-data" class="uk-margin uk-card uk-card-small uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
            <div class="uk-flex uk-flex-row uk-flex-between">
                <div class="uk-flex uk-flex-column">
                    <div class="uk-text-bold uk-margin-small-bottom">Request Notes</div>
                    <div class="uk-text-small uk-margin-small-bottom">{{ data.request_note }}</div>
                    <div class="uk-text-bold uk-margin-small-bottom">Available Amount</div>
                    <div class="uk-text-small">{{ formatCurrency(data.max_amount) }}</div>
                </div>
                <div class="uk-flex uk-flex-column">
                    <div class="uk-text-bold uk-margin-small-bottom">Requested Amount</div>
                    <div class="uk-text-small uk-margin-small-bottom">{{ formatCurrency(data.request_amount) }}</div>
                    <div class="uk-text-bold uk-margin-small-bottom">Approved Amount</div>
                    <div class="uk-text-small">{{ data.decision_amount ? formatCurrency(data.decision_amount) : '-' }}</div>
                </div>
                <div class="uk-flex uk-flex-column">
                    <div class="uk-text-bold uk-margin-small-bottom">Status</div>
                    <div class="uk-text-small uk-margin-small-bottom" :style="{ color: statusColor(data.status)}">{{ formatStatus(data.status) }}</div>
                    <div class="uk-text-bold uk-margin-small-bottom">Approval/Rejection Notes</div>
                    <div class="uk-text-small">{{ data.decision_note ? data.decision_note : '-' }}</div>
                </div>
                <div class="uk-flex uk-flex-row">
                    <template v-if="data.status == 1">
                        <div class="uk-margin-auto-vertical">
                            <button class="uk-button uk-button-primary uk-border-rounded" @click="showModal('approve')">Approve</button>
                        </div>
                        <div class="uk-margin-auto-vertical uk-margin-left">
                            <button class="uk-button uk-button-danger uk-border-rounded" @click="showModal('reject')">Reject</button>
                        </div>
                    </template>
                    <template v-if="data.status == 2">
                        <div class="uk-margin-auto-vertical">
                            <button class="uk-button uk-button-primary uk-border-rounded" @click="showModal('change-status')">Change Status</button>
                        </div>
                    </template>
                    <template v-if="data.status == 4 || data.status == 3">
                        <div class="uk-margin-auto-vertical">
                            <button id="disabled" class="uk-button uk-border-rounded">Change Status</button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- Card -->

        <!-- Modal box Change Status -->
        <div id="change-status" class="change-status" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('change-status')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Change Status</h2>
                </div>
                <div class="uk-modal-body">
                    Change Status to Transferred?
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="transfer">Save</button>
                </div>
            </div>
        </div>
        <!-- End modal box Change Status -->

        <!-- Modal box Approve -->
        <div id="approve" class="approve" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('approve')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Approve Cash Advance Request</h2>
                </div>
                <div v-if="data" class="uk-modal-body">
                    <div class="uk-width-auto uk-margin-bottom">
                        <table id="table-data">
                            <tbody>
                                <tr>
                                    <td>Request ID</td>
                                    <td>{{ data._id }}</td>
                                </tr>
                                <tr>
                                    <td>Available Amount</td>
                                    <td>{{ formatCurrency(data.max_amount) }}</td>
                                </tr>
                                <tr>
                                    <td>Requested Amount</td>
                                    <td>{{ formatCurrency(data.request_amount) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <form @submit.prevent>
                        <div class="uk-margin">
                            <label for="decision-amount">Please enter the approved amount</label><br>
                            <input id="decision-amount" type="number" class="uk-input" :max="data.max_amount" min="0" v-model="form.decision_amount">
                        </div>
                        <div class="uk-margin">
                            <label for="decision-note">Notes (optional)</label><br>
                            <input id="decision-note" type="text" class="uk-input" v-model="form.decision_note">
                        </div>
                    </form>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="approve">Save</button>
                </div>
            </div>
        </div>
        <!-- End modal box Approve -->

        <!-- Modal box Reject -->
        <div id="reject" class="reject" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('reject')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Reject Cash Advance Request</h2>
                </div>
                <div v-if="data" class="uk-modal-body">
                    <div class="uk-width-auto uk-margin-bottom">
                        <table id="table-data">
                            <tbody>
                                <tr>
                                    <td>Request ID</td>
                                    <td>{{ data._id }}</td>
                                </tr>
                                <tr>
                                    <td>Available Amount</td>
                                    <td>{{ formatCurrency(data.max_amount) }}</td>
                                </tr>
                                <tr>
                                    <td>Requested Amount</td>
                                    <td>{{ formatCurrency(data.request_amount) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <form @submit.prevent>
                        <div class="uk-margin">
                            <label for="decision-note">Rejection Notes</label><br>
                            <input id="decision-note" type="text" class="uk-input" v-model="form.decision_note">
                        </div>
                    </form>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="reject">Save</button>
                </div>
            </div>
        </div>
        <!-- End modal box Reject -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import moment from 'moment';
moment.locale('id');
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';

export default {
    data({ $route }) {
        return {
            moment: moment,
            meta: $route.query,
            data: null,
            images: PREFIX_IMAGE,
            form: {
                decision_amount: null,
                decision_note: null
            }
        };
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        async meta() {
            await this.getData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.change-status')) window.UIkit.modal('.change-status').$destroy(true);
        if(window.UIkit.modal('.approve')) window.UIkit.modal('.approve').$destroy(true);
        if(window.UIkit.modal('.reject')) window.UIkit.modal('.reject').$destroy(true);
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        ...mapActions({
            getCashAdvanceDetail: 'salary_adjustment/getCashAdvanceDetail',
            transferCashAdvance: 'salary_adjustment/transferCashAdvance',
            approveCashAdvance: 'salary_adjustment/approveCashAdvance',
            rejectCashAdvance: 'salary_adjustment/rejectCashAdvance',
        }),
        async getData() {
            this.data = await this.getCashAdvanceDetail(this.meta.salary_adjustment_id);
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        formatStatus(data) {
            switch (data) {
            case 1:
                return 'Requested';
            case 2:
                return 'Approved';
            case 3:
                return 'Rejected';
            case 4:
                return 'Transferred';
            }
        },
        statusColor(data) {
            switch (data) {
            case 1:
                return '#000000';
            case 2:
                return '#009688';
            case 3:
                return '#D8020F';
            case 4:
                return '#0275D8';
            }
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'change-status':
                await window.UIkit.modal('#change-status').hide();
                break;
            case 'approve':
                await window.UIkit.modal('#approve').hide();
                this.form.decision_amount = null;
                this.form.decision_note = null;
                break;
            case 'reject':
                await window.UIkit.modal('#reject').hide();
                this.form.decision_amount = null;
                this.form.decision_note = null;
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'change-status':
                await window.UIkit.modal('#change-status').show();
                break;
            case 'approve':
                await window.UIkit.modal('#approve').show();
                break;
            case 'reject':
                await window.UIkit.modal('#reject').show();
                break;
            }
        },
        async transfer() {
            const response = await this.transferCashAdvance(this.meta.salary_adjustment_id);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('change-status');
                await this.getData();
            } else {
                notificationDangerCustom('Failed to transfer!');
            }
        },
        async approve() {
            if (this.data.max_amount < this.form.decision_amount) {
                notificationDangerCustom('Approved amount must be less than available amount!');
            }
            const response = await this.approveCashAdvance({...this.form, _id: this.meta.salary_adjustment_id});
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('approve');
                await this.getData();
            } else {
                notificationDangerCustom('Failed to transfer!');
            }
        },
        async reject() {
            const response = await this.rejectCashAdvance({_id: this.meta.salary_adjustment_id, decision_note: this.form.decision_note});
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('reject');
                await this.getData();
            } else {
                notificationDangerCustom('Failed to transfer!');
            }
        }
    }
};
</script>

<style scoped>
#table-data td {
    padding: 8px;
    padding-left: 0px;
    font-size: 14px;
    font-weight: 400;
    border-style: hidden;
    border-collapse: collapse;
}
#table-data tr:nth-child(even){background-color: white;}
#card-data {
    color: black;
}
#disabled {
    background: #9E9E9E;
    color: white;
    cursor: not-allowed;
}
</style>
