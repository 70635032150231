<template>
    <section>
        <div id="upload-template-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="close"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Please select the type of template that you wish to upload</h2>
                </div>
                <div v-if="data_upload" class="uk-modal-body">
                    <div class="uk-flex uk-flex-middle uk-flex-center">
                        <div uk-spinner="ratio: 2"></div>
                    </div>
                </div>
                <div v-else class="uk-modal-body">
                    <div
                        v-for="(opt, id) in selection"
                        :key="id"
                        :value="opt.type"
                    >
                        <button
                            type="button"
                            class="uk-button uk-button-primary uk-width-1-1 uk-border-rounded uk-margin-small-bottom uk-text-left template-link"
                            @click="triggerUpload(opt.type)"
                        >
                            <img :src="`${images}/shift-upload.svg`" alt="" width="25" />
                            <span>Upload {{ opt.name }} template</span>
                            <input
                                :ref="opt.type"
                                type="file"
                                accept=".xls,.xlsx"
                                style="display: none"
                                @change="uploadFileMitraData($event, opt.type)"
                            />
                        </button>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left uk-modal-close" type="button" @click="close">Back</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import { notificationSuccess, notificationDanger, notificationDangerCustom } from "@/utils/notification";

export default {
    props: {
        selection: {
            type: Array,
            required: true,
        },
        source: {
            type: String,
            default: '',
            required: false,
        },
    },
    data(){
        return {
            images: PREFIX_IMAGE,
            data_upload: false,
        };
    },
    mounted() {
        window.UIkit.modal("#upload-template-modal").show();
    },

    methods: {
        ...mapActions({
            uploadMitraData: "worker/uploadMitraData",
        }),
        triggerUpload(ref) {
            this.$refs[ref][0].click();
        },
        async uploadFileMitraData(e, type) {
            this.data_upload = true;
            try {
                const filepath = e.target.files[0];
                let data = new FormData();
                data.append('type', type);
                data.append('excel', filepath);
                if (this.source) {
                    data.append('source', 'onboarding');
                }

                const response = await this.uploadMitraData(data);
                if (response && response.status === 'OK') {
                    data.delete('type');
                    data.delete('excel');
                    e.target.value = null;
                    notificationSuccess(`Upload worker data successful.`);
                } else {
                    data.delete('type');
                    data.delete('excel');
                    e.target.value = null;
                    notificationDangerCustom('Upload failed!');
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.data_upload = false;
        },
        close() {
            window.UIkit.modal('#upload-template-modal').$destroy(true);
            this.$emit('close');
        },
    }
};
</script>
