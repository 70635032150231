import Vue from 'vue';
import api from "@/utils/api";
import { notificationDanger } from "@/utils/notification";
import { OVERTIME_REPORT } from "../../utils/api-url";

const namespaced = true;
const state = {
    lists: { docs: []},
    list_detail: {docs: []}
};

const mutations = {
    "SET_LIST": (state, payload) => {
        Vue.set(state, 'lists', payload);
    },
    "SET_LIST_DETAIL_MITRA": (state, payload) => {
        Vue.set(state, 'list_detail', payload);
    }
};

const actions = {
    async getDashboardData(context, data) {
        try {
            const response = await api.apiGetAuth(OVERTIME_REPORT.DASHBOARD, data);
            return response.data.result;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getOfficeRoleOptions(context, data) {
        try {
            const response = await api.apiGetAuth(OVERTIME_REPORT.OFFICE_ROLE_OPTIONS, data);
            return response.data.result;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getOvertimeReportList(context, data) {
        try {
            const response = await api.apiGetAuth(OVERTIME_REPORT.LIST, data);
            context.commit('SET_LIST', response.data.result);
        } catch (error) {
            notificationDanger(error);
        }
    },
    async verifyReject(context, data) {
        try {
            const {...formData} = data;
            const response = await api.apiPatchAuth(OVERTIME_REPORT.VERIFY_REJECT, formData);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getDetailMitraReport(context, data) {
        try {
            const {job_partner_id, ...formData} = data;
            const response = await api.apiGetAuth(OVERTIME_REPORT.DETAIL_MITRA(job_partner_id), formData);
            context.commit('SET_LIST_DETAIL_MITRA', response.data.result);
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    lists: state => {
        return state.lists;
    },
    list_detail: state => {
        return state.list_detail;
    }
};

export default {
    namespaced,
    actions,
    state,
    mutations,
    getters
};
