<template>
    <div v-if="!isLoad" class="uk-background-cover uk-text-left">
        <header-general  />

        <div class="uk-grid-collapse" uk-grid>
            <sidebar-general />
            <div class="uk-width-expand@s">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderGeneral from '@/components/globals/headers/General';
import { mapActions } from 'vuex';
import SidebarGeneral from '@/components/globals/sidebars/General';
import { getUserLogin } from "@/utils/auth";
import { propAbility } from "@/utils/acl";
import { AbilityBuilder } from '@casl/ability';

export default {
    components: {
        HeaderGeneral,
        SidebarGeneral
    },
    data(){
        return {
            isLoad: true,
        };
    },
    async mounted() {
        // clear all filter from localstorage when refresh
        for (const key in localStorage) {
            if (/^\$filter /.test(key)) {
                localStorage.removeItem(key);
            }
        }

        await this.getMyRole();
        await this.getUserNotifCounter();
        this.isLoad = false;
    },
    methods:{
        ...mapActions({
            getRole: 'auth/getMyRole',
            getNotifCounter: 'notification/getNotifCounter'
        }),
        async getMyRole(){
            await this.getRole().then(res =>{
                this.mapRole(res);
            });
        },
        mapRole(datas){
            const { can, rules } = new AbilityBuilder();
            if (getUserLogin().roles.includes('qwe123') || getUserLogin().roles.includes('superadmin-tech') || getUserLogin().roles.includes('superadmin-product')) {
                can('manage','all');
            } else {
                datas.forEach(item => {
                    item.permissions.forEach(data => {
                        const abl = propAbility(data.ability);
                        Object.keys(abl).forEach(val => {
                            if (abl[val]) {
                                can(val, data.code);
                            }
                        });
                    });
                });
            }
            this.$ability.update(rules);
        },
        async getUserNotifCounter() {
            await this.getNotifCounter();
        }
    }
};
</script>
