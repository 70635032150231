<template>
    <div>
        <div class="uk-flex uk-flex-between myrobin-background-white uk-padding-small">
            <div class="uk-flex uk-flex-middle">
                <p class="uk-text-bold uk-margin-remove">Mitra Data Validation</p>
            </div>
            <div class="uk-flex uk-flex-middle" style="gap: 10px;">
                <button
                    v-if="validated.is_valid"
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('READ', 'worker-kyc')
                        ? 'myrobin-background-white myrobin-border-green myrobin-color-green'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('READ', 'worker-kyc'))"
                    @click="$emit('read-validation-notes')"
                >View Platinum Notes</button>
                <button
                    v-if="validated.is_valid"
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'myrobin-background-white myrobin-border-blue myrobin-color-blue'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                    @click="$emit('unlabel')"
                >Unlabel This Mitra as Platinum</button>
                <button
                    v-else
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'uk-button-primary myrobin-color-white'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                    @click="$emit('validate')"
                >Label This Mitra as Platinum</button>
                <button
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'myrobin-background-primary myrobin-color-white'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    @click="$emit('is-validating');"
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                >Validate</button>
            </div>
        </div>
        <div class="uk-width-1-1 uk-card uk-card-default uk-margin-top">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <template v-if="tableLoading">
                        <LoadingTable :colspan="2" />
                    </template>
                    <template v-else>
                        <tbody>
                            <tr :class="{ 'myrobin-background-pink' : !data.npwp }">
                                <td>NPWP Number</td>
                                <td>{{ data.npwp || '-' }}</td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.npwp_mitra || !data.npwp_mdm }">
                                <td>
                                    <div>NPWP Photo (from PWA)</div>
                                    <p class="uk-text-italic text-color-blue">NPWP Photo by MDM</p>
                                </td>
                                <td>
                                    <div v-if="data.npwp_mitra && data.npwp_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.npwp_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.npwp_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.npwp_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                    <p class="text-color-blue">{{ data.npwp_mdm || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.cv_mitra }">
                                <td>CV</td>
                                <td>
                                    <template v-if="data.cv_mitra && data.cv_mitra.file_url">
                                        <img
                                            class="uk-preserve-width uk-margin-small-right pointer-cursor"
                                            :src="`${images}/visibility.svg`"
                                            width="20"
                                            alt="View attachment icon"
                                            @click="previewFile({ file_url: data.cv_mitra.file_url })"
                                        >
                                        <img
                                            class="uk-preserve-width uk-margin-small-right pointer-cursor"
                                            :src="`${images}/cloud_download.svg`"
                                            width="20"
                                            alt="Download attachment icon"
                                            @click="downloadFile({ file_url: data.cv_mitra.file_url })"
                                        >
                                    </template>
                                    <template v-else>
                                        <img
                                            class="uk-preserve-width uk-margin-small-right"
                                            :src="`${images}/visibility.svg`"
                                            width="20"
                                            alt="View attachment icon"
                                        >
                                        <img
                                            class="uk-preserve-width uk-margin-small-right"
                                            :src="`${images}/cloud_download.svg`"
                                            width="20"
                                            alt="Download attachment icon"
                                        >
                                    </template>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.ijazah_mitra }">
                                <td>Ijazah</td>
                                <td>
                                    <div v-if="data.ijazah_mitra && data.ijazah_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.ijazah_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.ijazah_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.ijazah_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                </td>
                            </tr>
                            <tr v-for="(data1, id) in platinumDataset1" :key="`A${id}`" :class="{ 'myrobin-background-pink' : !data1.fill }">
                                <td class="uk-text-left uk-text-nowrap">{{ data1.field }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data1.fill || '-' }}</td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.sim_mitra || !data.sim_mdm }">
                                <td>
                                    <div>SIM Photo (from PWA)</div>
                                    <p class="uk-text-italic text-color-blue">SIM Photo by MDM</p>
                                </td>
                                <td>
                                    <div v-if="data.sim_mitra && data.sim_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.sim_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.sim_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.sim_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                    <p class="text-color-blue">{{ data.sim_mdm || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.stnk_mitra || !data.stnk_mdm }">
                                <td>
                                    <div>STNK Photo (from PWA)</div>
                                    <p class="uk-text-italic text-color-blue">STNK Photo by MDM</p>
                                </td>
                                <td>
                                    <div v-if="data.stnk_mitra && data.stnk_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.stnk_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.stnk_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.stnk_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                    <p class="text-color-blue">{{ data.stnk_mdm || '-' }}</p>
                                </td>
                            </tr>
                            <tr v-for="(data2, id) in platinumDataset2" :key="`B${id}`" :class="{ 'myrobin-background-pink' : !data2.fill }">
                                <td class="uk-text-left uk-text-nowrap">{{ data2.field }}</td>
                                <td class="uk-text-left uk-text-nowrap">
                                    <div v-if="data2.type == 'image'" class="uk-width-expand">
                                        <div v-if="data[data2.value] && data[data2.value].file_url" class="uk-flex uk-flex-middle">
                                            <img
                                                :src="data[data2.value].file_url"
                                                class="uk-margin-right"
                                                alt="User ktp photo"
                                                width="30px"
                                                @click="previewImage({ file_url: data[data2.value].file_url })">
                                            >
                                            <span class="uk-text-truncate myrobin-color-grey">{{ data[data2.value].file_path }}</span>
                                        </div>
                                        <p v-else>-</p>
                                    </div>
                                    <p v-else>{{ data2.fill || '-' }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>

                <p class="uk-text-bold">Additional Document (if any)</p>
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <template v-if="tableLoading">
                        <LoadingTable :colspan="2" />
                    </template>
                    <template v-else>
                        <tbody v-if="data.other_docs.length > 0">
                            <tr v-for="(doc, id) in data.other_docs" :key="`C${id}`">
                                <td class="uk-text-left uk-text-nowrap">{{ doc.name }}</td>
                                <td class="uk-flex uk-flex-middle">
                                    <div v-if="doc.document_url && doc.document_url.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="doc.document_url.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: doc.document_url.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ doc.document_url.file_path }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <EmptyTable v-else :colspan="2" empty-text="No entries found" />
                    </template>
                </table>
            </div>
        </div>

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
        <PreviewPDFModal
            v-if="modal.isShowPreviewPDFModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewPDFModal = false"
        />
        <PreviewOfficeModal
            v-if="modal.isShowPreviewOfficeModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewOfficeModal = false"
        />
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { contentType } from '@/utils/helper';

export default {
    name: 'PlatinumValidationTableView',
    components: {
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        PreviewImageModal: () => import('@/components/globals/modals/ImageViewer'),
        PreviewPDFModal: () => import('@/components/globals/modals/PdfViewer'),
        PreviewOfficeModal: () => import('@/components/globals/modals/OfficeViewer'),
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        validated: {
            type: Object,
            required: false,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            previewSrc: null,
            modal: {
                isShowPreviewImageModal: false,
                isShowPreviewPDFModal: false,
                isShowPreviewOfficeModal: false,
            },
        };
    },
    computed: {
        platinumDataset1() {
            return [
                {
                    field: 'Religion',
                    fill: this.data.religion
                },
                {
                    field: 'Gender',
                    fill: this.data.gender ? this.getGender(this.data.gender) : ''
                },
                {
                    field: "Mitra's Mother Name",
                    fill: this.data.mother_name
                },
                {
                    field: 'Blood Type',
                    fill: this.data.blood_type ? this.data.blood_type.toUpperCase() : ''
                },
                {
                    field: 'Number of Children',
                    fill: this.data.children_amount
                },
                {
                    field: 'SIM Number',
                    fill: this.data.sim_number
                },
            ];
        },
        platinumDataset2() {
            return [
                {
                    field: 'BPJS TK Number',
                    fill: this.data.bpjs_tk
                },
                {
                    field: 'BPJS Kes Number',
                    fill: this.data.bpjs_kes
                },
                {
                    field: 'Paklaring Photo from Prev. Company',
                    type: 'image',
                    fill: this.data.paklaring_mitra
                },
                {
                    field: 'PIC Prev Company Name',
                    fill: this.data.previous_company_name
                },
                {
                    field: 'Relation with PIC Prev Company ',
                    fill: this.data.previous_company_role
                },
                {
                    field: 'PIC Prev Company Number',
                    fill: this.data.previous_company_contact
                },
            ];
        },
    },
    methods: {
        getGender(val){
            let data = '';
            if (val === 'female') {
                data = 'Perempuan';
            } else if(val === 'male') {
                data = 'Laki-laki';
            }
            return data;
        },
        previewImage({ file_url }) {
            this.previewSrc = file_url;
            this.modal.isShowPreviewImageModal = true;
        },
        previewFile({ file_url }) {
            this.previewSrc = file_url;
            this.modal[contentType(this.previewSrc)] = true;
        },
        downloadFile({ file_url }) {
            window.location = file_url;
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
    color: white !important;
}
tbody, input {
    color: #000 !important;
}
.myrobin-color-white {
    color: #ffffff;
}
.myrobin-color-green {
    color: #0abab5;
}
.myrobin-color-blue {
    color: #0275d8;
}
</style>
