import api from '@/utils/api';
import { FILE_UPLOAD } from '@/utils/api-url';
import { notificationDanger } from '@/utils/notification';

const namespaced = true;

const actions = {
    async uploadAttachment(context, data) {
        try {
            const response = await api.apiPostAuth(FILE_UPLOAD.UPLOAD_ATTACHMENT(data.api), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions
};
