<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle min-height-100">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else class="uk-flex uk-flex-middle uk-flex-center">
            <!-- Report filter -->
            <div v-if="$can('READ', 'report-user-attendance')" class="uk-width-1-2 uk-card uk-card-default uk-padding">
                <h3>Report Filter</h3>
                <hr>
                <form class="uk-grid-small" uk-grid @submit.prevent="sendReport">
                    <div class="uk-width-1-1">
                        <label for="city" class="uk-form-label">City</label>
                        <multiselect
                            id="city"
                            name="city"
                            :options="cityOptions"
                            :multiple="true"
                            :close-on-select="false"
                            :disabled="disabledCityField"
                            label="name" 
                            track-by="name"
                            placeholder="Select city"
                            v-model="form.city"
                        >
                            <span slot="noResult">Data not found</span>
                        </multiselect>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="office" class="uk-form-label">Office</label>
                        <div class="uk-width-1-1 uk-position-relative">
                            <button 
                                type="button"
                                ref="showDropdownOfficeOptions"
                                class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between uk-border-rounded border-1-e8e8e8 padding-10" 
                                :class="[disabledOfficeField ? 'color-e8e8e8' : 'color-35495e']"
                                :disabled="disabledOfficeField"
                                @click="openDropdownOfficeOptions"
                            >
                                <span>{{ form.office.length > 0 ? `${form.office.length} office(s) selected` : 'Select office' }}</span>
                                <span>
                                    <img :src="`${images}/chevron-up-down.svg`" alt="Chevron up down icon">
                                </span>
                            </button>
                            <div 
                                v-if="showDropdownOfficeOptions" 
                                v-closable="{
                                    exclude: ['showDropdownOfficeOptions'],
                                    handler: 'closeDropdownOfficeOptions'
                                }"
                                class="uk-width-1-1 uk-position-absolute border-1-cccccc overflow-y-auto background-white z-1" 
                            >
                                <div class="height-100 max-height-191">
                                    <div class="uk-width-1-1 padding-5-10">
                                        <label>
                                            <input 
                                                class="uk-checkbox uk-border-rounded" 
                                                type="checkbox"
                                                :checked="selectAllOffice"
                                                @change="selectAllData({ event: $event, data: 'office' })"
                                            > All offices
                                        </label>
                                    </div>
                                    <div 
                                        v-for="(office, index) in officeOptions" 
                                        :key="index" 
                                        class="uk-width-1-1 padding-5-10"
                                    >
                                        <label>
                                            <input 
                                                class="uk-checkbox uk-border-rounded" 
                                                type="checkbox"
                                                :checked="form.office.includes(office._id)"
                                                @change="selectData({ id: office._id, data: 'office' })"
                                            > {{ office.name || '-' }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="role" class="uk-form-label">Role</label>
                        <div class="uk-width-1-1 uk-position-relative">
                            <button 
                                type="button"
                                ref="showDropdownRoleOptions"
                                class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between uk-border-rounded border-1-e8e8e8 padding-10" 
                                :class="[disabledRoleField ? 'color-e8e8e8' : 'color-35495e']"
                                :disabled="disabledRoleField"
                                @click="openDropdownRoleOptions"
                            >
                                <span>{{ form.role.length > 0 ? `${form.role.length} role(s) selected` : 'Select role' }}</span>
                                <span>
                                    <img :src="`${images}/chevron-up-down.svg`" alt="Chevron up down icon">
                                </span>
                            </button>
                            <div 
                                v-if="showDropdownRoleOptions" 
                                v-closable="{
                                    exclude: ['showDropdownRoleOptions'],
                                    handler: 'closeDropdownRoleOptions'
                                }"
                                class="uk-width-1-1 uk-position-absolute border-1-cccccc overflow-y-auto background-white z-1" 
                            >
                                <div class="height-100 max-height-191">
                                    <div class="uk-width-1-1 padding-5-10">
                                        <label>
                                            <input 
                                                class="uk-checkbox uk-border-rounded" 
                                                type="checkbox"
                                                :checked="selectAllRole"
                                                @change="selectAllData({ event: $event, data: 'role' })"
                                                > All roles
                                            </label>
                                    </div>
                                    <div 
                                        v-for="(role, index) in roleOptions" 
                                        :key="index" 
                                        class="uk-width-1-1 padding-5-10" 
                                    >
                                        <label>
                                            <input 
                                                class="uk-checkbox uk-border-rounded" 
                                                type="checkbox"
                                                :checked="form.role.includes(role._id)"
                                                @change="selectData({ id: role._id, data: 'role' })"
                                            > {{ role.name || '-' }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="user" class="uk-form-label">User</label>
                        <div class="uk-width-1-1 uk-position-relative">
                            <button 
                                type="button"
                                ref="showDropdownUserOptions"
                                class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between uk-border-rounded border-1-e8e8e8 padding-10" 
                                :class="[disabledUserField ? 'color-e8e8e8' : 'color-35495e']"
                                :disabled="disabledUserField"
                                @click="openDropdownUserOptions"
                            >
                                <span>{{ form.user_ids.length > 0 ? `${form.user_ids.length} user(s) selected` : 'Select user' }}</span>
                                <span>
                                    <img :src="`${images}/chevron-up-down.svg`" alt="Chevron up down icon">
                                </span>
                            </button>
                            <div 
                                v-if="showDropdownUserOptions" 
                                v-closable="{
                                    exclude: ['showDropdownUserOptions'],
                                    handler: 'closeDropdownUserOptions'
                                }"
                                class="uk-width-1-1 uk-position-absolute border-1-cccccc overflow-y-auto background-white z-1"  
                            >
                                <div class="height-100 max-height-191">
                                    <div class="uk-width-1-1 padding-5-10">
                                        <label>
                                            <input 
                                                class="uk-checkbox uk-border-rounded" 
                                                type="checkbox"
                                                :checked="form.user_ids.length === userOptions.length"
                                                @change="selectAllData({ event: $event, data: 'user_ids' })"
                                                > All users
                                            </label>
                                    </div>
                                    <div 
                                        v-for="(user, index) in userOptions" 
                                        :key="index" 
                                        class="uk-width-1-1 padding-5-10" 
                                    >
                                        <label>
                                            <input 
                                                class="uk-checkbox uk-border-rounded" 
                                                type="checkbox"
                                                :checked="form.user_ids.includes(user._id)"
                                                @change="selectData({ id: user._id, data: 'user_ids' })"
                                            > {{ user.fullname }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1">
                        <p class="uk-margin-remove uk-form-label">Date</p>
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-2">
                                <label for="dateFrom" class="uk-form-label">From</label>
                                <datetime
                                    id="dateFrom"
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    value-zone="Asia/Jakarta"
                                    zone="Asia/Jakarta"
                                    format="dd LLL yyyy"
                                    placeholder="Select date"
                                    :max-datetime="disabledMaxDateFrom"
                                    :disabled="disabledDateFormField"
                                    v-model="form.date_from"
                                ></datetime>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="dateTo" class="uk-form-label">To</label>
                                <datetime
                                    id="dateTo"
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    value-zone="Asia/Jakarta"
                                    zone="Asia/Jakarta"
                                    format="dd LLL yyyy"
                                    placeholder="Select date"
                                    :disabled="disabledDateToField"
                                    :min-datetime="disabledMinDateTo"
                                    :max-datetime="disabledMaxDateTo"
                                    v-model="form.date_to"
                                ></datetime>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1">
                        <label for="sendTo" class="uk-form-label">Send to</label>
                        <input 
                            id="sendTo" 
                            class="uk-input uk-border-rounded" 
                            type="email" 
                            placeholder="example@mail.com" 
                            :disabled="disabledSendToField"
                            v-model="form.send_to"
                        >
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="buttonSendLoading"
                            type="button"
                            class="uk-button uk-button-default"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button 
                            v-else
                            type="submit" 
                            class="uk-button"
                            :class="[disabledShareButton ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="disabledShareButton"
                        >Share</button>
                    </div>
                </form>
            </div>
            <!-- End report filter -->

            <!-- Handler when user can't access this page -->
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>You are not authorized for read data this page</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler when user can't access this page -->
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from "vuex";
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import { 
    notificationSuccess, 
    notificationDanger,
    notificationDangerCustom
} from "@/utils/notification";
import { PREFIX_IMAGE } from "@/utils/constant";

let handleOutsideClick;
Vue.directive('closable', {
    bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
        handleOutsideClick = (e) => {
            e.stopPropagation();
            // Get the handler method name and the exclude array
            // from the object used in v-closable
            const { handler, exclude } = binding.value;
            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                // We only run this code if we haven't detected
                // any excluded element yet
                if (!clickedOnExcludedEl) {
                    // Get the element using the reference name
                    const excludedEl = vnode.context.$refs[refName];
                    // See if this excluded element
                    // is the same element the user just clicked on
                    if (excludedEl) {
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                }
            });
            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the dialog
                // and not the button, then call the outside-click handler
                // from the same component this directive is used in
                vnode.context[handler]();
            }
        };
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },
    unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    },
});

export default {
    name: 'UserAttendance',
    components: {
        Multiselect,
        Datetime
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            dateTime: DateTime,
            isLoading: false,
            buttonSendLoading: false,
            showDropdownOfficeOptions: false,
            showDropdownRoleOptions: false,
            showDropdownUserOptions: false,
            cityOptions: [],
            officeOptions: [],
            roleOptions: [],
            userOptions: [],
            form: {
                city: [],
                office: [],
                role: [],
                user_ids: [],
                date_from: null,
                date_to: null,
                send_to: null
            }
        };
    },
    watch: {
        'form.city'(val) {
            this.form.office = [];
            this.form.role = [];
            this.form.user_ids = [];
            this.form.date_from = null;
            this.form.date_to = null;
            this.form.send_to = null;

            if (val && val.length > 0) {
                const cityIds = val.map((data) => data._id);
                this.setOfficeOptions({ domicileCityIds: cityIds });
            }
        },
        async 'form.office'(val) {
            try {
                this.form.role = [];
                this.form.user_ids = [];
                this.form.date_from = null;
                this.form.date_to = null;
                this.form.send_to = null;

                if (val && val.length > 0) {
                    await this.setRoleOptions({ officeIds: val });
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async 'form.role'() {
            this.form.user_ids = [];
            this.form.date_from = null;
            this.form.date_to = null;
            this.form.send_to = null;

            if (this.form.role.length > 0 && this.form.office.length > 0) {
                try {
                    await this.setUserOptions();
                } catch (error) {
                    notificationDanger(error);
                }
            }
        },
        'form.user_ids'() {
            this.form.date_from = null;
            this.form.date_to = null;
            this.form.send_to = null;
        },
        'form.date_from'(val) {
            this.form.date_to = null;
            this.form.send_to = null;

            if (val) {
                if (this.dateTime.fromISO(val).plus({ days: 30 }).toMillis() > this.dateTime.now().toMillis()) {
                    this.form.date_to = this.dateTime.now().toISODate();
                } else {
                    this.form.date_to = this.dateTime.fromISO(val).plus({ days: 30 }).toISODate();
                }
                this.form.date_from = this.dateTime.fromISO(new Date(val).toISOString()).toISODate();
            }
        },
        'form.date_to'() {
            this.form.send_to = null;
        }
    },
    computed: {
        ...mapGetters({
            company_offices: "company_office/company_offices",
            roles: "option_data/roles"
        }),
        disabledCityField() {
            let disabled = true;

            if (this.cityOptions.length > 0) {
                disabled = false;
            }

            return disabled;
        },
        disabledOfficeField() {
            let disabled = true;

            if (this.officeOptions.length > 0 && this.form.city.length > 0) {
                disabled = false;
            }

            return disabled;
        },
        disabledRoleField() {
            let disabled = true;

            if (
                this.roleOptions.length > 0 && 
                this.form.city.length > 0 && 
                this.form.office.length > 0
            ) {
                disabled = false;
            }

            return disabled;
        },
        disabledUserField() {
            let disabled = true;

            if (
                this.userOptions.length > 0 && 
                this.form.city.length > 0 && 
                this.form.office.length > 0 && 
                this.form.role.length > 0
            ) {
                disabled = false;
            }

            return disabled;
        },
        disabledDateFormField() {
            let disabled = true;

            if (
                this.form.city.length > 0 && 
                this.form.office.length > 0 && 
                this.form.role.length > 0 && 
                this.form.user_ids.length > 0
            ) {
                disabled = false;
            }

            return disabled;
        },
        disabledDateToField() {
            let disabled = true;

            if (
                this.form.city.length > 0 && 
                this.form.office.length > 0 && 
                this.form.role.length > 0 && 
                this.form.user_ids.length > 0 && 
                this.form.date_from
            ) {
                disabled = false;
            }

            return disabled;
        },
        disabledMaxDateFrom() {
            return this.dateTime.now().toISODate();
        },
        disabledMinDateTo() {
            if (this.form.date_from) {
                return this.dateTime.fromISO(new Date(this.form.date_from).toISOString()).plus({ days: 1 }).toISODate();
            } else {    
                return null;
            }
        },
        disabledMaxDateTo() {
            if (this.form.date_from) {
                if (this.dateTime.fromISO(new Date(this.form.date_from).toISOString()).plus({ days: 30 }).toMillis() > this.dateTime.now().toMillis()) {
                    return this.dateTime.now().toISODate();
                } else {
                    return this.dateTime.fromISO(new Date(this.form.date_from).toISOString()).plus({ days: 30 }).toISODate();
                }
            } else {
                return this.dateTime.now().toISODate();
            }
        },
        disabledSendToField() {
            let disabled = true;

            if (
                this.form.city.length > 0 && 
                this.form.office.length > 0 && 
                this.form.role.length > 0 && 
                this.form.user_ids.length > 0 && 
                this.form.date_from && 
                this.form.date_to
            ) {
                disabled = false;
            }

            return disabled;
        },
        disabledShareButton() {
            let disabled = true;

            if (
                this.form.city.length > 0 && 
                this.form.office.length > 0 && 
                this.form.role.length > 0 && 
                this.form.user_ids.length > 0 && 
                this.form.date_from && 
                this.form.date_to && 
                this.form.send_to
            ) {
                disabled = false;
            }

            return disabled;
        },
        selectAllOffice() {
            return this.officeOptions.every((element) => {
                if (element && element._id) {
                    return this.form.office.includes(element._id);
                }
            });
        },
        selectAllRole() {
            return this.roleOptions.every((element) => {
                if (element && element._id) {
                    return this.form.role.includes(element._id);
                }
            });
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.getCompanyOffice();
            this.setCityOptions();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: "company_office/getCompanyOffice",
            getRolesByOffice: "option_data/getRolesByOffice",
            getRoles: "option_data/getRoles",
            getWorkerListAll: "worker/getWorkerListAll",
            getUserAttendanceReport: 'report/getUserAttendanceReport'
        }),
        setCityOptions() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cityOptions.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cityOptions.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cityOptions.length > 0) {
                this.cityOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        setOfficeOptions({ domicileCityIds }) {
            if ((domicileCityIds && domicileCityIds.length > 0) && (this.company_offices && this.company_offices.length > 0)) {
                const offices = [];

                domicileCityIds.forEach((cityId) => {
                    this.company_offices.forEach((office) => {
                        if ((cityId) && (office && office.domicile_city_id && office.domicile_city_id._id) && (cityId === office.domicile_city_id._id)) {
                            offices.push(office);
                        }
                    });
                });

                this.officeOptions = offices;
            }

            if (this.officeOptions.length > 0) {
                this.officeOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        async setRoleOptions({ officeIds }) {
            try {
                if (officeIds && officeIds.length > 0) {
                    const roleOptions = [];

                    const [roleByOffice] = await Promise.all([
                        this.getRolesByOffice({ company_office_ids: officeIds }),
                        this.getRoles()
                    ]);

                    if ((roleByOffice && roleByOffice.length > 0) && (this.roles && this.roles.length > 0)) {
                        roleByOffice.forEach((roleId) => {
                            this.roles.forEach((role) => {
                                if ((roleId) && (role && role._id) && (roleId === role._id)) {
                                    roleOptions.push(role);
                                }
                            });
                        });

                    }

                    this.roleOptions = roleOptions;
                }

                if (this.roleOptions.length > 0) {
                    this.roleOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setUserOptions() {
            try {
                const query = {
                    company_office_id: this.form.office,
                    role_id: this.form.role
                };
                this.userOptions = await this.getWorkerListAll(query);

                if (this.userOptions.length > 0) {
                    this.userOptions.sort((a, b) => (a.fullname.toUpperCase() > b.fullname.toUpperCase()) ? 1 : ((b.fullname.toUpperCase() > a.fullname.toUpperCase()) ? -1 : 0));
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async sendReport() {
            try {
                this.buttonSendLoading = true;
                const payloads = {
                    start_date: this.form.date_from ? moment(this.form.date_from).add(7, 'hours').toISOString() : null,
                    end_date: this.form.date_to ? moment(this.form.date_to).add(7, 'hours').toISOString() : null,
                    company_office_ids: this.form.office,
                    role_ids: this.form.role,
                    user_ids: this.form.user_ids,
                    recipient_mail: this.form.send_to
                };

                const response = await this.getUserAttendanceReport(payloads);
                if (response && response.status === 'OK') {
                    this.resetForm();
                    this.resetOptionData();
                    this.closeAllDropdown();
                    notificationSuccess(`Report successfully sent to email ${payloads.recipient_mail}`);
                } else {
                    notificationDangerCustom('Failed to send report');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonSendLoading = false;
            }
        },
        resetForm() {
            this.form.city = [];
            this.form.office = [];
            this.form.role = [];
            this.form.user_ids = [];
            this.form.date_from = null;
            this.form.date_to = null;
            this.form.send_to = null;
        },
        resetOptionData() {
            this.cityOptions = [];
            this.officeOptions = [];
            this.roleOptions = [];
            this.userOptions = [];
        },
        closeAllDropdown() {
            this.showDropdownOfficeOptions = false;
            this.showDropdownRoleOptions = false;
            this.showDropdownUserOptions = false;
        },
        openDropdownOfficeOptions() {
            this.showDropdownOfficeOptions = !this.showDropdownOfficeOptions;
        },
        openDropdownRoleOptions() {
            this.showDropdownRoleOptions = !this.showDropdownRoleOptions;
        },
        openDropdownUserOptions() {
            this.showDropdownUserOptions = !this.showDropdownUserOptions;
        },
        closeDropdownOfficeOptions() {
            this.showDropdownOfficeOptions = false;
        },
        closeDropdownRoleOptions() {
            this.showDropdownRoleOptions = false;
        },
        closeDropdownUserOptions() {
            this.showDropdownUserOptions = false;
        },
        selectAllData({ event, data }) {
            let options = [];

            switch (data) {
            case 'city':
                options = this.cityOptions.map((data) => data._id);
                break;
            case 'office':
                options = this.officeOptions.map((data) => data._id);
                break;
            case 'role':
                options = this.roleOptions.map((data) => data._id);
                break;
            case 'user_ids':
                options = this.userOptions.map((data) => data._id);
                break;
            }

            const target = event.target;
            if (target.checked) {
                this.form[data] = JSON.parse(JSON.stringify(options));
            } else {
                this.form[data] = [];
            }
        },
        selectData({ id, data }) {
            let selectedIds = [];
            const findSameData = this.form[data].find((data) => data === id);
            selectedIds = this.form[data].length > 0 ? findSameData ? this.form[data].filter((data) => data !== id) : [...this.form[data], ...[id]] : [id];
            this.form[data] = selectedIds;
        }
    }
};
</script>

<style scoped>
.height-100 {
    height: 100%;
}

.max-height-191 {
    max-height: 191px;
}

.min-height-100 {
    min-height: 100%;
}

.padding-10 {
    padding: 10px;
}

.padding-5-10 {
    padding: 5px 10px;
}

.border-1-e8e8e8 {
    border: 1px solid #e8e8e8
}

.border-1-cccccc {
    border: 1px solid #cccccc;
}

.color-e8e8e8 {
    color: #e8e8e8;
}

.color-35495e {
    color: #35495e;
}

.background-white {
    background: white;
}

.overflow-y-auto {
    overflow-y: auto;
}

.z-1 {
    z-index: 1;
}
</style>
