<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <!-- Filter -->
            <div v-if="$can('READ', 'ex-mitra')" class="uk-grid-small" uk-grid>
                <div class="uk-width-expand">
                    <form  @submit.prevent="filterExWorker">
                        <input 
                            type="text"
                            class="uk-input uk-width-1-1"
                            placeholder="Ex-Mitra Name"
                            v-model="meta.fullname"
                        >
                    </form>
                </div>
                <div class="uk-width-expand">
                    <form @change="filterExWorker">
                        <select class="uk-select uk-width-1-1" v-model="meta.company_id">
                            <option :value="null" selected>Last Company</option>
                            <option
                                v-for="(company, index) in companies"
                                :key="index"
                                :value="company._id"
                            >{{ company.name }}</option>
                        </select>
                    </form>
                </div>
                <div class="uk-width-expand">
                    <form @change="filterExWorker">
                        <select class="uk-select uk-width-1-1" v-model="meta.reasons">
                            <option :value="null" selected>Reasons</option>
                            <option 
                                v-for="(reason, index) in reasons" 
                                :key="index" 
                                :value="reason.id"
                            >{{ reason.label }}</option>
                        </select>
                    </form>
                </div>
                <div class="uk-width-expand">
                    <button
                        class="uk-inline uk-button uk-button-default uk-width-1-1"
                        style="background-color: white;"
                        type="button"
                        @click="showFilterLastWorkingDateModal"
                    >
                        <span class="uk-form-icon" uk-icon="icon: calendar"></span>
                        <span>{{ filter.text }}</span>
                    </button>
                </div>
                <div class="uk-width-expand">
                    <div class="uk-width-1-1 uk-position-relative">
                        <input
                            ref="input"
                            type="text"
                            class="uk-input uk-text-small"
                            placeholder="Location"
                            autocomplete="off"
                            v-model="location"
                            @focus="onFocus"
                            @keydown.enter="enter"
                            @input="findCities"
                        >
                        <div
                            v-if="open"
                            v-closable="{
                                exclude: ['input'],
                                handler: 'onClose'
                            }"
                            class="uk-position-absolute uk-position-z-index uk-width-1-1"
                        >
                            <div
                                class="
                                    uk-card
                                    uk-card-default
                                    uk-card-body
                                    uk-padding-remove
                                    uk-text-left
                                    uk-flex
                                    uk-flex-column
                                    uk-height-max-medium
                                "
                                style="border-radius: 10px; overflow: auto"
                            >
                                <span
                                    v-if="locations.length === 0"
                                    class="location-item"
                                >
                                    Ketik Kota / Kabupaten
                                </span>
                                <button
                                    v-for="(location, index) in locations"
                                    :key="index"
                                    class="location-item"
                                    @click="onSelectItem(location)"
                                >{{ location.name || '-' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End filter -->

            <!-- Ex mitra list -->
            <div v-if="$can('READ', 'ex-mitra')" class="uk-margin-top">
                <div v-if="workers && workers.docs.length > 0" class="uk-grid-small uk-child-width-1-1 uk-padding-remove uk-margin-remove" uk-grid>
                    <ex-mitra-item
                        v-for="(worker, index) in workers.docs"
                        :key="index"
                        :photo-url="worker.photo_url ? worker.photo_url.file_url : null"
                        :fullname="worker.fullname"
                        :last-company="worker.company_name"
                        :last-office="worker.office_name"
                        :last-position="worker.role_name"
                        :last-working-date="worker.deleted_at"
                        :reasons="worker.reasons"
                        :notes="worker.notes"
                        :duration-as-mitra="worker.duration_as_mitra"
                        :location="worker.location"
                        @re-active="showConfirmationModal({ workerId: worker._id })"
                        @auto-assign="showAutoAssignModal({ userId: worker.user_id })"
                        @cv-details="goToCvDetails({ workerId: worker._id })"
                    ></ex-mitra-item>
                </div>
                <div v-else>
                    <p class="uk-margin-remove">No Data Found!</p>
                </div>
                <div v-if="!filterLoading && workers && workers.totalDocs" class="uk-margin-top">
                    <pagination
                        :total-data="workers.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
            </div>
            <!-- End ex mitra list -->

            <!-- Filter last working date modal -->
            <div v-if="$can('READ', 'ex-mitra')" id="last-working-date-modal" class="uk-flex-top last-working-date-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-text-lead uk-text-center">Select Range Date To Filter</h2>
                    </div>
                    <form class="uk-flex uk-flex-column" style="gap: 10px;" @submit.prevent="filterExWorkerByLastWorkingDate">
                        <section style="padding: 20px 20px 0 20px;">
                            <label class="uk-form-label" for="start_date">From</label>
                            <datetime
                                id="start_date"
                                name="start_date"
                                input-class="uk-input"
                                placeholder="dd/mm/yyy"
                                type='date'
                                v-model="filter.start_date"
                                :max-datetime="datetime.now().toISODate()"
                                value-zone="Asia/Jakarta"
                                zone="Asia/Jakarta"
                            ></datetime>
                        </section>
                        <section style="padding: 0 20px 20px 20px;">
                            <label class="uk-form-label" for="end_date">To</label>
                            <datetime
                                id="end_date"
                                name="end_date"
                                input-class="uk-input"
                                placeholder="dd/mm/yyy"
                                type='date'
                                v-model="filter.end_date"
                                :min-datetime="datetime.fromISO(filter.start_date).plus({days: 1}).toISODate()"
                                :max-datetime="datetime.now().toISODate()"
                                value-zone="Asia/Jakarta"
                                zone="Asia/Jakarta"
                            ></datetime>
                        </section>
                        <hr class="uk-margin-remove">
                        <section class="uk-text-right" style="padding: 20px;">
                            <button
                                type="button"
                                class="uk-button uk-button-default"
                                style="border-radius: 5px;"
                                @click="resetFilterLastWorkingDate"
                                :disabled="disableButtonFilterLastWorkingDate"
                            >
                                Reset
                            </button>
                            <button
                                type="submit"
                                class="uk-button uk-margin-small-left"
                                :class="[disableButtonFilterLastWorkingDate ? 'uk-button-default' : 'uk-button-primary']"
                                style="border-radius: 5px;"
                                :disabled="disableButtonFilterLastWorkingDate"
                            >Apply</button>
                        </section>
                    </form>
                </div>
            </div>
            <!-- End filter last working date modal -->

            <!-- Auto assign modal -->
            <div v-if="$can('EDIT', 'ex-mitra')" id="auto-assign-modal" class="uk-flex-top auto-assign-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-text-lead uk-text-center">Set Auto Assign</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-modal-body">
                        <div class="uk-flex uk-flex-middle uk-flex-center uk-padding-large">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <template v-else>
                        <form @submit.prevent="autoAssignWorker">
                            <div class="uk-padding uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="jobPost" class="uk-form-modal">Assign to</label>
                                    <select id="jobPost" class="uk-select uk-border-rounded" v-model="formAutoAssignModal.job_id">
                                        <option :value="null" selected>Select Job Post</option>
                                        <option
                                            v-for="(job, index) in jobList"
                                            :key="index"
                                            :value="job._id"
                                        >{{ job.title }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button 
                                    v-if="autoAssignButtonLoading"
                                    type="button"
                                    class="uk-button uk-button-default uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else 
                                    type="submit"
                                    class="uk-button uk-border-rounded"
                                    :class="[!formAutoAssignModal.job_id ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="!formAutoAssignModal.job_id"
                                >Send</button>
                            </div>
                        </form>
                    </template>
                </div>
            </div>
            <!-- End auto assign modal -->

            <!-- Confirmation modal -->
            <div v-if="$can('EDIT', 'ex-mitra')" id="confirmation-modal" class="uk-flex-top confirmation-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-text-lead uk-text-center">Confirmation</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>Are you sure want to proceed?</p>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button 
                            v-if="reactiveButtonLoading"
                            type="button"
                            class="uk-button uk-button-default uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <template v-else>
                            <button 
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded"
                                style="border: 1px solid #0275D8; color: #0275D8;"
                                @click="hideConfirmationModal"
                            >No</button>
                            <button 
                                type="button"
                                class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left"
                                @click="doReactiveWorker"
                            >Yes</button>
                        </template>
                    </div>
                </div>
            </div>
            <!-- End confirmation modal -->

            <!-- If not permitted -->
            <div v-else class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
            <!-- End if not permitted -->
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from "vuex";
import { 
    notificationSuccess, 
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';
import Pagination from "@/components/globals/Pagination";
import formatter from "@/utils/formatter";
import { DateTime } from 'luxon';
import { Datetime } from 'vue-datetime';

let handleOutsideClick;
Vue.directive('closable', {
    bind(el, binding, vnode) {
        handleOutsideClick = (e) => {
            e.stopPropagation();
            const { handler, exclude } = binding.value;
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName];
                    clickedOnExcludedEl = excludedEl.contains(e.target);
                }
            });
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        };
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },
    unbind() {
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    },
});

export default {
    name: 'ExMitra',
    components: {
        ExMitraItem: () => import('@/components/globals/ExMitra/ExMitraItem.vue'),
        Pagination,
        Datetime
    },
    data() {
        return {
            isLoading: false,
            datetime: DateTime,
            workers: null,
            companies: null,
            reasons: [
                {
                    id: 0,
                    label: 'Habis Kontrak'
                },
                {
                    id: 1,
                    label: 'Sakit'
                },
                {
                    id: 2,
                    label: 'Selesai Event'
                },
                {
                    id: 3,
                    label: 'Blacklist - Tak Tertoleransi'
                },
                {
                    id: 4,
                    label: 'Blacklist - Tertoleransi'
                },
                {
                    id: 5,
                    label: 'Lainnya'
                }
            ],
            filter: {
                text: 'Last Working Date',
                start_date: null,
                end_date: null
            },
            meta: {
                fullname: null,
                company_id: null,
                reasons: null,
                domicile_id: null,
                start_date: null,
                end_date: null,
                limit: 10,
                page: 1,
            },
            filterLoading: false,
            open: false,
            location: '',
            locations: [],
            modalLoading: false,
            formAutoAssignModal: {
                job_id: null
            },
            selectedWorker: {
                _id: null,
                user_id: null
            },
            jobList: [],
            autoAssignButtonLoading: false,
            reactiveButtonLoading: false
        };
    },
    watch: {
        async meta() {
            this.workers = await this.getExWorkerList(this.meta); 
        }
    },
    computed: {
        ...mapGetters({
            company_offices: "company_office/company_offices",
        }),
        disableButtonFilterLastWorkingDate() {
            return !this.filter.start_date || !this.filter.end_date;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.last-working-date-modal')) window.UIkit.modal('.last-working-date-modal').$destroy(true);
        if (window.UIkit.modal('.auto-assign-modal')) window.UIkit.modal('.auto-assign-modal').$destroy(true);
        if (window.UIkit.modal('.confirmation-modal')) window.UIkit.modal('.confirmation-modal').$destroy(true);
    },
    async mounted() {
        this.isLoading = true;
        try {
            this.companies = await this.getCompanies();
            this.workers = await this.getExWorkerList(this.meta);  
        } catch (error) {
            notificationDanger(error);
        }
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            getExWorkerList: "worker/getExWorkerList",
            getCities: 'option_data/getCities',
            getCompanies: 'option_data/getCompanies',
            getJobList: 'initiation/getJobList',
            inviteToJob: 'candidate/inviteToJob',
            reactiveExMitra: 'worker/reactiveExMitra'
        }),
        async filterExWorker() {
            this.filterLoading = true;
            try {
                this.resetMetaPageAndLimit();
                if (this.meta.fullname) this.meta.fullname = formatter.sanitizeString(this.meta.fullname);
                this.workers = await this.getExWorkerList(this.meta);
            } catch (error) {
                notificationDanger(error);
            }
            this.filterLoading = false;
        },
        async filterExWorkerByLastWorkingDate() {
            try {
                if (this.filter.start_date != null && this.filter.end_date != null) {
                    this.meta.start_date = this.filter.start_date;
                    this.meta.end_date = this.filter.end_date;
                    const start_date = new Date(this.meta.start_date);
                    const end_date = new Date(this.meta.end_date);
                    if (start_date > end_date) {
                        notificationDangerCustom('End date must be greater than start date');
                    }
                } else if (this.filter.start_date == null) {
                    notificationDangerCustom('Start date must be filled');
                }
                await window.UIkit.modal("#last-working-date-modal").hide();
                this.filter.text = `${this.datetime.fromISO(this.filter.start_date).toLocaleString()} - ${this.datetime.fromISO(this.filter.end_date).toLocaleString()}`;
                await this.filterExWorker();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showFilterLastWorkingDateModal() {
            try {
                await window.UIkit.modal("#last-working-date-modal").show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async resetFilterLastWorkingDate() {
            try {
                this.filter.text = 'Last Working Date';
                this.filter.start_date = null;
                this.filter.end_date = null;
                this.meta.start_date = null;
                this.meta.end_date = null;
                await window.UIkit.modal("#last-working-date-modal").hide();
                await this.filterExWorker();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationModal({ workerId }) {
            try {
                await window.UIkit.modal("#confirmation-modal").show();
                this.selectedWorker._id = workerId;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideConfirmationModal() {
            try {
                await window.UIkit.modal("#confirmation-modal").hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doReactiveWorker() {
            this.reactiveButtonLoading = true;
            try {
                const payload = { id: this.selectedWorker._id };
                const response = await this.reactiveExMitra(payload);
                if (response && response.status === 'OK') {
                    await window.UIkit.modal("#confirmation-modal").hide();
                    notificationSuccess('Workers have been successfully reactivated');
                    this.selectedWorker._id = null;
                    await this.filterExWorker();
                } else {
                    notificationDangerCustom('Failed to reactivate worker');
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.reactiveButtonLoading = false;
        },
        async showAutoAssignModal({ userId }) {
            this.modalLoading = true;
            try {
                await window.UIkit.modal("#auto-assign-modal").show();
                this.selectedWorker.user_id = userId;
                this.jobList = await this.getJobList({ auto_assign: 1 });
            } catch (error) {
                notificationDanger(error);
            }
            this.modalLoading = false;
        },
        goToCvDetails({ workerId }) {
            this.$router.push(`/admin/ex-mitra/${workerId}`);
        },
        async autoAssignWorker() {
            this.autoAssignButtonLoading = true;
            try {
                const response = await this.inviteToJob({ user_id: this.selectedWorker.user_id, job_id: this.formAutoAssignModal.job_id });
                if (response && response.status === 'OK') {
                    await window.UIkit.modal("#auto-assign-modal").hide();
                    this.formAutoAssignModal.job_id = null;
                    this.selectedWorker.user_id = null;
                    if (response.message === 'Invited') {
                        notificationSuccess(response.message.toString());
                    } else {
                        notificationDangerCustom(response.message.toString());
                    }
                } else {
                    notificationDangerCustom('Failed to invite worker!');
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.autoAssignButtonLoading = false;
        },
        resetMetaPageAndLimit() {
            this.meta.page = 1;
            this.meta.limit = 10;
        },
        async findCities() {
            try {
                const params = { name: this.location };
                this.locations = await this.getCities(params);
            } catch (error) {
                notificationDanger(error);
            }
        },
        onFocus() {
            this.open = true;
        },
        async enter() {
            try {
                this.location = '';
                this.meta.domicile_id = '';
                this.open = true;
                await this.filterExWorker();
            } catch (error) {
                notificationDanger(error);
            }   
        },
        onChange() {
            if (this.open === false) {
                this.open = true;
            }
        },
        onClose() {
            this.location = '';
            this.meta.domicile_id = '';
            this.open = false;
        },
        async onSelectItem(location) {
            try {
                this.location = location.name;
                this.meta.domicile_id = location._id;
                await this.filterExWorker();
                this.open = false;
            } catch (error) {
                notificationDanger(error);
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value,
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value,
            };
        },
    }
};
</script>

<style scoped>
    .location-item {
        cursor: pointer;
        padding: 10px;
        font-size: 12px;
        text-align: left;
    }

    .location-item:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
</style>
