import Vue from 'vue';
import api from '@/utils/api';
import { MITRA, DAILY_SHIFT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    recaps: {docs:[]},
};

const mutations = {
    "SET_RECAP": (state, payload) => {
        Vue.set(state, 'recaps', payload);
    }
};

const actions = {
    async uploadAttendanceVerification(context, data) {
        try {
            const response = await api.apiPostAuth(MITRA.UPLOAD_ATTENDANCE_VERIFICATION, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateSingleLock(context, data) {
        try {
            const response = await api.apiPutAuth(MITRA.UPDATE_SINGLE_MITRA_ATTENDANCE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateMultipleLock(context, data) {
        try {
            const response = await api.apiPutAuth(MITRA.UPDATE_MULTIPLE_MITRA_ATTENDANCE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRecapsAttendance(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.ALL_RECAP_ATTENDANCE, data);
            context.commit('SET_RECAP', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRecaps(context, data) {
        try {
            const response =  await api.apiGetAuth(MITRA.ALL_RECAP_ATTENDANCE_DETAIL, data);
            context.commit('SET_RECAP', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRecapsDetailReport(context, data) {
        try {
            const response =  await api.apiGetAuth(MITRA.ALL_RECAP_ATTENDANCE_DETAIL, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRecapsReport(context, data) {
        try {
            const response =  await api.apiGetAuth(MITRA.ALL_RECAP_ATTENDANCE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRecapsV2(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.ALL_RECAP_PAGINATE_V2, data);
            context.commit('SET_RECAP', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGrabWheelsRecaps(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.ALL_RECAP_PAGINATE_GRABWHEELS, data);
            context.commit('SET_RECAP', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllRecaps(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.ALL_RECAP, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGrabWheelsAllRecaps(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.ALL_RECAP_GRABWHEELS, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWeeklyReport(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.WEEKLY_REPORT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWeeklyReportByMonth(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.WEEKLY_REPORT_BY_MONTH, data);
            return response.data.results;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDailyReportByMonth(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.DAILY_REPORT_BY_MONTH, data);
            return response.data.results;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGrabWheelsDailyReportByMonth(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.DAILY_REPORT_BY_MONTH_GRABWHEELS, data);
            return response.data.results;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllNonGroupedRecaps(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.ALL_RECAP_NON_GROUPED, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGrabWheelsAllNonGroupedRecaps(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.ALL_RECAP_NON_GROUPED_GRABWHEELS, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateRecap(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(MITRA.RECAP_DETAIL(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteRecap(context, id) {
        try {
            const response = await api.apiDeleteAuth(MITRA.RECAP_DETAIL(id),);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateGrabWheelsRecap(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(MITRA.RECAP_DETAIL_GRABWHEELS(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAstroMonthlyShiftReport(context, data) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.MONTHLY_SHIFT_REPORT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAstroTemplateShift(context, data) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.DOWNLOAD_SHIFT_TEMPLATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadTemplateOrCreatePayslipBatch(context, data) {
        try {
            const response = await api.apiPostAuth(MITRA.DOWNLOAD_TEMPLATE_OR_CREATE_PAYSLIP_BATCH, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getPayslipBatchErrorLogs(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.PAYSLIP_BATCH_ERROR_LOGS(data.id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getPayslipBatchList(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.PAYSLIP_BATCH_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getPayslipBatchListName(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.PAYSLIP_BATCH_LIST_NAME, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deletePayslipBatch(context, data) {
        try {
            const response = await api.apiDeleteAuth(MITRA.DELETE_PAYSLIP_BATCH(data.id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showPayslipBatchDetail(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_PAYSLIP_BATCH_DETAIL(data.id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadExcelPayslip(context, data) {
        try {
            const response = await api.apiPostAuth(MITRA.UPLOAD_EXCEL_PAYSLIP(data.get('id')), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadPayslipBatchExcel(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.DOWNLOAD_PAYSLIP_BATCH_EXCEL(data.id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadPayslipBatchTemplate(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.DOWNLOAD_PAYSLIP_BATCH_TEMPLATE(data.id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editPayslipOnBatch(context, data) {
        try {
            const params = { 
                batch_id: data.batch_id,
                slip_id: data.slip_id
            };
            delete data.batch_id;
            delete data.slip_id;
            const response = await api.apiPatchAuth(MITRA.EDIT_PAYSLIP_ON_BATCH(params.batch_id, params.slip_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deletePayslipOnBatch(context, data) {
        try {
            const response = await api.apiDeleteAuth(MITRA.DELETE_PAYSLIP_ON_BATCH(data.batch_id, data.slip_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editPayslipBatchTemplate(context, data) {
        try {
            const params = { batch_id: data.batch_id };
            delete data.batch_id;
            const response = await api.apiPatchAuth(MITRA.EDIT_PAYSLIP_BATCH_TEMPLATE(params.batch_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async copyFieldPayslipBatch(context, data) {
        try {
            const params = { batch_id: data.batch_id };
            delete data.batch_id;
            const response = await api.apiPatchAuth(MITRA.COPY_FIELD_PAYSLIP_BATCH(params.batch_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async distributePayslipBatch(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.DISTRIBUTE_PAYSLIP_BATCH(data.batch_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async distributePayslip(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.DISTRIBUTE_PAYSLIP(data.batch_id, data.slip_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addMitraPayslip(context, data) {
        try {
            const params = { batch_id: data.batch_id };
            delete data.batch_id;
            const response = await api.apiPostAuth(MITRA.ADD_MITRA_PAYSLIP(params.batch_id), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getIdentityDoc(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_IDENTITY_DOC(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getFamilyDetail(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_FAMILY_DETAIL(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEquipmentDetail(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_EQUIPMENT_DETAIL(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDocument(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_DOCUMENT(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEmergencyContact(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_EMERGENCY_CONTACT(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobDetail(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_JOB_DETAIL(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEducation(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_EDUCATION(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getExperience(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_EXPERIENCE(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getTestResult(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_TES_RESULT(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAdditionalQuestions(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_ADDITIONAL_QUESTIONS(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getProfile(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_PROFILE(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchProfile(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_PROFILE(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchIdentityDoc(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_IDENTITY_DOC(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchFamilyDetail(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_FAMILY_DETAIL(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchEquipment(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_EQUIPMENT_DETAIL(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchDocument(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_DOCUMENT(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchEmergencyContact(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_EMERGENCY_CONTACT(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchJobDetail(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_JOB_DETAIL(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchEducation(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_EDUCATION(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchExperience(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_EXPERIENCE(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraOnBoarding(context, data) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_MITRA_ONBOARDING(data.source), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postManualInputOnboarding(context, payload) {
        try {
            const response = await api.apiPostAuth(MITRA.MANUAL_INPUT_ONBOARDING, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSalaryData(context, user_id) {
        try {
            const response = await api.apiGetAuth(MITRA.SHOW_SALARY_DATA(user_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchSalary(context, data) {
        try {
            const response = await api.apiPatchAuth(MITRA.SHOW_SALARY_DATA(data.user_id), data.payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },

    // Payslip v3
    async setFieldAsHighLight(context, data) {
        try {
            const params = { batch_id: data.batch_id };
            delete data.batch_id;
            const response = await api.apiPatchAuth(MITRA.SET_FIELD_AS_HIGHLIGHT(params.batch_id), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async removeFieldAsHighLight(context, data) {
        try {
            const params = { batch_id: data.batch_id };
            delete data.batch_id;
            const response = await api.apiPatchAuth(MITRA.REMOVE_FIELD_AS_HIGHLIGHT(params.batch_id), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    recaps: state => {
        return state.recaps;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
