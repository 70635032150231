<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <!-- TODO: Menunggu tim produk -->
        <!-- <div class="uk-flex uk-child-width-expand@s" style="gap: 15px">
            <button 
                v-for="(tab, index) in tabMenu"
                :key="index"
                class="uk-button uk-border-rounded"
                :class="[activeTabMenu === tab.query ? 'myrobin-background-1 myrobin-text-1' : 'uk-button-default']"
                :disabled="activeTabMenu === tab.query"
                @click="setActiveTabMenu(tab.query)"
            >{{ tab.name }}</button>
        </div> -->

        <div>
            <working-time-calculation v-if="activeTabMenu === 'working-time-calculation'"></working-time-calculation>
            <salary-settings v-if="activeTabMenu === 'salary-settings'"></salary-settings>
            <reimburse-setup v-if="activeTabMenu === 'reimburse-setup'"></reimburse-setup>
            <other-salary-setup v-if="activeTabMenu === 'other-salary-setup'"></other-salary-setup>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SalarySettingsPage',
    components: {
        WorkingTimeCalculation: () => import('@/components/globals/SalarySettings/WorkingTimeCalculation'),
        SalarySettings: () => import('@/components/globals/SalarySettings/SalarySettings'),
        ReimburseSetup: () => import('@/components/globals/SalarySettings/ReimburseSetup'),
        OtherSalarySetup: () => import('@/components/globals/SalarySettings/OtherSalarySetup')
    },
    data() {
        return {
            tabMenu: [
                {
                    name: 'Working Time Calculation',
                    query: 'working-time-calculation'
                },
                {
                    name: 'Salary Settings',
                    query: 'salary-settings'
                },
                {
                    name: 'Reimburse Setup',
                    query: 'reimburse-setup'
                },
                {
                    name: 'Other Salary Setup',
                    query: 'other-salary-setup'
                }
            ],
            activeTabMenu: null
        };
    },
    async mounted() {
        await this.setFirstActiveTabMenu();
    },
    methods: {
        async setFirstActiveTabMenu() {
            if(this.$route.query.tab === undefined) {
                await this.setQueryURL('reimburse-setup');
                this.setTab(this.$route.query.tab);
            } else {
                this.setTab(this.$route.query.tab);
            }
        },
        async setActiveTabMenu(tab) {
            await this.setQueryURL(tab);
            this.setTab(tab);
        },
        async setQueryURL(tab) {
            await this.$router.replace({
                path: '/settings/salary-settings',
                query: {
                    tab
                }
            });
        },
        setTab(tab) {
            this.activeTabMenu = tab;
        }
    }
};
</script>

<style scoped>
.myrobin-background-1 {
    background: #0ABAB5;
}

.myrobin-text-1 {
    color: white;
}
</style>
