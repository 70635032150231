import api from '@/utils/api';
import { DASHBOARD } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getDashboardData(context, data) {
        try {
            const response = await api.apiGetAuth(DASHBOARD.GET_DATA, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
