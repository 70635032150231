<template>
    <section>
        <div id="download-template-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="close"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Please select the type of template that you wish to download</h2>
                </div>
                <div v-if="data_download" class="uk-modal-body">
                    <div class="uk-flex uk-flex-middle uk-flex-center">
                        <div uk-spinner="ratio: 2"></div>
                    </div>
                </div>
                <div v-else class="uk-modal-body">
                    <div
                        v-for="(opt, id) in selection"
                        :key="id"
                        :value="opt.type"
                        class="uk-card uk-card-default uk-card-body uk-card-small template-link"
                        @click="downloadExcelMitraData(opt.type)"
                    >
                        <div class="uk-flex uk-flex-between">
                            <span>
                                <img :src="`${images}/excel.png`" alt="excel" width="20"> {{ opt.name }} template
                            </span>
                            <img :src="`${images}/download-report.png`" alt="download">
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left uk-modal-close" type="button" @click="close">Back</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import { notificationDanger, notificationDangerCustom } from "@/utils/notification";
import { excelDownloader, excelDownloaderWithAdditionalSheet } from '@/utils/helper';

export default {
    props: {
        selection: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
            required: true
        },
    },
    data(){
        return {
            images: PREFIX_IMAGE,
            data_download: false,
        };
    },
    async mounted() {
        await window.UIkit.modal("#download-template-modal").show();
    },

    methods: {
        ...mapActions({
            downloadOnboardingMitradataExcel: "worker/downloadOnboardingMitradataExcel",
            downloadMitraDataExcel: "worker/downloadMitraDataExcel",
        }),
        async downloadExcelMitraData(type) {
            if (this.meta.tabActive) {
                await this.downloadExcelMitraDataOnboarding(type);
            } else {
                await this.downloadExcelMitraDataDetails(type);
            }
        },
        async downloadExcelMitraDataOnboarding(type) {
            try {
                if (!this.data_download) {
                    this.data_download = true;
                    const payload = {
                        ...this.meta,
                        type: type
                    };
                    const result = await this.downloadOnboardingMitradataExcel({ source: this.meta.tabActive, payload: payload });
                    const dataExcel = result.docs;
                    excelDownloader(dataExcel, `${result.file_name}.xlsx`);
                } else {
                    notificationDangerCustom('Another download is in progress, please wait.');
                }
            } catch (error) {
                notificationDanger(error);
            }

            this.data_download = false;
        },
        async downloadExcelMitraDataDetails(type) {
            try {
                if (!this.data_download) {
                    this.data_download = true;
                    const payload = {
                        ...this.meta,
                        type: type
                    };
                    const result = await this.downloadMitraDataExcel(payload);
                    const dataExcel = result.docs;
                    if (result.additional_data && result.additional_data.length > 0) {
                        const additionalDataExcel = result.additional_data;
                        excelDownloaderWithAdditionalSheet(dataExcel, additionalDataExcel, `${result.file_name}.xlsx`);
                    } else {
                        excelDownloader(dataExcel, `${result.file_name}.xlsx`);   
                    }
                } else {
                    notificationDangerCustom('Another download is in progress, please wait.');
                }
            } catch (error) {
                notificationDanger(error);
            }

            this.data_download = false;
        },
        async close() {
            await window.UIkit.modal('#download-template-modal').$destroy(true);
            this.$emit('close');
        },
    }
};
</script>
