import api from '@/utils/api';
import { ADMIN_JOB_VACANCY } from '@/utils/api-url';
import { notificationDanger } from '@/utils/notification';
import Vue from "vue";

const namespaced = true;
const state = {
    admin_job_vacancies: [],
};

const mutations = {
    "SET_ADMIN_JOB_VACANCY": (state, payload) => {
        Vue.set(state, 'admin_job_vacancies', payload);
    }
};

const actions = {
    async getAdminJobVacancy(context, data) {
        try {
            const response = await api.apiGetAuth(ADMIN_JOB_VACANCY.LIST, data);
            context.commit('SET_ADMIN_JOB_VACANCY', response.data.result.docs);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async searchAdminJobVacancy(context, data) {
        try {
            const response = await api.apiGetAuth(ADMIN_JOB_VACANCY.SEARCH, data);
            context.commit('SET_ADMIN_JOB_VACANCY', response.data.result.docs);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {
    admin_job_vacancies: state => {
        return state.admin_job_vacancies;
    },
};

export default {
    state,
    mutations,
    namespaced,
    actions,
    getters
};
