const METHOD = {
    GET: 'get',
    PATCH: 'patch',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
};

const PREFIX_IMAGE = '/images';
const PREFIX_ATTENDANCE_IMAGE = 'https://s3.ap-southeast-1.amazonaws.com/documents.myrobin.tech/attendance-photo/';
const PREFIX_PROFILE_IMAGE = 'https://s3.ap-southeast-1.amazonaws.com/documents.myrobin.tech/profile/';

const BANK_LIST = [
    'Aladin Syariah', 'Allo Bank', 'Amar', 'ANZ', 'Artha Graha','Bank Aceh Syariah', 'Bank Nagari','Bank NTB Syariah', 'Bank Of India',
    'BCA', 'BCA Syariah','BNI', 'BNP Paribas', 'BPD Bali', 'BPD Banten', 'BPD Bengkulu', 'BPD DIY', 'BPD DKI', 'BPD Jambi',
    'BPD Jawa Barat dan Banten', 'BPD Jawa Tengah', 'BPD Jawa Timur', 'BPD Kalimantan Barat', 'BPD Kalimantan Selatan',
    'BPD Kalimantan Tengah', 'BPD Kalimantan Timur dan Kalimantan Utara', 'BPD Lampung', 'BPD Maluku dan Maluku Utara', 'BPD NTB', 'BPD NTT',
    'BPD Papua', 'BPD Riau Kepri Syariah', 'BPD Sulawesi Selatan dan Sulawesi Barat', 'BPD Sulawesi Tengah', 'BPD Sulawesi Tenggara',
    'BPD Sulawesi Utara dan Gorontalo', 'BPD Sumatera Selatan dan Bangka Belitung', 'BPD Sumatera Utara', 'BRI', 'BSI', 'BTN', 'BTPN',
    'BTPN Syariah', 'Bumi Artha', 'Capital', 'China Construction', 'CIMB', 'Commonwealth', 'CTBC', 'Danamon', 'DBS', 'Digital BCA',
    'Fama Internasional', 'Ganesha', 'HSBC', 'IBK', 'ICBC', 'Ina Perdana', 'Index Selindo', 'Jabar Banten Syariah', 'Jago',
    'Jasa Jakarta', 'JTrust', 'KB Bukopin', 'KB Bukopin Syariah', 'KEB Hana', 'Krom Bank Indonesia', 'Mandiri', 'Mandiri Taspen',
    'Maspion', 'Mayapada International', 'Maybank', 'Mayora', 'Mega', 'Mega Syariah', 'Mestika Dharna', 'Mizuho', 'MNC', 'Muamalat',
    'Multiarta Sentosa', 'Nationalnobu', 'Neo Commerce', 'OCBC', 'Oke Indonesia', 'PAN', 'Panin Dubai Syariah', 'Permata',
    'Prima Master Bank', 'QNB', 'Raya', 'Resona Perdania', 'Sahabat Sampoerna', 'SBI', 'Seabank', 'Shinhan', 'Sinarmas', 'UOB',
    'Victoria Internasional', 'Victoria Syariah', 'Woori Suara Indonesia 1906'
];
const EWALLET_LIST = ['DANA', 'Doku Wallet', 'GoPay', 'iSaku', 'Jenius', 'LinkAja', 'OVO', 'PayPal', 'Sakuku', 'ShopeePay'];

// transform JOB_VACANCY_STATUS to array
const JOB_VACANCY_STATUS = [
    {
        TEXT: 'In Progress', // 'In Progress'
        CLASS: 'bg-success',
    },
    {
        TEXT: 'Submitted', // 'Submitted'
        CLASS: 'bg-warning',
    },
    {
        TEXT: 'Completed', // 'Completed'
        CLASS: 'bg-danger',
    },
];

export {
    METHOD,
    PREFIX_IMAGE,
    PREFIX_ATTENDANCE_IMAGE,
    PREFIX_PROFILE_IMAGE,
    BANK_LIST,
    EWALLET_LIST,
    JOB_VACANCY_STATUS,
};
