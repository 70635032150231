import api from '@/utils/api';
import { CONTRACT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getContractsPaginate(context, data) {
        try {
            const response = await api.apiGetAuth(CONTRACT.LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getContractDetail(context, id) {
        try {
            const response = await api.apiGetAuth(CONTRACT.DETAIL(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSelectableContract(context, data) {
        try {
            const response = await api.apiGetAuth(CONTRACT.SELECTABLE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllContract(context, data) {
        try {
            const response = await api.apiGetAuth(CONTRACT.ALL, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobVacancyContract(context, data) {
        try {
            const response = await api.apiGetAuth(CONTRACT.JOB_VACANCY_CONTRACTS, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
