import Vue from 'vue';
import api from '@/utils/api';
import { OPTION_DATA } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    roles: [],
    roles_by_office: [],
    open_roles: [],
    shifts: [],
    internal_roles: []
};

const mutations = {
    "SET_ROLE": (state, payload) => {
        Vue.set(state, 'roles', payload);
    },
    "SET_ROLE_BY_OFFICE": (state, payload) => {
        Vue.set(state, 'roles_by_office', payload);
    },
    "SET_OPEN_ROLE": (state, payload) => {
        Vue.set(state, 'open_roles', payload);
    },
    "SET_SHIFT": (state, payload) => {
        Vue.set(state, 'shifts', payload);
    },
    "SET_INTERNAL_ROLE": (state, payload) => {
        Vue.set(state, 'internal_roles', payload);
    }
};

const actions = {
    async getRoles(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.ROLE, data);
            context.commit('SET_ROLE', response.data.result);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRolesByOffice(context, data) {
        try {
            const response = await api.apiPostAuth(OPTION_DATA.ROLE_BY_OFFICE, data);
            context.commit('SET_ROLE_BY_OFFICE', response.data.result);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getOpenRoles(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.OPEN_ROLE, data);
            context.commit('SET_OPEN_ROLE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getOfficeRoles(context, id) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.OFFICE_ROLE(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShifts(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.SHIFT, data);
            context.commit('SET_SHIFT', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShiftDetail(context, id) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.SHIFT_ITEM(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postShift(context, data) {
        try {
            const response = await api.apiPostAuth(OPTION_DATA.SHIFT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async checkShift(context, data) {
        try {
            const { id } = data;
            const response = await api.apiGetAuth(OPTION_DATA.CHECK_SHIFT(id));
            return response.data;
        } catch (err) {
            console.warn(err);
        }
    },
    async updateShift(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(OPTION_DATA.SHIFT_ITEM(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteShift(context, data) {
        try {
            const { id } = data;
            const response = await api.apiDeleteAuth(OPTION_DATA.SHIFT_ITEM(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getInternalRoles(context) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.ROLE, {internal_shipper: true});
            context.commit('SET_INTERNAL_ROLE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobTypes() {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.JOB_TYPE);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEducationLevels() {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.EDUCATION_LEVEL);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getTestCategories() {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.TEST_CATEGORY);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobPreferences() {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.JOB_PREFERENCE);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCities(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.CITY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getProvinces(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.PROVINCE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllShiftByCompany(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.GET_ALL_SHIFT_BY_COMPANY(data.company_id), '');
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllShiftByOffice(context, data) {
        try {
            const { _id } = data;
            const response = await api.apiGetAuth(OPTION_DATA.GET_ALL_SHIFT_BY_OFFICE(_id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanies(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.COMPANY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRolesOnCompany(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.ROLE+ '/' + data.company_id);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getBanks() {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.BANK);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllCompanyRoles(context, data){
        try {
            const response = await api.apiGetAuth(OPTION_DATA.GET_ALL_COMPANY_ROLES, data);
            return response.data.result;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getCompanyOfficeList(context, data){
        try {
            const response = await api.apiGetAuth(OPTION_DATA.GET_COMPANY_OFFICE_LIST, data);
            return response.data.result.docs;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    roles: state => {
        return state.roles;
    },
    roles_by_office: state => {
        return state.roles_by_office;
    },
    open_roles: state => {
        return state.open_roles;
    },
    shifts: state => {
        return state.shifts;
    },
    internal_roles: state => {
        return state.internal_roles;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
