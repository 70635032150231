<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <LoaderPage v-if="isLoading" />
        <template v-else>
            <!-- Filter -->
            <div class="uk-grid-small" uk-grid>
                <form class="uk-width-1-3" @submit.prevent="filterApplicantData">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon" uk-icon="icon: search"></span>
                        <input v-model="meta.application_name" class="uk-input uk-border-pill" placeholder="Search Applicant Name..." />
                    </div>
                </form>
                <div class="uk-width-auto">
                    <button
                        type="button"
                        class="uk-button uk-button-default uk-border-pill uk-flex uk-flex-middle uk-flex-center"
                        :class="[meta.job_title || meta.interview_method || meta.interview_datetime ? 'myrobin-color-yellow' : 'myrobin-background-white']"
                        @click="showInterviewScheduleModalBox"
                    >
                        <img
                            :src="`${images}/filter_list.svg`"
                            alt="Filter icon"
                            loading="lazy"
                            class="uk-margin-small-right"
                        />
                        <p class="uk-margin-remove">Filter</p>
                    </button>
                </div>
                <div v-if="meta.job_title || meta.interview_method || meta.interview_datetime" class="uk-width-auto uk-flex uk-flex-middle uk-flex-center">
                    <p class="uk-margin-remove filter-badge">filters applied</p>
                </div>
            </div>
            <div class="uk-grid-small" uk-grid>
                <div class="uk-width-expand uk-flex uk-flex-middle uk-flex-center">
                    <hr class="uk-width-1-1" />
                </div>
                <div class="uk-width-1-4">
                    <select v-model="meta.sort_by" class="uk-select uk-border-pill" @change="filterApplicantData">
                        <option :value="null">Latest Interview Date</option>
                        <option value="interview_date_asc">Oldest Interview Date</option>
                        <option value="applicant_name_asc">A-Z</option>
                        <option value="applicant_name_desc">Z-A</option>
                    </select>
                </div>
            </div>
            <!-- End filter -->

            <!-- Applicant list -->
            <div v-if="applicantData.totalDocs > 0" class="uk-card uk-card-default uk-margin-top">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-medium uk-table-striped">
                        <thead>
                            <tr>
                                <th class="uk-text-left uk-text-nowrap">Action</th>
                                <th class="uk-text-left uk-text-nowrap">Name</th>
                                <th class="uk-text-left uk-text-nowrap">Vacancy Title</th>
                                <th class="uk-text-left uk-text-nowrap">Interview Date</th>
                                <th class="uk-text-left uk-text-nowrap">Interview Time</th>
                                <th class="uk-text-left uk-text-nowrap">Method</th>
                                <th class="uk-text-left uk-text-nowrap">Info</th>
                                <th class="uk-text-center uk-text-nowrap">
                                    <p class="uk-margin-remove">Candidate</p>
                                    <p class="uk-margin-remove">Phone Number</p>
                                </th>
                                <th class="uk-text-left uk-text-nowrap">Interviewer</th>
                            </tr>
                        </thead>
                        <LoaderTable v-if="tableLoading" :colspan="8" />
                        <template v-else>
                            <tbody v-if="applicantData.totalDocs > 0">
                                <tr v-for="applicant in applicantData.docs" :key="applicant && applicant._id">
                                    <td class="uk-text-left uk-text-nowrap" uk-tooltip="Send WhatsApp Invites">
                                        <span class="send-wa-btn" @click="sendWhatsApp(applicant)">
                                            <img
                                                :src="`${images}/whatsapp-icon.svg`"
                                                alt="Whatsapp Icon"
                                            />
                                        </span>
                                    </td>
                                    <td class="uk-text-left uk-text-nowrap" :uk-tooltip="applicant && applicant.fullname || '-'">
                                        {{ stringPreview({ text: applicant && applicant.fullname || null, limit: 26 }) }}
                                    </td>
                                    <td class="uk-text-left uk-text-nowrap" :uk-tooltip="applicant && applicant.job_title || '-'">
                                        {{ stringPreview({ text: applicant && applicant.job_title || null, limit: 26 }) }}
                                    </td>
                                    <td class="uk-text-left uk-text-nowrap">
                                        {{ formatDate(applicant && applicant.interview_datetime || null) }}
                                    </td>
                                    <td class="uk-text-left uk-text-nowrap">
                                        {{ applicant && applicant.interview_datetime ? `${formatHourMinute(applicant && applicant.interview_datetime || null)} WIB` : '-' }}
                                    </td>
                                    <td class="uk-text-left uk-text-nowrap">{{ applicant && applicant.interview_method || '-' }}</td>
                                    <td class="uk-text-left uk-text-nowrap">
                                        <template v-if="applicant && applicant.interview_method == 'Online'">
                                            <template v-if="applicant && applicant.interview_url">
                                                <p class="uk-margin-remove uk-inline">
                                                    {{ stringPreview({ text: applicant && applicant.interview_url || null, limit: 26 }) }}
                                                </p>
                                                <button type="button" v-clipboard:copy="applicant && applicant.interview_url" v-clipboard:success="onCopy">
                                                    <img
                                                        :src="`${images}/copy-blue.svg`"
                                                        alt="Copy icon"
                                                        loading="lazy"
                                                        class="uk-margin-small-left"
                                                    />
                                                </button>
                                            </template>
                                            <p v-else class="uk-margin-remove">-</p>
                                        </template>
                                        <template v-else-if="applicant && applicant.interview_method == 'Offline'">
                                            <template v-if="applicant && applicant.interview_address">
                                                <p class="uk-margin-remove uk-inline">
                                                    {{ stringPreview({ text: applicant && applicant.interview_address || null, limit: 26 }) }}
                                                </p>
                                                <button type="button" v-clipboard:copy="applicant && applicant.interview_address" v-clipboard:success="onCopy">
                                                    <img
                                                        :src="`${images}/copy-blue.svg`"
                                                        alt="Copy icon"
                                                        loading="lazy"
                                                        class="uk-margin-small-left"
                                                    />
                                                </button>
                                            </template>
                                            <p v-else class="uk-margin-remove">-</p>
                                        </template>
                                        <p v-else class="uk-margin-remove">-</p>
                                    </td>
                                    <td class="uk-text-center uk-text-nowrap">
                                        <template v-if="applicant && applicant.phone_number">
                                            <p class="uk-margin-remove uk-inline">
                                                {{ stringPreview({ text: applicant && applicant.phone_number || null, limit: 26 }) }}
                                            </p>
                                            <button type="button" v-clipboard:copy="applicant && applicant.phone_number" v-clipboard:success="onCopy">
                                                <img
                                                    :src="`${images}/copy-blue.svg`"
                                                    alt="Copy icon"
                                                    loading="lazy"
                                                    class="uk-margin-small-left"
                                                />
                                            </button>
                                        </template>
                                        <p v-else class="uk-margin-remove">-</p>
                                    </td>
                                    <td class="uk-text-left uk-text-nowrap" :uk-tooltip="applicant && applicant.interviewer_name || '-'">
                                        {{ stringPreview({ text: applicant && applicant.interviewer_name || null, limit: 26 }) }}
                                    </td>
                                </tr>
                            </tbody>
                            <EmptyTable v-else :colspan="8" />
                        </template>
                    </table>
                </div>
                <Pagination
                    :total-data="applicantData.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <NotFoundFilter v-else class="uk-margin-top" />

            <!-- Filter interview schedule modal box -->
            <div id="filter-interview-schedule-modal-box" class="filter-interview-schedule-modal-box" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-margin-remove">Filter Interview Schedule</h2>
                    </div>
                    <form @submit.prevent="filterApplicantFromModalBox">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-2">
                                    <label><input v-model="formFilterModalBox.isActiveVacancyTitle" class="uk-checkbox" type="checkbox" /> Vacancy Title</label>
                                    <input
                                        v-model="formFilterModalBox.vacancyTitle"
                                        class="uk-input uk-border-rounded"
                                        type="text"
                                        placeholder="Type to filter Vacancy Tile"
                                    />
                                </div>
                                <div class="uk-width-1-2">
                                    <label><input v-model="formFilterModalBox.isActiveInterviewMethod" class="uk-checkbox" type="checkbox" /> Interview Method</label>
                                    <select v-model="formFilterModalBox.interviewMethod" class="uk-select uk-border-rounded">
                                        <option :value="null">Select Interview Method</option>
                                        <option :value="1">Online</option>
                                        <option :value="2">Offline</option>
                                    </select>
                                </div>
                                <div class="uk-width-1-2">
                                    <label><input v-model="formFilterModalBox.isActiveInterviewDate" class="uk-checkbox" type="checkbox" /> Interview Date</label>
                                    <Datetime
                                        id="interviewDate"
                                        v-model="formFilterModalBox.interviewDate"
                                        name="interviewDate"
                                        input-class="uk-input uk-border-rounded"
                                        type="date"
                                        format="dd/LL/yyyy"
                                        placeholder="dd/mm/yyyy"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-between">
                            <button
                                type="button"
                                class="uk-button uk-button-default"
                                @click="clearFilterApplicantFromModalBox"
                            >Clear Filter</button>
                            <button
                                type="submit"
                                :class="[disabledButtonApplyFilterModalBox ? 'uk-button uk-button-default myrobin-background-grey myrobin-disabled-element' : 'uk-button uk-button-primary']"
                                :disabled="disabledButtonApplyFilterModalBox"
                            >Apply</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End filter interview schedule modal box -->
        </template>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger,
    // notificationDangerCustom,
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import phoneNumber from "@/utils/phone-number";

export default {
    name: 'InterviewSchedule',
    components: {
        Datetime,
        Pagination: () => import('@/components/globals/Pagination'),
        NotFoundFilter: () => import('@/components/globals/JobApplicant/NotFoundFilter'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        LoaderTable: () => import('@/components/globals/tables/Loading')
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            tableLoading: false,
            profile: null,
            applicantData: {
                docs: [],
                totalDocs: 0,
            },
            meta: {
                limit: 10,
                page: 1,
                application_name: null,
                job_title: null,
                interview_method: null,
                interview_datetime: null,
                sort_by: null,
                timezone_offset: new Date().getTimezoneOffset()
            },
            formFilterModalBox: {
                isActiveVacancyTitle: false,
                isActiveInterviewMethod: false,
                isActiveInterviewDate: false,
                vacancyTitle: null,
                interviewMethod: null,
                interviewDate: null
            }
        };
    },
    watch: {
        async meta() {
            try {
                this.tableLoading = true;
                await this.setApplicantData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        }
    },
    computed: {
        profileCompanyId() {
            return this.profile?.company_id;
        },
        disabledButtonApplyFilterModalBox() {
            return (!this.formFilterModalBox.isActiveVacancyTitle || !this.formFilterModalBox.vacancyTitle) &&
                (!this.formFilterModalBox.isActiveInterviewMethod || !this.formFilterModalBox.interviewMethod) &&
                (!this.formFilterModalBox.isActiveInterviewDate || !this.formFilterModalBox.interviewDate);
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.filter-interview-schedule-modal-box')) window.UIkit.modal('.filter-interview-schedule-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.profile = await this.getProfile();
            await this.setApplicantData();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getProfile: 'auth/getProfile',
            getInterviewSchedule: 'job_applicant/getInterviewSchedule',
        }),
        async setApplicantData() {
            try {
                const response = await this.getInterviewSchedule({ companyId: this.profileCompanyId, meta: this.meta });
                if (response?.status === 'OK' && response?.result) {
                    const result = response?.result;
                    const docs = result?.docs || [];
                    const totalDocs = result?.totalDocs || 0;

                    this.applicantData.docs = docs;
                    this.applicantData.totalDocs = totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showInterviewScheduleModalBox() {
            try {
                await window.UIkit.modal('#filter-interview-schedule-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterApplicantData() {
            try {
                this.tableLoading = true;
                await this.setApplicantData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async filterApplicantFromModalBox() {
            try {
                this.tableLoading = true;

                if (this.formFilterModalBox.isActiveVacancyTitle) {
                    this.meta.job_title = this.formFilterModalBox.vacancyTitle;
                } else {
                    this.formFilterModalBox.vacancyTitle = null;
                    this.meta.job_title = null;
                }

                if (this.formFilterModalBox.isActiveInterviewMethod) {
                    this.meta.interview_method = this.formFilterModalBox.interviewMethod;
                } else {
                    this.formFilterModalBox.interviewMethod = null;
                    this.meta.interview_method = null;
                }

                if (this.formFilterModalBox.isActiveInterviewDate) {
                    this.meta.interview_datetime = this.formFilterModalBox.interviewDate;
                } else {
                    this.formFilterModalBox.interviewDate = null;
                    this.meta.interview_datetime = null;
                }

                await this.setApplicantData();
                await window.UIkit.modal('#filter-interview-schedule-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async clearFilterApplicantFromModalBox() {
            try {
                this.formFilterModalBox.isActiveVacancyTitle = false;
                this.formFilterModalBox.isActiveInterviewMethod = false;
                this.formFilterModalBox.isActiveInterviewDate = false;
                this.formFilterModalBox.vacancyTitle = null;
                this.formFilterModalBox.interviewMethod = null;
                this.formFilterModalBox.interviewDate = null;

                await this.filterApplicantFromModalBox();
            } catch (error) {
                notificationDanger(error);
            }
        },
        sendWhatsApp(applicant) {
            if (!applicant) return;

            let interviewMethod = '';
            let additionalInfo = '';
            let closing = 'Mohon hadir tepat waktu ya~';
            if (applicant.interview_method == 'Offline') {
                interviewMethod = 'secara tatap muka';
                additionalInfo = `4. Alamat Wawancara : ${applicant.interview_address}%0A`;
            } else if (applicant.interview_method == 'Online' && applicant.online_interview_method == 'Video Call') {
                interviewMethod = 'via Video Call';
                additionalInfo = `4. Link Interview : ${applicant.interview_url}%0A`;
            } else if (applicant.interview_method == 'Online' && applicant.online_interview_method == 'Phone Call') {
                interviewMethod = 'via Telepon';
                closing = 'Pastikan nomor HP atau nomor WA kamu aktif ya!';
            }

            const waTemplate = `Undangan Interview - MyRobin%0A%0A` +
                `Hi ${applicant.fullname},%0A%0A` +
                `Selamat! Lamaran kamu lolos tahap administrasi.%0A%0A` +
                `HRD MyRobin mengundang kamu untuk melanjutkan proses ke tahap wawancara ${interviewMethod} dengan informasi berikut:%0A%0A` +
                `1. Nama Lowongan : ${applicant.job_title}%0A` +
                `2. Tanggal Wawancara : ${this.formatDate(applicant.interview_datetime)}%0A` +
                `3. Waktu Wawancara : ${this.formatHourMinute(applicant.interview_datetime)} WIB%0A` +
                `${additionalInfo}%0A` +
                `Kamu akan diwawancara oleh HRD MyRobin bernama ${applicant.interviewer_name}.%0A%0A` +
                `${closing}%0A%0A` +
                `Terima kasih sudah mendaftar kerja melalui MyRobin dan semoga berhasil! 🥳🤝`;

            window.open(`https://api.whatsapp.com/send/?phone=${phoneNumber(applicant.phone_number)}&text=${waTemplate}&type=phone_number&app_absent=0`);
        },
        onCopy(e) {
            notificationSuccess("Copied to clipboard: " + e.text);
        },
        formatDate(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateComplete(data);
        },
        formatHourMinute(data) {
            if (data == null) {
                return '-';
            }
            return formatter.hourMinuteOnly(data);
        },
        stringPreview({ text, limit }) {
            if (typeof text !== "string") throw new Error("Text must be a string");
            if (typeof limit !== "number") throw new Error("Limit must ");

            let string = '';
            if (text.length > limit) {
                string = `${text.substring(0, limit)}...`;
            } else {
                string = text;
            }

            return string;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
    }
};
</script>

<style scoped>
thead {
    background: #0ABAB5;
}

th {
    color: #FFFFFF;
}

td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.myrobin-background-white {
    background: #FFFFFF;
}

.myrobin-background-grey {
    background: #F4F4F4;
}

.myrobin-disabled-element {
    cursor: not-allowed;
}

.myrobin-color-yellow {
    background: #FFE500;
}

.filter-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 20px;
    font-size: 12px;
    color: #000000;
    font-weight: 500;
    font-style: italic;
    background: #FFE500;
}

.send-wa-btn:hover {
    cursor: pointer;
}
</style>
