<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div class="uk-grid-column-small uk-grid-row-large uk-child-width-1-2@m" uk-grid>
            <div v-if="$can('READ', 'worker-salary')">
                <div v-if="isShow">
                    <div class="location-header" style="cursor:pointer" @click="showModal('filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20">
                        {{ meta.date ? moment(meta.date).format('MMMM YYYY') : `${moment(meta.start_date).format('DD MMMM YYYY')} - ${moment(meta.end_date).format('DD MMMM YYYY')}` }},
                        {{ cityName }},
                        {{ officeName }}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer" @click="showModal('filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20"> Select filter {{ meta.date }}
                    </div>
                </div>
                <button v-if="isShow" class="reset-filter" @click="resetFilter">Reset Location Filter</button>
            </div>
            <div v-else>
              <div v-if="isShow">
                  <div class="location-header">
                      <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{ cityName || 'n/a' }}
                  </div>
                  <div class="location-header">
                      <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{ officeName || 'n/a' }}
                  </div>
              </div>
            </div>
        </div>
        <div v-show="isShow" class="uk-flex uk-flex-between uk-margin-remove-top" uk-grid>
            <div class="uk-inline">
                <span class="uk-form-icon uk-form-icon-flip uk-margin-auto-vertical" uk-icon="icon: search"></span>
                <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'worker_name')" type="text" placeholder="Search worker by name">
            </div>
            <div v-if="!generateMode && recaps.docs.length>0 && $can('EDIT', 'worker-salary') && $can('WRITE', 'worker-salary')">
                <button class="uk-button uk-button-primary uk-border-rounded" @click="generateMode = !generateMode">Generate Salary</button>
            </div>
            <div v-if="generateMode" class="uk-margin-auto-vertical">
                <button class="uk-button uk-button-danger uk-border-rounded" @click="generateMode = !generateMode;selected_item=[]">Cancel</button>
                <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" @click="showModal('generate')">Continue</button>
            </div>
        </div>

        <!-- Main Table -->
        <div v-if="$can('READ', 'worker-salary')" class="uk-card uk-card-default uk-margin" v-show="isShow">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th v-if="generateMode" class="uk-text-center">
                                <input 
                                    class="uk-checkbox" 
                                    type="checkbox"
                                    :checked="findCheckedAllItem()"
                                    @change="addAllItem()"
                                >
                            </th>
                            <th class="uk-table-expand">Full Name</th>
                            <th class="uk-text-center">Job Role</th>
                            <th class="uk-text-center">Office</th>
                            <th class="uk-text-center">Total Work<br>Days</th>
                            <th class="uk-text-center">Total Work<br>Hours</th>
                            <th class="uk-text-center">Income</th>
                            <th class="uk-text-center">Expense</th>
                            <th class="uk-text-center">Total Payment</th>
                            <th class="uk-text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="recaps.docs.length>0">
                        <tr v-for="(worker, i) in recaps.docs" :key="i">
                            <td v-if="generateMode" class="uk-text-middle">
                                <input 
                                    class="uk-checkbox" 
                                    type="checkbox"
                                    :checked="findCheckedItem({
                                        user_id: worker._id.user_id,
                                        job_partner_id: worker._id.job_partner_id,
                                        company_office_id: worker._id.company_office_id
                                    })"
                                    @change="addItem({
                                        user_id: worker._id.user_id,
                                        job_partner_id: worker._id.job_partner_id,
                                        company_office_id: worker._id.company_office_id,
                                        daily_recap_ids: worker.daily_recap_ids,
                                        subtraction_adjustment_ids: worker.subtraction_adjustment_ids,
                                        addition_adjustment_ids: worker.addition_adjustment_ids,
                                        expense_ids: worker.expense_ids,
                                        income: worker.income,
                                        expense: worker.expense
                                    })"
                                >
                            </td>
                            <td class="uk-text-left uk-text-justify uk-text-middle">
                                <a
                                    style="text-decoration: none;"
                                    @click="detailCandidate({
                                        start_date: meta.start_date,
                                        end_date: meta.end_date,
                                        date: meta.date,
                                        fee_normal_unit: meta.fee_normal_unit,
                                        daily_recap_ids: worker.daily_recap_ids.toString(),
                                        city: meta.city,
                                        office: company_office.name
                                    })"
                                    href="javascript:void(0)"
                                >{{worker.fullname || '-'}}</a>
                            </td>
                            <td class="uk-text-center uk-text-middle">{{ worker.role }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.company_office }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.workdays }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.workhours }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(worker.income)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(worker.expense)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(worker.total_payment)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.status }}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="10"/>
                </table>
            </div>
            <pagination
                :total-data="recaps.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized read data from this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End of Main Table -->

        <!-- Modal box filter data -->
        <div id="filter" class="filter" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Select filter for Salary list</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="fee_unit">Fee Unit<span class="uk-text-danger">*</span></label>
                        <div class="uk-form-controls">
                            <select
                                name="fee_unit"
                                class="uk-select"
                                id="fee_unit"
                                v-model="meta.fee_normal_unit"
                                placeholder="Fee Unit"
                            >
                                <option :value="null" selected>Select Fee Unit</option>
                                <option value="Hour">Hour</option>
                                <option value="Day">Day</option>
                                <option value="Month">Month</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="meta.fee_normal_unit && meta.fee_normal_unit == 'Month'" class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">For (Month)<span class="uk-text-danger">*</span></label>
                        <div class="uk-form-controls" @click="hideYearMonth">
                            <datetime
                                id="date"
                                name="date"
                                input-class="uk-input"
                                placeholder="MMMM, yyyy"
                                type='month'
                                :flow="['year','month']"
                                format="MMMM, yyyy"
                                v-model="meta.date"
                                :min-datetime="new Date(2022, 0, 1).toISOString()"
                                :max-datetime="moment().toISOString()"
                                value-zone="local"
                                title="For (Month)"
                            ></datetime>
                        </div>
                    </div>
                    <div v-if="meta.fee_normal_unit && meta.fee_normal_unit != 'Month'" class="uk-margin">
                        <label class="uk-form-label" for="start_date">Start Date<span class="uk-text-danger">*</span></label>
                        <div class="uk-form-controls">
                            <datetime
                                id="start_date"
                                name="start_date"
                                input-class="uk-input"
                                placeholder="dd MMMM yyyy"
                                :flow="['year','month','date']"
                                format="dd MMMM yyyy"
                                v-model="meta.start_date"
                                :min-datetime="new Date(2022, 0, 1).toISOString()"
                                :max-datetime="moment().toISOString()"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                    <div v-if="meta.fee_normal_unit && meta.fee_normal_unit != 'Month' && meta.start_date" class="uk-margin">
                        <label class="uk-form-label" for="end_date">End Date<span class="uk-text-danger">*</span></label>
                        <div class="uk-form-controls">
                            <datetime
                                id="end_date"
                                name="end_date"
                                input-class="uk-input"
                                placeholder="dd MMMM yyyy"
                                :flow="['year','month','date']"
                                format="dd MMMM yyyy"
                                v-model="meta.end_date"
                                :min-datetime="meta.start_date"
                                :max-datetime="moment().toISOString()"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                    <div v-if="meta.fee_normal_unit" class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select-1">City</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office City"
                                class="uk-select"
                                id="form-stacked-select-1"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="all" selected>All Cities</option>
                                <option 
                                    v-for="(city, index) in cities" 
                                    :key="index" 
                                    :value="city"
                                >{{ city.name || '-' }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="selected_city != 'all'" class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select-2">Office</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office Property"
                                class="uk-select"
                                id="form-stacked-select-2"
                                v-model="company_office"
                            >
                                <option value="all" selected>All Offices</option>
                                <option 
                                    v-for="(office, index) in filtered_offices" 
                                    :key="index" 
                                    :value="office"
                                >{{ office.name || '-' }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="applyFilter">Apply</button>
                </div>
            </div>
        </div>
        <!-- End modal box filter data -->

        <!-- Modal box Generate -->
        <div id="generate" class="generate" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('generate')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Generate Salary</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label for="batch-name">Salary Report Name<span class="uk-text-danger">*</span></label>
                        <input id="batch-name" class="uk-input" type="text" placeholder="Please input salary report name" v-model="batchName">
                    </div>
                    <div class="uk-margin">Number of Mitra for this payment: {{selected_item.length}} Mitra</div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal('confirmation')">Confirm</button>
                </div>
            </div>
        </div>
        <!-- End modal box Generate -->

        <!-- Modal box Confirmation -->
        <div id="confirmation" class="confirmation" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Confirmation</h2>
                </div>
                <div class="uk-modal-body">Are you sure want to proceed?</div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-border-rounded" @click="hideModal('confirmation')">No</button>
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="generateSalary">Yes</button>
                </div>
            </div>
        </div>
        <!-- End modal box Confirmation -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import { Datetime } from 'vue-datetime';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import moment from 'moment';
moment.locale('id');

export default {
    data({ $route }) {
        return {
            moment: moment,
            isShow: false,
            meta: {
                limit: $route.query.limit ? $route.query.limit : 10,
                page: $route.query.page ? $route.query.page : 1,
                date: $route.query.date ? $route.query.date : null,
                start_date: $route.query.start_date ? $route.query.start_date : null,
                end_date: $route.query.end_date ? $route.query.end_date : null,
                domicile_city_id: $route.query.domicile_city_id ? $route.query.domicile_city_id : null,
                company_office_id: $route.query.company_office_id ? $route.query.company_office_id : null,
                worker_name: $route.query.worker_name ? $route.query.worker_name : '',
                fee_normal_unit: $route.query.fee_normal_unit ? $route.query.fee_normal_unit : null,
                sort: $route.query.sort ? $route.query.sort : '-1'
            },
            images: PREFIX_IMAGE,
            selected_city: 'all',
            company_office: 'all',
            disabled_start_date: {
                to: new Date('2020','07','01') // 1 August 2020
            },
            cities: [],
            filtered_offices: [],
            selected_data: null,
            generateMode: false,
            selected_item: [],
            batchName: null
        };
    },
    components: {
        EmptyTable,
        Pagination,
        Datetime
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            recaps: 'salary/recaps'
        }),
        user_cred() {
            return getUserLogin();
        },
        cityId() {
            let id = null;

            if (this.selected_city && this.selected_city._id) {
                id = this.selected_city._id;
            }

            return id;
        },
        officeId() {
            let id = null;

            if (this.company_office && this.company_office._id) {
                id = this.company_office._id;
            }

            return id;
        },
        cityName() {
            let name = 'All Cities';

            if ((this.meta.domicile_city_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((office) => {
                    if (
                        (office && office.domicile_city_id && office.domicile_city_id._id) &&
                        (office.domicile_city_id._id === this.meta.domicile_city_id)
                    ) {
                        return office;
                    }
                });

                if (findCity && findCity.domicile_city_id && findCity.domicile_city_id.name) {
                    name = findCity.domicile_city_id.name;
                }
            }

            return name;
        },
        officeName() {
            let name = 'All Offices';

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findOffice = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.meta.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findOffice && findOffice.name) {
                    name = findOffice.name;
                }
            }

            return name;
        }
    },
    watch: {
        meta: {
            handler(){
                if (this.meta.date || (this.meta.start_date && this.meta.end_date)) {
                    this.getRecaps(this.meta.end_date ? {...this.meta} : {...this.meta, end_date: moment.utc(this.meta.end_date).add(1, 'days').endOf('day').toISOString()});
                }
            },
            deep: true
        }
    },
    beforeMount() {
        if(window.UIkit.modal('.filter')) window.UIkit.modal('.filter').$destroy(true);
        if(window.UIkit.modal('.generate')) window.UIkit.modal('.generate').$destroy(true);
        if(window.UIkit.modal('.confirmation')) window.UIkit.modal('.confirmation').$destroy(true);
    },
    async mounted() {
        await this.getCompanyOffice();
        this.setCity();
        if (this.$route.query.fee_normal_unit) {
            this.mapQuery();
            await this.getRecaps(this.meta.end_date ? {...this.meta} : {...this.meta, end_date: moment.utc(this.meta.end_date).add(1, 'days').endOf('day').toISOString()});
            this.isShow = true;
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getRecaps: 'salary/getRecaps',
            postGenerateSalary: 'salary/postGenerateSalary',
            postTransferSalary: 'salary/postTransferSalary',
        }),
        detailCandidate(data) {
            if (data.fee_normal_unit == "Month") {
                data.start_date = moment(data.date).startOf('month').toISOString();
                data.end_date = moment(data.date).endOf('month').toISOString();
            } else {
                delete data.date;
            }
            this.$router.push({ path: `salary/detail`, query: data });
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        selectCompanyOffice(){
            this.company_office = this.company_offices.length > 0 ? this.company_offices[0] : 'n/a';
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'filter':
                await window.UIkit.modal('#filter').hide();
                break;
            case 'generate':
                await window.UIkit.modal('#generate').hide();
                break;
            case 'confirmation':
                await window.UIkit.modal('#confirmation').hide();
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'filter':
                this.setCity();
                this.filterOffice();
                this.mapQuery();
                await window.UIkit.modal('#filter').show();
                break;
            case 'generate':
                if (this.selected_item.length < 1) {
                    return notificationDangerCustom('Record must be selected at least one item');
                } else {
                    await window.UIkit.modal('#generate').show();
                }
                break;
            case 'confirmation':
                if (!this.batchName) {
                    return notificationDangerCustom('Salary Report Name must be filled');
                }
                await window.UIkit.modal('#confirmation').show();
                break;
            }
        },
        setCity() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }

            this.filtered_offices = [];
        },
        filterOffice() {
            this.company_office = 'all';

            if (this.company_offices && this.company_offices.length > 0) {
                const offices = this.company_offices.filter((office) => {
                    if (
                        (office.domicile_city_id && office.domicile_city_id._id) && 
                        (this.selected_city && this.selected_city._id) &&
                        (office.domicile_city_id._id === this.selected_city._id)
                    ) {
                        return office;
                    }
                });
                this.filtered_offices = offices;
            }

            if (this.filtered_offices.length > 0) {
                this.filtered_offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        async applyFilter() {
            if (!this.meta.fee_normal_unit) {
                return notificationDangerCustom('"Fee Unit" must be filled');
            } else if (this.meta.fee_normal_unit == "Month" && !this.meta.date) {
                return notificationDangerCustom('"For (Month)" must be filled');
            } else if (this.meta.fee_normal_unit != "Month" && (!this.meta.start_date || !this.meta.end_date)) {
                return notificationDangerCustom('All Date must be filled');
            } else {
                this.meta.date = moment(this.meta.date).toISOString();
                this.meta.domicile_city_id = this.cityId;
                this.meta.company_office_id = this.officeId;
                this.isShow = true;
                this.$store.state.selected_company = this.company_office;
                await this.hideModal('filter');
                this.$router.push({ path: `salary`, query: this.meta });
            }
        },
        resetFilter() {
            this.meta.fee_normal_unit = null;
            this.meta.company_office_id = null;
            this.meta.domicile_city_id = null;
            this.selected_city = 'all';
            this.company_office = 'all';
            this.cities = [];
            this.filtered_offices = [];
            this.$store.state.selected_company = '';
            this.meta.date = null;
            this.meta.start_date = null;
            this.meta.end_date = null;
            this.isShow = false;
            this.$router.push({ path: `salary` });
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        hideYearMonth() {
            const dateHide = document.getElementsByClassName("vdatetime-popup__date");
            dateHide[0].style.display = 'none';
            const yearHide = document.getElementsByClassName("vdatetime-popup__year");
            yearHide[0].style.display = 'none';
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        async generateSalary() {
            const response = await this.postGenerateSalary({
                name: this.batchName, 
                selected_items: this.selected_item, 
                start_date: this.meta.fee_normal_unit == "Month" ? moment(this.meta.date).startOf('month').toISOString() : this.meta.start_date,
                end_date: this.meta.fee_normal_unit == "Month" ? moment(this.meta.date).endOf('month').toISOString() : this.meta.end_date
            });
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('confirmation');
                this.generateMode = false;
                await this.getRecaps(this.meta);
            } else {
                notificationDangerCustom('Failed to generate salary!');
            }
        },
        async transferSalary(data) {
            if (!data.reference) {
                return notificationDangerCustom('"Reference Number" must be filled');
            }
            const response = await this.postTransferSalary(data);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('transfer');
                this.batchName = null;
                this.selected_item = [];
                await this.getRecaps(this.meta);
            } else {
                notificationDangerCustom('Failed to transfer salary!');
            }
        },
        addItem(data) {
            const exist = this.selected_item.some((item) => ((item.user_id === data.user_id) && (item.job_partner_id === data.job_partner_id) && (item.company_office_id === data.company_office_id)));
            if (exist) {
                this.selected_item = this.selected_item.filter((item) => item.user_id != data.user_id 
                || item.job_partner_id != data.job_partner_id || item.company_office_id != data.company_office_id);
            } else {
                this.selected_item.push(data);
            }
        },
        addAllItem() {
            if (this.recaps.docs.length > 0) {
                for (const iterator of this.recaps.docs) {
                    const exist = this.selected_item.some((item) => ((item.user_id === iterator._id.user_id) && (item.job_partner_id === iterator._id.job_partner_id) 
                    && (item.company_office_id === iterator._id.company_office_id)));
                    if (exist) {
                        this.selected_item = this.selected_item.filter((item) => item.user_id != iterator._id.user_id 
                        || item.job_partner_id != iterator._id.job_partner_id || item.company_office_id != iterator._id.company_office_id);
                    } else {
                        this.selected_item.push({
                            user_id: iterator._id.user_id,
                            job_partner_id: iterator._id.job_partner_id,
                            company_office_id: iterator._id.company_office_id,
                            daily_recap_ids: iterator.daily_recap_ids,
                            subtraction_adjustment_ids: iterator.subtraction_adjustment_ids,
                            addition_adjustment_ids: iterator.addition_adjustment_ids,
                            expense_ids: iterator.expense_ids,
                            income: iterator.income,
                            expense: iterator.expense
                        });
                    }
                }
            }
        },
        findCheckedItem(data) {
            return !!this.selected_item.find((item) => item.user_id === data.user_id && item.job_partner_id === data.job_partner_id && item.company_office_id === data.company_office_id);
        },
        findCheckedAllItem() {
            if (this.recaps.docs.length < 1) {
                return false;
            } else {
                if (this.selected_item.length < 1) {
                    return false;
                } else {
                    let allSelected = false;
                    for (const iterator of this.recaps.docs) {
                        allSelected = this.selected_item.find((item) => item.user_id === iterator._id.user_id && item.job_partner_id === iterator._id.job_partner_id 
                        && item.company_office_id === iterator._id.company_office_id);
                    }
                    return allSelected;
                }
            }
        },
        mapQuery() {
            if ((this.$route.query.domicile_city_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((office) => {
                    if (
                        (office && office.domicile_city_id && office.domicile_city_id._id) &&
                        (office.domicile_city_id._id === this.$route.query.domicile_city_id)
                    ) {
                        return office;
                    }
                });

                if (
                    (findCity && findCity.domicile_city_id) && 
                    (findCity.domicile_city_id._id && findCity.domicile_city_id.name)
                ) {
                    const city = {
                        _id: findCity.domicile_city_id._id,
                        name: findCity.domicile_city_id.name
                    };

                    this.selected_city = city;
                }
            }

            if ((this.$route.query.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findOffice = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.$route.query.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findOffice) {
                    this.company_office = findOffice;
                }
            }
        }
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.report-download{
    font-family: 'Roboto';
    color: #5FD855;
    font-size: 20px;
}
</style>
