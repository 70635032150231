<template>
    <tr>
        <!-- Actions -->
        <td class="uk-table-expand uk-text-middle sticky-col first-col">
            <input 
                v-if="showCheckbox"
                type="checkbox" 
                class="uk-checkbox uk-border-rounded uk-margin-small-right"
                :checked="selectedApplicant"
                @change="onChangeSelectApplicant"
            >
        </td>
        <!-- End Actions -->
        <td class="uk-table-expand uk-text-middle sticky-col second-col">
            <div class="uk-flex uk-flex-row">
                <a href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="viewDetailApplicant(applicant)">
                    <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/visibility.svg`" alt="">
                </a>
                <span v-if="button_download_cv_loading" class="uk-margin-small-right">
                    <div uk-spinner="ratio: 0.5"></div>
                </span>
                <a v-else href="javascript:void(0);" class="uk-flex uk-flex-middle" @click="downloadCV({ userId: applicant.user._id })">
                    <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/cloud-download.svg`" alt="">
                </a>
                <div v-if="applicant && [0,1,4,5,3].includes(applicant.status)">
                    <span v-if="disabledPickupApplicantButton && applicant.status === 0">
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right-disabled.svg`" width="20" alt="">
                    </span>
                    <a 
                        v-else-if="applicant && applicant.status === 0" 
                        href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                        @click="moveUser({state: 1, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                    >
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right.svg`" width="20" alt="">
                    </a>
                    <span v-else-if="disabledInviteToInterviewButton && applicant.status === 1">
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right-disabled.svg`" width="20" alt="">
                    </span>
                    <a 
                        v-else-if="applicant && applicant.status === 1" 
                        href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                        @click="moveUser({state: 4, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                    >
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right.svg`" width="20" alt="">
                    </a>
                    <span v-else-if="disabledInterviewedApplicant && applicant.status === 4">
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right-disabled.svg`" width="20" alt="">
                    </span>
                    <a 
                        v-else-if="applicant && applicant.status === 4" 
                        href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                        @click="moveUser({state: 5, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                    >
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-right.svg`" width="20" alt="">
                    </a>
                    <span v-else-if="disabledPickupApplicantButton && applicant.status === 3">
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-left-disabled.svg`" width="20" alt="">
                    </span>
                    <a 
                        v-else-if="applicant && applicant.status === 3" 
                        href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                        @click="moveUser({state: 1, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                    >
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/arrow-left.svg`" width="20" alt="">
                    </a>
                </div>
                <div v-if="applicant && [0,1,4,5,3].includes(applicant.status)">
                    <span v-if="disabledSendContractButton && [0,1,4,5].includes(applicant.status)">
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/forward-disabled.svg`" width="20" alt="">
                    </span>
                    <a 
                        v-else-if="applicant && [0,1,4,5].includes(applicant.status)" 
                        href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                        @click="moveUser({state: 6, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                    >
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/forward.svg`" width="20" alt="">
                    </a>
                    <span v-if="disabledInviteToInterviewButton && [3].includes(applicant.status)">
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/backward-disabled.svg`" width="20" alt="">
                    </span>
                    <a 
                        v-else-if="applicant && [3].includes(applicant.status)" 
                        href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                        @click="moveUser({state: 4, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                    >
                        <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/backward.svg`" width="20" alt="">
                    </a>
                </div>
                <div v-if="applicant && [0,1,4,5].includes(applicant.status)">
                    <span v-if="disabledRejectApplicantButton && [0,1,4,5].includes(applicant.status)">
                        <img class="uk-preserve-width" :src="`${images}/job_applicant/reject-disabled.svg`" width="20" alt="">
                    </span>
                    <a 
                        v-else-if="applicant && [0,1,4,5].includes(applicant.status)" 
                        href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                        @click="moveUser({state: 3, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                    >
                        <img class="uk-preserve-width" :src="`${images}/job_applicant/reject.svg`" width="20" alt="">
                    </a>
                </div>
            </div>
        </td>
        <td class="uk-table-expand uk-text-middle sticky-col third-col">
            <p
                class="uk-margin-remove text-f14-l21 uk-text-bold uk-text-default"
            >
                {{ applicant.user.fullname || '-' }}
            </p>
            <p
                class="uk-margin-remove text-f12-l18 uk-text-default"
            >
                {{  applicant.user.domicile.name ?
                    toTitleCase({ text: applicant.user.domicile.name }) : '-'
                }}
            </p>
            <p
                class="uk-margin-remove myrobin-text-949494 text-f12-l18"
            >
                {{
                    getAgeAndGender({ age: applicant.user_age, gender: applicant.user.gender })
                }}
            </p>
        </td>
        <td class="uk-table-expand uk-text-middle sticky-col fourth-col">
            <div class="uk-flex uk-flex-row">
                <a 
                    href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                    @click="copyText({value: applicant.user.phone_number, text: 'Phone Number has been copied to clipboard.'})"
                >
                    <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/detail/telephone-black.svg`" width="25" alt="">
                </a>
                <a 
                    href="javascript:void(0);" class="uk-flex uk-flex-middle" 
                    @click="copyText({value: applicant.user.email, text: 'Email has been copied to clipboard.'})"
                >
                    <img class="uk-preserve-width uk-margin-small-right" :src="`${images}/job_applicant/detail/email-black.svg`" width="25" alt="">
                </a>
            </div>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <p
                class="uk-margin-remove uk-text-bold uk-text-default"
                style="font-size: 16px"
            >
                <a href="javascript:void(0)" @click="gotoJobDetail(applicant.job._id)" class="uk-text-bold uk-text-primary">{{
                    applicant.job.title || '-'
                }}</a>
            </p>
            <p
                class="uk-margin-remove uk-text-normal text-f12-l18 uk-text-default"
            >
                {{
                    applicant.job.company || '-'
                }}
            </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <div
                class="uk-text-bold uk-border-rounded uk-text-center label-status"
                :class="[
                    [0].includes(applicant.status)
                        ? 'bg-warning'
                        : '',
                    [1, 4, 5].includes(applicant.status)
                        ? 'bg-primary'
                        : '',
                    [6, 7].includes(applicant.status)
                        ? 'bg-success'
                        : '',
                    [3].includes(applicant.status)
                        ? 'bg-danger'
                        : '',
                    applicant.partner ? 'bg-danger': ''
                ]"
            >
                {{
                    applicant.partner ? 'Accepted' :
                    convertStatusApplicant({
                        status: applicant.status
                    })
                }}
            </div>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <div
                class="uk-text-bold uk-border-rounded uk-text-center label-status"
                :class="[
                    applicant.initiation.status === 0
                        ? 'bg-vacancy-success'
                        : '',
                    applicant.initiation.status === 1
                        ? 'bg-vacancy-warning'
                        : '',
                    applicant.initiation.status === 2
                        ? 'bg-vacancy-danger'
                        : ''
                ]"
            >
                {{
                    convertStatusVacancy({
                        status: applicant.initiation.status
                    })
                }}
            </div>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <p class="text-f14-l21 uk-text-default uk-text-400">{{ applicant.user.identity_number || '-'}}</p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <p class="text-f14-l21 uk-text-default uk-text-400">
                {{
                    applicant.user.education_level &&
                    applicant.user.education_level.name
                        ? applicant.user.education_level
                                .name
                        : '-'
                }}
            </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <div
                v-if="
                    applicant &&
                    applicant.cv &&
                    applicant.cv.work_experiences &&
                    applicant.cv.work_experiences.length > 0
                "
                class="uk-flex uk-flex-column uk-width-auto"
            >
                <p
                    class="uk-margin-remove text-f14-l21 uk-text-bold uk-text-default"
                >
                    {{ applicant.cv.work_experiences[0].title || '-' }}
                </p>
                <p
                    class="uk-margin-remove text-f12-l18 uk-text-default"
                >
                    {{
                        applicant.cv.work_experiences[0]
                            .company_name || '-'
                    }}
                </p>
                <p
                    class="uk-margin-remove myrobin-text-949494 text-f12-l18"
                >
                    {{
                        applicant.cv.work_experiences[0]
                            .start_date || ' -' | moment('MMMM YYYY')
                    }}
                    -
                    {{
                        applicant.cv.work_experiences[0].present
                            ? 'Present'
                            : applicant.cv.work_experiences[0]
                                    .end_date || ' -'
                                | moment('MMMM YYYY')
                    }}
                </p>
                <p
                    class="uk-margin-remove text-f12-l18 uk-text-default"
                >
                    {{
                        applicant &&
                        applicant.cv &&
                        applicant.cv.work_experiences &&
                        applicant.cv.work_experiences.length > 0
                            ? getYearsExperiences({
                                    data: applicant.cv
                                        .work_experiences,
                                    limit: 1
                                })
                            : '-'
                    }}
                </p>
            </div>
            <div
                v-else
                class="uk-flex uk-flex-column uk-width-auto"
            >
                <p
                    class="uk-margin-remove text-f12-l24 uk-text-default"
                >
                    {{ '-' }}
                </p>
            </div>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <p
                class="uk-margin-remove text-f14-l21 uk-text-default"
            >
                {{
                    applicant &&
                    applicant.cv &&
                    applicant.cv.work_experiences &&
                    applicant.cv.work_experiences.length > 0
                        ? getYearsExperiences({
                                data: applicant.cv
                                    .work_experiences
                            })
                        : '-'
                }}
            </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <a
                v-if="applicant && applicant.cv && applicant.cv.ktp"
                href="javascript:void(0)"
                class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"
                @click="openImage({file_url: applicant.cv.ktp.document_url.file_url, title: 'KTP'})"
                >View</a
            >
            <p v-else class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"> - </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <a
                v-if="applicant && applicant.cv && applicant.cv.kk"
                href="javascript:void(0)"
                class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"
                @click="openImage({file_url: applicant.cv.kk.document_url.file_url, title: 'KK'})"
                >View</a
            >
            <p v-else class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"> - </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <a
                v-if="applicant && applicant.cv && applicant.cv.sim"
                href="javascript:void(0)"
                class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"
                @click="openImage({file_url: applicant.cv.sim.document_url.file_url, title: 'SIM'})"
                >View</a
            >
            <p v-else class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"> - </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <a
                v-if="applicant && applicant.cv && applicant.cv.ijazah"
                href="javascript:void(0)"
                class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"
                @click="openImage({file_url: applicant.cv.ijazah.document_url.file_url, title: 'Ijazah'})"
                >View</a
            >
            <p v-else class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"> - </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <a
                v-if="applicant && applicant.cv && applicant.cv.skck"
                href="javascript:void(0)"
                class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"
                @click="openImage({file_url: applicant.cv.skck.document_url.file_url, title: 'SKCK'})"
                >View</a
            >
            <p v-else class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"> - </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <a
                v-if="applicant.job.additional_docs.length > 0"
                href="javascript:void(0)"
                class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"
                @click="openAdditionalDocModal"
                >View Additional Documents</a
            >
            <p v-else class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"> - </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <a
                v-if="applicant.question_answers.length > 0"
                href="javascript:void(0)"
                class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"
                @click="openAdditionalQuestion(applicant.question_answers)"
                >View Additional Questions</a
            >
            <p v-else class="uk-text-bold text-f14-l21 uk-text-primary uk-text-bold"> - </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <p class="uk-margin-remove text-f14-l21 uk-text-default uk-text-500">
                {{
                    applicant.apply_date
                        ? applicant.apply_date
                        : ' -' | moment('DD MMM YYYY')
                }}
            </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <p class="uk-margin-remove text-f14-l21 uk-text-default uk-text-500">
                {{
                    applicant &&
                    applicant.initiation &&
                    applicant.initiation.start_date
                        ? applicant.initiation.start_date
                        : ' -' | moment('D MMM YYYY')
                }}
                -
                {{
                    applicant &&
                    applicant.initiation &&
                    applicant.initiation.due_date
                        ? applicant.initiation.due_date
                        : ' -' | moment('D MMM YYYY')
                }}
            </p>
        </td>
        <td class="uk-table-expand uk-text-middle myrobin-background-white">
            <p class="uk-margin-remove text-f14-l21 uk-text-default uk-text-500">
                {{ applicant.apply_from || '-' }}
            </p>
        </td>
    </tr>
</template>

<script>
import formatter from '../../../../../utils/formatter';
import { PREFIX_IMAGE } from '@/utils/constant';
export default {
    name: 'TableView',
    props: {
        applicant: {
            type: Object,
            required: true,
            default: null
        },
        showCheckbox: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedApplicant: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledViewProfileButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledDownloadCvButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledPickupApplicantButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInviteToInterviewButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInterviewedApplicant: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledSendContractButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledRejectApplicantButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            button_download_cv_loading: false,
            formatter: formatter,
            images: PREFIX_IMAGE,
        };
    },
    methods: {
        toTitleCase({ text }) {
            return formatter.toTitleCase(text);
        },
        getYearAndMonth({ months }) {
            let m = months % 12;
            let y = (months - m) / 12;

            return `${y} Years ${m} Months`;
        },
        getYearsExperiences({ data, limit = null }) {
            let month = 0;
            let r = '';
            let length = limit ? limit : data.length;
            for (let i = 0; i < length; i++) {
                const lastDate = data[i].end_date
                    ? data[i].end_date
                    : new Date();
                month += formatter.diffMonths(data[i].start_date, lastDate);
            }
            r = this.getYearAndMonth({ months: month });
            return r;
        },
        convertStatusApplicant({ status }) {
            switch (status) {
            case 0:
                return 'New Applicant';
            case 1:
                return 'Picked up Applicant';
            case 3:
                return 'Rejected';
            case 4:
                return 'Invited to Interview';
            case 5:
                return 'Interviewed Applicant';
            case 6:
                return 'Contract Sent';
            case 7:
                return 'Signed Applicant';
            }
        },
        convertStatusVacancy({ status }) {
            switch (status) {
            case 0:
                return 'In Progress';
            case 1:
                return 'Submitted';
            case 2:
                return 'Completed/Closed';
            }
        },
        downloadCV({ userId }) {
            this.button_download_cv_loading = true;
            this.$emit('download-cv', {
                userId,
                done: () => {
                    this.button_download_cv_loading = false;
                }
            });
        },
        moveUser({state, job_apply_id, office_id}){
            this.$emit('change-status', {state, job_apply_id, office_id});
        },
        viewDetailApplicant(user) {
            let route = this.$router.resolve({path: `/admin/job_applicant/candidate/detail/${user._id}`});
            window.open(route.href, '_blank');
        },
        gotoJobDetail(job_id){
            this.$router.push({path: `/admin/job_applicant/detail/${this.$route.params.province_id}/${job_id}`});
        },
        openAdditionalQuestion(question){
            this.$emit('open-additional-question', question);
        },
        redirectToDetailDocument(user){
            let route = this.$router.resolve({path: `/admin/job_applicant/candidate/detail/${user._id}`, query: {accordion: 'document-qualifications'}});
            window.open(route.href, '_blank');
        },
        openAdditionalDocModal(){
            this.$emit('open-additional-docs', this.applicant);
        },
        onChangeSelectApplicant() {
            this.$emit('select-applicant', { _id: this.$props.applicant?._id });
        },
        openImage({file_url, title}){
            this.$emit('open-image', {file_url, title});
        },
        getAgeAndGender({age, gender}){
            const cage = age ? age : 0;
            const cgender = gender == 'male' ? 'Laki - laki': 'Perempuan';
            const message = `${cage} Tahun • ${cgender}`;
            return message;
        },
        copyText({value, text}) {
            this.$emit('copy', {value, text});
        }
    }
};
</script>
<style scoped>
.sticky-col-th {
  position: -webkit-sticky;
  position: sticky;
  background-color: #0abab5;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: #FFF;
}

.first-col {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  left: 0px;
}

.second-col {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  left: 30px;
}

.third-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 200px;
}

.fourth-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 330px;
}

.label-status {
    width: fit-content;
    padding: 4px 8px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}
.bg-warning {
    background: #ffe500;
}
.bg-primary {
    color: white;
    background: #3586ff;
}
.bg-success {
    color: white;
    background: #00b500;
}
.bg-danger {
    color: white;
    background: #df5c70;
}
.bg-vacancy-success {
    color: white;
    background: #22cc8e;
}
.bg-vacancy-warning {
    color: white;
    background: #febe10;
}
.bg-vacancy-danger {
    color: white;
    background: #df5c70;
}
</style>
