<template>
    <div>
        <div class="uk-flex uk-flex-between myrobin-background-white uk-padding-small">
            <div class="uk-flex uk-flex-middle">
                <p class="uk-text-bold uk-margin-remove">Mitra Data Validation</p>
            </div>
            <div class="uk-flex uk-flex-middle" style="gap: 10px;">
                <button
                    type="button"
                    class="uk-button uk-border-rounded myrobin-background-white myrobin-color-green myrobin-border-green uk-position-relative uk-width-auto"
                    @click="$emit('cancel-validation')"
                >Cancel</button>
                <button
                    type="button"
                    class="uk-button myrobin-background-primary myrobin-color-white uk-border-rounded uk-position-relative uk-width-auto"
                    @click="saveValidation()"
                >Save</button>
            </div>
        </div>
        <div class="uk-width-1-1 uk-card uk-card-default uk-margin-top">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data, id) in goldDataset" :key="id">
                            <td class="uk-text-left uk-text-nowrap">{{ data.label }}</td>
                            <td class="uk-text-left uk-text-nowrap">
                                <div v-if="data.type === 'select'" class="uk-width-expand">
                                    <select v-if="data.value == 'bank_name'" v-model="form[data.value]" class="uk-select">
                                        <option v-for="option in bankOptions[form.payment_method]" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <select v-else v-model="form[data.value]" class="uk-select" :disabled="data.value == 'payment_method'">
                                        <option v-for="option in data.options" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                                <input v-else v-model="form[data.value]" class="uk-input" type="text" disabled placeholder="-">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>Account Book Photo (from PWA)</div>
                                <p class="uk-text-italic text-color-blue">Account Book Photo by MDM</p>
                            </td>
                            <td>
                                <div v-if="data.bank_account_mitra && data.bank_account_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.bank_account_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.bank_account_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ data.bank_account_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                                <p>
                                    <input v-model="form.bank_account_mdm" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>KK Number</td>
                            <td>
                                <input v-model="form.kk_number" class="uk-input" type="text">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>KK Photo (from PWA)</div>
                                <p class="uk-text-italic text-color-blue">KK Photo by MDM</p>
                            </td>
                            <td>
                                <div v-if="data.kk_mitra && data.kk_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.kk_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.kk_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ data.kk_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                                <p>
                                    <input v-model="form.kk_mdm" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>SKCK Photo (from PWA)</div>
                                <p class="uk-text-italic text-color-blue">SKCK Photo by MDM</p>
                            </td>
                            <td>
                                <div v-if="data.skck_mitra && data.skck_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.skck_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.skck_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ data.skck_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                                <p>
                                    <input v-model="form.skck_mdm" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { BANK_LIST, EWALLET_LIST} from '@/utils/constant';
import { notificationSuccess, notificationDangerCustom } from "@/utils/notification";
import PreviewImageModal from '@/components/globals/modals/ImageViewer.vue';

export default {
    name: 'GoldValidationTableEdit',
    components: {
        PreviewImageModal,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            form: { ...this.$props.data },
            goldDataset: [
                {
                    label: 'Email Address',
                    value: 'email'
                },
                {
                    label: 'Marital Status',
                    type: 'select',
                    value: 'marital_status',
                    options: [
                        { value: null, text: "Pilih status pernikahan:" },
                        { value: "married", text: "Kawin" },
                        { value: "single", text: "Belum Kawin" }
                    ]
                },
                {
                    label: 'Main Payment Method',
                    type: 'select',
                    value: 'payment_method',
                    options: [
                        { value: 0, text: "Pilih Tipe Pembayaran:" },
                        { value: 1, text: "Bank" },
                        { value: 2, text: "E-Wallet" }
                    ],
                },
                {
                    label: 'Main Bank/E-wallet Name',
                    // type: 'select',
                    value: 'bank_name'
                },
                {
                    label: 'Main Bank/E-wallet Number',
                    value: 'bank_account_number'
                },
                {
                    label: 'Main Bank/E-wallet Account Name',
                    value: 'bank_account_name'
                },
            ],
            bankOptions: {
                0: [],
                1: BANK_LIST,
                2: EWALLET_LIST,
            },
            previewSrc: null,
            modal: {
                isShowPreviewImageModal: false,
            },
        };
    },
    methods: {
        ...mapActions({
            validateKYCDataGold: 'kyc/validateKYCDataGold',
        }),
        async saveValidation() {
            const payload = { id: this.$route.params.id };
            if (this.form.marital_status) {
                payload.marital_status = this.form.marital_status;
            }
            // if (this.form.payment_method) {
            //     payload.payment_method = this.form.payment_method;
            // }
            // if (this.form.bank_name) {
            //     payload.bank_name = this.form.bank_name;
            // }
            // if (this.form.bank_account_number) {
            //     payload.bank_account_number = this.form.bank_account_number;
            // }
            // if (this.form.bank_account_name) {
            //     payload.bank_account_name = this.form.bank_account_name;
            // }
            if (this.form.bank_account_mdm) {
                payload.bank_account_mdm = this.form.bank_account_mdm;
            }
            if (this.form.kk_number) {
                payload.kk_number = this.form.kk_number;
            }
            if (this.form.kk_mdm) {
                payload.kk_mdm = this.form.kk_mdm;
            }
            if (this.form.skck_mdm) {
                payload.skck_mdm = this.form.skck_mdm;
            }

            const response = await this.validateKYCDataGold(payload);
            if (response && response.status == 'OK') {
                notificationSuccess('Mitra gold data validated!');
            } else {
                notificationDangerCustom('Failed to validate mitra gold data.');
            }

            this.$emit('save-validation');
        },
        previewImage({ file_url }) {
            this.previewSrc = file_url;
            this.modal.isShowPreviewImageModal = true;
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
    color: white !important;
}
tbody, input {
    color: #000 !important;
}
input:disabled, select:disabled {
    background-color: #e5e5e5 !important;
}
.myrobin-color-white {
    color: #ffffff;
}
</style>
