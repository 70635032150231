import XLSX from 'xlsx';

const excelDownloader = (json_data, filename) => {
    const data = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    XLSX.writeFile(wb,`${filename}`);
};

const excelDownloaderWithAdditionalSheet = (json_data, additional_data, filename) => {
    const data = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    // additional data is objects, the key would be the sheet name and the value of key should be array of objects
    for (const key in additional_data) {
        if (additional_data[key].data && additional_data[key].data.length > 0) {
            const newDataSheet = XLSX.utils.json_to_sheet(additional_data[key].data);
            XLSX.utils.book_append_sheet(wb, newDataSheet, additional_data[key].sheet_name);
        }
    }
    XLSX.writeFile(wb,`${filename}`);
};

const detailWorkerImgChecker = (oldimg, isEdit, newImg) => {
    let imgMime = null;

    if(oldimg && !isEdit) {
        const splitImg = oldimg.split(".");
        imgMime = splitImg[splitImg.length - 1];
    } else if(isEdit) {
        const splitImg = newImg.type.split("/");
        imgMime = splitImg[splitImg.length - 1];
    }
        
    if (imgMime && ["png", "jpg", "jpeg", "svg", "gif"].includes(imgMime)) {
        return oldimg;
    }

    return "/images/documents-icon.png";
};

const contentType = (url) => {
    const contentType = url.split(".").slice(-1)[0];

    if (['doc', 'docx'].includes(contentType)) {
        return "isShowPreviewOfficeModal";
    } else if (['pdf'].includes(contentType)) {
        return "isShowPreviewPDFModal";
    } else {
        return "isShowPreviewImageModal";
    }
};

const sortString = (data, column, type) => {
    return data.sort((a, b) => {
        let fa = a[column].toString().toLowerCase(),
            fb = b[column].toString().toLowerCase();
    
        if (type === 'asc') {
            return -fa.localeCompare(fb);
        } else {
            return fa.localeCompare(fb);
        }
    });
};

export {
    excelDownloader, detailWorkerImgChecker, sortString, contentType, excelDownloaderWithAdditionalSheet
};
