<template>
    <div>
        <div class="uk-flex uk-flex-between myrobin-background-white uk-padding-small">
            <div class="uk-flex uk-flex-middle">
                <p class="uk-text-bold uk-margin-remove">Mitra Data Validation</p>
            </div>
            <div class="uk-flex uk-flex-middle" style="gap: 10px;">
                <button
                    v-if="validated.is_valid"
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('READ', 'worker-kyc')
                        ? 'myrobin-background-white myrobin-border-green myrobin-color-green'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('READ', 'worker-kyc'))"
                    @click="$emit('read-validation-notes')"
                >View Gold Notes</button>
                <button
                    v-if="validated.is_valid"
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'myrobin-background-white myrobin-border-blue myrobin-color-blue'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                    @click="$emit('unlabel')"
                >Unlabel This Mitra as Gold</button>
                <button
                    v-else
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'uk-button-primary myrobin-color-white'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                    @click="$emit('validate')"
                >Label This Mitra as Gold</button>
                <button
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="$can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc')
                        ? 'myrobin-background-primary myrobin-color-white'
                        : 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                    "
                    @click="$emit('is-validating');"
                    :disabled="!($can('WRITE', 'worker-kyc') || $can('EDIT', 'worker-kyc'))"
                >Validate</button>
            </div>
        </div>
        <div class="uk-width-1-1 uk-card uk-card-default uk-margin-top">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <template v-if="tableLoading">
                        <LoadingTable :colspan="2" />
                    </template>
                    <template v-else>
                        <tbody>
                            <tr v-for="(data, id) in goldDataset" :key="id" :class="{ 'myrobin-background-pink' : !data.fill }">
                                <td class="uk-text-left uk-text-nowrap">{{ data.field }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data.fill || '-' }}</td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.bank_account_mitra || !data.bank_account_mdm }">
                                <td>
                                    <div>Account Book Photo (from PWA)</div>
                                    <p class="uk-text-italic text-color-blue">Account Book Photo by MDM</p>
                                </td>
                                <td>
                                    <div v-if="data.bank_account_mitra && data.bank_account_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.bank_account_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.bank_account_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.bank_account_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                    <p class="text-color-blue">{{ data.bank_account_mdm || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.kk_number }">
                                <td>KK Number</td>
                                <td>{{ data.kk_number || '-' }}</td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.kk_mitra || !data.kk_mdm }">
                                <td>
                                    <div>KK Photo (from PWA)</div>
                                    <p class="uk-text-italic text-color-blue">KK Photo by MDM</p>
                                </td>
                                <td>
                                    <div v-if="data.kk_mitra && data.kk_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.kk_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.kk_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.kk_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                    <p class="text-color-blue">{{ data.kk_mdm || '-' }}</p>
                                </td>
                            </tr>
                            <tr :class="{ 'myrobin-background-pink' : !data.skck_mitra || !data.skck_mdm }">
                                <td>
                                    <div>SKCK Photo (from PWA)</div>
                                    <p class="uk-text-italic text-color-blue">SKCK Photo by MDM</p>
                                </td>
                                <td>
                                    <div v-if="data.skck_mitra && data.skck_mitra.file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data.skck_mitra.file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data.skck_mitra.file_url })"
                                        >
                                        <span class="uk-text-truncate myrobin-color-grey">{{ data.skck_mitra.file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                    <p class="text-color-blue">{{ data.skck_mdm || '-' }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </div>
        </div>

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
    </div>
</template>

<script>
import PreviewImageModal from '@/components/globals/modals/ImageViewer.vue';

export default {
    name: 'GoldValidationTableView',
    components: {
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        PreviewImageModal,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        validated: {
            type: Object,
            required: false,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            previewSrc: null,
            modal: {
                isShowPreviewImageModal: false,
            },
        };
    },
    computed: {
        goldDataset() {
            let paymentMethod = '';
            if (this.data.payment_method == 1) {
                paymentMethod = 'Bank';
            } else if (this.data.payment_method == 2) {
                paymentMethod = 'E-Wallet';
            }

            return [
                {
                    field: 'Email Address',
                    fill: this.data.email
                },
                {
                    field: 'Marital Status',
                    fill: this.data.marital_status ? this.getMaritalStatus(this.data.marital_status) : ''
                },
                {
                    field: 'Main Payment Method',
                    fill: paymentMethod
                },
                {
                    field: 'Main Bank/E-wallet Name',
                    fill: this.data.bank_name
                },
                {
                    field: 'Main Bank/E-wallet Number',
                    fill: this.data.bank_account_number
                },
                {
                    field: 'Main Bank/E-wallet Account Name',
                    fill: this.data.bank_account_name
                },
            ];
        },
    },
    methods: {
        getMaritalStatus(value) {
            switch (value) {
            case "married":
                return "Kawin";
            default:
                return "Belum Kawin";
            }
        },
        previewImage({ file_url }) {
            this.previewSrc = file_url;
            this.modal.isShowPreviewImageModal = true;
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
    color: white !important;
}
tbody, input {
    color: #000 !important;
}
.myrobin-color-white {
    color: #ffffff;
}
.myrobin-color-green {
    color: #0abab5;
}
.myrobin-color-blue {
    color: #0275d8;
}
</style>
