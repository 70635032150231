import {getUserLogin} from "@/utils/auth";
import moment from "moment";

moment.locale("id");

const formatter = {};
formatter.getFullname = (user) => {
    const data = user || getUserLogin();
    return data.fullname || "n/a";
};

formatter.getUserRole = () => {
    let role = "";
    if (getUserLogin().client_role == "null") {
        if (
            getUserLogin().roles.includes("admin-bd") ||
      getUserLogin().roles.includes("admin-operation")
        ) {
            role = "PIC";
        }
    } else {
        role = getUserLogin().client_role;
    }
    return role;
};

formatter.selectData = (data, option) => {
    if (data === null) return "n/a";

    const select = option.find((x) => x.value === data.toLowerCase());
    return select ? select.name : "n/a";
};

formatter.textAvatar = (text) => {
    let data = null;
    if (!text) {
        data = formatter.getFullname();
    } else {
        data = text;
    }
    if (data) {
        const textArr = data.split(" ");
        if (textArr.length > 1) {
            return `${textArr[0].substring(0, 1).toUpperCase()}${textArr[1]
                .substring(0, 1)
                .toUpperCase()}`;
        }
        return data.substring(0, 2).toUpperCase();
    }
    return "";
};

formatter.getMessageError = (err) => {
    let message = null;
    if (err.response) {
        message =
      typeof err.response === "object"
          ? typeof err.response.data === "object"
              ? err.response.data.message
              : null
          : null;
    } else {
        message = err.message;
    }

    return message || "Something wrong";
};

formatter.getHeaderTitle = (fullpath) => {
    if (fullpath.search("attendance_method") >= 0) {
        return "Attendance Method";
    } else if (fullpath.search("workers/candidate") >= 0) {
        return "Mitra";
    } else if (fullpath.search("cash-advance/detail") >= 0) {
        return "Cash Advance Detail";
    } else if (fullpath.search("cash-advance") >= 0) {
        return "Cash Advance";
    } else if(fullpath.search("settings/salary-settings") >= 0) {
        return "Salary Settings";
    } else if (fullpath.search("reimbursement/detail") >= 0) {
        return "Reimbursement Detail";
    } else if (fullpath.search("reimbursement") >= 0) {
        return "Reimbursement";
    } else if (fullpath.search("salary/report/detail") >= 0) {
        return "Salary Report Detail";
    } else if (fullpath.search("salary/report") >= 0) {
        return "Salary Report";
    } else if (fullpath.search("salary/detail") >= 0) {
        return "Salary Detail";
    } else if (fullpath.search("salary") >= 0) {
        return "Salary";
    } else if (fullpath.search("dashboard") >= 0) {
        return "Dashboard";
    } else if (fullpath.search("candidate") >= 0) {
        return "Candidate";
    } else if (fullpath.search("ex-mitra") >= 0) {
        return "Ex Mitra";
    } else if (fullpath.search("job_vacancy/detail") >= 0) {
        return "Job Post's Detail";
    } else if (fullpath.search("job_vacancy") >= 0) {
        return "Job Vacancy";
    } else if (fullpath.search("applicant/new") >= 0) {
        return "New Applicants";
    } else if (fullpath.search("applicant/all") >= 0) {
        return "All Applicants";
    } else if (fullpath.search("applicant/passed") >= 0) {
        return "Passed";
    } else if (fullpath.search("applicant/screening") >= 0) {
        return "Screening Applicant";
    } else if (fullpath.search("applicant/rejected") >= 0) {
        return "Rejected";
    } else if (fullpath.search("applicant/interview") >= 0) {
        return "Interview";
    } else if (fullpath.search("job_applicant/schedule/interview") >= 0) {
        return "Job Applicant - Interview Schedule";
    } else if (fullpath.search("schedule") >= 0) {
        return "Schedule";
    } else if (fullpath.search("shift/detail") >= 0) {
        return "Shift Details";
    } else if (fullpath.search("shift/edit-shift") >= 0) {
        return "Edit Shift";
    } else if (fullpath.search("shift/add-shift") >= 0) {
        return "Add Shift";
    } else if (fullpath.search("shift") >= 0) {
        return "Shift";
    } else if (fullpath.search("recap") >= 0) {
        return "Recap";
    } else if (fullpath.search("workers/payslip") >= 0) {
        return "Payslip";
    } else if (fullpath.search("workers/tax") >= 0) {
        return "Tax Deduction";
    } else if (fullpath.search("workers/salary") >= 0) {
        return "Salary";
    } else if (fullpath.search("workers/task") >= 0) {
        return "Task Assignment";
    } else if (fullpath.search("report/mitra_attendance") >= 0) {
        return "Mitra Attendance";
    } else if (fullpath.search("report/user_attendance") >= 0) {
        return "User Attendance Report";
    } else if (fullpath.search("attendance") >= 0) {
        return "Attendance";
    } else if (fullpath.search("report/mitra") >= 0) {
        return "Mitra Report";
    } else if (fullpath.search("report/office") >= 0) {
        return "Office Report";
    } else if (fullpath.search("report/initiation_fulfillment") >= 0) {
        return "Initiation Fulfillment Report";
    } else if (fullpath.search("report/initiation") >= 0) {
        return "Initiation Report";
    } else if (fullpath.search("mitra") >= 0) {
        return "Mitra";
    } else if (fullpath.search("announcement") >= 0) {
        return "Announcement";
    } else if (fullpath.search("contract") >= 0) {
        return "Contract";
    } else if (fullpath.search("job_applicant") >= 0) {
        return "Job Applicant - Processing";
    } else if (fullpath.search("job_applicant/detail") >= 0) {
        return "Job Applicant Details";
    } else if (fullpath.search("leave") >= 0) {
        return "Leave Report";
    } else if (fullpath.search('whats-new')>=0) {
        return "What's New?";
    }  else if (fullpath.search("onboarding") >= 0) {
        return "Onboarding";
    }  else if (fullpath.search("kyc") >= 0) {
        return "KYC Validation";
    } else if (fullpath.search("workers/overtime-report") >= 0) {
        return "Overtime Report";
    } else {
        return "My Page";
    }
};

formatter.dateComplete = (date) => {
    // 25 Nov 2019
    // return moment(date).format('DD MMM YYYY');
    return new Date(date).toUTCString().slice(5, 16);
};

formatter.dateCompleteNew = (date) => {
    return moment(date).format('DD MMMM YYYY');
};

formatter.dateCustomFormat = ({ date, format = 'MMMM DD YYYY', locale }) => {
    if (locale) moment.locale(locale);

    return moment(date).format(format);
};

formatter.dateFormatSlash = (date) => {
    return moment(date).format('MM/DD/YYYY');
};

formatter.dateCompleteWithDay = (date) => {
    // Monday, 25 Nov 2019
    return moment(date).format("dddd, DD MMM YYYY");
};

formatter.diffYears = (startDate, endDate) => {
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');
    return Math.round(moment.duration(end.diff(start)).asYears());
};

formatter.yearOnly = (date) => {
    // 2019
    return moment(date).format("YYYY");
};

formatter.hourMinuteOnly = (date) => {
    // 18:51
    return moment(date).format("HH:mm");
};

formatter.timeComplete = (time) => {
    return time && time !== null ? moment(time).format("HH:mm:ss") : "-";
};

formatter.thousandSeparator = (data) => {
    return Math.abs(data || 0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

formatter.absoluteNumber = (data) => {
    return Math.abs(data || 0);
};

formatter.datetimeShort = (date) => {
    // 25/11/2019 17:31:21
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
};

formatter.datetimeForFileName = (date) => {
    return moment(date).format("DD-MM-YYYY-HH-mm-ss");
};

formatter.datetimeShortNew = (date) => {
    // 25/11/2019 17:31:21
    return moment(date).format("DD MMM YYYY HH:mm:ss");
};

formatter.dateHourAndMinute = (date) => {
    return moment(date).format("DD MMMM YYYY, HH:mm");
};

formatter.ageInYear = (date) => {
    // 25
    return moment().diff(date, "years");
};

formatter.monthTotal = (date) => {
    return moment().diff(date, "months");
};

formatter.dateTimezoneOffset = (date) => {
    return moment(date).format("Z");
};

formatter.getMonthYear = (date) => {
    moment().locale("id");
    return moment(date).format("MMM YYYY");
};

formatter.sanitizeString = (str) => {
    const input = str.replace(/[^a-z0-9 '-]/gi, "");
    return input;
};

formatter.getMonthString = ({ index }) => {
    switch(index) {
    case 1:
        return 'January';
    case 2:
        return 'February';
    case 3:
        return 'March';
    case 4:
        return 'April';
    case 5:
        return 'May';
    case 6:
        return 'June';
    case 7:
        return 'July';
    case 8:
        return 'August';
    case 9:
        return 'September';
    case 10:
        return 'October';
    case 11:
        return 'November';
    case 12:
        return 'December';
    default:
        return '-';
    }
};

formatter.salaryCurrency = (data) => {
    const salary = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    }).format(data);
    return salary;
};

formatter.isSameDate = (firstDate, secondDate) => {
    return moment(firstDate).isSame(secondDate);
};

formatter.autoIncrementNumberPagination = ({ pagingCounter, index }) => {
    if (!isNaN(pagingCounter) && !isNaN(index)) {
        return Number.parseInt(pagingCounter) + Number.parseInt(index);
    } else {
        return '-';
    }
};

formatter.toTitleCase = (str) => {
    const titleCase = str
        .toLowerCase()
        .split(' ')
        .map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    return titleCase;
};

formatter.diffMonths = (startDate, endDate) => {
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');
    return Math.round(moment.duration(end.diff(start)).asMonths());
};

formatter.trimWords = (text, limit) => {
    let words = text.split(' ');
    let trimmedText = '';
    for (let i = 0; i < limit; i++) {
        trimmedText = words.slice(0, limit).join(' ');
    }
    if (words.length > limit) {
        trimmedText += '...';
    }
    return trimmedText;
};

formatter.getMyrobinFilePreviewerUrl = (s3_url) => {
    const splittedUrl = s3_url.split("/");
    let string = process.env.NODE_ENV === 'production' ? "https://company.myrobin.id/file?" : "https://pre-company.myrobin.tech/file?";
    string = string + `bucket=${splittedUrl[3]}&` + `folder=${splittedUrl[4]}&` + `id=${splittedUrl[5]}&` + `file=${splittedUrl[6]}`;
    return string;
};

export default formatter;
