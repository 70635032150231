<template>
  <section>
    <div id="download-contract-modal" class="uk-flex-top download-contract-modal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <button class="uk-modal-close-default" type="button" uk-close @click="closeDownload" />
        <div class="uk-modal-header">
            <h2 class="uk-modal-title uk-text-bold uk-text-center">Download Contract</h2>
        </div>
        <form @submit.prevent="doDownloadContract" v-if="selected_worker.sign_contracts.length > 0">
            <div class="uk-padding uk-grid-small" uk-grid>
                <div class="uk-width-1-1">
                    <label for="contract" class="uk-form-label">Select the contract to download!</label>
                    <select id="contract" name="contract" class="uk-select" v-model="job_contract_id">
                        <option value="" selected>Choose Contract...</option>
                        <option
                            v-for="(contract, index) in selected_worker.sign_contracts"
                            :key="index"
                            :value="contract._id"
                        >{{ contract.job_contract.title }}</option>
                    </select>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button
                    v-if="buttonDownloadContractLoading"
                    type="button"
                    class="uk-button uk-button-default"
                    disabled
                >
                    <span uk-spinner="ratio: 0.5"></span>
                    <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="submit"
                    class="uk-button"
                    :class="[disabledButtonDownloadContract ? 'uk-button-default' : 'uk-button-primary']"
                    :disabled="disabledButtonDownloadContract"
                >Download</button>
            </div>
        </form>
        <div class="uk-modal-body" v-if="selected_worker.sign_contracts.length < 1">
            <p>Contract not found!</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDanger, notificationDangerCustom } from "@/utils/notification";

export default {
    props: {
        selected_worker: {
            type: Object,
            required: true
        }
    },

    data(){
        return {
            buttonDownloadContractLoading: false,
            job_contract_id: '',
        };
    },

    mounted() {
        window.UIkit.modal("#download-contract-modal").show();
    },

    computed: {
        disabledButtonDownloadContract() {
            return !this.job_contract_id;
        },
    },

    methods: {
        ...mapActions({
            downloadContract: "worker/downloadContract",
        }),

        async doDownloadContract() {
            try {
                this.buttonDownloadContractLoading = true;
                const payloads = {
                    sign_contract_id: this.job_contract_id,
                    user_id: this.$props.selected_worker.user_id,
                    job_partner_id: this.$props.selected_worker.job_partner_id
                };
                const response = await this.downloadContract(payloads);
                this.buttonDownloadContractLoading = false;
                if (response && response.status === 'OK') {
                    window.open(response.result.contract_path.file_url, "_blank");
                    notificationSuccess("Contract downloaded!");
                } else {
                    notificationDangerCustom('Failed to download contract!');
                }
            } catch (error) {
                notificationDanger(error);
                this.buttonDownloadContractLoading = false;
            }
        },

        closeDownload() {
            window.UIkit.modal('#download-contract-modal').$destroy(true);
            this.$emit('close');
        },
    }
};
</script>
