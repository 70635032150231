module.exports = (phone) => {
    if (!phone) {
        return null;
    }

    // default indo
    phone = phone.replace(/\s+/g, '').replace(/[^0-9+]/gi,''); //remove all spaces and alphabets
    const firstChar = phone.substring(0, 1);
    if (firstChar == '0') {
        return `+62${phone.substring(1, 100)}`;
    } 

    const findCountry = phone.substring(0, 3);
    if (findCountry == '+62') { // indonesia
        const findZero = phone.substring(3, 4);
        if (findZero == '0') {
            return `+62${phone.substring(4, 100)}`;
        } else {
            return phone;
        }
    }
    else {
        return phone;
    }
};
