<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable uk-heigth-1-1">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else class="uk-height-1-1">
            <!-- Filter -->
            <div class="filter-section">
                <!-- ROW 1 -->
                <div class="uk-flex uk-flex-left" style="gap: 10px">
                    <div class="uk-flex">
                        <!-- select domicile city -->
                        <Multiselect
                            v-model="filter.city"
                            id="city"
                            name="city"
                            :options="cities"
                            label="name"
                            track-by="_id"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            placeholder="Select City"
                            :select-label="null"
                            :deselect-label="null"
                        >
                            <span slot="noResult">Data not found</span>
                        </Multiselect>
                    </div>
                    <div class="uk-flex">
                        <!-- filter by warehouse -->
                        <Multiselect
                            v-model="filter.company_office"
                            id="company_office"
                            name="company_office"
                            :options="filtered_offices"
                            label="name"
                            track-by="_id"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :disabled="!meta.domicile_city_id"
                            placeholder="Select Office"
                            :select-label="null"
                            :deselect-label="null"
                        >
                            <span slot="noResult">Data not found</span>
                        </Multiselect>
                    </div>
                    <div class="uk-flex">
                        <Multiselect
                            v-model="filter.role"
                            id="role"
                            name="role"
                            :options="roleOptions"
                            :loading='isFetching'
                            label="name"
                            track-by="_id"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :disabled="!meta.domicile_city_id"
                            :select-label="null"
                            :deselect-label="null"
                            placeholder="Select Role"
                        >
                            <span slot="noResult">Data not found</span>
                        </Multiselect>
                    </div>
                </div>

                <!-- ROW 2 -->
                <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
                    <div class="uk-flex" style="gap: 10px">
                        <!-- filter by identity -->
                        <form @submit.prevent="filterWorkerList">
                            <input
                                v-if="meta.identity_filter == 'name'"
                                type="text"
                                class="uk-input"
                                placeholder="Search Mitra Name"
                                v-model="meta.fullname"
                                style="width: 13rem;"
                            >
                            <input
                                v-if="meta.identity_filter == 'identity_number'"
                                type="text"
                                class="uk-input"
                                placeholder="Search Mitra NIK"
                                v-model="meta.identity_number"
                                style="width: 13rem;"
                            >
                            <input
                                v-if="meta.identity_filter == 'email'"
                                type="text"
                                class="uk-input"
                                placeholder="Search Mitra Email"
                                v-model="meta.email"
                                style="width: 13rem;"
                            >
                        </form>
                        <select name="identity_filter" id="identity_filter" class="uk-select" v-model="meta.identity_filter" style="width: 13rem;">
                            <option
                                v-for="(filter, id) in idFilterOptions"
                                :key="id"
                                :value="filter.id"
                            >{{ filter.label }}</option>
                        </select>
                        <!-- filter by condition -->
                        <form @change="filterCondition">
                            <select name="filter" id="filter" class="uk-select" v-model="meta.filter" style="width: 13rem;">
                                <option value="" selected>Filter by ...</option>
                                <option
                                    v-for="(filter, id) in filterOptions"
                                    :key="id"
                                    :value="filter.id"
                                >{{ filter.label }}</option>
                            </select>
                        </form>
                        <!-- sort data -->
                        <form v-if="!select" @change="filterWorkerList">
                            <select name="office" id="office" class="uk-select" v-model="meta.sort_by" style="width: 13rem;">
                                <option value="" selected>Sort by ...</option>
                                <option
                                    v-for="(sort, id) in sortOptions"
                                    :key="id"
                                    :value="sort.id"
                                >{{ sort.label }}</option>
                            </select>
                        </form>
                    </div>
                </div>

                <!-- ROW 3 -->
                <div v-if="meta.filter === 'date'" class="uk-flex uk-flex-middle uk-margin-top uk-flex-wrap" uk-grid>
                    <div class="uk-flex">
                        <button @click="resetDateFilter" type="button" uk-close class="uk-margin-right" aria-label="Close"></button>
                        <p class="uk-margin-remove">Filte Mitra by Date</p>
                    </div>
                    <div>
                        <form
                            @submit.prevent="filterWorkerList"
                            class="uk-flex-middle uk-flex-center uk-flex-left@s uk-grid-small uk-flex-wrap"
                            uk-grid
                        >
                            <div class="uk-inline uk-width-1-3@s">
                                <datetime
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    value-zone="Asia/Jakarta"
                                    format="dd LLL yyyy"
                                    placeholder="Start date"
                                    v-model="meta.start_date"
                                ></datetime>
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                            <div>to</div>
                            <div class="uk-inline uk-width-1-3@s">
                                <datetime
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    format="dd LLL yyyy"
                                    placeholder="End date"
                                    :min-datetime="datetime.fromISO(meta.start_date).plus({days: 1}).toISODate()"
                                    v-model="meta.end_date"
                                    :input-style="{
                                        cursor: meta.start_date === '' ? 'not-allowed' : 'auto'
                                    }"
                                    :disabled="meta.start_date === ''"
                                ></datetime>
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                            <div class="uk-width-1-5@s">
                                <button
                                    type="submit"
                                    class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                    style="gap: 5px;"
                                    :style="{
                                        backgroundColor: disabledButtonDateFilter ? '#DDD8D8' : '#0275D8',
                                        color: disabledButtonDateFilter ? '#888' : 'white',
                                        cursor: disabledButtonDateFilter ? 'auto' : 'pointer'
                                    }"
                                    :disabled=disabledButtonDateFilter
                                >
                                    <span>Apply</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- ROW 4 -->
                <!-- show if not in multi select mode (default)-->
                <div v-if="!select" class="uk-flex uk-flex-between uk-margin-top">
                    <!-- open excel form download modal -->
                    <div>
                        <button
                            class="uk-button uk-button-default uk-text-nowrap uk-margin-small-right"
                            style="border-radius: 5px; border: 1px solid #0abab5; color: white; background-color: #0abab5;"
                            @click="isShowDownloadExcel = true"
                        >
                            <img :src="`${images}/shift-download-white.svg`" alt="" width="25">
                            <span>Download Mitra template</span>
                        </button>
                        <!-- open excel form upload modal -->
                        <button
                            class="uk-button uk-button-default uk-text-nowrap"
                            style="border-radius: 5px; border: 1px solid #0abab5; color: #0abab5;"
                            @click="isShowUploadExcel = true"
                        >
                            <img :src="`${images}/shift-upload-primary.svg`" alt="" width="25">
                            <span>Upload details Mitra</span>
                        </button>
                    </div>
                    <button
                        type="button"
                        class="uk-button uk-button-default uk-text-nowrap uk-flex uk-flex-center uk-flex-middle uk-border-rounded button-filter"
                        @click="enableMultiselect"
                    >
                        <img src="/images/multi-select-filter.svg" alt="Multi select filter icon">
                        <span>Multi-select Mitra</span>
                    </button>
                </div>
                <div v-else class="uk-flex uk-flex-between uk-flex-middle uk-margin-top" style="gap: 10px">
                    <div class="uk-flex uk-flex-left uk-flex-middle" style="gap: 10px">
                        <button
                            v-if="totalWorkers.length < 1"
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                            style="gap: 5px;"
                            :style="[
                                (selectWorkerOption && selectWorkerOption.length < 1) || filterLoading ?
                                {'background-color': '#BDBDBD', 'color': 'white'} :
                                {'background-color': 'white', 'border': '1px solid #0275D8', 'color': '#0275D8'}
                            ]"
                            :disabled="(selectWorkerOption && selectWorkerOption.length < 1) || filterLoading"
                            @click="doSelectAllWorker"
                        >
                            <img
                                v-if="(selectWorkerOption && selectWorkerOption.length < 1) || filterLoading"
                                :src="`${images}/select-all-white.svg`"
                                alt="Select all white icon"
                                width="25"
                            >
                            <img
                                v-else-if="(selectWorkerOption && selectWorkerOption.length > 0) || filterLoading"
                                :src="`${images}/select-all-blue.svg`"
                                alt="Select all icon blue"
                                width="25"
                            >
                            <span>Select All</span>
                        </button>
                        <button
                            v-else
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                            style="gap: 5px;"
                            :style="[
                                (selectWorkerOption && selectWorkerOption.length < 1) || filterLoading ?
                                {'background-color': '#BDBDBD', 'color': 'white'} :
                                {'background-color': 'white', 'border': '1px solid #0275D8', 'color': '#0275D8'}
                            ]"
                            :disabled="(selectWorkerOption && selectWorkerOption.length < 1) || filterLoading"
                            @click="doUnselectAllWorker"
                        >
                            <img
                                v-if="(selectWorkerOption && selectWorkerOption.length < 1) || filterLoading"
                                :src="`${images}/select-all-white.svg`"
                                alt="Select all white icon"
                                width="25"
                            >
                            <img
                                v-else-if="(selectWorkerOption && selectWorkerOption.length > 0) || filterLoading"
                                :src="`${images}/select-all-blue.svg`"
                                alt="Select all icon blue"
                                width="25"
                            >
                            <span>Unselect All</span>
                        </button>
                    </div>
                    <div class="uk-flex uk-flex-right uk-flex-middle" style="gap: 10px">
                        <button
                            class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center"
                            style="gap: 5px;"
                            :style="[
                                filterLoading ? {'background-color': '#BDBDBD', 'color': 'white'} : {'border': '1px solid #0275D8', 'color': '#0275D8'}
                            ]"
                            :disabled="filterLoading"
                            @click="disableMultiselect"
                        >Cancel</button>
                        <button
                            v-if="$can('EDIT', 'worker-mitra')"
                            class="uk-button"
                            style="border-radius: 5px; color: white;"
                            :style="{
                                backgroundColor: filterLoading || disabledButtonMultipleMove ? '#DDD8D8' : '#0ABAB5',
                                color: filterLoading || disabledButtonMultipleMove ? '#888' : 'white'
                            }"
                            :disabled="filterLoading || disabledButtonMultipleMove"
                            @click="renewContractMulti"
                        >Renew Contract</button>
                        <button
                            v-if="$can('EDIT', 'worker-mitra')"
                            class="uk-button"
                            style="border-radius: 5px; width: 102px; color: white;"
                            :style="{
                                backgroundColor: filterLoading || disabledButtonMultipleMove ? '#DDD8D8' : '#0275D8',
                                color: filterLoading || disabledButtonMultipleMove ? '#888' : 'white'
                            }"
                            :disabled="filterLoading || disabledButtonMultipleMove"
                            @click="moveOrChangeRoleMultipleMitra({ totalWorker: totalWorkers })"
                        >Move</button>
                        <button
                            v-if="$can('DELETE', 'worker-mitra')"
                            class="uk-button"
                            style="border-radius: 5px; width: 102px; color: white;"
                            :style="{
                                backgroundColor: filterLoading || disabledButtonMultipleDelete ? '#DDD8D8' : '#D8020F',
                                color: filterLoading || disabledButtonMultipleDelete ? '#888' : 'white'
                            }"
                            :disabled="filterLoading || disabledButtonMultipleDelete"
                            @click="deleteWorkerMultiple({ totalWorker: totalWorkers })"
                        >Delete</button>
                        <button
                            v-if="$can('EDIT', 'worker-mitra')"
                            class="uk-button"
                            style="border-radius: 5px; width: 102px;"
                            :style="{
                                backgroundColor: filterLoading || disabledButtonMultipleHold ? '#DDD8D8' : 'white',
                                border: filterLoading || disabledButtonMultipleHold ? '' : '1px solid #FEBE10',
                                color: filterLoading || disabledButtonMultipleHold ? '#888' : '#FEBE10'
                            }"
                            :disabled="filterLoading || disabledButtonMultipleHold"
                            @click="holdUnholdMultipleWorker({ status: 2,  totalWorker: totalWorkers })"
                        >Hold</button>
                        <button
                            v-if="$can('EDIT', 'worker-mitra')"
                            class="uk-button"
                            style="border-radius: 5px;"
                            :style="{
                                backgroundColor: filterLoading || disabledButtonMultipleUnhold ? '#DDD8D8' : 'white',
                                border: filterLoading || disabledButtonMultipleUnhold ? '' : '1px solid #FEBE10',
                                color: filterLoading || disabledButtonMultipleUnhold ? '#888' : '#FEBE10'
                            }"
                            :disabled="filterLoading || disabledButtonMultipleUnhold"
                            @click="holdUnholdMultipleWorker({ status: 1, totalWorker: totalWorkers })"
                        >Un-Hold</button>
                    </div>
                </div>
            <!-- End filter -->
            </div>

            <!-- Handler when not filter -->
            <!-- <div v-if="!meta.city" class="uk-margin-top">
                <p class="uk-margin-remove">Please Choose City!</p>
            </div> -->
            <!-- Handler when not filter -->

            <!-- Worker List -->
            <div class="uk-margin-top uk-height-1-1">
                <!-- Filter loading -->
                <div v-if="filterLoading || paginationLoading" class="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
                    <div uk-spinner="ratio: 2"></div>
                </div>
                <!-- End filter loading -->

                <!-- Handler when worker list length less than 1 -->
                <div v-if="!filterLoading && !paginationLoading && workers.docs.length < 1">
                    <p class="uk-margin-remove">No Data Found!</p>
                </div>
                <!-- End handler when worker list length less than 1 -->

                <!-- Handler when worker list length greather than 0 -->
                <div v-else-if="!filterLoading && !paginationLoading && workers.docs.length > 0">
                    <div v-if="totalWorkers.length > 0">
                        <p class="uk-margin-remove">{{ totalWorkers.length }} Mitra selected</p>
                    </div>
                    <div class="uk-margin-top uk-flex uk-flex-column" style="gap: 20px">
                        <div v-for="(worker, index) in workers.docs" :key="index" class="uk-card uk-card-default uk-flex uk-flex-middle" style="border-radius: 10px;">
                            <div v-if="select" class="uk-margin-right">
                                <input
                                    type="checkbox"
                                    class="uk-checkbox"
                                    style="border-radius: 5px; width: 24px; height: 24px; border: 2px solid #979797; margin: 0 0 0 30px"
                                    :checked="findCheckedUser({ worker })"
                                    @change="addSelection({ worker })"
                                >
                            </div>
                            <mitra-item
                                :photo-url="worker.photo_url"
                                :fullname="worker.fullname"
                                :employee-id="worker.employee_id"
                                :office-name="worker.office_name"
                                :role="worker.role"
                                :start-date="worker.start_date"
                                :end-date="worker.end_date"
                                :status="worker.status"
                                :select="select"
                                :source="worker.source"
                                :is-hold="worker.is_hold"
                                :user-id="worker.user_id"
                                :contract-signed-status="worker.contract_signed_status"
                                :contract-status="worker.contract_status"
                                :have-sp="worker.have_sp"
                                :sp-sent="worker.sp_sent"
                                :is-doc-complete="worker.is_document_complete"
                                :is-doc-valid="worker.is_all_document_valid"
                                :is-data-complete="worker.is_user_data_complete"
                                :doc-complete="worker.list_incomplete_doc"
                                :doc-valid="worker.list_invalid_doc"
                                :data-complete="worker.list_incomplete_data"
                                :employment-type="worker.employment_type"
                                @move="moveOrChangerRoleMitra({ workerId: worker._id })"
                                @view-sp-req="viewMitraSpRequest(worker._id)"
                                @reblast-notify="reblastNotify({ workerId: worker._id })"
                                @mitra-action="openActionModal({ worker })"
                                @mitra-contract="openContractModal({ worker })"
                                @multiple-validation="docValidating({ worker })"
                            ></mitra-item>
                                <!-- @details="mitraDetail({ workerId: worker._id })" // detail old version
                                @delete="deleteWorker({ workerId: worker._id })"
                                @hold-unhold="holdUnholdWorker({ status: worker.status, workerId: worker._id })"
                                @view-sp-sent="viewMitraSpData(worker._id)"
                                @send-sp="sendMitraSp(worker._id)"
                                @download-contract="handleDownloadContract({ workerId: worker._id })"
                                @renew="renewContract({ workerId: worker._id })" -->
                        </div>
                    </div>
                </div>
                <!-- End handler when worker list length greather than 0-->

                <!-- Pagination -->
                <div v-if="!filterLoading" class="uk-margin-top">
                    <pagination
                        :total-data="workers.totalDocs"
                        :limit-page="meta.limit"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <!-- End pagination -->
            </div>
            <!-- End worker list -->

            <!-- Mitra details modal box, unused -->
            <!-- <div id="detail-modal" class="uk-flex-top detail-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Mitra Detail</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-modal-body">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <div v-else class="uk-modal-body">
                        <div style="color: black;">
                            <div class="uk-flex uk-flex-center uk-flex-middle" style="gap: 10px">
                                <p class="uk-margin-remove uk-text-bold uk-text-large">{{ selected_worker.fullname || '-' }}</p>
                            </div>
                            <div class="uk-flex uk-flex-middle uk-margin-small-top">
                                <div>
                                    <img
                                        v-if="!selected_worker.photo_url"
                                        :data-src="`${images}/avatar.svg`"
                                        alt="Avatar Icon"
                                        uk-img
                                        class="photo"
                                    >
                                    <img
                                        v-else-if="selected_worker.photo_url"
                                        :data-src="selected_worker.photo_url.file_url"
                                        @error="(e) => imgError(e)"
                                        alt="Avatar Icon"
                                        uk-img
                                        class="photo"
                                    >
                                </div>
                                <div class="uk-margin-medium-left">
                                    <p class="uk-margin-remove">Tanggal Lahir: {{ formatDate(selected_worker.birthdate) }}</p>
                                    <p class="uk-margin-remove">Domisili: {{ selected_worker.domicile ? selected_worker.domicile.name : '-' }}</p>
                                    <p class="uk-margin-remove">Pendidikan: <span v-if="selected_worker.cv === null">-</span></p>
                                    <ol v-if="selected_worker.cv !== null" class="uk-margin-remove">
                                        <li
                                            v-for="(education, index) in selected_worker.cv.educations"
                                            :key="index"
                                        >
                                            {{ education.institution_name }}
                                        </li>
                                    </ol>
                                    <p class="uk-margin-remove">Pengalaman Kerja: <span v-if="selected_worker.cv === null">-</span></p>
                                    <ol v-if="selected_worker.cv !== null" class="uk-margin-remove">
                                        <li
                                            v-for="(experience, index) in selected_worker.cv.work_experiences"
                                            :key="index"
                                        >
                                            {{ experience.title }} -
                                            {{ experience.company_name }}
                                            {{ formatYear(experience.start_date) }} - {{ formatYear(experience.end_date) }}
                                        </li>
                                    </ol>
                                    <p class="uk-margin-remove">Assesment Online yang diambil: <span v-if="!selected_worker.all_user_scores">-</span></p>
                                    <ul v-if="selected_worker.all_user_scores" class="uk-margin-remove">
                                        <li
                                            v-for="(test, index) in selected_worker.all_user_scores"
                                            :key="index"
                                        >{{ test.test_category_id.name }}: {{ test.score }}</li>
                                    </ul>
                                    <p class="uk-margin-remove">
                                        <span>Dokumen Pendukung: </span> <span v-if="selected_worker.cv === null">-</span>
                                        <span class="uk-inline-flex">
                                            <a
                                                v-if="selected_worker.cv !== null && selected_worker.cv.ktp !== null"
                                                class="uk-text-normal"
                                                :href="selected_worker.cv.ktp.document_url.file_url"
                                            >KTP</a>
                                            <a
                                                v-if="selected_worker.cv !== null && selected_worker.cv.sim !== null"
                                                class="uk-text-normal uk-margin-small-left"
                                                :href="selected_worker.cv.sim.document_url.file_url"
                                            >SIM</a>
                                            <a
                                                v-if="selected_worker.cv !== null && selected_worker.cv.skck !== null"
                                                class="uk-text-normal uk-margin-small-left"
                                                :href="selected_worker.cv.skck.document_url.file_url"
                                            >SKCK</a>
                                            <a
                                                v-if="selected_worker.cv !== null && selected_worker.cv.ijazah !== null"
                                                class="uk-text-normal uk-margin-small-left"
                                                :href="selected_worker.cv.ijazah.document_url.file_url"
                                            >Ijazah</a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- End mitra details modal box -->

            <!-- Modal Move Mitra -->
            <div id="move-modal" class="uk-flex-top move-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Change Role</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <form v-else @submit.prevent="handleMoveMitra">
                        <div class="uk-padding uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">You are going to change role this mitra!</p>
                                <p class="uk-margin-small-top uk-text-bold uk-text-lead">
                                    {{ totalWorkers.length > 0 ? totalWorkers.length + ' Mitra' : selected_worker.fullname ? selected_worker.fullname : 'Mitra MyRobin' }}
                                </p>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="moveOfficeId" class="uk-form-label">Select Office:</label>
                                <Multiselect
                                    v-model="form_move.new_office_id"
                                    id="moveOfficeId"
                                    name="company_office"
                                    :options="company_offices"
                                    label="name"
                                    track-by="name"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :internal-search="true"
                                    :clear-on-select="true"
                                    :close-on-select="true"
                                    placeholder="Choose Office"
                                    :select-label="null"
                                    :deselect-label="null"
                                    @select="onChangeMoveOffice"
                                    @remove="form_move.new_role_id = ''"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                    <template slot="noResult">Data not found</template>
                                </Multiselect>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="moveRoleId" class="uk-form-label">Select Role:</label>
                                <Multiselect
                                    v-model="form_move.new_role_id"
                                    id="moveRoleId"
                                    name="role"
                                    :options="office_roles || []"
                                    label="role_name"
                                    track-by="role_id"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :internal-search="true"
                                    :clear-on-select="true"
                                    :close-on-select="true"
                                    placeholder="Choose Role"
                                    :select-label="null"
                                    :deselect-label="null"
                                    :disabled="!form_move.new_office_id ||
                                        office_roles === null ||
                                        (office_roles !== null && office_roles.length < 1)"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.role_name }}</strong></template>
                                    <template slot="noResult">Data not found</template>
                                </Multiselect>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="moveDate" class="uk-form-label">Move Date:</label>
                                <div class="uk-width-1-1 uk-inline">
                                    <datetime
                                        id="moveDate"
                                        name="Move Date"
                                        input-class="uk-input uk-border-rounded"
                                        type="date"
                                        v-model="form_move.move_date"
                                        :max-datetime="new Date().toISOString()"
                                        placeholder="MMM DD, YYYY"
                                    ></datetime>
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button
                                type="submit"
                                class="uk-button"
                                :class="[disabledButtonMove ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledButtonMove"
                            >Confirm</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Modal Move Mitra -->

            <!-- Modal Delete Mitra -->
            <div id="delete-modal" class="uk-flex-top delete-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Delete Mitra</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <form v-else @submit.prevent="handleDeleteMitra">
                        <div class="uk-padding uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">You are going to delete this mitra!</p>
                                <p class="uk-margin-small-top uk-text-bold uk-text-lead">
                                    {{ totalWorkers.length > 1 ? totalWorkers.length + ' Mitra' : selected_worker.fullname ? selected_worker.fullname : 'Mitra MyRobin' }}
                                </p>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="reason" class="uk-form-label">Reason <span class="uk-text-danger">*</span></label>
                                <select id="reason" name="Reason" class="uk-select uk-border-rounded" v-model="form_delete.reasons">
                                    <option value="" selected>Select reason below...</option>
                                    <option
                                        v-for="(reason, index) in reasons"
                                        :key="index"
                                        :value="reason.id"
                                    >{{ reason.label }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="notes" class="uk-form-label">Notes</label>
                                <textarea
                                    id="notes"
                                    name="Notes"
                                    class="uk-textarea uk-border-rounded"
                                    rows="3"
                                    v-model="form_delete.notes"
                                ></textarea>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button
                                type="submit"
                                class="uk-button"
                                :class="[disabledButtonDelete ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledButtonDelete"
                            >Confirm</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Modal Delete Mitra -->

            <!-- Modal Download Contract -->
            <div id="download-contract-modal" class="uk-flex-top download-contract-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close @click="closeContractModal()"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Download Contract</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <div v-else>
                        <form v-if="selected_worker.sign_contracts.length > 0">
                            <div class="uk-padding uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="contract" class="uk-form-label">Select the contract to download!</label>
                                    <select id="contract" name="contract" class="uk-select" v-model="sign_contract_id" @change="selDownContract = 'contract'">
                                        <option value="" selected>Choose Contract...</option>
                                        <option
                                            v-for="(contract, index) in selected_worker.sign_contracts"
                                            :key="index"
                                            :value="contract._id"
                                        >{{ contract.job_contract.title }}</option>
                                    </select>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="contract" class="uk-form-label">Select type of documents to download</label>
                                    <div>
                                        <label>
                                            <input v-model="selDownContract" value="contract" class="uk-radio" type="radio" :disabled="disabledButtonDownloadContract"> Contract
                                        </label>
                                    </div>
                                    <div v-if="sign_contract_id && selected_worker.sign_contracts.find(d => d._id === sign_contract_id).certificate_number">
                                        <label>
                                            <input v-model="selDownContract" value="signature" class="uk-radio" type="radio" :disabled="disabledButtonDownloadContract"> Signature Certificate
                                        </label>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <button
                                        type="button"
                                        class="uk-button uk-margin-small-right"
                                        :class="[disabledButtonDownloadContract || buttonDownloadContractLoading ? 'uk-button-default' : 'uk-button-primary']"
                                        :disabled="disabledButtonDownloadContract || buttonDownloadContractLoading"
                                        @click="doDownloadContract(false)"
                                    >
                                        <span v-if="buttonDownloadContractLoading" uk-spinner="ratio: 0.5"></span>
                                        <img class="uk-margin-small-right" :src="`${images}/cloud_download_green.svg`" alt="" />
                                        Download Contract
                                    </button>
                                    <button
                                        type="button"
                                        class="uk-button"
                                        :class="[disabledButtonDownloadContract || buttonDownloadContractLoading ? 'uk-button-default' : 'uk-button-primary']"
                                        :disabled="disabledButtonDownloadContract || buttonDownloadContractLoading"
                                        @click="doDownloadContract(true)"
                                    >
                                        <span v-if="buttonDownloadContractLoading" uk-spinner="ratio: 0.5"></span>
                                        Preview
                                    </button>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-text-right">
                                <button
                                    type="button"
                                    class="uk-button uk-button-primary uk-modal-close"
                                    @click="closeContractModal()"
                                >Close</button>
                            </div>
                        </form>
                        <div class="uk-modal-body" v-if="selected_worker.sign_contracts.length < 1">
                            <p>Contract not found!</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Download Contract -->

            <!-- Modal Unisgned Contract -->
            <div id="unsigned-contract-modal" class="uk-flex-top unsigned-contract-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close @click="closeContractModal()"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Download Contract</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <div v-else>
                        <form v-if="selected_worker.unsigned_contracts.length > 0">
                            <div class="uk-padding uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="contract" class="uk-form-label">Select the contract to download!</label>
                                    <select id="contract" name="contract" class="uk-select" v-model="unsign_contract_id" @change="selDownContract = 'contract'">
                                        <option value="" selected>Choose Contract...</option>
                                        <option
                                            v-for="(contract, index) in selected_worker.unsigned_contracts"
                                            :key="index"
                                            :value="contract._id"
                                        >{{ contract.job_contract.title }}</option>
                                    </select>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="contract" class="uk-form-label">Select type of documents to download</label>
                                    <div>
                                        <label>
                                            <input v-model="selDownContract" value="contract" class="uk-radio" type="radio" :disabled="disabledButtonDownloadUnsignedContract"> Contract
                                        </label>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <button
                                        type="button"
                                        class="uk-button uk-margin-small-right"
                                        :class="[disabledButtonDownloadUnsignedContract || buttonDownloadContractLoading ? 'uk-button-default' : 'uk-button-primary']"
                                        :disabled="disabledButtonDownloadUnsignedContract || buttonDownloadContractLoading"
                                        @click="dwlUnsignedContract(false)"
                                    >
                                        <span v-if="buttonDownloadContractLoading" uk-spinner="ratio: 0.5"></span>
                                        <img class="uk-margin-small-right" :src="`${images}/cloud_download_green.svg`" alt="" />
                                        Download Contract
                                    </button>
                                    <button
                                        type="button"
                                        class="uk-button"
                                        :class="[disabledButtonDownloadUnsignedContract || buttonDownloadContractLoading ? 'uk-button-default' : 'uk-button-primary']"
                                        :disabled="disabledButtonDownloadUnsignedContract || buttonDownloadContractLoading"
                                        @click="dwlUnsignedContract(true)"
                                    >
                                        <span v-if="buttonDownloadContractLoading" uk-spinner="ratio: 0.5"></span>
                                        Preview
                                    </button>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-text-right">
                                <button
                                    type="button"
                                    class="uk-button uk-button-primary uk-modal-close"
                                    @click="closeContractModal()"
                                >Close</button>
                            </div>
                        </form>
                        <div class="uk-modal-body" v-if="selected_worker.unsigned_contracts.length < 1">
                            <p>Contract not found!</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Unsigned Contract -->

            <!-- Modal View SP Request -->
            <div id="sp-req-modal" class="uk-flex-top sp-req-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Request Details</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="uk-padding uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p>Request details for <span class="uk-text-bold">{{ worker_sp_data.worker_name || 'Mitra MyRobin' }}</span></p>
                                <p><b>Request Type:</b> {{ worker_sp_data.sp_type }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="notes" class="uk-form-label uk-text-bold">Notes</label>
                                <textarea
                                    v-model="worker_sp_data.notes"
                                    id="notes"
                                    name="Notes"
                                    class="uk-textarea uk-border-rounded"
                                    rows="3"
                                    readonly
                                ></textarea>
                            </div>
                            <div class="uk-width-1-1">
                                <p><b>Requested by:</b> {{ worker_sp_data.requested_by || 'SPV MyRobin' }}</p>
                                <p><b>Company:</b> {{ worker_sp_data.company || '-' }}</p>
                                <p><b>Request Date:</b> {{ formatDate(worker_sp_data.request_date) || '-' }}</p>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button type="button" class="uk-button uk-button-primary uk-modal-close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal View SP Request -->

            <!-- Modal View Mitra SP -->
            <div id="sp-sent-modal" class="uk-flex-top sp-sent-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Request Details</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="uk-padding uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p>SP details for <span class="uk-text-bold">{{ worker_sp_data.worker_name }}</span></p>
                                <p>SP Type: {{ worker_sp_data.sp_type }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="notes" class="uk-form-label">Notes</label>
                                <textarea
                                    id="notes"
                                    name="Notes"
                                    class="uk-textarea uk-border-rounded"
                                    rows="3"
                                    readonly
                                    v-model="worker_sp_data.notes"
                                ></textarea>
                            </div>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">Attachment</p>
                                <p class="uk-margin-remove">
                                    <a
                                        v-if="worker_sp_data.document"
                                        class="uk-margin-remove"
                                        :href="worker_sp_data.document.file_url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style="text-decoration: none;"
                                    >View Attachment</a>
                                </p>
                            </div>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">Actions</p>
                                <div class="uk-child-width-1-2 uk-margin" uk-grid>
                                    <div v-if="worker_sp_data.sp_val == 1">
                                        <button
                                            class="uk-button uk-width-1-1"
                                            style="background-color: #D8020F; color: white; border: 2px solid #D8020F;"
                                            @click="upgradeSpMitra(worker_sp_data.partner_id)"
                                        >Upgrade to SP2</button>
                                    </div>
                                    <div>
                                        <button
                                            class="uk-button uk-width-1-1"
                                            style="background-color: #ffffff; color: #D8020F; border: 2px solid #D8020F;"
                                            @click="handleCancelMitraSp()"
                                        >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button type="button" class="uk-button uk-button-primary uk-modal-close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal View Mitra SP -->

            <!-- Modal Send Mitra SP -->
            <div id="send-sp-modal" class="uk-flex-top send-sp-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Send SP</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <form v-else @submit.prevent="handleSendMitraSp">
                        <div class="uk-padding uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">You are going to send SP to this mitra!</p>
                                <p class="uk-margin-small-top uk-text-bold uk-text-lead">
                                    {{ selected_worker.fullname ? selected_worker.fullname : 'Mitra MyRobin' }}
                                </p>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="newOfficeId" class="uk-form-label">SP Type</label>
                                <select id="newOfficeId" name="Select Office" class="uk-select uk-border-rounded" v-model="form_sp.type">
                                    <option disabled value="">Select SP...</option>
                                    <option
                                        v-for="sp in spType"
                                        :key="sp.type"
                                        :value="sp.type"
                                    >{{ sp.name }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="notes" class="uk-form-label">Notes</label>
                                <textarea
                                    id="notes"
                                    name="Notes"
                                    class="uk-textarea uk-border-rounded"
                                    rows="3"
                                    placeholder="SP Notes"
                                    v-model="form_sp.notes"
                                ></textarea>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="notes" class="uk-form-label">Attachment</label>
                                <button
                                    type="button"
                                    class="uk-button uk-button-primary uk-border-rounded uk-flex uk-flex-middle"
                                    @click="triggerUploadSpFile()"
                                >
                                    <img :src="`${images}/download-outline.svg`" alt="Download icon">
                                    <span class="uk-margin-small-left uk-text-bold">Upload Document</span>
                                </button>
                                <span v-if="file_attachment" class="file-name">{{ file_attachment.name }}</span>
                                <input ref="attachment" style="display: none" type="file" accept=".pdf,.jpg,.jpeg,.png" @change="uploadFileVerification($event)" />
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button
                                type="submit"
                                class="uk-button"
                                :class="[disabledButtonSp ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledButtonSp"
                            >Confirm</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Modal Send Mitra SP -->

            <!-- Modal Renew Contract -->
            <div id="renew-contract-modal" class="uk-modal-container uk-flex-top renew-contract-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close @click="closeContractModal()"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Renew Contract</h2>
                    </div>
                    <div class="uk-modal-body" uk-overflow-auto>
                        <div v-if="modalLoading" class="uk-padding">
                            <div class="uk-flex uk-flex-middle uk-flex-center">
                                <div uk-spinner="ratio: 2"></div>
                            </div>
                        </div>
                        <form v-else>
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p class="uk-margin-remove">You are going to renew the contract of this mitra!</p>
                                    <p class="uk-margin-small-top uk-text-bold uk-text-lead">
                                        {{ selected_worker.fullname ? selected_worker.fullname : 'Mitra MyRobin' }}
                                    </p>
                                </div>
                                <div class="uk-width-3-5">
                                    <label for="contract" class="uk-form-label">Select Contract:</label>
                                    <select
                                        v-if="!form_renew.is_contract_from_vacancy"
                                        id="contract"
                                        name="contract"
                                        class="uk-select"
                                        :disabled="form_renew.is_contract_from_vacancy"
                                        @change="onSelectContract($event)"
                                    >
                                        <option value="" selected>Choose Contract...</option>
                                        <option
                                            v-for="(contract, index) in contractOptions"
                                            :key="index"
                                            :value="contract._id"
                                        >{{ contract.title }}</option>
                                    </select>
                                    <select v-else disabled id="contract" name="contract" class="uk-select" @change="onSelectContract($event)">
                                        <option value="" selected>Choose Contract...</option>
                                    </select>
                                </div>
                                <div class="uk-width-expand">
                                    <label for="newStartDate" class="uk-form-label">Start Date:</label>
                                    <div class="uk-inline uk-width-expand">
                                        <datetime
                                            id="newStartDate"
                                            name="Start Date"
                                            input-class="uk-input uk-border-rounded"
                                            type="date"
                                            value-zone='local'
                                            v-model="form_renew.new_start_date"
                                        ></datetime>
                                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                    </div>
                                </div>
                                <div class="uk-width-expand">
                                    <label for="newEndDate" class="uk-form-label">End Date:</label>
                                    <div class="uk-inline uk-width-expand">
                                        <datetime
                                            id="newEndDate"
                                            name="End Date"
                                            input-class="uk-input uk-border-rounded"
                                            type="date"
                                            :min-datetime="datetime.fromISO(form_renew.new_start_date).plus({days: 1}).toISODate()"
                                            value-zone='local'
                                            v-model="form_renew.new_end_date"
                                        ></datetime>
                                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <label>
                                        <input v-model="form_renew.is_contract_from_vacancy" class="uk-checkbox" type="checkbox" @change="onChangeContractVacancy">
                                            Use Contract from Job Vacancy
                                    </label>
                                </div>
                                <div v-if="form_renew.contract_list.length > 0" class="uk-width-1-1">
                                    <span>Contract</span>
                                </div>
                                <div v-if="form_renew.contract_list.length > 0" class="uk-width-1-1">
                                    <ul class="uk-child-width-expand" uk-tab>
                                        <li v-for="(contract, index) in form_renew.contract_list" :key="index" >
                                            <a href="#" @click="contract_editor_index = index">{{ contract.title }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="form_renew.contract_list.length > 0" class="uk-width-1-1">
                                    <div id="contract-content">
                                        <vue-editor v-model="form_renew.contract_list[contract_editor_index].contract"></vue-editor>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-modal-close uk-margin-right button-filter"
                            @click="closeContractModal()"
                        >Cancel</button>
                        <button
                            class="uk-button"
                            :class="[disabledButtonRenew ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="disabledButtonRenew"
                            @click="handleRenewContract"
                        >Send</button>
                    </div>
                </div>
            </div>
            <!-- Modal Renew Contract -->

            <!-- Modal Reblast Notify -->
            <div id="reblast-notify-modal" class="uk-flex-top reblast-notify-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close @click="resetReblast"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Reblast Notification</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <form v-else @submit.prevent="confirmReblast">
                        <div class="uk-padding uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">Please select the type of email to reblast to <b>{{ selected_worker.fullname ? selected_worker.fullname : 'Mitra MyRobin' }}</b></p>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="contract" class="uk-form-label">Notification Type:</label>
                                <select id="contract" name="contract" class="uk-select" v-model="reblast.type" @change="resetReblast">
                                    <option value="add_data" selected>Reblast Data Tambahan</option>
                                    <option value="onboarding" selected>Reblast OnBoarding</option>
                                </select>
                            </div>
                            <div v-if="reblast.type === 'add_data'" class="uk-width-1-1">
                                <label for="contract" class="uk-form-label">Please check the necessary information to send</label>
                                <div><label>
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        value="profile"
                                        @change="reblast.info_list.includes('profile')
                                            ? reblast.info_list = reblast.info_list.filter(d => d !== 'profile')
                                            : reblast.info_list.push('profile')"> Profil
                                </label></div>
                                <div><label>
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        value="family-detail"
                                        @click="reblast.info_list.includes('family-detail')
                                            ? reblast.info_list = reblast.info_list.filter(d => d !== 'family-detail')
                                            : reblast.info_list.push('family-detail')"> Detail Keluarga
                                </label></div>
                                <div><label>
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        value="equipment"
                                        @click="reblast.info_list.includes('equipment')
                                            ? reblast.info_list = reblast.info_list.filter(d => d !== 'equipment')
                                            : reblast.info_list.push('equipment')"> Perlengkapan
                                </label></div>
                                <div><label>
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        value="document"
                                        @click="reblast.info_list.includes('document')
                                            ? reblast.info_list = reblast.info_list.filter(d => d !== 'document')
                                            : reblast.info_list.push('document')"> Dokumen
                                </label></div>
                                <div><label>
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        value="emergency-contact"
                                        @click="reblast.info_list.includes('emergency-contact')
                                            ? reblast.info_list = reblast.info_list.filter(d => d !== 'emergency-contact')
                                            : reblast.info_list.push('emergency-contact')"> Kontak Darurat
                                </label></div>
                                <div><label>
                                    <input
                                        class="uk-checkbox"
                                        type="checkbox"
                                        value="job-detail"
                                        @click="reblast.info_list.includes('job-detail')
                                            ? reblast.info_list = reblast.info_list.filter(d => d !== 'job-detail')
                                            : reblast.info_list.push('job-detail')"> Detail Pekerjaan
                                </label></div>
                            </div>
                            <div v-if="reblast.type === 'add_data'" class="uk-width-1-1">
                                <label for="contract" class="uk-form-label uk-margin-small-right">Notes</label>
                                <textarea class="uk-textarea" v-model="reblast.notes" placeholder="Email notes"></textarea>
                            </div>
                            <div v-if="reblast.type === 'add_data'" class="uk-width-1-1">
                                <label for="contract" class="uk-form-label uk-margin-small-right">Send to:</label>
                                <label class="uk-margin-small-right"><input
                                    class="uk-checkbox"
                                    type="checkbox"
                                    @click="reblast.in_app_notif = !reblast.in_app_notif"> In-app Notification</label>
                                <label class="uk-margin-small-right"><input
                                    class="uk-checkbox"
                                    type="checkbox"
                                    @click="reblast.email_notif = !reblast.email_notif"> Email</label>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button type="submit" class="uk-button uk-button-primary">Send</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Modal Reblast Notify -->

            <!-- Confirm Modal -->
            <div id="confirm-modal" class="uk-flex-top confirm-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">Confirmation</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>Are you sure want to proceed?</p>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button
                            v-if="confirmationModalButtonLoading"
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button
                            v-if="!confirmationModalButtonLoading"
                            class="uk-button uk-button-default uk-modal-close uk-border-rounded"
                            type="button"
                            style="border: 1px solid #0275D8; color: #0275D8;"
                            :disabled="confirmationModalButtonLoading"
                        >No</button>
                        <button
                            v-if="!confirmationModalButtonLoading"
                            class="uk-button uk-margin-small-left uk-border-rounded uk-button-primary"
                            type="button"
                            :disabled="confirmationModalButtonLoading"
                            @click="handleConfirmationModalBox"
                        >Yes</button>
                    </div>
                </div>
            </div>
            <!-- Confirm Modal -->

            <!-- Modal error when move worker -->
            <div id="error-modal" class="uk-flex-top error-modal uk-modal-container" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-bold uk-text-center">Failed to move mitra</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-padding">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                        </div>
                    </div>
                    <div v-else class="uk-padding">
                        <p>The list of mitra who wasn't check out</p>
                        <div class="uk-overflow-auto">
                            <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                                <thead>
                                    <tr>
                                        <th class="uk-text-nowrap">User ID</th>
                                        <th class="uk-text-nowrap">Employee ID</th>
                                        <th class="uk-text-nowrap">Name</th>
                                        <th class="uk-text-nowrap">Check In</th>
                                        <th class="uk-text-nowrap">Check In Server</th>
                                    </tr>
                                </thead>
                                <tbody v-if="partnerDataNotYetCheckout.length > 0">
                                    <tr v-for="(worker, index) in partnerDataNotYetCheckout" :key="index">
                                        <td class="uk-text-nowrap">{{ worker.user_id ? worker.user_id._id : '-' }}</td>
                                        <td class="uk-text-nowrap">{{ worker.job_partner_id ? worker.job_partner_id.employee_id : '-' }}</td>
                                        <td class="uk-text-nowrap">{{ worker.user_id ? worker.user_id.fullname : '-' }}</td>
                                        <td class="uk-text-nowrap">{{ formateDateHourAndMinute(worker.clock_in) }}</td>
                                        <td class="uk-text-nowrap">{{ formateDateHourAndMinute(worker.clock_in_server) }}</td>
                                    </tr>
                                </tbody>
                                <empty-table
                                    v-else
                                    :colspan="5"
                                ></empty-table>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End modal error when move worker -->

            <!-- Available action for mitra modal -->
            <div id="action-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">Action</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p class="uk-margin-remove">Please select action to proceed</p>
                        <select id="action" name="action" class="uk-select" v-model="selectedAction" @change="setMitraAction()">
                            <option :value=null>Select action</option>
                            <option
                                v-for="(item, index) in actionList"
                                :key="index"
                                :value="item.action"
                            >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button
                            class="uk-button uk-button-primary uk-margin-small-left uk-modal-close"
                            type="button"
                        >Close</button>
                    </div>
                </div>
            </div>

            <!-- Contract option modal -->
            <div id="contract-modal" class="contract-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close @click="closeContractModal()"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">Contract</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p class="uk-margin-remove">Please select action to proceed</p>
                        <select id="action" name="action" class="uk-select" v-model="selectedAction" @change="setMitraAction()">
                            <option :value=null>Select action</option>
                            <option
                                v-for="(item, index) in contractActionList"
                                :key="index"
                                :value="item.action"
                            >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button
                            class="uk-button uk-button-primary uk-margin-small-left uk-modal-close"
                            type="button"
                            @click="closeContractModal()"
                        >Close</button>
                    </div>
                </div>
            </div>

            <multiRenewContractModal
                v-if="isShowModalMultiRenew"
                :contractOptions="contractOptions"
                :totalWorkers="totalWorkers"
                @hideModal="hideRenewMulti"
            />
            <MitraDataDownload
                v-if="isShowDownloadExcel"
                :selection="templateSelection"
                :meta="meta"
                @close="isShowDownloadExcel = false"
            />
            <MitraDataUpload
                v-if="isShowUploadExcel"
                :selection="templateSelection"
                @close="isShowUploadExcel = false"
            />
            <PreviewPDFModal
                v-if="isShowPreviewPDFModal"
                :url="previewSrc"
                @hideModal="isShowPreviewPDFModal = false"
            />
            <ValidateMultipleDocument
                v-if="isShowValidatingModal"
                :user-id="actionWorker.user_id"
                @close="isShowValidatingModal = false"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from "@/utils/constant";
import { Datetime } from 'vue-datetime';
import { VueEditor } from 'vue2-editor';
import { DateTime } from 'luxon';
import Multiselect from 'vue-multiselect';
import { notificationSuccess, notificationDanger, notificationDangerCustom } from "@/utils/notification";

export default {
    name: 'Mitra',
    components: {
        Datetime,
        VueEditor,
        Multiselect,
        MitraItem: () => import('@/components/globals/Mitra/MitraItem.vue'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        multiRenewContractModal: () => import('./modals/renew-multi-contract'),
        MitraDataDownload: () => import('@/components/globals/modals/MitraDataDownload'),
        MitraDataUpload: () => import('@/components/globals/modals/MitraDataUpload'),
        PreviewPDFModal: () => import('@/components//globals/modals/PdfViewer'),
        ValidateMultipleDocument: () => import('@/components/globals/modals/ValidateMultipleDocument.vue'),
    },
    data() {
        return {
            reblast: {
                type: 'add_data',
                in_app_notif: false,
                email_notif: false,
                notes: '',
                info_list: [],
            },
            datetime: DateTime,
            selDownContract: 'contract',
            isShowPreviewPDFModal: false,
            previewSrc: '',
            cities: [],
            filtered_offices: [],
            roleOptions: [],
            isLoading: false,
            isFetching: false,
            filterLoading: false,
            modalLoading: false,
            isShowModalMultiRenew: false,
            isShowDownloadExcel: false,
            isShowUploadExcel: false,
            confirmationModalButtonLoading: false,
            images: PREFIX_IMAGE,
            meta: {
                company_office_id: '',
                domicile_city_id: '',
                fullname: '',
                start_date: '',
                end_date: '',
                sort_by: '',
                filter: '',
                limit: 10,
                page: 1,
                identity_filter: 'name',
                identity_number: '',
                email: '',
                role_id: '',
            },
            metaSelectWorker: {
                domicile_city_id: '',
                company_office_id: '',
                fullname: '',
                start_date: '',
                end_date: '',
                sort_by: '',
                filter: '',
                limit: 10,
                page: 1,
                identity_number: '',
                email: '',
                role_id: '',
            },
            sortOptions: [
                {
                    id: 'start-asc',
                    label: 'Latest start date'
                },
                {
                    id: 'start-desc',
                    label: 'Oldest start date'
                },
                {
                    id: 'end-asc',
                    label: 'Latest end date'
                },
                {
                    id: 'end-desc',
                    label: 'Oldest end date'
                },
            ],
            filterOptions: [
                {
                    id: 'sp1',
                    label: 'SP1'
                },
                {
                    id: 'sp2',
                    label: 'SP2'
                },
                {
                    id: 'termin',
                    label: 'Terminasi Kontrak'
                },
                {
                    id: 'hold',
                    label: 'Mitra On Hold'
                },
                {
                    id: 'date',
                    label: 'Date'
                },
                {
                    id: 'signed',
                    label: 'Contract Signed'
                },
                {
                    id: 'not-signed',
                    label: 'Contract Not Signed'
                },
            ],
            idFilterOptions: [
                {
                    id: 'name',
                    label: 'Search by Name'
                },
                {
                    id: 'identity_number',
                    label: 'Search by NIK'
                },
                {
                    id: 'email',
                    label: 'Search by Email'
                }
            ],
            filter: {
                role: null,
                city: null,
                company_office: null,
            },
            workers: {
                docs: [],
                totalDocs: 0
            },
            totalWorkers: [],
            select: false,
            selected_worker: {
                fullname: null,
                photo_url: null,
                // birthdate: null,
                // domicile: null,
                // cv: null,
                // all_user_scores: null,
                company_office_id: null,
                role_id: null,
                user_id: null,
                job_partner_id: null,
                sign_contracts: [],
                unsigned_contracts: [],
            },
            form_move: {
                new_office_id: '',
                new_role_id: '',
                job_partner_ids: [],
                move_date: null
            },
            form_hold_unhold: {
                status: null,
                job_partner_ids: []
            },
            office_roles: null,
            reasons: [
                {
                    id: 0,
                    label: 'Habis Kontrak'
                },
                {
                    id: 1,
                    label: 'Sakit'
                },
                {
                    id: 2,
                    label: 'Selesai Event'
                },
                {
                    id: 3,
                    label: 'Blacklist - Tak Tertoleransi'
                },
                {
                    id: 4,
                    label: 'Blacklist - Tertoleransi'
                },
                {
                    id: 5,
                    label: 'Lainnya'
                }
            ],
            form_delete: {
                reasons: '',
                notes: '',
                job_partner_ids: []
            },
            data_download: false,
            data_upload: false,
            templateSelection: [
                {
                    name: 'Profile',
                    type: 'profile',
                },
                {
                    name: 'Identity Document',
                    type: 'document'
                },
                {
                    name: 'Family Details',
                    type: 'family'
                },
                {
                    name: 'Equipment',
                    type: 'equipment'
                },
                {
                    name: 'Emergency Contact',
                    type: 'emergency'
                },
                {
                    name: 'Job Details',
                    type: 'job'
                },
                {
                    name: 'Contract Salary Setting',
                    type: 'contractsalarysetting'
                },
                {
                    name: 'Move Office & Role',
                    type: 'moveofficerole'
                },
            ],
            sign_contract_id: '',
            unsign_contract_id: '',
            buttonDownloadContractLoading: false,
            confirmationType: null,
            spType: [
                {
                    type: 1,
                    name: 'SP1'
                },
                {
                    type: 2,
                    name: 'SP2'
                },
            ],
            worker_sp_data: {
                worker_name: '',
                sp_val: 1,
                sp_type: '',
                notes: '',
                requested_by: '',
                company: '',
                request_date: '',
                document: null
            },
            form_sp: {
                type: '',
                notes: '',
                attachment: '',
            },
            file_attachment: null,
            contractOptions: [],
            form_renew: {
                new_start_date: '',
                new_end_date: '',
                contract_list: [],
                is_contract_from_vacancy: false
            },
            contract_editor_index: 0,
            selectWorkerOption: null,
            partnerDataNotYetCheckout: [],
            paginationLoading: false,
            selectedAction: null,
            actionWorker: null,
            actionList: [
                {
                    name: 'Delete',
                    action: 'delete'
                },
                {
                    name: 'Hold',
                    action: 'hold-unhold'
                },
            ],
            contractActionList: [
                {
                    name: 'Contract',
                    action: 'download-contract'
                },
                {
                    name: 'Unsigned Contract (template only)',
                    action: 'unsigned'
                },
                {
                    name: 'Renew Contract',
                    action: 'renew'
                },
            ],
            isShowValidatingModal: false,
        };
    },
    computed: {
        ...mapGetters({
            company_offices: "company_office/company_offices",
            roles: "option_data/roles",
        }),
        disabledButtonDateFilter() {
            return this.filterLoading || this.meta.end_date === '';
        },
        disabledButtonMove() {
            let disable = false;
            if (this.selected_worker.company_office_id && this.selected_worker.role_id && this.office_roles) {
                if (
                    this.form_move.new_office_id._id == this.selected_worker.company_office_id &&
                    this.form_move.new_role_id.role_id == this.selected_worker.role_id
                ) {
                    disable = true;
                } else if (!this.office_roles.find((item) => item.role_id == this.form_move.new_role_id.role_id)) {
                    disable = true;
                }
            }
            if (!this.form_move.move_date) disable = true;
            return disable;
        },
        disabledButtonDelete() {
            return this.form_delete.reasons === '';
        },
        disabledButtonDownloadContract() {
            return !this.sign_contract_id;
        },
        disabledButtonDownloadUnsignedContract() {
            return !this.unsign_contract_id;
        },
        disabledButtonSp() {
            let disable = false;
            if(!this.form_sp.type || !this.form_sp.notes.replace(/\s+/g, '') || !this.file_attachment) {
                disable = true;
            }
            return disable;
        },
        disabledButtonRenew() {
            let disable = false;
            if (!this.form_renew.contract_list.length > 0 || !this.form_renew.new_start_date || !this.form_renew.new_end_date) {
                disable = true;
            }
            return disable;
        },
        disabledButtonMultipleMove() {
            if (this.totalWorkers.length < 1) {
                return true;
            } else {
                const findHoldWorker = this.totalWorkers.find((data) => data.is_hold);
                if (findHoldWorker) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        disabledButtonMultipleDelete() {
            if (this.totalWorkers.length < 1) {
                return true;
            } else {
                return false;
            }
        },
        disabledButtonMultipleHold() {
            if (this.totalWorkers.length < 1) {
                return true;
            } else {
                return false;
            }
        },
        disabledButtonMultipleUnhold() {
            if (this.totalWorkers.length < 1) {
                return true;
            } else {
                return false;
            }
        }
    },
    watch: {
        async meta() {
            try {
                this.paginationLoading = true;
                await this.setWorkerData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.paginationLoading = false;
            }
        },
        'form_renew.new_start_date'(val) {
            if (val > this.form_renew.new_end_date) {
                this.form_renew.new_end_date = '';
            }
        },
        'meta.identity_filter'() {
            this.meta.fullname = '';
            this.meta.email = '';
            this.meta.identity_number = '';
        },
        async 'filter.city'(val) {
            if (val) {
                this.meta.domicile_city_id = val._id;
            } else {
                this.meta.domicile_city_id = '';
                this.filter.company_office = '';
                this.filter.role = null;
            }
            await this.filterOffice();
        },
        async 'filter.company_office'(val) {
            if (val) {
                this.meta.company_office_id = val._id;
            } else {
                this.meta.company_office_id = '';
                this.filter.role = null;
            }
            await this.filterWorkerList();
        },
        async 'filter.role'(val) {
            if (val) {
                this.meta.role_id = val._id;
            } else {
                this.meta.role_id = null;
            }
            await this.filterWorkerList();
        },
    },
    beforeMount() {
        // if (window.UIkit.modal('.detail-modal')) window.UIkit.modal('.detail-modal').$destroy(true);
        if (window.UIkit.modal('.move-modal')) window.UIkit.modal('.move-modal').$destroy(true);
        if (window.UIkit.modal('.delete-modal')) window.UIkit.modal('.delete-modal').$destroy(true);
        if (window.UIkit.modal('.confirm-modal')) window.UIkit.modal('.confirm-modal').$destroy(true);
        if (window.UIkit.modal('.sp-req-modal')) window.UIkit.modal('.sp-req-modal').$destroy(true);
        if (window.UIkit.modal('.sp-sent-modal')) window.UIkit.modal('.sp-sent-modal').$destroy(true);
        if (window.UIkit.modal('.send-sp-modal')) window.UIkit.modal('.send-sp-modal').$destroy(true);
        if (window.UIkit.modal('.error-modal')) window.UIkit.modal('.error-modal').$destroy(true);
        if (window.UIkit.modal('.action-modal')) window.UIkit.modal('.action-modal').$destroy(true);
        if (window.UIkit.modal('.contract-modal')) window.UIkit.modal('.contract-modal').$destroy(true);
        if (window.UIkit.modal('.download-contract-modal')) window.UIkit.modal('.download-contract-modal').$destroy(true);
        if (window.UIkit.modal('.unsigned-contract-modal')) window.UIkit.modal('.unsigned-contract-modal').$destroy(true);
        if (window.UIkit.modal('.renew-contract-modal')) window.UIkit.modal('.renew-contract-modal').$destroy(true);
        if (window.UIkit.modal('.reblast-notify-modal')) window.UIkit.modal('.reblast-notify-modal').$destroy(true);
    },
    async mounted() {
        try {
            this.isLoading = true;

            await this.getCompanyOffice();
            this.setCity();
            this.mapQuery();
            await this.filterWorkerList();

            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: "company_office/getCompanyOffice",
            getAllWorkerList: "worker/getAllWorkerList",
            getWorkerList: "worker/getWorkerList",
            getMitraDetail: "worker/getMitraDetail",
            downloadContract: "worker/downloadContract",
            downloadSignature: "worker/downloadSignature",
            deleteMitra: "worker/deleteMitra",
            moveMultipleMitra: "worker/moveMultipleMitra",
            getOfficeRoles: "option_data/getOfficeRoles",
            holdUnholdMitra: "worker/holdUnholdMitra",
            holdUnholdMultipleMitra: "worker/holdUnholdMultipleMitra",
            getMitraSpData: "worker/getMitraSpData",
            postResendEmail: "worker/postResendEmail",
            sendSpToMitra: "worker/sendSpToMitra",
            cancelSpMitra: "worker/cancelSpMitra",
            uploadAttachment: "file/uploadAttachment",
            mitraDataForForm: "worker/mitraDataForForm",
            uploadMitraData: "worker/uploadMitraData",
            renewContractMitra: "worker/renewContractMitra",
            getSelectableContract: 'contract/getSelectableContract',
            reblastNotification: 'worker/reblastNotification',
            downloadUnsignedContract: 'worker/downloadUnsignedContract',
            getRolesByOffice: "option_data/getRolesByOffice",
            getRoles: "option_data/getRoles",
            getJobVacancyContract: 'contract/getJobVacancyContract'
        }),
        async filterOffice() {
            try {
                this.meta.company_office_id = '';

                if (this.company_offices && this.company_offices.length > 0) {
                    const offices = this.company_offices.filter((office) => {
                        if (
                            (office.domicile_city_id && office.domicile_city_id._id) &&
                            (this.meta.domicile_city_id) &&
                            (office.domicile_city_id._id === this.meta.domicile_city_id)
                        ) {
                            return office;
                        }
                    });
                    this.filtered_offices = offices;
                }

                if (this.filtered_offices.length > 0) {
                    this.filtered_offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

                    const officeIds = this.filtered_offices.map(office => office._id);
                    await this.setRoleOptions({ officeIds });
                }

                if (this.$route.query.company_office_id && this.filtered_offices.length > 0) {
                    this.meta.company_office_id = this.$route.query.company_office_id;
                    this.filter.company_office = this.filtered_offices.find(office => office._id == this.meta.company_office_id);
                }

                await this.filterWorkerList();
            } catch (error) {
                notificationDanger(error);
            }
        },
        resetReblast() {
            this.reblast.in_app_notif = false;
            this.reblast.email_notif = false;
            this.reblast.notes = '';
            this.reblast.info_list = [];
        },
        setCity() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }

            this.filtered_offices = [];
        },
        async setRoleOptions({ officeIds }) {
            try {
                this.isFetching = true;
                if (officeIds && officeIds.length > 0) {
                    const roleOptions = [];

                    const [roleByOffice] = await Promise.all([
                        this.getRolesByOffice({ company_office_ids: officeIds }),
                        this.getRoles()
                    ]);

                    if ((roleByOffice && roleByOffice.length > 0) && (this.roles && this.roles.length > 0)) {
                        roleByOffice.forEach((roleId) => {
                            this.roles.forEach((role) => {
                                if ((roleId) && (role && role._id) && (roleId === role._id)) {
                                    roleOptions.push(role);
                                }
                            });
                        });
                    }

                    this.roleOptions = roleOptions;
                }

                if (this.roleOptions.length > 0) {
                    this.roleOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }

                if (this.$route.query.role_id && this.roleOptions.length > 0) {
                    this.meta.role_id = this.$route.query.role_id;
                    this.filter.role = this.roleOptions.find(role => role._id == this.meta.role_id);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetching = false;
            }
        },
        async filterCondition() {
            try {
                if (this.meta.filter !== 'date') {
                    if (this.meta.start_date) {
                        this.meta.start_date = '';
                        this.meta.end_date = '';
                    }
                    await this.filterWorkerList();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async resetDateFilter() {
            try {
                this.meta.start_date = '';
                this.meta.end_date = '';
                await this.filterWorkerList();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterWorkerList() {
            try {
                if (this.filterLoading) {
                    return;
                }

                this.filterLoading = true;
                this.meta.page = 1;
                this.meta.limit = 10;
                this.meta.fullname = formatter.sanitizeString(this.meta.fullname);
                await this.setWorkerData();
                this.resetTotalWorkers();
                this.fillMetaSelectWorker();
                this.filterLoading = false;
            } catch (error) {
                this.filterLoading = false;
            }
        },
        // async mitraDetail({ workerId }) {
        //     try {
        //         this.modalLoading = true;
        //         await window.UIkit.modal("#details-modal").show();
        //         this.resetSelectedWorker();
        //         const response = await this.getMitraDetail(workerId);
        //         if (response && response.status === 'OK') {
        //             this.fillSelectedWorker({ workerDetail: response.result });
        //         }
        //         this.modalLoading = false;
        //     } catch (error) {
        //         await window.UIkit.modal("#details-modal").show();
        //         this.resetSelectedWorker();
        //         this.modalLoading = false;
        //     }
        // },
        async moveOrChangeRoleMultipleMitra({ totalWorker }) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#move-modal").show();
                this.resetSelectedWorker();
                this.resetFormMove();
                const distinctWorkerId = [...new Set(totalWorker.map(item => String(item._id)))];
                this.fillFormMove({ workerId: distinctWorkerId });
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#move-modal").show();
                this.resetSelectedWorker();
                this.resetFormMove();
                this.modalLoading = false;
            }
        },
        hideRenewMulti(isReload) {
            if (isReload) this.setWorkerData();

            this.isShowModalMultiRenew = false;
            this.resetSelectedWorker();
            this.resetTotalWorkers();
            this.disableMultiselect();
            this.setSelectWorkerOptionData();
        },
        async renewContractMulti() {
            try {
                this.contractOptions = await this.getSelectableContract();
                this.isShowModalMultiRenew = true;
            } catch (error) {
                this.isShowModalMultiRenew = true;
            }
        },
        async moveOrChangerRoleMitra({ workerId }) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#move-modal").show();
                this.resetSelectedWorker();
                this.resetFormMove();
                const response = await this.getMitraDetail(workerId);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                    await this.fillFormMove({ workerId: response.result });
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#move-modal").show();
                this.resetSelectedWorker();
                this.resetFormMove();
                this.modalLoading = false;
            }
        },
        async deleteWorkerMultiple({ totalWorker }) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#delete-modal").show();
                this.resetSelectedWorker();
                this.resetFormDelete();
                const distinctWorkerId = [...new Set(totalWorker.map(item => String(item._id)))];
                this.fillFormDelete({ workerId: distinctWorkerId });
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#delete-modal").show();
                this.resetSelectedWorker();
                this.resetTotalWorkers();
                this.modalLoading = false;
            }
        },
        async deleteWorker({ workerId }) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#delete-modal").show();
                this.resetSelectedWorker();
                this.resetFormDelete();
                const response = await this.getMitraDetail(workerId);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                    this.fillFormDelete({ workerId });
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#delete-modal").show();
                this.resetSelectedWorker();
                this.resetFormDelete();
                this.modalLoading = false;
            }
        },
        async holdUnholdMultipleWorker({ status, totalWorker }) {
            try {
                this.confirmationModalButtonLoading = false;
                await window.UIkit.modal("#confirm-modal").show();
                this.confirmationType = 'hold-unhold-mitra';
                this.resetFormHoldUnhold();
                const distinctWorkerId = [...new Set(totalWorker.map(item => String(item._id)))];
                this.fillFormHoldUnhold({ status, workerId: distinctWorkerId });
            } catch (error) {
                this.confirmationModalButtonLoading = false;
                await window.UIkit.modal("#confirm-modal").show();
                this.resetFormHoldUnhold();
            }
        },
        async holdUnholdWorker({ status, workerId }) {
            try {
                this.confirmationModalButtonLoading = false;
                await window.UIkit.modal("#confirm-modal").show();
                this.confirmationType = 'hold-unhold-mitra';
                this.resetFormHoldUnhold();
                this.fillFormHoldUnhold({ status: status === 2 ? 1 : 2, workerId });
            } catch (error) {
                this.confirmationModalButtonLoading = false;
                await window.UIkit.modal("#confirm-modal").show();
                this.resetFormHoldUnhold();
            }
        },
        async handleMoveMitra() {
            this.confirmationModalButtonLoading = false;
            this.confirmationType = 'move-mitra';
            await window.UIkit.modal("#confirm-modal").show();
        },
        async handleDeleteMitra() {
            this.confirmationModalButtonLoading = false;
            this.confirmationType = 'delete-mitra';
            await window.UIkit.modal("#confirm-modal").show();
        },
        async handleDownloadContract({ workerId }) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#download-contract-modal").show();
                this.resetJobContractId();
                this.resetSelectedWorker();
                const response = await this.getMitraDetail(workerId);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#download-contract-modal").hide();
                this.resetJobContractId();
                this.resetSelectedWorker();
                this.modalLoading = false;
            }
        },
        async handleUnsignedContract({ workerId }) {
            try {
                this.modalLoading = true;
                this.selDownContract = 'contract';
                await window.UIkit.modal("#unsigned-contract-modal").show();
                this.resetJobContractId();
                this.resetSelectedWorker();
                const response = await this.getMitraDetail(workerId);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#unsigned-contract-modal").hide();
                this.resetJobContractId();
                this.resetSelectedWorker();
                this.modalLoading = false;
            }
        },
        async handleRenewContract() {
            this.confirmationType = 'renew-contract';
            await window.UIkit.modal("#confirm-modal").show();
        },
        async handleConfirmationModalBox() {
            this.confirmationModalButtonLoading = false;
            if (this.confirmationType === 'move-mitra') {
                await this.doMoveMitra();
            } else if (this.confirmationType === 'delete-mitra') {
                await this.doDelete();
            } else if (this.confirmationType === 'hold-unhold-mitra') {
                await this.doHoldUnholdMitra();
            } else if (this.confirmationType === 'send-sp-mitra') {
                await this.uploadSpAttachmentFile();
            } else if (this.confirmationType === 'cancel-sp-mitra') {
                await this.doCancelMitraSp();
            } else if (this.confirmationType === 'renew-contract') {
                await this.doRenewContract();
            }
        },
        async doMoveMitra() {
            try {
                this.confirmationModalButtonLoading = true;
                const form_move = {
                    ...this.form_move,
                    new_office_id: this.form_move.new_office_id._id,
                    new_role_id: this.form_move.new_role_id.role_id
                };
                const response = await this.moveMultipleMitra(form_move);
                await window.UIkit.modal("#confirm-modal").hide();
                if (response && response.status == 'OK') {
                    notificationSuccess("Mitra changed!");
                    if (response.result && response.result.partner_data_not_yet_checkout.length > 0) {
                        await window.UIkit.modal("#error-modal").show();
                        this.partnerDataNotYetCheckout = response.result.partner_data_not_yet_checkout;
                    }
                } else {
                    notificationDangerCustom("Failed to change mitra.");
                }
            } catch (error) {
                await window.UIkit.modal("#confirm-modal").hide();
                await window.UIkit.modal("#error-modal").hide();
                notificationDanger(error);
            } finally {
                await window.UIkit.modal("#confirm-modal").hide();
                this.confirmationModalButtonLoading = false;
                this.resetSelectedWorker();
                this.resetTotalWorkers();
                this.resetFormMove();
                this.disableMultiselect();
                await this.setWorkerData();
                await this.setSelectWorkerOptionData();
            }
        },
        async doHoldUnholdMitra() {
            try {
                this.confirmationModalButtonLoading = true;
                const response = await this.holdUnholdMultipleMitra(this.form_hold_unhold);
                await window.UIkit.modal('#confirm-modal').hide();
                if (response && response.status === 'OK') {
                    notificationSuccess("Mitra changed!");
                } else {
                    notificationDangerCustom("Failed to change mitra.");
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal("#confirm-modal").hide();
                this.confirmationModalButtonLoading = false;
                this.resetSelectedWorker();
                this.resetTotalWorkers();
                this.resetFormHoldUnhold();
                this.disableMultiselect();
                await this.setWorkerData();
                await this.setSelectWorkerOptionData();
            }
        },
        async doDelete() {
            try {
                this.confirmationModalButtonLoading = true;
                const payloads = {
                    reasons: this.form_delete.reasons,
                    notes: this.form_delete.notes,
                    job_partner_ids: this.form_delete.job_partner_ids
                };
                const response = await this.deleteMitra(payloads);
                await window.UIkit.modal("#confirm-modal").hide();
                if (response && response.status === "OK") {
                    notificationSuccess("Mitra deleted!");
                } else {
                    notificationDangerCustom("Failed to delete mitra.");
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal("#confirm-modal").hide();
                this.confirmationModalButtonLoading = false;
                this.resetSelectedWorker();
                this.resetTotalWorkers();
                this.disableMultiselect();
                await this.setWorkerData();
                await this.setSelectWorkerOptionData();
            }
        },
        async doDownloadContract(isPreview) {
            if (this.selDownContract === 'contract') {
                this.dwlContract(isPreview);
            } else {
                this.dwlSignature(isPreview);
            }
        },
        async dwlContract(isPreview) {
            try {
                this.buttonDownloadContractLoading = true;
                const payloads = {
                    sign_contract_id: this.sign_contract_id,
                    user_id: this.selected_worker.user_id,
                    job_partner_id: this.selected_worker.job_partner_id
                };
                const response = await this.downloadContract(payloads);
                this.buttonDownloadContractLoading = false;
                if (response && response.status === 'OK') {
                    if (isPreview) {
                        this.isShowPreviewPDFModal = true;
                        this.previewSrc = response.result.contract_path.file_url;
                    } else {
                        window.open(response.result.contract_path.file_url, "_blank");
                        notificationSuccess("Contract downloaded!");
                    }
                } else {
                    notificationDangerCustom('Failed to download contract!');
                }
            } catch (error) {
                notificationDanger(error);
                this.buttonDownloadContractLoading = false;
            }
        },
        async dwlSignature(isPreview) {
            try {
                this.buttonDownloadContractLoading = true;
                const payloads = {
                    sign_contract_id: this.sign_contract_id,
                    user_id: this.selected_worker.user_id,
                    job_partner_id: this.selected_worker.job_partner_id
                };
                const response = await this.downloadSignature(payloads);
                this.buttonDownloadContractLoading = false;
                if (response && response.status === 'OK') {
                    if (isPreview) {
                        this.isShowPreviewPDFModal = true;
                        this.previewSrc = response.result.signature_certificate_path.file_url;
                    } else {
                        window.open(response.result.signature_certificate_path.file_url, "_blank");
                        notificationSuccess("signature downloaded!");
                    }
                } else {
                    notificationDangerCustom('Failed to download signature!');
                }
            } catch (error) {
                notificationDanger(error);
                this.buttonDownloadContractLoading = false;
            }
        },
        async dwlUnsignedContract(isPreview) {
            try {
                this.buttonDownloadContractLoading = true;
                const payloads = {
                    sign_contract_id: this.unsign_contract_id,
                    user_id: this.selected_worker.user_id,
                    job_partner_id: this.selected_worker.job_partner_id
                };
                const response = await this.downloadUnsignedContract(payloads);
                this.buttonDownloadContractLoading = false;
                if (response && response.status === 'OK') {
                    if (isPreview) {
                        this.isShowPreviewPDFModal = true;
                        this.previewSrc = response.result.contract_path.file_url;
                    } else {
                        window.open(response.result.contract_path.file_url, "_blank");
                        notificationSuccess("Contract downloaded!");
                    }
                } else {
                    notificationDangerCustom('Failed to download contract!');
                }
            } catch (error) {
                notificationDanger(error);
                this.buttonDownloadContractLoading = false;
            }
        },
        async confirmReblast() {
            await window.UIkit.modal("#reblast-notify-modal").hide();

            if (this.reblast.type === 'add_data') {
                this.reblaseData();

            } else {
                this.reblasOnboarding();
            }
        },
        async reblaseData() {
            try {
                const payloads = {
                    in_app_notif: this.reblast.in_app_notif,
                    email_notif: this.reblast.email_notif,
                    notes: this.reblast.notes,
                    info_list: this.reblast.info_list
                };
                const response = await this.reblastNotification({ payload: payloads, user_id: this.selected_worker.user_id });

                if (response && response.status === 'OK') {
                    notificationSuccess("success reblast notification!");
                } else {
                    notificationDangerCustom('Failed to reblast notification!');
                }
                this.resetReblast();
            } catch (error) {
                notificationDanger(error);
                this.resetReblast();
            }
        },
        async reblasOnboarding() {
            try {
                const response = await this.postResendEmail({ job_partner_ids: [this.selected_worker.job_partner_id] });
                if (response && response.status === 'OK') {
                    notificationSuccess("email success resend!");
                } else {
                    notificationDangerCustom('Failed to reblast notification!');
                }
                this.resetReblast();
            } catch (error) {
                notificationDanger(error);
                this.resetReblast();
            }
        },
        async renewContract({ workerId }) {
            try {
                this.modalLoading = true;
                this.contractOptions = await this.getSelectableContract();
                await window.UIkit.modal("#renew-contract-modal").show();
                this.resetFormRenew();
                this.resetSelectedWorker();
                const response = await this.getMitraDetail(workerId);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#renew-contract-modal").hide();
                this.resetFormRenew();
                this.resetSelectedWorker();
                this.modalLoading = false;
            }
        },
        async reblastNotify({ workerId }) {
            try {
                this.modalLoading = true;
                this.contractOptions = await this.getSelectableContract();
                await window.UIkit.modal("#reblast-notify-modal").show();
                this.resetSelectedWorker();
                const response = await this.getMitraDetail(workerId);
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#reblast-notify-modal").show();
                this.resetSelectedWorker();
                this.modalLoading = false;
            }
        },
        async doRenewContract() {
            try {
                this.confirmationModalButtonLoading = true;
                this.form_renew.new_start_date = this.datetime.fromISO(this.form_renew.new_start_date).setZone("utc", { keepLocalTime: true }).toISO();
                this.form_renew.new_end_date = this.datetime.fromISO(this.form_renew.new_end_date).setZone("utc", { keepLocalTime: true }).toISO();
                const response = await this.renewContractMitra({ id: this.selected_worker.job_partner_id, payload: this.form_renew});

                await window.UIkit.modal("#confirm-modal").hide();
                this.resetFormRenew();
                this.resetSelectedWorker();
                this.confirmationModalButtonLoading = false;
                if (response && response.status === "OK") {
                    notificationSuccess("Mitra contract renewed!");
                    await this.setWorkerData();
                } else {
                    notificationDangerCustom("Failed to renew mitra contract.");
                }
            } catch (error) {
                await window.UIkit.modal("#confirm-modal").hide();
                this.resetFormRenew();
                this.resetSelectedWorker();
                this.confirmationModalButtonLoading = false;
            }
        },
        async viewMitraSpRequest(workerId) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#sp-req-modal").show();
                this.resetWorkerSpData();

                const response = await this.getMitraSpData(workerId);
                if (response && response.status === 'OK') {
                    this.fillWorkerSpData(response.result);
                    this.worker_sp_data.partner_id = workerId;
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#sp-req-modal").show();
                this.resetWorkerSpData();
                this.modalLoading = false;
            }
        },
        async viewMitraSpData(workerId) {
            try {
                this.modalLoading = true;
                await window.UIkit.modal("#sp-sent-modal").show();
                this.resetWorkerSpData();

                const response = await this.getMitraSpData(workerId);
                if (response && response.status === 'OK') {
                    this.fillWorkerSpData(response.result);
                    this.worker_sp_data.partner_id = workerId;
                }
                this.modalLoading = false;
            } catch (error) {
                await window.UIkit.modal("#sp-sent-modal").show();
                this.resetWorkerSpData();
                this.modalLoading = false;
            }
        },
        async sendMitraSp(workerId, upgrade = false) {
            try {
                this.modalLoading = true;
                this.resetSelectedWorker();
                this.resetFormSp();
                await window.UIkit.modal("#send-sp-modal").show();

                const response = await this.getMitraDetail(workerId);
                if (upgrade) {
                    this.form_sp.type = 2;
                }
                if (response && response.status === 'OK') {
                    this.fillSelectedWorker({ workerDetail: response.result });
                    this.form_sp.partner_id = response.result._id;
                }

                this.modalLoading = false;
            } catch (error) {
                this.resetSelectedWorker();
                this.resetFormSp();
                await window.UIkit.modal("#send-sp-modal").show();
                this.modalLoading = false;
            }
        },
        async upgradeSpMitra(workerId) {
            await window.UIkit.modal("#sp-sent-modal").hide();
            await this.sendMitraSp(workerId, true);
        },
        async handleSendMitraSp() {
            try {
                this.confirmationType = 'send-sp-mitra';
                await window.UIkit.modal("#confirm-modal").show();
            } catch (error) {
                this.resetSelectedWorker();
                this.resetFormSp();
                await window.UIkit.modal("#confirm-modal").show();
            }
        },
        async doSendMitraSp() {
            try {
                const payload = {
                    type: this.form_sp.type,
                    notes: this.form_sp.notes,
                    sp_attachment: this.form_sp.attachment
                };

                const response = await this.sendSpToMitra({ id: this.form_sp.partner_id, payload });
                await window.UIkit.modal("#confirm-modal").hide();
                this.resetSelectedWorker();
                this.resetFormSp();
                this.confirmationModalButtonLoading = false;
                if (response && response.status === "OK") {
                    notificationSuccess("SP sent to mitra.");
                    await this.setWorkerData();
                } else {
                    notificationDangerCustom("Failed to send SP to mitra.");
                }
            } catch (error) {
                await window.UIkit.modal("#confirm-modal").hide();
                notificationDanger(error);
                this.resetSelectedWorker();
                this.resetFormSp();
                this.confirmationModalButtonLoading = false;
            }
        },
        async handleCancelMitraSp() {
            try {
                this.confirmationType = 'cancel-sp-mitra';
                await window.UIkit.modal("#confirm-modal").show();
            } catch (error) {
                await window.UIkit.modal("#confirm-modal").show();
                this.resetSelectedWorker();
                this.resetFormSp();
            }
        },
        async doCancelMitraSp() {
            try {
                this.confirmationModalButtonLoading = true;
                const response = await this.cancelSpMitra(this.worker_sp_data.partner_id);
                await window.UIkit.modal("#confirm-modal").hide();
                this.resetFormSp();
                this.confirmationModalButtonLoading = false;
                if (response && response.status === "OK") {
                    notificationSuccess("Mitra SP canceled.");
                    await this.setWorkerData();
                } else {
                    notificationDangerCustom("Failed to cancel mitra SP.");
                }
            } catch (error) {
                await window.UIkit.modal("#confirm-modal").hide();
                notificationDanger(error);
                this.resetFormSp();
                this.confirmationModalButtonLoading = false;
            }
        },
        triggerUploadSpFile() {
            this.$refs.attachment.click();
        },
        async uploadFileVerification(e) {
            if (e.target.files) {
                if (this.checkFile(e.target.files[0].size, e.target.files[0].type)) {
                    this.file_attachment = e.target.files[0];
                }
            }
        },
        checkFile(fileSize, fileExt) {
            if (fileSize / 1024 / 1024 > 10) {
                notificationDangerCustom('File terlalu besar, maksimal file berukuran 10 MB');
                this.$refs.attachment.value = null;
                return false;
            }

            const extension = ["png", "jpg", "jpeg", "pdf"];
            const splitImg = fileExt.split("/");
            let imgMime = splitImg[splitImg.length - 1];

            if (!extension.includes(imgMime)) {
                notificationDangerCustom('Ekstensi file tidak dikenali');
                this.$refs.attachment.value = null;
                return false;
            }

            return true;
        },
        async uploadSpAttachmentFile () {
            this.confirmationModalButtonLoading = true;
            let formData = new FormData();
            formData.append('file', this.file_attachment);

            const result = await this.uploadAttachment({
                api: 'sp-attachment',
                payload: formData
            });

            if (result.status === 'OK') {
                notificationSuccess(`Upload successful`);
                this.form_sp.attachment = result.result.file_path;
                await this.doSendMitraSp();
            } else {
                notificationDangerCustom('Failed to upload!');
                this.confirmationModalButtonLoading = false;
            }
        },
        async setWorkerData() {
            const response = await this.getWorkerList(this.meta);
            this.workers.docs = response.docs;
            this.workers.totalDocs = response.totalDocs;

            const queryArray = [];
            for (const key in this.meta) {
                if (this.meta[key]) {
                    queryArray.push(key + '=' + this.meta[key]);
                }
            }
            const queryString = queryArray.join('&');
            await this.$router.replace(this.$route.path + '?reset');
            await this.$router.replace(this.$route.path + `?${queryString}`);
        },
        async setSelectWorkerOptionData() {
            const response = await this.getAllWorkerList(this.metaSelectWorker);
            this.selectWorkerOption = response;
        },
        async getSelectedOfficeRoles({ companyOfficeId }) {
            this.office_roles = await this.getOfficeRoles(companyOfficeId);
        },
        async enableMultiselect() {
            this.isLoading = true;
            await this.setSelectWorkerOptionData();
            this.resetTotalWorkers();
            this.select = true;
            this.isLoading = false;
        },
        disableMultiselect() {
            this.resetTotalWorkers();
            this.select = false;
        },
        findCheckedUser({ worker }) {
            return !!this.totalWorkers.find((item) => item._id == worker._id);
        },
        addSelection({ worker }) {
            let selectedIds = [];
            const resultFindSameData = this.totalWorkers.find((item) => item._id == worker._id);
            selectedIds = this.totalWorkers.length > 0 ? resultFindSameData ? this.totalWorkers.filter((item) => item._id != worker._id) : [...this.totalWorkers, ...[worker]] : [worker];
            this.totalWorkers = selectedIds;
        },
        doSelectAllWorker() {
            if (this.selectWorkerOption && this.selectWorkerOption.length > 0) {
                for (const worker of this.selectWorkerOption) {
                    const findSameData = this.totalWorkers.find((data) => data._id == worker._id);
                    if (!findSameData) {
                        this.totalWorkers.push(worker);
                    }
                }
            }
        },
        doUnselectAllWorker() {
            this.resetTotalWorkers();
        },
        fillSelectedWorker({ workerDetail }) {
            this.selected_worker.fullname = workerDetail.fullname;
            this.selected_worker.photo_url = workerDetail.photo_url;
            // this.selected_worker.birthdate = workerDetail.birthdate;
            // this.selected_worker.domicile = workerDetail.domicile;
            // this.selected_worker.cv = workerDetail.cv;
            // this.selected_worker.all_user_scores = workerDetail.all_user_scores;
            this.selected_worker.company_office_id = workerDetail.company_office._id;
            this.selected_worker.role_id = workerDetail.role_id;
            this.selected_worker.user_id = workerDetail.user_id;
            this.selected_worker.job_partner_id = workerDetail._id;
            this.selected_worker.sign_contracts = workerDetail.sign_contracts;
            this.selected_worker.unsigned_contracts = workerDetail.unsigned_contracts;
        },
        async fillFormMove({ workerId }) {
            if (this.totalWorkers.length < 1) {
                this.form_move.new_office_id = this.company_offices.find(office => office._id == workerId.company_office._id);
                await this.onChangeMoveOffice(this.form_move.new_office_id);
                this.form_move.new_role_id = this.office_roles.find(role => role.role_id == workerId.role_id);
                this.form_move.job_partner_ids.push(workerId._id);
            } else {
                this.form_move.new_office_id = '';
                this.form_move.new_role_id = '';
                this.form_move.job_partner_ids = workerId;
            }
        },
        fillFormDelete({ workerId }) {
            if (this.totalWorkers.length < 1) {
                this.form_delete.job_partner_ids.push(workerId);
            } else {
                this.form_delete.job_partner_ids = workerId;
            }
        },
        fillFormHoldUnhold({ status, workerId }) {
            this.form_hold_unhold.status = status;
            if (this.totalWorkers.length < 1) {
                this.form_hold_unhold.job_partner_ids.push(workerId);
            } else {
                this.form_hold_unhold.job_partner_ids = workerId;
            }
        },
        fillMetaSelectWorker() {
            this.metaSelectWorker.domicile_city_id = this.meta.domicile_city_id;
            this.metaSelectWorker.company_office_id = this.meta.company_office_id;
            this.metaSelectWorker.fullname = this.meta.fullname;
            this.metaSelectWorker.start_date = this.meta.start_date;
            this.metaSelectWorker.end_date = this.meta.end_date;
            this.metaSelectWorker.sort_by = this.meta.sort_by;
            this.metaSelectWorker.filter = this.meta.filter;
            this.metaSelectWorker.limit = this.workers.totalDocs;
            this.metaSelectWorker.page = this.meta.page;
            this.metaSelectWorker.identity_number = this.meta.identity_number;
            this.metaSelectWorker.email = this.meta.email;
            this.metaSelectWorker.role_id = this.meta.role_id;
        },
        resetSelectedWorker() {
            this.selected_worker.fullname = null;
            this.selected_worker.photo_url = null;
            // this.selected_worker.birthdate = null;
            // this.selected_worker.domicile = null;
            // this.selected_worker.cv = null;
            // this.selected_worker.all_user_scores = null;
            this.selected_worker.company_office_id = null;
            this.selected_worker.role_id = null;
            this.selected_worker.user_id = null;
            this.selected_worker.job_partner_id = null;
            this.selected_worker.sign_contracts = [];
            this.selected_worker.unsigned_contracts = [];
        },
        resetFormMove() {
            this.form_move.new_office_id = '';
            this.form_move.new_role_id = '';
            this.form_move.job_partner_ids = [];
            this.form_move.move_date = null;
        },
        resetFormDelete() {
            this.form_delete.reasons = '';
            this.form_delete.notes = '';
            this.form_delete.job_partner_ids = [];
        },
        resetFormHoldUnhold() {
            this.form_hold_unhold.status = null;
            this.form_hold_unhold.job_partner_ids = [];
        },
        resetFormRenew() {
            this.form_renew.new_start_date = '';
            this.form_renew.new_end_date = '';
            this.form_renew.contract_list = [];
            this.form_renew.is_contract_from_vacancy = false;
        },
        resetTotalWorkers() {
            this.totalWorkers = [];
        },
        resetJobContractId() {
            this.sign_contract_id = '';
            this.unsign_contract_id = '';
        },
        fillWorkerSpData(data) {
            const spType = data.sp_type == 1 ? 'SP1' : data.sp_type == 2 ? 'SP2' : data.sp_type == 3 ? 'Terminasi Kontrak' : '-';
            this.worker_sp_data.worker_name = data.user_name;
            this.worker_sp_data.sp_val = data.sp_type;
            this.worker_sp_data.sp_type = spType;
            this.worker_sp_data.notes = data.notes;
            this.worker_sp_data.requested_by = data.request_user;
            this.worker_sp_data.company = data.company;
            this.worker_sp_data.request_date = data.created_at;
            this.worker_sp_data.document = data.sp_document || null;
        },
        resetWorkerSpData() {
            this.worker_sp_data.partner_id = '';
            this.worker_sp_data.worker_name = '';
            this.worker_sp_data.sp_val = 1;
            this.worker_sp_data.sp_type = '';
            this.worker_sp_data.notes = '';
            this.worker_sp_data.requested_by = '';
            this.worker_sp_data.company = '';
            this.worker_sp_data.request_date = '';
            this.worker_sp_data.document = null;
        },
        resetFormSp() {
            this.form_sp.type = '';
            this.form_sp.notes = '';
            this.form_sp.attachment = '';
            this.file_attachment = null;
        },
        docValidating({ worker }) {
            this.actionWorker = null;
            this.actionWorker = worker;
            this.isShowValidatingModal = true;
        },
        async openActionModal({ worker }) {
            try {
                this.modalLoading = true;
                this.selectedAction = this.actionWorker = null;
                this.actionWorker = worker;
                if (this.actionList.length > 2) {
                    this.actionList.pop();
                }
                if (worker.sp_sent) {
                    this.actionList.push({
                        name: 'View SP Details',
                        action: 'view-sp-sent'
                    });
                } else {
                    this.actionList.push({
                        name: 'Send SP',
                        action: 'send-sp'
                    });
                }
                await window.UIkit.modal("#action-modal").show();
                this.modalLoading = false;
            } catch (error) {
                this.selectedAction = null;
                await window.UIkit.modal("#action-modal").hide();
                this.modalLoading = false;
            }
        },
        async openContractModal({ worker }) {
            try {
                this.modalLoading = true;
                this.selectedAction = this.actionWorker = null;
                this.actionWorker = worker;
                await window.UIkit.modal("#contract-modal").show();
                this.modalLoading = false;
            } catch (error) {
                this.selectedAction = null;
                await window.UIkit.modal("#contract-modal").hide();
                this.modalLoading = false;
            }
        },
        async closeContractModal() {
            try {
                this.selectedAction = null;
                await window.UIkit.modal("#contract-modal").hide();
                await window.UIkit.modal("#renew-contract-modal").hide();
                await window.UIkit.modal("#download-contract-modal").hide();
                await window.UIkit.modal("#unsigned-contract-modal").hide();
                this.modalLoading = false;
            } catch (error) {
                this.selectedAction = null;
                await window.UIkit.modal("#contract-modal").hide();
                await window.UIkit.modal("#renew-contract-modal").hide();
                await window.UIkit.modal("#download-contract-modal").hide();
                await window.UIkit.modal("#unsigned-contract-modal").hide();
                this.modalLoading = false;
            }
        },
        async setMitraAction() {
            try {
                await window.UIkit.modal("#action-modal").hide();
                await window.UIkit.modal("#contract-modal").hide();

                switch(this.selectedAction) {
                case 'delete':
                    await this.deleteWorker({ workerId: this.actionWorker._id });
                    break;
                case 'hold-unhold':
                    await this.holdUnholdWorker({ status: this.actionWorker.status, workerId: this.actionWorker._id });
                    break;
                case 'view-sp-sent':
                    await this.viewMitraSpData(this.actionWorker._id);
                    break;
                case 'send-sp':
                    await this.sendMitraSp(this.actionWorker._id);
                    break;
                case 'download-contract':
                    await this.handleDownloadContract({ workerId: this.actionWorker._id });
                    break;
                case 'unsigned':
                    await this.handleUnsignedContract({ workerId: this.actionWorker._id });
                    break;
                case 'renew':
                    await this.renewContract({ workerId: this.actionWorker._id });
                    break;
                }
            } catch (error) {
                this.selectedAction = null;
                await window.UIkit.modal("#action-modal").hide();
                await window.UIkit.modal("#contract-modal").hide();
            } finally {
                this.selectedAction = null;
                await window.UIkit.modal("#action-modal").hide();
                await window.UIkit.modal("#contract-modal").hide();
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value,
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value,
            };
        },
        imgError(e) {
            e.target.src = `${this.images}/avatar.svg`;
        },
        formatDate(data) {
            if (data == null) {
                return "-";
            } else {
                return formatter.dateComplete(data);
            }
        },
        formateDateHourAndMinute(data) {
            if (data == null) {
                return "-";
            } else {
                return formatter.dateHourAndMinute(data);
            }
        },
        formatYear(data) {
            if (!data || data == '') {
                return '-';
            } else {
                return formatter.yearOnly(data);
            }
        },
        mapQuery() {
            if (this.$route.query.domicile_city_id) {
                this.meta.domicile_city_id = this.$route.query.domicile_city_id;
                this.filter.city = this.cities.find(city => city._id == this.meta.domicile_city_id);

                this.filterOffice();
            }
            if (this.$route.query.fullname) {
                this.meta.fullname = this.$route.query.fullname;
            }
            if (this.$route.query.identity_number) {
                this.meta.identity_number = this.$route.query.identity_number;
            }
            if (this.$route.query.email) {
                this.meta.email = this.$route.query.email;
            }
            if (this.$route.query.identity_filter) {
                this.meta.identity_filter = this.$route.query.identity_filter;
            }
            if (this.$route.query.start_date) {
                this.meta.start_date = this.$route.query.start_date.replace(' ', '+');
            }
            if (this.$route.query.end_date) {
                this.meta.end_date = this.$route.query.end_date.replace(' ', '+');
            }
            if (this.$route.query.sort_by) {
                this.meta.sort_by = this.$route.query.sort_by;
            }
            if (this.$route.query.filter) {
                this.meta.filter = this.$route.query.filter;
            }
            if (this.$route.query.limit) {
                this.meta.limit = this.$route.query.limit;
            }
            if (this.$route.query.page) {
                this.meta.page = this.$route.query.page;
            }
        },
        async onChangeContractVacancy() {
            this.form_renew.contract_list = [];
            if (this.form_renew.is_contract_from_vacancy) {
                const response = await this.getJobVacancyContract({ job_partner_ids: [this.selected_worker.job_partner_id] });
                if (response) {
                    this.form_renew.contract_list = response.find(data => data._id == this.selected_worker.job_partner_id).contracts;
                }
            }
        },
        async onChangeMoveOffice(office) {
            this.form_move.new_role_id = '';
            await this.getSelectedOfficeRoles({ companyOfficeId: office._id });
        },
        onSelectContract(event) {
            this.form_renew.contract_list = [];
            this.form_renew.contract_list.push(this.contractOptions.find(data=> data._id == event.target.value));
        }
    }
};
</script>

<style scoped>
.photo {
    width: 106px;
    height: 106px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}
.button-filter {
    border: 1px solid #0275D8;
    background-color: white;
    color: #0275D8;
}
.file-name {
    text-decoration: underline;
    color: blue;
}
.template-link {
    cursor: pointer;
}
thead {
    background-color: #0ABAB5;
}

th {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.filter-section .multiselect {
    width: 15rem;
}
</style>
