<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <LoaderPage v-if="is_loading" />
        <div v-else>
            <!-- Page header -->
            <div v-if="$can('READ', 'worker-payslip') || $can('WRITE', 'worker-payslip')" class="uk-flex uk-flex-middle uk-flex-between">
                <p v-if="$can('READ', 'worker-payslip')" class="uk-padding-remove uk-margin-remove uk-text-bold">Payslip List</p>
                <button
                    v-if="$can('WRITE', 'worker-payslip')"
                    class="uk-button uk-button-primary uk-border-rounded uk-flex uk-flex-middle"
                    @click="createTemplate"
                >
                    <img :src="`${images}/plus-circle-white.svg`" alt="Download icon">
                    <span class="uk-margin-small-left uk-text-bold">Create Template</span>
                </button>
            </div>
            <!-- End page header -->

            <!-- Payslip list table -->
            <div v-if="$can('READ', 'worker-payslip')" class="uk-overflow-auto uk-margin-top uk-card-default">
                <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-text-nowrap">No</th>
                            <th class="uk-text-center uk-text-nowrap">Template Name</th>
                            <th class="uk-text-center uk-text-nowrap">Month</th>
                            <th class="uk-text-center uk-text-nowrap">Year</th>
                            <th class="uk-text-center uk-text-nowrap">Number<br>of Mitra</th>
                            <th class="uk-text-center uk-text-nowrap">Number of<br>Payslip Sent</th>
                            <th
                                v-if="$can('READ', 'worker-payslip') || $can('DELETE', 'worker-payslip')"
                                class="uk-text-center uk-text-nowrap uk-table-shrink"
                            >Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="payslip_batch_list !== null && payslip_batch_list.docs.length > 0">
                        <tr v-for="(payslip, index) in  payslip_batch_list.docs" :key="index">
                            <td class="uk-text-center uk-text-nowrap">{{ setNumberList({ index }) }}</td>
                            <td
                                class="uk-text-center uk-text-nowrap uk-text-primary"
                                style="cursor: pointer;"
                                @click="goToPayslipDetail({ payslipId: payslip._id })"
                            >{{ payslip.name || '-' }}</td>
                            <td class="uk-text-center uk-text-nowrap">{{ monthFormatter({ index: payslip.month }) }}</td>
                            <td class="uk-text-center uk-text-nowrap">{{ payslip.year || '-' }}</td>
                            <td class="uk-text-center uk-text-nowrap">{{ payslip.count_mitra || 0 }}</td>
                            <td class="uk-text-center uk-text-nowrap">{{ payslip.count_mitra_distributed || 0 }}</td>
                            <td
                                v-if="$can('READ', 'worker-payslip') || $can('DELETE', 'worker-payslip')"
                                class="uk-text-center uk-text-nowrap"
                            >
                                <button
                                    v-if="$can('EDIT', 'worker-payslip')"
                                    type="button"
                                    class="uk-button uk-border-rounded uk-button-primary"
                                    @click="distributeBatch({ payslipId: payslip._id })"
                                >Blast to Mitra</button>
                                <button
                                    v-if="$can('DELETE', 'worker-payslip')"
                                    type="button"
                                    class="uk-button uk-border-rounded uk-button-danger uk-margin-small-left"
                                    @click="deleteTemplate({ payslipId: payslip._id })"
                                >Delete</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
                <pagination
                    v-if="payslip_batch_list !== null && payslip_batch_list.totalDocs > 0"
                    :total-data="payslip_batch_list.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End payslip list table -->

            <!-- Create template modal box -->
            <div v-if="$can('WRITE', 'worker-payslip')" id="create-template" class="create-template" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        @click="hideCreateTemplateModal"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-margin-remove">Create Payslip Template</h2>
                    </div>
                    <form @submit.prevent="doCreateTemplate">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label
                                        for="templateName"
                                        class="uk-form-label"
                                    >Template name</label>
                                    <input
                                        id="templateName"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="Template name"
                                        v-model="form.name"
                                    >
                                </div>
                                <div class="uk-width-1-2@s">
                                    <label
                                        for="month"
                                        class="uk-form-label"
                                    >Month</label>
                                    <select
                                        id="month"
                                        name="month"
                                        class="uk-select uk-border-rounded"
                                        v-model="form.month"
                                    >
                                        <option
                                            v-for="(month, index) in month_list"
                                            :key="index"
                                            :value="month.value"
                                        >{{ month.label }}</option>
                                    </select>
                                </div>
                                <div class="uk-width-1-2@s">
                                    <label
                                        for="year"
                                        class="uk-form-label"
                                    >Year</label>
                                    <select
                                        id="year"
                                        name="year"
                                        class="uk-select uk-border-rounded"
                                        v-model="form.year"
                                    >
                                        <option
                                            v-for="(year, index) in year_list"
                                            :key="index"
                                            :value="year"
                                        >{{ year }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="!button_loading"
                                type="button"
                                class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue"
                                @click="hideCreateTemplateModal"
                            >Cancel</button>
                            <button
                                v-if="!button_loading"
                                type="submit"
                                class="uk-button uk-margin-small-left"
                                :class="[disabledButtonDownload ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledButtonDownload"
                            >Create</button>
                            <button
                                v-if="button_loading"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End create template modal box -->

            <!-- Delete payslip modal box -->
            <div v-if="$can('DELETE', 'worker-payslip')" id="delete-payslip" class="delete-payslip" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        @click="hideDeletePayslipModal"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-medium">Confirmation</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>Are you sure want to delete this entry?</p>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="!button_delete_loading"
                            type="button"
                            class="uk-button uk-button-default uk-modal-close"
                            style="border: 1px solid #0275D8; color: #0275D8;"
                            @click="hideDeletePayslipModal"
                        >No</button>
                        <button
                            v-if="!button_delete_loading"
                            type="button"
                            class="uk-button uk-button-default uk-margin-small-left uk-button-primary"
                            @click="doDeletePayslip"
                        >Yes</button>
                        <button
                            v-if="button_delete_loading"
                            type="button"
                            class="uk-button uk-button-default"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- End delete payslip modal box -->

            <!-- Handler when user can't access this page -->
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>You are not authorized for read data this page</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler when user can't access this page -->

            <!-- Loading process modal box -->
            <div id="processing-modal" class="processing-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <div class="uk-modal-body">
                        <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                            <div class="uk-margin-top">Please wait...</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end loading process modal box -->
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from "@/utils/constant";
import { 
    notificationSuccess, 
    notificationDanger, 
    notificationDangerCustom, 
} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: 'PayslipList',
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Pagination: () => import('@/components/globals/Pagination')
    },
    data() {
        return {
            is_loading: false,
            images: PREFIX_IMAGE,
            month_list: [
                {
                    value: 1,
                    label: 'January'
                },
                {
                    value: 2,
                    label: 'February'
                },
                {
                    value: 3,
                    label: 'March'
                },
                {
                    value: 4,
                    label: 'April'
                },
                {
                    value: 5,
                    label: 'May'
                },
                {
                    value: 6,
                    label: 'June'
                },
                {
                    value: 7,
                    label: 'July'
                },
                {
                    value: 8,
                    label: 'August'
                },
                {
                    value: 9,
                    label: 'September'
                },
                {
                    value: 10,
                    label: 'October'
                },
                {
                    value: 11,
                    label: 'November'
                },
                {
                    value: 12,
                    label: 'December'
                }
            ],
            year_list: [],
            form: {
                name: null,
                month: null,
                year: null
            },
            button_loading: false,
            payslip_batch_list: null,
            meta: {
                limit: 10,
                page: 1
            },
            selected_payslip_batch_id: null,
            button_delete_loading: false
        };
    },
    computed: {
        disabledButtonDownload() {
            return (
                this.form.name === null || 
                this.form.month === null || 
                this.form.year === null
            );
        }
    },
    watch: {
        async meta() {
            try {
                await this.getPayslipList();
            } catch (error) {
                notificationDanger(error);
            }
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-template')) window.UIkit.modal('.create-template').$destroy(true);
        if (window.UIkit.modal('.delete-payslip')) window.UIkit.modal('.delete-payslip').$destroy(true);
        if (window.UIkit.modal('.processing-modal')) window.UIkit.modal('.processing-modal').$destroy(true);
    },
    async mounted() {
        try {
            this.is_loading = true;
            await this.getPayslipList();
            this.generateArrayOfYears();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.is_loading = false;
        }
    },
    methods: {
        ...mapActions({
            downloadTemplateOrCreatePayslipBatch: 'mitra/downloadTemplateOrCreatePayslipBatch',
            getPayslipBatchList: 'mitra/getPayslipBatchList',
            deletePayslipBatch: 'mitra/deletePayslipBatch',
            distributePayslipBatch: 'mitra/distributePayslipBatch'
        }),
        async getPayslipList() {
            try {
                this.payslip_batch_list = await this.getPayslipBatchList(this.meta);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async createTemplate() {
            try {
                await window.UIkit.modal('#create-template').show();
                this.fillForm();
            } catch (error) {
                notificationDanger(error);
            }
        },
        goToPayslipDetail({ payslipId }) {
            this.$router.push(`/admin/workers/payslip/detail/${payslipId}`);
        },
        async deleteTemplate({ payslipId }) {
            try {
                await window.UIkit.modal('#delete-payslip').show();
                this.selected_payslip_batch_id = payslipId;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async distributeBatch({ payslipId }) {
            try {
                this.selected_payslip_batch_id = payslipId;
                await this.doDistributeBatch();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideCreateTemplateModal() {
            try {
                await window.UIkit.modal('#create-template').hide();
                this.resetForm();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideDeletePayslipModal() {
            try {
                await window.UIkit.modal('#delete-payslip').hide();
                this.selected_payslip_batch_id = null;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateTemplate() {
            try {
                this.button_loading = true;
                const response = await this.downloadTemplateOrCreatePayslipBatch(this.form);
                if (response && response.status === 'OK') {
                    await this.hideCreateTemplateModal();
                    await this.getPayslipList();
                    notificationSuccess(`${response.result.name} payslip template created successfully`);
                } else {
                    notificationDangerCustom(` ${this.form.name} payslip template failed to create`);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.button_loading = false;
            }
        },
        async doDeletePayslip() {
            try {
                this.button_delete_loading = true;
                const params = { id: this.selected_payslip_batch_id };
                const response = await this.deletePayslipBatch(params);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await this.hideDeletePayslipModal();
                    await this.getPayslipList();
                    notificationSuccess('Payslip successfully removed');
                } else {
                    notificationDangerCustom('Payslip failed to delete');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.button_delete_loading = false;
            }
        },
        async doDistributeBatch() {
            try {
                await window.UIkit.modal('#processing-modal').show();
                const params = { batch_id: this.selected_payslip_batch_id };
                const response = await this.distributePayslipBatch(params);
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.selected_payslip_batch_id = null;
                    await this.getPayslipList();
                    notificationSuccess('Payslip successfully blasted');
                } else {
                    notificationDangerCustom('Payslip failed to blast');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#processing-modal').hide();
            }
        },
        fillForm() {
            this.form = {
                name: null,
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear()
            };
        },
        resetForm() {
            this.form = {
                name: null,
                month: null,
                year: null
            };
        },
        monthFormatter({ index }) {
            return formatter.getMonthString({ index });
        },
        setNumberList({ index }) {
            if(this.payslip_batch_list !== null) {
                const number = index + 1;
                if(this.payslip_batch_list.page === 1) {
                    return number;
                } else {
                    if(number !== 10) {
                        return `${this.payslip_batch_list.page - 1}${number}`;
                    } else {
                        return `${number * this.payslip_batch_list.page}`;
                    }
                } 
            } else {
                return '-';
            }
        },
        generateArrayOfYears() {
            const max = new Date().getFullYear();
            const min = max - 9;
            const years = [];
            for(let i = max; i >= min; i--) {
                if(i >= 2020) {
                    years.push(i);
                }
            }
            this.year_list = years;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.myrobin-border-blue {
    border: 1px solid #0275D8;
}
.myrobin-color-blue {
    color: #0275D8;
}
</style>
