import Vue from 'vue';
import api from '@/utils/api';
import { NEW_SHIFT, DAILY_SHIFT } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    shifts: []
};

const mutations = {
    "SET_SHIFT": (state, payload) => {
        Vue.set(state, 'shifts', payload);
    },
    "SET_COMPANY_OFFICE": (state, payload) => {
        Vue.set(state, 'company_offices', payload);
    },
    "SET_SHIFT_DETAIL": (state, payload) => {
        Vue.set(state, 'shift_detail', payload);
    }
};

const actions = {
    async uploadShiftTemplate(context, data) {
        try {
            const response = await api.apiPostAuth(DAILY_SHIFT.UPLOAD_SHIFT_TEMPLATE, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyOffice(context, data) {
        try {
            const response = await api.apiGetAuth(NEW_SHIFT.GET_COMPANY_OFFICE, data);
            context.commit('SET_COMPANY_OFFICE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShiftList(context, data) {
        try {
            const response = await api.apiGetAuth(NEW_SHIFT.GET_SHIFT_LIST, data);
            context.commit('SET_SHIFT', response.data.result);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraList(context, data) {
        try {
            const response = await api.apiGetAuth(NEW_SHIFT.GET_MITRA_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShiftDetail(context, shift_id) {
        try {
            const response = await api.apiGetAuth(NEW_SHIFT.SHOW_COMPANY_SHIFT(shift_id));
            context.commit('SET_SHIFT_DETAIL', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addShift(context, data) {
        try {
            const response = await api.apiPostAuth(NEW_SHIFT.ADD_SHIFT, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editShift(context, data) {
        try {
            const shift_id  = data.shift_id;
            delete data.shift_id;
            const response = await api.apiPutAuth(NEW_SHIFT.EDIT_SHIFT(shift_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteShift(context, data) {
        try {
            const shift_id  = data.shift_id;
            delete data.shift_id;
            const response = await api.apiDeleteAuth(NEW_SHIFT.DELETE_SHIFT(shift_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteMitraShift(context, data) {
        try {
            const job_partner_id  = data;
            const response = await api.apiDeleteAuth(NEW_SHIFT.DELETE_MITRA_SHIFT(job_partner_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async moveMitraShift(context, data) {
        try {
            const job_partner_id  = data.job_partner_id;
            delete data.job_partner_id;
            const response = await api.apiPutAuth(NEW_SHIFT.MOVE_MITRA_SHIFT(job_partner_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addMitraShift(context, data) {
        try {
            const response = await api.apiPostAuth(NEW_SHIFT.ADD_MITRA_SHIFT, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },

    // Astro
    async getAstroShiftList(context, data) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.GET_SHIFT_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAstroMitraList(context, data) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.GET_MITRA_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAstroShiftDetail(context, shift_id) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.GET_DETAIL_SHIFT(shift_id));
            context.commit('SET_SHIFT_DETAIL', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addAstroDailyShift(context, data) {
        try {
            const response = await api.apiPostAuth(DAILY_SHIFT.ADD_SHIFT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editAstroDailyShift(context, data) {
        try {
            const shift_id  = data.shift_id;
            delete data.shift_id;
            const response = await api.apiPutAuth(DAILY_SHIFT.EDIT_SHIFT(shift_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async assignMitraShift(context, data) {
        try {
            const response = await api.apiPostAuth(DAILY_SHIFT.ASSIGN_MITRA_SHIFT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAstroShiftRecapDate(context, data) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.GET_SHIFT_RECAP_DATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAstroShiftRecapUser(context, data) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.GET_SHIFT_RECAP_USER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteAstroDailyShift(context, data) {
        try {
            const shift_id  = data.shift_id;
            delete data.shift_id;
            const response = await api.apiDeleteAuth(DAILY_SHIFT.DELETE_SHIFT(shift_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShiftUploadErrorLogs(context, data) {
        try {
            const response = await api.apiGetAuth(DAILY_SHIFT.GET_UPLOAD_SHIFT_ERROR_LOGS, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },

    async getShiftListNoPaginate(contextm, data) {
        try {
            const response = await api.apiGetAuth(NEW_SHIFT.GET_SHIFT_LIST_NO_PAGINATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {
    shifts: state => {
        return state.shifts;
    },
    company_offices: state => {
        return state.company_offices;
    },
    shift_detail: state => {
        return state.shift_detail;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
