import api from '@/utils/api';
import { API_DAILY_RECAP_WORKER_ATTENDANCE } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getDailyRecapWorkerAttendance(context, data) {
        try {
            const response = await api.apiGetAuth(API_DAILY_RECAP_WORKER_ATTENDANCE.RECAP, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDailyRecapPerQuery(context, data) {
        try {
            const response = await api.apiGetAuth(API_DAILY_RECAP_WORKER_ATTENDANCE.RECAP_PER_QUERY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSummaryAttendance(context, data) {
        try {
            const response = await api.apiGetAuth(API_DAILY_RECAP_WORKER_ATTENDANCE.RECAP_SUMMARY_ATTENDANCE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSummaryAttendancePerQuery(context, data) {
        try {
            const response = await api.apiGetAuth(API_DAILY_RECAP_WORKER_ATTENDANCE.RECAP_SUMMARY_ATTENDANCE_PER_QUERY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
