<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable" v-if="$can('READ', 'attendance-method')">
        <div class="uk-margin">
            <button
                class="uk-button uk-button-large uk-margin-right uk-border-rounded"
                :class="tabActive === 'method' ? 'uk-button-primary' : 'uk-button-default uk-text-muted'"
                @click="tabActive = 'method'"
            >
                Method Setting
            </button>
            <button
                v-if="$can('EDIT', 'attendance-method')"
                class="uk-button uk-button-default uk-button-large uk-border-rounded"
                :class="tabActive === 'correction' ? 'uk-button-primary' : 'uk-button-default uk-text-muted'"
                @click="tabActive = 'correction'"
            >
                Correction Limit
            </button>
        </div>
        <section v-if="tabActive === 'method'">
            <div class="uk-margin" v-if="profile!=null">
                <div uk-grid class="uk-grid-small uk-flex uk-flex-column uk-flex-center">
                    <div disabled>
                        <img :src="`${images}/office.svg`" alt="" style="width:25px" class="uk-margin-right">
                        <span style="font-size:18px;weight:400">{{profile.company_name}}</span>
                    </div>
                    <div disabled>
                        <img :src="`${images}/maps.svg`" alt="" style="width:25px" class="uk-margin-right">
                        <span style="font-size:18px;weight:400">{{profile.company_address}}</span>
                    </div>
                </div>
            </div>
            <div class="uk-margin uk-flex uk-flex-between">
                <div uk-grid class="uk-grid-small uk-flex uk-flex-middle">
                    <div class="uk-form-controls">
                        <input
                            class="uk-input"
                            type="text"
                            placeholder="Type Office Name here"
                            v-model="meta.name"
                            @keyup.enter="getOfficeList"
                        >
                    </div>
                    <div class="uk-form-controls">
                        <select class="uk-select" v-model="meta.attendance_method" @change="getOfficeList">
                            <option :value="null">Select Attendance Method</option>
                            <option value=0>Scanned by Employer Supervisor</option>
                            <option value=1>Self-scan on Office</option>
                            <option value=2>Photo upload</option>
                        </select>
                    </div>
                </div>
                <div
                    v-if="$can('READ', 'attendance-method')"
                    class="text-primary"
                    @click="$router.push('/admin/attendance_setting')"
                >
                    <img :src="`${images}/mi_filter.svg`" alt="" width="20"> Settings
                </div>
            </div>
    
            <div v-if="$can('READ', 'attendance-method')" class="uk-border-rounded uk-card uk-card-default uk-margin">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-medium uk-table-divider">
                        <thead>
                            <tr>
                                <th class="uk-text-center uk-table-shrink">No</th>
                                <th class="uk-text-center uk-width-auto">Office Name</th>
                                <th class="uk-text-center uk-width-auto">Office Address</th>
                                <th class="uk-text-center uk-width-auto">Method</th>
                                <th v-if="$can('EDIT', 'attendance-method')" class="uk-text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="company_offices.docs.length>0">
                            <tr v-for="(office, i) in company_offices.docs" :key="i">
                                <td class="uk-text-center">{{i+1}}</td>
                                <td class="uk-text-center uk-width-auto">{{office.name}}</td>
                                <td class="uk-text-center uk-width-auto">{{office.address}}</td>
                                <td class="uk-text-center">{{statusLabel(office)}}</td>
                                <td v-if="$can('EDIT', 'attendance-method')" class="uk-text-center">
                                    <button
                                        class="uk-margin-small-right uk-button-small uk-button-primary uk-border-rounded"
                                        @click="showModal('edit-modal', office)"
                                        v-if="$can('EDIT', 'attendance-method')"
                                    >Edit</button>
                                </td>
                            </tr>
                        </tbody>
                        <empty-table v-else :colspan="6"/>
                    </table>
                </div>
                <pagination
                    :total-data="company_offices.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>You are not authorized read data from this page</p>
                        </span>
                    </div>
                </div>
            </div>
            <div id="edit-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-modal')"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">Change Attendance Method</h2>
                    </div>
                    <div class="uk-modal-body" v-if="selected_office!=null">
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Office Name</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" type="text" disabled :value="selected_office.name">
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Office Address</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" type="text" disabled :value="selected_office.address">
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Attendance Method</label>
                            <div class="uk-form-controls">
                                <select class="uk-select" name="attendance_method" v-model="form.attendance_method" v-validate="'required'">
                                    <option value=0>Scanned by Employer Supervisor</option>
                                    <option value=1>Self-scan on Office</option>
                                    <option value=2>Photo upload</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="saveMethod">Save</button>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="saveMethod">Save</button>
                    </div>
                </div>
            </div>
        </section>
        <section v-else>
            <h3 class="uk-text-bold">Attendance Correction Limit</h3>
            <span>Input Correction Limit</span>
            <div class="uk-margin">
                <div class="uk-inline">
                    <span
                        class="uk-form-icon uk-form-icon-flip uk-background-muted uk-text-secondary uk-padding-small"
                        style="border: 1px solid #e5e5e5"
                    >
                        Day
                    </span>
                    <input class="uk-input" placeholder="Please Input" v-model="attendance_correction_limit" type="text">
                </div>
                <div v-if="!limitCorrect" class="uk-text-danger" style="margin-top: 3px">limit tidak valid</div>
                <div class="uk-margin-top">
                    <button
                        class="uk-button uk-button-default uk-margin-right uk-border-rounded"
                        style="border-color: #0275D8; color: #0275D8;"
                        @click="clearCorrection"
                    >
                        Clear
                    </button>
                    <button
                        class="uk-button uk-button-blue uk-border-rounded"
                        style="color: #ffffff"
                        :style="
                            attendance_correction_limit && limitCorrect
                                ? { 'background': '#0275D8', 'borderColor': '#0275D8' }
                                : { 'borderColor': '#c8c8c8', 'background': '#c8c8c8' }
                        "
                        :disabled="!attendance_correction_limit"
                        @click="saveLimit"
                    >
                        Save
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
// import Multiselect from 'vue-multiselect';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";

export default {
    data(){
        return {
            tabActive: 'method',
            images: PREFIX_IMAGE,
            meta: {
                limit: 10,
                page: 1,
                name: '',
                attendance_method: null
            },
            attendance_correction_limit: "",
            limitCorrect: true,
            form: {
                attendance_method: null
            },
            company_offices: {docs: []},
            isLoading: false,
            isEdit: false,
            profile: null,
            selected_office: null,
        };
    },
    watch: {
        attendance_correction_limit() {
            if (this.attendance_correction_limit !== null) {
                const globalRegex = new RegExp('^[0-9]*$');
                this.limitCorrect = globalRegex.test(this.attendance_correction_limit);
            } else {
                this.limitCorrect = false;
            }
        }
    },
    components: {
        // Multiselect,
        EmptyTable,
        Pagination,
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    async mounted() {
        this.profile = await this.getProfile();
        await this.getOfficeList();
        await this.getCorrectionLimit();
    },
    methods: {
        ...mapActions({
            getProfile: 'auth/getProfile',
            getCompanyOfficePaginate: 'company_office/getCompanyOfficePaginate',
            patchCompanyOfficeAttendanceMethod: 'company_office/patchCompanyOfficeAttendanceMethod',
            patchCorrectionLimit: 'company/patchCorrectionLimit',
            getCorrectionLimits: 'company/getCorrectionLimit',
        }),
        async getOfficeList(){
            this.isLoading = true;
            this.meta.name = formatter.sanitizeString(this.meta.name);
            this.company_offices = await this.getCompanyOfficePaginate(this.meta);
            this.isLoading = false;
        },
        async saveLimit(){
            this.isLoading = true;
            await this.patchCorrectionLimit(this.attendance_correction_limit);
            notificationSuccess('Update saved!');
            this.isLoading = false;
        },
        async clearCorrection() {
            this.isLoading = true;
            this.attendance_correction_limit = 0;
            await this.patchCorrectionLimit(this.attendance_correction_limit);
            this.attendance_correction_limit = '';
            notificationSuccess('Update saved!');
            this.isLoading = false;
        },
        async getCorrectionLimit(){
            this.isLoading = true;
            this.attendance_correction_limit = await this.getCorrectionLimits() ?? '';
            this.isLoading = false;
        },
        async showModal(modal_name, data=null) {
            switch(modal_name) {
            case 'edit-modal':
                this.fillForm(data);
                this.isEdit = true;
                await window.UIkit.modal('#edit-modal').show();
                break;
            }
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'edit-modal':
                await window.UIkit.modal('#edit-modal').hide();
                this.resetForm();
                this.isEdit = false;
                break;
            }
        },
        async changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
            await this.getOfficeList();
        },
        async changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
            await this.getOfficeList();
        },
        resetForm(){
            this.form = {
                attendance_method: null
            };
            this.selected_office = null;
        },
        fillForm(data){
            this.selected_office = data;
            this.form.id = data._id;
            this.form.attendance_method = data.attendance_method;
        },
        async saveMethod() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.form.attendance_method = Number(this.form.attendance_method);

                let response = await this.patchCompanyOfficeAttendanceMethod(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Update saved!');
                    await this.getOfficeList();
                    this.hideModal('edit-modal');
                } else {
                    notificationDanger('Failed to Update.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        statusLabel(data){
            if (data == null) {
                return 'Unknown';
            } else if (data.attendance_method == 0) {
                return 'Scan by Employer';
            } else if (data.attendance_method == 1) {
                return 'Self-scan on Office';
            } else if (data.attendance_method == 2) {
                return 'Photo Upload';
            }
        },
    },
};
</script>

<style scoped>
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.text-primary {
    color: #0ABAB5;
    filter: invert(55%) sepia(98%) saturate(1621%) hue-rotate(136deg) brightness(93%) contrast(92%);
    cursor: pointer;
}
.uk-button-primary {
    background-color: #0ABAB5
}
</style>
