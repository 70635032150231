<template>
    <div>
        <div class="uk-flex uk-flex-between myrobin-background-white uk-padding-small">
            <div class="uk-flex uk-flex-middle">
                <p class="uk-text-bold uk-margin-remove">Mitra Data Validation</p>
            </div>
            <div class="uk-flex uk-flex-middle" style="gap: 10px;">
                <button
                    type="button"
                    class="uk-button uk-border-rounded myrobin-background-white myrobin-color-green myrobin-border-green uk-position-relative uk-width-auto"
                    @click="$emit('cancel-validation')"
                >Cancel</button>
                <button
                    type="button"
                    class="uk-button uk-border-rounded uk-position-relative uk-width-auto"
                    :class="disableSaveValidationButon
                        ? 'myrobin-background-grey myrobin-border-grey myrobin-color-white'
                        : 'myrobin-background-primary myrobin-color-white'
                    "
                    :disabled="disableSaveValidationButon"
                    @click="saveValidation()"
                >Save</button>
            </div>
        </div>
        <div class="uk-width-1-1 uk-card uk-card-default uk-margin-top">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>NPWP Number</td>
                            <td>
                                <input v-model="form.npwp" class="uk-input" type="text">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>NPWP Photo (from PWA)</div>
                                <p class="uk-text-italic text-color-blue">NPWP Photo by MDM</p>
                            </td>
                            <td>
                                <div v-if="data.npwp_mitra && data.npwp_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.npwp_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.npwp_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ data.npwp_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                                <p>
                                    <input v-model="form.npwp_mdm" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>CV</td>
                            <td>
                                <template v-if="data.cv_mitra && data.cv_mitra.file_url">
                                    <img
                                        class="uk-preserve-width uk-margin-small-right pointer-cursor"
                                        :src="`${images}/visibility.svg`"
                                        width="20"
                                        alt="View attachment icon"
                                        @click="previewFile({ file_url: data.cv_mitra.file_url })"
                                    >
                                    <img
                                        class="uk-preserve-width uk-margin-small-right pointer-cursor"
                                        :src="`${images}/cloud_download.svg`"
                                        width="20"
                                        alt="Download attachment icon"
                                        @click="downloadFile({ file_url: data.cv_mitra.file_url })"
                                    >
                                </template>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Ijazah</td>
                            <td>
                                <div v-if="data.ijazah_mitra && data.ijazah_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.ijazah_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.ijazah_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ data.ijazah_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                            </td>
                        </tr>
                        <tr v-for="(data1, id) in platinumDataset1" :key="`A${id}`">
                            <td class="uk-text-left uk-text-nowrap">{{ data1.label }}</td>
                            <td class="uk-text-left uk-text-nowrap uk-flex uk-flex-column">
                                <div v-if="data1.type == 'select'" class="uk-width-expand">
                                    <select v-model="form[data1.value]" class="uk-select">
                                        <option v-for="option in data1.options" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                                <input v-else v-model="form[data1.value]" class="uk-input" type="text">
                                <span
                                    v-show="data1.value == 'children_amount' && Number(form.children_amount) > 99"
                                    class="uk-text-small uk-text-danger"
                                >Please ensure the value does not exceed 99</span>
                                <span
                                    v-show="(data1.value == 'children_amount' || data1.value == 'sim_number') && hasError[data1.value]"
                                    class="uk-text-small uk-text-danger"
                                >The entered data must be in numeric format</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>SIM Photo (from PWA)</div>
                                <p class="uk-text-italic text-color-blue">SIM Photo by MDM</p>
                            </td>
                            <td>
                                <div v-if="data.sim_mitra && data.sim_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.sim_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.sim_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ data.sim_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                                <p>
                                    <input v-model="form.sim_mdm" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>STNK Photo (from PWA)</div>
                                <p class="uk-text-italic text-color-blue">STNK Photo by MDM</p>
                            </td>
                            <td>
                                <div v-if="data.stnk_mitra && data.stnk_mitra.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="data.stnk_mitra.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: data.stnk_mitra.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ data.stnk_mitra.file_path }}</span>
                                </div>
                                <p v-else>-</p>
                                <p>
                                    <input v-model="form.stnk_mdm" class="uk-input" type="text">
                                </p>
                            </td>
                        </tr>
                        <tr v-for="(data2, id) in platinumDataset2" :key="`B${id}`">
                            <td class="uk-text-left uk-text-nowrap">{{ data2.label }}</td>
                            <td class="uk-text-left uk-text-nowrap">
                                <div v-if="data2.type == 'image'" class="uk-width-expand">
                                    <div v-if="data[data2.value] && data[data2.value].file_url" class="uk-flex uk-flex-middle">
                                        <img
                                            :src="data[data2.value].file_url"
                                            class="uk-margin-right"
                                            alt="User ktp photo"
                                            width="30px"
                                            @click="previewImage({ file_url: data[data2.value].file_url })"
                                        >
                                        <span class="uk-text-truncate">{{ data[data2.value].file_path }}</span>
                                    </div>
                                    <p v-else>-</p>
                                </div>
                                <input v-model="form[data2.value]" class="uk-input" type="text">
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p class="uk-text-bold">Additional Document (if any)</p>
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-table-shrink myrobin-color-white">Title</th>
                            <th class="uk-text-left myrobin-color-white">Description</th>
                        </tr>
                    </thead>
                    <tbody v-if="data.other_docs.length > 0">
                        <tr v-for="(doc, id) in data.other_docs" :key="`C${id}`">
                            <td class="uk-text-left uk-text-nowrap">{{ doc.name }}</td>
                            <td class="uk-flex uk-flex-middle">
                                <div v-if="doc.document_url && doc.document_url.file_url" class="uk-flex uk-flex-middle">
                                    <img
                                        :src="doc.document_url.file_url"
                                        class="uk-margin-right"
                                        alt="User ktp photo"
                                        width="30px"
                                        @click="previewImage({ file_url: doc.document_url.file_url })"
                                    >
                                    <span class="uk-text-truncate">{{ doc.document_url.file_path }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <EmptyTable v-else :colspan="2" empty-text="No entries found" />
                </table>
            </div>
        </div>

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
        <PreviewPDFModal
            v-if="modal.isShowPreviewPDFModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewPDFModal = false"
        />
        <PreviewOfficeModal
            v-if="modal.isShowPreviewOfficeModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewOfficeModal = false"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import { contentType } from '@/utils/helper';
import { notificationSuccess, notificationDangerCustom } from "@/utils/notification";

export default {
    name: 'PlatinumValidationTableEdit',
    components: {
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        PreviewImageModal: () => import('@/components/globals/modals/ImageViewer'),
        PreviewPDFModal: () => import('@/components/globals/modals/PdfViewer'),
        PreviewOfficeModal: () => import('@/components/globals/modals/OfficeViewer'),
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            form: { ...this.$props.data },
            platinumDataset1: [
                {
                    label: 'Religion',
                    type: 'select',
                    value: 'religion',
                    options: [
                        { value: null, text: "Pilih agama:" },
                        { value: "Islam", text: "Islam" },
                        { value: "Protestan", text: "Protestan" },
                        { value: "Katolik", text: "Katolik" },
                        { value: "Hindu", text: "Hindu" },
                        { value: "Budha", text: "Budha" },
                        { value: "Konghucu", text: "Konghucu" },
                    ]
                },
                {
                    label: 'Gender',
                    type: 'select',
                    value: 'gender',
                    options: [
                        { value: null, text: "Pilih jenis kelamin:" },
                        { value: "male", text: "Laki-laki" },
                        { value: "female", text: "Perempuan" }
                    ]
                },
                {
                    label: "Mitra's Mother Name",
                    value: 'mother_name'
                },
                {
                    label: 'Blood Type',
                    type: 'select',
                    value: 'blood_type',
                    options: [
                        { value: null, text: "Pilih golongan darah:" },
                        { value: "A", text: "A" },
                        { value: "B", text: "B" },
                        { value: "AB", text: "AB" },
                        { value: "O", text: "O" }
                    ]
                },
                {
                    label: 'Number of Children',
                    value: 'children_amount'
                },
                {
                    label: 'SIM Number',
                    value: 'sim_number'
                },
            ],
            platinumDataset2: [
                {
                    label: 'BPJS TK Number',
                    value: 'bpjs_tk'
                },
                {
                    label: 'BPJS Kes Number',
                    value: 'bpjs_kes'
                },
                {
                    label: 'Paklaring Photo from Prev. Company',
                    type: 'image',
                    value: 'paklaring_mitra'
                },
                {
                    label: 'PIC Prev Company Name',
                    value: 'previous_company_name'
                },
                {
                    label: 'Relation with PIC Prev Company ',
                    value: 'previous_company_role'
                },
                {
                    label: 'PIC Prev Company Number',
                    value: 'previous_company_contact'
                },
            ],
            numberRegex: /^\d*$/,
            hasError: {
                children_amount: false,
                sim_number: false,
            },
            previewSrc: null,
            modal: {
                isShowPreviewImageModal: false,
                isShowPreviewPDFModal: false,
                isShowPreviewOfficeModal: false,
            },
        };
    },
    computed: {
        disableSaveValidationButon() {
            let disabled = false;
            if (
                this.hasError.children_amount || this.hasError.sim_number ||
                (this.form.children_amount && Number(this.form.children_amount) > 99)
            ) {
                disabled = true;
            }

            return disabled;
        }
    },
    watch: {
        'form.children_amount'(value) {
            this.hasError.children_amount = !this.numberRegex.test(value);
        },
        'form.sim_number'(value) {
            this.hasError.sim_number = !this.numberRegex.test(value);
        },
    },
    methods: {
        ...mapActions({
            validateKYCDataPlatinum: 'kyc/validateKYCDataPlatinum',
        }),
        async saveValidation() {
            const payload = { id: this.$route.params.id };
            if (this.form.npwp) {
                payload.npwp = this.form.npwp;
            }
            if (this.form.npwp_mdm) {
                payload.npwp_mdm = this.form.npwp_mdm;
            }
            if (this.form.religion) {
                payload.religion = this.form.religion;
            }
            if (this.form.gender) {
                payload.gender = this.form.gender;
            }
            if (this.form.mother_name) {
                payload.mother_name = this.form.mother_name;
            }
            if (this.form.blood_type) {
                payload.blood_type = this.form.blood_type;
            }
            if (this.form.children_amount) {
                payload.children_amount = this.form.children_amount;
            }
            if (this.form.sim_number) {
                payload.sim_number = this.form.sim_number;
            }
            if (this.form.sim_mdm) {
                payload.sim_mdm = this.form.sim_mdm;
            }
            if (this.form.stnk_mdm) {
                payload.stnk_mdm = this.form.stnk_mdm;
            }
            if (this.form.bpjs_tk) {
                payload.bpjs_tk = this.form.bpjs_tk;
            }
            if (this.form.bpjs_kes) {
                payload.bpjs_kes = this.form.bpjs_kes;
            }
            if (this.form.previous_company_name) {
                payload.previous_company_name = this.form.previous_company_name;
            }
            if (this.form.previous_company_role) {
                payload.previous_company_role = this.form.previous_company_role;
            }
            if (this.form.previous_company_contact) {
                payload.previous_company_contact = this.form.previous_company_contact;
            }

            const response = await this.validateKYCDataPlatinum(payload);
            if (response && response.status == 'OK') {
                notificationSuccess('Mitra platinum data validated!');
            } else {
                notificationDangerCustom('Failed to validate mitra platinum data.');
            }

            this.$emit('save-validation');
        },
        previewImage({ file_url }) {
            this.previewSrc = file_url;
            this.modal.isShowPreviewImageModal = true;
        },
        previewFile({ file_url }) {
            console.log('f == ', file_url);
            this.previewSrc = file_url;
            this.modal[contentType(this.previewSrc)] = true;
        },
        downloadFile({ file_url }) {
            window.location = file_url;
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
    color: white !important;
}
tbody, input {
    color: #000 !important;
}
input:disabled {
    background-color: #e5e5e5 !important;
}
.myrobin-color-white {
    color: #ffffff;
}
</style>
