<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin-small-bottom">
            <div class="uk-margin-auto-vertical uk-text-bold">Salary Report Details</div>
            <button
                @click="$router.back()"
                >
                <img :src="`${images}/cross.svg`" alt="" width="25">
            </button>
        </div>
        <div v-if="data" class="uk-flex uk-flex-left">
            <div class="uk-width-auto">
                <table id="table-data">
                    <tbody>
                        <tr>
                            <td>Salary Report Name</td>
                            <td>: {{ data.batch.name }}</td>
                        </tr>
                        <tr>
                            <td>Creation Date</td>
                            <td>: {{ moment(data.batch.created_at).format('DD MMM YYYY') }}</td>
                        </tr>
                        <tr>
                            <td>Number of Workers</td>
                            <td>: {{ data.batch.count_mitra }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="uk-margin-xlarge-left uk-width-auto uk-flex uk-flex-column">
                <div>
                    <table id="table-data">
                        <tbody>
                            <tr>
                                <td>Total Income</td>
                                <td>: {{ formatCurrency(data.batch.total_income) }}</td>
                            </tr>
                            <tr>
                                <td>Total Expense</td>
                                <td>: {{ formatCurrency(data.batch.total_expense) }}</td>
                            </tr>
                            <tr>
                                <td>Generated By</td>
                                <td>: {{ data.batch.updated_by.fullname || '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Main Table -->
        <div class="uk-card uk-card-default uk-margin"
        v-if="data && !consist(user_cred.roles, ['user-marketing'])">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand">Full Name</th>
                            <th class="uk-text-center">Job Role</th>
                            <th class="uk-text-center">Office</th>
                            <th class="uk-text-center">Total Work<br>Days</th>
                            <th class="uk-text-center">Total Work<br>Hours</th>
                            <th class="uk-text-center">Income</th>
                            <th class="uk-text-center">Expense</th>
                            <th class="uk-text-center">Total Payment</th>
                            <th class="uk-text-center">Status</th>
                            <th class="uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="data.docs.length>0">
                        <tr v-for="(worker, i) in data.docs" :key="i">
                            <td class="uk-text-left uk-text-justify uk-text-middle">
                                <a style="text-decoration: none;" @click="detailCandidate({ salary_id: worker.salary._id })" href="javascript:void(0)">{{worker.fullname || '-'}}</a>
                            </td>
                            <td class="uk-text-center uk-text-middle">{{ worker.role }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.company_office }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.workdays }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.workhours }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(worker.income)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(worker.expense)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(worker.total_payment)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ worker.status }}</td>
                            <td class="uk-text-center uk-text-middle">
                                <button v-if="worker.salary.status == 1" class="uk-button uk-button-small uk-button-primary uk-border-rounded" @click="showModal('transfer', worker)">Transfer</button>
                                <button id="disabled" v-else class="uk-button uk-button-small uk-button-primary uk-border-rounded">Transfer</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="10"/>
                </table>
            </div>
            <pagination
                :total-data="data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End of Main Table -->

        <!-- Modal box Transfer -->
        <div id="transfer" class="transfer" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog" v-if="selected_data && selected_data.salary && selected_data.bank_info ">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('transfer')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Payment Transfer Details</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        Below are payment details for {{selected_data.fullname}} ({{selected_data.role}} at {{selected_data.company_office}})<br><br>
                        Account Holder: {{selected_data.bank_info.bank_account_name}}<br>
                        Bank: {{selected_data.bank_info.bank_name}}<br>
                        Account Number: {{selected_data.bank_info.bank_account_number}}<br><br>
                        <span class="uk-text-bold">Total Payment: {{formatCurrency(parseInt(selected_data.total_payment))}}</span>
                    </div>
                    <div class="uk-margin">
                        <form class="uk-form-stacked">
                            <legend>Please input reference number for the transfer</legend>
                            <div class="uk-margin">
                                <label class="uk-form-label" for="ref_number">Reference Number<span class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input class="uk-input" id="ref_number" type="number" v-model="selected_data.salary.reference">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="transferSalary({
                        _id: selected_data._id.salary_id,
                        reference: selected_data.salary.reference
                        })">Transfer</button>
                </div>
            </div>
        </div>
        <!-- End modal box Transfer -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import moment from 'moment';
moment.locale('id');
import { PREFIX_IMAGE } from '@/utils/constant';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';

export default {
    data({ $route }) {
        return {
            moment: moment,
            meta: $route.query,
            data: null,
            images: PREFIX_IMAGE,
            selected_data: null
        };
    },
    components: {
        EmptyTable,
        Pagination
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        async meta() {
            await this.getData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.transfer')) window.UIkit.modal('.transfer').$destroy(true);
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        ...mapActions({
            getSalaryReportList: 'salary/getSalaryReportList',
            postTransferSalary: 'salary/postTransferSalary',
        }),
        detailCandidate(data) {
            this.$router.push({ path: `detail`, query: data });
        },
        async getData() {
            this.data = await this.getSalaryReportList(this.meta);
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'transfer':
                this.selected_data = null;
                await window.UIkit.modal('#transfer').hide();
                break;
            }
        },
        async showModal(modal_name, data=null) {
            switch(modal_name) {
            case 'transfer':
                this.selected_data = data;
                await window.UIkit.modal('#transfer').show();
                break;
            }
        },
        async transferSalary(data) {
            if (!data.reference) {
                return notificationDangerCustom('"Reference Number" must be filled');
            }
            const response = await this.postTransferSalary(data);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('transfer');
                await this.getData(this.meta);
            } else {
                notificationDangerCustom('Failed to transfer salary!');
            }
        }
    }
};
</script>

<style scoped>
#table-data td {
    padding: 8px;
    padding-left: 0px;
    font-size: 14px;
    font-weight: 400;
    border-style: hidden;
    border-collapse: collapse;
}
#table-data tr:nth-child(even){background-color: white;}
#card-data {
    color: black;
}
#disabled {
    background: #9E9E9E;
    color: white;
    cursor: not-allowed;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
</style>
