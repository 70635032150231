<template>
    <div class="main uk-position-relative uk-margin-top card-scrollable">
        <!-- Filter -->
        <div class="uk-container uk-container-expand">
            <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom uk-grid-small" uk-grid>
                <div class="uk-width-1-3">
                    <p class="uk-margin-small-bottom">Please choose date</p>
                    <div class="uk-inline uk-width-1-1" @click="showModal">
                        <a href="#" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></a>
                        <input class="uk-input" type="text" :value="filterDateText()" disabled>
                    </div>
                </div>
                <button 
                    class="uk-button uk-button-primary uk-border-rounded text-semibold myrobin-background-primary"
                    :class="[meta.start_date && meta.end_date ? '': 'disabled-button']"
                    @click="gotoDetail"
                    :disabled="!(meta.start_date && meta.end_date)"
                >
                    <span>View Detailed Report</span>
                </button>
            </div>
            <div 
                class="uk-flex uk-flex-row uk-border-rounded alert-warning uk-padding-small">
                <img class="uk-margin-small-right" :src="`${images}/warning-icon-black.svg`" alt="">
                <p class="uk-margin-remove">
                    {{ meta.start_date && meta.end_date ? 
                        `Overtime data for ${formatDate(meta.start_date)} to ${formatDate(meta.end_date)}` 
                        : 'Please select the date range above to show overtime data.' 
                    }}
                </p>
            </div>
        </div>

        <div class="uk-container uk-container-expand uk-margin-small-top">
            <div class="total-card-container uk-margin-bottom">
                <div class="uk-flex uk-flex-column">
                    <div class="uk-margin-small-top">
                        <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                            uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                            <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                <p class="uk-margin-remove">Amount of mitra who <span class="uk-text-bold">requested</span> for overtime</p>
                            </div>
                            <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                style="background-color: #FFE500;">
                                {{ data.mitra_request_overtime_count || 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="uk-margin-small-top">
                        <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                            uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                            <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                <p class="uk-margin-remove">Total overtime request hours that <span class="uk-text-bold">accepted</span> by SPV</p>
                            </div>
                            <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                style="background-color: #AB47BC; color: white;">
                                {{ data.total_ot_hour_accepted || 0 }} hrs
                            </div>
                        </div>
                    </div>
                    <div class="uk-margin-small-top">
                        <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                            uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                            <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                <p class="uk-margin-remove">Average <span class="uk-text-bold">requested</span> overtime hrs / mitra</p>
                            </div>
                            <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                style="background-color: #22CC8E; color: white;">
                                {{ data.avg_ot_hour_accepted || 0 }} hrs
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-flex uk-flex-column">
                    <div class="uk-margin-small-top">
                        <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                            uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                            <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                <p class="uk-margin-remove">Amount of mitra <span class="uk-text-bold">assigned</span> to do overtime by SPV</p>
                            </div>
                            <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                style="background-color: #FFE500;">
                                {{ data.mitra_assigned_overtime_count || 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="uk-margin-small-top">
                        <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                            uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                            <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                <p class="uk-margin-remove">Total overtime hours that <span class="uk-text-bold">assigned</span> by SPV</p>
                            </div>
                            <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                style="background-color: #AB47BC; color: white;">
                                {{ data.total_ot_hour_assigned || 0 }} hrs
                            </div>
                        </div>
                    </div>
                    <div class="uk-margin-small-top">
                        <div class="total-card uk-card uk-card-default uk-card-body uk-flex
                            uk-child-width-1-2 uk-padding-remove uk-overflow-hidden">
                            <div class="title uk-flex uk-flex-column uk-flex-center uk-padding">
                                <p class="uk-margin-remove">Average <span class="uk-text-bold">assigned</span> overtime hrs / mitra</p>
                            </div>
                            <div class="number uk-flex uk-flex-center uk-flex-middle uk-text-center uk-padding"
                                style="background-color: #22CC8E; color: white;">
                                {{ data.avg_ot_hour_assigned || 0 }} hrs
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="date-filter" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Choose date range</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">From</label>
                        <div class="uk-form-controls">
                            <datetime
                                name="Start Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                type='date'
                                v-model="meta.start_date"
                                :max-datetime="datetime.now().toISODate()"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">To</label>
                        <div class="uk-form-controls">
                            <datetime
                                name="End Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                type='date'
                                v-model="meta.end_date"
                                :min-datetime="datetime.fromISO(meta.start_date).plus({days:1}).toISODate()"
                                :max-datetime="datetime.now().toISODate()"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="applyFilter">Apply</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { Datetime } from 'vue-datetime';
import formatter from "@/utils/formatter";
import {
    // notificationSuccess,
    notificationDangerCustom,
    // notificationDanger
} from '@/utils/notification';
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import { DateTime } from 'luxon';
import moment from 'moment';
moment.locale('id');

let handleOutsideClick;
Vue.directive('closable', {
    bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
        handleOutsideClick = (e) => {
            e.stopPropagation();
            // Get the handler method name and the exclude array
            // from the object used in v-closable
            const { handler, exclude } = binding.value;
            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                // We only run this code if we haven't detected
                // any excluded element yet
                if (!clickedOnExcludedEl) {
                    // Get the element using the reference name
                    const excludedEl = vnode.context.$refs[refName];
                    // See if this excluded element
                    // is the same element the user just clicked on
                    if (excludedEl) {
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                }
            });
            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the dialog
                // and not the button, then call the outside-click handler
                // from the same component this directive is used in
                vnode.context[handler]();
            }
        };
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },
    unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    },
});

export default {
    data() {
        return {
            datetime: DateTime,
            images: PREFIX_IMAGE,
            meta: {
                start_date: null,
                end_date: null,
                hour_diff: DateTime.local().offset / 60,
                method: null
            },
            data: {
                mitra_request_overtime_count: 0,
                mitra_assigned_overtime_count: 0,
                total_ot_hour_accepted: 0,
                total_ot_hour_assigned: 0,
                avg_ot_hour_accepted: 0,
                avg_ot_hour_assigned: 0
            }, 
            profile: null
        };
    },
    components: {
        Datetime
    },
    watch: {
        meta() {
            this.getData();
        },
    },
    async mounted() {
        this.profile = await this.getProfile();
        await this.mapQuery();
        if (this.$route.query.start_date && this.$route.query.end_date) {
            await this.getData();
        }
    },
    beforeMount() {
        if (window.UIkit.modal('#date-filter')) window.UIkit.modal('#date-filter').$destroy(true);
    },
    methods: {
        ...mapActions({
            getDashboardData: 'overtime_report/getDashboardData',
            getProfile: "auth/getProfile"
        }),
        async getData() {
            const response = await this.getDashboardData({
                start_date: this.meta.start_date,
                end_date: this.meta.end_date,
                method: this.meta.method
            });
            this.data = response;
        },
        async showModal(){
            await window.UIkit.modal('#date-filter').show();
        },
        async hideModal(){
            await window.UIkit.modal('#date-filter').hide();
        },
        async applyFilter() {
            if (!this.meta.start_date) {
                return notificationDangerCustom('Start date must be filled');
            }

            if (this.meta.start_date && !this.meta.end_date) {
                const start_date = moment(this.meta.start_date).add(this.meta.hour_diff, 'hours').format();
                const end_date = moment(this.meta.start_date).add(30, 'days').format();

                this.meta.start_date = start_date;
                this.meta.end_date = end_date;
            } else if (this.meta.start_date && this.meta.end_date) {
                const start_date = moment(this.meta.start_date).add(this.meta.hour_diff, 'hours').format();
                const end_date = moment(this.meta.end_date).add(this.meta.hour_diff, 'hours').format();

                if (start_date > end_date) {
                    return notificationDangerCustom('End date must be greater than start date');
                }

                this.meta.start_date = start_date;
                this.meta.end_date = end_date;
            }

            this.meta.start_date = moment(this.meta.start_date).format('YYYY-MM-DD');
            this.meta.end_date = moment(this.meta.end_date).format('YYYY-MM-DD');

            await this.changeQuery();
            await this.getData();
            await this.hideModal('date-filter');
        },
        filterDateText() {
            let date_filter_text = this.dateFilterAsText();
            if (date_filter_text != '') {
                return date_filter_text;
            }
            return 'Choose date range';
        },
        dateFilterAsText() {
            let date_format = '';
            if (this.meta.start_date) {
                date_format = date_format + this.formatDate(this.meta.start_date);
            }
            if (this.meta.end_date) {
                if (date_format !== '') {
                    date_format = date_format + '-';
                }
                date_format = date_format + this.formatDate(this.meta.end_date);
            }
            return date_format;
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        async mapQuery() {
            if (this.$route.query.start_date) {
                this.meta.start_date = this.$route.query.start_date;
            }
            if (this.$route.query.end_date) {
                this.meta.end_date = this.$route.query.end_date;
            }
            if (this.$route.query.hour_diff) {
                this.meta.hour_diff = this.$route.query.hour_diff;
            }
            if (this.profile.company_overtime_type){
                this.meta.method = this.profile.company_overtime_type;
            }
            await this.changeQuery();
        },
        async changeQuery(){
            const queryString = Object.keys(this.meta).map(key => key + '=' + this.meta[key]).join('&');
            await this.$router.replace(this.$route.path + '?reset');
            await this.$router.replace(this.$route.path + `?${queryString}`);
        },
        gotoDetail(){
            this.$router.push({ path: `/admin/workers/overtime-report/detail`, query: this.$route.query });
        }
    }
};
</script>
<style scoped>

.alert-warning {
    background: rgba(254, 190, 16, 0.1);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254, 190, 16);
    font-weight: 500;
}

.text-semibold {
    font-weight: 600;
}

.all-time-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    justify-content: space-between;
}

.all-time-card {
    padding: 12px;
    background-color: white;
    font-size: 26px;
    aspect-ratio: 1/1;
    display: grid;
    grid-template-rows: 1fr 1fr;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.all-time-card p {
    margin: 0;
    align-self: center;
}

.all-time-card div {
    align-self: self-start;
}

.total-card-container {
    --grid-layout-gap: 20px;
    --grid-column-count: 2;
    --grid-item-min-width: 415px;
    --grid-item-max-width: calc((100% - var(--grid-layout-gap)) / var(--grid-column-count));
    display: grid;
    gap: var(--grid-layout-gap);
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
}

.total-card {
    border-radius: 20px;
    color: #000;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.total-card .title {
    font-size: 18px;
}

.total-card .number {
    font-size: 50px;
    font-weight: 500;
}

.disabled-button {
    background-color: #CDCDCD;
    color: white;
}

</style>
