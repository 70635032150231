<template>
    <section>
        <div
            id="modal-center"
            class="uk-flex-top"
            uk-modal
            esc-close="false"
            bg-close="false"
        >
            <div class="uk-modal-dialog">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="hideModal"
                ></button>
                <div class="uk-modal-header uk-text-center">
                    <h2 class="uk-modal-title">{{ title }}</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-width-1-1 uk-flex uk-flex-column">
                        <div class="uk-flex">
                            <img
                                :src="url"
                                :alt="title"
                                height="250"
                                style="margin: auto; display: block"
                                loading="lazy"
                            />
                        </div>
                        <div class="uk-flex uk-flex-row uk-margin-small-top">
                            <div v-if="type === 'required_docs'" id="required-docs">
                                <button
                                    v-if="applicant.cv.ktp"
                                    type="button"
                                    @click="
                                        selectDoc({
                                            url: applicant.cv.ktp.document_url
                                                .file_url,
                                            type: applicant.cv.ktp.type
                                        })
                                    "
                                    :type-doc="applicant.cv.ktp.type"
                                    class="badge-doc uk-margin-small-right uk-padding-small"
                                    :class="[selectedDoc == applicant.cv.ktp.type ? 'active' : '']"
                                >
                                    {{ applicant.cv.ktp.type }}
                                </button>
                                <button
                                    v-if="applicant.cv.kk"
                                    type="button"
                                    @click="
                                        selectDoc({
                                            url: applicant.cv.kk.document_url
                                                .file_url,
                                            type: applicant.cv.kk.type
                                        })
                                    "
                                    :type-doc="applicant.cv.kk.type"
                                    class="badge-doc uk-margin-small-right uk-padding-small"
                                    :class="[selectedDoc == applicant.cv.kk.type ? 'active' : '']"
                                >
                                    {{ applicant.cv.kk.type }}
                                </button>
                                <button
                                    v-if="applicant.cv.sim"
                                    type="button"
                                    @click="
                                        selectDoc({
                                            url: applicant.cv.sim.document_url
                                                .file_url,
                                            type: applicant.cv.sim.type
                                        })
                                    "
                                    :type-doc="applicant.cv.sim.type"
                                    class="badge-doc uk-margin-small-right uk-padding-small"
                                    :class="[selectedDoc == applicant.cv.sim.type ? 'active' : '']"
                                >
                                    {{ applicant.cv.sim.type }}
                                </button>
                                <button
                                    v-if="applicant.cv.ijazah"
                                    type="button"
                                    @click="
                                        selectDoc({
                                            url: applicant.cv.ijazah
                                                .document_url.file_url,
                                            type: applicant.cv.ijazah.type
                                        })
                                    "
                                    :type-doc="applicant.cv.ijazah.type"
                                    class="badge-doc uk-margin-small-right uk-padding-small"
                                    :class="[selectedDoc == applicant.cv.ijazah.type ? 'active' : '']"
                                >
                                    {{ applicant.cv.ijazah.type }}
                                </button>
                                <button
                                    v-if="applicant.cv.skck"
                                    type="button"
                                    @click="
                                        selectDoc({
                                            url: applicant.cv.skck.document_url
                                                .file_url,
                                            type: applicant.cv.skck.type
                                        })
                                    "
                                    :type-doc="applicant.cv.skck.type"
                                    class="badge-doc uk-margin-small-right uk-padding-small"
                                    :class="[selectedDoc == applicant.cv.skck.type ? 'active' : '']"
                                >
                                    {{ applicant.cv.skck.type }}
                                </button>
                            </div>
                            <div v-if="type === 'additional_docs'" id="additional-docs">
                                <button
                                    v-for="(addDoc, index) in additionalDocs"
                                    :key="index"
                                    type="button"
                                    @click="
                                        selectDoc({
                                            url: addDoc.document_url.file_url,
                                            type: addDoc.doc_code
                                        })
                                    "
                                    :type-doc="addDoc.doc_code"
                                    class="badge-doc uk-margin-small-right uk-margin-small-top uk-padding-small"
                                    :class="[selectedDoc == addDoc.doc_code ? 'active' : '']"
                                >
                                    {{ addDoc.name }}
                                </button>
                                <div v-show="viewMore">
                                    <button
                                        v-for="(addDoc, index) in moreAdditionalDocs"
                                        :key="index"
                                        type="button"
                                        @click="
                                            selectDoc({
                                                url: addDoc.document_url.file_url,
                                                type: addDoc.doc_code
                                            })
                                        "
                                        :type-doc="addDoc.doc_code"
                                        class="badge-doc uk-margin-small-right uk-margin-small-top uk-padding-small"
                                        :class="[selectedDoc == addDoc.doc_code ? 'active' : '']"
                                    >
                                        {{ addDoc.name }}
                                    </button>
                                </div>
                                <button
                                    v-if="applicant.additional_docs.length > 9"
                                    class="badge-doc uk-margin-small-right uk-margin-small-top uk-padding-small"
                                    style="background: #EEEEEE;"
                                    @click="viewMore = !viewMore"
                                >
                                    {{ viewMore ? 'Show Less' : 'View More' }}
                                    <span>
                                        <img v-if="viewMore" :src="`${baseUrlImg}/job_applicant/detail/collapse-arrow.svg`" width="20px" height="20px"/>
                                        <img v-else :src="`${baseUrlImg}/job_applicant/detail/expand-arrow.svg`" width="20px" height="20px"/>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-center">
                    <section>
                        <button
                            class="uk-button uk-button-primary"
                            type="button"
                            style="
                                background: #0abab5;
                                border-color: #0abab5;
                                color: #ffffff;
                                margin: 0 1rem;
                            "
                            @click="download(urlDownload)"
                        >
                            Download
                        </button>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    props: {
        title: {
            type: String,
            default: 'Lampiran'
        },
        applicant: {
            type: Object,
            default: () => {},
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            url: null,
            urlDownload: null,
            selectedDoc: null,
            docType: [
                'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'
            ],
            baseUrlImg: PREFIX_IMAGE,
            viewMore: false
        };
    },
    computed: {
        additionalDocs(){
            return this.applicant.additional_docs.slice(0,9);
        },
        moreAdditionalDocs(){
            const array = this.applicant.additional_docs;
            const data = array.slice(9, array.length);
            return data;
        }
    },
    async mounted() {
        window.UIkit.modal('#modal-center').show();
        await this.selectFirstDoc();
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#modal-center').$destroy(true);
            this.$emit("hideModal");
        },
        download(img) {
            window.location = img;
        },
        selectDoc({url, type}){
            this.urlDownload = url;
            const fileUrl = url.split('.');
            if (this.docType.includes(fileUrl[fileUrl.length - 1])) {
                this.url = `${this.baseUrlImg}/job_applicant/detail/image-doc.png`;
            } else {
                this.url = url;
            }
            this.selectedDoc = type;
        },
        selectFirstDoc(){
            let firstBtn;
            if (this.type === 'required_docs') {
                firstBtn = document.getElementById('required-docs').firstChild.getAttribute('type-doc');
                this.url = this.applicant.cv[firstBtn.toLowerCase()].document_url.file_url;
            } else if(this.type === 'additional_docs') {
                firstBtn = document.getElementById('additional-docs').firstChild.getAttribute('type-doc');
                const fileUrl = this.applicant.additional_docs.find(additional => additional.doc_code === firstBtn);
                this.url = fileUrl.document_url.file_url;
            }
            this.urlDownload = this.url;
            this.selectedDoc = firstBtn;
        }
    }
};
</script>

<style scoped>
.badge-doc {
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    border: 1px solid #9E9E9E;
    background: #FFF;
    color: #9E9E9E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.active {
    border-radius: 20px;
    border: 1px solid #000;
    background: #EEE;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}
</style>
