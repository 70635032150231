<template>
    <section>
        <div id="download-report-modal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
                <div class="uk-modal-header">
                    <div class="uk-flex uk-flex-middle">
                        <img :src="`${images}/info-green-filled.svg`" alt="Icon" class="uk-margin-right" />
                        <h3 class="uk-margin-remove">Download Report based on Filter Apply</h3>
                    </div>
                </div>
                <div class="uk-modal-body text-black">
                    <div class="uk-overflow-hidden">
                        <div>
                            Are you sure you want to download report for this filters?
                        </div>
                        <div class="uk-padding-small text-f12-l18 filter-data-container">
                            <div>Filters that applied are:</div>
                            <table class="uk-table uk-table-small uk-margin-small-top">
                                <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td class="uk-width-1-5">Vacancy choices </td>
                                        <td>: </td>
                                        <td class="uk-text-600 uk-table-expand content">
                                            {{ shownFilters.vacancyChoices || '-' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td class="uk-width-1-5">Vacancy filters </td>
                                        <td>: </td>
                                        <td class="uk-text-600 uk-table-expand content">
                                            {{ shownFilters.vacancyFilters.length != 0 ? shownFilters.vacancyFilters.join(', '): '-' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td class="uk-width-1-5">Search applicant </td>
                                        <td>: </td>
                                        <td class="uk-text-600 uk-table-expand content">
                                            {{ shownFilters.searchApplicant || '-' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td class="uk-width-1-5">Applicant filters </td>
                                        <td>: </td>
                                        <td class="uk-text-600 uk-table-expand content uk-padding-small-left">
                                            {{ shownFilters.applicantFilters.length != 0 ? shownFilters.applicantFilters.join(', '): '-' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right uk-text-500">
                    <div class="uk-flex uk-flex-between">
                        <button
                            class="uk-button uk-modal-close uk-padding-small uk-padding-remove-vertical uk-width-1-4"
                            :class="[isLoading? 'uk-button-default': 'cancel-button']"
                            :disabled="isLoading"
                            @click="hideModal"
                        >
                            Cancel
                        </button>
                        <button
                            v-if="isLoading"
                            class="uk-button uk-button-default uk-padding-small uk-padding-remove-vertical uk-width-1-4"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </button>
                        <button
                            v-else
                            class="uk-button uk-padding-small uk-padding-remove-vertical uk-width-1-4 uk-button-primary"
                            @click="handleConfirm"
                        >
                            Yes, I'm Sure
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import { excelDownloader } from '@/utils/helper';
import { notificationDanger } from '@/utils/notification';

export default {
    props: {
        filters: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            vacancyFilters: {
                'vacancy_domicilies': 'City/Domicile',
                'vacancy_roles': 'Role',
                'vacancy_offices': 'Office',
                'vacancy_status': 'Vacancy Status'
            },
            applicantFilters: {
                'job_preferences': 'Job Preference',
                'domicilies': 'Domicile',
                'education_levels': 'Education',
                'work_experiences': 'Work experience',
                'age': 'Age',
                'gender': 'Gender',
                'applicant_status': 'Applicant status',
                'has_doc_ktp': 'KTP',
                'has_doc_sim': 'SIM',
                'has_doc_skck': 'SKCK',
                'has_doc_kk': 'KK',
                'has_doc_ijazah': 'Ijazah'
            },
            shownFilters: {
                vacancyChoices: null,
                vacancyFilters: [],
                searchApplicant: null,
                applicantFilters: [],
            }
        };
    },
    async mounted() {
        this.formatFilter();
        window.UIkit.modal('#download-report-modal').show();
    },
    methods: {
        ...mapActions({
            getReportAllApplicant: 'job_applicant/getReportAllApplicant',
        }),
        hideModal() {
            window.UIkit.modal('#download-report-modal').$destroy(true);
            this.$emit("hideModal");
        },
        async handleConfirm() {
            try {
                this.isLoading = true;
                const response = await this.getReportAllApplicant(this.filters);
                if (response && response.status === 'OK') {
                    excelDownloader(response.result, `Report Job Applicant_${this.filters.province_name}_${this.filters.company_name}.xls`);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        formatFilter() {
            for (const [key, value] of Object.entries(this.filters)) {
                const isValid = value && !Array.isArray(value) ? true : false;
                if (key == 'vacancy_name') {
                    this.shownFilters.vacancyChoices = value;
                } else if (Object.keys(this.vacancyFilters).includes(key) && isValid) {
                    this.shownFilters.vacancyFilters.push(this.vacancyFilters[key]);
                } else if (key == 'fullname' && value) {
                    this.shownFilters.searchApplicant = `"${value}"`;
                } else if (Object.keys(this.applicantFilters).includes(key) && isValid) {
                    this.shownFilters.applicantFilters.push(this.applicantFilters[key]);
                }
            }
        }
    }
};
</script>

<style scoped>
.filter-data-container {
    background-color: #F8F7F7;
    margin-top: 16px;
}

.filter-data-container table td {
    padding: 6px 0;
}

.filter-data-container table td:first-child {
    padding-right: 6px;
}

.filter-data-container table td:last-child {
    padding-left: 6px;
}

.cancel-button {
    border: 1px solid #EF5350;
    color: #EF5350;
}

.text-black {
    color: #000;
}
</style>
