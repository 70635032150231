<template>
    <div class="pagination">
        <span class="row-per-page">Rows per page:</span>
        <div class="limit-page">
            <select class="uk-select" id="form-horizontal-select" v-model="limitPage" @change="changeLimit">
                <option
                    v-for="page in limitList"
                    :key="page"
                    :value="page"
                >
                    {{page}}
                </option>
            </select>
        </div>
        <span class="info">{{firstInPage}}-{{lastInPage}} of {{totalData}}</span>
        <div class="action">
            <button class="uk-button uk-button-text" @click="firstPage" :disabled="totalData === 0 || page === 1">
                <span uk-icon="icon: chevron-double-left"></span>
            </button>
            <button class="uk-button uk-button-text" @click="toPage(-1)" :disabled="totalData === 0 || page === 1">
                <span uk-icon="icon: chevron-left"></span>
            </button>
            <button class="uk-button uk-button-text" @click="toPage(1)" :disabled="totalData === 0 || (page === Math.ceil(this.totalData / this.limitPage))">
                <span uk-icon="icon: chevron-right"></span>
            </button>
            <button class="uk-button uk-button-text" @click="lastPage" :disabled="totalData === 0 || (page === Math.ceil(this.totalData / this.limitPage))">
                <span uk-icon="icon: chevron-double-right"></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        totalData: {
            required: true,
            type: Number,
            default: 0
        },
        changeLimit: {
            required: true,
            type: Function,
        },
        changePage: { 
            required: true,
            type: Function,
        },
        limitList: {
            type: Array,
            default: () => [10, 50, 100, 200, 500],
        },
        limitPage: {
            type: Number,
            default: 10
        },
        startPage: {
            type: Number,
            default: 1
        },
    },
    watch: {
        startPage(value) {
            this.page = value;
        },
    },
    data() {
        return {
            currentPage: 1,
            page: this.$props.startPage
        };
    },
    computed: {
        firstInPage() {
            return this.page * this.limitPage - (this.limitPage-1);
        },
        lastInPage() {
            return this.totalData > this.page * this.limitPage ? this.page * this.limitPage : this.totalData;
        }
    },
    methods: {
        toPage(val) {
            this.page += val;
            this.changePage(this.page);
        },
        firstPage() {
            this.page = 1;
            this.changePage(this.page);
        },
        lastPage() {
            this.page = Math.ceil(this.totalData / this.limitPage);
            this.changePage(this.page);
        }
    },
};
</script>

<style scoped>
.pagination {
    display: flex;
    border-top: 1px solid #e5e5e5;
    line-height: 54px;
    height: 54px;
    padding: 0 20px;
    font-size: 13px;
}
.row-per-page {
    flex: 1;
    text-align: right
}
.limit-page {
    margin-left: 20px;
}
.uk-select {
    border: none;
    height: 100%;
    line-height: normal;
    cursor: pointer;
}
.info {
    margin-left: 30px;
}
.action {
    margin-left: 20px;
    margin-top: -2px;
}
.uk-button {
    margin-left: 10px;
}
</style>
