<template>
    <div class="uk-container uk-padding-small uk-container-expand card-scrollable">
        <button type="button" class="uk-button uk-button-default" @click="goBack">
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            <span>Back</span>
        </button>
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else class="uk-height-1-1 uk-margin-top">
            <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-flex uk-flex-middle uk-flex-between">
                <p class="uk-text-bold uk-text-lead uk-padding-remove uk-margin-remove">Mitra Attendance</p>
                <button
                    v-if="downloadLoading"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded uk-button-small"
                    disabled
                >
                    <span uk-spinner="ratio: 0.4"></span>
                    <span class="uk-margin-small-left">Loading</span>
                </button>
                <button 
                    v-else
                    type="button"
                    class="uk-button uk-button-primary uk-border-rounded uk-button-small"
                    @click="downloadReport"
                >Download Report</button>
            </div>
            <table v-if="$can('READ', 'report-mitra-attendance')" class="uk-table uk-table-striped uk-width-1-2 uk-table-small">
                <tbody>
                    <tr>
                        <td>Date</td>
                        <td>{{ summaryAttendanceDetailDate }}</td>
                    </tr>
                    <tr>
                        <td>Office</td>
                        <td>{{ officeName }}</td>
                    </tr>
                    <tr>
                        <td>Total Mitra</td>
                        <td>{{ totalWorker }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-margin-top uk-flex uk-flex-middle uk-child-width-expand@s" style="gap: 20px;">
                <button 
                    class="uk-button uk-border-rounded"
                    :class="[activeFilter == 'worker_not_absent' ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default myrobin-color-grey']"
                    @click="setActiveFilterWorkerList('worker_not_absent')"
                >Belum Absen ({{ totalWorkerAbsent }})</button>
                <button 
                    class="uk-button uk-button-default uk-border-rounded myrobin-color-grey"
                    :class="[activeFilter == 'worker_clock_in' ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default myrobin-color-grey']"
                    @click="setActiveFilterWorkerList('worker_clock_in')"
                >Absen Masuk ({{ totalWorkerCheckIn }})</button>
                <button 
                    class="uk-button uk-button-default uk-border-rounded myrobin-color-grey"
                    :class="[activeFilter == 'worker_clock_out' ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default myrobin-color-grey']"
                    @click="setActiveFilterWorkerList('worker_clock_out')"
                >Absen Pulang ({{ totalWorkerCheckOut }})</button>
                <button 
                    class="uk-button uk-button-default uk-border-rounded myrobin-color-grey"
                    :class="[activeFilter == 'worker_leave' ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default myrobin-color-grey']"
                    @click="setActiveFilterWorkerList('worker_leave')"
                >Mitra Izin ({{ totalWorkerLeave }})</button>
            </div>
            <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-height-1-1">
                <div v-if="filterLoading" class="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-padding">
                    <div uk-spinner="ratio: 2"></div>
                </div>
                <div v-else>
                    <div v-if="summaryAttendanceWorkerList.docs.length > 0" class="uk-margin-top uk-flex uk-flex-column" style="gap: 20px;">
                        <div   
                            v-for="(worker, index) in summaryAttendanceWorkerList.docs"
                            :key="index"
                            class="uk-card uk-card-default uk-card-body uk-border-rounded uk-flex uk-flex-middle"
                        >
                            <img v-if="worker.photo === null" :data-src="`${images}/avatar.svg`" alt="Avatar Icon" uk-img class="photo">
                            <img v-else-if="worker.photo !== null" :src="worker.photo.file_url" alt="User photo" uk-img class="photo">
                            <div class="uk-margin-medium-left">
                                <p class="uk-padding-remove uk-margin-remove uk-text-bold">{{ worker.fullname || '-' }}</p>
                                <p class="uk-padding-remove uk-margin-remove">Office : {{ worker.office || '-' }}</p>
                                <p class="uk-padding-remove uk-margin-remove">Role : {{ worker.role || '-' }}</p>
                                <p class="uk-padding-remove uk-margin-remove">Shift : {{ worker.shift || '-' }}</p>
                                <p class="uk-padding-remove uk-margin-remove">Start Date : {{ formatDateCompleteNew(worker.start_date) }}</p>
                                <p class="uk-padding-remove uk-margin-remove">End Date : {{ formatDateCompleteNew(worker.end_date) }}</p>
                                <p class="uk-padding-remove uk-margin-remove">Salary : {{ worker.salary || '-' }}</p>
                                <p class="uk-padding-remove uk-margin-remove">Status : {{ formatStatus(worker.status) }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="uk-padding">
                        <p class="uk-padding-remove uk-margin-remove uk-text-center">data not found</p>
                    </div>
                </div>
                <pagination
                    :total-data="summaryAttendanceWorkerList.totalDocs"
                    :limit-page="meta.limit"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <div v-else class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from "@/utils/constant";
import { excelDownloader } from '@/utils/helper';
import { notificationDanger } from '@/utils/notification';
import formatter from "@/utils/formatter";

export default {
    name: 'SummaryAttendanceDetail',
    components: {
        Pagination: () => import('@/components/globals/Pagination')
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            activeFilter: null,
            isLoading: false,
            filterLoading: false,
            downloadLoading: false,
            meta: {
                limit: 10,
                page: 1,
                id: this.$route.params.daily_recap_worker_attendance_id,
                filter: 'worker_not_absent'
            },
            summaryAttendanceDetail: null,
            summaryAttendanceWorkerList: {
                docs: [],
                totalDocs: 0
            }
        };
    },
    watch: {
        async meta() {
            try {
                this.filterLoading = true;
                this.resetData();
                await this.setSummaryAttendanceWorkerList();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.filterLoading = false;
            }
        }
    },
    computed: {
        summaryAttendanceDetailDate() {
            return this.summaryAttendanceDetail ? this.formatDate(this.summaryAttendanceDetail.date) ? this.formatDate(this.summaryAttendanceDetail.date) : '-' : '-';
        },
        officeName() {
            return this.summaryAttendanceDetail ? this.summaryAttendanceDetail.office_name ? this.summaryAttendanceDetail.office_name : '-' : '-';
        },
        totalWorker() {
            return this.summaryAttendanceDetail ? this.summaryAttendanceDetail.total_worker ? this.summaryAttendanceDetail.total_worker : 0 : 0;
        },
        totalWorkerAbsent() {
            return this.summaryAttendanceDetail ? this.summaryAttendanceDetail.total_worker_not_absent ? this.summaryAttendanceDetail.total_worker_not_absent : 0 : 0;
        },
        totalWorkerCheckIn() {
            return this.summaryAttendanceDetail ? this.summaryAttendanceDetail.total_worker_checkin ? this.summaryAttendanceDetail.total_worker_checkin : 0 : 0;
        },
        totalWorkerCheckOut() {
            return this.summaryAttendanceDetail ? this.summaryAttendanceDetail.total_worker_checkout ? this.summaryAttendanceDetail.total_worker_checkout : 0 : 0;
        },
        totalWorkerLeave() {
            return this.summaryAttendanceDetail ? this.summaryAttendanceDetail.total_worker_leave ? this.summaryAttendanceDetail.total_worker_leave : 0 : 0;
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
            await Promise.all([
                this.setSummaryAttendanceDetail(),
                this.setFirstFilterWorkerList(),
                this.setSummaryAttendanceWorkerList()
            ]);
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getSummaryAttendanceDetail: 'report/getSummaryAttendanceDetail',
            getSummaryAttendanceWorkerList: 'report/getSummaryAttendanceWorkerList',
            getSummaryAttendanceWorkerListReport: 'report/getSummaryAttendanceWorkerListReport',
        }),
        async setFirstFilterWorkerList() {
            try {
                if (this.$route.query.filter === undefined) {
                    await this.setQueryURL('worker_not_absent');
                }
                this.filterSummaryAttendanceWorkerList(this.$route.query.filter);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setActiveFilterWorkerList(filter) {
            try {
                this.filterLoading = true;
                if (this.$route.query.filter !== undefined) {
                    await this.setQueryURL(filter);
                    this.filterSummaryAttendanceWorkerList(filter);
                    this.resetData();
                    await Promise.all([
                        this.setSummaryAttendanceDetail(),
                        this.setSummaryAttendanceWorkerList()
                    ]);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.filterLoading = false;
            }
        },
        async setQueryURL(filter) {
            try {
                await this.$router.replace({
                    path: `/admin/report/mitra_attendance/${this.$route.params.daily_recap_worker_attendance_id}`,
                    query: {
                        filter
                    }
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async downloadReport() {
            try {
                this.downloadLoading = true;
                const params = { id: this.$route.params.daily_recap_worker_attendance_id };
                const workerListReport = await this.getSummaryAttendanceWorkerListReport(params);
                const dataExcel = [];
                workerListReport.forEach((data) => {
                    let obj = {
                        "Date": this.formatDateCompleteNew(data.date),
                        "Office": data.office,
                        "Nama": data.fullname,
                        "Email": data.email,
                        "No Telp": data.phone_number,
                        "No KTP": data.identity_number,
                        "Status": this.formatStatusAttendance(data)
                    };
                    dataExcel.push(obj);
                });
                const companyName = this.summaryAttendanceDetail.company_name;
                const officeName = this.summaryAttendanceDetail.office_name;
                const date = this.formatDateCompleteNew(this.summaryAttendanceDetail.date);
                const fileName = `SummaryAttendanceDetail_${companyName}_${officeName}_${date}.xls`;
                excelDownloader(dataExcel, fileName);
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadLoading = false;
            }
        },
        async setSummaryAttendanceDetail() {
            try {
                const params = { id: this.$route.params.daily_recap_worker_attendance_id };
                this.summaryAttendanceDetail = await this.getSummaryAttendanceDetail(params);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setSummaryAttendanceWorkerList() {
            try {
                const response = await this.getSummaryAttendanceWorkerList(this.meta);
                if (response && response.docs && response.totalDocs) {
                    this.summaryAttendanceWorkerList.docs = response.docs;
                    this.summaryAttendanceWorkerList.totalDocs = response.totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        goBack() {
            this.$router.back();
        },
        resetData() {
            this.summaryAttendanceWorkerList.docs = [];
            this.summaryAttendanceWorkerList.totalDocs = 0;
        },
        filterSummaryAttendanceWorkerList(filter) {
            this.activeFilter = filter;
            if (filter == 'worker_not_absent') {
                this.meta.filter = 'worker_not_absent';
            } else if (filter == 'worker_clock_in') {
                this.meta.filter = 'worker_clock_in';
            } else if (filter == 'worker_clock_out') {
                this.meta.filter = 'worker_clock_out';
            } else if (filter == 'worker_leave') {
                this.meta.filter = 'worker_leave';
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        formatDate(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateComplete(data);
        },
        formatDateCompleteNew(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateCompleteNew(data);
        },
        formatStatus(status) {
            if (status == 0) {
                return 'Inactive';
            } else if (status == 1) {
                return 'Active';
            } else {
                return '-';
            }
        },
        formatStatusAttendance(status) {
            if (status.user_attendance_clock_in) {
                return 'Absen Masuk';
            } else if (status.user_attendance_clock_out) {
                return 'Absen Pulang';
            } else if (status.user_request_leave_date) {
                return 'Izin';
            } else {
                return 'Belum Absen';
            }
        } 
    }
};
</script>

<style scoped>
.myrobin-color-grey {
    color: #9A9A9A;
}

.myrobin-color-white {
    color: #FFFFFF;
}

.myrobin-background-primary {
    background: #0ABAB5;
}

.photo {
    width: 106px; 
    height: 106px; 
    border-radius: 100%; 
    object-fit: cover;
    object-position: center;
}
</style>
