import Vue from 'vue';
import api from '@/utils/api';
import { INITIATION } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    initiations: {docs:[], totalDocs: 0},
    allInitiations: null
};

const mutations = {
    "SET_INITIATION": (state, payload) => {
        Vue.set(state, 'initiations', payload);
    },
    "SET_ALL_INITIATIONS": (state, payload) => {
        Vue.set(state, 'allInitiations', payload);
    }
};

const actions = {
    async importUser(context, data) {
        try {
            const { job_id, formData } = data;
            const response = await api.apiPostAuth(INITIATION.POST_IMPORT_MITRA(job_id), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async manualInput(context, payload) {
        try {
            const { job_id, formData } = payload;
            const response = await api.apiPostAuth(INITIATION.POST_MANUAL_INPUT(job_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getInitiationList(context, data) {
        try {
            const response = await api.apiGetAuth(INITIATION.LIST, data);
            context.commit('SET_INITIATION', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyInitiationList(context, data) {
        try {
            const response = await api.apiGetAuth(INITIATION.COMPANY_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async GetTemplateFullMigration(context, data) {
        try {
            const response = await api.apiGetAuth(INITIATION.GET_TEMPLATE_FULL_MIGRATION(data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async PostFullMigration(context, payload) {
        try {
            const { office_id, formData } = payload;
            const response = await api.apiPostAuth(INITIATION.POST_FULL_MIGRATION(office_id), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobList(context, data) {
        try {
            const response = await api.apiGetAuth(INITIATION.JOB_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobDetails(context, id) {
        try {
            const response = await api.apiGetAuth(INITIATION.EDIT_DETAIL_JOB(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSalarySetup(context, data) {
        try {
            const response = await api.apiGetAuth(INITIATION.SALARY_SETUP, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postJob(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPostAuth(INITIATION.POST_JOB(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postMultiJob(context, payload) {
        try {
            const response = await api.apiPostAuth(INITIATION.POST_MULTI_JOB, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateJob(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(INITIATION.EDIT_DETAIL_JOB(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async autoAssignMitraJob(context, data) {
        try {
            const {job_id, ...formData} = data;
            const response = await api.apiPutAuth(INITIATION.AUTO_ASSIGN_MITRA(job_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getOfficeByRole(context, role_id) {
        try {
            const response = await api.apiGetAuth(INITIATION.GET_OFFICE_BY_ROLE(role_id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAdditionalDocument() {
        try {
            const response = await api.apiGetAuth(INITIATION.GET_ADDITIONAL_DOCUMENT);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },

    // =================== V4 ===================
    async resetInitiationDataV4(context) {
        context.commit('SET_INITIATION', {docs:[], totalDocs: 0});
    },
    async getListInitiationJobsV4(context, data) {
        try {
            const response = await api.apiGetAuth(INITIATION.JOB_LIST_V4, data);
            context.commit('SET_INITIATION', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getFilterInitiationJobsV4() {
        try {
            const response = await api.apiGetAuth(INITIATION.FILTER_V4);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateStatusV4(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(INITIATION.UPDATE_STATUS_V4(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async searchPICV4(context, data) {
        try {
            const response = await api.apiGetAuth(INITIATION.SEARCH_PIC_V4, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllListInitiationJobsV4(context, data) {
        const response = await api.apiGetAuth(INITIATION.ALL_JOB_LIST_V4, data);
        context.commit('SET_ALL_INITIATIONS', response.data.result);
    },
    async resetAllListInitiationDataV4(context) {
        context.commit('SET_ALL_INITIATIONS', null);
    },
    async multipleUpdateStatusV4(context, data) {
        try {
            const response = await api.apiPatchAuth(INITIATION.MULTIPLE_UPDATE_STATUS_V4, data);
            return response?.data;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    initiations: state => {
        return state.initiations;
    },
    allInitiations: state => {
        return state.allInitiations;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
