<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div v-if="$can('READ', 'worker-reimbursement')" class="uk-flex uk-flex-between">
            <div class="uk-flex uk-child-width-expand@s" style="gap: 20px">
                <form @change="getData">
                    <select name="office" id="office" class="uk-select" v-model="meta.status">
                        <option :value="null" selected>All Status</option>
                        <option :value="1">Requested</option>
                        <option :value="2">Accepted</option>
                        <option :value="3">Rejected</option>
                        <option :value="4">Transferred</option>
                    </select>
                </form>
                <form @change="getData">
                    <select name="sort" id="sort" class="uk-select" v-model="meta.sort">
                        <option value="-1" selected>Newest</option>
                        <option value="1">Oldest</option>
                    </select>
                </form>
                <form class="uk-width-auto" action="javascript:void(0);">
                  <input
                      type="text"
                      class="uk-input"
                      placeholder="Candidate Name"
                      v-model="meta.fullname"
                      @input="searchName"
                      @keyup.enter="searchName(true)"
                  >
                </form>
            </div>
            <div>
                <button class="uk-button uk-button-primary uk-border-rounded" @click="showModal('report')">Download Report</button>
            </div>
        </div>
        <!-- Main Table -->
        <div v-if="$can('READ', 'worker-reimbursement')" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-center">Reimbursement ID</th>
                            <th class="uk-text-center">Request Date</th>
                            <th class="uk-text-center">Employee Name</th>
                            <th class="uk-text-center">Notes</th>
                            <th class="uk-text-center">Amount</th>
                            <th class="uk-text-center">Approved Amount</th>
                            <th class="uk-text-center">Status</th>
                            <th class="uk-text-center">Approval Date</th>
                            <th class="uk-text-center">Approved By</th>
                        </tr>
                    </thead>
                    <tbody v-if="data.docs.length>0">
                        <tr v-for="(item, i) in data.docs" :key="i">
                            <td class="uk-text-center uk-text-middle">
                                <a style="text-decoration: none;" :href="`reimbursement/detail?salary_adjustment_id=${item._id}`">{{item._id || '-'}}</a>
                            </td>
                            <td class="uk-text-center uk-text-middle">{{ moment(item.request_date).format('DD MMM YYYY') }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.user.fullname }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.request_note }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(item.request_amount)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.decision_amount ? formatCurrency(parseInt(item.decision_amount)) : '-' }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatStatus(item.status) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.decision_date ? moment(item.decision_date).format('DD MMM YYYY') : '-' }}</td>
                            <td class="uk-text-center uk-text-middle">{{ item.decision_by ? item.decision_by : '-' }}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="9"/>
                </table>
            </div>
            <pagination
                :total-data="data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End of Main Table -->
        <!-- Modal box Report -->
        <div id="report" class="report" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('report')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Download Report</h2>
                </div>
                <div class="uk-modal-body">
                    <form @submit.prevent>
                        <div class="uk-width-expand">
                            <label for="based-on">Based On</label><br>
                            <select name="based-on" id="based-on" class="uk-select" v-model="report_data.based_on">
                                <option :value="'request date'">Request Date</option>
                                <option :value="'decision date'">Approved Date</option>
                            </select>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('based-on')">{{ errors.first('based-on') }}</span>
                        </div>
                        <div class="uk-flex uk-child-width-expand@s" style="gap: 20px">
                            <div>
                                <label for="start-date">Select Start Date</label><br>
                                <datetime
                                    id="start-date"
                                    name="start-date"
                                    :input-class="{'uk-form-danger': errors.has('start-date'), 'uk-input': true}"
                                    placeholder="dd MMM y"
                                    format="dd MMM y"
                                    type='date'
                                    :max-datetime="moment().toISOString()"
                                    v-validate="'required'"
                                    v-model="report_data.start_date"
                                ></datetime>
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('start-date')">{{ errors.first('start-date') }}</span>
                            </div>
                            <div>
                                <label for="end-date">Select End Date</label><br>
                                <datetime
                                    id="end-date"
                                    name="end-date"
                                    :input-class="{'uk-input': true}"
                                    placeholder="dd MMM y"
                                    format="dd MMM y"
                                    type='date'
                                    :max-datetime="moment().toISOString()"
                                    :min-datetime="report_data.start_date"
                                    :disabled="!report_data.start_date"
                                    v-model="report_data.end_date"
                                ></datetime>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="download">Download</button>
                </div>
            </div>
        </div>
        <!-- End modal box Report -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import moment from 'moment';
moment.locale('id');
import { Datetime } from 'vue-datetime';
import {
    notificationDanger
} from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';

export default {
    data() {
        return {
            moment: moment,
            meta: {
                fullname: '',
                limit: 10,
                page: 1,
                status: null,
                sort: '-1'
            },
            data: {
                docs: [],
                totalDocs: 0
            },
            selected_data: null,
            report_data: {
                based_on: 'request date',
                start_date: null,
                end_date: null
            },
            isLoading: false
        };
    },
    components: {
        EmptyTable,
        Pagination,
        Datetime
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        async meta() {
            await this.getData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.report')) window.UIkit.modal('.report').$destroy(true);
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        ...mapActions({
            getReimbursements: 'salary_adjustment/getReimbursements',
            getReimbursementReport: 'report/getReimbursementReport'
        }),
        searchName(onEnter = false) {
            if (onEnter){
                this.getData();
            } else {
                // auto start search if key more than 2 words
                this.meta.fullname.length > 2 ? this.getData() : null;
            }
        },
        async getData() {
            this.data = await this.getReimbursements(this.meta);
        },
        detail(data) {
            this.$router.push({ path: `reimbursement/detail`, query: data });
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        formatStatus(data) {
            switch (data) {
            case 1:
                return 'Requested';
            case 2:
                return 'Approved';
            case 3:
                return 'Rejected';
            case 4:
                return 'Transferred';
            }
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'report':
                this.report_data.start_date = null;
                this.report_data.end_date = null;
                this.report_data.based_on = 'request date';
                this.$validator.reset();
                await window.UIkit.modal('#report').hide();
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'report':
                await window.UIkit.modal('#report').show();
                break;
            }
        },
        async download() {
            try {     
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                const all_recaps = await this.getReimbursementReport(this.report_data);
                
                excelDownloader(all_recaps, this.generateFileName());
                this.isLoading = false;
            } catch (err) {
                notificationDanger(err);
            }
        },
        generateFileName() {
            let suffixName = '';

            if (this.report_data.end_date) {
                suffixName = `(${moment(this.report_data.start_date).format('DD MMM YYYY')} - ${moment(this.report_data.end_date).format('DD MMM YYYY')})`;
            } else {
                suffixName = `(${moment(this.report_data.start_date).format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')})`;
            }

            let filename = `Reimbursement Report${suffixName}.xls`;

            return filename;
        }
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.report-download{
    font-family: 'Roboto';
    color: #5FD855;
    font-size: 20px;
}
</style>
