import { render, staticRenderFns } from "./Recap.vue?vue&type=template&id=81ce69ca&scoped=true"
import script from "./Recap.vue?vue&type=script&lang=js"
export * from "./Recap.vue?vue&type=script&lang=js"
import style0 from "./Recap.vue?vue&type=style&index=0&id=81ce69ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81ce69ca",
  null
  
)

export default component.exports