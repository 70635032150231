<template>
    <section>
        <div id="interview-modal" class="uk-flex-top interview-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Invite to Interview</h2>
                </div>
                <form @submit.prevent="handleInterviewApplicant">
                    <div class="uk-padding uk-grid-small" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="interview-method" class="uk-form-label">Interview Method</label>
                            <select
                                id="interview-method"
                                name="interview-method"
                                class="uk-select"
                                v-model="formData.interview_method"
                                :class="{'uk-form-danger': errors.has('interview-method')}"
                                v-validate="'required'"
                            >
                                <option value="" disabled>Select interview method</option>
                                <option v-for="(option, key) in methodOptions" :value="option.value" :key="key">{{option.label}}</option>
                            </select>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('interview-method')">This field is required!</span>
                        </div>
                        <div v-if="formData.interview_method == 1" class="uk-width-1-1">
                            <label for="online-interview-method" class="uk-form-label">Online Interview Method</label>
                            <select
                                id="online-interview-method"
                                name="online-interview-method"
                                class="uk-select"
                                v-model="formData.online_interview_method"
                                :class="{'uk-form-danger': errors.has('online-interview-method')}"
                                v-validate="'required'"
                            >
                                <option value="" disabled>Select online interview method</option>
                                <option v-for="(option, key) in onlineMethodOptions" :value="option.value" :key="key">{{option.label}}</option>
                            </select>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('online-interview-method')">This field is required!</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="interviewer-name" class="uk-form-label">Interviewer Name</label>
                            <input
                                id="interviewer-name"
                                class="uk-input"
                                type="text"
                                placeholder="Input interviewer name"
                                v-model="formData.interviewer_name"
                            >
                        </div>
                        <div class="uk-width-1-1">
                            <label for="interview-schedule" class="uk-form-label">Interview Schedule</label>
                            <div class="uk-inline uk-width-expand">
                                <datetime
                                    id="interview-schedule"
                                    name="interview_datetime"
                                    input-class="uk-input"
                                    type="datetime"
                                    format="EEEE, dd MMM yyyy , HH:mm"
                                    :min-datetime="new Date().toISOString()"
                                    v-model="formData.interview_datetime"
                                    v-validate="'required'"
                                ></datetime>
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('interview_datetime')">This field is required!</span>
                        </div>
                        <div v-if="formData.online_interview_method == 1" class="uk-width-1-1">
                            <label for="interview-url" class="uk-form-label">Interview URL</label>
                            <input
                                id="interview-url"
                                name="interview_url"
                                key="interview_url"
                                class="uk-input"
                                :class="{'uk-form-danger': errors.has('interview_url')}"
                                type="text"
                                placeholder="URL for meeting"
                                v-model="formData.interview_url"
                                v-validate="'required'"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('interview_url')">This field is required!</span>
                        </div>
                        <div v-else-if="formData.online_interview_method == 2" class="uk-width-1-1">
                            <label for="interview-phone-number" class="uk-form-label">Interview Phone Number</label>
                            <input
                                id="interview-phone-number"
                                name="interview-phone-number"
                                key="interview-phone-number"
                                class="uk-input"
                                :class="{'uk-form-danger': errors.has('interview-phone-number')}"
                                type="text"
                                placeholder="Input interviewer phone number"
                                v-model="formData.interview_phone_number"
                                v-validate="'required'"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('interview-phone-number')">This field is required!</span>
                        </div>
                        <div v-else-if="formData.interview_method == 2" class="uk-width-1-1">
                            <label for="interview-address" class="uk-form-label">Interview Address</label>
                            <textarea
                                id="interview-address"
                                name="interview-address"
                                key="interview-address"
                                class="uk-textarea"
                                :class="{'uk-form-danger': errors.has('interview-address')}"
                                rows="5"
                                placeholder="Input interview address"
                                v-model="formData.interview_address"
                                v-validate="'required'"
                            ></textarea>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('interview-address')">This field is required!</span>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="isLoading"
                            class="uk-button uk-button-default uk-flex uk-flex-middle uk-flex-center"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </button>
                        <button v-else type="submit" class="uk-button uk-button-primary">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';

export default {
    components: {
        Datetime
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        ids: {
            type: Array,
            default: () => [],
        },
        isMulti: {
            type: Boolean,
            required: true,
        }
    },
    data(){
        return {
            datetime: DateTime,
            isLoading: false,
            methodOptions: [
                { label: 'Online', value: 1 },
                { label: 'Offline', value: 2 }
            ],
            onlineMethodOptions: [
                { label: 'Video Call', value: 1 },
                { label: 'Phone Call', value: 2 }
            ],
            formData: {
                job_applicant_ids: this.$props.ids,
                id: this.$props.id,
                interview_method: '',
                online_interview_method: '',
                interviewer_name: null,
                interview_datetime: null,
                interview_url: null,
                interview_phone_number: null,
                interview_address: null
            }
        };
    },
    watch: {
        'formData.interview_method'() {
            this.formData.online_interview_method = '';
            this.formData.interview_address = null;
        },
        'formData.online_interview_method'() {
            this.formData.interview_url = null;
            this.formData.interview_phone_number = null;
        }
    },
    mounted() {
        window.UIkit.modal('#interview-modal').show();
    },
    methods: {
        ...mapActions({
            inviteMultiInterviewApplicant: 'applicant/inviteMultiInterviewApplicant',
            inviteInterviewApplicant: 'applicant/inviteInterviewApplicant',
        }),
        hideModal() {
            window.UIkit.modal('#interview-modal').$destroy(true);
            this.$emit("hideModal");
        },
        async handleInterviewApplicant() {
            this.isLoading = true;

            const validate = await this.$validator.validateAll();
            if (!validate || this.$validator.errors.any()) {
                this.isLoading = false;
                return;
            }

            this.formData.interviewer_name = this.validateValue(this.formData.interviewer_name);

            let pathApi = 'inviteInterviewApplicant';

            if (this.$props.isMulti) {
                pathApi = 'inviteMultiInterviewApplicant';
                delete this.formData.id;
            } else {
                delete this.formData.job_applicant_ids;
            }

            const response = await this[pathApi](this.formData);

            if (response && response.status === 'OK') {
                notificationSuccess('Change status success!');
                window.UIkit.modal('#interview-modal').$destroy(true);
                this.$emit("onSave");
            } else {
                notificationDangerCustom('Failed to change status applicant!');
            }

            this.isLoading = false;
        },
        validateValue(value) {
            if (!value || value.trim().length == 0) {
                return null;
            }
            return value.trim();
        }
    }
};
</script>
