import { render, staticRenderFns } from "./SalaryReportList.vue?vue&type=template&id=cfc45f44&scoped=true"
import script from "./SalaryReportList.vue?vue&type=script&lang=js"
export * from "./SalaryReportList.vue?vue&type=script&lang=js"
import style0 from "./SalaryReportList.vue?vue&type=style&index=0&id=cfc45f44&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfc45f44",
  null
  
)

export default component.exports