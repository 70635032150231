<template>
  <section>
    <div id="delete-modal" class="uk-flex-top delete-modal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
          <button class="uk-modal-close-default" type="button" uk-close @click="closeDownload" />
          <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-bold uk-text-center">Delete Mitra</h2>
          </div>
          <div v-if="modalLoading" class="uk-padding">
              <div class="uk-flex uk-flex-middle uk-flex-center">
                  <div uk-spinner="ratio: 2"></div>
              </div>
          </div>
          <form v-else @submit.prevent="handleDeleteMitra">
              <div class="uk-padding uk-grid-small" uk-grid>
                  <div class="uk-width-1-1">
                      <p class="uk-margin-remove">You are going to delete this mitra!</p>
                      <p class="uk-margin-small-top uk-text-bold uk-text-lead">
                          {{ selected_worker.fullname ? selected_worker.fullname : 'Mitra MyRobin' }}
                      </p>
                  </div>
                  <div class="uk-width-1-1">
                      <label for="reason" class="uk-form-label">Reason <span class="uk-text-danger">*</span></label>
                      <select id="reason" name="Reason" class="uk-select uk-border-rounded" v-model="form_delete.reasons">
                          <option value="" selected>Select reason below...</option>
                          <option
                              v-for="(reason, index) in reasons"
                              :key="index"
                              :value="reason.id"
                          >{{ reason.label }}</option>
                      </select>
                  </div>
                  <div class="uk-width-1-1">
                      <label for="notes" class="uk-form-label">Notes</label>
                      <textarea
                          id="notes"
                          name="Notes"
                          class="uk-textarea uk-border-rounded"
                          rows="3"
                          v-model="form_delete.notes"
                      ></textarea>
                  </div>
              </div>
              <div class="uk-modal-footer uk-text-right">
                  <button
                      type="submit"
                      class="uk-button"
                      :class="[disabledButtonDelete ? 'uk-button-default' : 'uk-button-primary']"
                      :disabled="disabledButtonDelete"
                  >Confirm</button>
              </div>
          </form>
      </div>
    </div>

    <ConfirmModal
      v-if="isShowConfirm"
      title="Confirmation"
      body="Are you sure want to proceed?"
      @hideModal="(isShowConfirm = false, closeDownload())"
      @confirmProcess="deleteMitra"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDanger, notificationDangerCustom } from "@/utils/notification";
import ConfirmModal from '@/components/globals/modals/SimpleConfirm';

export default {
    props: {
        selected_worker: {
            type: Object,
            required: true
        },
        idToDelete: {
            type: String,
            required: true
        }
    },

    components: { ConfirmModal },

    data(){
        return {
            modalLoading: false,
            isShowConfirm: false,
            form_delete: {
                reasons: '',
                notes: '',
                job_partner_ids: [this.$props.idToDelete]
            },
            reasons: [
                {
                    id: 0,
                    label: 'Habis Kontrak'
                },
                {
                    id: 1,
                    label: 'Sakit'
                },
                {
                    id: 2,
                    label: 'Selesai Event'
                },
                {
                    id: 3,
                    label: 'Blacklist - Tak Tertoleransi'
                },
                {
                    id: 4,
                    label: 'Blacklist - Tertoleransi'
                },
                {
                    id: 5,
                    label: 'Lainnya'
                }
            ],
        };
    },

    mounted() {
        window.UIkit.modal("#delete-modal").show();
    },

    computed: {
        disabledButtonDelete() {
            return this.form_delete.reasons === '';
        }
    },

    methods: {
        ...mapActions({
            postDeleteMitra: "worker/deleteMitra",
        }),

        async handleDeleteMitra() {
            await window.UIkit.modal("#delete-modal").hide();
            this.isShowConfirm = true;
        },

        async deleteMitra() {
            try {
                this.modalLoading = true;
                const response = await this.postDeleteMitra(this.form_delete);

                if (response && response.status === "OK") {
                    notificationSuccess("Mitra deleted!");
                } else {
                    notificationDangerCustom("Failed to delete mitra.");
                }

                window.UIkit.modal('#delete-modal').$destroy(true);
                this.$emit('close');
            } catch (error) {
                notificationDanger(error);
                window.UIkit.modal("#delete-modal").show();
            } finally {
                this.modalLoading = false;
            }
        },

        closeDownload() {
            window.UIkit.modal('#delete-modal').$destroy(true);
            this.$emit('close');
        },
    }
};
</script>
