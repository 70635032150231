<template>
    <div class="uk-container uk-container-expand card-scrollable uk-padding-small">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <!-- Header -->
            <div v-if="$can('WRITE', 'rostering-shift')" class="uk-flex uk-flex-middle uk-flex-between">
                <p class="uk-margin-remove uk-text-lead">Tambah Shift</p>
                <button class="uk-button uk-button-link" @click="goBack">
                    <img :src="`${images}/cross.svg`" alt="Cross icon" width="15">
                </button>
            </div>
            <!-- End header -->

            <!-- Form add shift -->
            <form v-if="$can('WRITE', 'rostering-shift')" class="uk-margin-top uk-grid-small" @submit.prevent="createShift" uk-grid>
                <div class="uk-width-1-2">
                    <label for="shiftName" class="uk-form-label">Nama Shift</label>
                    <input 
                        id="shiftName"
                        class="uk-input uk-border-rounded" 
                        type="text"
                        v-model="shift_data.shift_name" 
                    >
                </div>
                <div class="uk-width-1-1">
                    <label :for="'day1' && 'day2'" class="uk-form-label">Hari</label>
                    <div id="day1" class="uk-grid-small" uk-grid> 
                        <div class="uk-width-expand">
                            <button 
                                type="button" 
                                class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                :class="[shift_data.monday ? 'mr-button-primary' : '']" 
                                @click="chooseDay({ day: 'monday' })"
                            >Senin</button>
                        </div>
                        <div class="uk-width-expand">
                            <button 
                                type="button" 
                                class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                :class="[shift_data.tuesday ? 'mr-button-primary' : '']"
                                @click="chooseDay({ day: 'tuesday' })"
                            >Selasa</button>
                        </div>
                        <div class="uk-width-expand">
                            <button 
                                type="button" 
                                class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                :class="[shift_data.wednesday ? 'mr-button-primary' : '']"
                                @click="chooseDay({ day: 'wednesday' })"
                            >Rabu</button>
                        </div>
                        <div class="uk-width-expand">
                            <button 
                                type="button" 
                                class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                :class="[shift_data.thursday ? 'mr-button-primary' : '']"
                                @click="chooseDay({ day: 'thursday' })"
                            >Kamis</button>
                        </div>
                        <div class="uk-width-expand">
                            <button 
                                type="button" 
                                class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                :class="[shift_data.friday ? 'mr-button-primary' : '']"
                                @click="chooseDay({ day: 'friday' })"
                            >Jumat</button>
                        </div>
                        <div class="uk-width-expand">
                            <button 
                                type="button" 
                                class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                :class="[shift_data.saturday ? 'mr-button-primary' : '']"
                                @click="chooseDay({ day: 'saturday' })"
                            >Sabtu</button>
                        </div>
                        <div class="uk-width-expand">
                            <button 
                                type="button" 
                                class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                                :class="[shift_data.sunday ? 'mr-button-primary' : '']"
                                @click="chooseDay({ day: 'sunday' })"
                            >Minggu</button>
                        </div>
                    </div>
                    <div id="day2" class="uk-margin-small-top uk-flex uk-flex-middle uk-flex-between uk-width-1-2">
                        <label><input class="uk-checkbox uk-border-rounded" type="checkbox" v-model="a_week"> Every days</label>
                        <label><input class="uk-checkbox uk-border-rounded" type="checkbox" v-model="weekdays_only"> Weekdays only</label>
                        <label><input class="uk-checkbox uk-border-rounded" type="checkbox" v-model="weekend_only"> Weekend only</label>
                    </div>
                </div>
                <div class="uk-width-1-2">
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-2">
                            <label for="startShift" class="uk-form-label">Dari jam</label>
                            <datetime 
                                id="startShift" 
                                input-class="uk-input uk-text-center uk-border-rounded"
                                type="time"
                                title="Start Shift"
                                placeholder="--:--"
                                v-model="shift_data.start_shift"
                            ></datetime>
                        </div>
                        <div class="uk-width-1-2">
                            <label for="endShift" class="uk-form-label">Sampai jam</label>
                             <datetime 
                                id="endShift" 
                                input-class="uk-input uk-text-center uk-border-rounded"
                                type="time"
                                title="End Shift"
                                placeholder="--:--"
                                v-model="shift_data.end_shift"
                            ></datetime>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-2"></div>
                <div class="uk-width-1-2">
                    <label for="jobTitle" class="uk-form-label">Job Title</label>
                    <select 
                        id="jobTitle" 
                        class="uk-select uk-border-rounded" 
                        v-model="shift_data.role_id" 
                        @change="filterMitraListByJobTitle"
                    >
                        <option :value="null" selected>Pilih job title...</option>
                        <option 
                            v-for="(role, index) in company_roles" 
                            :key="index" 
                            :value="role._id"
                        >{{ role.name }}</option>
                    </select>
                </div>
                <div class="uk-width-1-2"></div>
                <div class="uk-width-1-4">
                    <button 
                        type="button" 
                        class="uk-button uk-button-default uk-border-rounded uk-width-1-1 uk-text-bolder"
                        style="border: 1px solid #0275D8; color: #0275D8; background-color: white;"
                        @click="showAddWorkerModal"
                    >{{ addMitraButtonText }}</button>
                </div>
                <div class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-right uk-margin-large-top">
                    <button 
                        v-if="button_create_shift_loading" 
                        type="button"
                        class="uk-button uk-button-default uk-flex uk-flex-middle uk-flex-center"
                        disabled
                    >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button 
                        v-else 
                        type="submit" 
                        class="uk-button uk-button-primary"
                    >Save</button>
                </div>
            </form>
            <!-- End form add shift -->

            <!-- Add worker modal box -->
            <div v-if="$can('WRITE', 'rostering-shift')" id="add-worker-modal" class="uk-flex-top uk-modal-container add-worker-modal" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" @click="cancelSelectWorker" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Tambah Mitra</h2>
                    </div>
                    <div v-if="modalLoading" class="uk-flex uk-flex-center uk-flex-middle uk-padding-large">
                        <div uk-spinner="ratio: 2"></div>
                    </div>
                    <template v-else>
                        <div class="uk-padding-small uk-padding-remove-vertical" style="padding: 0px 27px;">
                            <div class="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
                                <div class="uk-flex uk-flex-middle">
                                    <input 
                                        class="uk-checkbox"
                                        type="checkbox"
                                        v-model="select_all_worker"
                                        :checked="workers.length === 10"
                                        @change="selectAllWorker"
                                    >
                                    <form class="uk-margin-small-left" @submit.prevent="searchMitraList">
                                        <div class="uk-inline">
                                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                                            <input 
                                                type="search" 
                                                class="uk-input select-input" 
                                                placeholder="Search worker by name"
                                                v-model="meta.fullname"
                                            >
                                        </div>
                                    </form>
                                </div>
                                <button 
                                    class="uk-button" 
                                    :class="[workers.length < 1 ? 'uk-button-default' : ' uk-button-primary']" 
                                    :disabled="workers.length < 1" 
                                    @click="deselectAllWorker"
                                >Deselect All</button>
                            </div>
                        </div>
                        <div class="uk-margin-top uk-padding-small uk-padding-remove-vertical" uk-overflow-auto>
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-small uk-table-striped uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th class="uk-text-nowrap"></th>
                                            <th class="uk-text-nowrap">Name</th>
                                            <th class="uk-text-nowrap">Location</th>
                                            <th class="uk-text-nowrap">Existing Shift</th>
                                            <th class="uk-text-nowrap">Job Role</th>
                                            <th class="uk-text-nowrap">Phone Number</th>
                                            <th class="uk-text-nowrap">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="mitra_list && mitra_list.docs && mitra_list.docs.length > 0">
                                        <tr v-for="(data, index) in mitra_list.docs" :key="index">
                                            <td>
                                                <input 
                                                    class="uk-checkbox" 
                                                    type="checkbox"
                                                    :checked="findSelectedWorker({ mitraId: data._id })"
                                                    @change="selectWorker({ mitraId: data._id, userId: data.user_id })"
                                                >
                                            </td>
                                            <td class="uk-flex uk-flex-middle uk-text-nowrap">
                                                <span>
                                                    <img
                                                        v-if="!data.photo_url"
                                                        :data-src="`${images}/avatar.svg`"
                                                        alt="Avatar Icon"
                                                        class="uk-preserve-width uk-border-circle"
                                                        style="object-fit: contain; object-position: center; width: 40px; height: 40px;"
                                                        uk-img
                                                    >
                                                    <img
                                                        v-else
                                                        :data-src="data.photo_url.file_url"
                                                        @error="(e) => imgError(e)"
                                                        alt="Avatar Icon"
                                                        class="uk-preserve-width uk-border-circle"
                                                        style="object-fit: contain; object-position: center; width: 40px; height: 40px;"
                                                        uk-img
                                                    >
                                                </span>
                                                <span class="uk-margin-small-left">{{ data.fullname || '-' }}</span>
                                            </td>
                                            <td class="uk-text-nowrap">{{ data.location || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.existing_shift || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.job_role || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.phone_number || '-' }}</td>
                                            <td class="uk-text-nowrap">{{ data.email || '-' }}</td>
                                        </tr>
                                    </tbody>
                                    <empty-table v-else :colspan="7"/>
                                </table>
                            </div>
                            <pagination
                                v-if="mitra_list && mitra_list.totalDocs"
                                :total-data="mitra_list.totalDocs"
                                :change-limit="changeLimit"
                                :change-page="changePage"
                            />
                        </div>
                        <div class="uk-margin-top uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button 
                                type="button"
                                class="uk-button uk-button-primary"
                                @click="saveSelectedWorker"
                            >Save</button>
                        </div>
                    </template>
                </div>
            </div>
            <!-- End add worker modal box -->

            <!-- Handler if don't have access permission -->
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>Selamat Datang di Company Dashboard</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler if don't have access permission -->
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import { Datetime } from 'vue-datetime';
import formatter from "@/utils/formatter";
import { 
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom 
} from '@/utils/notification';
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';

export default {
    name: 'AddShift',
    components: {
        Datetime,
        Pagination,
        EmptyTable
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            modalLoading: false,
            profile: null,
            company_roles: null,
            shift_data: {
                shift_name: '',
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                start_shift: null,
                end_shift: null,
                role_id: null,
                users: []
            },
            a_week: false,
            weekdays_only: false,
            weekend_only: false,
            mitra_list: null,
            meta: {
                limit: 10,
                page: 1,
                role_id: null,
                fullname: null
            },
            select_all_worker: false,
            workers: [],
            select_all_worker_temp: false,
            workers_temp: [],
            button_create_shift_loading: false
        };
    },
    watch: {
        a_week(value) {
            if(value) {
                this.weekdays_only = false;
                this.weekend_only = false;
                this.toggleChooseDayButton({
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true
                });
            } else {
                if (
                    !this.shift_data.monday ||
                    !this.shift_data.tuesday ||
                    !this.shift_data.wednesday ||
                    !this.shift_data.thursday ||
                    !this.shift_data.friday ||
                    !this.shift_data.saturday ||
                    !this.shift_data.sunday
                ) {
                    this.toggleChooseDayButton({
                        monday: this.shift_data.monday,
                        tuesday: this.shift_data.tuesday,
                        wednesday: this.shift_data.wednesday,
                        thursday: this.shift_data.thursday,
                        friday: this.shift_data.friday,
                        saturday: this.shift_data.saturday,
                        sunday: this.shift_data.sunday
                    });
                } else {
                    this.toggleChooseDayButton({
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false
                    });
                }
            }
        },
        weekdays_only(value) {
            if(value) {
                this.a_week = false;
                this.weekend_only = false;
                this.toggleChooseDayButton({
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false,
                    sunday: false
                });
            } else {
                if (
                    !this.shift_data.monday ||
                    !this.shift_data.tuesday ||
                    !this.shift_data.wednesday ||
                    !this.shift_data.thursday ||
                    !this.shift_data.friday ||
                    this.shift_data.saturday ||
                    this.shift_data.sunday
                ) {
                    this.toggleChooseDayButton({
                        monday: this.shift_data.monday,
                        tuesday: this.shift_data.tuesday,
                        wednesday: this.shift_data.wednesday,
                        thursday: this.shift_data.thursday,
                        friday: this.shift_data.friday,
                        saturday: this.shift_data.saturday,
                        sunday: this.shift_data.sunday
                    });
                } else {
                    this.toggleChooseDayButton({
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false
                    });
                }
            }
        },
        weekend_only(value) {
            if(value) {
                this.a_week = false;
                this.weekdays_only = false;
                this.toggleChooseDayButton({
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: true,
                    sunday: true
                });
            } else {
                if (
                    this.shift_data.monday ||
                    this.shift_data.tuesday ||
                    this.shift_data.wednesday ||
                    this.shift_data.thursday ||
                    this.shift_data.friday ||
                    !this.shift_data.saturday ||
                    !this.shift_data.sunday
                ) {
                    this.toggleChooseDayButton({
                        monday: this.shift_data.monday,
                        tuesday: this.shift_data.tuesday,
                        wednesday: this.shift_data.wednesday,
                        thursday: this.shift_data.thursday,
                        friday: this.shift_data.friday,
                        saturday: this.shift_data.saturday,
                        sunday: this.shift_data.sunday
                    });
                } else {
                    this.toggleChooseDayButton({
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false
                    });
                }
            }
        },
        async meta() {
            this.mitra_list = await this.getMitraList(this.meta);
            this.findSelectedAllWorker();
        }
    },
    computed: {
        addMitraButtonText() {
            return this.shift_data.users.length < 1 ? 'Tambah Mitra' : `${this.shift_data.users.length} Mitra Terpilih`;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.add-worker-modal')) window.UIkit.modal('.add-worker-modal').$destroy(true);
    },
    async mounted() {
        this.isLoading = true;
        try {
            this.profile = await this.getProfile();
            this.company_roles = await this.getRolesOnCompany(this.profile);
            this.fillShiftData();
        } catch (error) {
            notificationDanger(error);
        }
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            getProfile: 'auth/getProfile',
            getRolesOnCompany: 'option_data/getRolesOnCompany',
            getMitraList: 'new_shift/getMitraList',
            addShift: 'new_shift/addShift',
        }),
        goBack() {
            this.$router.push('/admin/shift');
        },
        async showAddWorkerModal() {
            this.modalLoading = true;
            try {
                await window.UIkit.modal('#add-worker-modal').show();
                this.mitra_list = await this.getMitraList(this.meta);
            } catch (error) {
                notificationDanger(error);
            }
            this.modalLoading = false;
        },
        async filterMitraListByJobTitle() {
            this.meta.role_id = this.shift_data.role_id;
            this.mitra_list = await this.getMitraList(this.meta);
        },
        async searchMitraList() {
            this.mitra_list = await this.getMitraList(this.meta);
            this.findSelectedAllWorker();
        },
        selectAllWorker() {
            if (this.select_all_worker) {
                this.mitra_list.docs.forEach((data) => {
                    const findData = this.workers.find((worker) => worker._id === data._id);
                    if (!findData) {
                        this.workers.push({
                            _id: data._id,
                            user_id: data.user_id
                        });
                    }
                });
            } else {
                this.mitra_list.docs.forEach((data) => {
                    this.workers = this.workers.filter((item) => item._id !== data._id);
                });
            }
        },
        deselectAllWorker() {
            this.select_all_worker_temp = this.select_all_worker;
            this.workers_temp = this.workers;
            this.select_all_worker = false;
            this.workers = [];
        },
        cancelSelectWorker() {
            this.select_all_worker = this.select_all_worker_temp;
            this.workers = this.workers_temp;
        },
        selectWorker({ mitraId, userId }) {
            this.select_all_worker = false;
            let selectedIds = [];
            const resultFindSameData = this.workers.find((item) => item._id === mitraId);
            selectedIds =
                this.workers.length > 0 ?
                    resultFindSameData ?
                        this.workers.filter((item) => item._id !== mitraId)
                        : [...this.workers, ...[{ _id: mitraId, user_id: userId }]]
                    : [{ _id: mitraId, user_id: userId }];
            this.workers = selectedIds;
        },
        findSelectedWorker({ mitraId }) {
            return this.workers.find((worker) => worker._id === mitraId);
        },
        findSelectedAllWorker() {
            const mitraIds = this.mitra_list.docs.map((data) => data._id);
            const workerIds = this.workers.map((worker) => worker._id);
            const result = mitraIds.filter((data) => workerIds.includes(data));
            if (result.length === this.mitra_list.docs.length) {
                this.select_all_worker = true;
            } else {
                this.select_all_worker = false;
            }
        },
        async saveSelectedWorker() {
            await window.UIkit.modal('#add-worker-modal').hide();
            this.shift_data.users = this.workers;
        },
        async createShift() {
            this.button_create_shift_loading = true;
            try {
                const start_shift = this.formatTimeShift(this.shift_data.start_shift);
                const end_shift = this.formatTimeShift(this.shift_data.end_shift);
                const payload = {
                    company_office_id: this.$route.params.company_office_id,
                    shift_name: this.shift_data.shift_name,
                    role_id: this.shift_data.role_id,
                    monday: this.shift_data.monday,
                    tuesday: this.shift_data.tuesday,
                    wednesday: this.shift_data.wednesday,
                    thursday: this.shift_data.thursday,
                    friday: this.shift_data.friday,
                    saturday: this.shift_data.saturday,
                    sunday: this.shift_data.sunday,
                    start_shift,
                    end_shift,
                    users: this.shift_data.users
                };
                const response = await this.addShift(payload);
                if (response && response.status === 'OK' && response.result === 'success') {
                    notificationSuccess(`Shift ${payload.shift_name} successfully created!`);
                    this.resetShiftData();
                    setTimeout(() => {
                        this.$router.push('/admin/shift');
                    }, 500);
                } else {
                    notificationDangerCustom('Failed to create a new shift!');
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.button_create_shift_loading = false;
        },
        chooseDay({ day }) {
            switch (day) {
            case 'monday':
                this.a_week = false;
                this.weekdays_only = false;
                this.weekend_only = false;
                this.shift_data.monday = !this.shift_data.monday;   
                break;
            case 'tuesday':    
                this.a_week = false;
                this.weekdays_only = false;
                this.weekend_only = false;
                this.shift_data.tuesday = !this.shift_data.tuesday;
                break;
            case 'wednesday':
                this.a_week = false;
                this.weekdays_only = false;
                this.weekend_only = false;
                this.shift_data.wednesday = !this.shift_data.wednesday;
                break;
            case 'thursday':
                this.a_week = false;
                this.weekdays_only = false;
                this.weekend_only = false;
                this.shift_data.thursday = !this.shift_data.thursday;
                break;
            case 'friday':
                this.a_week = false;
                this.weekdays_only = false;
                this.weekend_only = false;
                this.shift_data.friday = !this.shift_data.friday;
                break;
            case 'saturday':
                this.a_week = false;
                this.weekdays_only = false;
                this.weekend_only = false;
                this.shift_data.saturday = !this.shift_data.saturday;
                break;
            case 'sunday':
                this.a_week = false;
                this.weekdays_only = false;
                this.weekend_only = false;
                this.shift_data.sunday = !this.shift_data.sunday;
                break;
            }
        },
        toggleChooseDayButton({ monday, tuesday, wednesday, thursday, friday, saturday, sunday }) {
            this.shift_data.monday = monday ? monday : false;
            this.shift_data.tuesday = tuesday ? tuesday : false;
            this.shift_data.wednesday = wednesday ? wednesday : false;
            this.shift_data.thursday = thursday ? thursday : false;
            this.shift_data.friday = friday ? friday : false;
            this.shift_data.saturday = saturday ? saturday : false;
            this.shift_data.sunday = sunday ? sunday : false;
        },
        fillShiftData() {
            this.shift_data.start_shift = this.formatHourMinute({ hour: '07', minute: '00' });
            this.shift_data.end_shift = this.formatHourMinute({ hour: '16', minute: '00' });
        },
        resetShiftData() {
            this.shift_data = {
                shift_name: '',
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                start_shift: null,
                end_shift: null,
                role_id: null,
                users: []
            };
            this.select_all_worker = false;
            this.workers = [];
            this.select_all_worker_temp = false;
            this.workers_temp = [];
        },
        resetMeta() {
            this.meta.page = 1;
            this.meta.fullname = null;
        },
        formatTimeShift(value) {
            if (value == '') {
                return null;
            } else {
                return {
                    hour: new Date(value).getHours(),
                    minute: new Date(value).getMinutes()
                };
            }
        },
        formatHourMinute(data) {
            if (!data) {
                return '-';
            }
            return formatter.hourMinuteOnly(data);
        },
        imgError(e) {
            e.target.src = `${this.images}/avatar.svg`;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
    .mr-button-primary {
        background: #0ABAB5;
        color: white;
    }

    thead {
        background: #0ABAB5;
    }

    th {
        color: #FFFFFF;
    }
</style>
