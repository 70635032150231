<template>
  <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
    <!-- Back button -->
    <button class="uk-button uk-button-default" @click="goBack">
      <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
      <span>Back</span>
    </button>
    <!-- End back button -->

    <!-- Loader -->
    <div
      v-if="isLoading"
      class="uk-flex uk-flex-center uk-flex-middle"
      style="min-height: 100%"
    >
      <div uk-spinner="ratio: 2"></div>
    </div>
    <!-- End loader -->

    <!-- Page content -->
    <div v-else-if="!isLoading">
      <!-- Job detail -->
      <div
        v-if="$can('READ', 'job-vacancy') && jobDetail !== null"
        class="uk-margin-top uk-margin-bottom uk-card uk-card-default uk-padding uk-text-break"
      >
        <div class="uk-margin-medium-left uk-flex uk-flex-middle uk-flex-between">
          <p class="uk-margin-remove job-title uk-width-1-2">
            {{ jobDetail.title || "-" }}
          </p>
          <p class="uk-margin-remove uk-margin-left text-detail uk-width-1-2 uk-text-right">
            Created At: {{ formatDate({ date: jobDetail.created_at || null }) }}
          </p>
        </div>
        <div class="uk-margin-medium-left uk-flex uk-flex-middle uk-flex-left">
          <p class="uk-margin-remove" :class="labelStatus">{{ postStatus }}</p>
          <button
            v-if="postStatus == 'Live'"
            class="uk-margin-small-left uk-button uk-button-small"
            v-clipboard:copy="`${pwaUrl}${jobDetail.seo_url}`"
            v-clipboard:success="onCopy"
          >
            <img
              :src="`${images}/share.svg`"
              alt="Share icon"
              style="width: 25px"
            />
          </button>
        </div>
        <div class="uk-margin-medium-left uk-margin-top text-detail">
          <p class="uk-margin-remove">
            Description: {{ jobDetail.job_description || "-" }}
          </p>
          <p class="uk-margin-remove">
            Status Kerja:
            {{ jobDetail.job_type ? jobDetail.job_type.name : "-" }}
          </p>
          <p class="uk-margin-remove">
            Job Industry:
            {{ jobDetail.job_industry ? jobDetail.job_industry.name : "-" }}
          </p>
          <p class="uk-margin-remove">
            Fee: Rp{{ formatCurrency({ data: jobDetail.salary || null }) }}/{{
              jobDetail.salary_unit || "-"
            }}
          </p>
          <p class="uk-margin-remove">
            Fee Lembur:
            {{
              jobDetail.salary_overtime_hourly == 0
                ? "-"
                : `Rp${formatCurrency({
                    data: jobDetail.salary_overtime_hourly || null,
                  })}/Jam`
            }}
          </p>
          <p v-if="jobDetail.benefits" class="uk-margin-remove">
            Benefit: {{ jobDetail.benefits }}
          </p>
          <p class="uk-margin-remove">Lokasi: {{ jobDetail.city || "-" }}</p>
          <p class="uk-margin-remove">
            Mulai Bekerja:
            {{ formatDate({ date: jobDetail.job_start || null }) }}
          </p>
          <p
            v-if="jobDetail.working_hour_start && jobDetail.working_hour_end"
            class="uk-margin-remove"
          >
            Jam Kerja: {{ jobDetail.working_hour_start }} -
            {{ jobDetail.working_hour_end }}
          </p>
          <p class="uk-margin-remove">
            Gender: {{ genderTranslater({ data: jobDetail.gender || null }) }}
          </p>
          <p class="uk-margin-remove">
            Usia: {{ jobDetail.age_group || "Bebas" }}
          </p>
          <p class="uk-margin-remove">
            Pendidikan Minimal:
            {{
              jobDetail.education_level ? jobDetail.education_level.name : "-"
            }}
          </p>
          <p v-if="jobDetail.is_experience_needed" class="uk-margin-remove">
            Dibutuhkan keahlian: {{ jobDetail.skill_needed || "-" }}
          </p>
          <p
            v-if="jobDetail.required_docs && jobDetail.required_docs.length > 0"
            class="uk-margin-remove"
          >
            Dibutuhkan dokumen:
            <span v-for="(doc, index) in jobDetail.required_docs" :key="index"
              >{{ doc }}
            </span>
          </p>
          <template v-if="jobDetail.required_tests">
            <p
              class="uk-margin-remove"
              v-for="(test, index) in jobDetail.required_tests"
              :key="index"
            >
              Dibutuhkan test {{ test.test_category_id.name }} minimal score
              {{ test.minimum_score }}
            </p>
          </template>
        </div>
        <div class="uk-margin-medium-left uk-margin-top uk-grid-small" uk-grid>
          <div class="uk-width-1-2 uk-padding-remove text-detail">
            <button
              class="uk-margin-remove uk-text-primary"
              @click="
                setApplicantData({
                  jobId: jobDetail._id,
                  limit: jobDetail.total_applicant,
                })
              "
            >
              Total Applicant: {{ jobDetail.total_applicant }}
            </button>
            <p class="uk-margin-remove">
              Dilihat oleh {{ jobDetail.job_view }} user
            </p>
          </div>
          <div
            v-if="$can('EDIT', 'job-vacancy')"
            class="uk-width-1-2 uk-padding-remove uk-flex uk-flex-middle uk-flex-right"
          >
            <!-- Button Download Template-->
            <div>
              <button
                class="uk-button uk-button-default"
                style="
                  border-radius: 5px;
                  width: 250px;
                  border: 1px solid #0abab5;
                  color: #0abab5;
                "
                @click="downloadTemplateJobVacancy"
              >
                <img :src="`${images}/shift-download.svg`" alt="" width="25" />
                Download Template
              </button>
            </div>
            <!-- Button Import User-->
            <div class="uk-margin-small-right">
              <button
                class="uk-button uk-button-default"
                style="
                  border-radius: 5px;
                  width: 175px;
                  border: 1px solid #0abab5;
                  color: white;
                  margin-left: 1rem;
                  background-color: #0abab5;
                "
                @click="importUserModal"
              >
                <img :src="`${images}/shift-upload.svg`" alt="" width="25" />
                Import User
              </button>
            </div>
            <button
              class="uk-button uk-button-small-large uk-button-default"
              type="button"
            >
              Action
            </button>
            <div uk-dropdown="boundary: .uk-container;pos: top-right">
              <ul class="uk-nav uk-dropdown-nav">
                <li>
                  <button
                    class="uk-button uk-button-small"
                    type="button"
                    @click="editJobPost({ jobDetail })"
                  >
                    <span uk-icon="file-edit"></span>
                    Edit Job Vacancy
                  </button>
                </li>
                <li class="uk-nav-divider"></li>
                <template v-if="jobDetail.shift == null">
                  <li>
                    <button
                      class="uk-button uk-button-small"
                      type="button"
                      @click="editAutoAssign({ jobDetail })"
                    >
                      <span uk-icon="unlock"></span>
                      Enable Auto Assign Mitra
                    </button>
                  </li>
                </template>
                <template v-else>
                  <li>
                    <button
                      class="uk-button uk-button-small"
                      type="button"
                      @click="editAutoAssign({ jobDetail })"
                    >
                      <span uk-icon="pencil"></span>
                      Edit Auto Assign Mitra
                    </button>
                  </li>
                  <li class="uk-nav-divider"></li>
                  <li>
                    <button
                      class="uk-button uk-button-small"
                      type="button"
                      v-clipboard:copy="`${autoAssignUrl}${jobDetail.seo_url}`"
                      v-clipboard:success="onCopy"
                    >
                      <span uk-icon="social"></span>
                      Copy Special Link
                    </button>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <button
            class="uk-button uk-button-primary uk-text-bold uk-border-rounded"
            @click="(isShowAddUserModal = true)"
          >
            <img :data-src="`${images}/add-user-outline.svg`" alt="icon add multi" uk-img class="photo">
            Create User
          </button>
        </div>
      </div>
      <!-- End job detail -->

      <!-- Applicant list -->
      <div
        v-if="$can('READ', 'job-vacancy') && applicants.length > 0"
        class="uk-card uk-card-default uk-margin-top uk-text-break"
      >
        <div class="uk-overflow-auto">
          <table class="uk-table uk-table-medium uk-table-striped">
            <thead>
              <tr>
                <th class="uk-table-shrink uk-text-center">No</th>
                <th class="uk-table-expand">Nama</th>
                <th class="uk-table-expand">Usia</th>
                <th class="uk-table-expand">Gender</th>
                <th class="uk-table-expand">Pendidikan</th>
                <th class="uk-table-expand">Kota</th>
                <th class="uk-table-expand">Required Test Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(applicant, index) in applicants" :key="index">
                <td class="uk-text-center">{{ index + 1 }}</td>
                <td>{{ applicant.user ? applicant.user.fullname : "-" }}</td>
                <td>{{ formatAge({ data: applicant.user.birthdate }) }}</td>
                <td>{{ genderTranslater({ data: applicant.user.gender }) }}</td>
                <td>
                  {{
                    applicant.user.education_level
                      ? applicant.user.education_level.name
                      : "-"
                  }}
                </td>
                <td>
                  {{
                    applicant.user.domicile ? applicant.user.domicile.name : "-"
                  }}
                </td>
                <td>
                  <template
                    v-if="
                      applicant.all_user_scores &&
                      applicant.all_user_scores.length > 0
                    "
                  >
                    <p
                      v-for="(test, index) in applicant.all_user_scores"
                      :key="index"
                      class="uk-margin-remove"
                    >
                      <template v-if="!applicant.all_user_scores[x + 1]">
                        {{ test.test_category_id.name }} {{ test.score }}
                      </template>
                      <template v-else>
                        {{ test.test_category_id.name }} {{ test.score }}
                      </template>
                    </p>
                  </template>
                  <template v-else>
                    <span>-</span>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- End applicant list -->

      <!-- Import User modal -->
      <modal
        name="import-user"
        height="auto"
        styles="max-height: 90%; overflow-y: auto;"
        :clickToClose="false"
      >
        <button
          class="uk-modal-close-default"
          type="button"
          uk-close
          @click="hideImportUser"
        ></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center">Import User</h2>
        </div>
        <form @submit.prevent="importUserJobVacancy">
          <div class="uk-padding uk-grid-small" uk-grid>
            <div class="uk-width-1-1">
              <div
                class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove"
                uk-grid
              >
                <div
                  class="uk-width-1-2 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-left"
                >
                  <span class="uk-text-bold"> Select Shift </span>
                </div>
                <div class="uk-width-1-2 uk-padding-remove uk-margin-remove">
                  <multiselect
                    placeholder="Select shift"
                    label="name"
                    id="shift"
                    name="shift"
                    track-by="_id"
                    :internal-search="true"
                    :multiple="false"
                    :options="shifts"
                    v-model="formDataJobVacancy.shift_id"
                    v-validate="'required'"
                    required
                  ></multiselect>
                  <span
                    class="uk-text-small uk-text-danger"
                    v-show="errors.has('shift')"
                    >{{ errors.first("shift") }}</span
                  >
                </div>
              </div>
            </div>
            <div class="uk-width-1-1">
              <div
                class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove"
                uk-grid
              >
                <div
                  class="uk-width-1-2 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-left"
                >
                  <span class="uk-text-bold"> Date From </span>
                </div>
                <div class="uk-width-1-2 uk-padding-remove uk-margin-remove">
                  <div class="uk-inline uk-width-expand">
                    <datetime
                        id="endDate"
                        name="end_date"
                        input-class="uk-input uk-border-rounded"
                        type="date"
                        placeholder="dd/mm/yyyy"
                        v-model="formDataJobVacancy.from"
                        v-validate="'required'"
                        required
                    ></datetime>
                    <span
                      class="uk-text-small uk-text-danger"
                      v-show="errors.has('end_date')"
                      >{{ errors.first("end_date") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1">
              <div
                class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove"
                uk-grid
              >
                <div
                  class="uk-width-1-2 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-left"
                >
                  <span class="uk-text-bold"> Date to </span>
                </div>
                <div class="uk-width-1-2 uk-padding-remove uk-margin-remove">
                  <div class="uk-inline uk-width-expand">
                    <datetime
                        id="startDate"
                        name="start_date"
                        input-class="uk-input uk-border-rounded"
                        type="date"
                        placeholder="dd/mm/yyyy"
                        v-model="formDataJobVacancy.to"
                        :min-datetime="datetime.fromISO(formDataJobVacancy.from).plus({days: 1}).toISODate()"
                        v-validate="'required'"
                        required
                    ></datetime>
                    <span
                      class="uk-text-small uk-text-danger"
                      v-show="errors.has('start_date')"
                      >{{ errors.first("start_date") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1">
              <div
                class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove"
                uk-grid
              >
                <div
                  class="uk-width-1-2 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-left"
                >
                  <span class="uk-text-bold"> Select File to Import </span>
                </div>
                <div class="uk-width-1-2 uk-padding-remove uk-margin-remove">
                  <div v-if="!formDataJobVacancy.file" class="uk-width-expand">
                    <button
                      type="button"
                      class="uk-button uk-button-default"
                      style="
                        border-radius: 5px;
                        width: 260px;
                        border: 1px solid #0abab5;
                        color: #0abab5;
                      "
                      @click="triggerUpload"
                    >
                      <img
                        :src="`${images}/shift-download.svg`"
                        alt=""
                        width="25"
                      />
                      <input
                        ref="upload"
                        style="display: none"
                        type="file"
                        accept=".xls,.xlsx"
                        @change="uploadFileMigration"
                      />
                      Select File
                    </button>
                  </div>
                  <div class="uk-width-expand" v-else>
                    {{ formDataJobVacancy.file.name }}
                  </div>
                </div>
              </div>
            </div>
            <template v-if="requiredTests.length > 0">
              <div
                v-for="(category, index) in requiredTests"
                :key="index"
                class="uk-width-1-1"
              >
                <label
                  for="minimumScore"
                  class="uk-form-label"
                  :key="category.name"
                  >{{ category.name || "-" }} Minimum Score
                  <span class="uk-text-danger">*</span></label
                >
                <input
                  :key="category._id"
                  id="minimumScore"
                  name="minimum_score"
                  class="uk-input uk-border-rounded"
                  type="number"
                  v-model="category.minimum_score"
                  max="100"
                  min="0"
                  required
                />
              </div>
            </template>
          </div>
          <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
            <button
              v-if="buttonFormDataUpdateJobPostLoading"
              class="uk-button uk-button-default uk-flex uk-flex-middle"
              type="button"
              disabled
            >
              <span uk-spinner="ratio: 0.5"></span>
              <span class="uk-margin-small-left">Loading</span>
            </button>
            <button
              v-if="btnImportMitra"
              type="submit"
              class="uk-button uk-button-default"
              disabled
            >
              <span uk-spinner="ratio: 0.5"></span>
              <span class="uk-margin-small-left">Uploading</span>
            </button>
            <button
              v-else
              class="uk-button"
              :class="{
                'uk-button-default': importUserValidate,
                'uk-button-primary': !importUserValidate,
              }"
              type="submit"
              :disabled="importUserValidate"
            >
              Save
            </button>
          </div>
        </form>
      </modal>
      <!-- End Import User modal -->

      <!-- Failed User Upload modal -->
      <modal
        name="failed-user-upload"
        :min-width="320"
        :max-width="740"
        width="60%"
        height="auto"
        styles="max-height: 90%; overflow-y: auto;"
        :clickToClose="false"
      >
        <button
          class="uk-modal-close-default"
          type="button"
          uk-close
          @click="hideFailedToUpload"
        ></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center">Failed to Upload</h2>
        </div>
        <div
          class="modal-margin uk-flex uk-flex-middle uk-flex-between uk-margin-top"
        >
          <p class="uk-margin-remove uk-width-1-2 uk-text-bold">
            {{ "Incorrect data" }}
          </p>
          <div class="uk-margin-remove text-detail uk-width-1-2 uk-text-right">
            <button
              :disabled="downloadFailedUserUploadIsLoading"
              class="uk-button uk-button-default"
              style="
                border-radius: 5px;
                width: 50%;
                border: 1px solid #0275d8;
                color: white;
                background-color: #0275d8;
              "
              @click="downloadTemplateFailedUser"
            >
              <img
                :src="`${images}/download-attendance-verification.svg`"
                alt=""
                width="18"
                style="margin: 0px 7px 3px 0px"
              />
              {{ "Download" }}
            </button>
          </div>
        </div>
        <div class="uk-border-rounded uk-margin">
          <div class="uk-overflow-auto modal-margin">
            <table class="uk-table uk-table-medium uk-table-divider">
              <thead>
                <tr>
                  <th class="uk-text-center uk-table-shrink">No</th>
                  <th class="uk-text-center uk-width-auto">Name</th>
                  <th class="uk-text-center uk-width-auto">Email</th>
                  <th class="uk-text-center uk-width-auto">Status</th>
                </tr>
              </thead>
              <tbody v-if="true">
                <tr v-for="(item, i) in failedUser" :key="i">
                  <td class="uk-text-center">{{ i + 1 }}</td>
                  <td class="uk-text-center uk-width-auto">
                    {{ item.fullname }}
                  </td>
                  <td class="uk-text-center uk-width-auto">{{ item.email }}</td>
                  <td class="uk-text-center">{{ item.notes }}</td>
                </tr>
              </tbody>
              <empty-table v-else :colspan="6" />
            </table>
          </div>
        </div>
        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
          <button
            class="uk-button uk-button-primary"
            type="button"
            @click="hideFailedToUpload"
          >
            Close
          </button>
        </div>
      </modal>
      <!-- End Failed User Upload modal -->

      <!-- Edit job post modal box -->
      <div v-if="multiJobPost.isOpen">
        <div class="mainModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="resetFormData" />
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center">{{ multiJobPost.postType === 'multi' ? 'Create Multiple Job Posts' : 'Job Post Form' }}</h2>
            </div>
            <ul class="uk-child-width-expand" uk-tab>
              <li :class="[multiJobPost.activeTab === 0 && 'active']">
                <a
                  class="uk-text-bold"
                  :class="[multiJobPost.activeTab === 0 && 'uk-text-primary']"
                  href="#"
                  @click="multiJobPost.activeTab = 0"
                >
                  Edit Job Post
                </a>
              </li>
              <li :class="[multiJobPost.activeTab === 1 && 'active']">
                <a
                  class="uk-text-bold"
                  :class="[multiJobPost.activeTab === 1 && 'uk-text-primary']"
                  href="#"
                  @click="multiJobPost.activeTab = 1"
                >
                  Additional Question(s)
                </a>
              </li>
            </ul>
            <div class="uk-modal-body uk-padding-remove-top">
              <AddSingleJobPost
                v-if="multiJobPost.activeTab === 0"
                :jobTypeOptions="jobTypeOptions"
                :contractOptions="allContractOptions"
                :ageFilter="ageFilter"
                :genderOptions="genderOptions"
                :educationLevelOptions="educationLevelOptions"
                :docOptions="docOptions"
                :additionalDocOptions="additionalDocOptions"
                :jobPreferenceOptions="jobPreferenceOptions"
                :testCategoryOptions="testCategoryOptions"
                :adminJobVacancyOptions="adminJobVacancyOptions"
                :formData="multiJobPost.formData"
                :employerCompanyId="employerProfile.company_id"
                @inValid="(multiJobPost.postIsValid = false)"
                @valid="(multiJobPost.postIsValid = true)"
              />
              <AddQuestion
                v-else
                :formData="multiJobPost.formData"
                @inValid="(multiJobPost.questionisValid = false)"
                @valid="(multiJobPost.questionisValid = true)"
              />
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button
                v-if="buttonFormDataUpdateJobPostLoading"
                class="uk-button uk-button-default uk-flex uk-flex-middle"
                disabled
              >
                <span uk-spinner="ratio: 0.5"></span>
                <span class="uk-margin-small-left">Loading</span>
              </button>
              <button
                v-else
                class="uk-button"
                :class="(multiJobPost.postIsValid && multiJobPost.questionisValid) ? 'uk-button-primary' : 'uk-button-default uk-text-lighter'"
                :style="{ cursor: (multiJobPost.postIsValid && multiJobPost.questionisValid) ? 'pointer' : 'default' }"
                :disabled="!multiJobPost.postIsValid || !multiJobPost.questionisValid"
                @click="updateJobPost"
              >Save</button>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit job post modal box -->

      <!-- Auto assign form modal -->
      <modal
        v-if="$can('EDIT', 'job-vacancy')"
        name="auto-assign-job-post-form-modal"
        height="auto"
        styles="max-height: 90%; overflow-y: auto;"
        :clickToClose="false"
      >
        <button
          class="uk-modal-close-default"
          type="button"
          uk-close
          @click="$modal.hide('auto-assign-job-post-form-modal')"
        ></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center">
            Choose Shift for the Candidate
          </h2>
        </div>
        <form @submit.prevent="updateAutoAssign">
          <div class="uk-padding uk-grid-small" uk-grid>
            <div class="uk-width-1-1">
              <label for="shift" class="uk-form-label"
                >Shift <span class="uk-text-danger">*</span></label
              >
              <multiselect
                placeholder="Select shift"
                label="name"
                id="shift"
                name="shift"
                track-by="_id"
                :internal-search="true"
                :multiple="false"
                :options="shifts"
                v-model="formAutoAssignMitra.shift_id"
                v-validate="'required'"
                required
              ></multiselect>
              <span
                class="uk-text-small uk-text-danger"
                v-show="errors.has('shift')"
                >{{ errors.first("shift") }}</span
              >
            </div>
            <div class="uk-width-1-1">
              <label for="startDate" class="uk-form-label"
                >Start Date <span class="uk-text-danger">*</span></label
              >
              <datetime
                  id="startDate"
                  name="start_date"
                  input-class="uk-input uk-border-rounded"
                  type="date"
                  placeholder="dd/mm/yyyy"
                  v-model="formAutoAssignMitra.auto_assign_contract.start_date"
                  v-validate="'required'"
                  required
              ></datetime>
              <span
                class="uk-text-small uk-text-danger"
                v-show="errors.has('start_date')"
                >{{ errors.first("start_date") }}</span
              >
            </div>
            <div class="uk-width-1-1">
              <label for="endDate" class="uk-form-label"
                >End Date <span class="uk-text-danger">*</span></label
              >
              <datetime
                  id="endDate"
                  name="end_date"
                  input-class="uk-input uk-border-rounded"
                  type="date"
                  placeholder="dd/mm/yyyy"
                  v-model="formAutoAssignMitra.auto_assign_contract.end_date"
                  v-validate="'required'"
                  required
              ></datetime>
              <span
                class="uk-text-small uk-text-danger"
                v-show="errors.has('end_date')"
                >{{ errors.first("end_date") }}</span
              >
            </div>
            <div class="uk-width-1-1">
              <label for="endOfAutoAssign"
                >End Of Auto Assign <span class="uk-text-danger">*</span></label
              >
              <div class="uk-inline uk-width-expand">
                <datetime
                  id="endOfAutoAssign"
                  name="auto_assign_datetime_end"
                  input-class="uk-input uk-border-rounded"
                  type="datetime"
                  format="cccc dd/MM/yyyy, HH:mm"
                  :min-datetime="moment().add('hours', 1).format()"
                  :minute-step="60"
                  v-model="formAutoAssignMitra.auto_assign_datetime_end"
                  v-validate="'required'"
                  required
                ></datetime>
                <span
                  class="uk-form-icon uk-form-icon-flip"
                  uk-icon="icon: clock"
                ></span>
              </div>
              <span
                class="uk-text-small uk-text-danger"
                v-show="errors.has('auto_assign_datetime_end')"
                >{{ errors.first("auto_assign_datetime_end") }}</span
              >
            </div>
          </div>
          <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
            <button
              v-if="buttonAutoAssignMitraUpdateLoading"
              class="uk-button uk-button-default uk-flex uk-flex-middle"
              type="button"
              disabled
            >
              <span uk-spinner="ratio: 0.5"></span>
              <span class="uk-margin-small-left">Loading</span>
            </button>
            <button v-else class="uk-button uk-button-primary" type="submit">
              Update
            </button>
          </div>
        </form>
      </modal>
      <!-- End auto assign form modal -->

      <!-- If not permitted -->
      <div
        v-else
        class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport"
      >
        <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
          <span class="uk-text-large uk-text-bold">
            <p>You are not authorized for read data this page</p>
          </span>
        </div>
      </div>
      <!-- End if not permitted -->
    </div>
    <!-- End page content -->

    <AddUserModal
      v-if="isShowAddUserModal"
      :data="jobDetail"
      @hideModal="isShowAddUserModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import moment from 'moment';
moment.locale('id');
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom,
} from "@/utils/notification";
import Multiselect from "vue-multiselect";
import { Datetime } from "vue-datetime";
import { DateTime } from "luxon";
import { excelDownloader } from "@/utils/helper";

export default {
    name: "JobPostDetail",
    components: {
        Multiselect,
        Datetime,
        AddUserModal: () => import('./modals/AddUser'),
        AddSingleJobPost: () => import('./modals/AddSingleJobPost'),
        AddQuestion: () => import('./modals/AddQuestion'),
    },
    data() {
        return {
            failedUser: [], // list of failed user after import migration
            downloadFailedUserUploadIsLoading: false,
            isShowAddUserModal: false,
            importUserValidate: false,
            btnImportMitra: false,
            images: PREFIX_IMAGE,
            isLoading: false,
            jobDetail: null,
            applicants: [],
            jobTypeOptions: [],
            educationLevelOptions: [],
            testCategoryOptions: [],
            jobPreferenceOptions: [],
            additionalDocOptions: [],
            contract: [],
            allContractOptions: [],
            adminJobVacancyOptions: [],
            genderOptions: [
                { label: "Pria", value: "male" },
                { label: "Wanita", value: "female" },
                { label: "Pria / Wanita", value: null },
            ],
            ageFilter: {
                needed: false,
                min: 16,
                max: 61,
            },
            docOptions: [
                { label: "KTP", value: "ktp", $isDisabled: true },
                { label: "Ijazah", value: "ijazah" },
                { label: "SIM", value: "sim" },
                { label: "SKCK", value: "skck" },
                { label: "KK", value: "kk" },
            ],
            requiredTests: [],
            formDataJobVacancy: {
                shift_id: null,
                from: null,
                to: null,
                file: null,
            },
            multiJobPost: {
                isOpen: false,
                activeTab: 0,
                postIsValid: false,
                questionisValid: true,
                isLoading: false,
                formData: {
                    id: null,
                    title: '',
                    job_preference_id: '',
                    admin_job_vacancy_id: '',
                    job_description: '',
                    salary: '',
                    salary_overtime_hourly: '',
                    salary_unit: '',
                    job_type_id: '',
                    benefits: null,
                    job_start: null,
                    working_hour_start: '',
                    working_hour_end: '',
                    gender: null,
                    age_group: null,
                    education_level_id: '',
                    skill_needed: '',
                    is_experience_needed: false,
                    required_tests: [],
                    required_docs: [],
                    additional_docs: [],
                    job_contracts: [],
                    additional_questions: [],
                    question_usage: {
                        default: true,
                        auto_assign: false,
                        auto_assign_unreg: false,
                        migration: false,
                    },
                },
            },
            buttonFormDataUpdateJobPostLoading: false,
            shifts: [],
            moment: moment,
            datetime: DateTime,
            formAutoAssignMitra: {
                shift_id: null,
                job_id: null,
                auto_assign_datetime_end: null,
                auto_assign_contract: {
                    start_date: null,
                    end_date: null,
                },
            },
            buttonAutoAssignMitraUpdateLoading: false,
            metaJobPreferenceList: {
                limit: 10000,
                page: 1,
            },
            metaAdminJobVacancyList: {
                limit: 10000,
                page: 1
            },
            employerProfile: null
        };
    },
    watch: {
        formDataJobVacancy: {
            handler() {
                let valid = Object.values(this.formDataJobVacancy).find(
                    (x) => x === null
                );
                this.importUserValidate = valid === null;
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            job_preferences: 'job_preference/job_preferences',
            admin_job_vacancies: 'admin_job_vacancy/admin_job_vacancies'
        }),
        postStatus() {
            return this.jobDetail !== null
                ? this.jobDetail.status == 1
                    ? "Live"
                    : "Inactive"
                : "-";
        },
        labelStatus() {
            return this.jobDetail !== null
                ? this.jobDetail.status == 1
                    ? "uk-label uk-label-success"
                    : "uk-label uk-label-danger"
                : "";
        },
        pwaUrl() {
            if (process.env.NODE_ENV == "production") {
                return "https://app.myrobin.id/loker/";
            } else {
                return "https://app.myrobin.tech/loker/";
            }
        },
        autoAssignUrl() {
            if (process.env.NODE_ENV === "production") {
                return "https://app.myrobin.id/signup/";
            } else {
                return "https://app.myrobin.tech/signup/";
            }
        },
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.setJobDetailData();
            await this.getJobPreference(this.metaJobPreferenceList);
            await this.getAdminJobVacancy(this.metaAdminJobVacancyList);
            await Promise.all([
                this.setJobTypeData(),
                this.setEducationLevelData(),
                this.setTestCategoryData(),
                this.setContractOptionData(),
                this.getAdditionalDocOptionData(),
            ]);
            this.jobPreferenceOptions = this.job_preferences;
            this.adminJobVacancyOptions = this.admin_job_vacancies;
            this.employerProfile = await this.getProfile();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getJobDetails: 'initiation/getJobDetails',
            updateJob: 'initiation/updateJob',
            importUser: 'initiation/importUser',
            autoAssignMitraJob: 'initiation/autoAssignMitraJob',
            getApplicantList: 'applicant/getApplicantList',
            getJobTypes: 'option_data/getJobTypes',
            getEducationLevels: 'option_data/getEducationLevels',
            getTestCategories: 'option_data/getTestCategories',
            getAllContract: 'contract/getAllContract',
            getProfile: 'auth/getProfile',
            getAllShiftByOffice: 'option_data/getAllShiftByOffice',
            getJobPreference: 'job_preference/getJobPreference',
            getAdditionalDocument: 'initiation/getAdditionalDocument',
            getAdminJobVacancy: 'admin_job_vacancy/getAdminJobVacancy'
        }),
        clearFormJobVacancy() {
            this.formDataJobVacancy.shift_id = null;
            this.formDataJobVacancy.from = null;
            this.formDataJobVacancy.to = null;
            this.formDataJobVacancy.file = null;
        },
        hideFailedToUpload() {
            // this.clearFormJobVacancy();
            this.$modal.hide("failed-user-upload");
        },
        hideImportUser() {
            this.clearFormJobVacancy();
            this.$modal.hide("import-user");
        },
        async uploadFileMigration(e) {
            if (e.target.files) {
                this.formDataJobVacancy.file = e.target.files[0];
            }
        },
        triggerUpload() {
            this.$refs.upload.click();
        },
        async importUserJobVacancy() {
            let data = new FormData();
            data.append("shift_id", this.formDataJobVacancy.shift_id._id);
            data.append("from", this.formDataJobVacancy.from);
            data.append("to", this.formDataJobVacancy.to);
            data.append("file", this.formDataJobVacancy.file);
            const formData = {
                formData: data,
                job_id: this.$route.params.job_id,
            };
            this.btnImportMitra = true;
            const response = await this.importUser(formData);
            if (response && response.status === "OK") {
                notificationSuccess(`Success import ${response.data.success} mitra`);
                await this.setJobDetailData();
                this.btnImportMitra = false;
                this.$modal.hide("import-user");
                this.clearFormJobVacancy();
                if (response.data.failed > 0) {
                    this.failedUser = response.data.failedUser;
                    this.$modal.show("failed-user-upload");
                }
            } else {
                this.formDataJobVacancy.file = null;
                this.btnImportMitra = false;
            }
        },
        async importUserModal() {
            this.shifts = await this.getAllShiftByOffice(
                this.jobDetail.company_office
            );
            this.$modal.show("import-user");
        },
        downloadTemplateFailedUser() {
            try {
                const dataExcel = [];
                const objExcel = {
                    Company: this.jobDetail.company.name,
                    City: this.jobDetail.city,
                    Office: this.jobDetail.company_office.name,
                    "Job Vacancy Title": this.jobDetail.title,
                    Role: this.jobDetail.initiation
                        ? this.jobDetail.initiation.role_id.name
                        : "",
                    Manpower: this.jobDetail.initiation
                        ? this.jobDetail.initiation.manpower
                        : 0,
                    Fullfilment: this.jobDetail.initiation
                        ? this.jobDetail.initiation.fulfilled_manpower
                        : 0,
                    "Start Date": this.jobDetail.initiation.start_date || "",
                    "Due Date": this.jobDetail.initiation.due_date || "",
                };
                this.failedUser.forEach((item, i) => {
                    if (i < 1) {
                        // set first init header of excel data format with email and name
                        dataExcel.push({
                            ...objExcel,
                            "Full Name*": item.fullname,
                            "Email*": item.email,
                        });
                    } else {
                        // insert only email and name of excel data
                        dataExcel.push({
                            "Full Name*": item.fullname,
                            "Email*": item.email,
                        });
                    }
                });
                excelDownloader(
                    dataExcel,
                    `Failed_User_${dataExcel[0]["Job Vacancy Title"]}_${dataExcel[0]["Office"]}_${dataExcel[0]["City"]}_${dataExcel[0]["Company"]}.xls`
                );
            } catch (e) {
                notificationDangerCustom(e);
            }
        },
        downloadTemplateJobVacancy() {
            try {
                let dataExcel = [
                    {
                        Company: this.jobDetail.company.name,
                        City: this.jobDetail.city,
                        Office: this.jobDetail.company_office.name,
                        "Job Vacancy Title": this.jobDetail.title,
                        Role: this.jobDetail.initiation
                            ? this.jobDetail.initiation.role_id.name
                            : "",
                        Manpower: this.jobDetail.initiation
                            ? this.jobDetail.initiation.manpower
                            : 0,
                        Fullfilment: this.jobDetail.initiation
                            ? this.jobDetail.initiation.fulfilled_manpower
                            : 0,
                        "Start Date": this.jobDetail.initiation.start_date || "",
                        "Due Date": this.jobDetail.initiation.due_date || "",
                        "Full Name*": "",
                        "Email*": "",
                    },
                ];
                excelDownloader(
                    dataExcel,
                    `${dataExcel[0]["Job Vacancy Title"]}_${dataExcel[0]["Office"]}_${dataExcel[0]["City"]}_${dataExcel[0]["Company"]}.xls`
                );
            } catch (e) {
                notificationDangerCustom(e);
            }
        },
        async setJobDetailData() {
            try {
                this.jobDetail = await this.getJobDetails(this.$route.params.job_id);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setApplicantData({ jobId, limit }) {
            try {
                this.applicants = await this.getApplicantList({ job_id: jobId, limit });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setJobTypeData() {
            try {
                this.jobTypeOptions = await this.getJobTypes();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setEducationLevelData() {
            try {
                this.educationLevelOptions = await this.getEducationLevels();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setTestCategoryData() {
            try {
                this.testCategoryOptions = await this.getTestCategories();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setContractOptionData() {
            try {
                this.allContractOptions = await this.getAllContract();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async getAdditionalDocOptionData() {
            try {
                this.additionalDocOptions = await this.getAdditionalDocument();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setShiftOptionData({ company_office_id }) {
            try {
                this.shifts = await this.getAllShiftByOffice(company_office_id);
            } catch (error) {
                notificationDanger(error);
            }
        },
        editJobPost({ jobDetail }) {
            this.hideDropdown();
            this.fillFormData({ jobDetail });
            this.multiJobPost.isOpen = true;
            // check if required docs has KTP value
            const hasKTPValue = this.multiJobPost.formData.required_docs.some(obj => obj.label === 'KTP');
            if (!hasKTPValue) { // check if not have it will append default KTP value
                this.multiJobPost.formData.required_docs.push({ label: 'KTP', value: 'KTP', $isDisabled: true});
            }
            setTimeout(() => (window.UIkit.modal('.mainModal').show()), 300);
        },
        async editAutoAssign({ jobDetail }) {
            try {
                this.hideDropdown();
                this.resetFormAutoAssignMitra();
                await this.setShiftOptionData({
                    company_office_id: jobDetail.company_office_id,
                });
                this.fillFormAutoAssignMitra({ jobDetail });
                this.$modal.show("auto-assign-job-post-form-modal");
            } catch (error) {
                notificationDanger(error);
            }
        },
        async updateJobPost() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.buttonFormDataUpdateJobPostLoading = true;

                if (this.ageFilter.needed) {
                    this.multiJobPost.formData.age_group = `${this.ageFilter.min} - ${this.ageFilter.max}`;
                } else {
                    this.multiJobPost.formData.age_group = null;
                }

                if (this.multiJobPost.formData.working_hour_start?.length > 6) {
                    this.multiJobPost.formData.working_hour_start = moment(
                        this.multiJobPost.formData.working_hour_start
                    ).format("HH:mm");
                } else if (
                    this.multiJobPost.formData.working_hour_start == "Invalid date" ||
                    this.multiJobPost.formData.working_hour_start == ""
                ) {
                    this.multiJobPost.formData.working_hour_start = null;
                }
                if (this.multiJobPost.formData.working_hour_end?.length > 6) {
                    this.multiJobPost.formData.working_hour_end = moment(
                        this.multiJobPost.formData.working_hour_end
                    ).format("HH:mm");
                } else if (
                    this.multiJobPost.formData.working_hour_end == "Invalid date" ||
                    this.multiJobPost.formData.working_hour_end == ""
                ) {
                    this.multiJobPost.formData.working_hour_end = null;
                }

                const required_docs = this.multiJobPost.formData.required_docs.map((d) => d.value);
                const additional_docs = this.multiJobPost.formData.additional_docs.map((d) => d._id);
                const job_contracts = this.multiJobPost.formData.job_contracts.map(d => d._id);
                const required_tests = this.multiJobPost.formData.required_tests.map(d => ({
                    test_category_id: d._id,
                    minimum_score: d.minimum_score
                }));

                if (this.multiJobPost.formData.benefits === null || this.multiJobPost.formData.benefits === "") {
                    this.multiJobPost.formData.benefits = null;
                }

                const job_preference_id = this.multiJobPost.formData.job_preference_id._id;
                if (this.multiJobPost.formData.admin_job_vacancy_id !== null) {
                    this.multiJobPost.formData.admin_job_vacancy_id = this.multiJobPost.formData.admin_job_vacancy_id._id;
                }

                delete this.multiJobPost.formData._id;

                const response = await this.updateJob({ ...this.multiJobPost.formData, required_docs, job_contracts, required_tests, additional_docs, job_preference_id});
                if (response && response.status === "OK") {
                    notificationSuccess("Updated!");
                    await this.setJobDetailData();
                    this.resetFormData();
                } else {
                    notificationDanger("Failed to update job.");
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonFormDataUpdateJobPostLoading = false;
            }
        },
        async updateAutoAssign() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.buttonAutoAssignMitraUpdateLoading = true;

                if (!this.formAutoAssignMitra.shift_id) {
                    notificationDangerCustom("Shift Cannot Empty");
                    return;
                }
                if (!this.formAutoAssignMitra.auto_assign_contract.start_date) {
                    notificationDangerCustom("Start Date Cannot Empty");
                    return;
                }
                if (!this.formAutoAssignMitra.auto_assign_contract.end_date) {
                    notificationDangerCustom("End Date Cannot Empty");
                    return;
                }
                if (!this.formAutoAssignMitra.auto_assign_datetime_end) {
                    notificationDangerCustom("End of Auto Assign Cannot Empty");
                    return;
                }
                if (this.formAutoAssignMitra.shift_id != null) {
                    this.formAutoAssignMitra.shift_id =
            this.formAutoAssignMitra.shift_id._id;
                }

                const response = await this.autoAssignMitraJob(
                    this.formAutoAssignMitra
                );
                if (response && response.status === "OK") {
                    notificationSuccess("Updated!");
                    await this.setJobDetailData();
                    this.$modal.hide("auto-assign-job-post-form-modal");
                    this.resetFormAutoAssignMitra();
                } else {
                    notificationDanger("Failed to save job!");
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonAutoAssignMitraUpdateLoading = false;
            }
        },
        fillFormData({ jobDetail }) {
            this.multiJobPost.formData.id = jobDetail._id;
            this.multiJobPost.formData.job_preference_id = this.jobPreferenceOptions
                .find(e => e._id === jobDetail.job_preference_id) || {};
            this.multiJobPost.formData.admin_job_vacancy_id = this.adminJobVacancyOptions
                .find(e => e._id === jobDetail.admin_job_vacancy_id) || {};
            this.multiJobPost.formData.title = jobDetail.title;
            this.multiJobPost.formData.job_description = jobDetail.job_description;
            this.multiJobPost.formData.salary = jobDetail.salary;
            this.multiJobPost.formData.salary_overtime_hourly = jobDetail.salary_overtime_hourly;
            this.multiJobPost.formData.salary_unit = this.feeUnitFormater({ text: jobDetail.salary_unit });
            this.multiJobPost.formData.job_type_id = jobDetail.job_type._id;
            this.multiJobPost.formData.benefits = jobDetail.benefits;
            this.multiJobPost.formData.job_start = jobDetail.job_start;
            if (jobDetail.working_hour_start !== null)
                this.multiJobPost.formData.working_hour_start = jobDetail.working_hour_start;
            if (jobDetail.working_hour_end !== null)
                this.multiJobPost.formData.working_hour_end = jobDetail.working_hour_end;
            this.multiJobPost.formData.gender = jobDetail.gender;
            this.multiJobPost.formData.education_level_id = jobDetail.education_level._id;
            this.multiJobPost.formData.is_experience_needed = jobDetail.is_experience_needed;
            this.multiJobPost.formData.skill_needed = jobDetail.skill_needed;
            this.multiJobPost.formData.additional_questions = jobDetail.additional_questions || [];

            if (jobDetail.question_usage && Object.keys(jobDetail.question_usage).length > 0) {
                this.multiJobPost.formData.question_usage = jobDetail.question_usage;
            }
            if (jobDetail.age_group) {
                const item_age = jobDetail.age_group.split("-");
                this.ageFilter.needed = true;
                this.ageFilter.min = parseInt(item_age[0]);
                this.ageFilter.max = parseInt(item_age[1]);
            }
            if (jobDetail.required_docs && jobDetail.required_docs.length > 0) {
                jobDetail.required_docs.forEach((element) => {
                    this.multiJobPost.formData.required_docs.push(
                        this.docOptions.find((e) => e.value == element)
                    );
                });
            }
            if (jobDetail.additional_docs && jobDetail.additional_docs.length > 0) {
                jobDetail.additional_docs.forEach((element) => {
                    this.multiJobPost.formData.additional_docs.push(
                        this.additionalDocOptions.find((e) => e._id == element._id)
                    );
                });
            }
            if (jobDetail.required_tests && jobDetail.required_tests.length > 0) {
                jobDetail.required_tests.forEach((element) => {
                    this.multiJobPost.formData.required_tests.push({
                        _id: element.test_category_id._id,
                        name: element.test_category_id.name,
                        minimum_score: element.minimum_score,
                    });
                });
            }
            if (jobDetail.job_contracts && jobDetail.job_contracts.length > 0) {
                jobDetail.job_contracts.forEach((element) => {
                    this.multiJobPost.formData.job_contracts.push(
                        this.allContractOptions.find((e) => e._id == element._id)
                    );
                });
            }
        },
        resetFormData() {
            window.UIkit.modal(".mainModal").$destroy(true);
            this.requiredTests = [];
            this.multiJobPost = {
                isOpen: false,
                activeTab: 0,
                postIsValid: false,
                questionisValid: true,
                isLoading: false,
                formData: {
                    id: null,
                    title: '',
                    job_preference_id: '',
                    admin_job_vacancy_id: null,
                    job_description: '',
                    salary: '',
                    salary_overtime_hourly: '',
                    salary_unit: '',
                    job_type_id: '',
                    benefits: null,
                    job_start: null,
                    working_hour_start: '',
                    working_hour_end: '',
                    gender: null,
                    age_group: null,
                    education_level_id: '',
                    skill_needed: '',
                    is_experience_needed: false,
                    required_tests: [],
                    required_docs: [],
                    additional_docs: [],
                    job_contracts: [],
                    additional_questions: [],
                    question_usage: {
                        default: true,
                        auto_assign: false,
                        auto_assign_unreg: false,
                        migration: false,
                    },
                },
            };
        },
        fillFormAutoAssignMitra({ jobDetail }) {
            this.formAutoAssignMitra = {
                shift_id: jobDetail.shift,
                job_id: jobDetail._id,
                auto_assign_datetime_end: jobDetail.auto_assign_datetime_end,
                auto_assign_contract: jobDetail.auto_assign_contract ?? {
                    start_date: null,
                    end_date: null,
                },
            };
        },
        resetFormAutoAssignMitra() {
            this.formAutoAssignMitra = {
                shift_id: null,
                job_id: null,
                auto_assign_datetime_end: null,
                auto_assign_contract: {
                    start_date: null,
                    end_date: null,
                },
            };
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown(".uk-dropdown", {
                delayHide: 0,
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
        goBack() {
            this.$router.back();
        },
        onCopy(e) {
            notificationSuccess("Copied to clipboard: " + e.text);
        },
        formatAge({ data }) {
            if (data === null || data === undefined) return "-";
            else return formatter.ageInYear(data);
        },
        formatDate({ date }) {
            if (date === null || date === undefined) return "-";
            else return formatter.dateComplete(date);
        },
        formatCurrency({ data }) {
            if (data === null || data === undefined) return "-";
            else return formatter.thousandSeparator(data);
        },
        feeUnitFormater({ text }) {
            switch (text) {
            case "Month":
                return "Bulan";
            case "Day":
                return "Hari";
            case "Hour":
                return "Jam";
            default:
                return text;
            }
        },
        genderTranslater({ data }) {
            switch (data) {
            case "male":
                return "Pria";
            case "female":
                return "Wanita";
            default:
                return "Pria / Wanita";
            }
        },
    },
};
</script>

<style scoped>
.modal-margin {
  margin: 1rem;
}

.text-detail {
  font-size: 19px;
  font-weight: 400;
}

.job-title {
  font-size: 26px;
  font-weight: 700;
}

thead {
  background: #0abab5;
}

th {
  color: #ffffff;
}

td {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
</style>
