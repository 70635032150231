import Vue from 'vue';
import api from '@/utils/api';
import { SALARY } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    recaps: {docs:[]},
};

const mutations = {
    "SET_RECAP": (state, payload) => {
        Vue.set(state, 'recaps', payload);
    }
};

const actions = {
    async getRecaps(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY.GET_SALARIES, data);
            context.commit('SET_RECAP', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSalaryDetail(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY.DETAIL_SALARY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSalaryReports(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY.GET_SALARY_REPORTS, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSalaryReportList(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY.GET_SALARY_REPORT_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSalaryReportDetail(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY.DETAIL_SALARY_REPORT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postEditFee(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(SALARY.EDIT_FEE(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateStatus(context, data) {
        try {
            const response = await api.apiPutAuth(SALARY.UPDATE_STATUS, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }, 
    async checkRecaps(context, data) {
        try {
            const response = await api.apiPostAuth(SALARY.CHECK_RECAPS, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postCreateBatch(context, data) {
        try {
            const response = await api.apiPostAuth(SALARY.CREATE_BATCH, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async exportSalaryDetail(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY.EXPORT(data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postAddReference(context, data) {
        try {
            const response = await api.apiPostAuth(SALARY.ADD_REFERENCE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRecapDetail(context, data) {
        try {
            const response = await api.apiGetAuth(SALARY.RECAP_DETAIL(data));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postGenerateSalary(context, data) {
        try {
            const response = await api.apiPostAuth(SALARY.GENERATE_SALARY, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postTransferSalary(context, data) {
        try {
            const response = await api.apiPostAuth(SALARY.TRANSFER_SALARY, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    recaps: state => {
        return state.recaps;
    }
};

export default {
    namespaced,
    actions,
    mutations,
    state,
    getters
};
