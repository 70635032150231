<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-card uk-card-default uk-card-body uk-width-1-2@m uk-margin-auto">
            <h3 class="uk-card-title">Report Filter</h3>
            <hr>
            <div class="uk-form-controls uk-width-auto">
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-select">Office</label>
                    <div class="uk-form-controls">
                        <select
                            name="Office City"
                            class="uk-select"
                            id="form-stacked-select"
                            v-model="meta.company_office_id"
                        >
                            <option :value="null" disabled selected>Choose Office</option>
                            <option value="all">All Office</option>
                            <option v-for="(office, index) in officeOptions" :key="index" :value="office">{{office.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary" type="button" @click="downloadReport">Download</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { excelDownloader } from '@/utils/helper';

export default {
    data() {
        return {
            meta: {
                company_office_id: null
            },
            isFetching: false,
            isLoading: false,
            officeOptions: [],
            company: {
                offices: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
        })
    },
    async mounted() {
        await this.getCompanyOffice();
        this.sortOfficeOptions();
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getInitJobReport: 'report/getInitJobReport',
        }),
        sortOfficeOptions() {
            for (const office of this.company_offices) {
                const findOffice = this.officeOptions.find((data) => data.name.toUpperCase() === office.name.toUpperCase());
                if(!findOffice) {
                    this.officeOptions.push(office);
                }
            }
            this.officeOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
        },
        async downloadReport() {
            this.isLoading = true;
            const param = {};
            if (this.meta.company_office_id && this.meta.company_office_id !== 'all') {
                param.company_office_id = this.meta.company_office_id._id;
            }
            const all_recaps = await this.getInitJobReport(param);
            excelDownloader(all_recaps, this.generateFileName());
            this.isLoading = false;
        },
        generateFileName() {
            let suffixName = "";
            const coId = this.meta.company_office_id != null ? this.meta.company_office_id.name : '';
            if (!coId){
                suffixName = ``;
            } else {
                suffixName = ` @ ${coId}`;
            }
            let filename = `Initiation Report${suffixName}.xls`;
            return filename;
        },
    },
};
</script>
