<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-grid-column-small uk-grid-row-large uk-child-width-1-2@m" uk-grid v-if="$can('READ', 'worker-salary-report')">
            <div>
                <div v-if="isShow">
                    <div class="location-header" style="cursor:pointer" @click="showModal('filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20">
                        {{ moment(meta.date).format('MMMM YYYY') }}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer" @click="showModal('filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20"> Select filter {{ meta.date }}
                    </div>
                </div>
                <button v-if="isShow" class="reset-filter" @click="resetFilter">Reset Location Filter</button>
            </div>
        </div>
        <div v-if="isShow" class="uk-flex uk-flex-between uk-margin-remove-top" uk-grid>
            <div uk-grid>
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search report by name">
                </div>
            </div>
        </div>

        <!-- Main Table -->
        <div v-if="isShow && $can('READ', 'worker-salary-report')" class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand">Salary Report Name</th>
                            <th class="uk-text-center">Created At</th>
                            <th class="uk-text-center">Date Range</th>
                            <th class="uk-text-center">Number of<br>Workers</th>
                            <th class="uk-text-center">Total<br>Income</th>
                            <th class="uk-text-center">Total<br>Expense</th>
                            <th class="uk-text-center">Generated By</th>
                        </tr>
                    </thead>
                    <tbody v-if="data.docs.length>0">
                        <tr v-for="(report, i) in data.docs" :key="i">
                            <td class="uk-text-left uk-text-justify uk-text-middle">
                                <a style="text-decoration: none;" @click="list({salary_batch_id: report._id})" href="javascript:void(0)">{{report.name || '-'}}</a>
                            </td>
                            <td class="uk-text-center uk-text-middle">{{ moment(report.created_at).format('DD MMM YYYY') }}</td>
                            <td class="uk-text-center uk-text-middle">{{ moment(report.start_date).format('DD MMM YYYY') }} - {{ moment(report.end_date).format('DD MMM YYYY') }}</td>
                            <td class="uk-text-center uk-text-middle">{{ report.count_mitra }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(report.total_income)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ formatCurrency(parseInt(report.total_expense)) }}</td>
                            <td class="uk-text-center uk-text-middle">{{ report.updated_by.fullname }}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End of Main Table -->

        <!-- Modal box filter data -->
        <div id="filter" class="filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Select filter for Salary Report</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">For (Month)<span class="uk-text-danger">*</span></label>
                        <div class="uk-form-controls" @click="hideYearMonth">
                            <datetime
                                    id="date"
                                    name="date"
                                    input-class="uk-input"
                                    placeholder="MMMM, yyyy"
                                    type='month'
                                    :flow="['year','month']"
                                    format="MMMM, yyyy"
                                    v-model="meta.date"
                                    :min-datetime="new Date(2022, 0, 1).toISOString()"
                                    :max-datetime="moment().toISOString()"
                                    value-zone="Asia/Jakarta"
                                    zone="Asia/Jakarta"
                                    title="For (Month)"
                            ></datetime>
                        </div>
                    </div>
                    <!-- <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select-1">City</label>
                        <div class="uk-form-controls">
                            <select
                                    name="Office City"
                                    class="uk-select"
                                    id="form-stacked-select-1"
                                    v-model="selected_city"
                                    @change="filterOffice"
                            >
                                <option value="all" selected>All Cities</option>
                                <option v-for="(city, index) in cities" :key="index" :value="city">{{city}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin" v-if="selected_city != 'all'">
                        <label class="uk-form-label" for="form-stacked-select-2">Office</label>
                        <div class="uk-form-controls">
                            <select
                                    name="Office Property"
                                    class="uk-select"
                                    id="form-stacked-select-2"
                                    v-model="company_office"
                            >
                                <option value="all" selected>All Offices</option>
                                <option v-for="(office, index) in filtered_offices" :key="index" :value="office">{{office.name}}</option>
                            </select>
                        </div>
                    </div> -->
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="applyFilter">Apply</button>
                </div>
            </div>
        </div>
        <!-- End modal box filter data -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import { Datetime } from 'vue-datetime';
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';
import moment from 'moment';
moment.locale('id');

export default {
    data({ $route }) {
        return {
            moment: moment,
            isShow: false,
            meta: {
                limit: $route.query.limit ? $route.query.limit : 10,
                page: $route.query.page ? $route.query.page : 1,
                date: $route.query.date ? $route.query.date : null,
                name: $route.query.name ? $route.query.name : '',
                sort: $route.query.sort ? $route.query.sort : '-1'
            },
            data: {
                docs: [],
                totalDocs: 0
            },
            images: PREFIX_IMAGE,
            selected_data: null
        };
    },
    components: {
        EmptyTable,
        Pagination,
        Datetime
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
    },
    watch: {
        // async meta() {
        //     this.data = await this.getSalaryReports(this.meta);
        // },
        meta: {
            async handler(){
                if (this.meta.date) {
                    this.isShow = false;
                    this.data = await this.getSalaryReports(this.meta);
                    this.isShow = true;
                }
            },
            deep: true
        }
    },
    beforeMount() {
        if(window.UIkit.modal('.filter')) window.UIkit.modal('.filter').$destroy(true);
        if(window.UIkit.modal('.transfer')) window.UIkit.modal('.transfer').$destroy(true);
    },
    methods: {
        ...mapActions({
            getSalaryReports: 'salary/getSalaryReports',
            postTransferSalary: 'salary/postTransferSalary',
        }),
        list(data) {
            this.$router.push({ path: `report/list`, query: data });
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'filter':
                await window.UIkit.modal('#filter').hide();
                break;
            case 'generate':
                this.selected_data = null;
                await window.UIkit.modal('#generate').hide();
                break;
            case 'transfer':
                this.selected_data = null;
                await window.UIkit.modal('#transfer').hide();
                break;
            }
        },
        async showModal(modal_name, data=null) {
            switch(modal_name) {
            case 'filter':
                await window.UIkit.modal('#filter').show();
                break;
            case 'generate':
                this.selected_data = data;
                await window.UIkit.modal('#generate').show();
                break;
            case 'transfer':
                this.selected_data = data;
                await window.UIkit.modal('#transfer').show();
                break;
            }
        },
        async applyFilter() {
            if (!this.meta.date) {
                return notificationDangerCustom('"For (Month)" must be filled');
            } else {
                this.meta.date = moment(this.meta.date).toISOString();
                // this.data = await this.getSalaryReports(this.meta);
                await this.hideModal('filter');
                this.$router.push({ path: `report`, query: this.meta });
                // this.isShow = true;
            }
        },
        resetFilter() {
            this.meta.date = null;
            this.isShow = false;
        },
        changeMeta(e, prop) {
            if(prop == 'name') {
                e.target.value = formatter.sanitizeString(e.target.value);
            }
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        hideYearMonth() {
            const dateHide = document.getElementsByClassName("vdatetime-popup__date");
            dateHide[0].style.display = 'none';
            const yearHide = document.getElementsByClassName("vdatetime-popup__year");
            yearHide[0].style.display = 'none';
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        async transferSalary(data) {
            if (!data.reference) {
                return notificationDangerCustom('"Reference Number" must be filled');
            }
            const response = await this.postTransferSalary(data);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('transfer');
                await this.getRecaps(this.meta);
            } else {
                notificationDangerCustom('Failed to transfer salary!');
            }
        }
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.report-download{
    font-family: 'Roboto';
    color: #5FD855;
    font-size: 20px;
}
</style>
