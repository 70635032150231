<template>
  <section>
    <div id="modal-center" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
        <div class="uk-modal-header uk-text-center">
          <h2 class="uk-modal-title">{{ title }}</h2>
        </div>
        <div class="uk-modal-body">
          <img :src="url" alt="lampiran" height="350" style="margin: auto; display: block">
        </div>
        <div class="uk-modal-footer uk-text-center">
          <section>
            <button
              class="uk-button uk-button-primary"
              type="button"
              style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff; margin: 0 1rem;"
              @click="download(url)"
            >
              Download
            </button>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: 'Lampiran'
        }
    },
    mounted() {
        window.UIkit.modal('#modal-center').show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#modal-center').$destroy(true);
            this.$emit("hideModal");
        },
        download(img) {
            window.location = img;
        },
    }
};
</script>
