<template>
    <div>
        <GoldValidationEdit
            v-if="isValidating"
            :data="validationData"
            @cancel-validation="cancelValidation"
            @save-validation="saveValidation"
        />
        <GoldValidationView
            v-else
            :data="data"
            :table-loading="tableLoading"
            :validated="validated"
            @validate="$emit('validate', { type: 'gold' })"
            @unlabel="$emit('unlabel', { type: 'gold' })"
            @read-validation-notes="$emit('read-validation-notes', { type: 'gold' })"
            @is-validating="enterValidationMode"
        />
    </div>
</template>

<script>
import GoldValidationView from "@/components/globals/kyc/view/GoldValidation";
import GoldValidationEdit from "@/components/globals/kyc/edit/GoldValidation";

export default {
    name: 'GoldValidationTable',
    components: {
        GoldValidationView,
        GoldValidationEdit,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        validated: {
            type: Object,
            required: false,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            isValidating: false,
            validationData: {},
        };
    },
    methods: {
        enterValidationMode() {
            this.validationData = {...this.data};
            this.isValidating = true;
        },
        cancelValidation() {
            this.isValidating = false;
        },
        saveValidation() {
            this.isValidating = false;
            this.$emit('re-fetch');
        },
    }
};
</script>

<style scoped></style>
