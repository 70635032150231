<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-grid-column-small uk-grid-row-large" uk-grid v-if="$can('READ', 'worker-salary')">
            <div v-if="getRole() !== 'supervisor'" class="uk-width-expand">
                <div v-if="isShow">
                    <div uk-grid>
                        <div class="uk-width-auto uk-margin-auto uk-margin-auto-vertical">
                            <img :src="worker ? worker.user_id.photo_url ? worker.user_id.photo_url.file_url : `${images}/avatar.svg` : `${images}/avatar.svg`" class="photo">
                        </div>
                        <div class="uk-width-expand uk-margin-auto uk-margin-auto-vertical">
                            <div class="applicant_name"><h4><span class="uk-text-bold">{{ worker ? worker.user_id.fullname : 'Nihil' }}</span></h4></div>
                            <div class="uk-flex uk-flex-row uk-flex-left">
                                <div>
                                    <span>Job Role: {{ worker ? worker.role_id.name : 'Nihil' }}</span><br>
                                    <span>Company: {{ worker ? worker.company_id.name : 'Nihil' }}</span><br>
                                    <span>Office: {{ worker ? worker.company_office_id.name : 'Nihil' }}</span><br>
                                </div>
                                <div class="uk-margin-xlarge-left">
                                    <span :class="{'uk-text-danger uk-text-bold': bankColor}">Account Holder: {{ bank_info ? bank_info.bank_account_name : 'Nihil' }}</span><br>
                                    <span :class="{'uk-text-danger uk-text-bold': bankColor}">Bank: {{ bank_info ? bank_info.bank_name : 'Nihil' }}</span><br>
                                    <span :class="{'uk-text-danger uk-text-bold': bankColor}">Account Number: {{ bank_info ? bank_info.bank_account_number : 'Nihil' }}</span><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 v-if="isShow" class="title">Payment history of <span class="uk-text-bold">{{ worker ? worker.user_id.fullname : 'Nihil' }}</span></h4>
            </div>
            <div>
                <div class="uk-align-right" style="overflow:auto">
                    <div
                        class="report-download"
                        style="cursor:pointer;float:right"
                        @click="$router.back()"
                        >
                        <img :src="`${images}/cross.svg`" alt="" width="25">
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-flex uk-flex-between uk-margin-remove-top" uk-grid>
            <div uk-grid>
                <div class="uk-inline" v-show="isShow">
                    <div class="location-header">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20">
                        {{ meta.fee_normal_unit == 'Month' ? moment(meta.end_date).format('MMMM YYYY')
                        : `${moment(meta.start_date).format('DD MMMM YYYY')} - ${moment(meta.end_date).format('DD MMMM YYYY')}`}},
                        {{ city ? city : 'All Cities' }},
                        {{ office ? office : 'All Office' }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$can('READ', 'worker-salary')">
            <ul uk-tab>
                <li class="uk-active"><a href="#" class="uk-button">PAYMENT</a></li>
                <li><a href="#" class="uk-button">ADJUSTMENTS</a></li>
            </ul>

            <div class="uk-switcher">
                <!-- Tab 1 -->
                <div>
                    <div class="uk-grid uk-margin">
                        <div class="uk-width-2-3" v-show="isShow"
                        v-if="$can('READ', 'worker-salary')">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-medium uk-table-striped">
                                    <caption class="table-caption">Income</caption>
                                    <thead>
                                        <tr>
                                            <th class="uk-text-left uk-text-middle">Type</th>
                                            <th class="uk-text-center uk-text-middle">Date</th>
                                            <th class="uk-text-center uk-text-middle">Total Working<br>Hours</th>
                                            <th class="uk-text-center uk-text-middle">Total Overtime<br>Hours</th>
                                            <th class="uk-text-center uk-text-middle">Fee</th>
                                            <th class="uk-text-center uk-text-middle">Overtime</th>
                                            <th
                                                v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-operation','admin-client'])"
                                                class="uk-text-center uk-text-middle">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="data && data.recaps.length>0">
                                        <tr v-for="(recap, i) in data.recaps" :key="i+'x'">
                                            <td class="uk-text-left uk-text-middle">Attendance</td>
                                            <td class="uk-text-center uk-text-middle">{{ formatDate(recap.date) }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ recap.normal_work_hour }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ recap.overtime_work_hour_spv }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ formatCurrency( recap.fee_normal_total? recap.fee_normal_total : recap.fee_total - recap.fee_overtime) }}</td>
                                            <td class="uk-text-center uk-text-middle">{{ formatCurrency( recap.fee_overtime_total ? recap.fee_overtime_total : recap.fee_overtime) }}</td>
                                            <td
                                                v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-operation','admin-client'])"
                                                class="uk-text-center uk-text-middle">
                                                <button v-if="!recap.salary_id" class="uk-button uk-button-primary uk-border-rounded" @click="showModal('edit-fee', recap)">Edit</button>
                                                <button v-else class="uk-button uk-button-default uk-border-rounded" disabled>Edit</button>
                                            </td>
                                        </tr>
                                        <tr v-if="data && data.recaps.length>0">
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-right td-bold border-top">Total</td>
                                            <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_normal_fee) }}</td>
                                            <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_overtime_fee) }}</td>
                                            <td class="uk-text-center border-top"></td>
                                        </tr>
                                        <template v-if="data && data.additions && data.additions.length>0" >
                                            <tr v-for="(addition, y) in data.additions" :key="y+'y'">
                                                <td class="uk-text-left uk-text-middle">{{ addition.name }}</td>
                                                <td class="uk-text-center uk-text-middle">{{ formatDate(addition.decision_date) }}</td>
                                                <td class="uk-text-center"></td>
                                                <td class="uk-text-center"></td>
                                                <td class="uk-text-center uk-text-middle">{{ formatCurrency( addition.decision_amount) }}</td>
                                                <td class="uk-text-center"></td>
                                            </tr>
                                            <tr>
                                                <td class="uk-text-center border-top"></td>
                                                <td class="uk-text-center border-top"></td>
                                                <td class="uk-text-center border-top"></td>
                                                <td class="uk-text-right td-bold border-top">Total</td>
                                                <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_income) }}</td>
                                                <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_overtime_fee) }}</td>
                                                <td class="uk-text-center border-top"></td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <empty-table v-else :colspan="7"/>
                                </table>
                            </div>
                        </div>
                        <div class="uk-width-1-3" v-show="isShow"
                        v-if="$can('READ', 'worker-salary')">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-medium uk-table-striped">
                                    <caption class="table-caption">Expense</caption>
                                    <thead>
                                        <tr>
                                            <th class="uk-text-center">Name</th>
                                            <th class="uk-text-center">Approval Date</th>
                                            <th class="uk-text-center">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="data && data.expenses && data.expenses.length > 0">
                                        <tr v-for="(expense, q) in data.expenses" :key="q">
                                            <td class="uk-text-center uk-text-truncate uk-width-1-2">{{ expense.name == 'Cash Advance' ? `Cash Advance - id ${expense._id}` : expense.name }}</td>
                                            <td class="uk-text-center uk-width-auto">{{ formatDate(expense.decision_date) }}</td>
                                            <td class="uk-text-center">{{ formatCurrency(expense.decision_amount) }}</td>
                                        </tr>
                                        <tr v-if="data && data.expenses && data.expenses.length > 0">
                                            <td class="uk-text-center border-top"></td>
                                            <td class="uk-text-right td-bold border-top">Total</td>
                                            <td class="uk-text-center td-bold border-top">{{ formatCurrency(data.total_expense) }}</td>
                                        </tr>
                                    </tbody>
                                    <empty-table v-else :colspan="3"/>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="uk-margin payment uk-padding-small uk-flex uk-flex-between">
                        <div class="uk-width-auto uk-margin-auto-vertical">Total Payment</div>
                        <div class="uk-width-auto uk-margin-auto-vertical uk-text-bold">Rp {{formatCurrency(data ? data.total_payment : 0)}}</div>
                    </div>
                </div>
                <!-- Tab 1 -->
                <!-- Tab 2 -->
                <div class="uk-margin">
                    <div v-if="$can('WRITE', 'worker-salary')" class="uk-flex uk-flex-right">
                        <div>
                            <button class="uk-button uk-button-primary uk-border-rounded" @click="showModal('adjustment', null)">Create</button>
                        </div>
                    </div>
                    <div v-show="isShow" v-if="$can('READ', 'worker-salary')">
                        <div class="uk-overflow-auto">
                            <table class="uk-table uk-table-medium uk-table-striped">
                                <thead>
                                    <tr>
                                        <th class="uk-text-left uk-text-middle">Type</th>
                                        <th class="uk-text-center uk-text-middle">Name</th>
                                        <th class="uk-text-center uk-text-middle">Date</th>
                                        <th class="uk-text-center uk-text-middle">Amount</th>
                                        <th class="uk-text-center uk-text-middle">Updated By</th>
                                        <th class="uk-text-center uk-text-middle">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="dataAdjustment && dataAdjustment.length>0">
                                    <tr v-for="(item, x) in dataAdjustment" :key="x+'a'">
                                        <td class="uk-text-left uk-text-middle">{{ item.type == 1 ? 'Expenses' : 'Income' }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ item.name }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ formatDate(item.decision_date) }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ formatCurrency(item.decision_amount) }}</td>
                                        <td class="uk-text-center uk-text-middle">{{ item.decision_by }}</td>
                                        <td v-if="$can('EDIT', 'worker-salary') || $can('DELETE', 'worker-salary')" class="uk-text-center uk-text-middle">
                                            <button
                                                v-if="$can('EDIT', 'worker-salary')"
                                                class="uk-button uk-button-primary uk-border-rounded"
                                                @click="showModal('adjustment', item)"
                                            >Edit</button>
                                            <button
                                                v-if="$can('DELETE', 'worker-salary')"
                                                class="uk-button uk-button-danger uk-border-rounded uk-margin-small-left" 
                                                @click="showModal('confirmation', item)"
                                            >Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="7"/>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Tab 2 -->
            </div>
        </div>

        <!-- Modal box edit fee -->
        <div id="edit-fee" class="edit-fee" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog" v-if="selected_data">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('transfer')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Edit Income</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <br>
                        Work Date: {{formatDate(selected_data.date)}}<br>
                        Total Working Hours: {{selected_data.normal_work_hour}}<br>
                        Total Overtime Hours: {{selected_data.overtime_work_hour_spv}}<br><br>
                    </div>
                    <div class="uk-margin">
                        <form class="uk-form-stacked">
                            <div class="uk-margin">
                                <label class="uk-form-label" for="normal_fee">Fee</label>
                                <div class="uk-form-controls">
                                    <input class="uk-input" id="normal_fee" type="number" v-model="selected_data.fee_normal_total">
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label" for="overtime_fee">Overtime</label>
                                <div class="uk-form-controls">
                                    <input class="uk-input" id="overtime_fee" type="number" v-model="selected_data.fee_overtime_total">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="editFee({
                        _id: selected_data._id,
                        fee_normal_total: selected_data.fee_normal_total,
                        fee_overtime_total: selected_data.fee_overtime_total
                        })">Save</button>
                </div>
            </div>
        </div>
        <!-- End modal box edit fee -->

        <!-- Modal box create adjustment -->
        <div id="adjustment" class="adjustment" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('adjustment')"></button>
                <div class="uk-modal-header">
                    <h2 v-if="createAdjustment" class="uk-modal-title uk-text-center">Crete new entry</h2>
                    <h2 v-else class="uk-modal-title uk-text-center">Edit this entry</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <form class="uk-form-stacked">
                            <div class="uk-margin">
                                <label class="uk-form-label" for="type">Type<span class="uk-text-danger">*</span></label>
                                <div class="uk-margin">
                                    <select class="uk-select" id="type" v-model="selectedAdjustment.type">
                                        <option :value="1">Expenses</option>
                                        <option :value="2">Income</option>
                                    </select>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label" for="name">Name<span class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input class="uk-input" id="name" type="text" v-model="selectedAdjustment.name">
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label" for="form-stacked-text">Date<span class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <datetime
                                        id="date"
                                        name="date"
                                        input-class="uk-input"
                                        type='date'
                                        format="dd MMMM yyyy"
                                        v-model="selectedAdjustment.decision_date"
                                        :min-datetime="meta.start_date ? meta.start_date : moment(meta.date).startOf('month')"
                                        :max-datetime="moment().toISOString()"
                                    ></datetime>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label" for="amount">Amount<span class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input class="uk-input" id="amount" type="number" v-model="selectedAdjustment.decision_amount">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="createAdjustment ? postAdjustment({
                        ...selectedAdjustment,
                        job_partner_id: data.recaps.at(-1).job_partner_id._id
                        }) : editAdjustment(selectedAdjustment) ">Save</button>
                </div>
            </div>
        </div>
        <!-- Modal box create adjustment -->

        <!-- Modal box Confirmation -->
        <div id="confirmation" class="confirmation" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Confirmation</h2>
                </div>
                <div class="uk-modal-body">
                    Are you sure want to proceed?
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-border-rounded" @click="hideModal('confirmation')">No</button>
                    <button class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" type="button" @click="deleteAdjustment()">Yes</button>
                </div>
            </div>
        </div>
        <!-- End modal box Confirmation -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserLogin } from '@/utils/auth';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { Datetime } from 'vue-datetime';
import moment from 'moment';
moment.locale('id');
import {
    notificationSuccess,
    notificationDangerCustom
} from '@/utils/notification';

export default {
    data({ $route }) {
        return {
            markers: [],
            showInfo: false,
            moment: moment,
            city: $route.query.city,
            office: $route.query.office,
            worker: null,
            isShow: true,
            meta: $route.query,
            images: PREFIX_IMAGE,
            company_office: { name: $route.query.property_name, city: $route.query.property_city},
            showResult: false,
            data: null,
            bank_info: null,
            selected_data: null,
            dataAdjustment: null,
            createAdjustment: true,
            selectedAdjustment: {
                type: null,
                name: null,
                decision_date: null,
                decision_amount: null
            },
        };
    },
    components: {
        EmptyTable,
        Datetime
    },
    computed : {
        user_cred() {
            return getUserLogin();
        },
        bankColor() {
            if (this.bank_info && this.bank_info._id) {
                return false;
            } else {
                return true;
            }
        }
    },
    beforeMount() {
        if(window.UIkit.modal('.edit-fee')) window.UIkit.modal('.edit-fee').$destroy(true);
        if(window.UIkit.modal('.adjustment')) window.UIkit.modal('.adjustment').$destroy(true);
        if(window.UIkit.modal('.confirmation')) window.UIkit.modal('.confirmation').$destroy(true);
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        ...mapActions({
            getSalaryDetail: 'salary/getSalaryDetail',
            getSalaryAdjustments: 'salary_adjustment/getSalaryAdjustments',
            createSalaryAdjustment: 'salary_adjustment/createSalaryAdjustment',
            editSalaryAdjustment: 'salary_adjustment/editSalaryAdjustment',
            deleteSalaryAdjustment: 'salary_adjustment/deleteSalaryAdjustment',
            postEditFee: 'salary/postEditFee',
        }),
        async getData() {
            this.data = await this.getSalaryDetail({...this.meta, end_date: moment.utc(this.meta.end_date).add(1, 'days').endOf('day').toISOString()});
            if (this.data && this.data.recaps.length > 0) {
                this.worker = this.data.recaps.at(-1).job_partner_id;
                this.worker.company_office_id = this.data.recaps.at(-1).company_office_id;
                this.bank_info = this.data.user_bank;
            }
            this.dataAdjustment = await this.getSalaryAdjustments(this.data.recaps.at(-1).job_partner_id._id);
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatHourMinute(data) {
            if (data == null) {
                return '-';
            }
            return formatter.hourMinuteOnly(data);
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'edit-fee':
                this.selected_data = null;
                await window.UIkit.modal('#edit-fee').hide();
                break;
            case 'adjustment':
                this.selectedAdjustment = {
                    type: null,
                    name: null,
                    decision_date: null,
                    decision_amount: null
                };
                await window.UIkit.modal('#adjustment').hide();
                break;
            case 'confirmation':
                this.selectedAdjustment = {
                    type: null,
                    name: null,
                    decision_date: null,
                    decision_amount: null
                };
                await window.UIkit.modal('#confirmation').hide();
                break;
            }
        },
        async showModal(modal_name, data=null) {
            switch(modal_name) {
            case 'edit-fee':
                this.selected_data = data;
                await window.UIkit.modal('#edit-fee').show();
                break;
            case 'adjustment':
                if (data) {
                    this.selectedAdjustment = data;
                    this.createAdjustment = false;
                }
                await window.UIkit.modal('#adjustment').show();
                break;
            case 'confirmation':
                this.selectedAdjustment = data;
                await window.UIkit.modal('#confirmation').show();
                break;
            }
        },
        async editFee(data) {
            const response = await this.postEditFee(data);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('edit-fee');
                await this.getData();
            } else {
                notificationDangerCustom('Failed to edit fee!');
            }
        },
        async postAdjustment(data) {
            data.decision_date = moment(data.decision_date).add(1, 'days');
            const response = await this.createSalaryAdjustment(data);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('adjustment');
                await this.getData();
            } else {
                notificationDangerCustom('Failed to create adjustment!');
            }
        },
        async editAdjustment(data) {
            data.decision_date = moment(data.decision_date).add(1, 'days');
            const response = await this.editSalaryAdjustment({
                _id: data._id,
                type: data.type,
                name: data.name,
                decision_amount: data.decision_amount,
                decision_date: data.decision_date
            });
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('adjustment');
                await this.getData();
            } else {
                notificationDangerCustom('Failed to edit adjustment!');
            }
        },
        async deleteAdjustment() {
            const response = await this.deleteSalaryAdjustment(this.selectedAdjustment._id);
            if (response && response.status === 'OK') {
                notificationSuccess(response.message);
                this.hideModal('confirmation');
                await this.getData();
            } else {
                notificationDangerCustom('Failed to delete!');
            }
        }
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.td-bold{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.report-download{
    font-family: 'Roboto';
    color: #5FD855;
    font-size: 20px;
}
.payment {
    background-color: #0ABAB5;
    color: #ffffff;
}
.photo {
    width: 106px;
    height: 106px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}
.border-top {
    border-top: 1px solid black;
}
.table-caption {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #000000;
}
</style>
