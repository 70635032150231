var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"uk-flex-top",attrs:{"id":"modal-center","uk-modal":"","esc-close":"false","bg-close":"false"}},[_c('div',{staticClass:"uk-modal-dialog"},[_c('button',{staticClass:"uk-modal-close-default",attrs:{"type":"button","uk-close":""},on:{"click":_vm.hideModal}}),_c('div',{staticClass:"uk-modal-header uk-text-center"},[_c('h2',{staticClass:"uk-modal-title"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"uk-modal-body"},[_c('div',{staticClass:"uk-width-1-1 uk-flex uk-flex-column"},[_c('div',{staticClass:"uk-flex"},[_c('img',{staticStyle:{"margin":"auto","display":"block"},attrs:{"src":_vm.url,"alt":_vm.title,"height":"250","loading":"lazy"}})]),_c('div',{staticClass:"uk-flex uk-flex-row uk-margin-small-top"},[(_vm.type === 'required_docs')?_c('div',{attrs:{"id":"required-docs"}},[(_vm.applicant.cv.ktp)?_c('button',{staticClass:"badge-doc uk-margin-small-right uk-padding-small",class:[_vm.selectedDoc == _vm.applicant.cv.ktp.type ? 'active' : ''],attrs:{"type":"button","type-doc":_vm.applicant.cv.ktp.type},on:{"click":function($event){return _vm.selectDoc({
                                        url: _vm.applicant.cv.ktp.document_url
                                            .file_url,
                                        type: _vm.applicant.cv.ktp.type
                                    })}}},[_vm._v(" "+_vm._s(_vm.applicant.cv.ktp.type)+" ")]):_vm._e(),(_vm.applicant.cv.kk)?_c('button',{staticClass:"badge-doc uk-margin-small-right uk-padding-small",class:[_vm.selectedDoc == _vm.applicant.cv.kk.type ? 'active' : ''],attrs:{"type":"button","type-doc":_vm.applicant.cv.kk.type},on:{"click":function($event){return _vm.selectDoc({
                                        url: _vm.applicant.cv.kk.document_url
                                            .file_url,
                                        type: _vm.applicant.cv.kk.type
                                    })}}},[_vm._v(" "+_vm._s(_vm.applicant.cv.kk.type)+" ")]):_vm._e(),(_vm.applicant.cv.sim)?_c('button',{staticClass:"badge-doc uk-margin-small-right uk-padding-small",class:[_vm.selectedDoc == _vm.applicant.cv.sim.type ? 'active' : ''],attrs:{"type":"button","type-doc":_vm.applicant.cv.sim.type},on:{"click":function($event){return _vm.selectDoc({
                                        url: _vm.applicant.cv.sim.document_url
                                            .file_url,
                                        type: _vm.applicant.cv.sim.type
                                    })}}},[_vm._v(" "+_vm._s(_vm.applicant.cv.sim.type)+" ")]):_vm._e(),(_vm.applicant.cv.ijazah)?_c('button',{staticClass:"badge-doc uk-margin-small-right uk-padding-small",class:[_vm.selectedDoc == _vm.applicant.cv.ijazah.type ? 'active' : ''],attrs:{"type":"button","type-doc":_vm.applicant.cv.ijazah.type},on:{"click":function($event){return _vm.selectDoc({
                                        url: _vm.applicant.cv.ijazah
                                            .document_url.file_url,
                                        type: _vm.applicant.cv.ijazah.type
                                    })}}},[_vm._v(" "+_vm._s(_vm.applicant.cv.ijazah.type)+" ")]):_vm._e(),(_vm.applicant.cv.skck)?_c('button',{staticClass:"badge-doc uk-margin-small-right uk-padding-small",class:[_vm.selectedDoc == _vm.applicant.cv.skck.type ? 'active' : ''],attrs:{"type":"button","type-doc":_vm.applicant.cv.skck.type},on:{"click":function($event){return _vm.selectDoc({
                                        url: _vm.applicant.cv.skck.document_url
                                            .file_url,
                                        type: _vm.applicant.cv.skck.type
                                    })}}},[_vm._v(" "+_vm._s(_vm.applicant.cv.skck.type)+" ")]):_vm._e()]):_vm._e(),(_vm.type === 'additional_docs')?_c('div',{attrs:{"id":"additional-docs"}},[_vm._l((_vm.additionalDocs),function(addDoc,index){return _c('button',{key:index,staticClass:"badge-doc uk-margin-small-right uk-margin-small-top uk-padding-small",class:[_vm.selectedDoc == addDoc.doc_code ? 'active' : ''],attrs:{"type":"button","type-doc":addDoc.doc_code},on:{"click":function($event){return _vm.selectDoc({
                                        url: addDoc.document_url.file_url,
                                        type: addDoc.doc_code
                                    })}}},[_vm._v(" "+_vm._s(addDoc.name)+" ")])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewMore),expression:"viewMore"}]},_vm._l((_vm.moreAdditionalDocs),function(addDoc,index){return _c('button',{key:index,staticClass:"badge-doc uk-margin-small-right uk-margin-small-top uk-padding-small",class:[_vm.selectedDoc == addDoc.doc_code ? 'active' : ''],attrs:{"type":"button","type-doc":addDoc.doc_code},on:{"click":function($event){return _vm.selectDoc({
                                            url: addDoc.document_url.file_url,
                                            type: addDoc.doc_code
                                        })}}},[_vm._v(" "+_vm._s(addDoc.name)+" ")])}),0),(_vm.applicant.additional_docs.length > 9)?_c('button',{staticClass:"badge-doc uk-margin-small-right uk-margin-small-top uk-padding-small",staticStyle:{"background":"#EEEEEE"},on:{"click":function($event){_vm.viewMore = !_vm.viewMore}}},[_vm._v(" "+_vm._s(_vm.viewMore ? 'Show Less' : 'View More')+" "),_c('span',[(_vm.viewMore)?_c('img',{attrs:{"src":(_vm.baseUrlImg + "/job_applicant/detail/collapse-arrow.svg"),"width":"20px","height":"20px"}}):_c('img',{attrs:{"src":(_vm.baseUrlImg + "/job_applicant/detail/expand-arrow.svg"),"width":"20px","height":"20px"}})])]):_vm._e()],2):_vm._e()])])]),_c('div',{staticClass:"uk-modal-footer uk-text-center"},[_c('section',[_c('button',{staticClass:"uk-button uk-button-primary",staticStyle:{"background":"#0abab5","border-color":"#0abab5","color":"#ffffff","margin":"0 1rem"},attrs:{"type":"button"},on:{"click":function($event){return _vm.download(_vm.urlDownload)}}},[_vm._v(" Download ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }