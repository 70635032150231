import api from '@/utils/api';
import { ANNOUNCEMENT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getAnnouncementList(context, data) {
        try {
            const response = await api.apiGetAuth(ANNOUNCEMENT.LIST_CREATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postAnnouncement(context, data) {
        try {
            const response = await api.apiPostAuth(ANNOUNCEMENT.LIST_CREATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateAnnouncement(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(ANNOUNCEMENT.GET_UPDATE_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteAnnouncement(context, id) {
        try {
            const response = await api.apiDeleteAuth(ANNOUNCEMENT.GET_UPDATE_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
