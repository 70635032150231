<template>
    <div>
        <div v-if="!queryComplete">
            Some query is missing, can't get file
        </div>
        <div v-else>
            <div v-if="fileExtension != 'pdf'">
                <img :src="fileUrl" :alt="$route.query.file">
            </div>
            <div v-else>
                <embed :src="fileUrl" width="100%" height="900px" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryComplete: true,
            fileUrl: "",
            fileExtension: null
        };
    },
    beforeMount() {
        if (!this.$route.query.bucket || !this.$route.query.folder || !this.$route.query.id || !this.$route.query.file) {
            this.queryComplete = false;
        } else {
            this.fileUrl = `${process.env.VUE_APP_S3_URL}/${this.$route.query.bucket}/${this.$route.query.folder}/${this.$route.query.id}/${this.$route.query.file}`;
            this.fileExtension = this.$route.query.file.slice(-3);
        }
    }
};
</script>
