import api from '@/utils/api';
import { TASK } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getTaskAssignment(context, data) {
        try {
            const response = await api.apiGetAuth(TASK.TASK_ASSIGNMENT_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addTaskAssignment(context, data) {
        try {
            const response = await api.apiPostAuth(TASK.ADD_ASSIGNMENT, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateTaskAssignment(context, data) {
        try {
            const id = data.id;
            delete data.id;
            const response = await api.apiPutAuth(TASK.UPDATE_ASSIGNMENT(id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
