import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import router from '@/router';

export const getUserLogin = () => {
    const token = Cookies.get('user_token');
    const user = token ? jwtDecode(token) : null;
    const client_role = Cookies.get('user_role') || null;

    return user ? {...user, client_role} : null;
};

export const getUserToken = () => {
    return Cookies.get('user_token') || null;
};

export const setAuthCookie = (data) => {
    Cookies.set('user_token', data, { expires: 3, path: '/' });
    return true;
};

export const setUserRole = (role) => {
    Cookies.set('user_role', role, { expires: 3, path: '/' });
    return true;
};

export const logoutUser = () => {
    Cookies.remove('user_token', { path: '/' });
    Cookies.remove('user_role', { path: '/' });
    router.go(0);
};
