import Vue from 'vue';
import api from '@/utils/api';
import { SCHEDULE } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    scheduleList: [],
    daily_schedules: {docs:[]},
};
const mutations = {
    "SET_DAILY_SCHEDULES": (state, payload) => {
        Vue.set(state, 'daily_schedules', payload);
    }
};

const actions = {
    async postCreateSchedule(context, data) {
        try {
            const response = await api.apiPostAuth(SCHEDULE.CREATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postNotifSchedule(context, data) {
        try {
            const response = await api.apiPostAuth(SCHEDULE.POST_NOTIF + '/' + data.id + '/send-notif?date=' + data.date, '');
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async putEditSchedule(context, data) {
        try {
            const response = await api.apiPutAuth(SCHEDULE.EDIT + '/' + data.id, data.payload);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async putEditSpesificSchedule(context, data) {
        try {
            const response = await api.apiPutAuth(SCHEDULE.EDIT_SPECIFIC(data.id, data.date), data.payload);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getScheduleShift(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.GET_SCHEDULE_SHIFT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getScheduleName(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.GET_SCHEDULE_NAME, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchScheduleName(context, data) {
        try {
            const response = await api.apiPatchAuth(SCHEDULE.GET_SCHEDULE_NAME + '/' + data.id, data.param);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyRole(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.GET_COMPANY_ROLE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllCompanyShift(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.GET_ALL_COMPANY_SHIFT + '/' + data.companyOfficeId, '');
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showScheduleShift(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.SHOW_SCHEDULE_SHIFT + '/' + data.id + '?date=' + data.date);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteSpecificScheduleShift(context, data) {
        try {
            const response = await api.apiDeleteAuth(SCHEDULE.SHOW_SCHEDULE_SHIFT + '/' + data.id + '/specific?date=' + data.date);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteScheduleShift(context, data) {
        try {
            const response = await api.apiDeleteAuth(SCHEDULE.SHOW_SCHEDULE_SHIFT + '/' + data.id, '');
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserPartner(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.GET_USER_PARTNER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async checkAvailable(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.CHECK_AVAILABLE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDailySchedules(context, data) {
        try {
            const response = await api.apiGetAuth(SCHEDULE.GET_CREATE_GRABWHEELS, data);
            context.commit('SET_DAILY_SCHEDULES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createDailySchedule(context, data) {
        try {
            const response = await api.apiPostAuth(SCHEDULE.GET_CREATE_GRABWHEELS, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateDailySchedule(context, {scheduleId, data}) {
        try {
            const response = await api.apiPatchAuth(SCHEDULE.EDIT_DELETE_GRABWHEELS(scheduleId), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteDailySchedule(context, id) {
        try {
            const response = await api.apiDeleteAuth(SCHEDULE.EDIT_DELETE_GRABWHEELS(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    daily_schedules: state => {
        return state.daily_schedules;
    }
};


export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
