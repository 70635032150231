<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <button @click="$router.push($store.state.routeBackFull);">
            <img class="uk-margin-small-right" :src="`${images}/arrow-back.svg`" alt="" />
        </button>
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <!-- Mitra basic data -->
            <div class="uk-flex uk-flex-row uk-flex-left uk-margin-small-top">
                <div class="uk-width-auto uk-margin-medium-right">
                    <img
                        v-if="mitraData.photo_url && mitraData.photo_url.file_url"
                        :src="mitraData.photo_url.file_url"
                        class="photo"
                        @error="(e) => imgError(e)"
                        alt="User profile photo"
                    >
                    <img v-else :src="`${images}/avatar.svg`" class="photo" alt="Default profile photo">
                </div>
                <div class="uk-width-expand uk-flex uk-flex-column">
                    <div class="uk-text-bold uk-text-large">
                        {{ mitraData.fullname }}
                    </div>
                    <div class="uk-flex uk-flex-row uk-margin-small-top">
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${images}/job_applicant/detail/foundation_telephone.svg`" alt="">
                                </span>
                                {{ mitraData.phone_number || '-' }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${images}/job_applicant/detail/ic_sharp-mail.svg`" alt="">
                                </span>
                                {{ mitraData.email }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${images}/job_applicant/detail/mdi_location.svg`" alt="">
                                </span>
                                {{ mitraData.domicile_city || '' }}
                                {{ mitraData.domicile_address ?
                                    mitraData.domicile_city ? ' , ' + mitraData.domicile_address : mitraData.domicile_address :
                                    mitraData.domicile_city ? '' : '-'
                                }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${images}/job_applicant/detail/ph_gender-intersex-bold.svg`" alt="">
                                </span>
                                {{ mitraData.gender ? getGender(mitraData.gender) : '-' }}
                            </div>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${images}/job_applicant/detail/lucide_calendar.svg`" alt="">
                                </span>
                                {{ mitraData.birthplace_city || '-' }}, {{ mitraData.birthdate ? formatShortDate(mitraData.birthdate) : '-' }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${images}/kyc/briefcase-small-gray.svg`" alt="">
                                </span>
                                {{ mitraData.role || '-' }}
                            </div>
                            <div class="uk-flex uk-flex-row text-grey text-f14-l24">
                                <span class="uk-flex uk-flex-center icon">
                                    <img :src="`${images}/kyc/tall-building-gray.svg`" alt="">
                                </span>
                                {{ mitraData.office || '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Validation label tab button -->
            <section class="uk-margin">
                <button
                    v-for="(bMenu, bKey) in buttonMenus"
                    :key="bKey"
                    class="uk-button uk-button-large uk-margin-right uk-margin-small-bottom uk-border-rounded"
                    :class="validation === bMenu.value ? 'myrobin-background-primary myrobin-color-white' : 'uk-button-default uk-text-muted'"
                    @click="changeActiveTab(bMenu.value)"
                >{{ bMenu.text }}</button>
            </section>

            <div class="uk-width-1-1">
                <SilverValidation
                    v-if="validation == 'silver'"
                    :data="silverData"
                    :validated="silverValidated"
                    :table-loading="tableLoading"
                    @validate="openValidationModal"
                    @unlabel="openUnlabelModal"
                    @read-validation-notes="openValidationNotesModal"
                    @re-fetch="getKYCMitraDetailData"
                />
                <GoldValidation
                    v-else-if="validation == 'gold'"
                    :data="goldData"
                    :validated="goldValidated"
                    :table-loading="tableLoading"
                    @validate="openValidationModal"
                    @unlabel="openUnlabelModal"
                    @read-validation-notes="openValidationNotesModal"
                    @re-fetch="getKYCMitraDetailData"
                />
                <PlatinumValidation
                    v-else-if="validation == 'platinum'"
                    :data="platinumData"
                    :validated="platinumValidated"
                    :table-loading="tableLoading"
                    @validate="openValidationModal"
                    @unlabel="openUnlabelModal"
                    @read-validation-notes="openValidationNotesModal"
                    @re-fetch="getKYCMitraDetailData"
                />
            </div>
        </div>

        <!-- Modal Label Mitra Validation -->
        <div id="label-validation-mitra" class="uk-flex-top label-validation-mitra" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <h2 class="uk-modal-title uk-text-bold uk-text-center uk-margin-small-top">Label This Mitra As {{ mitraLabelType }}</h2>
                <p class="uk-text-center">Add notes / score from KYC for {{ mitraLabelType }} Validation</p>
                <form @submit.prevent="confirmLabelMitraValidation()">
                    <div class="uk-padding uk-grid-small" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="notes" class="uk-form-label">Notes</label>
                            <textarea
                                id="notes"
                                name="Notes"
                                class="uk-textarea uk-border-rounded"
                                rows="3"
                                :placeholder="dynamicPlaceholder"
                                v-model="form.notes"
                            ></textarea>
                            <p class="uk-text-small uk-text-right">{{ form.notes.length}}/2000</p>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-modal-close uk-border-rounded"
                            style="border: 1px solid #0275D8; color: #0275D8;"
                        >Cancel</button>
                        <button
                            type="submit"
                            class="uk-button uk-margin-small-left uk-border-rounded"
                            :class="[disableSubmitMitraValidationLabel ? 'myrobin-background-grey myrobin-color-white' : 'uk-button-primary']"
                            :disabled="disableSubmitMitraValidationLabel"
                        >Save</button>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal Label Mitra Validation -->

        <!-- Confirm Modal -->
        <div id="confirm-modal" class="uk-flex-top confirm-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">
                        <span>
                            <img :src="`${images}/warning-circle-red.svg`" class="uk-margin-small-right" alt="Warning icon on action">
                        </span>
                        Label This Mitra as {{ mitraLabelType }}
                    </h2>
                </div>
                <div class="uk-modal-body">
                    <p>Are you sure you want to label this mitra as {{ mitraLabelType }}?</p>
                </div>
                <div class="uk-modal-footer uk-flex uk-flex-between">
                    <button
                        v-if="submitLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        disabled
                    >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        class="uk-button uk-button-default uk-modal-close uk-border-rounded"
                        type="button"
                        style="border: 1px solid #0275D8; color: #0275D8;"
                    >Cancel</button>

                    <button
                        v-if="submitLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        disabled
                    >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        class="uk-button uk-margin-small-left uk-border-rounded uk-button-primary"
                        type="button"
                        @click="sendLabelMitraValidation()"
                    >Yes, I'm sure</button>
                </div>
            </div>
        </div>
        <!-- Confirm Modal -->

        <!-- Unlabel Modal -->
        <div id="unlabel-modal" class="uk-flex-top unlabel-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">
                        <span>
                            <img :src="`${images}/warning-circle-red.svg`" class="uk-margin-small-right" alt="Warning icon on action">
                        </span>
                        Unlabel This Mitra from {{ mitraLabelType }}
                    </h2>
                </div>
                <div class="uk-modal-body">
                    <p>Are you sure you want to unlabel this partner from {{ mitraLabelType }}?</p>
                </div>
                <div class="uk-modal-footer uk-flex uk-flex-between">
                    <button
                        v-if="submitLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        disabled
                    >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        class="uk-button uk-button-default uk-modal-close uk-border-rounded"
                        type="button"
                        style="border: 1px solid #0275D8; color: #0275D8;"
                    >Cancel</button>

                    <button
                        v-if="submitLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        disabled
                    >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        class="uk-button uk-margin-small-left uk-border-rounded uk-button-primary"
                        type="button"
                        @click="unlabelMitra()"
                    >Yes, I'm sure</button>
                </div>
            </div>
        </div>
        <!-- Unlabel Modal -->

        <!-- Modal View Validation Notes -->
        <div id="validation-notes-modal" class="uk-flex-top validation-notes-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-bold uk-text-center">Notes / Score for {{ label.label }} Validation</h2>
                </div>
                <div class="uk-modal-body">
                    <p>KYC notes or validation score for {{ label.label }} Validation</p>
                    <div
                        v-for="(val, index) in validationData"
                        :key="index"
                        class="uk-width-1-1 uk-text-bold"
                        style="border-radius: 10px; margin-bottom: 3px"
                    >
                        <span class="uk-margin-remove">
                            <img :src="`${images}/${val.icon}`" alt="">
                            {{ val.label }}
                        </span>
                        <span class="uk-margin-remove uk-width-expand">{{ val.value }}</span>
                    </div>
                    <div class="uk-width-1-1 uk-padding-small uk-border-rounded" style="background-color: #F8F7F7;">
                        {{ label.notes }}
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal View Validation Notes -->
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';
import formatter from "@/utils/formatter";
import moment from 'moment';
import SilverValidation from '@/components/globals/kyc/SilverValidation';
import GoldValidation from '@/components/globals/kyc/GoldValidation';
import PlatinumValidation from '@/components/globals/kyc/PlatinumValidation';

moment.locale('id');

export default {
    name: 'DetailCandidate',
    components: {
        SilverValidation,
        GoldValidation,
        PlatinumValidation,
    },
    data(){
        return {
            images: PREFIX_IMAGE,
            datetime: DateTime,
            formatter: formatter,
            isLoading: false,
            mitraData: null,
            silverData: null,
            goldData: null,
            platinumData: null,
            silverValidated: {},
            goldValidated: {},
            platinumValidated: {},
            buttonMenus: [
                { text: 'Silver', value: 'silver' },
                { text: 'Gold', value: 'gold' },
                { text: 'Platinum', value: 'platinum' },
            ],
            validation: 'silver',
            form: {
                label: '',
                notes: '',
            },
            label: {
                label: '',
                notes: '',
            },
            submitLoading: false,
            mitraLabelType: '',
            dynamicPlaceholder: '',
        };
    },
    watch: {
        async '$route'(){
            this.isLoading = true;
            try {
                await this.getKYCMitraDetailData();
            } catch (error) {
                notificationDanger(error);
            }
            this.isLoading = false;
        }
    },
    computed: {
        disableSubmitMitraValidationLabel() {
            if (!this.form.notes || this.form.notes.length > 2000) {
                return true;
            } else {
                return false;
            }
        },
        validationData() {
            const validateDateInfo = this.silverValidated?.updated_at || null;

            let validateDate = '-', validateTime = '-';
            if (validateDateInfo) {
                validateDate = formatter.dateComplete(moment(validateDateInfo).toISOString());
                validateTime = formatter.hourMinuteOnly(moment(validateDateInfo).toISOString());
            }

            return [
                {
                    label: "Validated by",
                    icon: "checkmark-gray.svg",
                    value: ": " + this.silverValidated?.updated_by?.fullname || '-'
                },
                {
                    label: "Validated at",
                    icon: "clock-gray.svg",
                    value: `: ${validateDate} | ${validateTime}`
                },
            ];
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.label-validation-mitra')) window.UIkit.modal('.label-validation-mitra').$destroy(true);
        if (window.UIkit.modal('.confirm-modal')) window.UIkit.modal('.confirm-modal').$destroy(true);
        if (window.UIkit.modal('.unlabel-modal')) window.UIkit.modal('.unlabel-modal').$destroy(true);
        if (window.UIkit.modal('.validation-notes-modal')) window.UIkit.modal('.validation-notes-modal').$destroy(true);
    },
    async mounted() {
        this.isLoading = true;
        try {
            await this.getKYCMitraDetailData();
        } catch (error) {
            notificationDanger(error);
        }
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            getKYCMitraDetail: 'kyc/getKYCMitraDetail',
            markMitraKYCLabel: 'kyc/markMitraKYCLabel',
        }),
        async changeActiveTab(level) {
            this.validation = level;
        },
        async getKYCMitraDetailData() {
            this.tableLoading = true;

            const response = await this.getKYCMitraDetail(this.$route.params.id);
            if (response) {
                this.mitraData = response.profile;
                this.silverData = response.silver_dataset;
                this.goldData = response.gold_dataset;
                this.platinumData = response.platinum_dataset;

                if (this.platinumData.other_docs) {
                    this.platinumData.other_docs = this.platinumData.other_docs.filter((doc) => {
                        if (doc.document_url) {
                            return doc;
                        }
                    });

                    if (this.platinumData.other_docs.length > 0) {
                        this.platinumData.other_docs.sort((a, b) =>
                            (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0)
                        );
                    }
                }

                this.silverValidated = response.profile.silver_validation || {};
                this.goldValidated = response.profile.gold_validation || {};
                this.platinumValidated = response.profile.platinum_validation || {};
            }

            this.tableLoading = false;
        },
        async openValidationModal({ type }) {
            this.resetLabelForm();
            this.form.label = type;

            if (this.form.label == 'silver') {
                this.mitraLabelType = 'Silver';
            } else if (this.form.label == 'gold') {
                this.mitraLabelType = 'Gold';
            } else if (this.form.label == 'platinum') {
                this.mitraLabelType = 'Platinum';
            }
            this.dynamicPlaceholder = `Add KYC notes or validation score here for ${this.mitraLabelType} Validation...`;

            await window.UIkit.modal("#label-validation-mitra").show();
        },
        async confirmLabelMitraValidation() {
            await window.UIkit.modal("#label-validation-mitra").hide();
            await window.UIkit.modal("#confirm-modal").show();
        },
        async sendLabelMitraValidation() {
            try {
                const payload = {
                    user_id: this.$route.params.id,
                    label: this.form.label,
                    notes: this.form.notes,
                };

                const response = await this.markMitraKYCLabel(payload);
                if (response && response.status === "OK") {
                    notificationSuccess("Successfuuly label this mitra.");
                    await this.getKYCMitraDetailData();
                } else {
                    notificationDangerCustom("Failed to label this mitra.");
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.resetLabelForm();
                await window.UIkit.modal("#confirm-modal").hide();
                this.submitLoading = false;
            }
        },
        async openUnlabelModal({ type }) {
            this.resetLabelForm();
            this.form.label = type;

            if (this.form.label == 'silver') {
                this.mitraLabelType = 'Silver';
            } else if (this.form.label == 'gold') {
                this.mitraLabelType = 'Gold';
            } else if (this.form.label == 'platinum') {
                this.mitraLabelType = 'Platinum';
            }

            await window.UIkit.modal("#unlabel-modal").show();
        },
        async unlabelMitra() {
            try {
                this.submitLoading = true;

                const payload = {
                    user_id: this.$route.params.id,
                    label: this.form.label,
                };

                const response = await this.markMitraKYCLabel(payload);
                if (response && response.status === "OK") {
                    notificationSuccess("Successfuuly unlabel this mitra.");
                    await this.getKYCMitraDetailData();
                } else {
                    notificationDangerCustom("Failed to unlabel this mitra.");
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.resetLabelForm();
                await window.UIkit.modal("#unlabel-modal").hide();
                this.submitLoading = false;
            }
        },
        async openValidationNotesModal({ type }) {
            
            this.label = {
                label: '-',
                notes: '-',
            };

            if (type == 'silver') {
                if (!this.silverValidated?.is_valid) {
                    return;
                }

                this.label = {
                    label: 'Silver',
                    notes: this.silverValidated?.notes || '-',
                };
            } else if (type == 'gold') {
                if (!this.goldValidated?.is_valid) {
                    return;
                }

                this.label = {
                    label: 'Gold',
                    notes: this.goldValidated?.notes || '-',
                };                
            } else if (type == 'platinum') {
                if (!this.platinumValidated?.is_valid) {
                    return;
                }

                this.label = {
                    label: 'Platinum',
                    notes: this.platinumValidated?.notes || '-',
                };
            }

            await window.UIkit.modal("#validation-notes-modal").show();
        },
        getGender(val){
            let data = '';
            if (val === 'female') {
                data = 'Perempuan';
            } else if(val === 'male') {
                data = 'Laki-laki';
            }
            return data;
        },
        formatShortDate(date) {
            return formatter.dateComplete(moment(date).toISOString());
        },
        resetLabelForm() {
            this.form = {
                label: '',
                notes: '',
            };
        },
    }
};
</script>

<style scoped>
.photo {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
span.icon {
    align-items: center;
    margin-right: .7rem;
}
.myrobin-color-white {
    color: #ffffff;
}
</style>
