import api from '../../utils/api';
import {JOB} from '../../utils/api-url';
import {notificationDanger} from '../../utils/notification';
import Vue from 'vue';

const namespaced = true;
const state = {
    job_list: []
};

const mutations = {
    "SET_VACANCY_LIST": (state, payload) => {
        Vue.set(state, 'job_list', payload);
    }
};

const actions = {
    async getJobList(context, data) {
        try {
            const {...filter} = data;
            const response = await api.apiGetAuth(JOB.LIST, filter);
            context.commit('SET_VACANCY_LIST', response.data.result.docs);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    job_list: state => {
        return state.job_list;
    },
};

export default {
    state,
    mutations,
    namespaced,
    actions,
    getters
};
