<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-row" v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-operation'])">
            <div class="uk-width-1-4">
                <select
                    name="job_type"
                    class="uk-select"
                    v-model="meta.job_id"
                    @change="getApplicants"
                >
                <option value="" disabled selected>Choose Job</option>
                <option v-for="(item, it) in job_list" :value="item._id" :key="it">{{item.title}}</option>
                </select>
            </div>
            <div class="uk-margin-left uk-margin-auto-vertical uk-width-1-4">
                <label><input class="uk-checkbox" type="checkbox" v-model="meta.from_kormo" @change="getApplicants"> Kormo Only</label>
            </div>
        </div>

        <span v-if="isLoading" uk-spinner="ratio: 2" class="uk-position-center uk-margin-auto"></span>
        <div v-else-if="meta.job_id != '' && job_data.docs.length != 0" class="uk-margin-auto">
            <div class="uk-margin-top" style="font-weight: 600">
                <span>{{job_data.initiation.role.name}}<br></span>
                <span>Manpower : {{job_data.initiation.manpower}} Orang</span>
            </div>
            <div class="uk-card uk-card-default uk-margin" style="padding: 10px 46px 10px 30px" v-for="(applicant, a) in job_data.docs" :key="a">
                <div class="uk-margin-small-bottom">
                    <span>New</span>
                </div>
                <div uk-grid>
                    <div class="uk-width-auto uk-margin-auto uk-margin-auto-vertical">
                        <img v-if="applicant.user.photo_url != null" :src="applicant.user.photo_url.file_url" @error="(e) => imgError(e)" class="photo">
                        <img v-else :src="`${images}/avatar.svg`" class="photo">
                    </div>
                    <div class="uk-width-expand uk-margin-auto uk-margin-auto-vertical">
                        <span class="applicant_name">{{applicant.user.fullname}}</span><br>
                        <span>Required files:
                            <span v-if="requiredFiles(applicant.cv, applicant.job.required_docs)" style="font-weight: 700">Complete</span>
                            <span v-else style="font-weight: 700; color:red">Not Complete</span>
                        </span><br>
                        <span>Assessment Score:</span><br>
                        <span>{{scoreList(applicant.all_user_scores, job_data.initiation.job_id.required_tests)}}</span><br>
                        <span v-if="scorePass(applicant.all_user_scores, job_data.initiation.job_id.required_tests)" style="font-weight: 700">Pass Assessment Minimum Score</span>
                        <span v-else style="font-weight: 700; color:red">Not Pass Assessment Minimum Score</span>
                        <template v-if="applicant.from_kormo">
                            <br><span style="font-weight: 700">From Kormo</span>
                        </template>
                    </div>
                    <div class="uk-width-1-6 uk-margin-auto uk-margin-auto-vertical" v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-operation'])">
                        <button class="uk-button uk-align-right pickup" @click="pickTheApplicant(applicant._id)">Pickup</button>
                        <button class="uk-button uk-button-primary uk-align-right" @click="getDetail(applicant._id)">Details</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="meta.job_id != '' && job_data.docs.length == 0" class="uk-position-center uk-margin-auto"> No New Applicants Yet!</div>
        <pagination v-if="meta.job_id != '' && job_data.docs.length != 0"
                :total-data="job_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />

        <div id="detailModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('detailModal')"></button>
                <div class="uk-modal-header">
                    <h3 class="uk-modal-title uk-text-center">Detail Applicant</h3>
                </div>
                <div class="uk-modal-body">
                    <span v-if="isFetching" uk-spinner="ratio: 2" class="uk-position-center uk-margin"></span>
                    <div v-else-if="job_applicant_detail != null" class="uk-margin" uk-grid>
                        <div class="uk-width-1-1">
                            <h2 class="uk-text-center">{{job_applicant_detail.user.fullname}}</h2>
                        </div>
                        <div class="uk-width-auto uk-margin-auto uk-margin-auto-vertical">
                            <img v-if="job_applicant_detail.user.photo_url != null" :src="job_applicant_detail.user.photo_url.file_url"
                            @error="(e) => imgError(e)" class="photo">
                            <img v-else :src="`${images}/avatar.svg`" class="photo">
                        </div>
                        <div class="uk-width-expand uk-margin-medium-top uk-margin-auto">
                            <span>Tanggal Lahir: {{formatDate(job_applicant_detail.user.birthdate)}}</span><br>
                            <span>Domisili: {{job_applicant_detail.user.domicile ? job_applicant_detail.user.domicile.name : '-'}}</span><br>
                            <span>Pendidikan: {{job_applicant_detail.user.education_level.name || '-'}}</span><br>
                            <span>Pengalaman Kerja: </span><span v-if="job_applicant_detail.cv == null || job_applicant_detail.cv.work_experiences.length == 0">-<br></span>
                            <div v-else>
                                <span v-for="(exp, x) in job_applicant_detail.cv.work_experiences" :key="x" class="uk-margin-small-left" >
                                    <span v-if="exp.title">{{x+1}}. {{exp.title}} - {{exp.company_name}} ({{formatYear(exp.start_date)}} - {{formatYear(exp.end_date)}})<br></span>
                                </span>
                            </div>
                            <span>Kemampuan: </span><br>
                            <div>
                                <span v-for="(skill, i) in job_applicant_detail.cv.skills" :key="i" class="uk-margin-small-left">
                                - {{skill.name}}<br></span>
                            </div>
                            <span>Assessment Score: </span><br>
                            <span class="uk-margin-small-left">{{scoreList(job_applicant_detail.all_user_scores, job_data.initiation.job_id.required_tests)}}</span><br>
                            <span>Required files:
                                <a v-if="consistDoc(job_applicant_detail.cv, 'ktp')" style="font-weight: 700" :href="job_applicant_detail.cv.ktp.document_url.file_url">KTP</a>
                                <span v-else style="color:red">KTP</span>
                                &nbsp;
                                <a v-if="consistDoc(job_applicant_detail.cv, 'skck')" style="font-weight: 700" :href="job_applicant_detail.cv.skck.document_url.file_url">SKCK</a>
                                <span v-else style="color:red">SKCK</span>
                                &nbsp;
                                <a v-if="consistDoc(job_applicant_detail.cv, 'ijazah')" style="font-weight: 700" :href="job_applicant_detail.cv.ijazah.document_url.file_url">Ijazah</a>
                                <span v-else style="color:red">Ijazah</span>
                                &nbsp;
                                <a v-if="consistDoc(job_applicant_detail.cv, 'sim')" style="font-weight: 700" :href="job_applicant_detail.cv.sim.document_url.file_url">SIM</a>
                                <span v-else style="color:red">SIM</span>
                                &nbsp;
                            </span><br>
                            <span>Status: {{statusLabel(job_applicant_detail.status)}}</span><br>
                            <span v-if="job_applicant_detail.pickup_by">Pickup by: {{job_applicant_detail.pickup_by.fullname}} ({{job_applicant_detail.pickup_by.email}})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import Pagination from '@/components/globals/Pagination';
import { getUserLogin } from '@/utils/auth';

export default {
    async mounted() {
        this.job_list = await this.getJobList();
    },
    data(){
        return {
            meta: {
                limit: 50,
                page: 1,
                job_id: '',
                status: 0,
                from_kormo: false
            },
            images: PREFIX_IMAGE,
            isLoading: false,
            isFetching: false,
            job_list: [],
            job_data: {docs:[]},
            job_applicant_detail: null,
        };
    },
    computed: {
        user_cred() {
            return getUserLogin();
        },
    },
    components: {
        Pagination,
    },
    methods: {
        ...mapActions({
            getJobList: 'initiation/getJobList',
            getJobApplicants: 'applicant/getJobApplicants',
            getJobApplicantDetail: 'applicant/getJobApplicantDetail',
            pickupApplicant: 'applicant/pickupApplicant',
        }),
        async getApplicants(){
            this.isLoading = true;
            this.job_data = await this.getJobApplicants(this.meta);
            this.isLoading = false;
        },
        imgError(e){
            e.target.src = `${this.images}/avatar.svg`;
        },
        scoreList(user_scores, required_tests){
            let data = "";
            if (user_scores) {
                for (const req_test of required_tests) {
                    for (const score of user_scores) {
                        if (req_test.test_category_id == score.test_category_id._id) {
                            data += data + `- ${score.test_category_id.name}: ${score.score}\n`;
                        }
                    }
                }
                return data;
            } else {
                return data;
            }
        },
        scorePass(user_scores, required_tests){
            let pass = false;
            if (!user_scores && required_tests) {
                return pass;
            } else if (!required_tests || required_tests.length < 1) {
                return true;
            } else {
                for (const req_test of required_tests) {
                    for (const score of user_scores) {
                        if (req_test.test_category_id == score.test_category_id._id) {
                            pass = (score.score > req_test.minimum_score) ? true : false;
                        }
                    }
                }
                return pass;
            }
        },
        async pickTheApplicant(id){
            const response = await this.pickupApplicant(id);
            if (response && response.status === 'OK') {
                notificationSuccess('Picked Up!');
                this.hideModal('detailModal');
                await this.getApplicants();
            } else {
                notificationDanger('Failed to pickup applicant.');
            }
        },
        async getDetail(id){
            this.showModal('detailModal');
            this.isFetching = true;
            const response = await this.getJobApplicantDetail(id);
            this.job_applicant_detail = response.result;
            this.isFetching = false;
        },
        async showModal(modal){
            switch (modal) {
            case 'detailModal':
                await window.UIkit.modal('#detailModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'detailModal':
                await window.UIkit.modal('#detailModal').hide();
                this.job_applicant_detail = null;
                break;
            }
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatYear(data) {
            if (data == null) {
                return '-';
            }
            return formatter.yearOnly(data);
        },
        statusLabel(status){
            switch (status) {
            case 0:
                return "New";
            case 1:
                return "Picked Up";
            case 2:
                return "Hired";
            case 3:
                return "Rejected";
            case 4:
                return "Interview";
            case 5:
                return "Contract Sent";
            case 6:
                return "Contract Signed";
            }
        },
        requiredFiles(cv, req_docs){
            if (req_docs) {
                if (req_docs.length < 1 && cv && cv != null) {
                    return true;
                } else {
                    req_docs.forEach(doc => {
                        if (!cv[doc] || cv[doc] == null) {
                            return false;
                        }
                    });
                    return true;
                }
            } else {
                return true;
            }
        },
        consistDoc(data, filter){
            if (data) {
                switch (filter) {
                case 'ktp':
                    if (data.ktp) {
                        return data.ktp;
                    } else {
                        return false;
                    }
                case 'skck':
                    if (data.skck) {
                        return data.skck;
                    } else {
                        return false;
                    }
                case 'ijazah':
                    if (data.ijazah) {
                        return data.ijazah;
                    } else {
                        return false;
                    }
                case 'sim':
                    if (data.sim) {
                        return data.sim;
                    } else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>

<style scoped>
.photo {
    width: 120px;
    height: 120px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}
.applicant_name {
    font-weight: 700;
    margin-bottom: 5px;
}
button {
    width: 140px
}
.pickup {
    background-color:#5FD855; color:black
}
</style>
