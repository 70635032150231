<template>
    <div class="uk-container uk-container-expand card-scrollable uk-padding-small">
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <!-- Filter -->
            <div v-if="$can('READ', 'rostering-shift')">
                <template v-if="meta.company_office_id === null">
                    <button class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default" @click="showFilterShiftModal">
                        <span><img :src="`${images}/shipper-location.png`" alt="Location icon" width="20"></span>
                        <span class="uk-margin-small-left uk-text-warning">Select Location Filter</span>
                    </button>
                </template>
                <template v-else>
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <button class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default" @click="showFilterShiftModal">
                            <span><img :src="`${images}/shipper-location.png`" alt="Location icon" width="20"></span>
                            <span class="uk-margin-small-left uk-text-warning">{{ cityName || '-' }}</span>
                        </button>
                        <button
                            v-if="$can('WRITE', 'rostering-shift')"
                            class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default" 
                            @click="addShift"
                        >
                            <span><img :src="`${images}/shift-add.png`" alt="Add shift icon" width="25"></span>
                            <span class="uk-margin-small-left uk-text-success">Add Shift</span>
                        </button>
                    </div>
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <div class="uk-margin-top">
                            <button class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default" @click="showFilterShiftModal">
                                <span><img :src="`${images}/shipper-storage.png`" alt="Storage icon" width="20"></span>
                                <span class="uk-margin-small-left uk-text-warning">{{ officeName || '-' }}</span>
                            </button>
                            <button class="uk-button uk-button-link uk-text-muted uk-text-default uk-margin-small-top" @click="resetFilterShift">
                                <span style="text-decoration: underline;">Reset Location Filter</span>
                            </button>
                        </div>
                        <button
                            type="button"
                            class="uk-button uk-button-text uk-flex uk-flex-middle uk-flex-center uk-text-default"
                            @click="showDownloadReportModalBox"
                        >
                            <span><img :src="`${images}/download.png`" alt="Download icon" width="25"></span>
                            <span class="uk-margin-small-left uk-text-success">Download Report</span>
                        </button>
                    </div>
                </template>
            </div>
            <!-- End filter -->

            <!-- Shift list -->
            <div v-if="$can('READ', 'rostering-shift')" class="uk-card uk-card-default uk-margin-top">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-small uk-table-middle uk-table-striped">
                        <thead>
                            <tr>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Office</th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Shift Name</th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Job Title</th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Work Day(s)</th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Time</th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">
                                    <p class="uk-margin-remove">Duration</p>
                                    <p class="uk-margin-remove">(hours)</p>
                                </th>
                                <th class="uk-text-nowrap uk-text-center uk-text-middle">Workers</th>
                                <th
                                    v-if="$can('READ', 'rostering-shift') || $can('EDIT', 'rostering-shift')"
                                    class="uk-text-nowrap uk-text-center uk-text-middle"
                                >Action</th>
                            </tr>
                        </thead>
                        <loading-table v-if="tableLoading" :colspan="8" />
                        <template v-else>
                            <tbody v-if="shifts.docs.length > 0">
                                <tr v-for="(data, index) in shifts.docs" :key="index">
                                    <td class="uk-text-nowrap">{{ data.office_name || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ data.shift_name || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ data.job_title || 'All Job Title' }}</td>
                                    <td class="uk-text-nowrap">
                                        <template v-if="formatWorkDays({ data }).length > 0">
                                            <span
                                                v-for="(day, index) in formatWorkDays({ data })"
                                                :key="index"
                                            >
                                                <span>{{ day }}</span>
                                                <span v-if="index !== formatWorkDays({ data }).length -1">, </span>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span>Setiap hari</span>
                                        </template>
                                    </td>
                                    <td class="uk-text-nowrap uk-text-center">
                                        <span>{{ formatHourMinute({ data: data.start_shift }) }}</span>
                                        <span> - </span>
                                        <span>{{ formatHourMinute({ data: data.end_shift }) }}</span>
                                    </td>
                                    <td class="uk-text-nowrap uk-text-center">{{ formatDuration({ start_shift: data.start_shift, end_shift: data.end_shift }) }}</td>
                                    <td class="uk-text-nowrap uk-text-center">{{ data.workers || 0 }}</td>
                                    <td 
                                        v-if="$can('READ', 'rostering-shift') || $can('EDIT', 'rostering-shift')"
                                        class="uk-text-nowrap uk-text-center"
                                    >
                                        <button
                                            v-if="$can('EDIT', 'rostering-shift')"
                                            class="uk-button uk-button-primary uk-border-rounded"
                                            @click="editShift({ shiftId: data._id })"
                                        >Edit</button>
                                        <button
                                            v-if="$can('READ', 'rostering-shift')"
                                            class="uk-button uk-button-default uk-border-rounded uk-margin-small-left myrobin-button-primary"
                                            @click="detailShift({ shiftId: data._id })"
                                        >Details</button>
                                    </td>
                                </tr>
                            </tbody>
                            <empty-table
                                v-else
                                :colspan="8"
                            ></empty-table>
                        </template>
                    </table>
                </div>
                <pagination
                    :total-data="shifts.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End shift list -->

            <!-- Handler if don't have access permission -->
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>Selamat Datang di Company Dashboard</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler if don't have access permission -->

            <!-- Filter shift modal -->
            <div v-if="$can('READ', 'rostering-shift')" id="filter-shift-modal" class="uk-flex-top filter-shift-modal" uk-modal esc-close="false" bg-close="false">            
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">Filter Shift</h2>
                    </div>
                    <form @submit.prevent="doFilterShift">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="city" class="uk-form-label">City:</label>
                                    <select
                                        id="city"
                                        class="uk-select"
                                        v-model="formFilterShift.domicile_city_id"
                                        data-vv-name="domicile_city_id"
                                        data-vv-scope="formFilterShift"
                                        v-validate="{
                                            required: true
                                        }"
                                        @change="filterOffice"
                                    >
                                        <option :value="null" selected>Select City</option>
                                        <option
                                            v-for="(city, index) in cities"
                                            :key="index"
                                            :value="city._id"
                                        >{{ city.name || '-' }}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formFilterShift.domicile_city_id')">
                                        {{ errors.first('formFilterShift.domicile_city_id') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="office" class="uk-form-label">Office:</label>
                                    <select
                                        id="office"
                                        class="uk-select"
                                        v-model="formFilterShift.company_office_id"
                                        data-vv-name="company_office_id"
                                        data-vv-scope="formFilterShift"
                                        v-validate="{
                                            required: true
                                        }"
                                        :disabled="!formFilterShift.domicile_city_id"
                                    >
                                        <option :value="null" selected>Select Office</option>
                                        <option
                                            v-for="(office, index) in offices"
                                            :key="index"
                                            :value="office._id"
                                        >{{ office.name || '-' }}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formFilterShift.company_office_id')">
                                        {{ errors.first('formFilterShift.company_office_id') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="modalBoxButtonLoading"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="submit"
                                class="uk-button"
                                :class="[disabledApplyFilterButton ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledApplyFilterButton"
                            >Apply</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End filter shift modal -->

            <!-- Download report modal box -->
            <div id="download-report-modal-box" class="uk-flex-top download-report-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center uk-text-medium">Please select the type of report that you wish to download</h2>
                    </div>
                    <div class="uk-modal-body">
                        <div v-if="cityReport == false && officeReport == false">
                            <p>Please select category</p>
                            <div
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="cityReport = true, officeReport = false"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>Report per City</span>
                                </div>
                                <div>
                                    <img :src="`${images}/arrow-right.png`" alt="arrow-right">
                                </div>
                            </div>
                            <div
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="officeReport = true, cityReport = false"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>Report per Office</span>
                                </div>
                                <div>
                                    <img :src="`${images}/arrow-right.png`" alt="arrow-right">
                                </div>
                            </div>
                        </div>
                        <div v-if="cityReport == true || officeReport == true" uk-grid class="uk-margin-bottom">
                            <div class="uk-width-1-1">
                                <img :src="`${images}/excel.png`" alt="" width="20" />
                                Report per {{ cityReport ? "City" : "Office" }}
                            </div>
                            <div class="uk-width-1-2">
                                <label for="forMonth">For month:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    @change="setMonth({ event: $event })"
                                >
                                    <option
                                        v-for="(month, index) in monthOptions"
                                        :key="index"
                                        :value="month.name"
                                        :selected="month.name == currentMonthName"
                                    >{{ month.name || '-' }}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="forMonth">Year:</label>
                            </div>
                            <div class="uk-width-1-2">
                                <select
                                    id="forMonth"
                                    class="uk-select uk-border-rounded"
                                    v-model="reportYear"
                                >
                                    <option
                                        v-for="(year, index) in yearOptions"
                                        :key="index"
                                        :value="year"
                                        :selected="year === reportYear"
                                    >{{ year }}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="cityReport == true">
                            <div
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="doDownloadShiftReport({ type: 'city', data: null })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>All cities</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                            <div
                                v-for="(city, index) in cities"
                                :key="index"
                                :value="city"
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="doDownloadShiftReport({ type: 'city', data: city })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>{{ city.name || '-' }}</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                        </div>
                        <div v-if="officeReport == true">
                            <div
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="doDownloadShiftReport({ type: 'office', data: null })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>All Offices</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                            <div
                                v-for="(office, index) in company_offices"
                                :key="index"
                                :value="office"
                                class="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-between"
                                @click="doDownloadShiftReport({ type: 'office', data: office })"
                            >
                                <div class="uk-flex uk-margin-remove" style="gap: 10px;">
                                    <span><img :src="`${images}/excel.png`" alt="Excel icon" width="20"></span>
                                    <span>{{ office.name || '-' }}</span>
                                </div>
                                <div>
                                    <img :src="`${images}/download-report.png`" alt="download">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            type="button"
                            class="uk-button uk-button-primary"
                            @click="closeReportModal()"
                        >Back</button>
                    </div>
                </div>
            </div>
            <!-- End download report modal box -->

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import { notificationDanger } from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';
import moment from 'moment';
moment.locale('id');

export default {
    name: 'NewShift',
    components: {
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
    },
    data({ $route }) {
        return {
            isLoading: false,
            modalBoxButtonLoading: false,
            tableLoading: false,
            images: PREFIX_IMAGE,
            cities: [],
            offices: [],
            formFilterShift: Object.keys($route.query).length !== 0 ? $route.query : {
                domicile_city_id: null,
                company_office_id: null
            },
            shifts: {
                docs: [],
                totalDocs: 0
            },
            meta: {
                limit: 10,
                page: 1,
                company_office_id: null
            },
            cityReport: false,
            officeReport: false,
            monthOptions: [
                {
                    name: 'Januari',
                    value: '01'
                },
                {
                    name: 'Februari',
                    value: '02'
                },
                {
                    name: 'Maret',
                    value: '03'
                },
                {
                    name: 'April',
                    value: '04'
                },
                {
                    name: 'Mei',
                    value: '05'
                },
                {
                    name: 'Juni',
                    value: '06'
                },
                {
                    name: 'Juli',
                    value: '07'
                },
                {
                    name: 'Agustus',
                    value: '08'
                },
                {
                    name: 'September',
                    value: '09'
                },
                {
                    name: 'Oktober',
                    value: '10'
                },
                {
                    name: 'November',
                    value: '11'
                },
                {
                    name: 'Desember',
                    value: '12'
                },
            ],
            yearOptions: [2022, 2023, 2024],
            reportYear: null,
            reportMonth: null,
            currentMonthName: '',
            profile: null,
        };
    },
    watch: {
        async meta() {
            try {
                this.tableLoading = true;
                await this.setShiftData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            company_offices: 'company_office/company_offices'
        }),
        disabledApplyFilterButton() {
            let disabled = true;

            if (
                this.formFilterShift.domicile_city_id === null ||
                this.formFilterShift.company_office_id === null ||
                this.$validator.errors.any('formFilterShift')
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        cityName() {
            let name = null;

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.meta.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findCity && findCity.domicile_city_id && findCity.domicile_city_id.name) {
                    name = findCity.domicile_city_id.name;
                }
            }

            return name;
        },
        officeName() {
            let name = null;

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findOffice = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.meta.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findOffice && findOffice.name) {
                    name = findOffice.name;
                }
            }

            return name;
        }
    }, 
    beforeMount() {
        if (window.UIkit.modal('.filter-shift-modal')) window.UIkit.modal('.filter-shift-modal').$destroy(true);
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.getUserLogin();

            this.mapQuery();
            await Promise.all([
                this.getCompanyOffice(),
                this.setShiftData()
            ]);
            this.setCity();

            const today = moment();
            this.currentMonthName = today.format("MMMM");
            this.reportYear = today.format("YYYY");
            this.reportMonth = this.monthOptions.find((m) => m.name == today.format("MMMM"));
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getShiftList: 'new_shift/getShiftList',
            getWeeklyShiftMonthlyReport: 'report/getWeeklyShiftMonthlyReport',
            getProfile: "auth/getProfile",
        }),
        async getUserLogin() {
            this.profile = await this.getProfile();
            if (
                (this.profile.company_name.search("Astro") !== -1) ||
                (this.profile.company_name.search("Lion Parcel") !== -1)
            ) {
                this.$router.push(`/admin/shift/daily`);
            }
        },
        async setShiftData() {
            try {
                const response = await this.getShiftList(this.meta);
                if ((response) && (response.docs && response.totalDocs)) {
                    this.shifts.docs = response.docs;
                    this.shifts.totalDocs = response.totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showFilterShiftModal() {
            try {
                await window.UIkit.modal('#filter-shift-modal').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doFilterShift() {
            try {
                const validate = await this.$validator.validateAll('formFilterShift');
                if (!validate || this.$validator.errors.any('formFilterShift')) return;

                this.modalBoxButtonLoading = true;
                await window.UIkit.modal('#filter-shift-modal').hide();

                this.tableLoading = true;
                this.resetMeta();
                this.resetShifts();
                this.meta.company_office_id = this.formFilterShift.company_office_id;

                const queryString = Object.keys(this.formFilterShift).map(key => key + '=' + this.formFilterShift[key]).join('&');
                await this.$router.replace(this.$route.path + '?reset');
                await this.$router.replace(this.$route.path + `?${queryString}`);

                await this.setShiftData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async resetFilterShift() {
            try {
                this.tableLoading = true;
                this.$router.replace({'query': null});
                this.resetFormFilterShift();
                this.resetMeta();
                this.resetShifts();
                await this.setShiftData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        addShift() {
            if (this.meta.company_office_id === null) {
                return notificationDanger('Please select one of office');
            } else {
                this.$router.push(`/admin/shift/add-shift/${this.meta.company_office_id}`);
            }
        },
        editShift({ shiftId }) {
            this.$router.push(`/admin/shift/edit-shift/${shiftId}`);
        },
        detailShift({ shiftId }) {
            this.$router.push({ path: `/admin/shift/detail/${shiftId}`, query: this.formFilterShift });
        },
        setCity() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if (
                            (office.domicile_city_id && office.domicile_city_id._id) && 
                            (data._id === office.domicile_city_id._id)
                        ) {
                            return data;
                        }
                    });

                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        filterOffice() {
            this.formFilterShift.company_office_id = null;

            if (this.company_offices && this.company_offices.length > 0) {
                const offices = this.company_offices.filter((office) => {
                    if (
                        (office.domicile_city_id && office.domicile_city_id._id) && 
                        (this.formFilterShift.domicile_city_id) &&
                        (office.domicile_city_id._id === this.formFilterShift.domicile_city_id)
                    ) {
                        return office;
                    }
                });

                this.offices = offices;
            }

            if (this.offices.length > 0) {
                this.offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        resetFormFilterShift() {
            this.formFilterShift.domicile_city_id = null;
            this.formFilterShift.company_office_id = null;
            this.$validator.reset();
        },
        resetMeta() {
            this.meta.limit = 10;
            this.meta.page = 1;
            this.meta.company_office_id = null;
        },
        resetShifts() {
            this.shifts.docs = [];
            this.shifts.totalDocs = 0;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        formatWorkDays({ data }) {
            const days = [];
            if (data.monday === true) {
                days.push('Senin');
            }
            if (data.tuesday === true) {
                days.push('Selasa');
            }
            if (data.wednesday === true) {
                days.push('Rabu');
            }
            if (data.thursday === true) {
                days.push('Kamis');
            }
            if (data.friday === true) {
                days.push('Jumat');
            }
            if (data.saturday === true) {
                days.push('Sabtu');
            }
            if (data.sunday === true) {
                days.push('Minggu');
            }
            return days.length > 0 ? days : '-';
        },
        formatHourMinute({ data }) {
            if (!data) {
                return '--:--';
            }
            return formatter.hourMinuteOnly(data);
        },
        formatDuration({ start_shift, end_shift }) {
            if (!start_shift) {
                return '-';
            } else {
                const start = moment.utc(start_shift);
                const end = (start_shift.hour > end_shift.hour) == true
                    ? moment.utc(end_shift).add(1, 'days')
                    : moment.utc(end_shift);
                const duration = moment.duration(end.diff(start));
                const hours = Math.round(duration.asHours(), 2);
                return hours;
            }
        },
        async showDownloadReportModalBox() {
            try {
                this.cityReport = this.officeReport = false;
                await window.UIkit.modal('#download-report-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async closeReportModal() {
            try {
                if (this.cityReport || this.officeReport) {
                    this.cityReport = this.officeReport = false;
                } else {
                    await window.UIkit.modal('#download-report-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        setMonth({ event }) {
            this.reportMonth = this.monthOptions.find((month) => month.name == event.target.value);
        },
        async doDownloadShiftReport({ type, data }) {
            try {
                const month = moment(this.reportMonth.value).set('year', this.reportYear).format("YYYY-MM");
                let payload = { month };
                const days = moment().month(month).daysInMonth();

                let placeType = '', placeName = '';
                if (type === 'city') {
                    placeType = 'City';
                    if (data) {
                        payload = {
                            ...payload,
                            domicile_city_id: data._id || null
                        };
                        placeName = data.name || 'All cities';
                    } else {
                        placeName = 'All cities';
                    }
                } else {
                    placeType = 'Office';
                    if (data) {
                        payload = {
                            ...payload,
                            company_office_id: data._id || null
                        };
                        placeName = data.name || 'All offices';
                    } else {
                        placeName = 'All offices';
                    }
                }

                const monthlyUserReport = await this.getWeeklyShiftMonthlyReport(payload);
                const dataExcel = [];
                monthlyUserReport.forEach(mitra => {
                    let obj = {
                        "Fullname": mitra.fullname,
                        "Job Role": mitra.role_id,
                        "Mitra ID": mitra.employee_id,
                        "No KTP": mitra.identity_number,
                    };

                    for (let i = 1; i <= days; i++) {
                        obj[i+" "] = mitra.shift_per_day[i] || '';
                    }

                    dataExcel.push(obj);
                });
                excelDownloader(dataExcel, `Shift_${placeType}_${placeName}_${this.reportMonth.name}.xls`);
            } catch (error) {
                notificationDanger(error);
            }
        },
        mapQuery() {
            if (this.$route.query.company_office_id) {
                this.formFilterShift.company_office_id = this.meta.company_office_id = this.$route.query.company_office_id;
                this.filterOffice();
            }
            if (this.$route.query.domicile_city_id) {
                this.formFilterShift.domicile_city_id = this.$route.query.domicile_city_id;
            }
        }
    }
};
</script>

<style scoped>
thead {
    background: #0ABAB5;
}

th {
    color: #FFFFFF;
}

.myrobin-button-primary {
    border: 1px solid #0275D8;
    color: #0275D8;
    background-color: white;
}
</style>
