import api from '@/utils/api';
import { API_REPORT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getMitraReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.MITRA, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getOfficeReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.OFFICE_LIST, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getInitJobReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.INITIATION, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getInitFulfillmentReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.INITIATION_FULFILLMENT, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDailyRecapWorkerAttendanceList(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.DAILY_RECAP_WORKER_ATTENDANCE_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getReimbursementReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.REIMBURSEMENT, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSummaryAttendanceList(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.SUMMARY_ATTENDANCE_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSummaryAttendanceDetail(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.SUMMARY_ATTENDANCE_DETAIL(data.id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSummaryAttendanceWorkerList(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.SUMMARY_ATTENDANCE_WORKER_LIST(data.id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSummaryAttendanceWorkerListReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.SUMMARY_ATTENDANCE_WORKER_LIST_REPORT(data.id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDailyAttendanceReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.DAILY_ATTENDANCE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserAttendanceReport(context, data) {
        try {
            const response = await api.apiPostAuth(API_REPORT.USER_ATTENDANCE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWeeklyShiftMonthlyReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.WEEKLY_SHIFT_REPORT, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
