<template>
    <div 
        class="uk-card uk-card-default uk-card-body uk-padding-remove uk-border-rounded uk-margin-small-top custom-card"
        :class="[selectedApplicant ? 'border-blue' : '']"
    >
        <div v-if="showCheckbox" class="uk-padding-small">
            <label :class="[selectedApplicant ? 'color-blue' : '']">
                <input 
                    class="uk-checkbox uk-border-rounded uk-margin-small-right" 
                    type="checkbox"
                    :checked="selectedApplicant"
                    @change="onChangeSelectApplicant"
                >
                <span>Select Applicant</span>
            </label>
        </div>
        <div class="uk-flex">
            <div
                class="uk-width-1-4 uk-flex uk-flex-column uk-padding-small personal-data-bg"
                :class="[applicant.partner ? 'had-partner' : '']"
            >
                <div class="uk-flex uk-flex-row uk-height-80px">
                    <img
                        v-if="
                            applicant.user.photo_url &&
                            applicant.user.photo_url.file_url
                        "
                        :data-src="applicant.user.photo_url.file_url"
                        alt="Avatar Icon"
                        @error="(e) => imgError(e)"
                        uk-img
                        class="photo"
                    />
                    <img
                        v-else
                        :data-src="`${baseUrlImg}/avatar.svg`"
                        alt="Avatar Icon"
                        uk-img
                        class="photo"
                    />
                    <div class="uk-flex uk-flex-column uk-margin-small-left">
                        <p class="applicant-name uk-text-bold uk-margin-small-bottom">
                            {{ applicant.user.fullname || '-'}}
                        </p>
                        <div
                            class="uk-text-bold uk-border-rounded uk-text-center label-status"
                            :class="[
                                [0].includes(applicant.status)
                                    ? 'bg-warning'
                                    : '',
                                [1, 4, 5].includes(applicant.status)
                                    ? 'bg-primary'
                                    : '',
                                [6, 7].includes(applicant.status)
                                    ? 'bg-success'
                                    : '',
                                [3].includes(applicant.status)
                                    ? 'bg-danger'
                                    : '',
                                applicant.partner ? 'bg-danger': ''
                            ]"
                        >
                            {{
                                applicant.partner ? 'Accepted' :
                                convertStatusApplicant({
                                    status: applicant.status
                                })
                            }}
                        </div>
                    </div>
                </div>
                <hr class="uk-margin-small-bottom" />
                <div class="uk-flex uk-flex-column" style="gap: 12px">
                    <div class="uk-flex uk-flex-row text-f14-l24 uk-text-default uk-text-500">
                        <span class="uk-flex uk-flex-center icon">
                            <img
                                :src="`${baseUrlImg}/job_applicant/detail/telephone-black.svg`"
                                alt=""
                            />
                        </span>
                        {{ applicant.user.phone_number || '-'}}
                        <span class="uk-margin-auto-left send-wa-btn" @click="sendWhatsApp">
                            <img
                                :src="`${baseUrlImg}/whatsapp-icon.svg`"
                                alt="Whatsapp Icon"
                            />
                        </span>
                    </div>
                    <div class="uk-flex uk-flex-row text-f14-l24 uk-text-default uk-text-500">
                        <span class="uk-flex uk-flex-center icon">
                            <img
                                :src="`${baseUrlImg}/job_applicant/detail/email-black.svg`"
                                alt=""
                            />
                        </span>
                        {{
                            validateText({
                                text: applicant.user.email
                            })
                        }}
                    </div>
                    <div class="uk-flex uk-flex-row text-f14-l24 uk-text-default uk-text-500">
                        <span class="uk-flex uk-flex-center icon">
                            <img
                                :src="`${baseUrlImg}/job_applicant/detail/location-black.svg`"
                                alt=""
                            />
                        </span>
                        {{  applicant.user.domicile.name ?
                            toTitleCase({ text: applicant.user.domicile.name }) : '-'
                        }}
                    </div>
                    <div class="uk-flex uk-flex-row text-f14-l24 uk-text-default uk-text-500">
                        <span class="uk-flex uk-flex-center icon">
                            <img
                                :src="`${baseUrlImg}/job_applicant/detail/gender-black.svg`"
                                alt=""
                            />
                        </span>
                        {{
                            applicant.user.gender === 'male'
                                ? 'Laki-laki'
                                : 'Perempuan'
                        }}
                    </div>
                    <div class="uk-flex uk-flex-row text-f14-l24 uk-text-default uk-text-500">
                        <span class="uk-flex uk-flex-center icon">
                            <img
                                :src="`${baseUrlImg}/job_applicant/detail/birthday-cake-black.svg`"
                                alt=""
                            />
                        </span>
                        {{
                            applicant.user_age
                                ? applicant.user_age + ' Tahun'
                                : '-'
                        }}
                    </div>
                    <div class="uk-flex uk-flex-row text-f14-l24 uk-text-default uk-text-500">
                        <span class="uk-flex uk-flex-center icon">
                            <img
                                :src="`${baseUrlImg}/job_applicant/detail/identity-number-black.svg`"
                                alt=""
                            />
                        </span>
                        {{ applicant.user.identity_number || '-'}}
                    </div>
                </div>
                <hr />
                <div class="uk-flex uk-flex-middle">
                    <p class="uk-margin-remove text-f12-l24 uk-text-default uk-text-500">
                        {{ 'Applicant Source: ' + (applicant.apply_from || '-') }}
                    </p>
                </div>
            </div>
            <div class="uk-width-3-4 uk-flex uk-flex-column">
                <div class="uk-flex uk-flex-column" style="position: relative;">
                    <div 
                        class="box uk-padding-small uk-padding-remove-bottom" 
                        :class="[applicant.partner ? 'blur' : '']"
                        style="position: inherit;"
                    >
                        <div class="uk-flex uk-flex-column uk-height-80px">
                            <div class="uk-flex uk-flex-row uk-flex-between">
                                <p
                                    class="uk-margin-remove uk-text-bold uk-text-default"
                                    style="font-size: 16px"
                                >
                                    Applicant for
                                    <a href="javascript:void(0)" @click="gotoJobDetail(applicant.job._id)" class="uk-text-bold uk-text-primary">{{
                                        applicant.job.title || '-'
                                    }}</a>
                                </p>
                                <div
                                    class="uk-text-bold uk-border-rounded uk-text-center label-status"
                                    :class="[
                                        applicant.initiation.status === 0
                                            ? 'bg-vacancy-success'
                                            : '',
                                        applicant.initiation.status === 1
                                            ? 'bg-vacancy-warning'
                                            : '',
                                        applicant.initiation.status === 2
                                            ? 'bg-vacancy-danger'
                                            : ''
                                    ]"
                                >
                                    {{
                                        'Status Vacancy: ' +
                                        convertStatusVacancy({
                                            status: applicant.initiation.status
                                        })
                                    }}
                                </div>
                            </div>
                            <div class="uk-flex uk-flex-row" style="margin-top: 20px">
                                <div class="uk-width-1-2 uk-flex uk-flex-column">
                                    <div class="uk-flex uk-flex-row">
                                        <div class="uk-width-1-2 text-f12-l18">
                                            Apply Date
                                        </div>
                                        <div
                                            class="uk-width-1-2 uk-text-default uk-text-bold text-f12-l18"
                                        >
                                            :
                                            {{
                                                applicant.apply_date
                                                    ? applicant.apply_date
                                                    : ' -' | moment('DD MMM YYYY')
                                            }}
                                        </div>
                                    </div>
                                    <div class="uk-flex uk-flex-row">
                                        <div class="uk-width-1-2 text-f12-l18">
                                            Apply to Company
                                        </div>
                                        <div
                                            class="uk-width-1-2 uk-text-default uk-text-bold text-f12-l18"
                                        >
                                            :
                                            {{
                                                (applicant.job &&
                                                    applicant.job.company) ||
                                                '-'
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-2 uk-flex uk-flex-column">
                                    <div class="uk-flex uk-flex-row">
                                        <div class="uk-width-1-2 text-f12-l18">
                                            Start Date Vacancy
                                        </div>
                                        <div
                                            class="uk-width-1-2 uk-text-default uk-text-bold text-f12-l18"
                                        >
                                            :
                                            {{
                                                applicant &&
                                                applicant.initiation &&
                                                applicant.initiation.start_date
                                                    ? applicant.initiation.start_date
                                                    : ' -' | moment('D MMM YYYY')
                                            }}
                                        </div>
                                    </div>
                                    <div class="uk-flex uk-flex-row">
                                        <div class="uk-width-1-2 text-f12-l18">
                                            Due Date Vacancy
                                        </div>
                                        <div
                                            class="uk-width-1-2 uk-text-default uk-text-bold text-f12-l18"
                                        >
                                            :
                                            {{
                                                applicant &&
                                                applicant.initiation &&
                                                applicant.initiation.due_date
                                                    ? applicant.initiation.due_date
                                                    : ' -' | moment('D MMM YYYY')
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="uk-margin-small-bottom" />
                        <div class="uk-flex" style="height: 204px">
                            <div class="uk-width-1-2 uk-flex uk-flex-column">
                                <div class="uk-flex uk-flex-row">
                                    <div class="uk-width-small">
                                        <p class="text-f12-l18 myrobin-text-949494">
                                            Highest Education
                                        </p>
                                    </div>
                                    <div class="uk-flex uk-flex-column uk-width-auto">
                                        <p
                                            class="uk-margin-remove text-f12-l18 uk-text-bold uk-text-default"
                                        >
                                            {{
                                                applicant.user.education_level &&
                                                applicant.user.education_level.name
                                                    ? applicant.user.education_level
                                                          .name
                                                    : '-'
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-row uk-margin-small-bottom">
                                    <div class="uk-width-small">
                                        <p class="text-f12-l18 myrobin-text-949494">
                                            Latest Job
                                        </p>
                                    </div>
                                    <div
                                        v-if="
                                            applicant &&
                                            applicant.cv &&
                                            applicant.cv.work_experiences &&
                                            applicant.cv.work_experiences.length > 0
                                        "
                                        class="uk-flex uk-flex-column uk-width-auto"
                                    >
                                        <p
                                            class="uk-margin-remove text-f12-l24 uk-text-bold uk-text-default"
                                        >
                                            {{ applicant.cv.work_experiences[0].title || '-' }}
                                        </p>
                                        <p
                                            class="uk-margin-remove text-f12-l18 uk-text-default"
                                        >
                                            {{
                                                applicant.cv.work_experiences[0]
                                                    .company_name || '-'
                                            }}
                                        </p>
                                        <p
                                            class="uk-margin-remove myrobin-text-949494 text-f12-l18"
                                        >
                                            {{
                                                applicant.cv.work_experiences[0]
                                                    .start_date || ' -' | moment('MMMM YYYY')
                                            }}
                                            -
                                            {{
                                                applicant.cv.work_experiences[0].present
                                                    ? 'Present'
                                                    : applicant.cv.work_experiences[0]
                                                          .end_date || ' -'
                                                      | moment('MMMM YYYY')
                                            }}
                                        </p>
                                        <p
                                            class="uk-margin-remove text-f12-l18 uk-text-default"
                                        >
                                            {{
                                                applicant &&
                                                applicant.cv &&
                                                applicant.cv.work_experiences &&
                                                applicant.cv.work_experiences.length > 0
                                                    ? getYearsExperiences({
                                                          data: applicant.cv
                                                              .work_experiences,
                                                          limit: 1
                                                      })
                                                    : '-'
                                            }}
                                        </p>
                                    </div>
                                    <div
                                        v-else
                                        class="uk-flex uk-flex-column uk-width-auto"
                                    >
                                        <p
                                            class="uk-margin-remove text-f12-l24 uk-text-default"
                                        >
                                            {{ '-' }}
                                        </p>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-row">
                                    <div class="uk-width-small">
                                        <p class="text-f12-l18 myrobin-text-949494">
                                            Years of Professional Experience
                                        </p>
                                    </div>
                                    <div class="uk-flex uk-flex-column uk-width-auto">
                                        <p
                                            class="uk-margin-remove text-f12-l18 uk-text-bold uk-text-default"
                                        >
                                            {{
                                                applicant &&
                                                applicant.cv &&
                                                applicant.cv.work_experiences &&
                                                applicant.cv.work_experiences.length > 0
                                                    ? getYearsExperiences({
                                                          data: applicant.cv
                                                              .work_experiences
                                                      })
                                                    : '-'
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-width-1-2 uk-flex uk-flex-column">
                                <div class="uk-flex uk-flex-row" style="height: 145px">
                                    <div class="uk-width-1-2 uk-flex uk-flex-column">
                                        <a
                                            href="javascript:void(0)"
                                            class="uk-text-bold text-f12-l24 uk-text-primary"
                                            @click="openRequiredDocModal"
                                            >Form of identifications</a
                                        >
                                        <div class="uk-width-auto uk-flex">
                                            <img
                                                :src="[
                                                    applicant.cv.ktp &&
                                                    applicant.cv.ktp.document_url &&
                                                    applicant.cv.ktp.document_url
                                                        .file_url
                                                        ? `${baseUrlImg}/check-circle.svg`
                                                        : `${baseUrlImg}/cross-circle.svg`
                                                ]"
                                                class="uk-margin-small-right"
                                                width="20"
                                            />
                                            <p
                                                class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                            >
                                                KTP
                                            </p>
                                        </div>
                                        <div class="uk-width-auto uk-flex">
                                            <img
                                                :src="[
                                                    applicant.cv.kk &&
                                                    applicant.cv.kk.document_url &&
                                                    applicant.cv.kk.document_url
                                                        .file_url
                                                        ? `${baseUrlImg}/check-circle.svg`
                                                        : `${baseUrlImg}/cross-circle.svg`
                                                ]"
                                                class="uk-margin-small-right"
                                                width="20"
                                            />
                                            <p
                                                class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                            >
                                                KK
                                            </p>
                                        </div>
                                        <div class="uk-width-auto uk-flex">
                                            <img
                                                :src="[
                                                    applicant.cv.sim &&
                                                    applicant.cv.sim.document_url &&
                                                    applicant.cv.sim.document_url
                                                        .file_url
                                                        ? `${baseUrlImg}/check-circle.svg`
                                                        : `${baseUrlImg}/cross-circle.svg`
                                                ]"
                                                class="uk-margin-small-right"
                                                width="20"
                                            />
                                            <p
                                                class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                            >
                                                SIM
                                            </p>
                                        </div>
                                        <div class="uk-width-auto uk-flex">
                                            <img
                                                :src="[
                                                    applicant.cv.ijazah &&
                                                    applicant.cv.ijazah.document_url &&
                                                    applicant.cv.ijazah.document_url
                                                        .file_url
                                                        ? `${baseUrlImg}/check-circle.svg`
                                                        : `${baseUrlImg}/cross-circle.svg`
                                                ]"
                                                class="uk-margin-small-right"
                                                width="20"
                                            />
                                            <p
                                                class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                            >
                                                Ijazah
                                            </p>
                                        </div>
                                        <div class="uk-width-auto uk-flex">
                                            <img
                                                :src="[
                                                    applicant.cv.skck &&
                                                    applicant.cv.skck.document_url &&
                                                    applicant.cv.skck.document_url
                                                        .file_url
                                                        ? `${baseUrlImg}/check-circle.svg`
                                                        : `${baseUrlImg}/cross-circle.svg`
                                                ]"
                                                class="uk-margin-small-right"
                                                width="20"
                                            />
                                            <p
                                                class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                            >
                                                SKCK
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        v-if="applicant.job.additional_docs.length > 0"
                                        class="uk-width-1-2 uk-flex uk-flex-column"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            class="uk-text-bold text-f12-l24 uk-text-primary"
                                            @click="openAdditionalDocModal"
                                            >More additional documents</a
                                        >
                                        <div v-if="applicant.job.additional_docs.length > 4">
                                            <div
                                                v-for="index in 4"
                                                :key="index - 1"
                                                class="uk-width-auto uk-flex"
                                            >
                                                <img
                                                    :src="[
                                                        applicant.job.additional_docs[
                                                            index - 1
                                                        ] &&
                                                        applicant.job.additional_docs[
                                                            index - 1
                                                        ].document_url &&
                                                        applicant.job.additional_docs[
                                                            index - 1
                                                        ].document_url.file_url
                                                            ? `${baseUrlImg}/check-circle.svg`
                                                            : `${baseUrlImg}/cross-circle.svg`
                                                    ]"
                                                    class="uk-margin-small-right"
                                                    width="20"
                                                />
                                                <p
                                                    class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                                >
                                                    {{
                                                        applicant.job.additional_docs[
                                                            index - 1
                                                        ].name
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div
                                                v-for="(item, index) in applicant.job.additional_docs"
                                                :key="index - 1"
                                                class="uk-width-auto uk-flex"
                                            >
                                                <img
                                                    :src="[
                                                        item &&
                                                        item.document_url &&
                                                        item.document_url.file_url
                                                            ? `${baseUrlImg}/check-circle.svg`
                                                            : `${baseUrlImg}/cross-circle.svg`
                                                    ]"
                                                    class="uk-margin-small-right"
                                                    width="20"
                                                />
                                                <p
                                                    class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                                >
                                                    {{
                                                        item.name
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                applicant.job.additional_docs.length > 4
                                            "
                                            class="uk-width-auto uk-flex"
                                            style="margin-left: 30px"
                                        >
                                            <p
                                                class="text-f12-l24 uk-text-default uk-margin-remove uk-text-bold align-self-center"
                                            >
                                                {{
                                                    '+' +
                                                    (applicant.job.additional_docs
                                                        .length -
                                                        4) +
                                                    ' more documents'
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr class="uk-margin-small-top" />
                                <div
                                    class="uk-flex uk-flex-middle"
                                    style="height: 50px"
                                >
                                    <a
                                        v-if="applicant.question_answers.length > 0"
                                        href="javascript:void(0)"
                                        class="uk-text-bold text-f12-l24 uk-text-primary"
                                        @click="openAdditionalQuestion(applicant.question_answers)"
                                        >Additional Questions</a
                                    >
                                </div>
                            </div>
                        </div>
                        <hr class="uk-margin-remove-bottom" />
                    </div>
                    <div v-show="applicant.partner" class="box stack-top uk-flex uk-flex-center uk-flex-middle">
                        <div class="uk-flex uk-flex-column">
                            <img class="uk-align-center" :src="`${baseUrlImg}/job_applicant/detail/briefcase.svg`" width="140px">
                            <p :uk-tooltip="`This applicant has already been accepted by ${applicant.partner}`" class="text-f14-l21 uk-text-bold uk-margin-remove partner-notes">
                                {{formatter.trimWords(`This applicant has already been accepted by ${applicant.partner}`, 12)}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="uk-flex uk-margin-small-top uk-padding-small uk-padding-remove-vertical">
                    <div class="uk-margin-small-right">
                        <button
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledViewProfileButton ? 'action-btn-disabled' : 'myrobin-background-primary myrobin-color-white']"
                            :disabled="disabledViewProfileButton"
                            style="background-color: #66BB6A;"
                            @click="viewDetailApplicant(applicant)"
                        >
                            View Profile
                            <span>
                                <img
                                    class="icon-white"
                                    :src="`${baseUrlImg}/job_applicant/visibility-white.svg`"
                                    width="18px"
                                    height="18px"
                                />
                            </span>
                        </button>
                    </div>
                    <div class="uk-margin-small-right">
                        <button
                            v-if="button_download_cv_loading"
                            class="uk-button uk-button-default uk-border-pill uk-flex uk-flex-middle uk-flex-center"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </button>
                        <button
                            v-else
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledDownloadCvButton ? 'action-btn-disabled' : 'myrobin-background-primary myrobin-color-white']"
                            :disabled="disabledDownloadCvButton"
                            @click="downloadCV({ userId: applicant.user._id })"
                        >
                            Download CV
                        </button>
                    </div>
                    <div v-if="applicant && [0,1,4,5,3].includes(applicant.status)" class="uk-margin-small-right">
                        <button
                            v-if="applicant && applicant.status === 0"
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledPickupApplicantButton ? 'action-btn-disabled' : 'myrobin-background-secondary myrobin-color-white']"
                            :disabled="disabledPickupApplicantButton"
                            @click="moveUser({state: 1, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                        >
                            Pickup Applicant
                            <span>
                                <img
                                    class="icon-white forward"
                                    :src="`${baseUrlImg}/job_applicant/arrow-right-white.svg`"
                                    alt="Pickup Applicant"
                                    width="18px"
                                    height="18px"
                                />
                            </span>
                        </button>
                        <button
                            v-else-if="applicant && applicant.status === 1"
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledInviteToInterviewButton ? 'action-btn-disabled' : 'myrobin-background-secondary myrobin-color-white']"
                            :disabled="disabledInviteToInterviewButton"
                            @click="moveUser({state: 4, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                        >Invite to Interview
                            <span>
                                <img class="icon-white forward" :src="`${baseUrlImg}/job_applicant/arrow-right-white.svg`" alt="Invite to Interview" width="18px" height="18px"/>
                            </span>
                        </button>
                        <button
                            v-else-if="applicant && applicant.status === 4"
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledInterviewedApplicant ? 'action-btn-disabled' : 'myrobin-background-secondary myrobin-color-white']"
                            :disabled="disabledInterviewedApplicant"
                            @click="moveUser({state: 5, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                        >Interviewed Applicant
                            <span>
                                <img class="icon-white forward" :src="`${baseUrlImg}/job_applicant/arrow-right-white.svg`" alt="Interviewed Applicant" width="18px" height="18px"/>
                            </span>
                        </button>
                        <button
                            v-else-if="applicant && applicant.status === 3"
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledPickupApplicantButton ? 'action-btn-disabled' : 'myrobin-background-secondary myrobin-color-white']"
                            :disabled="disabledPickupApplicantButton"
                            @click="moveUser({state: 1, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                        >
                            <span>
                                <img class="icon-white backward" :src="`${baseUrlImg}/job_applicant/arrow-left-white.svg`" alt="Pickup Applicant" width="18px" height="18px"/>
                            </span>
                            Pickup Applicant
                        </button>
                    </div>
                    <div v-if="applicant && [0,1,4,5,3].includes(applicant.status)" class="uk-margin-small-right">
                        <button
                            v-if="applicant && [0,1,4,5].includes(applicant.status)"
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledSendContractButton ? 'action-btn-disabled' : 'myrobin-background-success myrobin-color-white']"
                            :disabled="disabledSendContractButton"
                            @click="moveUser({state: 6, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                        >Send Contract
                            <span>
                                <img class="icon-white forward" :src="`${baseUrlImg}/job_applicant/forward-white.svg`" alt="Send Contract" width="18px" height="18px"/>
                            </span>
                        </button>
                        <button
                            v-if="applicant && [3].includes(applicant.status)"
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledInviteToInterviewButton ? 'action-btn-disabled' : 'myrobin-background-success myrobin-color-white']"
                            :disabled="disabledInviteToInterviewButton"
                            @click="moveUser({state: 4, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                        >
                            <span>
                                <img class="icon-white backward" :src="`${baseUrlImg}/job_applicant/backward-white.svg`" alt="Invite to Interview" width="18px" height="18px"/>
                            </span>
                            Invite to Interview
                        </button>
                    </div>
                    <div v-if="applicant && [0,1,4,5].includes(applicant.status)" class="uk-margin-small-right">
                        <button
                            v-if="applicant && [0,1,4,5].includes(applicant.status)"
                            class="uk-button uk-button-default uk-border-pill padding-18px uk-padding-remove-vertical"
                            :class="[disabledRejectApplicantButton ? 'action-btn-disabled' : 'myrobin-background-danger myrobin-color-white']"
                            :disabled="disabledRejectApplicantButton"
                            @click="moveUser({state: 3, job_apply_id: applicant._id, office_id: applicant.initiation.office_id})"
                        >Reject Applicant
                            <span>
                                <img class="icon-white forward" :src="`${baseUrlImg}/job_applicant/reject-white.svg`" alt="Reject Applicant" width="18px" height="18px"/>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from '../../../../../utils/formatter';

export default {
    name: 'ApplicantItem',
    props: {
        applicant: {
            type: Object,
            required: true,
            default: null
        },
        showCheckbox: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedApplicant: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledViewProfileButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledDownloadCvButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledPickupApplicantButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInviteToInterviewButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInterviewedApplicant: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledSendContractButton: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledRejectApplicantButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            baseUrlImg: PREFIX_IMAGE,
            button_download_cv_loading: false,
            formatter: formatter
        };
    },
    computed: {
        openRequiredDoc(){
            let data = false;
            if (this.applicant.cv.ktp || this.applicant.cv.kk || 
                this.applicant.cv.sim || this.applicant.cv.ijazah || 
                this.applicant.cv.skck
            ) {
                data = true;
            }
            return data;
        }
    },
    methods: {
        validateText({ text, limit = 34 }) {
            let data = text;
            if (data.length > limit) {
                data = data.substring(0, limit) + '...';
            }
            return data;
        },
        toTitleCase({ text }) {
            return formatter.toTitleCase(text);
        },
        imgError(e) {
            e.target.src = `${this.baseUrlImg}/avatar.svg`;
        },
        getYearAndMonth({ months }) {
            let m = months % 12;
            let y = (months - m) / 12;

            return `${y} Years ${m} Months`;
        },
        getYearsExperiences({ data, limit = null }) {
            let month = 0;
            let r = '';
            let length = limit ? limit : data.length;
            for (let i = 0; i < length; i++) {
                const lastDate = data[i].end_date
                    ? data[i].end_date
                    : new Date();
                month += formatter.diffMonths(data[i].start_date, lastDate);
            }
            r = this.getYearAndMonth({ months: month });
            return r;
        },
        convertStatusApplicant({ status }) {
            switch (status) {
            case 0:
                return 'New Applicant';
            case 1:
                return 'Picked up Applicant';
            case 3:
                return 'Rejected';
            case 4:
                return 'Invited to Interview';
            case 5:
                return 'Interviewed Applicant';
            case 6:
                return 'Contract Sent';
            case 7:
                return 'Signed Applicant';
            }
        },
        convertStatusVacancy({ status }) {
            switch (status) {
            case 0:
                return 'In Progress';
            case 1:
                return 'Submitted';
            case 2:
                return 'Completed/Closed';
            }
        },
        downloadCV({ userId }) {
            this.button_download_cv_loading = true;
            this.$emit('download-cv', {
                userId,
                done: () => {
                    this.button_download_cv_loading = false;
                }
            });
        },
        moveUser({state, job_apply_id, office_id}){
            this.$emit('change-status', {state, job_apply_id, office_id});
        },
        viewDetailApplicant(user) {
            let route = this.$router.resolve({path: `/admin/job_applicant/candidate/detail/${user._id}`});
            window.open(route.href, '_blank');
        },
        gotoJobDetail(job_id){
            this.$router.push({path: `/admin/job_applicant/detail/${this.$route.params.province_id}/${job_id}`});
        },
        openAdditionalQuestion(question){
            this.$emit('open-additional-question', question);
        },
        redirectToDetailDocument(user){
            let route = this.$router.resolve({path: `/admin/job_applicant/candidate/detail/${user._id}`, query: {accordion: 'document-qualifications'}});
            window.open(route.href, '_blank');
        },
        sendWhatsApp(){
            this.$emit('send-whatsapp', this.applicant);
        },
        openRequiredDocModal(){
            if (this.openRequiredDoc) {
                this.$emit('open-required-docs', this.applicant);
            }
        },
        openAdditionalDocModal(){
            this.$emit('open-additional-docs', this.applicant);
        },
        onChangeSelectApplicant() {
            this.$emit('select-applicant', { _id: this.$props.applicant?._id });
        }
    }
};
</script>

<style scoped>
.uk-height-80px {
    height: 80px;
}
.personal-data-bg {
    background: #e6f4f8;
    border-radius: inherit;
}
hr {
    border-color: #afafaf;
}
.remove-border-radius-right {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.photo {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}
.label-status {
    width: fit-content;
    padding: 4px 8px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}
.bg-warning {
    background: #ffe500;
}
.bg-primary {
    color: white;
    background: #3586ff;
}
.bg-success {
    color: white;
    background: #00b500;
}
.bg-danger {
    color: white;
    background: #df5c70;
}
.bg-vacancy-success {
    color: white;
    background: #22cc8e;
}
.bg-vacancy-warning {
    color: white;
    background: #febe10;
}
.bg-vacancy-danger {
    color: white;
    background: #df5c70;
}
.applicant-name {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
span.icon {
    align-items: center;
    margin-right: 0.7rem;
}

.myrobin-background-primary {
    background-color: #0abab5;
}

.myrobin-background-success {
    background-color: #22cc8e;
}
.myrobin-background-danger {
    background-color: #df5c70;
}
.myrobin-background-secondary {
    background-color: #0275d8;
}

.myrobin-color-white {
    color: white;
}
.icon-white {
    fill: #ffffff;
}
.forward {
    margin-left: 5px;
}
.backward {
    margin-right: 5px;
}
.padding-18px {
    padding: 18px;
}
.send-wa-btn:hover {
    cursor: pointer;
}
.box {
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 0;
    left: 0;
}
.stack-top {
    background: #000;
    opacity: 0.5;
    z-index: 9;
}
.blur {
    filter: blur(2px);
    backdrop-filter: blur(2px);
}
.had-partner {
    background: rgba(223, 92, 112, 0.25);
}
p.partner-notes {
    color: #FFFFFF;
}

.custom-card {
    background: #F4F4F4 !important;
    overflow: hidden !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
}

.border-blue {
    border: 2px solid #0275D8 !important;
}

.color-blue {
    color: #0275D8 !important;
}

.action-btn-disabled {
    background: #C2C3C7 !important;
    color: white !important;
    cursor: not-allowed !important;
}
</style>
