<template>
    <div class="uk-container uk-container-expand uk-margin-top">
        <div class="uk-flex uk-flex-column">
            <div class="uk-flex uk-margin uk-child-width-expand@s" uk-grid>
                <div class="uk-width-1-1 uk-flex uk-flex-column">
                    <div class="uk-flex uk-flex-row">
                        <div class="uk-width-1-3 uk-margin-small-bottom">
                            <multiselect
                                v-model="selectedVacancy"
                                class="uk-border-pill uk-margin-small-bottom"
                                id="job"
                                name="job"
                                label="title"
                                :options="job_list"
                                :options-limit="1000"
                                :searchable="true"
                                :internal-search="true"
                                :multiple="false"
                                track-by="_id"
                                :allowEmpty="false"
                                :show-labels="false"
                                @select="onSelectVacancy">
                                <span slot="noResult">Data not found</span>
                            </multiselect>
                        </div>
                        <div class="uk-width-auto uk-margin-small-left">
                            <button
                                class="uk-button button-filter uk-button-default uk-padding-small uk-padding-remove-vertical"
                                :class="[isHaveFilterVacancy? 'active': '']"
                                @click="openModal({modalName: 'isShowFilterVacancy'})"
                            >
                                <i class="icon-filter uk-margin-small-right"></i>
                                <span>Filter Vacancy</span>
                            </button>
                            <span v-if="isHaveFilterVacancy" class="uk-badge badge-filter uk-margin-small-left">Vacancy filters applied</span>
                        </div>
                    </div>
                    <div class="uk-flex uk-flex-row">
                        <div class="uk-inline uk-width-1-3">
                            <span class="uk-form-icon" uk-icon="icon: search"></span>
                            <input @input="mainFilter('fullname')" v-model="filter.fullname" class="uk-input uk-border-pill" placeholder="Search Applicant Name...">
                        </div>
                        <div class="uk-width-auto uk-margin-small-left">
                            <button
                                class="uk-button button-filter uk-button-default uk-padding-small uk-padding-remove-vertical"
                                :class="[isHaveFilterApplicant ? 'active' : '']"
                                @click="openModal({modalName: 'isShowFilterApplicantModal'})"
                            >
                                <i class="icon-filter uk-margin-small-right"></i>
                                <span>Filter Applicant</span>
                            </button>
                            <span v-if="isHaveFilterApplicant" class="uk-badge badge-filter uk-margin-small-left">Applicant filters applied</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-flex uk-flex-row">
                <div class="uk-width-3-6 uk-flex uk-flex-column uk-margin-small-right" style="align-self: center;">
                    <hr class="uk-margin-remove"/>
                </div>
                <div class="uk-width-4-5 uk-flex uk-flex-row uk-margin-small-bottom">
                    <button 
                        type="button" 
                        class="switcher switcher-left uk-padding-small uk-padding-remove-vertical" 
                        :class="selectedView == 'table' ? 'active':''"
                        @click="switchView('table')">
                        <i 
                            class="table-view-icon"
                            :class="selectedView == 'table' ? 'active': ''"
                        ></i> Table View
                    </button>
                    <button 
                        type="button" 
                        class="switcher switcher-right uk-padding-small uk-padding-remove-vertical" 
                        :class="selectedView == 'card' ? 'active':''"
                        @click="switchView('card')">
                        <i class="card-view-icon" :class="selectedView == 'card' ? 'active': ''"></i> Card View
                    </button>
                    <div class="uk-width-auto uk-margin-small-left uk-margin-small-right uk-text-bold" style="align-self: center;">
                        Sort By:
                    </div>
                    <div class="uk-width-expand">
                        <select class="uk-select uk-border-pill option-select" v-model="filter.sorting" @change="isSortedChanged">
                            <option value="0">Latest Apply Date</option>
                            <option value="1">Oldest Apply Date</option>
                            <option value="2">A-Z</option>
                            <option value="3">Z-A</option>
                        </select>
                    </div>
                </div>
            </div>
            <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                <div uk-spinner="ratio: 2"></div>
            </div>
            <template v-else-if="!isLoading">
                <div v-if="isShowMultipleSelectApplicant && job_all_applicant_list_v4.length > 0" class="uk-flex uk-flex-middle uk-flex-between uk-padding-small">
                    <div class="uk-flex uk-flex-left uk-flex-middle">
                        <p v-if="selectedApplicantIds.length > 0" class="uk-margin-remove">{{ selectedApplicantIds.length }} Applicant(s) selected</p>
                        <p v-else class="uk-margin-remove">No Applicant(s) selected</p>
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-border-pill uk-margin-left button-select-all"
                            @click="selectAllApplicant"
                        >
                            <span class="uk-margin-small-right">Select All</span>
                            <img
                                src="/images/select-all-blue.svg"
                                alt="Select all icon blue"
                                width="25"
                                loading="lazy"
                            >
                        </button>
                        <button
                            v-if="isShowPickupMultiApplicantButton"
                            type="button"
                            class="uk-button uk-button-default uk-border-pill uk-margin-left"
                            :class="[disabledCancelSelectAllApplicant ? 'btn-disabled' : 'button-pickup-all-applicant']"
                            :disabled="disabledCancelSelectAllApplicant"
                            @click="openModal({ modalName: 'isShowMultiplePickupApplicantModal' })"
                        >
                            <template v-if="reversePickupMultiApplicantStyleButton">
                                <img 
                                    class="icon-white backward" 
                                    src="/images/job_applicant/arrow-left-white.svg"
                                    alt="Pickup Applicant" 
                                    width="18px" 
                                    height="18px"
                                />
                                <span class="uk-margin-small-left">Pickup Applicants</span>
                            </template>
                            <template v-else>
                                <span class="uk-margin-small-right">Pickup Applicants</span>
                                <img
                                    class="icon-white forward"
                                    src="/images/job_applicant/arrow-right-white.svg"
                                    alt="Pickup Applicant"
                                    width="18px"
                                    height="18px"
                                />
                            </template>
                        </button>
                        <button
                            v-if="isShowInviteToInterviewMultiApplicantButton"
                            type="button"
                            class="uk-button uk-button-default uk-border-pill uk-margin-left"
                            :class="[
                                disabledCancelSelectAllApplicant ? 'btn-disabled' : 
                                reverseInviteToInterviewApplicantStyleButton ? 'button-sent-contract-all-applicant' : 'button-pickup-all-applicant'
                            ]"
                            :disabled="disabledCancelSelectAllApplicant"
                            @click="openModal({ modalName: 'isShowMultipleInviteToInterviewApplicantModal' })"
                        >
                            <template v-if="reverseInviteToInterviewApplicantStyleButton">
                                <img 
                                    class="icon-white backward" 
                                    src="/images/job_applicant/backward-white.svg"
                                    alt="Pickup Applicant" 
                                    width="18px" 
                                    height="18px"
                                />
                                <span class="uk-margin-small-left">Invite to Interview</span>
                            </template>
                            <template v-else>
                                <span class="uk-margin-small-right">Invite to Interview</span>
                                <img
                                    class="icon-white forward"
                                    src="/images/job_applicant/arrow-right-white.svg"
                                    alt="Pickup Applicant"
                                    width="18px"
                                    height="18px"
                                />
                            </template> 
                        </button>
                        <button
                            v-if="isShowInterviewedMultiApplicantButton"
                            type="button"
                            class="uk-button uk-button-default uk-border-pill uk-margin-left"
                            :class="[disabledCancelSelectAllApplicant ? 'btn-disabled' : 'button-pickup-all-applicant']"
                            :disabled="disabledCancelSelectAllApplicant"
                            @click="openModal({ modalName: 'isShowMultiInterviewedApplicantModal' })"
                        >
                            <span class="uk-margin-small-right">Interviewed Applicants</span>
                            <img
                                class="icon-white forward"
                                src="/images/job_applicant/arrow-right-white.svg"
                                alt="Pickup Applicant"
                                width="18px"
                                height="18px"
                            />
                        </button>
                        <button
                            v-if="isShowSendContractMultiApplicantButton"
                            type="button"
                            class="uk-button uk-button-default uk-border-pill uk-margin-left button-sent-contract-all-applicant"
                            :class="[disabledCancelSelectAllApplicant ? 'btn-disabled' : 'button-sent-contract-all-applicant']"
                            :disabled="disabledCancelSelectAllApplicant"
                            @click="openModal({ modalName: 'isShowMultipleSentContractApplicantModal' })"
                        >
                            <span class="uk-margin-small-right">Send Contracts</span>
                            <img 
                                class="icon-white forward" 
                                src="/images/job_applicant/forward-white.svg"
                                alt="Send Contract" 
                                width="18px" 
                                height="18px"
                            />
                        </button>
                        <button
                            v-if="isShowRejectMultiApplicantButton"
                            type="button"
                            class="uk-button uk-button-default uk-border-pill uk-margin-left"
                            :class="[disabledCancelSelectAllApplicant ? 'btn-disabled' : 'button-reject-all-applicant']"
                            :disabled="disabledCancelSelectAllApplicant"
                            @click="openModal({ modalName: 'isShowMultipleRejectApplicantModal' })"
                        >
                            <span class="uk-margin-small-right">Reject Applicants</span>
                            <img 
                                class="icon-white forward" 
                                src="/images/job_applicant/reject-white.svg" 
                                alt="Reject Applicant" 
                                width="18px" 
                                height="18px"
                            />
                        </button>
                    </div>
                    <div v-if="selectedApplicantIds.length > 0" class="uk-margin-left">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-border-pill"
                            :class="[disabledCancelSelectAllApplicant ? 'btn-disabled' : 'button-cancel-select-all-applicant']"
                            :disabled="disabledCancelSelectAllApplicant"
                            @click="unselectAllApplicant"
                        >Cancel</button>
                    </div>
                </div>
                <div v-if="applicant_list_v4.docs.length > 0 && job_all_applicant_list_v4.length > 0" class="card-scrollable">
                    <div v-if="selectedView == 'card'">
                        <ApplicantItem
                            v-for="applicant in applicant_list_v4.docs"
                            :key="applicant._id"
                            :applicant="applicant"
                            :show-checkbox="isShowMultipleSelectApplicant"
                            :selected-applicant="findSelectedApplicant({ _id: applicant._id })"
                            :disabled-view-profile-button="disabledActionPerItemButton"
                            :disabled-download-cv-button="disabledActionPerItemButton"
                            :disabled-pickup-applicant-button="disabledActionPerItemButton"
                            :disabled-invite-to-interview-button="disabledActionPerItemButton"
                            :disabled-interviewed-applicant="disabledActionPerItemButton"
                            :disabled-send-contract-button="disabledActionPerItemButton"
                            :disabled-reject-applicant-button="disabledActionPerItemButton"
                            @change-status="moveUser"
                            @download-cv="downloadCV"
                            @open-additional-question="openAdditionalQuestion"
                            @send-whatsapp="sendWhatsApp"
                            @open-required-docs="openRequiredDocsModal"
                            @open-additional-docs="openAdditionalDocsModal"
                            @select-applicant="selectApplicant"
                        />
                    </div>
                    <div v-else-if="selectedView == 'table'" class="wrapper">
                        <table class="uk-table uk-table-middle uk-table-striped uk-padding-left">
                            <thead>
                                <tr class="myrobin-background-primary">
                                    <th class="uk-table-shrink uk-text-nowrap sticky-col-th first-col"></th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white sticky-col-th second-col">Actions</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white sticky-col-th third-col">Applicants Info</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white sticky-col-th fourth-col">Phone & Email</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Vacancy Name</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Applicant Status</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Vacancy Status</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">NIK</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Education</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Last Job</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Years of Experience</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">KTP</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">KK</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">SIM</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Ijazah</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">SKCK</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Additional Documents</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Additional Questions</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Apply Date</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Vacancy Start & Due Date</th>
                                    <th class="uk-table-expand uk-text-middle uk-text-left myrobin-color-white">Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                <TableViewItem
                                    v-for="applicant in applicant_list_v4.docs"
                                    :key="applicant._id"
                                    :applicant="applicant"
                                    :show-checkbox="isShowMultipleSelectApplicant"
                                    :selected-applicant="findSelectedApplicant({ _id: applicant._id })"
                                    :disabled-view-profile-button="disabledActionPerItemButton"
                                    :disabled-download-cv-button="disabledActionPerItemButton"
                                    :disabled-pickup-applicant-button="disabledActionPerItemButton"
                                    :disabled-invite-to-interview-button="disabledActionPerItemButton"
                                    :disabled-interviewed-applicant="disabledActionPerItemButton"
                                    :disabled-send-contract-button="disabledActionPerItemButton"
                                    :disabled-reject-applicant-button="disabledActionPerItemButton"
                                    @change-status="moveUser"
                                    @download-cv="downloadCV"
                                    @open-additional-question="openAdditionalQuestion"
                                    @send-whatsapp="sendWhatsApp"
                                    @open-required-docs="openRequiredDocsModal"
                                    @open-additional-docs="openAdditionalDocsModal"
                                    @select-applicant="selectApplicant"
                                    @open-image="openImage"
                                    @copy="copyText"
                                />
                            </tbody>
                        </table>
                    </div>
                    <pagination
                        v-if="applicant_list_v4.docs.length > 0"
                        :total-data="applicant_list_v4.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                        :limit-list="limitList"
                        :limit-page="filter.limit"
                        :start-page="filter.page"
                    />
                </div>
            </template>
            <div v-show="(!isLoading && (applicant_list_v4.docs.length < 1 && job_all_applicant_list_v4.length < 1))" class="uk-flex uk-flex-column uk-height-large uk-flex-middle">
                <img class="uk-margin-large-top" :src="`${baseUrlImg}/job_applicant/detail/briefcase.svg`">
                <div v-if="isHaveFilterApplicant || isHaveFilterVacancy" class="uk-flex uk-flex-column uk-margin-medium-top">
                    <p class="uk-text-italic text-grey text-f20-l30 uk-text-center">
                        No results found!
                    </p>
                    <p class="uk-text-italic text-grey text-f20-l30 uk-margin-remove uk-text-center">
                        {{'Sorry, there is no data to be shown with your filter settings.'}}
                    </p>
                    <p class="uk-text-italic text-grey text-f20-l30 uk-margin-remove uk-text-center">
                        {{'Please reorganize your filter settings now!'}}
                    </p>
                </div>
                <div v-else class="uk-flex uk-flex-column uk-margin-medium-top">
                    <p class="uk-text-italic text-grey text-f20-l30 uk-text-center">
                        {{'There is no available data for '+ selectedProvince.name +' Province'}}
                    </p>
                    <p class="uk-text-italic text-grey text-f20-l30 uk-margin-remove uk-text-center">
                        {{'Please select another province!'}}
                    </p>
                </div>
            </div>
        </div>

        <ConfirmModal
            v-if="modal.isShowConfirmModal"
            title="Confirmation"
            body="Are you sure want to proceed?"
            @hideModal="modal.isShowConfirmModal = false"
            @confirmProcess="confirmProcess"
        />
        <InterviewModal
            v-if="modal.isSHowInterviewModal"
            :id="selectedJobApply"
            :isMulti="false"
            @hideModal="modal.isSHowInterviewModal = false"
            @onSave="confirmProcess"
        />
        <InterviewDoneModal
            v-if="modal.isSHowDoneInterviewModal"
            :id="selectedJobApply"
            :isMulti="false"
            @hideModal="modal.isSHowDoneInterviewModal = false"
            @onSave="confirmProcess"
        />
        <PassedModal
            v-if="modal.isShowPassedModal"
            :id="selectedJobApply"
            :isMulti="false"
            :office_id="selectedInitiationOffice"
            @hideModal="modal.isShowPassedModal = false"
            @onSave="confirmProcess"
        />
        <RejectedModal
            v-if="modal.isShowRejectedModal"
            :id="selectedJobApply"
            :isMulti="false"
            @hideModal="modal.isShowRejectedModal = false"
            @onSave="confirmProcess"
        />

        <AdditionalQuestionModal
            v-if="modal.isShowAdditionalQuestionModal"
            :questions="selectedUserQuestion"
            @hideModal="modal.isShowAdditionalQuestionModal = false"
        />

        <FilterApplicantModal
            v-if="modal.isShowFilterApplicantModal"
            :applicant-filter="filterApplicant"
            :check-filter-applicant="checkFilterApplicant"
            :button-apply-filter="isHaveFilterApplicant"
            :is-fetching="isFetching"
            :filter-options="filterApplicantOptions"
            @get-city-options="getCityOptions"
            @apply-filter="applyFilterApplicant"
            @clear-filter="clearFilterApplicant"
            @hideModal="modal.isShowFilterApplicantModal = false"
        />
        <FilterVacancyModal
            v-if="modal.isShowFilterVacancy"
            :vacancy-filter="filterVacancy"
            :check-filter-vacancy="checkFilterVacancy"
            :button-apply-filter="isHaveFilterVacancy"
            :is-fetching="isFetching"
            :filter-options="filterVacancyOptions"
            @get-city-options="getCityOptions"
            @get-role-options="getRoleOptions"
            @get-company-office-options="getCompanyOfficeOptions"
            @apply-filter="applyFilterApplicant"
            @clear-filter="clearFilterVacancy"
            @hideModal="modal.isShowFilterVacancy = false"
        />

        <SendWhatsAppTemplateModal
            v-if="modal.isShowSendWhatsAppModal"
            :applicant="sendWaApplicant"
            @hideModal="modal.isShowSendWhatsAppModal = false"
        />

        <DocumentModal
            v-if="modal.isShowRequiredDocModal"
            title="Form of identifications"
            :applicant="selectedApplicant"
            type="required_docs"
            @hideModal="modal.isShowRequiredDocModal = false"
        />

        <DocumentModal
            v-if="modal.isShowAdditionalDocModal"
            title="Additional Documents"
            :applicant="selectedApplicant && selectedApplicant.job"
            type="additional_docs"
            @hideModal="modal.isShowAdditionalDocModal = false"
        />

        <ConfirmModal 
            v-if="modal.isShowMultiplePickupApplicantModal"
            title="Confirmation"
            body="Are you sure want to proceed?"
            @hideModal="modal.isShowMultiplePickupApplicantModal = false"
            @confirmProcess="confirmMultiplePickupApplicant"
        />

        <InterviewModal 
            v-if="modal.isShowMultipleInviteToInterviewApplicantModal"
            :ids="selectedApplicantIds"
            :is-multi="true"
            @hideModal="modal.isShowMultipleInviteToInterviewApplicantModal = false"
            @onSave="confirmMultipleProccessApplicant"
        />

        <InterviewDoneModal 
            v-if="modal.isShowMultiInterviewedApplicantModal"
            :ids="selectedApplicantIds"
            :is-multi="true"
            @hideModal="modal.isShowMultiInterviewedApplicantModal = false"
            @onSave="confirmMultipleProccessApplicant"
        />

        <PassedModal 
            v-if="modal.isShowMultipleSentContractApplicantModal"
            :ids="selectedApplicantIds"
            :is-multi="true"
            :company-id="employerCompanyId"
            @hideModal="modal.isShowMultipleSentContractApplicantModal = false"
            @onSave="confirmMultipleProccessApplicant"
        />

        <RejectedModal 
            v-if="modal.isShowMultipleRejectApplicantModal"
            :ids="selectedApplicantIds"
            :is-multi="true"
            @hideModal="modal.isShowMultipleRejectApplicantModal = false"
            @onSave="confirmMultipleProccessApplicant"
        />

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc.url"
            :title="previewSrc.title"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
    </div>
</template>

<script>
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';
import ApplicantItem from './ApplicantItem';
import TableViewItem from './TableViewItem';
import Pagination from '@/components/globals/Pagination';
import ConfirmModal from '@/components/globals/modals/SimpleConfirm';
import InterviewModal from '@/components/pages/admin/job_applicant/modals/InterviewModal';
import InterviewDoneModal from '@/components/pages/admin/job_applicant/modals/InterviewDoneModal';
import PassedModal from '@/components/pages/admin/job_applicant/modals/PassedModal';
import RejectedModal from '@/components/pages/admin/job_applicant/modals/RejectedModal';
import AdditionalQuestionModal from '@/components/pages/admin/job_applicant/modals/AdditionalQuestionModal';
import FilterApplicantModal from '@/components/pages/admin/job_applicant/modals/FilterApplicantModal';
import FilterVacancyModal from '@/components/pages/admin/job_applicant/modals/FilterVacancyModal';
import SendWhatsAppTemplateModal from '@/components/pages/admin/job_applicant/modals/SendWhatsAppTemplateModal';
import DocumentModal from '@/components/pages/admin/job_applicant/modals/DocumentModal';
import PreviewImageModal from '@/components//globals/modals/ImageViewer.vue';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import { contentType } from '@/utils/helper';

export default {
    name: 'ListApplicant',
    components: {
        ApplicantItem,
        Pagination,
        ConfirmModal,
        InterviewModal,
        InterviewDoneModal,
        PassedModal,
        RejectedModal,
        AdditionalQuestionModal,
        Multiselect,
        FilterApplicantModal,
        FilterVacancyModal,
        SendWhatsAppTemplateModal,
        DocumentModal,
        TableViewItem,
        PreviewImageModal
    },
    data() {
        return {
            baseUrlImg: PREFIX_IMAGE,
            isLoading: false,
            actionState: null,
            modal: {
                isShowConfirmModal: false,
                isSHowInterviewModal: false,
                isSHowDoneInterviewModal: false,
                isShowPassedModal: false,
                isShowRejectedModal: false,
                isShowResendContractModal: false,
                isShowAdditionalQuestionModal: false,
                isShowFilterVacancy: false,
                isShowFilterApplicantModal: false,
                isShowSendWhatsAppModal: false,
                isShowRequiredDocModal: false,
                isShowAdditionalDocModal: false,
                isShowMultiplePickupApplicantModal: false,
                isShowMultipleSentContractApplicantModal: false,
                isShowMultipleRejectApplicantModal: false,
                isShowMultipleInviteToInterviewApplicantModal: false,
                isShowMultiInterviewedApplicantModal: false,
                isShowPreviewImageModal: false
            },
            filter: {
                limit: 10,
                page: 1,
                sorting: 0,
                fullname: null,
                job_id: null
            },
            filterVacancy: {
                vacancy_domicilies: [],
                vacancy_roles: [],
                vacancy_offices: [],
                vacancy_status: null
            },
            filterVacancyOptions: {
                // fetch data for filter
                city_options: [],
                company_office_options: [],
                roles_options: [],
            },
            checkFilterVacancy: {
                is_domicile: false,
                is_company_office: false,
                is_role: false,
                is_status: false
            },
            filterApplicant: {
                job_preferences: [],
                domicilies: [],
                education_levels: [],
                work_experiences: '',
                gender: null,
                age: '',
                applicant_status: null,
                has_doc_ktp: false,
                has_doc_sim: false,
                has_doc_skck: false,
                has_doc_kk: false,
                has_doc_ijazah: false,
            },
            filterApplicantOptions: {
                // fetch data for filter
                city_options: [],
                education_options: [],
                job_preference_options: [],
            },
            isFetching: false,
            checkFilterApplicant: {
                is_job_preference: false,
                is_domicile: false,
                is_education: false,
                is_work_experience: false,
                is_gender: false,
                is_age: false,
                is_applicant_status: false
            },
            vacancyOptions: {
                limit: 1000,
                page: 1
            },
            limitList: [5, 10, 15, 20],
            selectedJobApply: null,
            selectedInitiationOffice: null,
            selectedUserQuestion: null,
            selectedProvince: {
                id: this.$route.params.province_id,
                name: ''
            },
            selectedVacancy: null,
            activeFilterApplicant: null,
            sendWaApplicant: null,
            selectedApplicant: null,
            selectedApplicantIds: [],
            profile: null,
            selectedView: 'card',
            previewSrc: {
                url: '',
                title: ''
            }
        };
    },
    computed: {
        ...mapGetters({
            applicant_list_v4: 'job_applicant/job_applicant_list_v4',
            job_list: 'job/job_list',
            job_all_applicant_list_v4: 'job_applicant/job_all_applicant_list_v4'
        }),
        isHaveFilterApplicant(){
            let data = false;
            if (
                this.filterApplicant.job_preferences.length > 0 || this.filterApplicant.domicilies.length > 0 ||
                this.filterApplicant.education_levels.length > 0 || this.filterApplicant.work_experiences != '' ||
                this.filterApplicant.gender != null || (this.filterApplicant.age != '' && this.filterApplicant.age != null) ||
                this.filterApplicant.applicant_status != null || this.filterApplicant.has_doc_ijazah || this.filterApplicant.has_doc_kk ||
                this.filterApplicant.has_doc_ktp || this.filterApplicant.has_doc_sim || this.filterApplicant.has_doc_skck
            ) {
                data = true;
            }

            return data;
        },
        isHaveFilterVacancy(){
            let data = false;
            if (
                this.filterVacancy.vacancy_domicilies.length > 0 || this.filterVacancy.vacancy_offices.length > 0 ||
                this.filterVacancy.vacancy_roles.length > 0 || this.filterVacancy.vacancy_status != null
            ) {
                data = true;
            }

            return data;
        },
        disabledCancelSelectAllApplicant() {
            return Boolean(this.selectedApplicantIds?.length < 1);
        },
        employerCompanyId() {
            return this.profile && this.profile.company && this.profile.company._id || null;
        },
        disabledActionPerItemButton() {
            return Boolean(this.selectedApplicantIds?.length > 0);
        },
        isShowMultipleSelectApplicant() {
            return Boolean(
                this.filterApplicant && 
                this.filterApplicant.applicant_status && 
                (
                    this.filterApplicant.applicant_status.value == 0 ||
                    this.filterApplicant.applicant_status.value == 1 ||
                    this.filterApplicant.applicant_status.value == 4 ||
                    this.filterApplicant.applicant_status.value == 5 ||
                    this.filterApplicant.applicant_status.value == 3
                )
            );
        },
        isShowPickupMultiApplicantButton() {
            return Boolean(
                this.filterApplicant && 
                this.filterApplicant.applicant_status && 
                (
                    this.filterApplicant.applicant_status.value == 0 ||
                    this.filterApplicant.applicant_status.value == 3
                )
            );
        },
        isShowInviteToInterviewMultiApplicantButton() {
            return Boolean(
                this.filterApplicant && 
                this.filterApplicant.applicant_status && 
                (
                    this.filterApplicant.applicant_status.value == 1 ||
                    this.filterApplicant.applicant_status.value == 3
                )
            );
        },
        isShowInterviewedMultiApplicantButton() {
            return Boolean(this.filterApplicant && this.filterApplicant.applicant_status && this.filterApplicant.applicant_status.value == 4);
        },
        isShowSendContractMultiApplicantButton() {
            return Boolean(
                this.filterApplicant && 
                this.filterApplicant.applicant_status && 
                (
                    this.filterApplicant.applicant_status.value == 0 ||
                    this.filterApplicant.applicant_status.value == 1 ||
                    this.filterApplicant.applicant_status.value == 4 ||
                    this.filterApplicant.applicant_status.value == 5
                )
            );
        },
        isShowRejectMultiApplicantButton() {
            return Boolean(
                this.filterApplicant && 
                this.filterApplicant.applicant_status && 
                (
                    this.filterApplicant.applicant_status.value == 0 ||
                    this.filterApplicant.applicant_status.value == 1 ||
                    this.filterApplicant.applicant_status.value == 4 ||
                    this.filterApplicant.applicant_status.value == 5
                )
            );
        },
        reversePickupMultiApplicantStyleButton() {
            return Boolean(this.filterApplicant && this.filterApplicant.applicant_status && this.filterApplicant.applicant_status.value == 3);
        },
        reverseInviteToInterviewApplicantStyleButton() {
            return Boolean(this.filterApplicant && this.filterApplicant.applicant_status && this.filterApplicant.applicant_status.value == 3);
        }
    },
    watch: {
        'checkFilterApplicant.is_job_preference'(val){
            if (!val) this.filterApplicant.job_preferences = [];
        },
        'checkFilterApplicant.is_domicile'(val){
            if (!val) this.filterApplicant.domicilies = [];
        },
        'checkFilterApplicant.is_education'(val){
            if (!val) this.filterApplicant.education_levels = [];
        },
        'checkFilterApplicant.is_work_experience'(val){
            if (!val) this.filterApplicant.work_experiences = '';
        },
        'checkFilterApplicant.is_gender'(val){
            if (!val) this.filterApplicant.gender = null;
        },
        'checkFilterApplicant.is_age'(val){
            if (!val) this.filterApplicant.age = '';
        },
        'checkFilterApplicant.is_applicant_status'(val){
            if (!val) this.filterApplicant.applicant_status = null;
        },
        'checkFilterVacancy.is_domicile'(val){
            if (!val) this.filterVacancy.vacancy_domicilies = [];
        },
        'checkFilterVacancy.is_company_office'(val){
            if (!val) this.filterVacancy.vacancy_offices = [];
        },
        'checkFilterVacancy.is_role'(val){
            if (!val) this.filterVacancy.vacancy_roles = [];
        },
        'checkFilterVacancy.is_status'(val){
            if (!val) this.filterVacancy.vacancy_status = null;
        }
    },
    async created() {
        if (localStorage.getItem(`$filter_province ${this.$route.name}`) && localStorage.getItem(`$filter_province ${this.$route.name}`).toString() !== this.$route.params.province_id.toString()) {
            localStorage.clear();
        }
        this.unselectAllApplicant();
        await Promise.all([
            this.getUserProfile(),
            this.getSavedFilter(),
            this.formatFilterApplicant(),
            this.getApplicantList(),
            this.getProvinceName(),
            this.getVacancyList()
        ]);
        [this.filterApplicantOptions.education_options, this.filterApplicantOptions.job_preference_options] = await Promise.all([
            this.getEducationLevels(),
            this.getJobPreferences()
        ]);
        this.selectedVacancy = this.job_list.find(item => item._id === null);
        localStorage.setItem(`$filter_province ${this.$route.name}`, this.selectedProvince.id);
        this.setDownloadReportFilter();
    },
    beforeMount() {
        if (window.UIkit.modal('.change-status-modal')) window.UIkit.modal('.change-status-modal').$destroy(true);
        if (window.UIkit.modal('.interview-modal')) window.UIkit.modal('.interview-modal').$destroy(true);
        if (window.UIkit.modal('.after-interview-modal')) window.UIkit.modal('.after-interview-modal').$destroy(true);
        if (window.UIkit.modal('#mainModal')) window.UIkit.modal('#mainModal').$destroy(true);
    },
    methods: {
        ...mapActions({
            getJobApplicantListV4: 'job_applicant/getJobApplicantListV4',
            pickupApplicant: 'applicant/pickupApplicant',
            downloadCVApplicant: 'job_applicant/downloadCVApplicant',
            getProvinces: 'option_data/getProvinces',
            getJobList: 'job/getJobList',
            getEducationLevels: 'option_data/getEducationLevels',
            getJobPreferences: 'option_data/getJobPreferences',
            getCities: 'option_data/getCities',
            getAllCompanyRoles: 'option_data/getAllCompanyRoles',
            getCompanyOfficeList: 'option_data/getCompanyOfficeList',
            getAllJobApplicantListV4: 'job_applicant/getAllJobApplicantListV4',
            pickupMultiApplicant: 'applicant/pickupMultiApplicant',
            getProfile: "profile/getProfile",
        }),
        async getApplicantList() {
            this.isLoading = true;
            await Promise.all([
                this.getJobApplicantListV4({
                    province: this.$route.params.province_id,
                    ...this.filter,
                    ...this.activeFilterApplicant
                }),
                this.getAllJobApplicantList()
            ]);
            this.isLoading = false;
        },
        async getProvinceName(){
            const province = await this.getProvinces({province_id: this.selectedProvince.id});
            this.selectedProvince.name = province[0].name;
        },
        async getVacancyList(){
            await this.getJobList({
                province_id: this.$route.params.province_id,
                ...this.vacancyOptions
            });
        },
        changeLimit(e) {
            this.filter.limit = e.target.value;
            localStorage.setItem(`$filter_main ${this.$route.name}`, JSON.stringify(this.filter));
            this.getApplicantList();
        },
        changePage(value) {
            this.filter.page = value;
            localStorage.setItem(`$filter_main ${this.$route.name}`, JSON.stringify(this.filter));
            this.getApplicantList();
        },
        moveUser({state, job_apply_id, office_id}) {
            const openModal = {
                "1": "isShowConfirmModal",
                "4": "isSHowInterviewModal",
                "5": "isSHowDoneInterviewModal",
                "6": "isShowPassedModal",
                "3": "isShowRejectedModal",
            };

            this.selectedJobApply = job_apply_id;
            this.selectedInitiationOffice = office_id;

            this.actionState = state;
            this.modal[openModal[state]] = true;
        },
        async confirmProcess() {
            const openModal = {
                "1": "isShowConfirmModal",
                "4": "isSHowInterviewModal",
                "5": "isSHowDoneInterviewModal",
                "6": "isShowPassedModal",
                "3": "isShowRejectedModal",
                "8": "isShowResendContractModal",
            };

            this.modal[openModal[this.actionState]] = false;

            if (this.actionState === 1) {
                this.pickedUpApplicant();
            } else {
                this.unselectAllApplicant();
                await this.getApplicantList();
            }
        },
        async pickedUpApplicant() {
            this.isLoading = true;
            try {
                const response = await this.pickupApplicant(this.selectedJobApply);
                if (response && response.status === 'OK') {
                    notificationSuccess('Picked up!');
                    this.selectedJobApply = null;
                    this.unselectAllApplicant();
                    await this.getApplicantList();
                } else {
                    notificationDangerCustom("Failed to picked up applicant!");
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.isLoading = false;
        },
        async downloadCV({userId, done}){
            try {
                const response = await this.downloadCVApplicant({ userId });
                if (response && response.status === 'OK') {
                    window.open(response.result.cv_path.file_url, '_blank');
                    notificationSuccess('CV downloaded!');
                } else {
                    notificationDangerCustom('Failed to download CV');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                done();
            }
        },
        openAdditionalQuestion(userQuestion){
            this.selectedUserQuestion = userQuestion;
            this.modal.isShowAdditionalQuestionModal = true;
        },
        async isSortedChanged(){
            this.unselectAllApplicant();
            await Promise.all([
                localStorage.setItem(`$filter_main ${this.$route.name}`, JSON.stringify(this.filter)),
                this.getApplicantList()
            ]);
        },
        async mainFilter(field) {
            if(this.filter[field].length === 0 || this.filter[field].length > 2){
                this.filter.limit = 10;
                this.filter.page = 1;
                this.unselectAllApplicant();
                await Promise.all([
                    localStorage.setItem(`$filter_main ${this.$route.name}`, JSON.stringify(this.filter)),
                    this.getApplicantList()
                ]);
                this.setDownloadReportFilter();
            }
        },
        onSelectVacancy(){
            this.filter.limit = 10;
            this.filter.page = 1;
            this.filter.job_id = this.selectedVacancy._id;
            this.unselectAllApplicant();
            this.getApplicantList();
            this.setDownloadReportFilter();
        },
        openModal({modalName}){
            this.modal[modalName] = true;
        },
        async getCityOptions(query){
            this.isFetching = true;
            this.filterVacancyOptions.city_options = await this.getCities({name: query, province_id: this.selectedProvince.id});
            this.filterApplicantOptions.city_options = await this.getCities({name: query});
            this.isFetching = false;
        },
        async getRoleOptions(query){
            this.isFetching = true;
            this.filterVacancyOptions.roles_options = await this.getAllCompanyRoles({name: query});
            this.isFetching = false;
        },
        async getCompanyOfficeOptions(query){
            this.isFetching = true;
            this.filterVacancyOptions.company_office_options = await this.getCompanyOfficeList({
                name: query,
                limit: 1000,
                page: 1,
                province_id: this.selectedProvince.id
            });
            this.isFetching = false;
        },
        async clearFilterApplicant(){
            this.unselectAllApplicant();
            await Promise.all([
                this.filter.limit = 10,
                this.filter.page = 1,
                this.filterApplicant.job_preferences = [],
                this.filterApplicant.domicilies = [],
                this.filterApplicant.education_levels = [],
                this.filterApplicant.work_experiences = '',
                this.filterApplicant.gender = null,
                this.filterApplicant.age = '',
                this.filterApplicant.applicant_status = null,
                this.filterApplicant.has_doc_ktp = false,
                this.filterApplicant.has_doc_sim = false,
                this.filterApplicant.has_doc_skck = false,
                this.filterApplicant.has_doc_kk = false,
                this.filterApplicant.has_doc_ijazah = false,
                this.clearCheckBoxFilter(this.checkFilterApplicant),
                this.formatFilterApplicant(),
                this.getApplicantList(),
                this.setDownloadReportFilter()
            ]);
        },
        async clearFilterVacancy(){
            this.unselectAllApplicant();
            await Promise.all([
                this.filter.limit = 10,
                this.filter.page = 1,
                this.filterVacancy.vacancy_domicilies = [],
                this.filterVacancy.vacancy_offices = [],
                this.filterVacancy.vacancy_roles = [],
                this.filterVacancy.vacancy_status = null,
                this.clearCheckBoxFilter(this.checkFilterVacancy),
                this.formatFilterApplicant(),
                this.getApplicantList(),
                this.setDownloadReportFilter()
            ]);
        },
        clearCheckBoxFilter(data){
            for (var i in data) {
                data[i] = false;
            }
        },
        async applyFilterApplicant(){
            this.filter.limit = 10;
            this.filter.page = 1;
            this.unselectAllApplicant();
            await Promise.all([
                this.formatFilterApplicant(),
                this.getApplicantList(),
                this.setDownloadReportFilter(),
            ]);
            this.isShowFilterApplicantModal = false;
            this.isShowFilterVacancy = false;
        },
        formatFilterApplicant(){
            let applicantFilter = JSON.parse(JSON.stringify(this.filterApplicant));

            if (this.checkFilterApplicant.is_job_preference && applicantFilter.job_preferences.length > 0) {
                applicantFilter.job_preferences = applicantFilter.job_preferences.map(jobPreference => jobPreference._id).join(',');
            } else {
                applicantFilter.job_preferences = [];
            }
            if (this.checkFilterApplicant.is_domicile && applicantFilter.domicilies.length > 0) {
                applicantFilter.domicilies = applicantFilter.domicilies.map(domicile => domicile._id).join(',');
            } else {
                applicantFilter.domicilies = [];
            }
            if (this.checkFilterApplicant.is_education && applicantFilter.education_levels.length > 0) {
                applicantFilter.education_levels = applicantFilter.education_levels.map(educationLevel => educationLevel._id).join(',');
            } else {
                applicantFilter.education_levels = [];
            }
            if (!this.checkFilterApplicant.is_work_experience && applicantFilter.work_experiences) {
                applicantFilter.work_experiences = '';
            }

            applicantFilter.gender = (applicantFilter.gender?.value || '').toLowerCase();
            applicantFilter.age = (applicantFilter?.age || '').replace('<', '0-').replace('>', '');
            applicantFilter.applicant_status = (applicantFilter.applicant_status?.value || '');

            if (this.checkFilterVacancy.is_domicile && this.filterVacancy.vacancy_domicilies.length > 0) {
                applicantFilter.vacancy_domicilies = this.filterVacancy.vacancy_domicilies.map(vdom => vdom._id).join(',');
            } else {
                applicantFilter.vacancy_domicilies = [];
            }

            if (this.checkFilterVacancy.is_company_office && this.filterVacancy.vacancy_offices.length > 0) {
                applicantFilter.vacancy_offices = this.filterVacancy.vacancy_offices.map(voffice => voffice._id).join(',');
            } else {
                applicantFilter.vacancy_offices = [];
            }

            if (this.checkFilterVacancy.is_role && this.filterVacancy.vacancy_roles.length > 0) {
                applicantFilter.vacancy_roles = this.filterVacancy.vacancy_roles.map(vrole => vrole._id).join(',');
            } else {
                applicantFilter.vacancy_roles = [];
            }

            applicantFilter.vacancy_status = (this.filterVacancy.vacancy_status?.value || '');

            this.activeFilterApplicant = applicantFilter;
            this.saveFilter();
        },
        saveFilter(){
            localStorage.setItem(`$filter_applicant ${this.$route.name}`, JSON.stringify(this.filterApplicant));
            localStorage.setItem(`$filter_vacancy ${this.$route.name}`, JSON.stringify(this.filterVacancy));
            localStorage.setItem(`$filter_check_applicant ${this.$route.name}`, JSON.stringify(this.checkFilterApplicant));
            localStorage.setItem(`$filter_check_vacancy ${this.$route.name}`, JSON.stringify(this.checkFilterVacancy));
            localStorage.setItem(`$filter_main ${this.$route.name}`, JSON.stringify(this.filter));
        },
        getSavedFilter(){
            if (localStorage.getItem(`$filter_applicant ${this.$route.name}`)) {
                this.filterApplicant = JSON.parse(localStorage.getItem(`$filter_applicant ${this.$route.name}`));
            }
            if (localStorage.getItem(`$filter_vacancy ${this.$route.name}`)) {
                this.filterVacancy = JSON.parse(localStorage.getItem(`$filter_vacancy ${this.$route.name}`));
            }
            if (localStorage.getItem(`$filter_check_applicant ${this.$route.name}`)){
                this.checkFilterApplicant = JSON.parse(localStorage.getItem(`$filter_check_applicant ${this.$route.name}`));
            }
            if (localStorage.getItem(`$filter_check_vacancy ${this.$route.name}`)){
                this.checkFilterVacancy = JSON.parse(localStorage.getItem(`$filter_check_vacancy ${this.$route.name}`));
            }
            if (localStorage.getItem(`$filter_main ${this.$route.name}`)) {
                this.filter = JSON.parse(localStorage.getItem(`$filter_main ${this.$route.name}`));
            }
        },
        setDownloadReportFilter() {
            this.$emit('filterApplied', {
                ...this.filter,
                ...this.activeFilterApplicant,
                vacancy_name: this.selectedVacancy.title,
                province_id: this.$route.params.province_id,
                province_name: this.selectedProvince.name,
                company_name: this.applicant_list_v4.docs[0].job.company || '-'
            });
        },
        sendWhatsApp(applicant){
            this.sendWaApplicant = applicant;
            this.modal.isShowSendWhatsAppModal = true;
        },
        openRequiredDocsModal(applicant){
            this.selectedApplicant = applicant;
            this.modal.isShowRequiredDocModal = true;
        },
        openAdditionalDocsModal(applicant){
            this.selectedApplicant = applicant;
            this.modal.isShowAdditionalDocModal = true;
        },
        async getAllJobApplicantList() {
            await this.getAllJobApplicantListV4({
                province: this.$route.params.province_id,
                ...this.filter,
                ...this.activeFilterApplicant
            });
        },
        selectApplicant({ _id = null }) {
            let selectedIds = [];
            const resultFindSameData = this.selectedApplicantIds.find((item) => item?.toString() == _id?.toString());
            selectedIds = this.selectedApplicantIds.length > 0 ? resultFindSameData ? this.selectedApplicantIds.filter((item) => item != _id) : [...this.selectedApplicantIds, ...[_id]] : [_id];
            this.selectedApplicantIds = selectedIds;
        },
        findSelectedApplicant({ _id = null }) {
            return !!this.selectedApplicantIds.find((item) => item?.toString() == _id?.toString());
        },
        selectAllApplicant() {
            if (Array.isArray(this.job_all_applicant_list_v4) && this.job_all_applicant_list_v4.length > 0) {
                for (const applicant of this.job_all_applicant_list_v4) {
                    const findSameData = this.selectedApplicantIds.find((data) => data?.toString() === applicant?._id?.toString());
                    if (!findSameData) {
                        this.selectedApplicantIds.push(applicant?._id);
                    }
                }
            }
        },
        unselectAllApplicant() {
            this.selectedApplicantIds = [];
        },
        async confirmMultiplePickupApplicant() {
            try {
                const payload = { job_applicant_ids: this.selectedApplicantIds };
                const response = await this.pickupMultiApplicant(payload);
                if (response && response.status === 'OK' && response.result === 'success' && (Array.isArray(response.rejected) && response.rejected.length < 1)) {
                    notificationSuccess('Picked up!');
                    await this.confirmMultipleProccessApplicant();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async confirmMultipleProccessApplicant() {
            try {
                this.unselectAllApplicant();
                await this.getApplicantList();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modal.isShowMultiplePickupApplicantModal = false;
                this.modal.isShowMultipleSentContractApplicantModal = false;
                this.modal.isShowMultipleRejectApplicantModal = false;
                this.modal.isShowMultipleInviteToInterviewApplicantModal = false;
                this.modal.isShowMultiInterviewedApplicantModal = false;
            }
        },
        async getUserProfile() {
            try {
                this.profile = await this.getProfile();
            } catch (error) {
                notificationDanger(error);
            }
        },
        openImage({file_url, title}) {
            this.previewSrc.url = file_url;
            this.previewSrc.title = title;
            this.modal[contentType(this.previewSrc.url)] = true;
        },
        copyText({value, text}){
            navigator.clipboard.writeText(value);
            notificationSuccess(text);
        },
        switchView(value){
            this.selectedView = value;
        }
    }
};
</script>

<style scoped>
.button-filter {
    border-radius: 18px;
    border: 1px solid #E5E5E5;
}
.button-filter.active {
    background:#FFE500 ;
}
.icon-filter {
    content: url('~@/assets/job_applicant/detail/filter-list.svg');
    vertical-align: middle;
}
.badge-filter {
    background-color: #FFE500;
    font-style: italic;
    color: black !important;
    padding: 0.7rem;
}

.button-select-all {
    border: 1px solid #0052CD !important;
    background: white !important;
    color: #0052CD !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
}

.button-pickup-all-applicant {
    background: #0052CD !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
}

.button-sent-contract-all-applicant {
    background: #22CC8E !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
}

.button-reject-all-applicant {
    background: #DF5C70 !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
}

.button-cancel-select-all-applicant {
    border: 1px solid #CC240F !important;
    background: white !important;
    color: #CC240F !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
}

.btn-disabled {
    background: #C2C3C7 !important;
    color: white !important;
    cursor: not-allowed !important;
}

.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

.sticky-col-th {
  position: -webkit-sticky;
  position: sticky;
  background-color: #0abab5;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: #FFF;
}

.first-col {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  left: 0px;
}

.second-col {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  left: 30px;
}

.third-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 200px;
}

.fourth-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 330px;
}

.switcher {
    border: 2px solid #0052CD;
    color: #0052CD;
}

.switcher.active {
    border: 0px;
    background: #0052CD;
    color: #FFFFFF;
    font-weight: bold;
}

.switcher-left {
    border-top-left-radius: 500px; 
    border-bottom-left-radius: 500px; 
}

.switcher-right {
    border-top-right-radius: 500px; 
    border-bottom-right-radius: 500px; 
}

.table-view-icon {
    content: url('~@/assets/job_applicant/table-view.svg');
    vertical-align: middle;
}

.table-view-icon.active {
    content: url('~@/assets/job_applicant/table-view-active.svg');
    vertical-align: middle;
}
.card-view-icon {
    content: url('~@/assets/job_applicant/card-view.svg');
    vertical-align: middle;
}

.card-view-icon.active {
    content: url('~@/assets/job_applicant/card-view-active.svg');
    vertical-align: middle;
}
</style>
