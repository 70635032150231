<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-margin uk-child-width-1-4" uk-grid v-if="!consist(user_cred.roles, ['asd123','user-marketing'])">
            <div>
                <div class="uk-form-controls">
                    <input
                        class="uk-input"
                        type="text"
                        placeholder="Type Contract Title here"
                        v-model="meta.title"
                        @keyup.enter="getInitContracts(meta)"
                    >
                </div>
            </div>
            <div class="uk-width-1-6">
                <select
                    name="type"
                    class="uk-select"
                    v-model="meta.type"
                    @change="getInitContracts(meta)"
                >
                    <option :value="null">Select Type</option>
                    <option value="1" selected>Myrobin Contract</option>
                    <option value="2" >Company Contract</option>
                </select>
            </div>
            <div class="uk-width-1-6">
                <select
                    name="type"
                    class="uk-select"
                    v-model="meta.status"
                    @change="getInitContracts(meta)"
                >
                    <option :value="null">Select Status</option>
                    <option value="1">Draft</option>
                    <option value="2" >Post</option>
                </select>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin" v-if="!consist(user_cred.roles, ['asd123','user-marketing'])">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-divider">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-table-shrink">No</th>
                            <th class="uk-text-center">Title</th>
                            <th class="uk-text-center uk-width-auto">Contract</th>
                            <th class="uk-text-center uk-width-auto">Status</th>
                            <th class="uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="contracts.docs.length>0">
                        <tr v-for="(contract, i) in contracts.docs" :key="i">
                            <td class="uk-text-center">{{i+1}}</td>
                            <td class="uk-text-center">{{contract.title}}</td>
                            <td class="uk-text-center">{{preview(contract.contract)}}</td>
                            <td class="uk-text-center"><span :class="statusLabelClass(contract)">{{statusLabel(contract)}}</span></td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-primary uk-border-rounded" @click="showModal('detailModal', contract)">Detail</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="contracts.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="detailModal" class="uk-modal-container uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-full uk-close-large" type="button" uk-close @click="hideModal('detailModal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Contract</h2>
                </div>
                <!-- <div> -->
                    <!-- <vue-html2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="true"
                        :preview-modal="false"
                        :paginate-elements-by-height="1400"
                        filename="hee hee"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="a4"
                        pdf-orientation="landscape"
                        pdf-content-width="800px"

                        @progress="onProgress($event)"
                        @hasStartedGeneration="hasStartedGeneration()"
                        @hasGenerated="hasGenerated($event)"
                        @beforeDownload="beforeDownload($event)"
                        ref="html2Pdf"
                    >
                        <section slot="pdf-content"> -->
                            <!-- PDF Content Here -->
                            <!-- <div v-if="selected_contract != null" class="uk-modal-body">
                                <div class="uk-margin">
                                    <div v-html="selected_contract.contract"></div>
                                </div>
                            </div>
                        </section>
                    </vue-html2pdf> -->
                <!-- </div> -->
                <div v-if="selected_contract != null" class="uk-modal-body uk-background-muted" uk-overflow-auto>
                    <div class="uk-margin uk-background-default">
                        <img width="780px" src="/images/kontrak_header_majapahit.png" alt="Header kontrak" style="object-fit: cover; width: 100%;">
                        <div class="detail-contract uk-margin uk-margin-medium-left uk-margin-medium-right" v-html="selected_contract.contract"></div>
                        <img width="780px" src="/images/kontrak_footer_majapahit.png" alt="Footer kontrak" style="object-fit: cover; width: 100%;">
                    </div>
                </div>
            </div>
        </div>

        <div id="contractModal" class="uk-modal-container" uk-modal esc-close="false" bg-close="false" v-if="false">
            <div class="uk-modal-dialog" uk-overflow-auto>
                <button class="uk-modal-close-full uk-close-large" type="button" uk-close @click="isEdit ? hideModal('editModal') : hideModal('createModal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Contract</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Title</label>
                        <input
                            class="uk-input"
                            type="text"
                            v-model="contractForm.title"
                        >
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Type</label>
                        <select
                            name="type"
                            class="uk-select"
                            v-model="contractForm.type"
                        >
                            <option value="1" selected>Myrobin Contract</option>
                            <option value="2" >Company Contract</option>
                        </select>
                    </div>
                    <div class="uk-margin" v-if="contractForm.type == 2">
                        <label class="uk-form-label">Company</label>
                        <multiselect
                            v-model="contractForm.company_id"
                            placeholder="Type to search company"
                            label="name"
                            name="company"
                            track-by="_id"
                            :options="company_options"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="false"
                            :options-limit="50"
                            @search-change="setCompanyValues"
                            v-validate="'required'"
                        ></multiselect>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Status</label>
                        <select
                            name="type"
                            class="uk-select"
                            v-model="contractForm.status"
                        >
                            <option value="1">Draft</option>
                            <option value="2" >Post</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Contract</label>
                        <vue-editor v-model="contractForm.contract"></vue-editor>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-margin-right" type="button" @click="showModal('previewModal')">Preview</button>
                    <button class="uk-button uk-button-primary" type="button" @click="saveContract()">Save</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import { VueEditor } from 'vue2-editor';
// import VueHtml2pdf from 'vue-html2pdf';

export default {
    data(){
        return {
            meta: {
                title: '',
                status: null,
                type: null,
                limit: 50,
                page: 1
            },
            contracts: {docs: []},
            selected_contract: null,
        };
    },
    components: {
        EmptyTable,
        Pagination,
        VueEditor,
        // VueHtml2pdf,
    },
    computed: {
        user_cred() {
            return getUserLogin();
        },
    },
    async mounted() {
        await this.getInitContracts();
    },
    methods: {
        ...mapActions({
            getContractsPaginate: 'contract/getContractsPaginate',
            getContractDetail: 'contract/getContractDetail',
        }),
        async getInitContracts(){
            this.meta.title = formatter.sanitizeString(this.meta.title);
            this.contracts = await this.getContractsPaginate(this.meta);
        },
        statusLabel(contract){
            if (contract == null) {
                return 'No Status';
            } else if (contract.status == 1) {
                return 'Draft';
            } else if (contract.status == 2) {
                return 'Posted';
            }
        },
        statusLabelClass(contract){
            if (contract == null) {
                return 'uk-label uk-label-danger uk-border-rounded';
            } else if (contract && contract.status == 1) {
                return 'uk-label uk-border-rounded';
            } else {
                return 'uk-label uk-label-success uk-border-rounded';
            }
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            data = data.replace(/<[^>]*>/g, ' ');
            return data.substring(0,26)+'...';
        },
        async showModal(modal, data = null){
            switch (modal) {
            case 'detailModal':
                this.selected_contract = data;
                await window.UIkit.modal('#detailModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'detailModal':
                await window.UIkit.modal('#detailModal').hide();
                this.selected_contract = null;
                break;
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}

th {
    color: #ffffff;
}

td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

/* Quilljs content styling */
.detail-contract {
    white-space: pre-wrap;
    line-height: 1.42;
}

.detail-contract >>> h1, .detail-contract >>> h2, .detail-contract >>> h3,
.detail-contract >>> h4, .detail-contract >>> h5, .detail-contract >>> h6, .detail-contract >>> p {
    margin: 0;
    padding: 0;
}

.detail-contract >>> ol, .detail-contract >>> ul {
    padding-left: 1.5em;
}

.detail-contract >>> ol li, .detail-contract >>> ul li {
    list-style-type: none;
    padding-left: 1.5em;
}

.detail-contract >>> ul>li::before {
    content: '\2022';
}

.detail-contract >>> li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right;
}

.detail-contract >>> ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;counter-increment: list-0;
}

.detail-contract >>> ol li:before {
    content: counter(list-0,decimal) '. ';
}

.detail-contract >>> ol li.ql-indent-1 {
    counter-increment: list-1;
}

.detail-contract >>> ol li.ql-indent-1:before {
    content: counter(list-1,lower-alpha) '. ';
}

.detail-contract >>> ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.detail-contract >>> ol li.ql-indent-2 {
    counter-increment: list-2;
}

.detail-contract >>> ol li.ql-indent-2:before {
    content: counter(list-2,lower-roman) '. ';
}

.detail-contract >>> ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.detail-contract >>> ol li.ql-indent-3 {
    counter-increment: list-3;
}

.detail-contract >>> ol li.ql-indent-3:before {
    content: counter(list-3,decimal) '. ';
}

.detail-contract >>> ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.detail-contract >>> .ql-indent-1 {
    padding-left: 3em;
}

.detail-contract >>> li.ql-indent-1 {
    padding-left: 4.5em;
}

.detail-contract >>> .ql-indent-2 {
    padding-left: 6em;
}

.detail-contract >>> li.ql-indent-2 {
    padding-left: 7.5em;
}

.detail-contract >>> .ql-indent-3 {
    padding-left: 9em;
}

.detail-contract >>> li.ql-indent-3 {
    padding-left: 10.5em;
}
.detail-contract >>> .ql-align-center {
    text-align: center;
}
.detail-contract >>> .ql-align-justify {
    text-align: justify;
}
.detail-contract >>> .ql-align-right {
    text-align: right;
}
</style>
