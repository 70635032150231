import api from '@/utils/api';
import { ADDITIONAL_QUESTION_TEMPLATE } from '@/utils/api-url';
import { notificationDanger } from '@/utils/notification';

const namespaced = true;
const state = {};

const mutations = {};

const actions = {
    async getTemplateList(context, data) {
        try {
            const { categoryId, ...meta } = data;
            const response = await api.apiGetAuth(ADDITIONAL_QUESTION_TEMPLATE.LIST(categoryId), meta);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
