<template>
    <section>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="hideModal(false)"
                ></button>
                <div class="uk-modal-header uk-text-center">
                    <h4>{{ title }}</h4>
                </div>
                <div class="uk-modal-body">
                    <div>{{ body }}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        body: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    mounted() {
        window.UIkit.modal("#mainModal").show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal("#mainModal").$destroy(true);
            this.$emit("hideModal");
        }
    },
};
</script>
