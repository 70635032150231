<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable" v-if="data != null">
        <div class="uk-flex uk-flex-wrap uk-flex-between uk-margin-bottom">
            <div class="uk-flex uk-flex-column uk-flex-between uk-margin-right" style="width: 424px">
                <div>
                    <h4 class="title">{{todayDate}}</h4>
                </div>
                <div class="uk-card uk-card-default uk-card-body uk-margin-auto uk-border-rounded uk-width-1-1 uk-flex uk-flex-row uk-padding-remove uk-margin-bottom">
                    <div class="subtitle uk-width-1-2 card-content uk-margin-medium-top">Daily<br>Workers<br>Attendance</div>
                    <div
                        class="uk-width-1-2 uk-text-center card-content card-content-right data-content uk-padding-remove-horizontal"
                        style="background: #24D0CB;color:white"
                    >{{data.recap.today}}</div>
                </div>
                <div class="uk-card uk-card-default uk-card-body uk-margin-auto uk-border-rounded uk-width-1-1 uk-flex uk-flex-row uk-padding-remove">
                    <div class="subtitle uk-width-1-2 card-content uk-margin-medium-top">Daily<br>No Attendance<br>Workers</div>
                    <div
                        class="uk-width-1-2 uk-text-center card-content card-content-right data-content uk-padding-remove-horizontal"
                        style="background: #FFFF82"
                    >{{data.mitra - data.recap.today}}</div>
                </div>
            </div>
            <div class="uk-card-small uk-card-default uk-card-body uk-margin-auto uk-border-rounded uk-width-expand" style="min-width:390px">
                <div class="uk-flex" uk-grid>
                    <div class="uk-width-1-1 uk-text-left ">
                        <h4 class="subtitle">Workers Attendance in the Past Seven Days</h4>
                    </div>
                    <div class="uk-width-1-1 uk-margin-remove-top" v-if="chart_data != null">
                        <line-chart :chartdata="chart_data" :options="chart_options" />
                    </div>
                </div>
            </div>
        </div>
        <div uk-grid>
            <div class="uk-width-1-1 uk-text-left">
                <h3 class="title">Daily Statistics for {{data.company.name}}</h3>
            </div>
            <div class="uk-width-1-1 uk-flex uk-flex-wrap uk-flex-between uk-margin-small-top">
                <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-text-center fixed-card">
                    <div class="uk-width-1-1">
                        <h4 class="title">Workers</h4>
                    </div>
                    <div class="uk-width-1-1">
                        <h1 class="data-content uk-padding-remove-horizontal">{{data.mitra}}</h1>
                    </div>
                    <div class="uk-width-1-1 subtitle">
                        <h4 class="subtitle">People</h4>
                    </div>
                </div>
                <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-text-center fixed-card">
                    <div class="uk-width-1-1">
                        <h4 class="title">Offices</h4>
                    </div>
                    <div class="uk-width-1-1">
                        <h1 class="data-content">{{data.offices}}</h1>
                    </div>
                    <div class="uk-width-1-1">
                        <h4 class="subtitle uk-padding-remove-horizontal">Branches</h4>
                    </div>
                </div>
                <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-text-center fixed-card">
                    <div class="uk-width-1-1">
                        <h4 class="title uk-text-nowrap">Job Vacancies</h4>
                    </div>
                    <div class="uk-width-1-1">
                        <h1 class="data-content uk-padding-remove-horizontal">{{data.job_vacancies}}</h1>
                    </div>
                    <div class="uk-width-1-1">
                        <h4 class="subtitle">Openings</h4>
                    </div>
                </div>
                <div class="uk-card uk-card-default uk-card-body uk-border-rounded uk-text-center fixed-card">
                    <div class="uk-width-1-1">
                        <h4 class="title">Applicants</h4>
                    </div>
                    <div class="uk-width-1-1">
                        <h1 class="data-content uk-padding-remove-horizontal">{{data.applicants}}</h1>
                    </div>
                    <div class="uk-width-1-1">
                        <h4 class="subtitle">Jobseekers</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
moment.locale('id');
import LineChart from '@/components/globals/chart/LineChart';
import formatter from "@/utils/formatter";


export default {
    data(){
        return {
            data: null,
            chart_data: null,
            chart_options: {
                responsive: true,
                maintainAspectRatio: false
            },
        };
    },
    async created() {
        if (!this.$can('READ', 'dashboard')) {
            this.$router.push('/admin/job_vacancy');
        } else {
            this.data = await this.getDashboardData({dashboard_date: moment().toISOString()});
            this.chart_data = {
                labels: this.data.recap.chartData.labels,
                datasets: [
                    {
                        label: 'Attended Workers',
                        fill: false,
                        data: this.data.recap.chartData.datasets,
                        tension: 0,
                        borderColor: '#0ABAB5'
                    }
                ]
            };
        }
    },
    computed: {
        todayDate() {
            return formatter.dateCompleteWithDay(moment().toISOString());
        }
    },
    components: {
        LineChart
    },
    methods: {
        ...mapActions({
            getDashboardData: 'dashboard/getDashboardData',
        }),
    }
};
</script>

<style scoped>
.title {
    font-size:24px;
    font-weight:700;
    color: black;
}
.subtitle {
    font-size:18px;
    font-weight:400;
    color: black;
}
.data-content {
    font-size:94px;
    font-weight:500;
    color: black;
}
.fixed-card {
    width:300px
}
.card-content {
    padding: 20px;
}
.card-content-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>
