<template>
    <div class="uk-container uk-container-expand card-scrollable" style="padding: 20px;">
        <div v-if="pageLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <span class="uk-text-bold uk-text-large">{{ roles.name }}</span>
            <!-- Table -->
            <div class="uk-margin-medium-top">
                <div class="uk-card uk-card-default">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-middle uk-table-medium uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-text-nowrap uk-text-center">Role</th>
                                    <th class="uk-width-large">View</th>
                                    <th class="uk-width-large">Create</th>
                                    <th class="uk-width-large">Edit</th>
                                    <th class="uk-width-large">Delete</th>
                                </tr>
                            </thead>
                            <tbody v-if="permissions.length > 0">
                                <tr v-for="(rs, index) in permissions" :key="index">
                                    <td class="uk-text-nowrap">{{ rs.name }}</td>
                                    <td> 
                                        <input
                                            class="uk-checkbox"
                                            type="checkbox"
                                            :checked="rs.ability ? rs.ability.READ ? true : false: false"
                                            @input="checked($event, 'READ', index)"
                                        />
                                    </td>
                                    <td> 
                                        <input
                                            class="uk-checkbox"
                                            type="checkbox"
                                            :checked="rs.ability ? rs.ability.WRITE ? true : false: false"
                                            @input="checked($event, 'WRITE', index)"
                                        />
                                    </td>
                                    <td> 
                                        <input
                                            class="uk-checkbox"
                                            type="checkbox"
                                            :checked="rs.ability ? rs.ability.EDIT ? true : false: false"
                                            @input="checked($event, 'EDIT', index)"
                                        />
                                    </td>
                                    <td> 
                                        <input
                                            class="uk-checkbox"
                                            type="checkbox"
                                            :checked="rs.ability ? rs.ability.DELETE ? true : false: false"
                                            @input="checked($event, 'DELETE', index)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <empty-table v-else :colspan="6"></empty-table>
                             <tfoot>
                                <tr>
                                    <td colspan="6">
                                        <button
                                            style="float: right;"
                                            class="uk-button uk-button-primary uk-button-small uk-margin-small-right" 
                                            @click="save"
                                        >Save</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
thead {
    background: #0ABAB5;
}
th {
    color: #FFFFFF;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
</style>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { propAbility, sumAbility } from '@/utils/acl';
import { notificationSuccess } from '@/utils/notification';

export default {
    name: 'PageSettingAccessDetail',
    data() {
        return {
            pageLoading: false,
            roles: null,
            permissions: [],
        };
    },
    components: {
        EmptyTable
    },
    computed: {},
    async mounted() {
        try {
            this.pageLoading = true;
            await this.getRoles();
        } catch (error) {
            this.pageLoading = false;
        }
    },
    methods:{
        ...mapActions({
            getRoleDetail: 'setting/getRoleDetail',
            updateOrCreateRole: 'setting/updateOrCreateRole',
        }),
        async getRoles() {
            this.isLoading = true;
            const params = {
                role_id : this.$route.params.id,
            };
            await this.getRoleDetail(params).then((result) => {
                this.permissions = this.mapAbility(result.permissions);
                this.roles = result.role;
            });
            this.isLoading = false;
        },
        mapAbility(data){
            const perm = data.map(item => {
                const abl = propAbility(item.ability);
                return {
                    name: item.name,
                    ability: abl,
                    company_access_id: item.company_access_id,
                    company_permission_id: item.company_permission_id,
                    company_role: item.company_role
                };
            });
            data = perm;
            this.pageLoading = false;
            return data;
        },
        checked(e, type, index) {          
            if (type === "READ" && !e.target.checked) {
                this.permissions[index].ability[type] = e.target.checked;
                this.permissions[index].ability.WRITE = false;
                this.permissions[index].ability.EDIT = false;
                this.permissions[index].ability.DELETE = false;
            }
            else {
                if (type !== "READ" && !this.permissions[index].ability.READ) {
                    this.permissions[index].ability.READ = true;
                }
                this.permissions[index].ability[type] = e.target.checked;
            }
        },
        async save(){
            const newPermission = this.permissions.map(data => {
                const abl = sumAbility(data.ability);
                return {
                    name: data.name,
                    company_access_id: data.company_access_id,
                    ability: abl,
                    company_permission_id: data.company_permission_id,
                    company_role_access_id: data.company_role
                };
            });
            await this.updateOrCreateRole(newPermission)
                .then(() => {
                    notificationSuccess('Permission setting updated!');
                    this.$router.push('/settings/roles');
                });
        }
    }
};
</script>
